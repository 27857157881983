import {Essence, Affiliation} from '../models/ConfigurationOptions';
import {getInitRappidShared} from '../configuration/rappidEnviromentFunctionality/shared';
import { OntologyEnforcementLevel } from '../modules/Settings/OrgOntology/ontologyInterfaces';
import {
  ConnectionSettings, defaultAllowUsersConnectionSettings,
  defaultMqttConnectionSettings, defaultMySQLConnectionSettings, defaultPythonConnectionSettings,
  defaultRosConnectionSettings,
  MySQLConnectionSettings
} from "./opl.service";

export const Languages = ['en', 'fr', 'gr', 'cn', 'ko', 'es', 'ml', 'jp' ];
export const spellCHeck = [true, false];
export const notes = [true, false];

export const Affiliations = [Affiliation.Systemic, Affiliation.Environmental];
export const Essences = [Essence.Physical, Essence.Informatical];
export const DisplayOpt = ['Show essence OPL for all Things', 'Show essence OPL only for non-default Things', 'Don\'t show essence OPL for All Things'];
export const UnitsOpt = ['Show only when applicable', 'Always show units'  , 'Hide units'];
export const AliasOpt = ['Show only when applicable', 'Always show alias' , 'Hide alias'];



export const oplTemplates_gr = {
  'structural_link': {
    /*
     * <T1> - source OPM Thing
     * <T2> - target OPM Thing
     * <T2...n> - set of target OPM Things
     */
    'Aggregation-Participation': `<T1> besteht aus <T2>.`,
    'Aggregation-Participation_incomplete': `<T1> consists of <T2> and <num> more parts.`,
    'Aggregation-Participation_(multiple)': `<T1> besteht aus <T2...n>.`,
    'Aggregation-Participation_incomplete_(multiple)': `<T1> consists of <T2...n> and <num> more parts.`,
    'Generalization-Specialization': `<T2> ist <T1>.`,
    'Generalization-Specialization_incomplete': `<T2> and <num> more specializations are <T1>.`,
    'Generalization-Specialization_(multiple)': `<T2...n> sind <T1>.`,
    'Generalization-Specialization_incomplete_(multiple)': `<T2...n> and <num> more specializations are <T1>.`,
    'Classification-Instantiation': `<T2> ist eine Instanz von <T1>.`,
    'Classification-Instantiation_incomplete': `<T2> and <num> more instances are instances of <T1>.`,
    'Classification-Instantiation_(multiple)': `<T2...n> ist eine Instanz von <T1>.`,
    'Classification-Instantiation_incomplete_(multiple)': `<T2...n> and <num> more instances are instances of <T1>.`,
    'Exhibition-Characterization': `<T1> weist <T2> auf.`,
    'Exhibition-Characterization_incomplete': `<T1> exhibits <T2> and <num> more attributes.`,
    'Exhibition-Characterization_(multiple)': `<T1> weist <T2...n> auf.`,
    'Exhibition-Characterization_incomplete_(multiple)': `<T1> exhibits <T2...n> and <num> more attributes.`,
    'Unidirectional_Tagged_Link': `<T1> bezieht sich auf <T2>.`,
    'Unidirectional_Tagged_Link_(tag)': `<T1> <tag> <T2>.`,
    'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
    'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
    'sequence': `in that sequence.`,
    'Bidirectional_Tagged_Link': `<T1> und <T2> sind aequivalent.`,
    'Bidirectional_Tagged_Link_(tag)': `<T1> und <T2> sind <tag>.`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<T1> <forward tag> <T2>, und <T2> <backward tag> <T1>.`,
  },
  'procedural_link': {
    /*
     * <O_Os> - OPM Object or OPM Stateful Object
     * <O_Os1...n> - set of OPM Objects or OPM Stateful Objects
     * <s> - OPM State
     * <P> - OPM Process
     */
    'Agent': `<O_Os> steuert <P>.`,
    'Agent_(multiple)': `<O\Os1...n> steuern <P>.`,
    'Agent_Condition': `<P> findet statt, wenn <O> existert, anderenfalls wird <P> uebersprungen.`,
    'Agent_Condition_state': `<P> findet statt, wenn <O> ist <s>, anderenfalls wird <P> uebersprungen.`,
    'Agent_Event': `<O> initiiert und steuert <P>.`,
    'Agent_Event_state': `<s> <O> initiiert und steuert <P>.`,

    'Instrument': `<P> benoetigt <O_Os>.`,
    'Instrument_(multiple)': `<P> benoetigt <O\Os1...n>.`,
    'Instrument_Condition': `<P> findet statt, wenn <O> existiert, anderenfalls wird <P> uebersprugen.`,
    'Instrument_Condition_state': `<P> findet statt, wenn sich <O> im Zustand <s> befindet, anderenfalls wird <P> uebersprungen.`,
    'Instrument_Event': `<O> initiiert <P>, welche erfordert <O> .`,
    'Instrument_Event_state': `<s> <O> initiiert <P>, welche erfordert <s> <O>.`,

    'Effect': `<P> beeinflusst <O_Os>.`,
    'Effect_(multiple)': `<P> beeinflusst <O\Os1...n>.`,
    'Effect_Condition': `<P> findet statt, wenn <O> existiert, in welchem Fall <P>  beinflusst <O>, anderenfalls wird <P>  uebersprungen.`,
    'Effect_Condition_state': `<P> findet statt, wenn sich <O> im Zustand <s> befindet, in welchem Fall <P> beinflusst <s> <O>, anderenfalls wird <P> uebersprungen.`,
    'Effect_Event': `<O> initiiert <P>, welche <O> beeinflusst.`,
    'Effect_Event_state': `<s> <O> initiiert <P>, welche beeinflusst <s> <O>`,

    'Consumption': `<P> verbraucht <O_Os>.`,
    'Consumption_(multiple)': `<P> verbraucht <O\Os1...n>.`,
    'Consumption_Condition': `<P> findet statt, wenn <O> existiert, in welchem Fall <P> verbraucht <O>, anderenfalls wird <P> uebersprungen.`,
    'Consumption_Condition_state': `<P> findet statt, wenn sich <O> im Zustand <s> befindet, in welchem Fall <P> verbraucht <s> <O>, anderenfalls wird <P> uebersprungen.`,
    'Consumption_Event': `<O> initiiert <P>, welche <O> verbraucht.`,
    'Consumption_Event_state': `<s> <O> initiiert <P>, welche <O> verbraucht.`,

    'Result': `<P> ergibt <O_Os>.`,
    'Result_(multiple)': `<P> ergibt <O\Os1...n>.`,

    'In-out_Link_Pair': `<P> aendert <O> von <s1> zu <s2>.`,
    'Split_input': `<P> aendert <O> von <s>.`,
    'Split_output': `<P> aendert <O> zu <s>.`,
    'Condition_Input': `<P> findet statt, wenn <O> <s1> is, in welchem Fall <P> aendert <O> von <s1> zu <s2>, anderenfalls wird <P> uebersprungen.`,
    'In-out_Link_Pair_Condition': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P> is skipped.`,
    'In-out_Link_Pair_Event': `<O> at state <s1> initiates <P>, which changes <O> from <s1> to <s2>.`,

    'Overtime_exception': `<O> löst aus <P> wenn <O> ist <s> mehr als <maxtime> <units>.`,
    'Undertime_exception': `<O> löst aus <P> wenn <O> ist <s> mehr als <mintime> <units>.`,
    'OvertimeUndertime-exception': `<O> löst aus <P> wenn <O> ist <s> mehr als <maxtime> <units> und weniger als <mintime> <units>.`,

    'Overtime_exception_(process)': `<P2> findet statt, wenn <P1> dauert laenger als <maxtime> <units>.`,
    'Undertime_exception_(process)': `<P2> findet statt, wenn <P1> unterschreitet <mintime> <units>.`,
    'OvertimeUndertime-exception_(process)':
      `<P2> findet statt, wenn <P1> unterschreitet <mintime> <units> oder dauert laenger als <maxtime> <units>.`,
    'Invocation': `<P1> ruft auf <P2>.`,
    'Invocation_(multiple)': `<P1> ruft auf <P2...n>.`,
    'Invocation_(self)': `<P1> ruft sich auf.`

  },
  'grouping': { // for OPM Elements in a group
    /*
     * <T> - OPM Thing
     * <T1> - first OPM Thing
     * <T2> - second OPM Thing
     * <T1...n-1> - first n-1 OPM Things
     * <Tn> - nth OPM Thing
     * <O1...n> - first nth OPM Objects
     * <P1...n> - first nth OPM Processes
     * <s> - OPM State
     * <O> - OPM Object
     * <a> - OPM Object as an Attribute
     * <e1...n> - set of OPM Objects as Exhibitors
     */
    'Single-Thing': `<T>`,
    'Multiple-Things': `<T1...n-1> und <Tn>`,
    'Multiple-Things-Object-Process-Separated': `<O1...n>, sowie, <P1...n>`,
    'AND': `<T1...n-1> und <Tn>`,
    'OR': `<T1...n-1> oder <Tn>`,
    'XOR': `<T1> xor <T2>`,
    'Stateful-Object': `<s> <O>`,
    'Stateful-Object-value': `<O> mit dem Wert <s>`,
    'Stateful-Object-value(multiple)': `<O> mit den Werten <s1...n>`,
    'Attribute-Exhibitor': `<a> von <e1...n>`,
    'indentation': `&nbsp;&nbsp;&nbsp;&nbsp;`
  },
  'tags': {
    'multiplicity': `<tag> <O_Os>`,
    'probability': `<opl>, mit Wahrscheinlichkeit <tag>.`,
    'path': `Folgend Pfad <tag>, <opl>.`,
  },
  'object': {
    /*
     * <a> - Affiliation
     * <e> - Essence
     * <SD_..> - System diagram
     */
    'thing_generic_name': `objekt`,
    'default_essence_affiliation': ``,
    'digital_twin': `<TWIN> is the Digital Twin of <O>.`,
    'default_essence': `<O> ist <a>.`,
    'default_affiliation': `<O> ist <e>.`,
    'non_default': `<O> ist <e> und <a>.`,
    'singleInzoom': `<O> von <SD_Parent> zoomt in <Current_SD> in <T_list>.`,
    'singleInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>.`,
    'multiInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>, as well as <P_list>.`,
    'multiInzoom': `<O> von <SD_Parent> zoomt in <Current_SD> in <O_list>, sowie <P_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<O> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<O> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <O_list>, as well as <P_list>.`,
    'single_unfold_instantiation': `<O> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    'object_list_sequence': `<O1...n>, in dieser vertikalen Reihenfolge`,
    'object_list_parallel': `<O1...n>, in dieser horizontalen Reihenfolge`
  },
  'process': {
    'thing_generic_name': `prozess`,
    'default_essence_affiliation': ``,
    'default_essence': `<P> ist <a>.`,
    'default_affiliation': `<P> ist <e>.`,
    'non_default': `<P> ist <e> und <a>.`,
    'singleInzoom': `<P> von <SD_Parent> zoomt in <Current_SD> in <T_list>, die in dieser zeitfolge auftreten.`,
    'singleInzoom_parallel': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
    'multiInzoom': `<P> von <SD_Parent> zoomt in <Current_SD> in <P_list>, sowie <O_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<P> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<P> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_instantiation': `<P> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    // 'process_list_parallel': `<P1...n>, in dieser horizontalen Reihenfolge`,
    'process_list_parallel': `parallel <P1...n>`,
    // 'process_list_sequence': `<P1...n>, in dieser vertikalen Reihenfolge`,
    'process_list_sequence': `<P1...n>`,
  },
  'state': {
    'single_state': `<O> ist <s>.`,
    'multiple_states': `<O> kann sein <s1...n>.`,
    'all_states_are_suppressed': `<O> is stateful.`,
    'one_state_shown_one_missing': `<O> is <s> or at one other state.`,
    'one_state_shown': `<O> is <s> and can be at one of <num> other states.`,
    'two_or_more_states_shown_one_missing': `<O> can be <s1...n> or at one other state.`,
    'two_or_more_states_shown': `<O> can be <s1...n> or at one of <num> other states.`,
    'default': `Zustand <s> ist standard.`,
    'default_initial': `Zustand <s> ist standard and anfaenglich.`,
    'initial': `Zustand <s> ist anfaenglich.`,
    'final': `Zustand <s> ist final.`,
    'default_initial_final': `Zustand <s> ist anfaenglich, final, und standard.`,
    'initial_final': `Zustand <s> ist anfaenglich und final.`,
    'default_final': `Zustand <s> ist final und standard.`,
    'none': ``,
    'Current': `<O> is currently at state <s>.`,
  },
  'essence': {
    'physical': `physisch`,
    'informatical': `informativ`,
  },
  'affiliation': {
    'systemic': `systemisch`,
    'environmental': `umgebend`,
  },
  'semifolding': {
    'object': `<O> lists `,
    'process': `<P> lists `,
    'aggregation': {
      'single': `<T> as a part`,
      'multiple': `<T1...n-1> and <Tn> as parts`,
    },
    'exhibition': {
      'single': `<T> as a feature`,
      'multiple': `<T1...n-1> and <Tn> as features`,
    },
    'generalization': {
      'single': `<T> as a specialization`,
      'multiple': `<T1...n-1> and <Tn> as specializations`,
    },
    'instantiation': {
      'single': `<T> as an instance`,
      'multiple': `<T1...n-1> and <Tn> as instances`,
    },
  },
  'hidden_attributes': {
    'requirement': `<set_object> of <owner> is <value>.`,
  },
  'father_model_to_sub_model': `The selected things, <o1...n>, and <p1...n> are refined in sub model <subsystem_name> subsystem model view.`,
  'sub_model_from_father_model': `The <subsystem_name> subsystem model view is derived from the <father_model_name> model.`
};

export const oplTemplates_fr = {
  'structural_link': {
    /*
     * <T1> - source OPM Thing
     * <T2> - target OPM Thing
     * <T2...n> - set of target OPM Things
     */
    'Aggregation-Participation': `<T1> est compris de <T2>.`,
    'Aggregation-Participation_incomplete': `<T1> consists of <T2> and <num> more parts.`,
    'Aggregation-Participation_(multiple)': `<T1> est compris de <T2...n>.`,
    'Aggregation-Participation_incomplete_(multiple)': `<T1> consists of <T2...n> and <num> more parts.`,
    'Generalization-Specialization': `<T2> est un <T1>.`,
    'Generalization-Specialization_incomplete': `<T2> and <num> more specializations are <T1>.`,
    'Generalization-Specialization_(multiple)': `<T2...n> sont des <T1>.`,
    'Generalization-Specialization_incomplete_(multiple)': `<T2...n> and <num> more specializations are <T1>.`,
    'Classification-Instantiation': `<T2> est un instance de <T1>.`,
    'Classification-Instantiation_incomplete': `<T2> and <num> more instances are instances of <T1>.`,
    'Classification-Instantiation_(multiple)': `<T2...n> est un instance de <T1>.`,
    'Classification-Instantiation_incomplete_(multiple)': `<T2...n> and <num> more instances are instances of <T1>.`,
    'Exhibition-Characterization': `<T1> expose <T2>.`,
    'Exhibition-Characterization_incomplete': `<T1> exhibits <T2> and <num> more attributes.`,
    'Exhibition-Characterization_(multiple)': `<T1> expose <T2...n>.`,
    'Exhibition-Characterization_incomplete_(multiple)': `<T1> exhibits <T2...n> and <num> more attributes.`,
    'Unidirectional_Tagged_Link': `<T1> se rapporte à <T2>.`,
    'Unidirectional_Tagged_Link_(tag)': `<T1> <tag> <T2>.`,
    'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
    'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
    'sequence': `in that sequence.`,
    'Bidirectional_Tagged_Link': `<T1> and <T2> sont équivalents.`,
    'Bidirectional_Tagged_Link_(tag)': `<T1> et <T2> sont <tag>.`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<T1> <forward tag> <T2>, et <T2> <backward tag> <T1>.`,
  },
  'procedural_link': {
    /*
     * <O_Os> - OPM Object or OPM Stateful Object
     * <O_Os1...n> - set of OPM Objects or OPM Stateful Objects
     * <s> - OPM State
     * <P> - OPM Process
     */
    'Agent': `<O_Os> gère <P>.`,
    'Agent_(multiple)': `<O\Os1...n> gère <P>.`,
    'Agent_Condition': `<P> arrive si <O> existe, sinon <P> est sauté.`,
    'Agent_Condition_state': `<P> arrive si <O> est <s>, sinon <P> st sauté.`,
    'Agent_Event': `<O> initie et gère <P>.`,
    'Agent_Event_state': `<s> <O> initie et gère <P>.`,

    'Instrument': `<P> requires <O_Os>.`,
    'Instrument_(multiple)': `<P> a besoin de <O\Os1...n>.`,
    'Instrument_Condition': `<P> arrive si <O> existe, sinon <P> est sauté.`,
    'Instrument_Condition_state': `<P> arrive si <O> est dans un état <s>, sinon <P> est sauté.`,
    'Instrument_Event': `<O> initie <P>, qui nécessite <O>.`,
    'Instrument_Event_state': `<s> <O> initie <P>, qui nécessite <s> <O>.`,

    'Effect': `<P> affecte <O_Os>.`,
    'Effect_(multiple)': `<P> affecte <O\Os1...n>.`,
    'Effect_Condition': `<P> arrive si <O> existe, auquel cas <P>  affecte <O>, sinon <P>  est sauté.`,
    'Effect_Condition_state': `<P> arrive si <O> est dans un état <s>, auquel cas <P> affecte <s> <O>, sinon <P> est sauté.`,
    'Effect_Event': `<O> initie <P>, qui touche <O>`,
    'Effect_Event_state': `<s> <O> initie <P>, qui touche <s> <O>`,

    'Consumption': `<P> consumes <O_Os>.`,
    'Consumption_(multiple)': `<P> consume <O\Os1...n>.`,
    'Consumption_Condition': `<P> arrive si <O> existe, auquel cas <P> consume <O>, sinon <P> est sauté.`,
    'Consumption_Condition_state': `<P> arrive si <O> est dans un état <s>, auquel cas <P> consume <s> <O>, sinon <P> est sauté.`,
    'Consumption_Event': `<O> initie <P>, qui consume <O>.`,
    'Consumption_Event_state': `<s> <O> initie <P>, qui consume <O>.`,

    'Result': `<P> donne <O_Os>.`,
    'Result_(multiple)': `<P> donne <O\Os1...n>.`,

    'In-out_Link_Pair': `<P> change <O> de <s1> à <s2>.`,
    'Split_input': `<P> change <O> de <s>.`,
    'Split_output': `<P> change <O> à <s>.`,
    'Condition_Input': `<P> arrive si <O> is <s1>, auquel cas <P> change <O> de <s1> à <s2>, sinon <P> est sauté.`,
    'In-out_Link_Pair_Condition': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P> is skipped.`,
    'In-out_Link_Pair_Event': `<O> at state <s1> initiates <P>, which changes <O> from <s1> to <s2>.`,

    'Overtime_exception': `<O> déclenche <P> quand <O> est <s> plus que <maxtime> <units>.`,
    'Undertime_exception': `<O> déclenche <P> quand <O> is <s> moins que <mintime> <units>.`,
    'OvertimeUndertime-exception': `<O> déclenche <P> quand <O> est <s> plus que <maxtime> <units> et moins que <mintime> <units>.`,

    'Overtime_exception_(process)': `<P2> arrive si <P1> dure plus de <maxtime> <units>.`,
    'Undertime_exception_(process)': `<P2> arrive si <P1> et moins que <mintime> <units>.`,
    'OvertimeUndertime-exception_(process)':
      `<P2> arrive si <P1> et moins que <mintime> <units> ou plus que <maxtime> <units>.`,
    'Invocation': `<P1> invoque <P2>.`,
    'Invocation_(multiple)': `<P1> invoque <P2...n>.`,
    'Invocation_(self)': `<P1> s'invoque.`

  },
  'grouping': { // for OPM Elements in a group
    /*
     * <T> - OPM Thing
     * <T1> - first OPM Thing
     * <T2> - second OPM Thing
     * <T1...n-1> - first n-1 OPM Things
     * <Tn> - nth OPM Thing
     * <O1...n> - first nth OPM Objects
     * <P1...n> - first nth OPM Processes
     * <s> - OPM State
     * <O> - OPM Object
     * <a> - OPM Object as an Attribute
     * <e1...n> - set of OPM Objects as Exhibitors
     */
    'Single-Thing': `<T>`,
    'Multiple-Things': `<T1...n-1> et <Tn>`,
    'Multiple-Things-Object-Process-Separated': `<O1...n>, aussi bien que <P1...n>`,
    'AND': `<T1...n-1> et <Tn>`,
    'OR': `<T1...n-1> ou <Tn>`,
    'XOR': `<T1> xor <T2>`,
    'Stateful-Object': `<s> <O>`,
    'Stateful-Object-value': `<O> avec la valeur <s>`,
    'Stateful-Object-value(multiple)': `<O> avec les valeurs <s1...n>`,
    'Attribute-Exhibitor': `<a> de <e1...n>`,
    'indentation': `&nbsp;&nbsp;&nbsp;&nbsp;`
  },
  'tags': {
    'multiplicity': `<tag> <O_Os>`,
    'probability': `<opl>, avec probabilité <tag>.`,
    'path': `En suivant le chemin <tag>, <opl>.`,
  },
  'object': {
    /*
     * <a> - Affiliation
     * <e> - Essence
     * <SD_..> - System diagram
     */
    'thing_generic_name': `objet`,
    'default_essence_affiliation': ``,
    'digital_twin': `<TWIN> is the Digital Twin of <O>.`,
    'default_essence': `<O> est <a>.`,
    'default_affiliation': `<O> est <e>.`,
    'non_default': `<O> est <e> et <a>.`,
    'singleInzoom': `<O> de <SD_Parent> fait un zoom sur <Current_SD> en <T_list>.`,
    'singleInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>.`,
    'multiInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>, as well as <P_list>.`,
    'multiInzoom': `<O> de <SD_Parent> fait un zoom sur <Current_SD> en <O_list>, aussi bien que <P_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<O> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<O> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <O_list>, as well as <P_list>.`,
    'single_unfold_instantiation': `<O> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    'object_list_sequence': `<O1...n>, dans cette séquence verticale`,
    'object_list_parallel': `<O1...n>, dans cette séquence horizontale`
  },
  'process': {
    'thing_generic_name': `processus`,
    'default_essence_affiliation': ``,
    'default_essence': `<P> est <a>.`,
    'default_affiliation': `<P> est <e>.`,
    'non_default': `<P> est <e> et <a>.`,
    'singleInzoom': `<P> de <SD_Parent> fait un zoom sur <Current_SD> en <T_list>, qui se produisent dans cette séquence temporelle.`,
    'singleInzoom_parallel': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
    'multiInzoom': `<P> de <SD_Parent> fait un zoom sur <Current_SD> en <P_list>, aussi bien que <O_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<P> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<P> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_instantiation': `<P> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    'process_list_parallel': `parallèle <P1...n>`,
    'process_list_sequence': `<P1...n>`,
  },
  'state': {
    'single_state': `<O> est <s>.`,
    'multiple_states': `<O> peut être <s1...n>.`,
    'all_states_are_suppressed': `<O> is stateful.`,
    'one_state_shown_one_missing': `<O> is <s> or at one other state.`,
    'one_state_shown': `<O> is <s> and can be at one of <num> other states.`,
    'two_or_more_states_shown_one_missing': `<O> can be <s1...n> or at one other state.`,
    'two_or_more_states_shown': `<O> can be <s1...n> or at one of <num> other states.`,
    'default': `State <s> est par défaut.`,
    'default_initial': `L'état <s> est la valeur par défaut et l'initiale.`,
    'initial': `L'état <s> est initial`,
    'final': `L'état <s> est final.`,
    'default_initial_final': `L'état <s> est initial, final et par défaut.`,
    'initial_final': `State <s> est initial et final.`,
    'default_final': `State <s> est final et par défaut.`,
    'none': ``,
    'Current': `<O> is currently at state <s>.`,
  },
  'essence': {
    'physical': `un physique`,
    'informatical': `un informatique`,
  },
  'affiliation': {
    'systemic': `systémique`,
    'environmental': `environmental`,
  },
  'semifolding': {
    'object': `<O> lists `,
    'process': `<P> lists `,
    'aggregation': {
      'single': `<T> as a part`,
      'multiple': `<T1...n-1> and <Tn> as parts`,
    },
    'exhibition': {
      'single': `<T> as a feature`,
      'multiple': `<T1...n-1> and <Tn> as features`,
    },
    'generalization': {
      'single': `<T> as a specialization`,
      'multiple': `<T1...n-1> and <Tn> as specializations`,
    },
    'instantiation': {
      'single': `<T> as an instance`,
      'multiple': `<T1...n-1> and <Tn> as instances`,
    },
  },
  'hidden_attributes': {
    'requirement': `<set_object> of <owner> is <value>.`,
  },
  'father_model_to_sub_model': `The selected things, <o1...n>, and <p1...n> are refined in sub model <subsystem_name> subsystem model view.`,
  'sub_model_from_father_model': `The <subsystem_name> subsystem model view is derived from the <father_model_name> model.`
};

export const oplTemplates_en = {
  'structural_link': {
    /*
     * <T1> - source OPM Thing
     * <T2> - target OPM Thing
     * <T2...n> - set of target OPM Things
     */
    'Aggregation-Participation': `<T1> consists of <T2>.`,
    'Aggregation-Participation_incomplete': `<T1> consists of <T2> and <num> more parts.`,
    'Aggregation-Participation_one_incomplete': `<T1> consists of <T2> and <num> more part.`,
    'Aggregation-Participation_(multiple)': `<T1> consists of <T2...n>.`,
    'Aggregation-Participation_incomplete_(multiple)': `<T1> consists of <T2...n> and <num> more parts.`,
    'Aggregation-Participation_incomplete_(one)': `<T1> consists of <T2...n> and <num> more part.`,
    'Generalization-Specialization': `<T2> is a <T1>.`,
    'Generalization-Specialization_one_incomplete': `<T2> and <num> more specialization are <T1>.`,
    'Generalization-Specialization_incomplete': `<T2> and <num> more specializations are <T1>.`,
    'Generalization-Specialization_(multiple)': `<T2...n> are <T1>.`,
    'Generalization-Specialization_incomplete_(multiple)': `<T2...n> and <num> more specializations are <T1>.`,
    'Generalization-Specialization_incomplete_(one)': `<T2...n> and <num> more specialization are <T1>.`,
    'Classification-Instantiation': `<T2> is an instance of <T1>.`,
    'Classification-Instantiation_one_incomplete': `<T2> and <num> more instance are instances of <T1>.`,
    'Classification-Instantiation_incomplete': `<T2> and <num> more instances are instances of <T1>.`,
    'Classification-Instantiation_(multiple)': `<T2...n> are instances of <T1>.`,
    'Classification-Instantiation_incomplete_(multiple)': `<T2...n> and <num> more instances are instances of <T1>.`,
    'Classification-Instantiation_incomplete_(one)': `<T2...n> and <num> more instance are instances of <T1>.`,
    'Exhibition-Characterization': `<T1> exhibits <T2>.`,
    'Exhibition-Characterization_incomplete': `<T1> exhibits <T2> and <num> more attributes.`,
    'Exhibition-Characterization_one_incomplete': `<T1> exhibits <T2> and <num> more attribute.`,
    'Exhibition-Characterization_incomplete_obj_proc': `<T1> exhibits <T2> and <num> more operations.`,
    'Exhibition-Characterization_one_incomplete_obj_proc': `<T1> exhibits <T2> and <num> more operation.`,
    'Exhibition-Characterization_(multiple)': `<T1> exhibits <T2...n>.`,
    'Exhibition-Characterization_incomplete_(multiple)': `<T1> exhibits <T2...n> and <num> more attributes.`,
    'Exhibition-Characterization_incomplete_(one)': `<T1> exhibits <T2...n> and one more attribute.`,
    'Exhibition-Characterization_incomplete_obj_proc_(multiple)': `<T1> exhibits <T2...n> and <num> more operations.`,
    'Exhibition-Characterization_incomplete_obj_proc_(one)': `<T1> exhibits <T2...n> and one more operation.`,
    'Exhibition-Characterization_incomplete_both': `<T1> exhibits <T2> and <num> more <attribute>, as well as <num2> more <operation>.`,
    'Exhibition-Characterization_incomplete_both_(multiple)': `<T1> exhibits <T2...n> and <num> more <attribute>, as well as <num2> more <operation>.`,
    'Exhibition-Attribute': 'attribute',
    'Exhibition-Attributes': 'attributes',
    'Exhibition-Operation': 'operation',
    'Exhibition-Operations': 'operations',
    'Unidirectional_Tagged_Link': `<T1> relates to <T2>.`,
    'Unidirectional_Tagged_Link_(tag)': `<T1> <tag> <T2>.`,
    'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
    'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
    'sequence': `in that sequence.`,
    'Bidirectional_Tagged_Link': `<T1> and <T2> are equivalent.`,
    'Bidirectional_Tagged_Link_(tag)': `<T1> and <T2> are <tag>.`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<T1> <forward tag> <T2>, and <T2> <backward tag> <T1>.`,
  },
  'procedural_link': {
    /*
     * <O_Os> - OPM Object or OPM Stateful Object
     * <O_Os1...n> - set of OPM Objects or OPM Stateful Objects
     * <s> - OPM State
     * <P> - OPM Process
     */
    'Agent': `<O_Os> handles <P>.`,
    'Agent_(multiple)': `<O\Os1...n> handle <P>.`,
    'Agent_Condition': `<P> occurs if <O> exists, otherwise <P> is skipped.`,
    'Agent_Condition(multiple)': `<P> occurs if <O\Os1...n> exist, otherwise <P> is skipped.`,
    'Agent_Condition_state': `<P> occurs if <O> is <s>, otherwise <P> is skipped.`,
    'Agent_Event': `<O> initiates and handles <P>.`,
    'Agent_Event(multiple)': `<O\Os1...n> initiate and handle <P>.`,
    'Agent_Event_state': `<s> <O> initiates and handles<P>.`,

    'Agent_Negation': `<P> occurs if <O_Os> is not present.`,
    'Agent_Negation_(multiple)': `<P> occurs if <O\Os1...n> are not present`,
    'Agent_Condition_Negation': `<P> occurs if <O> does not exist, otherwise <P> is skipped.`,
    'Agent_Condition_Negation(multiple)': `<P> occurs if <O\Os1...n> do not exist, otherwise <P> is skipped.`,
    'Agent_Condition_Negation_state': `<P> occurs if <O> is not at state <s>, otherwise <P> is skipped.`,

    'Instrument': `<P> requires <O_Os>.`,
    'Instrument_(multiple)': `<P> requires <O\Os1...n>.`,
    'Instrument_Condition': `<P> occurs if <O> exists, otherwise <P> is skipped.`,
    'Instrument_Condition(multiple)': `<P> occurs if <O\Os1...n> exist, otherwise <P> is skipped.`,
    'Instrument_Condition_state': `<P> occurs if <O> is at state <s>, otherwise <P> is skipped.`,
    'Instrument_Event': `<O> initiates <P>, which requires <O>.`,
    'Instrument_Event(multiple)': `<O\Os1...n> initiate <P>, which requires them.`,
    'Instrument_Event_state': `<s> <O> initiates <P>, which requires <s> <O>.`,

    'Instrument_Negation': `<P> occurs if <O_Os> does not exist.`,
    'Instrument_Negation_(multiple)': `<P> occurs if <O\Os1...n> do not exist.`,
    'Instrument_Condition_Negation': `<P> occurs if <O> does not exist, otherwise <P> is skipped.`,
    'Instrument_Condition_Negation(multiple)': `<P> occurs if <O\Os1...n> do not exist, otherwise <P> is skipped.`,
    'Instrument_Condition_Negation_state': `<P> occurs if <O> is not at state <s>, otherwise <P> is skipped.`,

    'Effect': `<P> affects <O_Os>.`,
    'Effect_(multiple)': `<P> affects <O\Os1...n>.`,
    'Effect_Condition': `<P> occurs if <O> exists, in which case <P> affects <O>, otherwise <P> is skipped.`,
    'Effect_Condition(multiple)': `<P> occurs if <O\Os1...n> exist, in which case <P> affects them, otherwise <P> is skipped.`,
    'Effect_Condition_state': `<P> occurs if <O> is at state <s>, in which case <P> affects <s> <O>, otherwise <P> is skipped.`,
    'Effect_Event': `<O> initiates <P>, which affects <O>`,
    'Effect_Event(multiple)': `<O\Os1...n> initiate <P>, which affects <O>.`,
    'Effect_Event_state': `<s> <O> initiates <P>, which affects <s> <O>`,

    'Effect_Negation': `<P> does not affect <O_Os>.`,
    'Effect_Negation_(multiple)': `<P> does not affect <O\Os1...n>.`,
    'Effect_Condition_Negation': `<P> occurs if <O> does not exist, in which case <P> affects <O>, otherwise <P> is skipped.`,
    'Effect_Condition_Negation(multiple)': `<P> occurs if <O\Os1...n> do not exist, in which case <P> affects them, otherwise <P> is skipped.`,
    'Effect_Condition_Negation_state': `<P> occurs if <O> is not at state <s>, in which case <P> affects <s> <O>, otherwise <P> is skipped.`,

    'Consumption': `<P> consumes <O_Os>.`,
    'Consumption_(multiple)': `<P> consumes <O\Os1...n>.`,
    'Consumption_Condition': `<P> occurs if <O> exists, in which case <P> consumes <O>, otherwise <P> is skipped.`,
    'Consumption_Condition(multiple)': `<P> occurs if <O\Os1...n> exist, in which case <P> consumes them, otherwise <P> is skipped.`,
    'Consumption_Condition_state': `<P> occurs if <O> is at state <s>, in which case <P> consumes <s> <O>, otherwise <P> is skipped.`,
    'Consumption_Event': `<O> initiates <P>, which consumes <O>.`,
    'Consumption_Event(multiple)': `<O\Os1...n> initiate <P>, which consumes them.`,
    'Consumption_Event_state': `<s> <O> initiates <P>, which consumes <O>.`,

    'Consumption_Negation': `<P> does not consume <O_Os>.`,
    'Consumption_Negation_(multiple)': `<P> does not consume <O\Os1...n>.`,
    'Consumption_Condition_Negation': `<P> occurs if <O> does not exist, in which case <P> consumes <O>, otherwise <P> is skipped.`,
    'Consumption_Condition_Negation(multiple)': `<P> occurs if <O\Os1...n> do not exist, in which case <P> consumes them, otherwise <P> is skipped.`,
    'Consumption_Condition_Negation_state': `<P> occurs if <O> is not at state <s>, in which case <P> consumes <s> <O>, otherwise <P> is skipped.`,

    'Result': `<P> yields <O_Os>.`,
    'Result_(multiple)': `<P> yields <O\Os1...n>.`,

    'In-out_Link_Pair': `<P> changes <O> from <s1> to <s2>.`,
    'In-out(group)': `<O> from <s1> to <s2>`,
    'In-out_Link_Pair(group)': `<P> changes <O> from <s1> to <s2><Other_changes>.`,
    // 'Split_input': `<P> changes <O> from <s> to any other state.`, // not used anymore
    // 'Split_output': `<P> changes <O> to <s> from any other state.`,// not used anymore
    'Split': `any other state`,
    'Condition_Input': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P> is skipped.`,
    'In-out_Link_Pair_Condition': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P> is skipped.`,
    'In-out_Link_Pair_Condition(group)': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P> is skipped.`,
    'In-out_Link_Pair_Event': `<O> at state <s1> initiates <P>, which changes <O> from <s1> to <s2>.`,
    'In-out_Link_Pair_Event(group)': `<O> at states <s1> initiates <P>, which changes <O> from <s1> to <s2>.`,

    'Overtime_exception': `<O> triggers <P> when <O> is <s> more than <maxtime> <units>.`,
    'Undertime_exception': `<O> triggers <P> when <O> is <s> less than <mintime> <units>.`,
    'OvertimeUndertime-exception': `<O> triggers <P> when <O> is <s> more than <maxtime> <units> and less than <mintime> <units>.`,

    'Overtime_exception_(process)': `<P2> occurs if <P1> lasts more than <maxtime> <units>.`,
    'Undertime_exception_(process)': `<P2> occurs if <P1> falls short of <mintime> <units>.`,
    'OvertimeUndertime-exception_(process)':
      `<P2> occurs if <P1> falls short of <mintime> <units> or lasts more than <maxtime> <units>.`,
    'Invocation': `<P1> invokes <P2>.`,
    'Invocation_(multiple)': `<P1> invokes <P2...n>.`,
    'Invocation_(self)': `<P1> invokes itself.`,
    'Invocation_(parent)': `<P1> invokes <P2>.`,

  },
  'grouping': { // for OPM Elements in a group
    /*
     * <T> - OPM Thing
     * <T1> - first OPM Thing
     * <T2> - second OPM Thing
     * <T1...n-1> - first n-1 OPM Things
     * <Tn> - nth OPM Thing
     * <O1...n> - first nth OPM Objects
     * <P1...n> - first nth OPM Processes
     * <s> - OPM State
     * <O> - OPM Object
     * <a> - OPM Object as an Attribute
     * <e1...n> - set of OPM Objects as Exhibitors
     */
    'Single-Thing': `<T>`,
    'Multiple-Things': `<T1...n-1> and <Tn>`,
    'Multiple-Things-Object-Process-Separated': `<O1...n>, as well as <P1...n>`,
    'AND(links)': `<T1...n-1>, and <Tn>`,
    'AND': `<T1...n-1> and <Tn>`,
    'OR': `<T1...n-1> or <Tn>`,
    'XOR': `<T1> xor <T2>`,
    'Stateful-Object': `<O> at state <s>`,
    'Stateful-Object-value': `<O> with value <s>`,
    'Stateful-Object-value(multiple)': `<O> with values <s1...n>`,
    'Stateful-Object-unidirectional': `state <s> of <O>`,
    'Stateful-Object(multiple)': `<O> at states <s1...n>`,
    'Attribute-Exhibitor': `<T> of <e1...n>`,
    'indentation': `&nbsp;&nbsp;&nbsp;&nbsp;`,
    'Multiple-InOut': `<Other_changes> and <change>.`
  },
  'logic_operators': {
    // process/object are the single thing in the XOR\OR relation
    // brothers - couple of states of the same object.
    'XOR': {
      'process': {
        'Result': '<P> yields exactly one of <O_Os1...n>.',
        'Result(brothers)': '<P> yields <O> at one of the states <s1...n>.',

        'Consumption': `<P> consumes exactly one of <O_Os1...n>.`,
        'Consumption(brothers)': `<P> consumes <O> at one of the states <s1...n>.`,
        'Consumption_Condition': `<P> occurs if exactly one of <O_Os1...n> exists, in which case <P> consumes the existed one, otherwise <P> is skipped.`,
        'Consumption_Condition(brothers)': `<P> occurs if <O> is at one of the states <s1...n>, in which case <P> consumes it, otherwise <P> is skipped.`,
        'Consumption_Event': `Exactly one of <O_Os1...n> initiates <P>, which consumes it.`,
        'Consumption_Event(brothers)': `<O> initiates <P> when it is at one of the states <s1...n>, which consumes it.`,

        'Consumption_Negation': `<P> does not consume exactly one of <O_Os1...n>.`,
        'Consumption_Negation(brothers)': `<P> does not consume <O> at one of the states <s1...n>.`,
        'Consumption_Condition_Negation': `<P> occurs if exactly one of <O_Os1...n> does not exist, in which case <P> consumes the existing one, otherwise <P> is skipped.`,
        'Consumption_Condition_Negation(brothers)': `<P> occurs if <O> is not at one of the states <s1...n>, in which case <P> consumes it, otherwise <P> is skipped.`,

        'Effect': `<P> affects exactly one of <O_Os1...n>.`,
        'Effect(brothers)': `<P> affects <O> at one of the states <s1...n>.`,
        'Effect_Condition': `<P> occurs if exactly one of <O_Os1...n> exists, in which case <P> affects it, otherwise <P> is skipped.`,
        'Effect_Condition(brothers)': `<P> occurs if <O> is at one of the states <s1...n>, in which case <P> affects it, otherwise <P> is skipped.`,
        'Effect_Event': `Exactly one of <O_Os1...n> initiates <P>, which affects it.`,
        'Effect_Event(brothers)': `<O> initiates <P> when it is at one of the states <s1...n>, which affects it.`,

        'Effect_Negation': `<P> does not have an effect on exactly one of <O_Os1...n>.`,
        'Effect_Negation(brothers)': `<P> does not have an affect on <O> at one of the states <s1...n>.`,
        'Effect_Condition_Negation': `<P> occurs if exactly one of <O_Os1...n> does not exist, in which case <P> affects all the others, otherwise <P> is skipped.`,
        'Effect_Condition_Negation(brothers)': `<P> occurs if <O> is not at one of the states <s1...n>, in which case <P> affects all the others, otherwise <P> is skipped.`,

        'Agent': `Exactly one of <O_Os1...n> handles <P>.`,
        'Agent(brothers)': `<O> handles <P> when it is at one of the states <s1...n>.`,
        'Agent_Event': `Exactly one of <O_Os1...n> initiates and handles <P>.`,
        'Agent_Event(brothers)': `<O> initiates and handles <P> when it is at one of the states <s1...n>.`,
        'Agent_Condition': `<P> occurs if exactly one of <O_Os1...n> exists, otherwise <P> is skipped.`,
        'Agent_Condition(brothers)': `<P> occurs if <O> is at one of the states <s1...n>, otherwise <P> is skipped.`,

        'Agent_Negation': `Exactly one of <O_Os1...n> does not handle <P>.`,
        'Agent_Negation(brothers)': `<O> does not handle <P> when it is at one of the states <s1...n>.`,
        'Agent_Condition_Negation': `<P> occurs if exactly one of <O_Os1...n> does not exist, otherwise <P> is skipped.`,
        'Agent_Condition_Negation(brothers)': `<P> occurs if <O> is not at one of the states <s1...n>, otherwise <P> is skipped.`,

        'Instrument': `<P> requires exactly one of <O_Os1...n>.`,
        'Instrument(brothers)': `<P> requires <O> at one of the states <s1...n>.`,
        'Instrument_Condition': `<P> occurs if exactly one of <O_Os1...n> exists, otherwise <P> is skipped.`,
        'Instrument_Condition(brothers)': `<P> occurs if <O> is at one of the states <s1...n>, otherwise <P> is skipped.`,
        'Instrument_Event': `Exactly one of <O_Os1...n> initiates <P>, which requires it.`,
        'Instrument_Event(brothers)': `<O> initiates <P> when it is at one of the states <s1...n>, which requires it.`,

        'Instrument_Negation': `<P> does not require exactly one of <O_Os1...n>.`,
        'Instrument_Negation(brothers)': `<P> does not require <O> at one of the states <s1...n>.`,
        'Instrument_Condition_Negation': `<P> occurs if exactly one of <O_Os1...n> does not exist, otherwise <P> is skipped.`,
        'Instrument_Condition_Negation(brothers)': `<P> occurs if <O> is not at one of the states <s1...n>, otherwise <P> is skipped.`,

        'Invocation_OUT': `<P> invokes either <P1...n>.`,
        'Invocation_IN': `Either <P1...n> invokes <P>.`,
        'InOut': `<P> changes <O> from <s1> to exactly one of <O_Os2...n>.`,
        'InOut_multi_Ins_Xor': `<P> changes <O> from exactly one of <ins1..n> to exactly one of <O_Os2...n>.`,
      },
      'object': { // _state means the link fan is from state.
        'Result': `Exactly one of <P1...n> yields <O_Os>.`,

        'Consumption': `Exactly one of <P1...n> consumes <O_Os>.`,
        'Consumption_Event': `<O_Os> initiates exactly one of <P1...n>, which consumes the initiated process.`,
        'Consumption_Condition_state': `Exactly one of <P1...n> occurs if <O> is <s1>, in which case the occurring process consumes <O>, otherwise these processes are skipped.`,
        'Consumption_Condition': `Exactly one of <P1...n> occurs if <O_Os> exists, in which case the occurring process consumes <O_Os>, otherwise these processes are skipped.`,

        'Consumption_Negation': `Exactly one of <P1...n> does not consume <O_Os>`,
        'Consumption_Condition_Negation_state': `Exactly one of <P1...n> occurs if <O> is not at state <s1>, in which case that process consumes <O>, otherwise all these processes are skipped.`,
        'Consumption_Condition_Negation': `Exactly one of <P1...n> occurs if <O_Os> does not exist, in which case that process consumes <O_Os>, otherwise all these processes are skipped.`,

        'Effect': `Exactly one of <P1...n> affects <O_Os>.`,
        'Effect_Event': `<O_Os> initiates exactly one of <P1...n>, which affects the occurring process.`,
        'Effect_Condition': `Exactly one of <P1...n> occurs if <O_Os> exists, in which case the occurring process affects <O_Os>, otherwise these processes are skipped.`,

        'Effect_Negation': `<P1...n> does not have an effect on exactly one of <O_Os>.`,
        'Effect_Condition_Negation': `<P1...n> occurs if exactly one of <O_Os> does not exist, in which case <P1...n> affects all the others, otherwise <P1...n> are skipped.`,

        'Agent': `<O_Os> handles exactly one of <P1...n>.`,
        'Agent_Event': `<O_Os> initiates and handles exactly one of <P1...n>.`,
        'Agent_Condition': `<O_Os> handles exactly one of <P1...n> if <O_Os> exists, otherwise these processes are skipped.`,
        'Agent_Condition_state': `<O> handles exactly one of <P1...n> if <O> is <s1>, otherwise these processes are skipped.`,

        'Agent_Negation': `Exactly one of <P1...n> occurs if <O_Os> is not present.`,
        'Agent_Condition_Negation':  `Exactly one of <P1...n> occurs if <O_Os> is not present, otherwise all these processes are skipped.`,
        'Agent_Condition_Negation_state': `Exactly one of <P1...n> occurs if <O> is not at state <s1>, otherwise all these processes are skipped.`,

        'Instrument': `Exactly one of <P1...n> requires <O_Os>.`,
        'Instrument_Event': `<O_Os> initiates exactly one of <P1...n>, which requires <O_Os>.`,
        'Instrument_Condition': `Exactly one of <P1...n> requires <O_Os>, otherwise these processes are skipped.`,
        'Instrument_Condition_state': `Exactly one of <P1...n> requires that <O> is <s1>, otherwise these processes are skipped.`,

        'Instrument_Negation': `Exactly one of <P1...n> occurs if <O_Os> does not exist.`,
        'Instrument_Condition_Negation': `Exactly one of <P1...n> occurs if <O_Os> does not exist, otherwise all these processes are skipped.`,
        'Instrument_Condition_Negation_state': `Exactly one of <P1...n> occurs if <O> is not at state <s1>, otherwise all these processes are skipped.`,
      }
    },
    'OR': {
      'process': {
        'Result': '<P> yields at least one of <O_Os1...n>.',

        'Consumption': `<P> consumes at least one of <O_Os1...n>.`,
        'Consumption_Condition': `<P> occurs if at least one of <O_Os1...n> exists, in which case <P> consumes the existed ones, otherwise <P> is skipped.`,
        'Consumption_Event': `At least one of <O_Os1...n> initiates <P>, which consumes it.`,

        'Consumption_Negation': `<P> does not consume at least one of <O_Os1...n>.`,
        'Consumption_Condition_Negation': `<P> occurs if at least one of <O_Os1...n> does not exist, in which case <P> consumes the existing ones, otherwise <P> is skipped.`,

        'Effect': `<P> affects at least one of <O_Os1...n>.`,
        'Effect_Condition': `<P> occurs if at least one of <O_Os1...n> exists, in which case <P> affects it, otherwise <P> is skipped.`,
        'Effect_Event': `At least one of <O_Os1...n> initiates <P>, which affects it.`,

        'Effect_Negation': `<P> does not have an effect on at least one of <O_Os1...n>.`,
        'Effect_Negation(brothers)': `<P> does not have an affect on <O> at least one of the states <s1...n>.`,
        'Effect_Condition_Negation': `<P> occurs if at least one of <O_Os1...n> does not exist, in which case <P> affects all the others, otherwise <P> is skipped.`,
        'Effect_Condition_Negation(brothers)': `<P> occurs if <O> is not at least one of the states <s1...n>, in which case <P> affects all the others, otherwise <P> is skipped.`,

        'Agent': `At least one of <O_Os1...n> handles <P>.`,
        'Agent_Event': `At least one of <O_Os1...n> initiates and handles <P>.`,
        'Agent_Condition': `<P> occurs if At least one of <O_Os1...n> exists, otherwise <P> is skipped.`,

        'Agent_Negation': `At least one of <O_Os1...n> does not handle <P>.`,
        'Agent_Condition_Negation': `<P> occurs if at least one of <O_Os1...n> does not exist, otherwise <P> is skipped.`,

        'Instrument': `<P> requires at least one of <O_Os1...n>.`,
        'Instrument_Condition': `<P> occurs if at least one of <O_Os1...n> exists, otherwise <P> is skipped.`,
        'Instrument_Event': `At least one of <O_Os1...n> initiates <P>, which requires it.`,

        'Instrument_Negation': `<P> does not require at least one of <O_Os1...n>.`,
        'Instrument_Condition_Negation': `<P> occurs if at least one of <O_Os1...n> does not exist, otherwise <P> is skipped.`,

        'Invocation_OUT': `<P> invokes at least one of <P1...n>.`,
        'Invocation_IN': `At least one of <P1...n> invokes <P>.`,
        'InOut': `<P> changes <O> from <s1> to at least one of <O_Os2...n>.`,
      },
      'object': {
        'Result': `At least one of <P1...n> yields <O_Os>.`,

        'Consumption': `At least one of <P1...n> consumes <O_Os>.`,
        'Consumption_Event': `<O_Os> initiates at least one of <P1...n>, which consumes the initiated process.`,
        'Consumption_Condition_state': `At least one of <P1...n> occurs if <O> is <s1>, in which case the occurring process consumes <O>, otherwise these processes are skipped.`,
        'Consumption_Condition': `At least one of <P1...n> occurs if <O_Os> exists, in which case the occurring process consumes <O_Os>, otherwise these processes are skipped.`,

        'Consumption_Negation': `At least one of <P1...n> does not consume <O_Os>.`,
        'Consumption_Condition_Negation_state': `At least one of <P1...n> occurs if <O> is not at state <s1>, in which case that process consumes <O>, otherwise all these processes are skipped.`,
        'Consumption_Condition_Negation': `At least one of <P1...n> occurs if <O_Os> does not exist, in which case that process consumes <O_Os>, otherwise all these processes are skipped.`,

        'Effect': `At least one of <P1...n> affects <O_Os>`,
        'Effect_Event': `<O_Os> initiates at least one of <P1...n>, which affects the occurring process.`,
        'Effect_Condition': `At least one of <P1...n> occurs if <O_Os> exists, in which case the occurring process affects <O_Os>, otherwise these processes are skipped.`,

        'Effect_Negation': `<P1...n> does not have an effect on at least one of <O_Os>.`,
        'Effect_Condition_Negation': `<P1...n> occurs if at least one of <O_Os> does not exist, in which case <P1...n> affects all the others, otherwise <P1...n> are skipped.`,

        'Agent': `<O_Os> handles at least one of <P1...n>.`,
        'Agent_Event': `<O_Os> initiates and handles at least one of <P1...n>.`,
        'Agent_Condition': `<O_Os> handles at least one of <P1...n> if <O_Os> exists, otherwise these processes are skipped.`,
        'Agent_Condition_state': `<O> handles at least one of <P1...n> if <O> is <s1>, otherwise these processes are skipped.`,

        'Agent_Negation': `At least one of <P1...n> occurs if <O_Os> is not present.`,
        'Agent_Condition_Negation':  `At least one of <P1...n> occurs if <O_Os> is not present, otherwise all these processes are skipped.`,
        'Agent_Condition_Negation_state': `At least one of <P1...n> occurs if <O> is not at state <s1>, otherwise all these processes are skipped.`,

        'Instrument': `At least one of <P1...n> requires <O_Os>`,
        'Instrument_Event': `<O_Os> initiates at least one of <P1...n>, which requires <O_Os>`,
        'Instrument_Condition': `At least one of <P1...n> requires <O_Os>, otherwise these processes are skipped.`,
        'Instrument_Condition_state': `At least one of <P1...n> requires that <O> is <s1>, otherwise these processes are skipped.`,

        'Instrument_Negation': `At least one of <P1...n> occurs if <O_Os> does not exist.`,
        'Instrument_Condition_Negation': `At least one of <P1...n> occurs if <O_Os> does not exist, otherwise all these processes are skipped.`,
        'Instrument_Condition_Negation_state': `At least one of <P1...n> occurs if <O> is not at state <s1>, otherwise all these processes are skipped.`,
      }
    },
    'NOT': {
      'process': {
        'Agent_Negation': `<O_Os1...n> does not handle <P>.`,
        'Agent_Condition_Negation': `<P> occurs if <O_Os1...n> does not exist, otherwise <P> is skipped.`,

        'Instrument_Negation': `<P> does not require <O_Os1...n>.`,
        'Instrument_Condition_Negation': `<P> occurs if <O_Os1...n> does not exist, otherwise <P> is skipped.`,

        'Consumption_Negation': `<P> does not consume <O_Os1...n>.`,
        'Consumption_Condition_Negation': `<P> occurs if <O_Os1...n> does not exist, in which case <P> consumes the existing ones, otherwise <P> is skipped.`,

        'Effect_Negation': `<P> does not have an effect on <O_Os1...n>.`,
        'Effect_Condition_Negation': `<P> occurs if <O_Os1...n> does not exist, in which case <P> affects all the others, otherwise <P> is skipped.`
      },
      'object': {
        'Agent_Negation': `<P1...n> occurs if <O_Os> is not present.`,
        'Agent_Condition_Negation':  `<P1...n> occurs if <O_Os> is not present, otherwise all these processes are skipped.`,
        'Agent_Condition_Negation_state': `<P1...n> occurs if <O> is not at state <s1>, otherwise all these processes are skipped.`,

        'Instrument_Negation': `<P1...n> occurs if <O_Os> does not exist.`,
        'Instrument_Condition_Negation': `<P1...n> occurs if <O_Os> does not exist, otherwise all these processes are skipped.`,
        'Instrument_Condition_Negation_state': `<P1...n> occurs if <O> is not at state <s1>, otherwise all these processes are skipped.`,

        'Consumption_Negation': `<P1...n> does not consume <O_Os>`,
        'Consumption_Condition_Negation_state': `<P1...n> occurs if <O> is not at state <s1>, in which case that process consumes <O>, otherwise all these processes are skipped.`,
        'Consumption_Condition_Negation': `<P1...n> occurs if <O_Os> does not exist, in which case that process consumes <O_Os>, otherwise all these processes are skipped.`,

        'Effect_Negation': `<P1...n> does not have an effect on <O_Os>.`,
        'Effect_Condition_Negation': `<P1...n> occurs if <O_Os> does not exist, in which case <P1...n> affects all the others, otherwise <P1...n> are skipped.`
      }
    }
  },
  'tags': {
    'multiplicity': `<tag> <O_Os>`,
    'constraints': `<O_Os>; where <tag>`,
    'probability': `<O_Os> with probability <tag>`,
    'rate': `<O_Os> at a rate of <tag> <units>`,
    'path': `Following path <tag>, <opl>`,
    'range': `<r> <opl>`
  },
  'symbols': {
    '?': `an optional`,
    '*': `optional`,
    '+': `at least one`,
    'n..n': `<n1> to <n2>`,
    'n..*': `at least <n1>`,
    'n..mean..n': `<n1> to <n2>, with a mean of <mean>`
  },
  'ranges': {
    '<=': `less than or equal to`,
    '>=': 'more than or equal to',
    '=': 'equal to',
    '<>': 'not equal to',
    '<': `less than`,
    '>': 'more than'
  },
  'object': {
    'thing_generic_name': `object`,
    'default_essence_affiliation': ``,
    'digital_twin': `<TWIN> is the Digital Twin of <O>.`,
    'default_essence': `<O> is <a>.`,
    'default_affiliation': `<O> is <e>.`,
    'non_default': `<O> is <e> and <a>.`,
    'singleInzoom': `<O> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
    'singleInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>.`,
    'multiInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>, as well as <P_list>.`,
    'multiInzoom': `<O> from <SD_Parent> zooms in <Current_SD> into <O_list>, as well as <P_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<O> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<O> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <O_list>, as well as <P_list>.`,
    'single_unfold_instantiation': `<O> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    'object_list_sequence': `<O1...n>, in that vertical sequence`,
    'object_list_parallel': `<O1...n>, in that horizontal sequence`,
  },
  'process': {
    'thing_generic_name': `process`,
    'default_essence_affiliation': ``,
    'default_essence': `<P> is <a>.`,
    'default_affiliation': `<P> is <e>.`,
    'non_default': `<P> is <e> and <a>.`,
    'singleInzoom': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>, which occur in that time sequence.`,
    'singleInzoom_parallel': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
    'multiInzoom': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, which occur in that time sequence, as well as <O_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<P> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<P> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_instantiation': `<P> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    // 'process_list_parallel': `<P1...n>, in that horizontal sequence`,
    'process_list_parallel': `parallel <P1...n>`,
    // 'process_list_sequence': `<P1...n>, in that vertical sequence`,
    'process_list_sequence': `<P1...n>`,
    'expected_duration': `The expected duration of <P> is <exp> <units>.`,
    'min_duration': `The minimal duration of <P> is <min> <units>.`,
    'max_duration': `The maximal duration of <P> is <max> <units>.`,
    'min_max_range_duration': `The minimal duration and maximal duration of <P> are <min> <units> and <max> <units>, respectively.`,
    'min_exp_range_duration': `The minimal duration and expected duration of <P> are <min> <units> and <exp> <units>, respectively.`,
    'exp_max_range_duration': `The expected duration and maximal duration of <P> are <exp> <units> and <max> <units>, respectively.`,
    'expected_range_duration': `The minimal duration, expected duration, and maximal duration of <P> are <min> <units>, <exp> <units>, and <max> <units>, respectively.`,
    'default_time_units': `seconds`
  },
  'state': {
    'single_state': `<O> is <s>.`,
    'multiple_states': `<O> can be <s1...n>.`,
    'all_states_are_suppressed': `<O> is stateful.`,
    'one_state_shown_one_missing': `<O> is <s> or at one other state.`,
    'one_state_shown': `<O> is <s> and can be at one of <num> other states.`,
    'two_or_more_states_shown_one_missing': `<O> can be <s1...n> or at one other state.`,
    'two_or_more_states_shown': `<O> can be <s1...n> or at one of <num> other states.`,
    'default': `State <s> is default.`,
    'default_initial': `State <s> is default and initial.`,
    'initial': `State <s> is initial.`,
    'final': `State <s> is final.`,
    'default_initial_final': `State <s> is initial, final, and default.`,
    'initial_final': `State <s> is initial and final.`,
    'default_final': `State <s> is final and default.`,
    'none': ``,
    'Current': `<O> is currently at state <s>.`,
    // 'timeDurational': {
      'expected_duration': `The expected duration of <s> is <exp> <units>.`,
      'min_duration': `The minimal duration of <s> is <min> <units>.`,
      'max_duration': `The maximal duration of <s> is <max> <units>.`,
      'min_max_range_duration': `The minimal duration and maximal duration of <s> are <min> <units> and <max> <units>, respectively.`,
      'min_exp_range_duration': `The minimal duration and expected duration of <s> are <min> <units> and <exp> <units>, respectively.`,
      'exp_max_range_duration': `The expected duration and maximal duration of <s> are <exp> <units> and <max> <units>, respectively.`,
      'expected_range_duration': `The minimal duration, expected duration, and maximal duration of <s> are <min> <units>, <exp> <units>, and <max> <units>, respectively.`,
      'default_time_units': `seconds`
   // }
  },
  'essence': {
    'physical': `a physical`,
    'informatical': `an informatical`,
  },
  'affiliation': {
    'systemic': `systemic`,
    'environmental': `environmental`,
  },
  'semifolding': {
    'object': `<O> lists `,
    'process': `<P> lists `,
    'general': {
      'object': `<O> from <SD> is semi-folded in <HighestSD>`,
      'process': `<P> from <SD> is semi-folded in <HighestSD>`,
    },
    'aggregation': {
      'single': `<T> as a part`,
      'multiple': `<T1...n-1> and <Tn> as parts`,
    },
    'exhibition': {
      'single': `<T> as a feature`,
      'multiple': `<T1...n-1> and <Tn> as features`,
    },
    'generalization': {
      'single': `<T> as a specialization`,
      'multiple': `<T1...n-1> and <Tn> as specializations`,
    },
    'instantiation': {
      'single': `<T> as an instance`,
      'multiple': `<T1...n-1> and <Tn> as instances`,
    },
  },
  'hidden_attributes': {
    'requirement': `<set_object> of <owner> is <value>.`,
  },
  'father_model_to_sub_model': `The selected things, <o1...n>, and <p1...n> are refined in sub model <subsystem_name> subsystem model view.`,
  'sub_model_from_father_model': `The <subsystem_name> subsystem model view is derived from the <father_model_name> model.`
};

export const oplTemplates_ru = {
  'structural_link': {
    'Aggregation-Participation': `<T1> состоит из <T2>.`,
    'Aggregation-Participation_incomplete': `<T1> consists of <T2> and <num> more parts.`,
    'Aggregation-Participation_(multiple)': `<T1> состоит из <T2...n>.`,
    'Aggregation-Participation_incomplete_(multiple)': `<T1> consists of <T2...n> and <num> more parts.`,
    'Generalization-Specialization': `<T2> это <T1>.`,
    'Generalization-Specialization_incomplete': `<T2> and <num> more specializations are <T1>.`,
    'Generalization-Specialization_(multiple)': `<T2...n> это <T1>.`,
    'Generalization-Specialization_incomplete_(multiple)': `<T2...n> and <num> more specializations are <T1>.`,
    'Classification-Instantiation': `<T2> является экземпляром <T1>.`,
    'Classification-Instantiation_incomplete': `<T2> and <num> more instances are instances of <T1>.`,
    'Classification-Instantiation_(multiple)': `<T2...n> являются экземплярами <T1>.`,
    'Classification-Instantiation_incomplete_(multiple)': `<T2...n> and <num> more instances are instances of <T1>.`,
    'Exhibition-Characterization': `<T1> представляет <T2>.`,
    'Exhibition-Characterization_incomplete': `<T1> exhibits <T2> and <num> more attributes.`,
    'Exhibition-Characterization_(multiple)': `<T1> представляет <T2...n>.`,
    'Exhibition-Characterization_incomplete_(multiple)': `<T1> exhibits <T2...n> and <num> more attributes.`,
    'Unidirectional_Tagged_Link': `<T1> связан/а с <T2>.`,
    'Unidirectional_Tagged_Link_(tag)': `<T1> <tag> <T2>.`,
    'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
    'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
    'sequence': `in that sequence.`,
    'Bidirectional_Tagged_Link': `<T1> и <T2> эквивалентны.`,
    'Bidirectional_Tagged_Link_(tag)': `<T1> и <T2> это <tag>.`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<T1> <forward tag> <T2>, и <T2> <backward tag> <T1>.`,
  },
  'procedural_link': {
    'Agent': `<O_Os> обрабатывает <P>.`,
    'Agent_(multiple)': `<OOs1...n> задействует процесс <P>.`,
    'Agent_Condition': `<P> происходит, если <O> существует, иначе <P> пропускается.`,
    'Agent_Condition(multiple)': `<P> происходит, если <OOs1...n> существуют, иначе <P> пропускается.`,
    'Agent_Condition_state': `<P> происходит, если <O> это <s>, иначе <P> пропускается.`,
    'Agent_Event': `<O> активирует и задействует <P>.`,
    'Agent_Event(multiple)': `<OOs1...n> активируют и задействуют <P>.`,
    'Agent_Event_state': `<s> <O> активирует и задействует<P>.`,
    'Agent_Negation': `<P> происходит, если <O_Os> нет.`,
    'Agent_Negation_(multiple)': `<P> происходит, если <OOs1...n> нет`,
    'Agent_Condition_Negation': `<P> происходит, если <O> не существует, иначе <P> пропускается.`,
    'Agent_Condition_Negation(multiple)': `<P> происходит, если <OOs1...n> не существуют, иначе <P> пропускается.`,
    'Agent_Condition_Negation_state': `<P> происходит, если <O> не в состоянии <s>, иначе <P> пропускается.`,
    'Instrument': `<P> требует <O_Os>.`,
    'Instrument_(multiple)': `<P> требует <OOs1...n>.`,
    'Instrument_Condition': `<P> происходит, если <O> существует, иначе <P> пропускается.`,
    'Instrument_Condition(multiple)': `<P> происходит, если <OOs1...n> существуют, иначе <P> пропускается.`,
    'Instrument_Condition_state': `<P> происходит, если <O> в состоянии <s>, иначе <P> пропускается.`,
    'Instrument_Event': `<O> активирует <P>, который/ая требует <O>.`,
    'Instrument_Event(multiple)': `<OOs1...n> активируют <P>, который/ая необходим им.`,
    'Instrument_Event_state': `<s> <O> активирует <P>, который/ая необходим <s> <O>.`,
    'Instrument_Negation': `<P> происходит, если <O_Os> не существует.`,
    'Instrument_Negation_(multiple)': `<P> происходит, если <OOs1...n> не существует.`,
    'Instrument_Condition_Negation': `<P> происходит, если <O> не существует, иначе <P> пропускается.`,
    'Instrument_Condition_Negation(multiple)': `<P> происходит, если <OOs1...n> не существуют, иначе <P> пропускается.`,
    'Instrument_Condition_Negation_state': `<P> происходит, если <O> не в состоянии <s>, иначе <P> пропускается.`,
    'Effect': `<P> влияет на <O_Os>.`,
    'Effect_(multiple)': `<P> влияет на <OOs1...n>.`,
    'Effect_Condition': `<P> происходит, если <O> существует, в этом случае <P> влияет на <O>, иначе <P> пропускается.`,
    'Effect_Condition(multiple)': `<P> происходит, если <OOs1...n> существует, в этом случае <P> влияет на них, иначе <P> пропускается.`,
    'Effect_Condition_state': `<P> происходит, если <O> в состоянии <s>, в этом случае <P> влияет на <s> <O>, иначе <P> пропускается.`,
    'Effect_Event': `<O> активирует <P>, который/ая влияет на <O>`,
    'Effect_Event(multiple)': `<OOs1...n> активируют <P>, который/ая влияет на <O>.`,
    'Effect_Event_state': `<s> <O> активирует <P>, который/ая влияет на <s> <O>`,
    'Effect_Negation': `<P> не влияет на <O_Os>.`,
    'Effect_Negation_(multiple)': `<P> не влияет на <OOs1...n>.`,
    'Effect_Condition_Negation': `<P> происходит, если <O> не существует, в этом случае <P> влияет на <O>, иначе <P> пропускается.`,
    'Effect_Condition_Negation(multiple)': `<P> происходит, если <OOs1...n> не существуют, в этом случае <P> влияет на них, иначе <P> пропускается.`,
    'Effect_Condition_Negation_state': `<P> происходит, если <O> не в состоянии <s>, в этом случае <P> влияет на <s> <O>, иначе <P> пропускается.`,
    'Consumption': `<P> потребляет <O_Os>.`,
    'Consumption_(multiple)': `<P> потребляет <OOs1...n>.`,
    'Consumption_Condition': `<P> происходит, если <O> существует, в этом случае <P> потребляет <O>, иначе <P> пропускается.`,
    'Consumption_Condition(multiple)': `<P> происходит, если <OOs1...n> существуют, в этом случае <P> потребляет их, иначе <P> пропускается.`,
    'Consumption_Condition_state': `<P> происходит, если <O> в состоянии <s>, в этом случае <P> потребляет <s> <O>, иначе <P> пропускается.`,
    'Consumption_Event': `<O> активирует <P>, который/ая потребляет <O>.`,
    'Consumption_Event(multiple)': `<OOs1...n> активируют <P>, который/ая потребляет их.`,
    'Consumption_Event_state': `<s> <O> активирует <P>, который/ая потребляет <O>.`,
    'Consumption_Negation': `<P> не потребляет <O_Os>.`,
    'Consumption_Negation_(multiple)': `<P> не потребляет <OOs1...n>.`,
    'Consumption_Condition_Negation': `<P> происходит, если <O> не существует, в этом случае <P> потребляет <O>, иначе <P> пропускается.`,
    'Consumption_Condition_Negation(multiple)': `<P> происходит, если <OOs1...n> не существует, в этом случае <P> потребляет их, иначе <P> пропускается.`,
    'Consumption_Condition_Negation_state': `<P> происходит, если <O> не в состоянии <s>, в этом случае <P> потребляет <s> <O>, иначе <P> пропускается.`,
    'Result': `<P> производит <O_Os>.`,
    'Result_(multiple)': `<P> производит <OOs1...n>.`,
    'In-out_Link_Pair': `<P> меняет <O> от <s1> к <s2>.`,
    'In-out(group)': `<O> от <s1> к <s2>`,
    'In-out_Link_Pair(group)': `<P> меняет <O> от <s1> к <s2><Other_changes>.`,
    'Split': `любое другое состояние`,
    'Condition_Input': `<P> происходит, если <O> в состоянии <s1>, в этом случае <P> меняет <O> от <s1> к <s2>, иначе <P> пропускается.`,
    'In-out_Link_Pair_Condition': `<P> происходит, если <O> в состоянии <s1>, в этом случае <P> меняет <O> от <s1> к <s2>, иначе <P> пропускается.`,
    'In-out_Link_Pair_Condition(group)': `<P> происходит, если <O> в состоянии <s1>, в этом случае <P> меняет <O> от <s1> к <s2>, иначе <P> пропускается.`,
    'In-out_Link_Pair_Event': `<O> в состоянии <s1> активирует <P>, который/ая меняет <O> от <s1> к <s2>.`,
    'In-out_Link_Pair_Event(group)': `<O> в состоянии <s1> активирует <P>, который/ая меняет <O> от <s1> к <s2>.`,
    'Overtime_exception': `<O> запускает <P> когда <O> в состоянии <s> более чем <maxtime> <units>.`,
    'Undertime_exception': `<O> запускает <P> когда <O> в состоянии <s> менее чем <mintime> <units>.`,
    'OvertimeUndertime-exception': `<O> запускает <P> когда <O> в состоянии <s> более чем <maxtime> <units> и менее чем <mintime> <units>.`,
    'Overtime_exception_(process)': `<P2> происходит, если <P1> длится более, чем <maxtime> <units>.`,
    'Undertime_exception_(process)': `<P2> происходит, если <P1> менее, чем <mintime> <units>.`,
    'OvertimeUndertime-exception_(process)': `<P2> происходит, если <P1> менее, чем <mintime> <units> или длится более, чем <maxtime> <units>.`,
    'Invocation': `<P1> вызывает <P2>.`,
    'Invocation_(multiple)': `<P1> вызывает <P2...n>.`,
    'Invocation_(self)': `<P1> вызывает сам/у себя.`,
    'Invocation_(parent)': `<P1> вызывает <P2>.`,
  },
  'grouping': {
    'Single-Thing': `<T>`,
    'Multiple-Things': `<T1...n-1> и <Tn>`,
    'Multiple-Things-Object-Process-Separated': `<O1...n>, так же как и <P1...n>`,
    'AND': `<T1...n-1> и <Tn>`,
    'OR': `<T1...n-1> или <Tn>`,
    'XOR': `<T1> xor <T2>`,
    'Stateful-Object': `<O> в состоянии <s>`,
    'Stateful-Object-value': `<O> со значением <s>`,
    'Stateful-Object-value(multiple)': `<O> со значениями <s1...n>`,
    'Stateful-Object-unidirectional': `состояние <s> <O>`,
    'Stateful-Object(multiple)': `<O> в состояниях <s1...n>`,
    'Attribute-Exhibitor': `<T> <e1...n>`,
    'indentation': `&nbsp;&nbsp;&nbsp;&nbsp;`,
    'Multiple-InOut': `<Other_changes> и <change>.`,
  },
  'logic_operators': {
    'XOR': {
      'process': {
        'Result': `<P> производит ровно один из <O_Os1...n>.`,
        'Result(brothers)': `<P> производит <O> в одном из состояний <s1...n>.`,
        'Consumption': `<P> потребляет ровно один из <O_Os1...n>.`,
        'Consumption(brothers)': `<P> потребляет <O> в одном из состояний <s1...n>.`,
        'Consumption_Condition': `<P> происходит, если ровно один из <O_Os1...n> существует, в этом случае <P> потребляет его, иначе <P> пропускается.`,
        'Consumption_Condition(brothers)': `<P> происходит, если <O> в одном из состояний <s1...n>, в этом случае <P> потребляет его, иначе <P> пропускается.`,
        'Consumption_Event': `Ровно один из <O_Os1...n> активирует <P>, который/ая потребляет его.`,
        'Consumption_Event(brothers)': `<O> активирует <P> когда он в одном из состояний <s1...n>, который/ая потребляет его.`,
        'Consumption_Negation': `<P> не потребляет ровно один из <O_Os1...n>.`,
        'Consumption_Negation(brothers)': `<P> не потребляет <O> в одном из состояний <s1...n>.`,
        'Consumption_Condition_Negation': `<P> происходит, если ровно один из <O_Os1...n> не существует, в этом случае <P> потребляет существующий, иначе <P> пропускается.`,
        'Consumption_Condition_Negation(brothers)': `<P> происходит, если <O> ни в одном из состояний <s1...n>, в этом случае <P> потребляет его, иначе <P> пропускается.`,
        'Effect': `<P> влияет на ровно на один <O_Os1...n>.`,
        'Effect(brothers)': `<P> влияет на <O> в одном из состояний <s1...n>.`,
        'Effect_Condition': `<P> происходит, если ровно один из <O_Os1...n> существует, в этом случае <P> влияет на него, иначе <P> пропускается.`,
        'Effect_Condition(brothers)': `<P> происходит, если <O> в одном из состояний <s1...n>, в этом случае <P> влияет на него, иначе <P> пропускается.`,
        'Effect_Event': `Ровно один из <O_Os1...n> активирует <P>, который/ая влияет на него.`,
        'Effect_Event(brothers)': `<O> активирует <P> когда он в одном из состояний <s1...n>, который/ая влияет на него.`,
        'Effect_Negation': `<P> не влияет на ровно на один из <O_Os1...n>.`,
        'Effect_Negation(brothers)': `<P> не влияет на <O> в одном из состояний <s1...n>.`,
        'Effect_Condition_Negation': `<P> происходит, если ровно один из <O_Os1...n> не существует, в этом случае <P> влияет на всех остальных, иначе <P> пропускается.`,
        'Effect_Condition_Negation(brothers)': `<P> происходит, если <O> ни в одном из состояний <s1...n>, в этом случае <P> влияет на всех остальных, иначе <P> пропускается.`,
        'Agent': `Ровно один из <O_Os1...n> задействует <P>.`,
        'Agent(brothers)': `<O> задействует <P> когда он в одном из состояний <s1...n>.`,
        'Agent_Event': `Ровно один из <O_Os1...n> активирует и обрабатывает <P>.`,
        'Agent_Event(brothers)': `<O> активирует и задействует <P> когда он в одном из состояний <s1...n>.`,
        'Agent_Condition': `<P> происходит, если ровно один из <O_Os1...n> существует, иначе <P> пропускается.`,
        'Agent_Condition(brothers)': `<P> происходит, если <O> в одном из состояний <s1...n>, иначе <P> пропускается.`,
        'Agent_Negation': `Ровно один из <O_Os1...n> не задействует <P>.`,
        'Agent_Negation(brothers)': `<O> не задействует <P> когда он в одном из состояний <s1...n>.`,
        'Agent_Condition_Negation': `<P> происходит, если ровно один из <O_Os1...n> не существует, иначе <P> пропускается.`,
        'Agent_Condition_Negation(brothers)': `<P> происходит, если <O> ни в одном из состояний <s1...n>, иначе <P> пропускается.`,
        'Instrument': `<P> требует ровно один из <O_Os1...n>.`,
        'Instrument(brothers)': `<P> требует <O> в одном из состояний <s1...n>.`,
        'Instrument_Condition': `<P> происходит, если ровно один из <O_Os1...n> существует, иначе <P> пропускается.`,
        'Instrument_Condition(brothers)': `<P> происходит, если <O> в одном из состояний <s1...n>, иначе <P> пропускается.`,
        'Instrument_Event': `Ровно один из <O_Os1...n> активирует <P>, который/ая это требует.`,
        'Instrument_Event(brothers)': `<O> активирует <P> когда он в одном из состояний <s1...n>, который/ая это требует.`,
        'Instrument_Negation': `<P> не требует ровно один из <O_Os1...n>.`,
        'Instrument_Negation(brothers)': `<P> не требует <O> в одном из состояний <s1...n>.`,
        'Instrument_Condition_Negation': `<P> происходит, если ровно один из <O_Os1...n> не существует, иначе <P> пропускается.`,
        'Instrument_Condition_Negation(brothers)': `<P> происходит, если <O> ни в одном из состояний <s1...n>, иначе <P> пропускается.`,
        'Invocation_OUT': `<P> вызывает какой-либо <P1...n>.`,
        'Invocation_IN': `Какой-либо <P1...n> вызывает <P>.`,
      },
      'object': {
        'Result': `Ровно один из <P1...n> производит <O_Os>.`,
        'Consumption': `Ровно один из <P1...n> потребляет <O_Os>`,
        'Consumption_Event': `<O_Os> активирует ровно один из <P1...n>, который/ая заканчивает начатый процесс.`,
        'Consumption_Condition_state': `Ровно один из <P1...n> происходит, если <O> в <s1>, в этом случае происходящий процесс потребляет <O>, иначе эти процессы пропускаются.`,
        'Consumption_Condition': `Ровно один из <P1...n> происходит, если <O_Os> существует, в этом случае происходящий процесс потребляет <O_Os>, иначе эти процессы пропускаются.`,
        'Consumption_Negation': `Ровно один из <P1...n> не потребляет <O_Os>`,
        'Consumption_Condition_Negation_state': `Ровно один из <P1...n> происходит, если <O> не в состоянии <s1>, в этом случае тот процесс потребляет <O>, иначе все эти процессы пропускаются.`,
        'Consumption_Condition_Negation': `Ровно один из <P1...n> происходит, если <O_Os> не существует, в этом случае тот процесс потребляет <O_Os>, иначе все эти процессы пропускаются.`,
        'Effect': `Ровно один из <P1...n> влияет на <O_Os>.`,
        'Effect_Event': `<O_Os> активирует ровно один из <P1...n>, который/ая влияет на происходящий процесс.`,
        'Effect_Condition': `Ровно один из <P1...n> происходит, если <O_Os> существует, в этом случае происходящий процесс влияет на <O_Os>, иначе эти процессы пропускаются.`,
        'Effect_Negation': `<P1...n> не влияют на ровно один из <O_Os>.`,
        'Effect_Condition_Negation': `<P1...n> происходит, если ровно один из <O_Os> не существует, в этом случае <P1...n> влияет на все остальные, иначе <P1...n> пропускаются.`,
        'Agent': `<O_Os> обрабатывает ровно один из <P1...n>.`,
        'Agent_Event': `<O_Os> активирует и задействует ровно один из <P1...n>.`,
        'Agent_Condition': `<O_Os> обрабатывает ровно один из <P1...n> если <O_Os> существует, иначе эти процессы пропускаются.`,
        'Agent_Condition_state': `<O> обрабатывает ровно один из <P1...n> если <O> в <s1>, иначе эти процессы пропускаются.`,
        'Agent_Negation': `Ровно один из <P1...n> происходит, если <O_Os> не существует.`,
        'Agent_Condition_Negation': `Ровно один из <P1...n> происходит, если <O_Os> не существуют, иначе все эти процессы пропускаются.`,
        'Agent_Condition_Negation_state': `Ровно один из <P1...n> происходит, если <O> не в состоянии <s1>, иначе все эти процессы пропускаются.`,
        'Instrument': `Ровно один из <P1...n> требует <O_Os>.`,
        'Instrument_Event': `<O_Os> активирует ровно один из <P1...n>, который/ая требует <O_Os>.`,
        'Instrument_Condition': `Ровно один из <P1...n> требует <O_Os>, иначе эти процессы пропускаются.`,
        'Instrument_Condition_state': `Ровно один из <P1...n> требует чтобы <O> в <s1>, иначе эти процессы пропускаются.`,
        'Instrument_Negation': `Ровно один из <P1...n> происходит, если <O_Os> не существует.`,
        'Instrument_Condition_Negation': `Ровно один из <P1...n> происходит, если <O_Os> не существует, иначе все эти процессы пропускаются.`,
        'Instrument_Condition_Negation_state': `Ровно один из <P1...n> происходит, если <O> не в состоянии <s1>, иначе все эти процессы пропускаются.`,
      }
    },
    'OR': {
      'process': {
        'Result': `<P> производит по меньшей мере один из <O_Os1...n>.`,
        'Consumption': `<P> потребляет по меньшей мере один из <O_Os1...n>.`,
        'Consumption_Condition': `<P> происходит, если по меньшей мере один из <O_Os1...n> существует, в этом случае <P> потребляет существующие, иначе <P> пропускается.`,
        'Consumption_Event': `По меньшей мере один из <O_Os1...n> активирует <P>, который/ая потребляет его.`,
        'Consumption_Negation': `<P> не потребляет по меньшей мере один из <O_Os1...n>.`,
        'Consumption_Condition_Negation': `<P> происходит, если по меньшей мере один из <O_Os1...n> не существует, в этом случае <P> потребляет существующие, иначе <P> пропускается.`,
        'Effect': `<P> влияет на по меньшей мере на один из <O_Os1...n>.`,
        'Effect_Condition': `<P> происходит, если по меньшей мере один из <O_Os1...n> существует, в этом случае <P> влияет на него, иначе <P> пропускается.`,
        'Effect_Event': `По меньшей мере один из <O_Os1...n> активирует <P>, который/ая влияет на него.`,
        'Effect_Negation': `<P> не влияет на по меньшей мере на один из <O_Os1...n>.`,
        'Effect_Negation(brothers)': `<P> не влияет на <O> по меньшей мере в одном из состояний <s1...n>.`,
        'Effect_Condition_Negation': `<P> происходит, если по меньшей мере один из <O_Os1...n> не существует, в этом случае <P> влияет на всех других, иначе <P> пропускается.`,
        'Effect_Condition_Negation(brothers)': `<P> происходит, если <O> в по меньшей мере ни в одном из состояний <s1...n>, в этом случае <P> влияет на всех других, иначе <P> пропускается.`,
        'Agent': `По меньшей мере один из <O_Os1...n> обрабатывает <P>.`,
        'Agent_Event': `По меньшей мере один из <O_Os1...n> активирует и обрабатывает <P>.`,
        'Agent_Condition': `<P> происходит, если по меньшей мере один из <O_Os1...n> существует, иначе <P> пропускается.`,
        'Agent_Negation': `По меньшей мере один из <O_Os1...n> не обрабатывает <P>.`,
        'Agent_Condition_Negation': `<P> происходит, если по меньшей мере один из <O_Os1...n> не существует, иначе <P> пропускается.`,
        'Instrument': `<P> требует по меньшей мере один из <O_Os1...n>.`,
        'Instrument_Condition': `<P> происходит, если по меньшей мере один из <O_Os1...n> существует, иначе <P> пропускается.`,
        'Instrument_Event': `По меньшей мере один из <O_Os1...n> активирует <P>, который/ая требует этого.`,
        'Instrument_Negation': `<P> не требует по меньшей мере один из <O_Os1...n>.`,
        'Instrument_Condition_Negation': `<P> происходит, если по меньшей мере один из <O_Os1...n> не существует, иначе <P> iпропускается.`,
        'Invocation_OUT': `<P> запускает по меньшей мере один из <P1...n>.`,
        'Invocation_IN': `По меньшей мере один из <P1...n> запускает <P>.`,
      },
      'object': {
        'Result': `По меньшей мере один из <P1...n> производит <O_Os>`,
        'Consumption': `По меньшей мере один из <P1...n> потребляет <O_Os>`,
        'Consumption_Event': `<O_Os> активирует по меньшей мере один из <P1...n>, который/ая потребляет инициируемый процесс.`,
        'Consumption_Condition_state': `По меньшей мере один из <P1...n> происходит, если <O> is <s1>, в этом случае текущий процесс потребляет <O>, иначе эти процессы пропускаются.`,
        'Consumption_Condition': `По меньшей мере один из <P1...n> происходит, если <O_Os> существует, в этом случае текущий процесс потребляет <O_Os>, иначе эти процессы пропускаются.`,
        'Consumption_Negation': `По меньшей мере один из <P1...n> не потребляет <O_Os>`,
        'Consumption_Condition_Negation_state': `По меньшей мере один из <P1...n> происходит, если <O> не в состоянии <s1>, в этом случае тот процесс потребляет <O>, иначе все эти процессы пропускаются.`,
        'Consumption_Condition_Negation': `По меньшей мере один из <P1...n> происходит, если <O_Os> не существует, в этом случае тот процесс потребляет <O_Os>, иначе все эти процессы пропускаются.`,
        'Effect': `По меньшей мере один из <P1...n> влияет на <O_Os>`,
        'Effect_Event': `<O_Os> активирует по меньшей мере один из <P1...n>, который/ая влияет на происходящий процесс.`,
        'Effect_Condition': `По меньшей мере один из <P1...n> происходит, если <O_Os> существует, в этом случае происходящий процесс влияет на <O_Os>, иначе эти процессы пропускаются.`,
        'Effect_Negation': `<P1...n> не влияет на по ме мере один из <O_Os>.`,
        'Effect_Condition_Negation': `<P1...n> происходит, если один из <O_Os> не существует, в этом случае <P1...n> aвлияет на все другие, иначе <P1...n> пропускаются.`,
        'Agent': `<O_Os> задействует по меньшей мере один из <P1...n>.`,
        'Agent_Event': `<O_Os> активирует и задействует по меньшей мере один из <P1...n>.`,
        'Agent_Condition': `<O_Os> задействует по меньшей мере один из <P1...n> если <O_Os> существует, иначе эти процессы пропускаются.`,
        'Agent_Condition_state': `<O> задействует по меньшей мере один из <P1...n> если <O> в <s1>, иначе эти процессы пропускаются.`,
        'Agent_Negation': `По меньшей мере один из <P1...n> происходит, если <O_Os> не представлен.`,
        'Agent_Condition_Negation': `По меньшей мере один из <P1...n> происходит, если <O_Os> не представлен, иначе все эти процессы пропускаются.`,
        'Agent_Condition_Negation_state': `По меньшей мере один из <P1...n> происходит, если <O> не в состоянии <s1>, иначе все эти процессы пропускаются.`,
        'Instrument': `По меньшей мере один из <P1...n> требуется <O_Os>`,
        'Instrument_Event': `<O_Os> активирует at least one of <P1...n>, который/ая требуется <O_Os>`,
        'Instrument_Condition': `По меньшей мере один из <P1...n> требуется <O_Os>, иначе эти процессы пропускаются.`,
        'Instrument_Condition_state': `По меньшей мере один из <P1...n> требует, чтобы <O> is <s1>, иначе эти процессы пропускаются.`,
        'Instrument_Negation': `По меньшей мере один из <P1...n> происходит, если <O_Os> не существует.`,
        'Instrument_Condition_Negation': `По меньшей мере один из <P1...n> происходит, если <O_Os> не существует, иначе все эти процессы пропускаются.`,
        'Instrument_Condition_Negation_state': `По меньшей мере один из <P1...n> происходит, если <O> не в состоянии <s1>, иначе все эти процессы пропускаются.`,
      }
    },
    'NOT': {
      'process': {
        'Agent_Negation': `<O_Os1...n> не задействует <P>.`,
        'Agent_Condition_Negation': `<P> происходит, если <O_Os1...n> не существует, иначе <P> пропускается.`,
        'Instrument_Negation': `<P> не требует <O_Os1...n>.`,
        'Instrument_Condition_Negation': `<P> происходит, если <O_Os1...n> не существует, иначе <P> пропускается.`,
        'Consumption_Negation': `<P> не потребляет <O_Os1...n>.`,
        'Consumption_Condition_Negation': `<P> происходит, если <O_Os1...n> не существует, в этом случае <P> потребляет существующие, иначе <P> пропускается.`,
        'Effect_Negation': `<P> не влияет на <O_Os1...n>.`,
        'Effect_Condition_Negation': `<P> происходит, если <O_Os1...n> не существует, в этом случае <P> влияет на все остальные, иначе <P> пропускается.`,
      },
      'object': {
        'Agent_Negation': `<P1...n> происходит, если <O_Os> не существует.`,
        'Agent_Condition_Negation': `<P1...n> происходит, если <O_Os> не существует, иначе все эти процессы пропускаются.`,
        'Agent_Condition_Negation_state': `<P1...n> происходит, если <O> не в состоянии <s1>, иначе все эти процессы пропускаются.`,
        'Instrument_Negation': `<P1...n> происходит, если <O_Os> не существует.`,
        'Instrument_Condition_Negation': `<P1...n> происходит, если <O_Os> не существует, иначе все эти процессы пропускаются.`,
        'Instrument_Condition_Negation_state': `<P1...n> происходит, если <O> не в состоянии <s1>, иначе все эти процессы пропускаются.`,
        'Consumption_Negation': `<P1...n> не потребляет <O_Os>`,
        'Consumption_Condition_Negation_state': `<P1...n> происходит, если <O> не в состоянии <s1>, в этом случае тот процесс потребляет <O>, иначе все эти процессы пропускаются.`,
        'Consumption_Condition_Negation': `<P1...n> происходит, если <O_Os> не существует, в этом случае тот процесс потребляет <O_Os>, иначе все эти процессы пропускаются.`,
        'Effect_Negation': `<P1...n> не имеют эффекта на <O_Os>.`,
        'Effect_Condition_Negation': `<P1...n> происходит, если <O_Os> не существует, в этом случае <P1...n> влияет на всех других, иначе <P1...n> пропускаются.`,
      }
    }
  },
  'tags': {
    'multiplicity': `<tag> <O_Os>`,
    'constraints': `<O_Os>; где <tag>`,
    'probability': `<O_Os> с вероятностью <tag>`,
    'rate': `<O_Os> в темпе <tag>`,
    'path': `По пути <tag>, <opl>`,
    'range': `<r> <opl>`,
  },
  'symbols': {
    '?': `опциональный/ая`,
    '*': `опционально`,
    '+': `по меньшей мере один`,
    'n..n': `<n1> до <n2>`,
    'n..*': `<n1> до много`,
    'n..mean..n': `<n1> до <n2>, со среднем значением <mean>`,
  },
  'ranges': {
    '<=': `менее чем или равен`,
    '>=': `более чем или равен`,
    '=': `равен`,
    '<>': `не равен`,
    '<': `менее чем`,
    '>': `более чем`,
  },
  'object': {
    'default_essence_affiliation': ``,
    'digital_twin': `<TWIN> is the Digital Twin of <O>.`,
    'default_essence': `<O> является <a>.`,
    'default_affiliation': `<O> является <e>.`,
    'non_default': `<O> является <e> и <a>.`,
    'singleInzoom': `<O> из <SD_Parent> зуммирует <Current_SD> в <T_list>.`,
    'multiInzoom': `<O> из <SD_Parent> зуммирует <Current_SD> в <O_list>, так же как и <P_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'object_list_sequence': `<O1...n>, в этой вертикальной последовательности`,
    'object_list_parallel': `<O1...n>, в этой горизонтальной последовательности`,
  },
  'process': {
    'default_essence_affiliation': ``,
    'default_essence': `<P> является <a>.`,
    'default_affiliation': `<P> является <e>.`,
    'non_default': `<P> является <e> и <a>.`,
    'singleInzoom': `<P> из <SD_Parent> зуммирует <Current_SD> в <T_list>, который/ая происходит в этой временной последовательности.`,
    'singleInzoom_parallel': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
    'multiInzoom': `<P> из <SD_Parent> зуммирует <Current_SD> в <P_list>, так же как и <O_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'process_list_parallel': `параллельно <P1...n>`,
    'process_list_sequence': `<P1...n>`,
    'expected_duration': `Ожидаемая продолжительность <P> будет <exp> <units>.`,
    'min_duration': `Минимальная продолжительность <P> будет <min> <units>.`,
    'max_duration': `Максимальная продолжительность <P> будет <max> <units>.`,
    'min_max_range_duration': `Минимальная и максимальная продолжительность <P> будут <min> <units> и <max> <units>, соответственно.`,
    'min_exp_range_duration': `Минимальная и ожидаемая продолжительности <P> будут <min> <units> и <exp> <units>, соответственно.`,
    'exp_max_range_duration': `Ожидаемая и максимальная продолжительности <P> будут <exp> <units> и <max> <units>, соответственно.`,
    'expected_range_duration': `Минимальная, ожидаемая, и максимальная продолжительности <P> будут <min> <units>, <exp> <units>, и <max> <units>, соответственно.`,
    'default_time_units': `секунды`,
  },
  'state': {
    'single_state': `<O> является <s>.`,
    'multiple_states': `<O> может быть <s1...n>.`,
    'all_states_are_suppressed': `<O> is stateful.`,
    'one_state_shown_one_missing': `<O> is <s> or at one other state.`,
    'one_state_shown': `<O> is <s> and can be at one of <num> other states.`,
    'two_or_more_states_shown_one_missing': `<O> can be <s1...n> or at one other state.`,
    'two_or_more_states_shown': `<O> can be <s1...n> or at one of <num> other states.`,
    'default': `Состояние <s> по умолчанию.`,
    'default_initial': `Состояние <s> по умолчанию и начальное.`,
    'initial': `Состояние <s> начальное.`,
    'final': `Состояние <s> конечное.`,
    'default_initial_final': `Состояние <s> начальное, конечное, и по умолчанию.`,
    'initial_final': `Состояние <s> начальное и конечное.`,
    'default_final': `Состояние <s> начальное и по умолчанию.`,
    'none': ``,
    'Current': `<O> находится в состоянии <s>.`,
    'expected_duration': `Ожидаемая продолжительность <s> будет <exp> <units>.`,
    'min_duration': `Минимальная продолжительность <s> будет <min> <units>.`,
    'max_duration': `Максимальная продолжительность <s> будет <max> <units>.`,
    'min_max_range_duration': `Минимальная и максимальная продолжительности <s> будут <min> <units> и <max> <units>, соответственно.`,
    'min_exp_range_duration': `Минимальная и ожидаемая продолжительности <s> будут <min> <units> и <exp> <units>, соответственно.`,
    'exp_max_range_duration': `Ожидаемая и максимальная продолжительности <s> будут <exp> <units> и <max> <units>, соответственно.`,
    'expected_range_duration': `Минимальная, ожидаемая, и максимальная продолжительности <s> будут <min> <units>, <exp> <units>, и <max> <units>, соответственно.`,
    'default_time_units': `секунды`,
  },
  'essence': {
    'physical': `физическим/ой`,
    'informatical': `информативным/ой`,
  },
  'affiliation': {
    'systemic': `системным/ой`,
    'environmental': `внешним/ей`,
  },
  'semifolding': {
    'object': `<O> перечисляет `,
    'process': `<P> перечисляет `,
    'general': {
      'object': `<O> из <SD> полусложен/а в <HighestSD>`,
      'process': `<P> из <SD> полусложен/а в <HighestSD>`,
    },
    'aggregation': {
      'single': `<T> как часть`,
      'multiple': `<T1...n-1> и <Tn> как части`,
    },
    'exhibition': {
      'single': `<T> как характеристика`,
      'multiple': `<T1...n-1> и <Tn> как характеристики`,
    },
    'generalization': {
      'single': `<T> как специализация`,
      'multiple': `<T1...n-1> и <Tn> как специализации`,
    },
    'instantiation': {
      'single': `<T> как экземпляр`,
      'multiple': `<T1...n-1> и <Tn> экземпляры`,
    }
  },
  'hidden_attributes': {
    'requirement': `<set_object> of <owner> is <value>.`,
  },
  'father_model_to_sub_model': `The selected things, <o1...n>, and <p1...n> are refined in sub model <subsystem_name> subsystem model view.`,
  'sub_model_from_father_model': `The <subsystem_name> subsystem model view is derived from the <father_model_name> model.`
};

export const oplTemplates_portuguese = {
  'structural_link': {
    /*
     * <T1> - source OPM Thing
     * <T2> - target OPM Thing
     * <T2...n> - set of target OPM Things
     */
    'Aggregation-Participation': `<T1> consiste de <T2>.`,
      'Aggregation-Participation_incomplete': `<T1> consiste de <T2> e <num> outras partes.`,
      'Aggregation-Participation_(multiple)': `<T1> consiste de <T2...n>.`,
      'Aggregation-Participation_incomplete_(multiple)': `<T1> consiste de <T2...n> e <num> outras partes.`,
      'Generalization-Specialization': `<T2> é um(a) <T1>.`,
      'Generalization-Specialization_incomplete': `<T2> e <num> outras especializações são <T1>.`,
      'Generalization-Specialization_(multiple)': `<T2...n> são <T1>.`,
      'Generalization-Specialization_incomplete_(multiple)': `<T2...n> e <num> outras especializações são <T1>.`,
      'Classification-Instantiation': `<T2> é uma instância de <T1>.`,
      'Classification-Instantiation_incomplete': `<T2> e <num> outras instâncias são instâncias de <T1>.`,
      'Classification-Instantiation_(multiple)': `<T2...n> são instâncias de <T1>.`,
      'Classification-Instantiation_incomplete_(multiple)': `<T2...n> e <num> outras instâncias são instâncias de <T1>.`,
      'Exhibition-Characterization': `<T1> exibe <T2>.`,
      'Exhibition-Characterization_incomplete': `<T1> exibe <T2> e <num> outras características.`,
      'Exhibition-Characterization_(multiple)': `<T1> exibe <T2...n>.`,
      'Exhibition-Characterization_incomplete_(multiple)': `<T1> exibe <T2...n> e <num> outras características.`,
      'Unidirectional_Tagged_Link': `<T1> relaciona-se com <T2>.`,
      'Unidirectional_Tagged_Link_(tag)': `<T1> <tag> <T2>.`,
      'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
      'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
      'sequence': `in that sequence.`,
      'Bidirectional_Tagged_Link': `<T1> e <T2> são equivalentes.`,
      'Bidirectional_Tagged_Link_(tag)': `<T1> e <T2> são <tag>.`,
      'Bidirectional_Tagged_Link_(ftag,btag)': `<T1> <forward tag> <T2>, e <T2> <backward tag> <T1>.`,
  },
  'procedural_link': {
    /*
     * <O_Os> - OPM Object or OPM Stateful Object
     * <O_Os1...n> - set of OPM Objects or OPM Stateful Objects
     * <s> - OPM State
     * <P> - OPM Process
     */
    'Agent': `<O_Os> realiza o processo de <P>.`,
      'Agent_(multiple)': `<O\Os1...n> realizam o processo de <P>.`,
      'Agent_Condition': `<P> ocorre se existir <O>, caso contrário o processo de <P> é ignorado.`,
      'Agent_Condition(multiple)': `<P> ocorre se <O\Os1...n> existirem, caso contrário o processo de <P> é ignorado.`,
      'Agent_Condition_state': `<P> ocorre se <O> for/estiver <s>, caso contrário o processo de <P> é ignorado.`,
      'Agent_Event': `<O> desencadeia e realiza o processo de <P>.`,
      'Agent_Event(multiple)': `<O\Os1...n> desencadeiam e realizam o processo de <P>.`,
      'Agent_Event_state': `<O> <s> desencadeia e realiza o processo de <P>.`,

      'Agent_Negation': `<P> ocorre se <O_Os> não estiver presente.`,
      'Agent_Negation_(multiple)': `<P> ocorre se <O\Os1...n> não estiverem presentes.`,
      'Agent_Condition_Negation': `<P> ocorre se não existir <O>, caso contrário o processo de <P> é ignorado.`,
      'Agent_Condition_Negation(multiple)': `<P> ocorre se <O\Os1...n> não existirem, caso contrário o processo de <P> é ignorado.`,
      'Agent_Condition_Negation_state': `<P> ocorre se <O> não for/estiver <s>, caso contrário o processo de <P> é ignorado.`,

      'Instrument': `<P> requer <O_Os>.`,
      'Instrument_(multiple)': `<P> requer <O\Os1...n>.`,
      'Instrument_Condition': `<P> ocorre se existir <O>, caso contrário o processo de <P> é ignorado.`,
      'Instrument_Condition(multiple)': `<P> ocorre se <O\Os1...n> existirem, caso contrário o processo de <P> é ignorado.`,
      'Instrument_Condition_state': `<P> ocorre se <O> for/estiver <s>, caso contrário o processo de <P> é ignorado.`,
      'Instrument_Event': `<O> desencadeia o processo de <P>, o qual requer o(a) <O>.`,
      'Instrument_Event(multiple)': `<O\Os1...n> desencadeiam o processo de <P>, o qual os(as) requer.`,
      'Instrument_Event_state': `<O> <s> desencadeira o processo de <P>, o qual requer o(a) <O> <s>.`,

      'Instrument_Negation': `<P> ocorre se não existir <O_Os>.`,
      'Instrument_Negation_(multiple)': `<P> ocorre se <O\Os1...n> não existirem.`,
      'Instrument_Condition_Negation': `<P> ocorre se não existir <O>, caso contrário o processo de <P> é ignorado.`,
      'Instrument_Condition_Negation(multiple)': `<P> ocorre se <O\Os1...n> não existirem, caso contrário o processo de <P> é ignorado.`,
      'Instrument_Condition_Negation_state': `<P> ocorre se <O> não for/estiver <s>, caso contrário o processo de <P> é ignorado.`,

      'Effect': `<P> afeta <O_Os>.`,
      'Effect_(multiple)': `<P> afeta <O\Os1...n>.`,
      'Effect_Condition': `<P> ocorre se existir <O>, condição na qual o processo de <P> afeta o(a) <O>, caso contrário o processo é ignorado.`,
      'Effect_Condition(multiple)': `<P> ocorre se <O\Os1...n> existirem, condição na qual o processo de <P> os(as) afeta, caso contrário o processo é ignorado.`,
      'Effect_Condition_state': `<P> ocorre se <O> for/estiver <s>, condição na qual o processo de <P> afeta o(a) <O> <s>, caso contrário o processo é ignorado.`,
      'Effect_Event': `<O> desencadeia o processo de <P>, o qual afeta o(a) <O>`,
      'Effect_Event(multiple)': `<O\Os1...n> desencadeiam o processo de <P>, o qual afeta o(a) <O>.`,
      'Effect_Event_state': `<O> <s> desencadeia o processo de <P>, o qual afeta o(a) <O> <s>`,

      'Effect_Negation': `<P> não afeta <O_Os>.`,
      'Effect_Negation_(multiple)': `<P> não afeta <O\Os1...n>.`,
      'Effect_Condition_Negation': `<P> ocorre se não existir <O>, condição na qual o processo de <P> afeta o(a) <O>, caso contrário o processo é ignorado.`,
      'Effect_Condition_Negation(multiple)': `<P> ocorre se <O\Os1...n> não existirem, condição na qual o processo de <P> os(as) afeta, caso contrário o processo é ignorado.`,
      'Effect_Condition_Negation_state': `<P> ocorre se <O> não for/estiver <s>, condição na qual o processo de <P> afeta o(a) <O> <s>, caso contrário o processo é ignorado.`,

      'Consumption': `<P> consome <O_Os>.`,
      'Consumption_(multiple)': `<P> consome <O\Os1...n>.`,
      'Consumption_Condition': `<P> ocorre se existir <O>, condição na qual o processo de <P> consome o(a) <O>, caso contrário o processo é ignorado.`,
      'Consumption_Condition(multiple)': `<P> ocorre se <O\Os1...n> existirem, condição na qual o processo de <P> os(as) consome, caso contrário o processo é ignorado.`,
      'Consumption_Condition_state': `<P> ocorre se <O> for/estiver <s>, condição na qual o processo de <P> consome o(a) <O> <s>, caso contrário o processo é ignorado.`,
      'Consumption_Event': `<O> desencadeira o processo de <P>, o qual consome o(a) <O>.`,
      'Consumption_Event(multiple)': `<O\Os1...n> desencadeiam o processo de <P>, o qual os(as) consome.`,
      'Consumption_Event_state': `<O> <s> desencadeia o processo de <P>, o qual consome o(a) <O>.`,

      'Consumption_Negation': `<P> não consome <O_Os>.`,
      'Consumption_Negation_(multiple)': `<P> não consome <O\Os1...n>.`,
      'Consumption_Condition_Negation': `<P> ocorre se não existir <O>, condição na qual o processo de <P> consome o(a) <O>, caso contrário o processo é ignorado.`,
      'Consumption_Condition_Negation(multiple)': `<P> ocorre se <O\Os1...n> não existirem, condição na qual o processo de <P> os(as) consome, caso contrário o processo é ignorado.`,
      'Consumption_Condition_Negation_state': `<P> ocorre se <O> não for/estiver <s>, condição na qual o processo de <P> consome o(a) <O> <s>, caso contrário o processo é ignorado.`,

      'Result': `<P> produz <O_Os>.`,
      'Result_(multiple)': `<P> produz <O\Os1...n>.`,

      'In-out_Link_Pair': `<P> altera o(a) <O> de <s1> para <s2>.`,
      'In-out(group)': `<O> de <s1> para <s2>`,
      'In-out_Link_Pair(group)': `<P> altera o(a) <O> de <s1> para <s2><Other_changes>.`,
      // 'Split_input': `<P> altera o(a) <O> de <s> para qualquer outro estado.`, // not used anymore
      // 'Split_output': `<P> altera o(a) <O> para <s> a partir de qualquer outro estado.`,// not used anymore
      'Split': `qualquer outro estado`,
      'Condition_Input': `<P> ocorre se <O> for/estiver <s1>, condição na qual o processo de <P> altera o(a) <O> de <s1> para <s2>, caso contrário o processo é ignorado.`,
      'In-out_Link_Pair_Condition': `<P> ocorre se <O> for/estiver <s1>, condição na qual o processo de <P> altera o(a) <O> de <s1> para <s2>, caso contrário o processo é ignorado.`,
      'In-out_Link_Pair_Condition(group)': `<P> ocorre se <O> for/estiver <s1>, condição na qual o processo de <P> altera o(a) <O> de <s1> para <s2>, caso contrário o processo é ignorado.`,
      'In-out_Link_Pair_Event': `<O> <s1> desencadeia o processo de <P>, o qual altera o(a) <O> de <s1> para <s2>.`,
      'In-out_Link_Pair_Event(group)': `<O> <s1> desencadeia o processo de <P>, o qual altera o(a) <O> de <s1> para <s2>.`,

      'Overtime_exception': `<O> desencadeia o processo de <P> quando o(a) <O> for/estiver <s> por mais de <maxtime> <units>.`,
      'Undertime_exception': `<O> desencadeia o processo de <P> quando o(a) <O> for/estiver <s> por menos de <mintime> <units>.`,
      'OvertimeUndertime-exception': `<O> desencadeia o processo de <P> quando o(a) <O> for/estiver <s> por mais de <maxtime> <units> e menos do que <mintime> <units>.`,

      'Overtime_exception_(process)': `<P2> ocorre se o processo de <P1> durar mais do que <maxtime> <units>.`,
      'Undertime_exception_(process)': `<P2> ocorre se o processo de processo <P1> durar menos do que <mintime> <units>.`,
      'OvertimeUndertime-exception_(process)':
    `<P2> ocorre se o processo de <P1> durar menos do que <mintime> <units> ou durar mais do que <maxtime> <units>.`,
      'Invocation': `<P1> invoca o processo de <P2>.`,
      'Invocation_(multiple)': `<P1> invoca os processos de <P2...n>.`,
      'Invocation_(self)': `<P1> invoca a si mesmo.`,
      'Invocation_(parent)': `<P1> invoca o processo de <P2>.`,

  },
  'grouping': { // for OPM Elements in a group
    /*
     * <T> - OPM Thing
     * <T1> - first OPM Thing
     * <T2> - second OPM Thing
     * <T1...n-1> - first n-1 OPM Things
     * <Tn> - nth OPM Thing
     * <O1...n> - first nth OPM Objects
     * <P1...n> - first nth OPM Processes
     * <s> - OPM State
     * <O> - OPM Object
     * <a> - OPM Object as an Attribute
     * <e1...n> - set of OPM Objects as Exhibitors
     */
    'Single-Thing': `<T>`,
    'Multiple-Things': `<T1...n-1> e <Tn>`,
    'Multiple-Things-Object-Process-Separated': `<O1...n>, bem como <P1...n>`,
    'AND': `<T1...n-1> e <Tn>`,
    'OR': `<T1...n-1> ou <Tn>`,
    'XOR': `<T1> ou exclusivamente <T2>`,
    'Stateful-Object': `<O> <s>`,
    'Stateful-Object-value': `<O> com o valor <s>`,
    'Stateful-Object-value(multiple)': `<O> com valores <s1...n>`,
    'Stateful-Object-unidirectional': `estado <s> de <O>`,
    'Stateful-Object(multiple)': `<O> nos estados <s1...n>`,
    'Attribute-Exhibitor': `<T> de <e1...n>`,
    'indentation': `&nbsp;&nbsp;&nbsp;&nbsp;`,
    'Multiple-InOut': `<Other_changes> e <change>.`
  },
  'logic_operators': {
    // process/object are the single thing in the XOR\OR relation
    // brothers - couple of states of the same object.
    'XOR': {
      'process': {
        'Result': '<P> produz exatamente um(a) dentre <O_Os1...n>.',
          'Result(brothers)': '<P> produz <O> <s1...n>.',

          'Consumption': `<P> consome exatamente um(a) dentre <O_Os1...n>.`,
          'Consumption(brothers)': `<P> consome <O> <s1...n>.`,
          'Consumption_Condition': `<P> ocorre se existir exatamente um(a) dentre <O_Os1...n> , condição na qual o processo de <P> o(a) consome, caso contrário o processo é ignorado.`,
          'Consumption_Condition(brothers)': `<P> ocorre se <O> for/estiver <s1...n>, condição na qual o processo de <P> o(a) consome, caso contrário o processo é ignorado.`,
          'Consumption_Event': `Exatamente um(a) dentre <O_Os1...n> desencadeia o processo de <P>, o qual o(a) consome.`,
          'Consumption_Event(brothers)': `<O> desencadeia o processo de <P> quando estiver <s1...n>, processo o qual o(a) consome.`,

          'Consumption_Negation': `<P> não consome exatamente um(a) dentre <O_Os1...n>.`,
          'Consumption_Negation(brothers)': `<P> não consome <O> <s1...n>.`,
          'Consumption_Condition_Negation': `<P> ocorre se exatamente um(a) dentre <O_Os1...n> não existir, condição na qualo processo de <P> consome os(as) que existem, caso contrário o processo é ignorado.`,
          'Consumption_Condition_Negation(brothers)': `<P> ocorre se <O> não for/estiver <s1...n>, condição na qual o processo de <P> o(a) consome, caso contrário o processo é ignorado.`,

          'Effect': `<P> afeta exatamente um(a) dentre <O_Os1...n>.`,
          'Effect(brothers)': `<P> afeta <O> <s1...n>.`,
          'Effect_Condition': `<P> ocorre se existir exatamente um(a) dentre <O_Os1...n>, condição na qual o processo de <P> o(a) afeta, caso contrário o processo é ignorado.`,
          'Effect_Condition(brothers)': `<P> ocorre se <O> for/estiver <s1...n>, condição na qual o processo de <P> o(a) afeta, caso contrário o processo é ignorado.`,
          'Effect_Event': `Exatamente um(a) dentre <O_Os1...n> desencadeia o processo de <P>, o qual o(a) afeta.`,
          'Effect_Event(brothers)': `<O> desencadeia o processo de <P> quando for/estiver <s1...n>, processo o qual o(a) afeta.`,

          'Effect_Negation': `<P> não tem efeito em exatamente um(a) dentre <O_Os1...n>.`,
          'Effect_Negation(brothers)': `<P> não tem efeito no(a) <O> <s1...n>.`,
          'Effect_Condition_Negation': `<P> ocorre se exatamente um(a) dentre <O_Os1...n> não existir, condição na qual o processo de <P> afeta todos(as) os(as) demais, caso contrário o processo é ignorado.`,
          'Effect_Condition_Negation(brothers)': `<P> ocorre se <O> não for/estiver <s1...n>, condição na qual o processo de <P> afeta todos os demais estados, caso contrário o processo é ignorado.`,

          'Agent': `Exatamente um(a) dentre <O_Os1...n> realiza o processo de <P>.`,
          'Agent(brothers)': `<O> realiza o processo de <P> quando for/estiver <s1...n>.`,
          'Agent_Event': `Exatamente um(a) dentre <O_Os1...n> desencadeia e realiza o processo de <P>.`,
          'Agent_Event(brothers)': `<O> desencadeia e realiza o processo de <P> quando for/estiver <s1...n>.`,
          'Agent_Condition': `<P> ocorre se existir exatamente um(a) dentre <O_Os1...n>, caso contrário o processo de <P> é ignorado.`,
          'Agent_Condition(brothers)': `<P> ocorre se <O> for/estiver <s1...n>, caso contrário o processo de <P> é ignorado.`,

          'Agent_Negation': `Exatamente um(a) dentre <O_Os1...n> não realiza o processo de <P>.`,
          'Agent_Negation(brothers)': `<O> não realiza o processo de <P> quando for/estiver <s1...n>.`,
          'Agent_Condition_Negation': `<P> ocorre se exatamente um(a) dentre <O_Os1...n> não existir, caso contrário o processo de <P> é ignorado.`,
          'Agent_Condition_Negation(brothers)': `<P> ocorre se <O> não for/estiver <s1...n>, caso contrário o processo de <P> é ignorado.`,

          'Instrument': `<P> requer exatamente um(a) dentre <O_Os1...n>.`,
          'Instrument(brothers)': `<P> requer <O> <s1...n>.`,
          'Instrument_Condition': `<P> ocorre se existir exatamente um(a) dentre <O_Os1...n>, caso contrário o processo de <P> é ignorado.`,
          'Instrument_Condition(brothers)': `<P> ocorre se <O> for/estiver <s1...n>, caso contrário o processo de <P> é ignorado.`,
          'Instrument_Event': `Exatamente um(a) dentre <O_Os1...n> desencadeia o processo de <P>, o qual o(a) requer.`,
          'Instrument_Event(brothers)': `<O> desencadeia o processo de <P> quando for/estiver <s1...n>, processo o qual o(a) requer.`,

          'Instrument_Negation': `<P> não requer exatamente um(a) dentre <O_Os1...n>.`,
          'Instrument_Negation(brothers)': `<P> não requer <O> <s1...n>.`,
          'Instrument_Condition_Negation': `<P> ocorre se exatamente um(a) dentre <O_Os1...n> não existir, caso contrário o processo de <P> é ignorado.`,
          'Instrument_Condition_Negation(brothers)': `<P> ocorre se <O> não for/estiver <s1...n>, caso contrário o processo de <P> é ignorado.`,

          'Invocation_OUT': `<P> invoca um processo qualquer dentre <P1...n>.`,
          'Invocation_IN': `Um processo qualquer dentre <P1...n> inovca o processo de <P>.`,
          'InOut': `<P> altera o(a) <O> de <s1> para exatamente um dentre <O_Os2...n>.`,
          'InOut_multi_Ins_Xor': `<P> altera o(a) <O> de exatamente um(a) dentre <ins1..n> para exatamente um(a) dentre <O_Os2...n>.`,
      },
      'object': { // _estado significa que o leque de relações advém deste estado.
        'Result': `Exatamente um processo dentre <P1...n> produz <O_Os>.`,

          'Consumption': `Exatamente um processo dentre <P1...n> consome <O_Os>`,
          'Consumption_Event': `<O_Os> desencadeia exatamente um processo dentre <P1...n>, o qual consome o(a) <O_Os>.`,
          'Consumption_Condition_state': `Exatamente um processo dentre <P1...n> ocorre se <O> for/estiver <s1>, condição na qual o processo ocorrente consome o(a) <O>, caso contrário estes processos são ignorados.`,
          'Consumption_Condition': `Exatamente um processso dentre <P1...n> ocorre se existir <O_Os>, condição na qual o processo ocorrente consome o(a) <O_Os>, caso contrário estes processos são ignorados.`,

          'Consumption_Negation': `Exatamente um processo dentre <P1...n> não consome <O_Os>`,
          'Consumption_Condition_Negation_state': `Exatamente um processo dentre <P1...n> ocorre se <O> não estiver no estado <s1>, condição na qual este processo consome o(a) <O>, caso contrário todos estes processos são ignorados.`,
          'Consumption_Condition_Negation': `Exatamente um processo dentre <P1...n> ocorre se <O_Os> não existir, condição na qual este processo consome o(a) <O_Os>, caso contrário todos estes processos são ignorados.`,

          'Effect': `Exatamente um processo dentre <P1...n> afeta <O_Os>.`,
          'Effect_Event': `<O_Os> desencadeia exatamente um processo dentre <P1...n>, o qual afeta o(a) <O_Os>.`,
          'Effect_Condition': `Exatamente um processo dentre <P1...n> ocorre se existir <O_Os>, condição na qual o processo ocorrente afeta o(a) <O_Os>, caso contrário estes processos são ignorados.`,

          'Effect_Negation': `<P1...n> não afetam exatamente um(a) dentre <O_Os>.`,
          'Effect_Condition_Negation': `<P1...n> ocorrem se exatamente um(a) dentre <O_Os> não existir, condição na qual <P1...n> afetam todos os demais, caso contrário os processos são ignorados.`,

          'Agent': `<O_Os> realiza exatamente um processo dentre <P1...n>.`,
          'Agent_Event': `<O_Os> desencadeia e realiza exatamente um processo dentre <P1...n>.`,
          'Agent_Condition': `<O_Os> realiza exatamente um processo dentre <P1...n> se existir <O_Os>, caso contrário estes processos são ignorados.`,
          'Agent_Condition_state': `<O> realiza exatamente um processo dentre <P1...n> se <O> for/estiver <s1>, caso contrários estes processos são ignorados.`,

          'Agent_Negation': `Exatamente um processo dentre <P1...n> ocorre se <O_Os> não estiver presente.`,
          'Agent_Condition_Negation':  `Exatamente um processo dentre <P1...n> ocorre se <O_Os> não estiver presente, caso contrário todos estes processos são ignorados.`,
          'Agent_Condition_Negation_state': `Exatamente um processo dentre <P1...n> ocorre se <O> não estiver no estado <s1>, caso contrário todos estes processos são ignorados.`,

          'Instrument': `Exatamente um processo dentre <P1...n> requer <O_Os>.`,
          'Instrument_Event': `<O_Os> desencadeia exatamente um processo dentre <P1...n>, o qual requer o(a) <O_Os>.`,
          'Instrument_Condition': `Exatamente um processo dentre <P1...n> requer <O_Os>, caso contrário estes processos são ignorados.`,
          'Instrument_Condition_state': `Exatamente um processo dentre <P1...n> requer que <O> seja/esteja <s1>, caso contrário estes processos são ignorados.`,

          'Instrument_Negation': `Exatamente um processo dentre <P1...n> ocorre se <O_Os> não existir.`,
          'Instrument_Condition_Negation': `Exatamente um processo dentre <P1...n> ocorre se <O_Os> não existir, caso contrário todos estes processos são ignorados.`,
          'Instrument_Condition_Negation_state': `Exatamente um processo dentre <P1...n> ocorre se <O> não for/estiver <s1>, caso contrário todos estes processos são ignorados.`,
      }
    },
    'OR': {
      'process': {
        'Result': '<P> produz ao menos um(a) dentre <O_Os1...n>.',

          'Consumption': `<P> consome ao menos um(a) dentre <O_Os1...n>.`,
          'Consumption_Condition': `<P> ocorre se existir ao menos um(a) dentre <O_Os1...n>, condição na qual o processo de <P> consome aqueles(as) que existirem, caso contrário o processo é ignorado.`,
          'Consumption_Event': `Ao menos um(a) dentre <O_Os1...n> desencadeia o processo de <P>, o qual o(a) consome.`,

          'Consumption_Negation': `<P> não consome ao menos um(a) dentre <O_Os1...n>.`,
          'Consumption_Condition_Negation': `<P> ocorre se ao menos um(a) dentre <O_Os1...n> não existir, condição na qual o processo de <P> consome aqueles(as) que existirem, caso contrário o processo é ignorado.`,

          'Effect': `<P> afeta ao menos um(a) dentre <O_Os1...n>.`,
          'Effect_Condition': `<P> ocorre se existir ao menos um(a) dentre <O_Os1...n>, condição na qual o processo de <P> o(a) afeta, caso contrário o processo é ignorado.`,
          'Effect_Event': `Ao menos um(a) dentre <O_Os1...n> desencadeia o processo de <P>, o qual o(a) afeta.`,

          'Effect_Negation': `<P> não afeta ao menos um(a) dentre <O_Os1...n>.`,
          'Effect_Negation(brothers)': `<P> não afeta <O> que for/estiver <s1...n>.`,
          'Effect_Condition_Negation': `<P> ocorre se ao menos um(a) dentre <O_Os1...n> não existir, condição na qual o processo de <P> afeta todos(as) os(as) demais, caso contrário o processo é ignorado.`,
          'Effect_Condition_Negation(brothers)': `<P> ocorre se <O> não for/estiver <s1...n>, condição na qual o processo de <P> afeta todos os demais estados, caso contrário o processo é ignorado.`,

          'Agent': `Ao menos um(a) dentre <O_Os1...n> realiza o processo de <P>.`,
          'Agent_Event': `Ao menos um(a) dentre <O_Os1...n> desencadeia e realiza o processo de <P>.`,
          'Agent_Condition': `<P> ocorre se existir ao menos um(a) dentre <O_Os1...n>, caso contrário o processo de <P> é ignorado.`,

          'Agent_Negation': `Ao menos um(a) dentre <O_Os1...n> não realiza o processo de <P>.`,
          'Agent_Condition_Negation': `<P> ocorre se ao menos um(a) dentre <O_Os1...n> não existir, caso contrário o processo de <P> é ignorado.`,

          'Instrument': `<P> requer ao menos um(a) dentre <O_Os1...n>.`,
          'Instrument_Condition': `<P> ocorre se existir ao menos um(a) dentre <O_Os1...n>, caso contrário o processo de <P> é ignorado.`,
          'Instrument_Event': `Ao menos um(a) dentre <O_Os1...n> desencadeia o processo de <P>, o qual o(a) requer.`,

          'Instrument_Negation': `<P> não requer ao menos um(a) dentre <O_Os1...n>.`,
          'Instrument_Condition_Negation': `<P> ocorre se ao menos um(a) dentre <O_Os1...n> não existir, caso contrário o processo de <P> é ignorado.`,

          'Invocation_OUT': `<P> invoca ao menos um processo dentre <P1...n>.`,
          'Invocation_IN': `Ao menos um processo dentre <P1...n> invoca o processo de <P>.`,
          'InOut': `<P> altera o(a) <O> de <s1> para ao menos um(a) dentre <O_Os2...n>.`,
      },
      'object': {
        'Result': `Ao menos um processo dentre <P1...n> produz <O_Os>`,

          'Consumption': `Ao menos um processo dentre <P1...n> consome <O_Os>`,
          'Consumption_Event': `<O_Os> desencadeia ao menos um processo dentre <P1...n>, o qual consome o(a) <O_Os>.`,
          'Consumption_Condition_state': `Ao menos um processo dentre <P1...n> ocorre se <O> for/estiver <s1>, condição na qual o processo ocorrente consome o(a) <O>, caso contrário estes processos são ignorados.`,
          'Consumption_Condition': `Ao menos um processo dentre <P1...n> ocorre se exisitr <O_Os>, condição na qual o processo ocorrente consome o(a) <O_Os>, caso contrário estes processos são ignorados.`,

          'Consumption_Negation': `Ao menos um processo dentre <P1...n> não consome <O_Os>`,
          'Consumption_Condition_Negation_state': `Ao menos um processo dentre <P1...n> ocorre se <O> não for/estiver <s1>, condição na qual o processo ocorrente consome o(a) <O>, caso contrário todos estes processos são ignorados.`,
          'Consumption_Condition_Negation': `Ao menos um processo dentre <P1...n> ocorre se <O_Os> não exisitr, condição na qual o processso ocorrente consome o(a) <O_Os>, caso contráro todos estes processos são ignorados.`,

          'Effect': `Ao menos um processo dentre <P1...n> afeta <O_Os>`,
          'Effect_Event': `<O_Os> desencadeia ao menos um processo dentre <P1...n>, o qual afeta o(a) <O_Os>.`,
          'Effect_Condition': `Ao menos um processo dentre <P1...n> ocorre se existir <O_Os>, condição na qual o processo ocorrente afeta o(a) <O_Os>, caso contrário estes processos são ignorados.`,

          'Effect_Negation': `<P1...n> não afetam ao menos um(a) dentre <O_Os>.`,
          'Effect_Condition_Negation': `Ao menos um processo dentre <P1...n> ocorre se não exisitr <O_Os>, condição na qual o processo ocorrente afeta o(a) <O_Os>, caso contrário os processos são ignorados.`,


          'Agent': `<O_Os> realiza ao menos um processo dentre <P1...n>.`,
          'Agent_Event': `<O_Os> desencadeia e realiza ao menos um processo dentre <P1...n>.`,
          'Agent_Condition': `<O_Os> realiza ao menos um processo dentre <P1...n> se <O_Os> existir, caso contrário estes processos são ignorados.`,
          'Agent_Condition_state': `<O> realiza ao menos um processo dentre <P1...n> se <O> for/estiver <s1>, caso contrário estes processos são ignorados.`,

          'Agent_Negation': `Ao menos um processo dentre <P1...n> ocorre se <O_Os> não estiver presente.`,
          'Agent_Condition_Negation':  `Ao menos um processo dentre <P1...n> ocorre se <O_Os> não estiver presente, caso contrário todos estes processos são ignorados.`,
          'Agent_Condition_Negation_state': `Ao menos um processo dentre <P1...n> ocorre se <O> não for/estiver no estado <s1>, caso contrário todos estes processos são ignorados.`,

          'Instrument': `Ao menos um processo dentre <P1...n> requer <O_Os>`,
          'Instrument_Event': `<O_Os> desencadeia ao menos um processo dentre <P1...n>, o qual requer o(a) <O_Os>`,
          'Instrument_Condition': `Ao menos um processo dentre <P1...n> requer <O_Os>, caso contrário estes processos são ignorados.`,
          'Instrument_Condition_state': `Ao menos um processo dentre <P1...n> requer que <O> seja/esteja <s1>, caso contrário estes processos são ignorados.`,

          'Instrument_Negation': `Ao menos um processo dentre <P1...n> ocorre se <O_Os> não existir.`,
          'Instrument_Condition_Negation': `Ao menos um processo dentre <P1...n> ocorre se <O_Os> não exisitr, caso contrário todos estes processos são ignorados.`,
          'Instrument_Condition_Negation_state': `Ao menos um processo dentre <P1...n> ocorre se <O> não for/estiver <s1>, caso contrário todos estes processos são ignorados.`,
      }
    },
    'NOT': {
      'process': {
        'Agent_Negation': `<O_Os1...n> não realizam o processo de <P>.`,
          'Agent_Condition_Negation': `<P> ocorre se <O_Os1...n> não existirem, caso contrário o processo de <P> é ignorado.`,

          'Instrument_Negation': `<P> não requer <O_Os1...n>.`,
          'Instrument_Condition_Negation': `<P> ocorre se <O_Os1...n> não existirem, caso contrário o processo de <P> é ignorado.`,

          'Consumption_Negation': `<P> não consome <O_Os1...n>.`,
          'Consumption_Condition_Negation': `<P> ocorre se <O_Os1...n> não existirem, condição na qual o processo de <P> consome os(as) existentes, caso contrário o processo é ignorado.`,

          'Effect_Negation': `<P> não afeta <O_Os1...n>.`,
          'Effect_Condition_Negation': `<P> ocorre se <O_Os1...n> não existem, condição na qual o processo de <P> afeta todos(as) os(as) demais, caso contrário o processo é ignorado.`
      },
      'object': {
        'Agent_Negation': `<P1...n> ocorrem se <O_Os> não estiver presente.`,
          'Agent_Condition_Negation':  `<P1...n> ocorrem se <O_Os> não estiver presente, caso contrário todos estes processos são ignorados.`,
          'Agent_Condition_Negation_state': `<P1...n> ocorrem se <O> não for/estiver <s1>, caso contrário todos estes processos são ignorados.`,

          'Instrument_Negation': `<P1...n> ocorrem se <O_Os> não existir.`,
          'Instrument_Condition_Negation': `<P1...n> ocorrem se <O_Os> não existir, caso contrário todos estes processos são ignorados.`,
          'Instrument_Condition_Negation_state': `<P1...n> ocorrem se <O> não for/estiver <s1>, caso contrário todos estes processos são ignorados.`,

          'Consumption_Negation': `<P1...n> não consomem <O_Os>`,
          'Consumption_Condition_Negation_state': `<P1...n> ocorrem se <O> não for/estiver <s1>, condição na qual estes processos consomem o(a) <O>, caso contrário todos estes processos são ignorados.`,
          'Consumption_Condition_Negation': `<P1...n> ocorrem se <O_Os> não existir, condição na qual os processo consomem o(a) <O_Os>, caso contrário todos estes processos são ignorados.`,

          'Effect_Negation': `<P1...n> não afetam <O_Os>.`,
          'Effect_Condition_Negation': `<P1...n> ocorrem se <O_Os> não existir, condição na qual <P1...n> afetam todos(as) os(as) demais, caso contrário todos estes processos são ignorados.`
      }
    }
  },
  'tags': {
    'multiplicity': `<tag> <O_Os>`,
      'constraints': `<O_Os>; onde <tag>`,
      'probability': `<O_Os> com probabilidade <tag>`,
      'rate': `<O_Os> a uma taxa de <tag> <units>`,
      'path': `Seguindo o caminho <tag>, <opl>`,
      'range': `<r> <opl>`
  },
  'symbols': {
    '?': `um opcional`,
      '*': `opcionais`,
      '+': `ao menos um`,
      'n..n': `<n1> a <n2>`,
      'n..*': `<n1> a muitos`,
      'n..mean..n': `<n1> a <n2>, com uma média de <mean>`
  },
  'ranges': {
    '<=': `menor ou igual a`,
      '>=': 'maior ou igual a',
      '=': 'igual a',
      '<>': 'diferente de',
      '<': `menor do que`,
      '>': 'maior do que'
  },
  'object': {
    'thing_generic_name': `objeto`,
    'default_essence_affiliation': ``,
    'digital_twin': `<TWIN> é o Gêmeo Digital de <O>.`,
    'default_essence': `<O> é <a>.`,
    'default_affiliation': `<O> é <e>.`,
    'non_default': `<O> é <e> e <a>.`,
    'singleInzoom': `<O> do <SD_Parent> expande-se no <Current_SD> em <T_list>.`,
    'singleInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>.`,
    'multiInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>, as well as <P_list>.`,
    'multiInzoom': `<O> do <SD_Parent> expande-se no <Current_SD> em <O_list>, bem como nos processos de <P_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<O> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<O> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <O_list>, as well as <P_list>.`,
    'single_unfold_instantiation': `<O> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    'object_list_sequence': `<O1...n>, nesta sequência vertical`,
    'object_list_parallel': `<O1...n>, nesta sequência horizontal`,
  },
  'process': {
    'thing_generic_name': `processo`,
    'default_essence_affiliation': ``,
    'default_essence': `<P> é um processo <a>.`,
    'default_affiliation': `<P> é um processo <e>.`,
    'non_default': `<P> é um processo <e> e <a>.`,
    'singleInzoom': `<P> do <SD_Parent> expande-se no <Current_SD> em <T_list>, os(as) quais ocorrem nesta ordem.`,
    'singleInzoom_parallel': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
    'multiInzoom': `<P> do <SD_Parent> expande-se no <Current_SD> nos processo de <P_list>, bem como em <O_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<P> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<P> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_instantiation': `<P> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    // 'process_list_parallel': `<P1...n>, nesta sequência horizontal`,
    'process_list_parallel': `<P1...n> em paralelo`,
    // 'process_list_sequence': `<P1...n>, nesta sequência vertical`,
    'process_list_sequence': `<P1...n>`,
    'expected_duration': `A duração esperada do processo de <P> é de <exp> <units>.`,
    'min_duration': `A duração mínima do processo de <P> é de <min> <units>.`,
    'max_duration': `A duração máxima do processo de <P> é de <max> <units>.`,
    'min_max_range_duration': `A duração mínima e a duração máxima do processo de <P> são de <min> <units> e de <max> <units>, respectivamente.`,
    'min_exp_range_duration': `A duração mínima e a duração esperada do processo de <P> são de <min> <units> e de <exp> <units>, respectivamente.`,
    'exp_max_range_duration': `A duração esperada e a duração máxima do processo de <P> são de <exp> <units> e de <max> <units>, respectivamente.`,
    'expected_range_duration': `As durações mínima, esperada e máxima do processo de <P> são de <min> <units>, <exp> <units>, e <max> <units>, respectivamente.`,
    'default_time_units': `segundos`
  },
  'state': {
    'single_state': `<O> é/esta <s>.`,
      'multiple_states': `<O> pode ser/estar <s1...n>.`,
      'all_states_are_suppressed': `<O> possui diferentes estados.`,
      'one_state_shown_one_missing': `<O> is <s> or at one other state.`,
      'one_state_shown': `<O> é/está <s>, mas pode assumir outros estados.`,
      'two_or_more_states_shown_one_missing': `<O> can be <s1...n> or at one other state.`,
      'two_or_more_states_shown': `<O> pode ser/estar <s1...n>, entre outros estados.`,
      'default': `<s> é o estado predefinido.`,
      'default_initial': `<s> é o estado predefinido e inicial.`,
      'initial': `<s> é o estado inicial.`,
      'final': `<s> é o estado final.`,
      'default_initial_final': `<s> é o estado inicial, final, e predefinido.`,
      'initial_final': `<s> é o estado inicial e final.`,
      'default_final': `<s> é o estado final e predefinido.`,
      'none': ``,
      'Current': `Neste momento o(a) <O> é/está <s>.`,
      // 'timeDurational': {
      'expected_duration': `A duração esperada no estado <s> é de <exp> <units>.`,
      'min_duration': `A duração mínima no estado <s> é de <min> <units>.`,
      'max_duration': `A duração máxima no estado <s> é de <max> <units>.`,
      'min_max_range_duration': `A duração mínima e a duração máxima no estado <s> são de <min> <units> e de <max> <units>, respectivamente.`,
      'min_exp_range_duration': `A duração mínima e a duração esperada no estado <s> são de <min> <units> e de <exp> <units>, respectivamente.`,
      'exp_max_range_duration': `A duração esperada e a duração máxima no estado <s> são de <exp> <units> e de <max> <units>, respectivamente.`,
      'expected_range_duration': `As durações mínima, esperada e máxima no estado <s> são de <min> <units>, <exp> <units>, e <max> <units>, respectivamente.`,
      'default_time_units': `segundos`
    // }
  },
  'essence': {
    'physical': `material`,
      'informatical': `informacional`,
  },
  'affiliation': {
    'systemic': `sistêmico`,
      'environmental': `ambiental`,
  },
  'semifolding': {
    'object': `<O> apresenta `,
      'process': `<P> apresenta `,
      'general': {
      'object': `<O> do <SD> está semi-desdobrado no <HighestSD>`,
        'process': `<P> do <SD> está semi-desdobrado no <HighestSD>`,
    },
    'aggregation': {
      'single': `<T> como uma parte`,
        'multiple': `<T1...n-1> e <Tn> como partes`,
    },
    'exhibition': {
      'single': `<T> como uma característica`,
        'multiple': `<T1...n-1> e <Tn> como características`,
    },
    'generalization': {
      'single': `<T> como uma especialização`,
        'multiple': `<T1...n-1> e <Tn> como especializações`,
    },
    'instantiation': {
      'single': `<T> como uma instância`,
        'multiple': `<T1...n-1> e <Tn> como instâncias`,
    },
  },
  'hidden_attributes': {
    'requirement': `<set_object> of <owner> is <value>.`,
  },
  'father_model_to_sub_model': `The selected things, <o1...n>, and <p1...n> are refined in sub model <subsystem_name> subsystem model view.`,
  'sub_model_from_father_model': `The <subsystem_name> subsystem model view is derived from the <father_model_name> model.`
};


export const oplTemplates_cn = {
  'structural_link': {
    /*
     * <T1> - source OPM Thing
     * <T2> - target OPM Thing
     * <T2...n> - set of target OPM Things
     */
    'Aggregation-Participation': `<T1>包含<T2>。`,
    'Aggregation-Participation_incomplete': `<T1> consists of <T2> and <num> more parts.`,
    'Aggregation-Participation_(multiple)': `<T1>包含<T2...n>。`,
    'Aggregation-Participation_incomplete_(multiple)': `<T1> consists of <T2...n> and <num> more parts.`,
    'Generalization-Specialization': `<T2>是一个<T1>。`,
    'Generalization-Specialization_incomplete': `<T2> and <num> more specializations are <T1>.`,
    'Generalization-Specialization_(multiple)': `<T2...n>是<T1>。`,
    'Generalization-Specialization_incomplete_(multiple)': `<T2...n> and <num> more specializations are <T1>.`,
    'Classification-Instantiation': `<T2> 是<T1>的一个实例。`,
    'Classification-Instantiation_incomplete': `<T2> and <num> more instances are instances of <T1>.`,
    'Classification-Instantiation_(multiple)': `<T2...n>是<T1>的实例。`,
    'Classification-Instantiation_incomplete_(multiple)': `<T2...n> and <num> more instances are instances of <T1>.`,
    'Exhibition-Characterization': `<T1>展示出属性<T2>。`,
    'Exhibition-Characterization_incomplete': `<T1> exhibits <T2> and <num> more attributes.`,
    'Exhibition-Characterization_(multiple)': `<T1>展示出属性<T2...n>。`,
    'Exhibition-Characterization_incomplete_(multiple)': `<T1> exhibits <T2...n> and <num> more attributes.`,
    'Unidirectional_Tagged_Link': `<T1>与<T2>相关。`,
    'Unidirectional_Tagged_Link_(tag)': `<T1><tag><T2>。`,
    'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
    'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
    'sequence': `in that sequence.`,
    'Bidirectional_Tagged_Link': `<T1>和<T2>是等价的。`,
    'Bidirectional_Tagged_Link_(tag)': `<T1>和<T2>是<tag>。`,
    'Bidirectional_Tagged_Link_(ftag，btag)': `<T1><forward tag><T2>，并且<T2><backward tag><T1>。`,
  },
  'procedural_link': {
    /*
     * <O_Os> - OPM Object or OPM Stateful Object
     * <O_Os1...n> - set of OPM Objects or OPM Stateful Objects
     * <s> - OPM State
     * <P> - OPM Process
     */
    'Agent': `<O_Os>操作<P>。`,
    'Agent_(multiple)': `<O\Os1...n>操作<P>。`,
    'Agent_Condition': `如果<O>存在，则<P>发生，否则<P>被跳过。`,
    'Agent_Condition(multiple)': `如果<O\Os1...n>存在，则<P>发生，否则<P>被跳过。`,
    'Agent_Condition_state': `如果<O>在<s>状态，则<P>发生，否则<P>被跳过。`,
    'Agent_Event': `<O>启动并操作<P>。`,
    'Agent_Event(multiple)': `<O\Os1...n>启动并操作<P>。`,
    'Agent_Event_state': `<s><O>启动并操作<P>。`,

    'Instrument': `<P>需要<O_Os>。`,
    'Instrument_(multiple)': `<P>需要<O\Os1...n>。`,
    'Instrument_Condition': `如果<O>存在，则<P>发生，否则<P>被跳过。`,
    'Instrument_Condition(multiple)': `如果<O\Os1...n>存在，则<P>发生，否则<P>被跳过。`,
    'Instrument_Condition_state': `如果<O>在<s>状态，则<P>发生，否则<P>被跳过。`,
    'Instrument_Event': `<O>启动<P>，这个过程需要<O>。`,
    'Instrument_Event(multiple)': `<O\Os1...n>启动<P>，这个过程需要这些对象。`,
    'Instrument_Event_state': `<s><O>启动<P>，这个过程需要<s><O>。`,

    'Effect': `<P>影响<O_Os>。`,
    'Effect_(multiple)': `<P>影响<O\Os1...n>。`,
    'Effect_Condition': `如果<O>存在，则<P>发生，在此情况下<P>影响<O>，否则<P>被跳过。`,
    'Effect_Condition(multiple)': `如果<O\Os1...n>存在，则<P>发生，在此情况下<P>影响这些对象，否则<P>被跳过。`,
    'Effect_Condition_state': `如果<O>在<s>状态，则<P>发生，在此情况下<P>影响<s><O>，否则<P>被跳过。`,
    'Effect_Event': `<O>启动<P>，这个过程影响<O>`,
    'Effect_Event(multiple)': `<O\Os1...n>启动<P>，这个过程影响<O>。`,
    'Effect_Event_state': `<s><O>启动<P>，这个过程影响<s><O>`,

    'Consumption': `<P>消耗<O_Os>。`,
    'Consumption_(multiple)': `<P>消耗<O\Os1...n>。`,
    'Consumption_Condition': `如果<O>存在，则<P>发生，在此情况下<P>消耗<O>，否则<P>被跳过。`,
    'Consumption_Condition(multiple)': `如果<O\Os1...n>存在，则<P>发生，在此情况下<P>消耗这些对象，否则<P>被跳过。`,
    'Consumption_Condition_state': `如果<O>在<s>状态，则<P>发生，在此情况下<P>消耗<s><O>，否则<P>被跳过。`,
    'Consumption_Event': `<O>启动<P>，这个过程消耗<O>。`,
    'Consumption_Event(multiple)': `<O\Os1...n>启动<P>，这个过程消耗这些对象。`,
    'Consumption_Event_state': `<s><O>启动<P>，这个过程消耗<O>。`,

    'Result': `<P>产生<O_Os>。`,
    'Result_(multiple)': `<P>产生<O\Os1...n>。`,

    'In-out_Link_Pair': `<P>将<O>从<s1>改变为<s2>。`,
    'In-out(group)': `<O>从<s1>改变为<s2>`,
    'In-out_Link_Pair(group)': `<P>将<O>从<s1>改变为<s2><Other_changes>。`,
    'Split_input': `<P>将<O>从<s>改变为其他状态。`,
    'Split_output': `<P>将<O>从其他状态改变为<s>。`,
    'Condition_Input': `如果<O>是<s1>状态，则<P>发生，在此情况下<P>将<O>从<s1>改变为<s2>，否则<P>被跳过。`,
    'In-out_Link_Pair_Condition': `如果<O>是<s1>状态，则<P>发生，在此情况下<P>将<O>从<s1>改变为<s2>，否则<P>被跳过。`,
    'In-out_Link_Pair_Condition(group)': `如果<O>是<s1>状态，则<P>发生，在此情况下<P>将<O>从<s1>改变为<s2>，否则<P>被跳过。`,
    'In-out_Link_Pair_Event': `<s1><O>启动<P>，这个过程将<O>从<s1>改变为<s2>。`,
    'In-out_Link_Pair_Event(group)': `<O>在<s1>状态时启动<P>，这个过程将<O>从<s1>改变为<s2>。`,


    'Overtime_exception': `当<O>在<s>状态超过<maxtime><units>时，<O>触发<P>。`,
    'Undertime_exception': `当<O>在<s>状态少于<mintime><units>时，<O>触发<P>。`,
    'OvertimeUndertime-exception': `当<O>在<s>状态超过<maxtime><units>并少于<mintime><units>时，<O>触发<P>。`,

    'Overtime_exception_(process)': `如果<P1>超过<maxtime><units>,<P2>发生。`,
    'Undertime_exception_(process)': `如果<P1>少于<mintime><units>,<P2>发生。`,
    'OvertimeUndertime-exception_(process)':
      `如果<P1>超过<maxtime><units>且少于<mintime><units>,<P2>发生。`,
    'Invocation': `<P1>引发<P2>。`,
    'Invocation_(multiple)': `<P1>引发<P2...n>。`,
    'Invocation_(self)': `<P1>引发它自身。`,
    'Invocation_(parent)': `<P1>引发<P2>。`,

  },
  'grouping': { // for OPM Elements in a group
    /*
     * <T> - OPM Thing
     * <T1> - first OPM Thing
     * <T2> - second OPM Thing
     * <T1...n-1> - first n-1 OPM Things
     * <Tn> - nth OPM Thing
     * <O1...n> - first nth OPM Objects
     * <P1...n> - first nth OPM Processes
     * <s> - OPM State
     * <O> - OPM Object
     * <a> - OPM Object as an Attribute
     * <e1...n> - set of OPM Objects as Exhibitors
     */
    'Single-Thing': `<T>`,
    'Multiple-Things': `<T1...n-1>和<Tn>`,
    'Multiple-Things-Object-Process-Separated': `<O1...n>，以及<P1...n>`,
    'AND': `<T1...n-1>和<Tn>`,
    'OR': `<T1...n-1>或<Tn>`,
    'XOR': `<T1> xor <T2>`,
    'Stateful-Object': `<s>状态的<O>`,
    'Stateful-Object-value': `<O> 其值为 <s>`,
    'Stateful-Object-value(multiple)': `<O> 其值为 <s1...n>`,
    'Stateful-Object(multiple)': `<s1...n>状态的<O>`,
    'Attribute-Exhibitor': ` <e1...n>的<T>`,
    'indentation': `&nbsp;&nbsp;&nbsp;&nbsp;`,
    'Multiple-InOut': `<Other_changes>和<change>。`
  },
  'logic_operators': {
    // process/object are the single thing in the XOR\OR relation
    // brothers - couple of states of the same object。
    'XOR': {
      'process': {
        'Result': '<P>产生<O_Os1...n>其中之一。',
        'Result(brothers)': '<P>产生<s1...n>至少其中一种状态下的<O>。',

        'Consumption': `<P>消耗<O_Os1...n>其中之一。`,
        'Consumption(brothers)': `<P>消耗<s1...n>至少其中一种状态下的<O>。`,
        'Consumption_Condition': `如果<O_Os1...n>其中之一存在，则<P>发生，在此情况下<P>消耗这个存在的对象，否则<P>被跳过。`,
        'Consumption_Condition(brothers)': `如果<O>在<s1...n>状态之一，则<P>发生，在此情况下<P>消耗it，否则<P>被跳过。`,
        'Consumption_Event': `<O_Os1...n>其中之一启动<P>，这个过程消耗它。`,
        'Consumption_Event(brothers)': `当<O>在<s1...n>状态之一时，<O>启动<P>，这个过程消耗它。`,


        'Effect': `<P>影响<O_Os1...n>其中之一。`,
        'Effect(brothers)': `<P>影响<s1...n>至少其中一种状态下的<O>。`,
        'Effect_Condition': `如果<O_Os1...n>其中之一存在，则<P>发生，在此情况下<P>影响它，否则<P>被跳过。`,
        'Effect_Condition(brothers)': `如果<O>在<s1...n>状态之一，则<P>发生，在此情况下<P>影响它，否则<P>被跳过。`,
        'Effect_Event': `<O_Os1...n>其中之一启动<P>，这个过程影响它。`,
        'Effect_Event(brothers)': `当<O>在<s1...n>状态之一时，<O>启动<P>，这个过程影响它。`,

        'Agent': `<O_Os1...n>其中之一操作<P>。`,
        'Agent(brothers)': `当<O>在<s1...n>状态之一时，<O>操作<P>。`,
        'Agent_Event': `<O_Os1...n>其中之一启动并操作<P>。`,
        'Agent_Event(brothers)': `当<O>在<s1...n>状态之一时，<O>启动并操作<P>。`,
        'Agent_Condition': `如果<O_Os1...n>其中之一存在，则<P>发生，否则<P>被跳过。`,
        'Agent_Condition(brothers)': `如果<O>在<s1...n>状态之一，则<P>发生，否则<P>被跳过。`,

        'Instrument': `<P>需要<O_Os1...n>其中之一。`,
        'Instrument(brothers)': `<P>需要<s1...n>至少其中一种状态下的<O>。`,
        'Instrument_Condition': `如果<O_Os1...n>其中之一存在，则<P>发生，否则<P>被跳过。`,
        'Instrument_Condition(brothers)': `如果<O>在<s1...n>状态之一，则<P>发生，否则<P>被跳过。`,
        'Instrument_Event': `<O_Os1...n>其中之一启动<P>，这个过程需要它。`,
        'Instrument_Event(brothers)': `如果<O>在<s1...n>状态之一，<O>启动<P>，这个过程需要它。`,

        'Invocation_OUT': `<P>引发或<P1...n>。`,
        'Invocation_IN': `或<P1...n>引发<P>。`,
        'InOut': `<P> changes <O> from <s1> to exactly one of <O_Os2...n>.`,
        'InOut_multi_Ins_Xor': `<P> changes <O> from exactly one of <ins1..n> to exactly one of <O_Os2...n>.`,
      },
      'object': { // _state means the link fan is from state。
        'Result': `<P1...n>其中之一产生<O_Os>。`,

        'Consumption': `<P1...n>其中之一消耗<O_Os>`,
        'Consumption_Event': `<O_Os>启动<P1...n>其中之一，这个过程消耗<O_Os>。`,
        'Consumption_Condition_state': `如果<O>在<s1>状态，则<P1...n>其中之一发生，在此情况下这个发生的过程消耗<O>，否则这些过程被跳过。`,
        'Consumption_Condition': `如果<O_Os>存在，则<P1...n>其中之一发生，在此情况下这个发生的过程消耗<O_Os>，否则这些过程被跳过。`,

        'Effect': `<P1...n>其中之一影响<O_Os>。`,
        'Effect_Event': `<O_Os>启动<P1...n>其中之一，这个过程影响<O_Os>。`,
        'Effect_Condition': `如果<O_Os>存在，<P1...n>其中之一发生，在此情况下这个发生的过程影响<O_Os>，否则这些过程被跳过。`,

        'Agent': `<O_Os>操作<P1...n>其中之一。`,
        'Agent_Event': `<O_Os>启动并操作<P1...n>其中之一。`,
        'Agent_Condition': `如果<O_Os>存在，则<O_Os>操作<P1...n>其中之一，否则这些过程被跳过。`,
        'Agent_Condition_state': `如果<O>在<s1>状态，则<O>操作<P1...n>其中之一，否则这些过程被跳过。`,

        'Instrument': `<P1...n>其中之一需要<O_Os>。`,
        'Instrument_Event': `<O_Os>启动<P1...n>其中之一，这个过程需要<O_Os>。`,
        'Instrument_Condition': `<P1...n>其中之一需要<O_Os>，否则这些过程被跳过。`,
        'Instrument_Condition_state': `<P1...n>其中之一需要<O>在<s1>状态，否则这些过程被跳过。`
      }
    },
    'OR': {
      'process': {
        'Result': '<P>产生<O_Os1...n>至少其一。',

        'Consumption': `<P>消耗<O_Os1...n>至少其一。`,
        'Consumption_Condition': `如果<O_Os1...n>至少其一存在，则<P>发生，在此情况下<P>消耗这个存在的对象，否则<P>被跳过。`,
        'Consumption_Event': `<O_Os1...n>至少其一启动<P>，这个过程消耗<O_Os1...n>。`,

        'Effect': `<P>影响<O_Os1...n>至少其一。`,
        'Effect_Condition': `如果<O_Os1...n>至少其一存在，则<P>发生，在此情况下<P>影响it，否则<P>被跳过。`,
        'Effect_Event': `<O_Os1...n>至少其一启动<P>，这个过程影响<O_Os1...n>。`,

        'Agent': `<O_Os1...n>至少其一操作<P>。`,
        'Agent_Event': `<O_Os1...n>至少其一启动并操作<P>。`,
        'Agent_Condition': `如果<O_Os1...n>至少其一存在，则<P>发生，否则<P>被跳过。`,

        'Instrument': `<P>需要<O_Os1...n>至少其一。`,
        'Instrument_Condition': `如果<O_Os1...n>至少其一存在，则<P>发生，否则<P>被跳过。`,
        'Instrument_Event': `<O_Os1...n>至少其一启动<P>，这个过程需要这个对象。`,


        'Invocation_OUT': `<P>引发<P1...n>至少其一。`,
        'Invocation_IN': `<P1...n>至少其一引发<P>。`,

        'InOut': `<P> changes <O> from <s1> to at least one of <O_Os2...n>.`,
      },
      'object': {
        'Result': `<P1...n>至少其一产生<O_Os>`,

        'Consumption': `<P1...n>至少其一消耗<O_Os>`,
        'Consumption_Event': `<O_Os>启动<P1...n>至少其一，这个过程消耗<O_Os>。`,
        'Consumption_Condition_state': `如果<O>在<s1>状态，则<P1...n>至少其一发生，在此情况下这个发生的过程消耗<O>，否则这些过程被跳过。`,
        'Consumption_Condition': `当<O_Os>存在时，则<P1...n>至少其一发生，在此情况下这个发生的过程消耗<O_Os>，否则这些过程被跳过。`,

        'Effect': `<P1...n>至少其一影响<O_Os>`,
        'Effect_Event': `<O_Os>启动<P1...n>至少其一，这个过程影响这个发生的过程。`,
        'Effect_Condition': `<P1...n>至少其一 occurs if <O_Os> exists，在此情况下这个发生的过程影响<O_Os>，否则这些过程被跳过。`,

        'Agent': `<O_Os>操作<P1...n>至少其一。`,
        'Agent_Event': `<O_Os>启动并操作<P1...n>至少其一。`,
        'Agent_Condition': `如果<O_Os>存在，则<O_Os>操作<P1...n>至少其一，否则这些过程被跳过。`,
        'Agent_Condition_state': `如果<O>在<s1>状态，则<O>操作<P1...n>至少其一，否则这些过程被跳过。`,

        'Instrument': `<P1...n>至少其一需要<O_Os>`,
        'Instrument_Event': `<O_Os>启动<P1...n>至少其一，这个过程需要<O_Os>`,
        'Instrument_Condition': `<P1...n>至少其一需要<O_Os>，否则这些过程被跳过。`,
        'Instrument_Condition_state': `<P1...n>至少其一需要<O>在<s1>状态，否则这些过程被跳过。`
      }
    }
  },
  'tags': {
    'multiplicity': `<tag><O_Os>`,
    'constraints': `<O_Os>; 其中<tag>`,
    'probability': `<O_Os>以<tag>的概率`,
    'path': `随路径<tag>，<opl>`,
    'range': `<r><opl>`
  },
  'symbols': {
    '?': `可选择的一个`,
    '*': `可选择的`,
    '+': `至少一个`,
    'n..n': `<n1>到<n2>个`,
    'n..*': `<n1>到更多个`
  },
  'ranges': {
    '<=': `小于等于`,
    '>=': '大于等于',
    '=': '等于',
    '<>': '不等于',
    '<': `小于`,
    '>': '大于'
  },
  'object': {
    'thing_generic_name': `目的`,
    'default_essence_affiliation': ``,
    'digital_twin': `<TWIN> is the Digital Twin of <O>.`,
    'default_essence': `<O>是<a>。`,
    'default_affiliation': `<O>是<e>。`,
    'non_default': `<O>是<e>和<a>。`,
    'singleInzoom': `<O>从<SD_Parent>在<Current_SD>放大为<T_list>。`,
    'singleInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>.`,
    'multiInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>, as well as <P_list>.`,
    'multiInzoom': `<O>从<SD_Parent>在<Current_SD>放大为<O_list>，以及<P_list>。`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<O> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<O> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <O_list>, as well as <P_list>.`,
    'single_unfold_instantiation': `<O> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    'object_list_sequence': `<O1...n>，以此纵向顺序 `,
    'object_list_parallel': `<O1...n>，以此横向顺序`
  },
  'process': {
    'thing_generic_name': `过程`,
    'default_essence_affiliation': ``,
    'default_essence': `<P>是<a>。`,
    'default_affiliation': `<P>是<e>。`,
    'non_default': `<P>是<e>和<a>。`,
    'singleInzoom': `<P>从<SD_Parent>在<Current_SD>放大为<T_list>，这些过程以此时间顺序发生。`,
    'singleInzoom_parallel': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
    'multiInzoom': `<P>从<SD_Parent>在<Current_SD>放大为<P_list>，以及<O_list>。`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<P> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<P> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_instantiation': `<P> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    // 'process_list_parallel': `<P1...n>， in that horizontal sequence`,
    'process_list_parallel': `并行的<P1...n>`,
    // 'process_list_sequence': `<P1...n>， in that vertical sequence`,
    'process_list_sequence': `<P1...n>`,
  },
  'state': {
    'single_state': `<O>是<s>。`,
    'multiple_states': `<O>可以为<s1...n>。`,
    'all_states_are_suppressed': `<O> is stateful.`,
    'one_state_shown_one_missing': `<O> is <s> or at one other state.`,
    'one_state_shown': `<O> is <s> and can be at one of <num> other states.`,
    'two_or_more_states_shown_one_missing': `<O> can be <s1...n> or at one other state.`,
    'two_or_more_states_shown': `<O> can be <s1...n> or at one of <num> other states.`,
    'default': `<s>状态为默认值。`,
    'default_initial': `<s>状态为默认和初始值。`,
    'initial': `<s>状态为初始值。`,
    'final': `<s>状态为最终值。`,
    'default_initial_final': `<s>状态为默认、初始和最终值。`,
    'initial_final': `<s>状态为初始和最终值。`,
    'default_final': `<s>状态为默认和最终值。`,
    'none': ``,
    'Current': `<O> is currently at state <s>.`,
  },
  'essence': {
    'physical': `实体的`,
    'informatical': `信息的`,
  },
  'affiliation': {
    'systemic': `系统的`,
    'environmental': `环境的`,
  },
  'semifolding': {
    'object': `<O> lists `,
    'process': `<P> lists `,
    'aggregation': {
      'single': `<T> as a part`,
      'multiple': `<T1...n-1> and <Tn> as parts`,
    },
    'exhibition': {
      'single': `<T> as a feature`,
      'multiple': `<T1...n-1> and <Tn> as features`,
    },
    'generalization': {
      'single': `<T> as a specialization`,
      'multiple': `<T1...n-1> and <Tn> as specializations`,
    },
    'instantiation': {
      'single': `<T> as an instance`,
      'multiple': `<T1...n-1> and <Tn> as instances`,
    },
  },
  'hidden_attributes': {
    'requirement': `<set_object> of <owner> is <value>.`,
  },
  'father_model_to_sub_model': `The selected things, <o1...n>, and <p1...n> are refined in sub model <subsystem_name> subsystem model view.`,
  'sub_model_from_father_model': `The <subsystem_name> subsystem model view is derived from the <father_model_name> model.`
};

export const oplTemplates_ko = {
  'structural_link': {
    /*
     * <T1> - source OPM Thing
     * <T2> - target OPM Thing
     * <T2...n> - set of target OPM Things
     */
    'Aggregation-Participation': `<T1>´Â(Àº) <T2>¸¦(À») Æ÷ÇÔÇÕ´Ï´Ù.`,
    'Aggregation-Participation_incomplete': `<T1> consists of <T2> and <num> more parts.`,
    'Aggregation-Participation_(multiple)': `<T1>´Â <T2...n>¸¦ Æ÷ÇÔÇÕ´Ï´Ù.`,
    'Aggregation-Participation_incomplete_(multiple)': `<T1> consists of <T2...n> and <num> more parts.`,
    'Generalization-Specialization': `<T2>´Â <T1>ÀÔ´Ï´Ù.`,
    'Generalization-Specialization_incomplete': `<T2> and <num> more specializations are <T1>.`,
    'Generalization-Specialization_(multiple)': `<T2...n>µéÀº <T1>ÀÔ´Ï´Ù.`,
    'Generalization-Specialization_incomplete_(multiple)': `<T2...n> and <num> more specializations are <T1>.`,
    'Classification-Instantiation': `<T2>´Â <T1>ÀÇ »ç·ÊÀÔ´Ï´Ù.`,
    'Classification-Instantiation_incomplete': `<T2> and <num> more instances are instances of <T1>.`,
    'Classification-Instantiation_(multiple)': `<T2...n>µéÀº <T1>µéÀÇ »ç·ÊÀÔ´Ï´Ù.`,
    'Classification-Instantiation_incomplete_(multiple)': `<T2...n> and <num> more instances are instances of <T1>.`,
    'Exhibition-Characterization': `<T1>´Â <T2>¸¦ ³ªÅ¸³À´Ï´Ù.`,
    'Exhibition-Characterization_incomplete': `<T1> exhibits <T2> and <num> more attributes.`,
    'Exhibition-Characterization_(multiple)': `<T1>´Â <T2...n>¸¦ ³ªÅ¸³À´Ï´Ù.`,
    'Exhibition-Characterization_incomplete_(multiple)': `<T1> exhibits <T2...n> and <num> more attributes.`,
    'Unidirectional_Tagged_Link': `<T1>´Â <T2>¿¡ ¿¬°áµË´Ï´Ù.`,
    'Unidirectional_Tagged_Link_(tag)': `<T1> <tag> <T2>.`,
    'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
    'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
    'sequence': `in that sequence.`,
    'Bidirectional_Tagged_Link': `<T1>°ú(¿Í) <T2>µéÀº µ¿µîÇÕ´Ï´Ù.`,
    'Bidirectional_Tagged_Link_(tag)': `<T1>¿Í <T2>´Â <tag>ÀÔ´Ï´Ù.`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<T1> <forward tag> <T2>, ¿Í <T2> <backward tag> <T1>.`,
  },
  'procedural_link': {
    /*
     * <O_Os> - OPM Object or OPM Stateful Object
     * <O_Os1...n> - set of OPM Objects or OPM Stateful Objects
     * <s> - OPM State
     * <P> - OPM Process
     */
    'Agent': `<O_Os>´Â <P>¸¦ Ã³¸®ÇÕ´Ï´Ù.`,
    'Agent_(multiple)': `<O\Os1...n>µéÀº <P>¸¦ Ã³¸®ÇÕ´Ï´Ù.`,
    'Agent_Condition': `¸¸¾à <O>°¡(ÀÌ) Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Agent_Condition(multiple)': `¸¸¾à <O\Os1...n>µéÀÌ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Agent_Condition_state': `¸¸¾à <O>°¡ ÀÖ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Agent_Event': `<O>´Â <P>¸¦ ½ÃÀÛ½ÃÅ°°í Ã³¸®ÇÕ´Ï´Ù.`,
    'Agent_Event(multiple)': `<O\Os1...n>µéÀº <P>¸¦ ½ÃÀÛ½ÃÅ°°í Ã³¸®ÇÕ´Ï´Ù.`,
    'Agent_Event_state': `<s> <O>´Â <P>¸¦ ½ÃÀÛ½ÃÅ°°í Ã³¸®ÇÕ´Ï´Ù.`,

    'Instrument': `<P>´Â <O_Os>¸¦ ÇÊ¿ä·Î ÇÕ´Ï´Ù.`,
    'Instrument_(multiple)': `<P>´Â <O\Os1...n>µéÀ» ÇÊ¿ä·Î ÇÕ´Ï´Ù.`,
    'Instrument_Condition': `¸¸¾à <O>°¡ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Instrument_Condition(multiple)': `¸¸¾à <O\Os1...n>°¡ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Instrument_Condition_state': `¸¸¾à <O>°¡ <s> »óÅÂ¿¡ ÀÖ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Instrument_Event': `<O>°¡ <O>¸¦ ÇÊ¿ä·ÎÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
    'Instrument_Event(multiple)': `<O\Os1...n>µéÀº ÇÊ¿ä·ÎÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
    'Instrument_Event_state': `<s> <O>°¡ ÇÊ¿ä·ÎÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,

    'Effect': `<P>´Â <O_Os>¿¡ ¿µÇâÀ» ÁÝ´Ï´Ù.`,
    'Effect_(multiple)': `<P>´Â <O\Os1...n>µé¿¡ ¿µÇâÀ» ÁÝ´Ï´Ù.`,
    'Effect_Condition': `¸¸¾à <O>°¡ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â <O>¿¡ ¿µÇâÀ» ÁÖ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Effect_Condition(multiple)': `¸¸¾à <O\Os1...n>µéÀÌ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â ÀÌ°Íµé¿¡ ¿µÇâÀ» ÁÖ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Effect_Condition_state': `¸¸¾à <O>°¡ <s> »óÅÂ¿¡ ÀÖ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â <s> <O>¿¡ ¿µÇâÀ» ÁÖ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Effect_Event': `<O>´Â <O>¿¡ ¿µÇâÀ» ÁÖ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
    'Effect_Event(multiple)': `<O\Os1...n>µéÀº <O>¿¡ ¿µÇâÀ» ÁÖ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
    'Effect_Event_state': `<s> <O>´Â <s> <O>¿¡ ¿µÇâÀ» ÁÖ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,

    'Consumption': `<P>´Â <O_Os>¸¦ »ç¿ëÇÕ´Ï´Ù.`,
    'Consumption_(multiple)': `<P>´Â <O\Os1...n>µéÀ» »ç¿ëÇÕ´Ï´Ù.`,
    'Consumption_Condition': `¸¸¾à <O>°¡ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â <O>¸¦ »ç¿ëÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Consumption_Condition(multiple)': `¸¸¾à <O\Os1...n>µéÀÌ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â ÀÌ°ÍµéÀ» »ç¿ëÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Consumption_Condition_state': `¸¸¾à <O>°¡ <s> »óÅÂ¿¡ ÀÖ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â <s> <O>¸¦ »ç¿ëÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'Consumption_Event': `<O>´Â <O>¸¦ »ç¿ëÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
    'Consumption_Event(multiple)': `<O\Os1...n>µéÀº  ÀÌ°ÍµéÀ» »ç¿ëÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
    'Consumption_Event_state': `<s> <O>´Â <s> <O>¸¦ »ç¿ëÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,

    'Result': `<P>´Â <O_Os>¸¦ ¸¸µé¾î³À´Ï´Ù.`,
    'Result_(multiple)': `<P>´Â <O\Os1...n>µéÀ» ¸¸µé¾î³À´Ï´Ù.`,

    'In-out_Link_Pair': `<P>´Â <O>¸¦ <s1>¿¡¼­ <s2>À¸·Î º¯°æÇÕ´Ï´Ù.`,
    'In-out(group)': `<O>¸¦ <s1>¿¡¼­ <s2>À¸·Î`,
    'In-out_Link_Pair(group)': `<P>´Â <O>¸¦ <s1>¿¡¼­ <s2><Other_changes>À¸·Î º¯°æÇÕ´Ï´Ù.`,
    'Split_input': `<P>´Â <O>¸¦ <s>À¸·ÎºÎÅÍ ´Ù¸¥ »óÅÂ·Î º¯°æÇÕ´Ï´Ù.`,
    'Split_output': `<P>´Â <O>¸¦ ´Ù¸¥ »óÅÂ·ÎºÎÅÍ <s>·Î º¯°æÇÕ´Ï´Ù.`,
    'Condition_Input': `¸¸¾à <O>°¡ <s1>ÀÌ¶ó¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â <O>¸¦ <s1>¿¡¼­ <s2>À¸·Î º¯°æÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'In-out_Link_Pair_Condition': `¸¸¾à <O>°¡ <s1>ÀÌ¶ó¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â <O>¸¦ <s1>¿¡¼­ <s2>À¸·Î º¯°æÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'In-out_Link_Pair_Condition(group)': `¸¸¾à <O>°¡ <s1>ÀÌ¶ó¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â <O>¸¦ <s1>¿¡¼­ <s2>À¸·Î º¯°æÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
    'In-out_Link_Pair_Event': `<s1> <O>´Â <s1>¿¡¼­ <s2>À¸·Î º¯°æÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
    'In-out_Link_Pair_Event(group)': `<s1>»óÅÂ¿¡¼­ <O>´Â <s1>¿¡¼­ <s2>À¸·Î º¯°æÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,

    'Overtime_exception': `<O>°¡ <maxtime> <units> ÀÌ»ó <s>ÀÏ ¶§ <P>¸¦ ÀÛµ¿½ÃÅµ´Ï´Ù.`,
    'Undertime_exception': `<O>°¡ <mintime> <units> ÀÌÇÏ <s>ÀÏ ¶§ <P>¸¦ ÀÛµ¿½ÃÅµ´Ï´Ù.`,
    'OvertimeUndertime-exception': `<O>°¡ <maxtime> <units> ÀÌ»óÀÌ°í <mintime> <units>ÀÌÇÏÀÏ ¶§ <P>¸¦ ÀÛµ¿½ÃÅµ´Ï´Ù.`,

    'Overtime_exception_(process)': `¸¸¾à <P1>°¡ <maxtime> <units> ÀÌ»óÀ¸·Î Áö¼ÓµÇ¸é <P2>°¡ ¹ß»ýÇÕ´Ï´Ù.`,
    'Undertime_exception_(process)': `¸¸¾à <P1>°¡ <mintime> <units> ÀÌÇÏ·Î ¶³¾îÁö¸é <P2>°¡ ¹ß»ýÇÕ´Ï´Ù.`,
    'OvertimeUndertime-exception_(process)':
      `¸¸¾à <P1>°¡ <mintime> <units> ÀÌÇÏ·Î ¶³¾îÁö°Å³ª <maxtime> <units> ÀÌ»óÀ¸·Î Áö¼ÓµÇ¸é <P2>°¡ ¹ß»ýÇÕ´Ï´Ù.`,
    'Invocation': `<P1>°¡ <P2>¸¦ È£ÃâÇÕ´Ï´Ù.`,
    'Invocation_(multiple)': `<P1>°¡ <P2...n>µé¸¦ È£ÃâÇÕ´Ï´Ù.`,
    'Invocation_(self)': `<P1>°¡ ÀÚ±âÈ£ÃâÇÕ´Ï´Ù.`,
    'Invocation_(parent)': `<P1>°¡ <P2>¸¦ È£ÃâÇÕ´Ï´Ù.`,

  },
  'grouping': { // for OPM Elements in a group
    /*
     * <T> - OPM Thing
     * <T1> - first OPM Thing
     * <T2> - second OPM Thing
     * <T1...n-1> - first n-1 OPM Things
     * <Tn> - nth OPM Thing
     * <O1...n> - first nth OPM Objects
     * <P1...n> - first nth OPM Processes
     * <s> - OPM State
     * <O> - OPM Object
     * <a> - OPM Object as an Attribute
     * <e1...n> - set of OPM Objects as Exhibitors
     */
    'Single-Thing': `<T>`,
    'Multiple-Things': `<T1...n-1> ±×¸®°í <Tn>`,
    'Multiple-Things-Object-Process-Separated': `<O1...n>, ±×¸®°í <P1...n>`,
    'AND': `<T1...n-1> ±×¸®°í <Tn>`,
    'OR': `<T1...n-1> ¶Ç´Â <Tn>`,
    'XOR': `<T1> ¹èÅ¸Àû ³í¸®ÇÕ <T2>`,
    'Stateful-Object': `<s> »óÅÂÀÇ <O>`,
    'Stateful-Object-value': `<O> 은 값이 <s>`,
    'Stateful-Object-value(multiple)': `<O> 은 값이 <s1...n>`,
    'Stateful-Object(multiple)': `<s1...n> »óÅÂÀÇ <O>`,
    'Attribute-Exhibitor': `<T>ÀÇ <e1...n>`,
    'indentation': `&nbsp;&nbsp;&nbsp;&nbsp;`,
    'Multiple-InOut': `<Other_changes> ±×¸®°í <change>.`
  },
  'logic_operators': {
    // process/object are the single thing in the XOR\OR relation
    // brothers - couple of states of the same object.
    'XOR': {
      'process': {
        'Result': '<P>´Â <O_Os1...n> ÁßÀÇ ÇÏ³ª¸¦ Á¤È®È÷ ¸¸µé¾î³À´Ï´Ù.',
        'Result(brothers)': '<P>´Â <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ª¿¡¼­ <O>¸¦ ¸¸µé¾î³À´Ï´Ù.',

        'Consumption': `<P>´Â <O_Os1...n> ÁßÀÇ ÇÏ³ª¸¦ Á¤È®È÷ »ç¿ëÇÕ´Ï´Ù.`,
        'Consumption(brothers)': `<P>´Â <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ª¿¡¼­ <O>¸¦ »ç¿ëÇÕ´Ï´Ù.`,
        'Consumption_Condition': `¸¸¾à <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ Á¤È®È÷ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â Á¸ÀçÇÏ´Â °ÍÀ» »ç¿ëÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
        'Consumption_Condition(brothers)': `'¸¸¾à <O>°¡ <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ª¶ó¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â ±×°ÍÀ» »ç¿ëÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
        'Consumption_Event': `Á¤È®ÇÏ°Ô <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ ±×°ÍÀ» »ç¿ëÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
        'Consumption_Event(brothers)': `<O>°¡ <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ªÀÏ ¶§ ±×°ÍÀ» »ç¿ëÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,


       'Effect': `<P>´Â <O_Os1...n> ÁßÀÇ ÇÏ³ª¿¡ Á¤È®È÷ ¿µÇâÀ» ÁÝ´Ï´Ù.`,
        'Effect(brothers)': `<P>´Â <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ª¿¡¼­ <O>¿¡ ¿µÇâÀ» ÁÝ´Ï´Ù.`,
        'Effect_Condition': `¸¸¾à <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ Á¤È®È÷ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â ±×°Í¿¡ ¿µÇâÀ» ÁÖ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
        'Effect_Condition(brothers)': `¸¸¾à <O>°¡ <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ª¶ó¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â ±×°Í¿¡ ¿µÇâÀ» ÁÖ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
        'Effect_Event': `Á¤È®ÇÏ°Ô <O_Os1...n> »óÅÂ ÁßÀÇ ÇÏ³ª°¡ ±×°Í¿¡ ¿µÇâÀ» ÁÖ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
        'Effect_Event(brothers)': `<O>°¡ <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ªÀÏ ¶§ ±×°Í¿¡ ¿µÇâÀ» ÁÖ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,

        'Agent': `Á¤È®ÇÏ°Ô <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ <P>¸¦ Ã³¸®ÇÕ´Ï´Ù.`,
        'Agent(brothers)': `<O>°¡ <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ªÀÏ ¶§ <P>¸¦ Ã³¸®ÇÕ´Ï´Ù.`,
        'Agent_Event': `Á¤È®ÇÏ°Ô <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ <P>¸¦ ½ÃÀÛ½ÃÅ°°í Ã³¸®ÇÕ´Ï´Ù.`,
        'Agent_Event(brothers)': `<O>°¡ <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ªÀÏ ¶§ ±×°ÍÀ» »ç¿ëÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅ°°í Ã³¸®ÇÕ´Ï´Ù.`,
        'Agent_Condition': `¸¸¾à <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ Á¤È®È÷ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
        'Agent_Condition(brothers)': `¸¸¾à <O>°¡ <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ª¶ó¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,

        'Instrument': `<P>´Â <O_Os1...n> ÁßÀÇ ÇÏ³ª¸¦ Á¤È®È÷ ÇÊ¿ä·Î ÇÕ´Ï´Ù.`,
        'Instrument(brothers)': `<P>´Â <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ª¿¡¼­ <O>¸¦ ÇÊ¿ä·Î ÇÕ´Ï´Ù.`,
        'Instrument_Condition': `¸¸¾à <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ Á¤È®È÷ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
        'Instrument_Condition(brothers)': `¸¸¾à <O>°¡ <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ª¶ó¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
        'Instrument_Event': `Á¤È®ÇÏ°Ô <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ ±×°ÍÀ» »ç¿ëÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
        'Instrument_Event(brothers)': `<O>°¡ <s1...n> »óÅÂ ÁßÀÇ ÇÏ³ªÀÏ ¶§ ±×°ÍÀ» ÇÊ¿ä·Î ÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,

        'Invocation_OUT': `<P>´Â <P1...n> ÁßÀÇ ÇÏ³ª¸¦ È£ÃâÇÕ´Ï´Ù.`,
        'Invocation_IN': `<P1...n> ÁßÀÇ ÇÏ³ª°¡ <P>¸¦ È£ÃâÇÕ´Ï´Ù.`,

        'InOut': `<P> changes <O> from <s1> to exactly one of <O_Os2...n>.`,
        'InOut_multi_Ins_Xor': `<P> changes <O> from exactly one of <ins1..n> to exactly one of <O_Os2...n>.`,
      },
      'object': { // _state means the link fan is from state.
        'Result': `Á¤È®ÇÏ°Ô <P1...n> ÁßÀÇ ÇÏ³ª°¡ <O_Os>¸¦ ¸¸µé¾î³À´Ï´Ù.`,

        'Consumption': `Á¤È®ÇÏ°Ô <P1...n> ÁßÀÇ ÇÏ³ª°¡ <O_Os>¸¦ »ç¿ëÇÕ´Ï´Ù.`,
        'Consumption_Event': `<O_Os>´Â ½ÃÀÛ ÇÁ·Î¼¼½º¸¦ »ç¿ëÇÏ´Â <P1...n> ÁßÀÇ ÇÏ³ª¸¦ Á¤È®È÷ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
        'Consumption_Condition_state': `¸¸¾à <O>°¡ <s1>ÀÌ¶ó¸é Á¤È®ÇÏ°Ô <P1...n> ÁßÀÇ ÇÏ³ª°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ ¹ß»ý ÇÁ·Î¼¼½º´Â <O>¸¦ »ç¿ëÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,
        'Consumption_Condition': `¸¸¾à <O_Os>°¡ Á¸ÀçÇÑ´Ù¸é Á¤È®ÇÏ°Ô <P1...n> ÁßÀÇ ÇÏ³ª°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ ¹ß»ý ÇÁ·Î¼¼½º´Â <O_Os>¸¦ »ç¿ëÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,

        'Effect': `Á¤È®ÇÏ°Ô <P1...n> ÁßÀÇ ÇÏ³ª°¡ <O_Os>¿¡ ¿µÇâÀ» ÁÝ´Ï´Ù.`,
        'Effect_Event': `<O_Os>°¡ ¹ß»ý ÇÁ·Î¼¼½ºµé¿¡ ¿µÇâÀ» ÁÖ´Â <P1...n> ÁßÀÇ ÇÏ³ª¸¦ Á¤È®È÷ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
        'Effect_Condition': `¸¸¾à <O_Os>°¡ Á¸ÀçÇÑ´Ù¸é Á¤È®ÇÏ°Ô <P1...n> ÁßÀÇ ÇÏ³ª°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ ¹ß»ý ÇÁ·Î¼¼½º´Â <O_Os>¿¡ ¿µÇâÀ» ÁÖ°í, ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,

        'Agent': `<O_Os>´Â <P1...n> ÁßÀÇ ÇÏ³ª¸¦ Á¤È®È÷ Ã³¸®ÇÕ´Ï´Ù.`,
        'Agent_Event': `<O_Os>´Â <P1...n> ÁßÀÇ ÇÏ³ª¸¦ Á¤È®È÷ ½ÃÀÛ½ÃÅ°°í Ã³¸®ÇÕ´Ï´Ù.`,
        'Agent_Condition': `¸¸¾à <O_Os>°¡ Á¸ÀçÇÑ´Ù¸é <O_Os>°¡ <P1...n> ÁßÀÇ ÇÏ³ª¸¦ Á¤È®È÷ Ã³¸®ÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,
        'Agent_Condition_state': `¸¸¾à <O>°¡ <s1>ÀÌ¶ó¸é <O>´Â <P1...n> ÁßÀÇ ÇÏ³ª¸¦ Á¤È®È÷ Ã³¸®ÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,

        'Instrument': `Á¤È®ÇÏ°Ô <P1...n> ÁßÀÇ ÇÏ³ª°¡ <O_Os>¸¦ ÇÊ¿ä·Î ÇÕ´Ï´Ù.`,
        'Instrument_Event': `<O_Os>¸¦ ÇÊ¿ä·Î ÇÏ´Â <O_Os>°¡ <P1...n> ÁßÀÇ ÇÏ³ª¸¦ Á¤È®È÷ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
        'Instrument_Condition': `Á¤È®ÇÏ°Ô <P1...n> ÁßÀÇ ÇÏ³ª´Â <O_Os>¸¦ ÇÊ¿ä·Î ÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,
        'Instrument_Condition_state': `Á¤È®ÇÏ°Ô <P1...n> ÁßÀÇ ÇÏ³ª´Â <O>°¡ <s1>ÀÓÀ» ÇÊ¿ä·Î ÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`
      }
    },
    'OR': {
      'process': {
        'Result': '<P>´Â Àû¾îµµ <O_Os1...n> ÁßÀÇ ÇÏ³ª¸¦ ¸¸µé¾î³À´Ï´Ù.',

        'Consumption': `<P>´Â Àû¾îµµ <O_Os1...n> ÁßÀÇ ÇÏ³ª¸¦ »ç¿ëÇÕ´Ï´Ù.`,
        'Consumption_Condition': `¸¸¾à <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ Àû¾îµµ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â Á¸ÀçÇÏ´Â ±×°ÍÀ» »ç¿ëÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
        'Consumption_Event': `Àû¾îµµ <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ ±×°ÍÀ» »ç¿ëÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,

        'Effect': `<P>´Â <O_Os1...n> ÁßÀÇ ÇÏ³ª¿¡ ¿µÇâÀ» ÁÝ´Ï´Ù.`,
        'Effect_Condition': `¸¸¾à <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ Àû¾îµµ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ <P>´Â ±×°Í¿¡ ¿µÇâÀ» ÁÖ°í, ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
        'Effect_Event': `Àû¾îµµ <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ ±×°Í¿¡ ¿µÇâÀ» ÁÖ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,

        'Agent': `Àû¾îµµ <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ <P>¸¦ Ã³¸®ÇÕ´Ï´Ù.`,
        'Agent_Event': `Àû¾îµµ <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ <P>¸¦ ½ÃÀÛ½ÃÅ°°í Ã³¸®ÇÕ´Ï´Ù.`,
        'Agent_Condition': `¸¸¾à <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ Àû¾îµµ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,

        'Instrument': `<P>´Â Àû¾îµµ <O_Os1...n> ÁßÀÇ ÇÏ³ª¸¦ ÇÊ¿ä·Î ÇÕ´Ï´Ù.`,
        'Instrument_Condition': `¸¸¾à <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ Àû¾îµµ Á¸ÀçÇÑ´Ù¸é <P>°¡ ¹ß»ýÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é <P>´Â »ý·«µË´Ï´Ù.`,
        'Instrument_Event': `Àû¾îµµ <O_Os1...n> ÁßÀÇ ÇÏ³ª°¡ ±×°ÍÀ» ÇÊ¿ä·Î ÇÏ´Â <P>¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,


        'Invocation_OUT': `<P>´Â <P1...n> ÁßÀÇ ÇÏ³ª¸¦ È£ÃâÇÕ´Ï´Ù.`,
        'Invocation_IN': `Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª°¡ <P>¸¦ È£ÃâÇÕ´Ï´Ù.`,

        'InOut': `<P> changes <O> from <s1> to at least one of <O_Os2...n>.`,
      },
      'object': {
        'Result': `Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª°¡ <O_Os>¸¦ ¸¸µé¾î³À´Ï´Ù`,

        'Consumption': `Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª´Â <O_Os>¸¦ »ç¿ëÇÕ´Ï´Ù`,
        'Consumption_Event': `<O_Os>´Â Àû¾îµµ ½ÃÀÛ ÇÁ·Î¼¼½º¸¦ »ç¿ëÇÏ´Â <P1...n> ÁßÀÇ ÇÏ³ª¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
        'Consumption_Condition_state': `¸¸¾à  <O>°¡ <s1>ÀÌ¶ó¸é Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª´Â ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ ¹ß»ý ÇÁ·Î¼¼½º´Â <O>, ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,
        'Consumption_Condition': `¸¸¾à <O_Os>°¡ Á¸ÀçÇÑ´Ù¸é Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª°¡ ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ ¹ß»ý ÇÁ·Î¼¼½º´Â <O_Os>¸¦ »ç¿ëÇÏ°í, ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,

        'Effect': `Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª´Â <O_Os>¿¡ ¿µÇâÀ» ÁÝ´Ï´Ù`,
        'Effect_Event': `<O_Os>´Â Àû¾îµµ ¹ß»ý ÇÁ·Î¼¼½º¿¡ ¿µÇâÀ» ÁÖ´Â <P1...n> ÁßÀÇ ÇÏ³ª¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù.`,
        'Effect_Condition': `¸¸¾à <O_Os>°¡ Á¸ÀçÇÑ´Ù¸é Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª´Â ¹ß»ýÇÕ´Ï´Ù. ÀÌ °æ¿ì¿¡ ¹ß»ý ÇÁ·Î¼¼½º´Â <O_Os>¿¡ ¿µÇâÀ» ÁÖ°í, ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,

        'Agent': `<O_Os>´Â Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª¸¦ Ã³¸®ÇÕ´Ï´Ù.`,
        'Agent_Event': `<O_Os>´Â Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª¸¦ ½ÃÀÛ½ÃÅ°°í Ã³¸®ÇÕ´Ï´Ù.`,
        'Agent_Condition': `¸¸¾à <O_Os>°¡ Á¸ÀçÇÑ´Ù¸é Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª¸¦ Ã³¸®ÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,
        'Agent_Condition_state': `¸¸¾à <O>°¡ <s1>ÀÌ¶ó¸é <O>°¡ Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª¸¦ Ã³¸®ÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,

        'Instrument': `Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª´Â <O_Os>¸¦ ÇÊ¿ä·Î ÇÕ´Ï´Ù`,
        'Instrument_Event': `<O_Os>´Â Àû¾îµµ <O_Os>¸¦ ÇÊ¿ä·ÎÇÏ´Â <P1...n> ÁßÀÇ ÇÏ³ª¸¦ ½ÃÀÛ½ÃÅµ´Ï´Ù`,
        'Instrument_Condition': `Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª´Â <O_Os>¸¦ ÇÊ¿ä·Î ÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`,
        'Instrument_Condition_state': `Àû¾îµµ <P1...n> ÁßÀÇ ÇÏ³ª´Â <O>°¡ <s1>ÀÓÀ» ÇÊ¿ä·Î ÇÕ´Ï´Ù. ±×·¸Áö ¾ÊÀ¸¸é ÀÌµé ÇÁ·Î¼¼½ºµéÀº »ý·«µË´Ï´Ù.`
      }
    }
  },
  'tags': {
    'multiplicity': `<tag> <O_Os>`,
    'constraints': `<O_Os>; Á¦ÇÑ <tag>`,
    'probability': `<O_Os> È®·ü <tag>`,
    'rate': `<O_Os> ºñÀ² <tag> <units>`,
    'path': `°æ·Î <tag>, <opl>`,
    'range': `<r> <opl>`
  },
  'symbols': {
    '?': `¼±ÅÃ»çÇ×`,
    '*': `¼±ÅÃÀû`,
    '+': `Àû¾îµµ ÇÏ³ª`,
    'n..n': `<n1> ´ë <n2>`,
    'n..*': `<n1> ´ë ´Ù¼ö`
  },
  'ranges': {
    '<=': `ÀÌÇÏ`,
    '>=': 'ÀÌ»ó',
    '=': '°°À½',
    '<>': '´Ù¸§',
    '<': `¹Ì¸¸`,
    '>': 'ÃÊ°ú'
  },
  'object': {
    'thing_generic_name': `물체`,
    'default_essence_affiliation': ``,
    'digital_twin': `<TWIN> is the Digital Twin of <O>.`,
    'default_essence': `<O>´Â <a>ÀÔ´Ï´Ù.`,
    'default_affiliation': `<O>´Â <e>ÀÔ´Ï´Ù.`,
    'non_default': `<O>´Â <e> ±×¸®°í <a>ÀÔ´Ï´Ù.`,
    'singleInzoom': `<SD_Parent>À¸·ÎºÎÅÍ <O>´Â ¼øÂ÷ÀûÀ¸·Î ¹ß»ýÇÏ´Â <T_list> ¾ÈÀ¸·Î <Current_SD> ÁÜÀÎµË´Ï´Ù.`,
    'singleInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>.`,
    'multiInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>, as well as <P_list>.`,
    'multiInzoom': `<SD_Parent>À¸·ÎºÎÅÍ <O>´Â <P_list> ±×¸®°í <O_list> ¾ÈÀ¸·Î <Current_SD> ÁÜÀÎµË´Ï´Ù.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<O> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<O> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <O_list>, as well as <P_list>.`,
    'single_unfold_instantiation': `<O> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    'object_list_sequence': `'¼öÁ÷¼ø¼­ <O1...n>`,
  'object_list_parallel': `¼öÆò¼ø¼­ <O1...n>`
},
'process': {
  'thing_generic_name': `프로세스`,
  'default_essence_affiliation': ``,
  'default_essence': `<P>´Â <a>ÀÔ´Ï´Ù.`,
  'default_affiliation': `<P>´Â <e>ÀÔ´Ï´Ù.`,
  'non_default': `<P>´Â <e> ±×¸®°í <a>ÀÔ´Ï´Ù.`,
  'singleInzoom': `<SD_Parent>À¸·ÎºÎÅÍ <P>´Â ¼øÂ÷ÀûÀ¸·Î ¹ß»ýÇÏ´Â <T_list> ¾ÈÀ¸·Î <Current_SD> ÁÜÀÎµË´Ï´Ù.`,
  'singleInzoom_parallel': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
  'multiInzoom': `<SD_Parent>À¸·ÎºÎÅÍ <P>´Â <P_list> ±×¸®°í <O_list> ¾ÈÀ¸·Î <Current_SD> ÁÜÀÎµË´Ï´Ù.`,
  'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
  'single_unfold_aggregation': `<P> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
  'single_unfold_generalization': `<P> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
  'single_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
  'multi_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <P_list>, as well as <O_list>.`,
  'single_unfold_instantiation': `<P> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
  'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
  // 'process_list_parallel': `¼öÆò¼ø¼­ <P1...n>`,
  'process_list_parallel': `ÆòÇà <P1...n>`,
  // 'process_list_sequence': `¼öÁ÷¼ø¼­ <P1...n>`,
  'process_list_sequence': `<P1...n>`,
  'expected_duration': `<P>ÀÇ ±â´ëÁö¼Ó°ªÀº <exp> <units>ÀÔ´Ï´Ù.`,
   'min_duration': `<P>ÀÇ ÃÖ¼ÒÁö¼Ó°ªÀº <min> <units>ÀÔ´Ï´Ù.`,
   'max_duration': `<P>ÀÇ ÃÖ´ëÁö¼Ó°ªÀº <max> <units>ÀÔ´Ï´Ù.`,
  'min_max_range_duration': `<P>ÀÇ ÃÖ¼ÒÁö¼Ó°ª, ÃÖ´ëÁö¼Ó°ªÀº °¢°¢ <min> <units>, <max> <units>ÀÔ´Ï´Ù.`,
  'min_exp_range_duration' : `<P>ÀÇ ÃÖ¼ÒÁö¼Ó°ª, ±â´ëÁö¼Ó°ª °¢°¢ <min> <units>, <exp> <units>ÀÔ´Ï´Ù.`,
  'exp_max_range_duration' : `<P>ÀÇ ±â´ëÁö¼Ó°ª, ÃÖ´ëÁö¼Ó°ªÀº °¢°¢ <exp> <units>, <max> <units>ÀÔ´Ï´Ù.`,
  'expected_range_duration': `<P>ÀÇ ÃÖ¼ÒÁö¼Ó°ª, ±â´ëÁö¼Ó°ª, ÃÖ´ëÁö¼Ó°ªÀº °¢°¢ <min> <units>, <exp> <units>, <max> <units>ÀÔ´Ï´Ù.`
},
'state': {
  'single_state': `<O>´Â <s>ÀÔ´Ï´Ù.`,
    'multiple_states': `<O>´Â <s1...n>°¡ µÉ ¼ö ÀÖ½À´Ï´Ù.`,
    'all_states_are_suppressed': `<O> is stateful.`,
    'one_state_shown_one_missing': `<O> is <s> or at one other state.`,
    'one_state_shown': `<O> is <s> and can be at one of <num> other states.`,
    'two_or_more_states_shown_one_missing': `<O> can be <s1...n> or at one other state.`,
    'two_or_more_states_shown': `<O> can be <s1...n> or at one of <num> other states.`,
    'default': `<s>´Â ±âº»»óÅÂÀÔ´Ï´Ù.`,
    'default_initial': `<s>´Â ±âº»»óÅÂÀÌ°í ÃÊ±â»óÅÂÀÔ´Ï´Ù.`,
    'initial': `<s>´Â ÃÊ±â»óÅÂÀÔ´Ï´Ù.`,
    'final': `<s>´Â ¸¶Áö¸·»óÅÂÀÔ´Ï´Ù.`,
    'default_initial_final': `<s>´Â ÃÊ±â»óÅÂ, ¸¶Áö¸·»óÅÂ, ±âº»»óÅÂÀÔ´Ï´Ù.`,
    'initial_final': `<s>´Â ÃÊ±â»óÅÂÀÌ°í ¸¶Áö¸·»óÅÂÀÔ´Ï´Ù.`,
    'default_final': `<s>´Â ¸¶Áö¸·»óÅÂÀÌ°í ±âº»»óÅÂÀÔ´Ï´Ù.`,
    'none': ``,
    'Current': `<O> is currently at state <s>.`,
 // 'timeDurational': {
    'expected_duration': `<s>ÀÇ ±â´ëÁö¼Ó°ªÀº <exp> <units>ÀÔ´Ï´Ù.`,
    'min_duration': `<s>ÀÇ ÃÖ¼ÒÁö¼Ó°ªÀº <min> <units>ÀÔ´Ï´Ù.`,
    'max_duration': `<s>ÀÇ ÃÖ´ëÁö¼Ó°ªÀº <max> <units>ÀÔ´Ï´Ù.`,
    'min_max_range_duration': `<s>ÀÇ ÃÖ¼ÒÁö¼Ó°ª, ÃÖ´ëÁö¼Ó°ªÀº °¢°¢ <min> <units>, <max> <units>ÀÔ´Ï´Ù.`,
    'min_exp_range_duration': `<s>ÀÇ ÃÖ¼ÒÁö¼Ó°ª, ±â´ëÁö¼Ó°ª °¢°¢ <min> <units>, <exp> <units>ÀÔ´Ï´Ù.`,
    'exp_max_range_duration': `<s>ÀÇ ±â´ëÁö¼Ó°ª, ÃÖ´ëÁö¼Ó°ªÀº °¢°¢ <exp> <units>, <max> <units>ÀÔ´Ï´Ù.`,
    'expected_range_duration': `<s>ÀÇ ÃÖ¼ÒÁö¼Ó°ª, ±â´ëÁö¼Ó°ª, ÃÖ´ëÁö¼Ó°ªÀº °¢°¢ <min> <units>, <exp> <units>, <max> <units>ÀÔ´Ï´Ù.`,
    'default_time_units': `seconds`
  // },
  },
  'essence': {
    'physical': `¹°¸®Àû`,
      'informatical': `Á¤º¸Àû`,
  },
  'affiliation': {
    'systemic': `½Ã½ºÅÛÀû`,
      'environmental': `È¯°æÀû`,
  },
  'semifolding': {
    'object': `<O> lists `,
    'process': `<P> lists `,
    'aggregation': {
      'single': `<T> as a part`,
      'multiple': `<T1...n-1> and <Tn> as parts`,
    },
    'exhibition': {
      'single': `<T> as a feature`,
      'multiple': `<T1...n-1> and <Tn> as features`,
    },
    'generalization': {
      'single': `<T> as a specialization`,
      'multiple': `<T1...n-1> and <Tn> as specializations`,
    },
    'instantiation': {
      'single': `<T> as an instance`,
      'multiple': `<T1...n-1> and <Tn> as instances`,
    },
  },
  'hidden_attributes': {
    'requirement': `<set_object> of <owner> is <value>.`,
  },
  'father_model_to_sub_model': `The selected things, <o1...n>, and <p1...n> are refined in sub model <subsystem_name> subsystem model view.`,
  'sub_model_from_father_model': `The <subsystem_name> subsystem model view is derived from the <father_model_name> model.`
};





// export function changeOplTemplates(){
// oplTemplates = oplDefaultSettings[oplDefaultSettings.language];
// changeOplTable();
// if(this.OplService.orgOplSettings.language === 'cn')
// oplTemplates = oplTemplates_cn;
// console.log(oplTemplates);
// };

// export function updateDefaultSettings(uSettings, oSettings){
//   userOplSettings = uSettings;
//   orgOplSettings = oSettings;
//   for (const key of Object.keys(oplDefaultSettings)){
//     if (uSettings[key]){
//       oplDefaultSettings[key]=uSettings[key];
//     }else{
//       oplDefaultSettings[key]=oSettings[key];
//     }
//   }
//   // for (const field of Object.keys(oplTemplates)){
//   //   for (const key of Object.keys(oplTemplates[field])){
//   //     if()
//   //   }
//   // }
//   oplTemplates = oSettings.oplTables[uSettings.language];
//   changeOplTable();
// }

/*
 * oplTable defines necessary fields in linkTable
 * and the linkTable is constructed in changeOplTable function based on oplTable
*/
export const oplTable = {
  'O1-O2': {
    'Aggregation-Participation': `<O1> consists of <O2>.`,
    'Unidirectional_Tagged_Link': `<O1> relates to <O2>.`,
    'Bidirectional_Tagged_Link': `<O1> and <O2> are equivalent.`,
    'Exhibition-Characterization': `<O1> exhibits <O2>.`,
    'Generalization-Specialization': `<O2> is a <O1>.`,
    'Unidirectional_Tagged_Link_(tag)': `<O1> <tag> <O2>.`,
    'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
    'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
    'sequence': `in that sequence.`,
    'Bidirectional_Tagged_Link_(tag)': `<O1> and <O2> are <tag>.`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<O1> <forward tag> <O2>, and <O2> <backward tag> <O1>.`,
    'Classification-Instantiation': `<O2> is an instance of <O1>.`,
  },
  'P1-P2': {
    'Overtime_exception': `<P2> occurs if <P1> lasts more than <maxtime> <units>.`,
    'Undertime_exception': `<P2> occurs if <P1> falls short of <mintime> <units>.`,
    'Aggregation-Participation': `<P1> consist of <P2>.`,
    'Exhibition-Characterization': `<P1> exhibits <P2>.`,
    'OvertimeUndertime-exception':
      `<P2> occurs if <P1> falls short of <mintime> <units> or lasts more than <maxtime> <units>.`,
    'Unidirectional_Tagged_Link': `<P1> relates to <P2>.`,
    'Invocation': `<P1> invokes <P2>.`,

    'Bidirectional_Tagged_Link': `<P1> and <P2> are equivalent.`,
    'Generalization-Specialization': `<P2> is a <P1>.`,
    'Classification-Instantiation': `<P2> is an instance of <P1>.`,
    'Unidirectional_Link_(tag)': `<P1> <tag> <P2>.`,
    'Bidirectional_Link_(tag)': `<P1> and <P2> are <forward tag>.`,
    'Bidirectional_Link_(ftag,btag)': `<P1> <forward tag> <P2> and <P2> <backward tag> <P1>.`,

  },
  'P1-P1 (same process)': {
    'Invocation': `<P1> invokes itself.`
  },
  'P1-P2 (parent process)': {
    'Invocation': `<P1> invokes <P2>.`
  },
  'O-P': {
    'Agent': `<O> handles <P>.`,
    'Agent_Negation': `<P1...n> occurs if <O_Os> is not present.`,
    'Agent_Condition': `<P> occurs if <O> exists, otherwise <P> is skipped.`,
    'Agent_Condition_Negation': `<P1...n> occurs if <O_Os> is not present, otherwise all these processes are skipped.`,
    'Agent_Event': `<O> initiates and handles <P>.`,
    'Consumption': `<P> consumes <O>.`,
    'Consumption_Negation': `<P> does not consume <O>.`,
    'Consumption_Condition': `<P> occurs if <O> exists, in which case<P>  consumes <O>, otherwise <P>  is skipped.`,
    'Consumption_Condition_Negation': `<P> occurs if <O> does not exist, in which case<P>  consumes <O>, otherwise <P>  is skipped.`,
    'Consumption_Event': `<O> initiates <P>, which consumes <O>.`,
    'Instrument': `<P> requires <O>.`,
    'Instrument_Negation': `<P> occurs if <O> does not exist.`,
    'Instrument_Condition': `<P> occurs if <O> exists, otherwise <P>  is skipped.`,
    'Instrument_Condition_Negation': `<P> occurs if <O> does not exist, otherwise <P>  is skipped.`,
    'Instrument_Event': `<O> initiates <P>, which requires <O>.`,
    'Exhibition-Characterization': `<O> exhibits <P>.`,
    'Effect': `<P> affects <O>.`,
    'Effect_Negation': `<P> does not affect <O>.`,
    'Effect_Event': `<O> initiates <P>, which affects <O>`,
    'Effect_Condition': `<P> occurs if <O> exists, in which case <P>  affects <O>, otherwise <P>  is skipped.`,
    'Effect_Condition_Negation': `<P> occurs if <O> does not exist, in which case <P>  affects <O>, otherwise <P>  is skipped.`
  },
  'P-O': {
    'Exhibition-Characterization': `<P> exhibits <O>.`,
    'Result': `<P> yields <O>.`,
    'Effect': `<P> affects <O>.`,
    'Effect_Negation': `<P> does not affect <O>.`,
    'Effect_Event': `<O> initiates <P>, which affects <O>`,
    'Effect_Condition': `<P> occurs if <O> exists, in which case<P>  affects <O>, otherwise <P>  is skipped.`,
    'Effect_Condition_Negation': `<P> occurs if <O> does not exist, in which case<P>  affects <O>, otherwise <P>  is skipped.`
  },

  'Os-(P)-O (from object state to the same object)': {
    'Overtime_exception': `<O> triggers <P> when <O> is <s> more than <maxtime> <units>, in which case <P> changes <O>.`,
    'Condition_Input ': `<P> occurs if <O> is <s>, in which case <P> changes <O> from <s> , otherwise <P>  is skipped.`,
    'In-out_Link_Pair': `<P> changes <O> from <s>.`,
    'In-out_Link_Pair_Condition': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P> is skipped.`,
    'In-out_Link_Pair_Event': `<O> at state <s1> initiates <P>, which changes <O> from <s1> to <s2>.`
  },
  'O1s-O2': {
    'Generalization-Specialization': `<O2> is a <O1> at state <s>.`,
    'Aggregation-Participation': `<O1> <s> consists of <O2>.`,
    'Bidirectional_Tagged_Link_(tag)': `<s> <O1> and <O2> are <forward tag>.`,
    'Unidirectional_Tagged_Link': `<s> <O1> relates to <O2>.`,
    'Bidirectional_Tagged_Link': `<s> <O1> and <O2> are equivalent.`,
    'Unidirectional_Tagged_Link_(tag)': `<s> <O1> <tag> <O2>.`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<s> <O1>  <forward tag> <O2>, and <O2> <backward tag> <s> <O1>.`,
    'Exhibition-Characterization': `<s> <O1> exhibits <O2>.`
  },
  'P-Os': {
    'In-out_Link_Pair': `<P> changes <O> from <s1> to <s2>.`,
    'Split_output': `<P> changes <O> to <s>.`,
    'In-out_Link_Pair_Condition': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P> is skipped.`,
    'In-out_Link_Pair_Event': `<O> at state <s1> initiates <P>, which changes <O> from <s1> to <s2>.`,
    'Exhibition-Characterization': `<P> exhibits <s> <O>.`,
    'Result': `<P> yields <s> <O>.`
  },
  'O1s1-O2s2': {
    'Unidirectional_Tagged_Link': `<s1> <O1> relates to <s2> <O2>.`,
    'Bidirectional_Tagged_Link_(tag)': `<s1> <O1> and <s2> <O2> are <forward tag>.`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<s1> <O1> <forward tag> <s2><O2>, and <s2><O2> <backward tag><s1> <O1>.`,
    'Bidirectional_Tagged_Link': `<s1> <O1> and <s2> <O2> are equivalent.`,
    'Unidirectional_Tagged_Link_(tag)': `<s1> <O1> <tag> <s2> <O2>.`,
    'Exhibition-Characterization': `<s> <O1> exhibits <s2> <O2>.`
  },
  'O1-T2..n (n>=2 many destinations)': {
    'Aggregation-Participation': `<O1> consist of <T1...n-1> and <Tn>.`,
    'Unidirectional_Tagged_Link': `<O1> relates to <T1...n-1> and <Tn>.`,
    'Generalization-Specialization': `<T1...n-1> and <Tn> are <O1>.`,
    'Classification-Instantiation': `<T1...n-1> and <Tn> are instances of <O1>.`,
    'Unidirectional_Tagged_Link_(tag)': `<O1> <tag> <T1...n-1> and <Tn>.`,
    'Exhibition-Characterization': `<O1> exhibits <T1...n-1> and <Tn>.`
  },
  'P1-T2..n (n>=2 many destinations)': {
    'Exhibition-Characterization': `<P1> exhibits <T1...n-1> and <Tn>.`,
    'Aggregation-Participation': `<P1> consist of <T1...n-1> and <Tn>.`,
    'Unidirectional_Tagged_Link': `<P1> relates to <T1...n-1> and <Tn>.`,
    'Generalization-Specialization': `<T1...n-1> and <Tn> are <P1>.`,
    'Classification-Instantiation': `<T1...n-1> and <Tn> are instances of <P1>.`,
    'Unidirectional_Tagged_Link_(tag)': `<P1> <tag> <T1...n-1> and <Tn>.`
  },
  'O1s1-T2..n (n>=2 many destinations)': {
    'Exhibition-Characterization': `<s1> <O1> exhibits <T1...n-1> and <Tn>.`
  },
  'O1-O2s': {
    'Aggregation-Participation': `<O1> consists of <s> <O2>.`,
    'Bidirectional_Link_(tag)': `<O1> and <s> <O2> are <forward tag>.`,
    'Unidirectional_Tagged_Link': `<O1> relates to <s> <O2>.`,
    'Bidirectional_Tagged_Link': `<O1> and <s> <O2> are equivalent.`,
    'Unidirectional_Link_(tag)': `<O1> <tag> <s> <O2>.`,
    'Bidirectional_Link_(ftag,btag)': `<O1> <forward tag> <s><O2> and <s><O2> <backward tag> to  <O1>.`,
    'Exhibition-Characterization': `<O1> exhibits <s> <O2>.`
  },
  'Os1-(P)-Os2 (same object)': {
    'Overtime_exception':
      `<O> triggers <P> when <O> is <s1> more than <maxtime> <units>, in which case <P> changes <O> to <s2>.`,
    'Condition_Input ': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P>  is skipped.`,
    'In-out_Link_Pair': `<P> changes <O> from <s1> to <s2>.`,
    'In-out_Link_Pair_Condition': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P> is skipped.`,
    'In-out_Link_Pair_Event': `<O> at state <s1> initiates <P>, which changes <O> from <s1> to <s2>.`
  },
  'Os-P': {
    'In-out_Link_Pair': `<P> changes <O> from <s1> to <s2>.`,
    'Agent': `<s> <O> handles <P>.`,
    'Agent_Negation': `<P> occurs if <O> is not at state <s>.`,
    'Agent_Condition': `<P> occurs if <O> is <s>, otherwise <P> is skipped.`,
    'Agent_Condition_Negation': `<P> occurs if <O> is not at state <s>, otherwise <P> is skipped.`,
    'Agent_Event': `<s> <O> initiates and handles<P>.`,

    'Consumption': `<P> consumes <s> <O>.`,
    'Consumption_Negation': `<P> does not consume <s> <O>.`,
    'Consumption_Condition': `<P> occurs if <O> is at state <s>, in which case <P> consumes <O>, otherwise <P> is skipped.`,
    'Consumption_Condition_Negation': `<P> occurs if <O> is not at state <s>, in which case <P> consumes <O>, otherwise <P> is skipped.`,
    'Consumption_Event': `<s> <O> initiates <P>, which consumes <O>.`,

    'Split_input': `<P> changes <O> from <s>.`,
    'In-out_Link_Pair_Condition': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P> is skipped.`,
    'In-out_Link_Pair_Event': `<O> at state <s1> initiates <P>, which changes <O> from <s1> to <s2>.`,

    'Instrument': `<P> requires <s> <O>.`,
    'Instrument_Negation': `<P> occurs if <O> is not at state <s>.`,
    'Instrument_Condition': `<P> occurs if <O> is at state <s>, otherwise <P> is skipped.`,
    'Instrument_Condition_Negation': `<P> occurs if <O> is not at state <s>, otherwise <P> is skipped.`,
    'Instrument_Event': `<s> <O> initiates <P>, which requires <s> <O>.`,

    'Overtime_exception': `<O> triggers <P> when <O> is <s> more than <maxtime> <units>.`,
    'Undertime_exception': `<O> triggers <P> when <O> is <s> less than <mintime> <units>.`,
    'OvertimeUndertime-exception': `<O> triggers <P> when <O> is <s> more than <maxtime> <units> and less than <mintime> <units>.`,
    'Exhibition-Characterization': `<s> <O> exhibits <P>.`
  },
};

export const oplTemplates_jp = {
  'structural_link': {
    /*
     * <T1> - source OPM Thing
     * <T2> - target OPM Thing
     * <T2...n> - set of target OPM Things
     */
    'Aggregation-Participation': `<T1> は <T2> から構成されます。`,
    'Aggregation-Participation_incomplete': `<T1> consists of <T2> and <num> more parts.`,
    'Aggregation-Participation_(multiple)': `<T1> は <T2...n> から構成されます。`,
    'Aggregation-Participation_incomplete_(multiple)': `<T1> consists of <T2...n> and <num> more parts.`,
    'Generalization-Specialization': `<T2> は <T1> です。`,
    'Generalization-Specialization_incomplete': `<T2> and <num> more specializations are <T1>.`,
    'Generalization-Specialization_(multiple)': `<T2...n> は <T1> です。`,
    'Generalization-Specialization_incomplete_(multiple)': `<T2...n> and <num> more specializations are <T1>.`,
    'Classification-Instantiation': `<T2> は <T1> のインスタンスです。`,
    'Classification-Instantiation_incomplete': `<T2> and <num> more instances are instances of <T1>.`,
    'Classification-Instantiation_(multiple)': `<T2...n> は <T1> のインスタンスです。`,
    'Classification-Instantiation_incomplete_(multiple)': `<T2...n> are instances of <T2> and <num> more parts.`,
    'Exhibition-Characterization': `<T1> は <T2> を表します。`,
    'Exhibition-Characterization_incomplete': `<T1> exhibits <T2> and <num> more attributes.`,
    'Exhibition-Characterization_(multiple)': `<T1> は <T2...n> を表します。`,
    'Exhibition-Characterization_incomplete_(multiple)': `<T1> exhibits <T2...n> and <num> more attributes.`,
    'Unidirectional_Tagged_Link': `<T1> は <T2> に関係します。`,
    'Unidirectional_Tagged_Link_(tag)': `<T1> <tag> <T2> 。`,
    'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
    'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
    'sequence': `in that sequence.`,
    'Bidirectional_Tagged_Link': `<T1> と <T2> は同じです。`,
    'Bidirectional_Tagged_Link_(tag)': `<T1> と <T2> は <tag> です。`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<T1> <forward tag> <T2>, と <T2> <backward tag> <T1> 。`,
  },
  'procedural_link': {
    /*
     * <O_Os> - OPM Object or OPM Stateful Object
     * <O_Os1...n> - set of OPM Objects or OPM Stateful Objects
     * <s> - OPM State
     * <P> - OPM Process
     */
    'Agent': `<O_Os> は <P> を処理します。`,
    'Agent_(multiple)': `<O\Os1...n> は <P> を処理します。`,
    'Agent_Condition': `<O> が存在する場合、 <P> が発生します。そうでなければ、 <P> はスキップします。`,
    'Agent_Condition(multiple)': `<O\Os1...n> が存在する場合、 <P> が発生します。そうでなければ、 <P> はスキップします。`,
    'Agent_Condition_state': ` <O> が <s> の場合、 <P> が発生します。そうでなければ、 <P> はスキップします。`,
    'Agent_Event': `<O> は <P> を開始して処理します。`,
    'Agent_Event(multiple)': `<O\Os1...n> は <P> を開始して処理します。`,
    'Agent_Event_state': `<s> <O> は <P> を開始して処理します。`,

    'Instrument': `<P> は <O_Os> を要求します。`,
    'Instrument_(multiple)': `<P> は <O\Os1...n> を要求します。`,
    'Instrument_Condition': `<O> が存在する場合、<P> が発生します。そうでなければ、 <P> はスキップします。`,
    'Instrument_Condition(multiple)': `<O\Os1...n> が存在する場合、 <P> が発生します。そうでなければ、 <P> はスキップします。`,
    'Instrument_Condition_state': ` <O> が <s> 状態の場合、 <P> が発生します。そうでなければ、 <P> はスキップします。`,
    'Instrument_Event': `<O> は <O>を要求する<P> を開始します。`,
    'Instrument_Event(multiple)': `<O\Os1...n> は、それらを要求する <P> を開始します。`,
    'Instrument_Event_state': `<s> <O> は <s> <O> を要求する <P> を開始します。`,

    'Effect': `<P> は <O_Os> に影響を与えます。`,
    'Effect_(multiple)': `<P> は <O\Os1...n> に影響を与えます。`,
    'Effect_Condition': `<O> が存在する場合、<P> が発生します。この場合、 <P> は <O> に影響を与えます。そうでなければ、 <P> はスキップします。`,
    'Effect_Condition(multiple)': `<O\Os1...n> が存在する場合、<P>  が発生します。この場合、<P> はそれらに影響を与えます。そうでなければ、 <P> はスキップします。`,
    'Effect_Condition_state': `<O> が <s> 状態の場合、<P> が発生します。この場合、 <P> は <s> <O> に影響を与えます。そうでなければ、<P> はスキップします。`,
    'Effect_Event': `<O> は  <O> に影響を与える <P> を開始します。`,
    'Effect_Event(multiple)': `<O\Os1...n> は <O> に影響を与える <P> を開始します。`,
    'Effect_Event_state': `<s> <O> は <s> <O> に影響を与える <P> を開始します。`,

    'Consumption': `<P> は <O_Os> を消費します。`,
    'Consumption_(multiple)': `<P> は <O\Os1...n> を消費します。`,
    'Consumption_Condition': `<O> が存在する場合、<P> が発生します。この場合、 <P> は <O> を消費します。 そうでなければ、 <P> はスキップします。`,
    'Consumption_Condition(multiple)': `<O\Os1...n> が存在する場合、<P> が発生します。この場合、 <P> がそれらを消費します。 そうでなければ、 <P> はスキップします。`,
    'Consumption_Condition_state': `<O> が <s> 状態の場合、<P> が発生します。この場合、 <P> は <s> <O> を消費します。, そうでなければ、 <P> はスキップします。`,
    'Consumption_Event': `<O> が  <O> を消費する <P> を開始します。`,
    'Consumption_Event(multiple)': `<O\Os1...n> がそれらを消費する <P> を開始します。`,
    'Consumption_Event_state': `<s> <O> は  <s> <O> を消費する <P> を開始します。`,

    'Result': `<P> は <O_Os> を引き渡します。`,
    'Result_(multiple)': `<P> は <O\Os1...n> を引き渡します。`,

    'In-out_Link_Pair': `<P> は <O> を <s1> から <s2> に変更します。`,
    'In-out(group)': `<O> は <s1> から <s2>`,
    'In-out_Link_Pair(group)': `<P> は <O> を <s1> から <s2><Other_changes> へ変更します。`,
    'Split_input': `<P> は <O> を <s> から別の状態に変更します。`,
    'Split_output': `<P> は <O> を別の状態から <s> に変更します。`,
    'Condition_Input': `<O> が <s1> の場合、 <P> が発生します。 この場合、 <P> は <O> を <s1> から <s2> に変更します。そうでなければ、 <P> はスキップします。`,
    'In-out_Link_Pair_Condition': `<O> が <s1> の場合、<P> が発生します。この場合、 <P> は <O> を <s1> から <s2> に変更します。そうでなければ、 <P> はスキップします。`,
    'In-out_Link_Pair_Condition(group)': `<O> が <s1> の場合、<P>  が発生します。この場合、<P> は <O> を <s1> から <s2> へ変更します。そうでなければ、 <P> はスキップします。`,
    'In-out_Link_Pair_Event': `<s1> <O> は <O> を <s1> から <s2> に変更する <P> を開始します。`,
    'In-out_Link_Pair_Event(group)': `<s1> 状態の <O> は <O> を <s1> から <s2> に変更する <P> を開始します。`,

    'Overtime_exception': `<O> が <maxtime> <units> よりも多い <s> の時に、 <O> は <P> を引き起こします。`,
    'Undertime_exception': `<O> が <mintime> <units> よりも少ない <s> の時に、<O> は <P> 引き起こします。`,
    'OvertimeUndertime-exception': `<O> が <maxtime> <units> よりも多い、または、 <mintime> <units> よりも少ない <s> の時に、 <O> は <P> 引き起こします。`,

    'Overtime_exception_(process)': `<P1> が <maxtime> <units> よりも多く持続する場合、<P2> が発生します。`,
    'Undertime_exception_(process)': `<P1> が <mintime> <units> に達しない場合、<P2> が発生します。`,
    'OvertimeUndertime-exception_(process)':
      `<P1> が <mintime> <units> に達しない、または、 <maxtime> <units> よりも多く持続する場合、<P2> が発生します。`,
    'Invocation': `<P1> は <P2> を呼び出します。`,
    'Invocation_(multiple)': `<P1> は <P2...n> を呼び出します。`,
    'Invocation_(self)': `<P1> は自分自身を呼び出します。`,
    'Invocation_(parent)': `<P1> は <P2> を呼び出します。`,

  },
  'grouping': { // for OPM Elements in a group
    /*
     * <T> - OPM Thing
     * <T1> - first OPM Thing
     * <T2> - second OPM Thing
     * <T1...n-1> - first n-1 OPM Things
     * <Tn> - nth OPM Thing
     * <O1...n> - first nth OPM Objects
     * <P1...n> - first nth OPM Processes
     * <s> - OPM State
     * <O> - OPM Object
     * <a> - OPM Object as an Attribute
     * <e1...n> - set of OPM Objects as Exhibitors
     */
    'Single-Thing': `<T>`,
    'Multiple-Things': `<T1...n-1> と <Tn>`,
    'Multiple-Things-Object-Process-Separated': `<O1...n>、同様に<P1...n>`,
    'AND': `<T1...n-1> と <Tn>`,
    'OR': `<T1...n-1> または <Tn>`,
    'XOR': `<T1> と <T2> の排他的論理和`,
    'Stateful-Object': `<s> 状態の <O>`,
    'Stateful-Object-value': `<O> 値が <s>`,
    'Stateful-Object-value(multiple)': `<O> 値が <s1...n>`,
    'Stateful-Object(multiple)': `<s1...n> 状態の <O>`,
    'Attribute-Exhibitor': `<e1...n> の <T> `,
    'indentation': `&nbsp;&nbsp;&nbsp;&nbsp;`,
    'Multiple-InOut': `<Other_changes> と <change>.`
  },
  'logic_operators': {
    // process/object are the single thing in the XOR\OR relation
    // brothers - couple of states of the same object.
    'XOR': {
      'process': {
        'Result': '<P> は <O_Os1...n> のうちの1つを引き渡します。',
        'Result(brothers)': '<P> は <s1...n> のいずれかの状態にある <O> を引き渡します。',

        'Consumption': `<P> は <O_Os1...n> のうちの1つを消費します。`,
        'Consumption(brothers)': `<P> は <s1...n> のいずれかの状態にある <O> を引き渡します。`,
        'Consumption_Condition': `<O_Os1...n> のうちの1つが存在する場合、 <P> が発生します。この場合、 <P> は既存のものを消費します。そうでなければ、 <P> はスキップします。`,
        'Consumption_Condition(brothers)': `<O> が <s1...n> のいずれかの状態にある場合、<P> が発生します。この場合、 <P> はそれを消費します。そうでなければ、 <P> はスキップします。`,
        'Consumption_Event': `<O_Os1...n> のうちの1つが、それを消費する <P> を開始します。`,
        'Consumption_Event(brothers)': `<O> が <s1...n> のいずれかの状態の時に、それを消費する <P> を開始します。`,


        'Effect': `<P> は <O_Os1...n> のうちの1つに影響を与えます。`,
        'Effect(brothers)': `<P> は <s1...n> のいずれかの状態にある <O> に影響を与えます。`,
        'Effect_Condition': `<O_Os1...n> のうちの1つが存在する場合、 <P> が発生します。この場合、 <P> はそれに影響を与えます。そうでなければ、 <P> はスキップします。`,
        'Effect_Condition(brothers)': `<s1...n> のいずれかの状態にある <O> が存在する場合、<P> が発生します。この場合、 <P> はそれに影響を与えます。そうでなければ、 <P> はスキップします。`,
        'Effect_Event': `<O_Os1...n> のうちの1つは、それに影響を与える <P> を開始します。`,
        'Effect_Event(brothers)': `<O> は <s1...n> のいずれかの状態の時に、それに影響を与える <P> を開始します。`,

        'Agent': `Exactly one of <O_Os1...n> は <P> を処理します。`,
        'Agent(brothers)': `<O> は <s1...n> のいずれかの状態の時に <P> を処理します。`,
        'Agent_Event': `<O_Os1...n> のうちの1つが、 <P> を開始して処理します。`,
        'Agent_Event(brothers)': `<O> は <s1...n> のいずれかの状態の時に <P> を開始して処理します。`,
        'Agent_Condition': `<O_Os1...n> のうちの1つが存在する場合、<P> が発生します。そうでなければ、 <P> はスキップします。`,
        'Agent_Condition(brothers)': `<O> が <s1...n> のいずれかの状態の場合、 <P> が発生します。そうでなければ、 <P> はスキップします。`,

        'Instrument': `<P> は <O_Os1...n> のうちの1つを要求します。`,
        'Instrument(brothers)': `<P> は <s1...n> のいずれかの状態の <O> を要求します。`,
        'Instrument_Condition': `<O_Os1...n> のうちの1つが存在する場合、<P> が発生します。そうでなければ、 <P> はスキップします。`,
        'Instrument_Condition(brothers)': `<O> が <s1...n> のいずれかの状態である場合、<P>  が発生します。そうでなければ、 <P> はスキップします。`,
        'Instrument_Event': `Exactly one of <O_Os1...n> のうちの1つが、それを要求する <P> を開始します。`,
        'Instrument_Event(brothers)': `<O> は、 <s1...n> のいずれかの状態の場合に、それを要求する <P> を開始します。`,

        'Invocation_OUT': `<P> は <P1...n> のいずれかを呼び出します。`,
        'Invocation_IN': `<P1...n> のいずれかは <P> を呼び出します。`,
        'InOut': `<P> changes <O> from <s1> to exactly one of <O_Os2...n>.`,
        'InOut_multi_Ins_Xor': `<P> changes <O> from exactly one of <ins1..n> to exactly one of <O_Os2...n>.`,
      },
      'object': { // _state means the link fan is from state.
        'Result': `<P1...n> のうちの1つが <O_Os> が引き渡します。`,

        'Consumption': `<P1...n> のうちの1つが <O_Os> を消費します。`,
        'Consumption_Event': `<O_Os> は <P1...n> のうちの1つを開始します。それは開始したプロセスを消費します。`,
        'Consumption_Condition_state': `<O> が <s1> の場合、 <P1...n> のうちの1つが発生します。この場合、発生しているプロセスは <O> を消費します。そうでなければ、これらのプロセスはスキップします。`,
        'Consumption_Condition': `<O_Os> が存在する場合、<P1...n> のうちの1つが発生します。この場合、発生しているプロセスは <O_Os> を消費します。そうでなければ、これらのプロセスはスキップします。`,

        'Effect': `<P1...n> のうちの1つは <O_Os> に影響を与えます。`,
        'Effect_Event': `<O_Os> は <P1...n> のうちの1つを開始します。それは発生しているプロセスに影響を与えます。`,
        'Effect_Condition': `<O_Os> が存在する場合、 <P1...n> が発生します。この場合、発生しているプロセスは <O_Os> に影響を与えます。そうでなければ、これらのプロセスはスキップします。`,

        'Agent': `<O_Os> は <P1...n> のうちの1つを処理します。`,
        'Agent_Event': `<O_Os> は <P1...n> のうちの1つを開始して処理します。`,
        'Agent_Condition': `<O_Os> が存在する場合、 <O_Os> は <P1...n> のうちの1つを処理します。そうでなければ、これらのプロセスはスキップします。`,
        'Agent_Condition_state': `<O> が <s1> の場合、 <O> は <P1...n> のうちの1つを処理します。そうでなければ、これらのプロセスはスキップします。`,

        'Instrument': `<P1...n> のうちの1つは <O_Os> を要求します。`,
        'Instrument_Event': `<O_Os> は <P1...n> のうちの1つを開始します。それは <O_Os> を要求します。`,
        'Instrument_Condition': `<P1...n> のうちの1つは <O_Os> を要求します。そうでなければ、これらのプロセスはスキップします。`,
        'Instrument_Condition_state': `<P1...n> のうちの1つは <O> が <s1> であることを要求します。そうでなければ、これらのプロセスはスキップします。`
      }
    },
    'OR': {
      'process': {
        'Result': '<P> は、少なくとも<O_Os1...n> のうちの1つを引き渡します。',

        'Consumption': `<P> は、少なくとも <O_Os1...n> のうちの1つを消費します。`,
        'Consumption_Condition': `少なくとも <O_Os1...n> のうちの1つが存在する場合、<P> が発生します。この場合、 <P> は、既存のものを消費します。そうでなければ、 <P> はスキップします。`,
        'Consumption_Event': `少なくとも <O_Os1...n> のうちの1つが、それを消費する <P>を開始します。`,

        'Effect': `<P> は、少なくとも <O_Os1...n> のうちの1つに影響を与えます。`,
        'Effect_Condition': `少なくとも <O_Os1...n> のうち1つが存在する場合、<P> が発生します。この場合、 <P> はそれに影響を与えます。そうでなければ、 <P> はスキップします。`,
        'Effect_Event': `少なくとも <O_Os1...n> のうちの1つが、それに影響を与える <P> を開始します。`,

        'Agent': `少なくとも <O_Os1...n> のうちの1つが <P> を処理します。`,
        'Agent_Event': `少なくとも <O_Os1...n> のうちの1つが <P> を開始して処理します。`,
        'Agent_Condition': `少なくとも <O_Os1...n> のうちの1つが存在する場合、 <P> が発生します。そうでなければ、 <P> はスキップします。`,

        'Instrument': `<P> は、少なくとも <O_Os1...n> のうちの1つを要求します。`,
        'Instrument_Condition': `少なくとも <O_Os1...n> のうちの1つが存在する場合、<P> が発生します。そうでなければ、 <P> はスキップします。`,
        'Instrument_Event': `少なくとも <O_Os1...n> は、それを要求する <P> を開始します。`,


        'Invocation_OUT': `<P> は、少なくとも <P1...n> のうちの1つを呼び出します。`,
        'Invocation_IN': `少なくとも <P1...n> のうちの1つは、 <P> を呼び出します。`,
        'InOut': `<P> changes <O> from <s1> to at least one of <O_Os2...n>.`,
      },
      'object': {
        'Result': `少なくとも <P1...n> のうちの1つは、 <O_Os> を引き渡します。`,

        'Consumption': `少なくとも <P1...n> のうちの1つは <O_Os> を消費します。`,
        'Consumption_Event': `<O_Os> は、少なくとも1つの <P1...n> を開始します。それは開始したプロセスを消費します。`,
        'Consumption_Condition_state': ` <O> が <s1> の場合、少なくとも <P1...n> のうちの1つが発生します。この場合、発生したプロセスは <O> を消費します。そうでなければ、これらのプロセスはスキップします。`,
        'Consumption_Condition': `<O_Os>  が存在する場合、少なくとも <P1...n> のうちの1つが発生します。この場合、発生したプロセスは <O_Os> を消費します。 そうでなければ、これらのプロセスはスキップします。`,

        'Effect': `少なくとも <P1...n> のうちの1つは、 <O_Os> に影響を与えます。`,
        'Effect_Event': `<O_Os> は、少なくとも <P1...n> のうちの1つを開始します。それは発生しているプロセスに影響を与えます。`,
        'Effect_Condition': `<O_Os> が存在する場合、少なくとも <P1...n> のうちの1つが発生します。この場合、発生しているプロセスは <O_Os> に影響を与えます。そうでなければ、これらのプロセスはスキップします。`,

        'Agent': `<O_Os> は、少なくとも <P1...n> のうちの1つを処理します。`,
        'Agent_Event': `<O_Os> は、少なくとも <P1...n> のうちの1つを開始して処理します。`,
        'Agent_Condition': `<O_Os> が存在する場合、 <O_Os> は、少なくとも <P1...n> のうちの1つを処理します。そうでなければ、これらのプロセスはスキップします。`,
        'Agent_Condition_state': `<O> が <s1> の場合、<O> は、少なくとも <P1...n> のうちの1つを処理します。, そうでなければ、これらのプロセスはスキップします。`,

        'Instrument': `少なくとも <P1...n> のうちの1つは、 <O_Os> を要求します。`,
        'Instrument_Event': `<O_Os> は、少なくとも <P1...n> のうちの1つを開始します。それは <O_Os> を要求します。`,
        'Instrument_Condition': `少なくとも <P1...n> のうちの1つは、 <O_Os> を要求します。そうでなければ、これらのプロセスはスキップします。`,
        'Instrument_Condition_state': `少なくとも <P1...n> のうちの1つは、 <O> が <s1> であることを要求します。そうでなければ、これらのプロセスはスキップします。`
      }
    }
  },
  'tags': {
    'multiplicity': `<tag> <O_Os>`,
    'constraints': `<O_Os>; <tag>において`,
    'probability': `<tag> の確率で <O_Os>`,
    'rate': `<tag> <units> の割合の <O_Os>`,
    'path': `次のパス <tag>, <opl>`,
    'range': `<r> <opl>`
  },
  'symbols': {
    '?': `オプション`,
    '*': `オプション`,
    '+': `少なくとも1つ`,
    'n..n': `<n1> から <n2>`,
    'n..*': `<n1> から多`
  },
  'ranges': {
    '<=': `以下`,
    '>=': '以上',
    '=': '等しい',
    '<>': '等しくない',
    '<': `より少ない`,
    '>': 'より多い'
  },
  'object': {
    'thing_generic_name': `物体`,
    'default_essence_affiliation': ``,
    'digital_twin': `<TWIN> is the Digital Twin of <O>.`,
    'default_essence': `<O> は <a> です。`,
    'default_affiliation': `<O> は <e> です。`,
    'non_default': `<O> は <e> と <a> です。`,
    'singleInzoom': `<SD_Parent> からの <O> は、 <T_list> に <Current_SD> を拡大します。`,
    'singleInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>.`,
    'multiInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>, as well as <P_list>.`,
    'multiInzoom': `<SD_Parent> からの <O> は、 <P_list> と同様に <O_list> に <Current_SD> を拡大します。`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<O> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<O> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <O_list>, as well as <P_list>.`,
    'single_unfold_instantiation': `<O> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    'object_list_sequence': `縦方向に <O1...n>`,
    'object_list_parallel': `横方向に <O1...n>`
  },
  'process': {
    'thing_generic_name': `処理する`,
    'default_essence_affiliation': ``,
    'default_essence': `<P> は <a> です。`,
    'default_affiliation': `<P> は <e> です。`,
    'non_default': `<P> は <e> と <a> です。`,
    'singleInzoom': `<SD_Parent> からの <P> は、 時系列で発生する <T_list> に <Current_SD> を拡大します。`,
    'singleInzoom_parallel': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
    'multiInzoom': `<SD_Parent> からの <P> は、<O_list> と同様に  <P_list> に <Current_SD> を拡大します。`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<P> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<P> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_instantiation': `<P> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    // 'process_list_parallel': `<P1...n>, in that horizontal sequence`,
    'process_list_parallel': `並列の <P1...n>`,
    // 'process_list_sequence': `<P1...n>, in that vertical sequence`,
    'process_list_sequence': `<P1...n>`,
    'expected_duration': `<P> の予想持続時間の値は <exp> <units> です。`,
    'min_duration': `<P> の最小持続時間 <min> <units> です。`,
    'max_duration': `<P> 最大持続時間の値は <max> <units> です。`,
    'min_max_range_duration': `<P> の最小持続時間と最大持続時間の値は、それぞれ <min> <units> と <max> <units> です。`,
    'min_exp_range_duration': `<P> の最小持続時間との予想持続時間の値は、それぞれ <min> <units> と <exp> <units> です。`,
    'exp_max_range_duration': `<P> の予想持続時間の値はと最大持続時間の値は、それぞれ <exp> <units> と <max> <units> です。`,
    'expected_range_duration': `The values of minimal Duration, expected Duration, and maximal Duration of <P> の最小持続時間、予想持続時間、最大持続時間の値は、それぞれ <min> <units> 、 <exp> <units> 、 <max> <units> です。`
  },
  'state': {
    'single_state': `<O> は <s> です。`,
    'multiple_states': `<O> は <s1...n> になりえます。`,
    'all_states_are_suppressed': `<O> is stateful.`,
    'one_state_shown_one_missing': `<O> is <s> or at one other state.`,
    'one_state_shown': `<O> is <s> and can be at one of <num> other states.`,
    'two_or_more_states_shown_one_missing': `<O> can be <s1...n> or at one other state.`,
    'two_or_more_states_shown': `<O> can be <s1...n> or at one of <num> other states.`,
    'default': `状態 <s> はデフォルトです。`,
    'default_initial': `状態 <s> はデフォルトで初期値です。`,
    'initial': `状態 <s> は初期値です。`,
    'final': `状態 <s> は最終値です。`,
    'default_initial_final': `状態 <s> は初期値、最終値、デフォルトです。`,
    'initial_final': `状態 <s> は初期値で最終値です。`,
    'default_final': `状態 <s> は最終値でデフォルトです。`,
    'none': ``,
    'Current': `<O> is currently at state <s>.`,
    // 'timeDurational': {
      'expected_duration': `<s> の予想持続時間の値は <exp> <units> です。`,
      'min_duration': `<s> の最小持続時間 <min> <units> です。`,
      'max_duration': `<s> 最大持続時間の値は <max> <units> です。`,
      'min_max_range_duration': `<s> の最小持続時間と最大持続時間の値は、それぞれ <min> <units> と <max> <units> です。`,
      'min_exp_range_duration': `<s> の最小持続時間との予想持続時間の値は、それぞれ <min> <units> と <exp> <units> です。`,
      'exp_max_range_duration': `<s> の予想持続時間の値はと最大持続時間の値は、それぞれ <exp> <units> と <max> <units> です。`,
      'expected_range_duration': `The values of minimal Duration, expected Duration, and maximal Duration of <s> の最小持続時間、予想持続時間、最大持続時間の値は、それぞれ <min> <units> 、 <exp> <units> 、 <max> <units> です。`,
      'default_time_units': `seconds`
    // },

  },
  'essence': {
    'physical': `モノ`,
    'informatical': `情報`,
  },
  'affiliation': {
    'systemic': `システム`,
    'environmental': `環境`,
  },
  'semifolding': {
    'object': `<O> lists `,
    'process': `<P> lists `,
    'aggregation': {
      'single': `<T> as a part`,
      'multiple': `<T1...n-1> and <Tn> as parts`,
    },
    'exhibition': {
      'single': `<T> as a feature`,
      'multiple': `<T1...n-1> and <Tn> as features`,
    },
    'generalization': {
      'single': `<T> as a specialization`,
      'multiple': `<T1...n-1> and <Tn> as specializations`,
    },
    'instantiation': {
      'single': `<T> as an instance`,
      'multiple': `<T1...n-1> and <Tn> as instances`,
    },
  }
};

export const oplTemplates_es = {
  'structural_link': {
    /*
     * <T1> - source OPM Thing
     * <T2> - target OPM Thing
     * <T2...n> - set of target OPM Things
     */
    'Aggregation-Participation': `<T1> consiste en <T2>.`,
    'Aggregation-Participation_incomplete': `<T1> consists of <T2> and <num> more parts.`,
    'Aggregation-Participation_(multiple)': `<T1> consiste en <T2...n>.`,
    'Aggregation-Participation_incomplete_(multiple)': `<T1> consists of <T2...n> and <num> more parts.`,
    'Generalization-Specialization': `<T2> es un <T1>.`,
    'Generalization-Specialization_incomplete': `<T2> and <num> more specializations are <T1>.`,
    'Generalization-Specialization_(multiple)': `<T2...n> son <T1>.`,
    'Generalization-Specialization_incomplete_(multiple)': `<T2...n> and <num> more specializations are <T1>.`,
    'Classification-Instantiation': `<T2> es un caso para <T1>.`,
    'Classification-Instantiation_incomplete': `<T2> and <num> more instances are instances of <T1>.`,
    'Classification-Instantiation_(multiple)': `<T2...n> son los casos de <T1>.`,
    'Classification-Instantiation_incomplete_(multiple)': `<T2...n> and <num> more instances are instances of <T1>.`,
    'Exhibition-Characterization': `<T1> exhibe <T2>.`,
    'Exhibition-Characterization_incomplete': `<T1> exhibits <T2> and <num> more attributes.`,
    'Exhibition-Characterization_(multiple)': `<T1> exhibe <T2...n>.`,
    'Exhibition-Characterization_incomplete_(multiple)': `<T1> exhibits <T2...n> and <num> more attributes.`,
    'Unidirectional_Tagged_Link': `<T1> se refiere a <T2>.`,
    'Unidirectional_Tagged_Link_(tag)': `<T1> <tag> <T2>.`,
    'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
    'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
    'sequence': `in that sequence.`,
    'Bidirectional_Tagged_Link': `<T1> y <T2> are equivalents.`,
    'Bidirectional_Tagged_Link_(tag)': `<T1> y <T2> son <tag>.`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<T1> <forward tag> <T2>, y <T2> <backward tag> <T1>.`,
  },
  'procedural_link': {
    /*
     * <O_Os> - OPM Object or OPM Stateful Object
     * <O_Os1...n> - set of OPM Objects or OPM Stateful Objects
     * <s> - OPM State
     * <P> - OPM Process
     */
    'Agent': `<O_Os> manipula <P>.`,
    'Agent_(multiple)': `<O\Os1...n> manipulo <P>.`,
    'Agent_Condition': `<P> ocurre si <O> existe, de lo contrario <P> is skipped.`,
    'Agent_Condition(multiple)': `<P> ocurre si <O\Os1...n> existe, de lo contrario <P> is skipped.`,
    'Agent_Condition_state': `<P> ocurre si <O> es <s>, de lo contrario <P> is skipped.`,
    'Agent_Event': `<O> inicia y manipula <P>.`,
    'Agent_Event(multiple)': `<O\Os1...n> inicio y manipulo <P>.`,
    'Agent_Event_state': `<s> <O> inicia y manipula <P>.`,

    'Instrument': `<P> requiere <O_Os>.`,
    'Instrument_(multiple)': `<P> requiere <O\Os1...n>.`,
    'Instrument_Condition': `<P> ocurre si <O> existe, de lo contrario <P> is skipped.`,
    'Instrument_Condition(multiple)': `<P> ocurre si <O\Os1...n> existe, de lo contrario <P> is skipped.`,
    'Instrument_Condition_state': `<P> ocurre si <O> estÃ¡ en estado <s>, de lo contrario <P> is skipped.`,
    'Instrument_Event': `<O> inicia <P>, el cual requiere <O>.`,
    'Instrument_Event(multiple)': `<O\Os1...n> inicio <P>, which requires.`,
    'Instrument_Event_state': `<s> <O> inicia <P>, el cual requiere <s> <O>.`,

    'Effect': `<P> afecta <O_Os>.`,
    'Effect_(multiple)': `<P> afecta <O\Os1...n>.`,
    'Effect_Condition': `<P> ocurre si <O> existe, en ese caso <P> afecta <O>, de lo contrario <P> is skipped.`,
    'Effect_Condition(multiple)': `<P> ocurre si <O\Os1...n> existe, en ese caso <P> los afectan , de lo contrario <P> is skipped.`,
    'Effect_Condition_state': `<P> ocurre si <O> estÃ¡ en estado <s>, en ese caso <P> afecta <s> <O>, de lo contrario <P> is skipped.`,
    'Effect_Event': `<O> inicia <P>, en ese caso <O>`,
    'Effect_Event(multiple)': `<O\Os1...n> inicio <P>, que afecta <O>.`,
    'Effect_Event_state': `<s> <O> inicia <P>, que afecta <s> <O>`,

    'Consumption': `<P> consume <O_Os>.`,
    'Consumption_(multiple)': `<P> consume <O\Os1...n>.`,
    'Consumption_Condition': `<P> ocurre si <O> existe, en ese caso <P> consume <O>, de lo contrario <P> is skipped.`,
    'Consumption_Condition(multiple)': `<P> ocurre si <O\Os1...n> existe, en ese caso <P> los consume, de lo contrario <P> is skipped.`,
    'Consumption_Condition_state': `<P> ocurre si <O> estÃ¡ en estado <s>, en ese caso <P> consume <s> <O>, de lo contrario <P> is skipped.`,
    'Consumption_Event': `<O> inicia <P>, el cual consume <O>.`,
    'Consumption_Event(multiple)': `<O\Os1...n> inicio <P>, which consumes them.`,
    'Consumption_Event_state': `<s> <O> inicia <P>, que consume <O>.`,

    'Result': `<P> produce <O_Os>.`,
    'Result_(multiple)': `<P> produce <O\Os1...n>.`,

    'In-out_Link_Pair': `<P> cambia <O> desde <s1> a <s2>.`,
    'In-out(group)': `<O> desde <s1> a <s2>`,
    'In-out_Link_Pair(group)': `<P> cambia <O> desde <s1> a <s2><Other_changes>.`,
    'Split_input': `<P> cambia <O> a cualquier otro estado desde <s>.`,
    'Split_output': `<P> cambia <O> desde cualquier otro estado a <s>.`,
    'Condition_Input': `<P> ocurre si <O> es <s1>, en ese caso <P> cambia <O> desde <s1> a <s2>, de lo contrario <P> is skipped.`,
    'In-out_Link_Pair_Condition': `<P> ocurre si <O> es <s1>, en ese caso <P> cambia <O> desde <s1> a <s2>, de lo contrario <P> is skipped.`,
    'In-out_Link_Pair_Condition(group)': `<P> ocurre si <O> es <s1>, en ese caso <P> cambia <O> desde <s1> a <s2>, de lo contrario <P> is skipped.`,
    'In-out_Link_Pair_Event': `<s1> <O> inicia <P>, que cambia <O> desde <s1> a <s2>.`,
    'In-out_Link_Pair_Event(group)': `<O> en estado <s1> inicia <P>, que cambia <O> desde <s1> a <s2>.`,

    'Overtime_exception': `<O> activa <P> cuando <O> es <s> mayor que <maxtime> <units>.`,
    'Undertime_exception': `<O> activa <P> cuando <O> es <s> menor que <mintime> <units>.`,
    'OvertimeUndertime-exception': `<O> activa <P> cuando <O> es <s> mayor que <maxtime> <units> y menor que <mintime> <units>.`,

    'Overtime_exception_(process)': `<P2> ocurre si <P1> estÃ¡ sobre <maxtime> <units>.`,
    'Undertime_exception_(process)': `<P2> ocurre si <P1> cae bajo de <mintime> <units>.`,
    'OvertimeUndertime-exception_(process)':
      `<P2> occurs if <P1> cae bajo de <mintime> <units> o estÃ¡ sobre <maxtime> <units>.`,
    'Invocation': `<P1> invoca <P2>.`,
    'Invocation_(multiple)': `<P1> invoca <P2...n>.`,
    'Invocation_(self)': `<P1> invokes itself.`,
    'Invocation_(parent)': `<P1> invoca <P2>.`,

  },
  'grouping': { // for OPM Elements in a group
    /*
     * <T> - OPM Thing
     * <T1> - first OPM Thing
     * <T2> - second OPM Thing
     * <T1...n-1> - first n-1 OPM Things
     * <Tn> - nth OPM Thing
     * <O1...n> - first nth OPM Objects
     * <P1...n> - first nth OPM Processes
     * <s> - OPM State
     * <O> - OPM Object
     * <a> - OPM Object as an Attribute
     * <e1...n> - set of OPM Objects as Exhibitors
     */
    'Single-Thing': `<T>`,
    'Multiple-Things': `<T1...n-1> y <Tn>`,
    'Multiple-Things-Object-Process-Separated': `<O1...n>, asÃ­ como <P1...n>`,
    'AND': `<T1...n-1> y <Tn>`,
    'OR': `<T1...n-1> o <Tn>`,
    'XOR': `<T1> xor <T2>`,
    'Stateful-Object': `<O> en estado <s>`,
    'Stateful-Object-value': `<O> con el valor <s>`,
    'Stateful-Object-value(multiple)': `<O> con valores <s1...n>`,
    'Stateful-Object(multiple)': `<O> en estado <s1...n>`,
    'Attribute-Exhibitor': `<T> de <e1...n>`,
    'indentation': `&nbsp;&nbsp;&nbsp;&nbsp;`,
    'Multiple-InOut': `<Other_changes> y <change>.`
  },
  'logic_operators': {
    // process/object are the single thing in the XOR\OR relation
    // brothers - couple of states of the same object.
    'XOR': {
      'process': {
        'Result': '<P> produce exactamente uno de <O_Os1...n>.',
        'Result(brothers)': '<P> produce <O> en uno de los estados <s1...n>.',

        'Consumption': `<P> consume exactamente uno de <O_Os1...n>.`,
        'Consumption(brothers)': `<P> consume <O> en uno de los estados <s1...n>.`,
        'Consumption_Condition': `<P> ocurre si exactamente uno de <O_Os1...n> existe, en ese caso <P> consume el existente, de lo contrario <P> is skipped.`,
        'Consumption_Condition(brothers)': `<P> ocurre si <O> estÃ¡ en uno de los estados <s1...n>, en ese caso <P> se consume, de lo contrario <P> is skipped.`,
        'Consumption_Event': `Exactly one of <O_Os1...n> inicia <P>, which consumes it.`,
        'Consumption_Event(brothers)': `<O> inicia <P> cuando estÃ¡ en uno de los estados <s1...n>, which consumes it.`,


        'Effect': `<P> afecta exactamente uno de <O_Os1...n>.`,
        'Effect(brothers)': `<P> afecta <O> en uno de los estados <s1...n>.`,
        'Effect_Condition': `<P> ocurre si exactamente uno de <O_Os1...n> existe, en ese caso <P> lo afecta, de lo contrario <P> is skipped.`,
        'Effect_Condition(brothers)': `<P> ocurre si <O> estÃ¡ en uno de los estados <s1...n>, en ese caso <P> lo afecta, de lo contrario <P> is skipped.`,
        'Effect_Event': `Exactly one of <O_Os1...n> inicia <P>, which affects it.`,
        'Effect_Event(brothers)': `<O> inicia <P> cuando estÃ¡ en uno de los estados <s1...n>, which affects it.`,

        'Agent': `Exactly one of <O_Os1...n> manipula <P>.`,
        'Agent(brothers)': `<O> manipula <P> cuando estÃ¡ en uno de los estados <s1...n>.`,
        'Agent_Event': `Exactly one of <O_Os1...n> inicia y manipula <P>.`,
        'Agent_Event(brothers)': `<O> inicia y manipula <P> cuando estÃ¡ en uno de los estados <s1...n>.`,
        'Agent_Condition': `<P> ocurre si exactamente uno de <O_Os1...n> existe, de lo contrario <P> is skipped.`,
        'Agent_Condition(brothers)': `<P> ocurre si <O> estÃ¡ en uno de los estados <s1...n>, de lo contrario <P> is skipped.`,

        'Instrument': `<P> requiere exactamente uno de <O_Os1...n>.`,
        'Instrument(brothers)': `<P> requiere <O> en uno de los estados <s1...n>.`,
        'Instrument_Condition': `<P> ocurre si exactamente uno de <O_Os1...n> existe, de lo contrario <P> is skipped.`,
        'Instrument_Condition(brothers)': `<P> ocurre si <O> estÃ¡ en uno de los estados <s1...n>, de lo contrario <P> is skipped.`,
        'Instrument_Event': `Exactly one of <O_Os1...n> inicia <P>, which requiere it.`,
        'Instrument_Event(brothers)': `<O> inicia <P> cuando estÃ¡ en uno de los estados <s1...n>, which requires it.`,

        'Invocation_OUT': `<P> invoca cualquiera <P1...n>.`,
        'Invocation_IN': `Either <P1...n> invoca <P>.`,
        'InOut': `<P> changes <O> from <s1> to exactly one of <O_Os2...n>.`,
        'InOut_multi_Ins_Xor': `<P> changes <O> from exactly one of <ins1..n> to exactly one of <O_Os2...n>.`,
      },
      'object': { // _state means the link fan is from state.
        'Result': `Exactly one of <P1...n> produce <O_Os>.`,

        'Consumption': `Exactly one of <P1...n> consume <O_Os>`,
        'Consumption_Event': `<O_Os> inicia exactamente uno de <P1...n>, which consumes the initiated process.`,
        'Consumption_Condition_state': `Exactly one of <P1...n> ocurre si <O> es <s1>, en ese caso el proceso que se produce consume <O>, de lo contrario these processes are skipped.`,
        'Consumption_Condition': `Exactly one of <P1...n> ocurre si <O_Os> existe, en ese caso el proceso que se produce consume <O_Os>, otherwise these processes are skipped.`,

        'Effect': `Exactly one of <P1...n> afecta <O_Os>.`,
        'Effect_Event': `<O_Os> inicia exactamente uno de <P1...n>, which affects the occurring process.`,
        'Effect_Condition': `Exactly one of <P1...n> ocurre si <O_Os> existe, en ese caso el proceso que ocurre afecta <O_Os>, otherwise these processes are skipped.`,

        'Agent': `<O_Os> maneja exactamente uno de <P1...n>.`,
        'Agent_Event': `<O_Os> inicia y maneja exactamente uno de <P1...n>.`,
        'Agent_Condition': `<O_Os> maneja exactamente uno de <P1...n> si <O_Os> exists, otherwise these processes are skipped.`,
        'Agent_Condition_state': `<O> maneja exactamente uno de <P1...n> si <O> es <s1>, otherwise these processes are skipped.`,

        'Instrument': `Exactly one of <P1...n> requiere <O_Os>.`,
        'Instrument_Event': `<O_Os> inicia exactamente uno de <P1...n>, el cual requiere <O_Os>.`,
        'Instrument_Condition': `Exactly one of <P1...n> requiere <O_Os>, otherwise these processes are skipped.`,
        'Instrument_Condition_state': `Exactly one of <P1...n> que requiere <O> es <s1>, otherwise these processes are skipped.`
      }
    },
    'OR': {
      'process': {
        'Result': '<P> produce al menos uno de <O_Os1...n>.',

        'Consumption': `<P> consume al menos uno de <O_Os1...n>.`,
        'Consumption_Condition': `<P> ocurre si al menos uno de <O_Os1...n> existe, en ese caso <P> consume el existente, de lo contrario <P> is skipped.`,
        'Consumption_Event': `At least one of <O_Os1...n> inicia <P>, que lo consume.`,

        'Effect': `<P> afecta al menos uno de <O_Os1...n>.`,
        'Effect_Condition': `<P> ocurre si al menos uno de <O_Os1...n> existe, en ese caso <P> lo afecta, de lo contrario <P> is skipped.`,
        'Effect_Event': `At least one of <O_Os1...n> inicia <P>, which affects it.`,

        'Agent': `At least one of <O_Os1...n> maneja <P>.`,
        'Agent_Event': `At least one of <O_Os1...n> inicia y maneja <P>.`,
        'Agent_Condition': `<P> ocurre si Al menos uno de <O_Os1...n> existe, de lo contrario <P> is skipped.`,

        'Instrument': `<P> requiere al menos uno de <O_Os1...n>.`,
        'Instrument_Condition': `<P> ocurre si al menos uno de <O_Os1...n> existe, de lo contrario <P> is skipped.`,
        'Instrument_Event': `At least one of <O_Os1...n> inicia <P>, which requires it.`,


        'Invocation_OUT': `<P> invoca a lo menos uno de <P1...n>.`,
        'Invocation_IN': `At least one of <P1...n> invoca <P>.`,

        'InOut': `<P> changes <O> from <s1> to at least one of <O_Os2...n>.`,
      },
      'object': {
        'Result': `At least one of <P1...n> produce <O_Os>`,

        'Consumption': `At least one of <P1...n> consume <O_Os>`,
        'Consumption_Event': `<O_Os> inicia al menos uno de <P1...n>, which consumes the initiated process.`,
        'Consumption_Condition_state': `At least one of <P1...n> ocurre si <O> es <s1>, en ese caso el proceso que ocurre consume <O>, otherwise these processes are skipped.`,
        'Consumption_Condition': `At least one of <P1...n> ocurre si <O_Os> existe, en ese caso el proceso que ocurre consume <O_Os>, otherwise these processes are skipped.`,

        'Effect': `At least one of <P1...n> afecta <O_Os>`,
        'Effect_Event': `<O_Os> inicia al menos uno de <P1...n>, which affects the occurring process.`,
        'Effect_Condition': `At least one of <P1...n> ocurre si <O_Os> existe, en ese caso el proceso que ocurre afecta <O_Os>, otherwise these processes are skipped.`,

        'Agent': `<O_Os> maneja al menos uno de <P1...n>.`,
        'Agent_Event': `<O_Os> inicia y maneja al menos uno de <P1...n>.`,
        'Agent_Condition': `<O_Os> maneja al menos uno de <P1...n> si <O_Os> existe, otherwise these processes are skipped.`,
        'Agent_Condition_state': `<O> maneja al menos uno de <P1...n> si <O> es <s1>, otherwise these processes are skipped.`,

        'Instrument': `At least one of <P1...n> requiere <O_Os>`,
        'Instrument_Event': `<O_Os> inicia al menos uno de <P1...n>, el cual requiere <O_Os>`,
        'Instrument_Condition': `At least one of <P1...n> requiere <O_Os>, otherwise these processes are skipped.`,
        'Instrument_Condition_state': `At least one of <P1...n> requiere que <O> es <s1>, otherwise these processes are skipped.`
      }
    }
  },
  'tags': {
    'multiplicity': `<tag> <O_Os>`,
    'constraints': `<O_Os>; donde <tag>`,
    'probability': `<O_Os> con probabilidad <tag>`,
    'rate': `<O_Os> a razÃ³n de <tag> <units>`,
    'path': `Following path <tag>, <opl>`,
    'range': `<r> <opl>`
  },
  'symbols': {
    '?': `an optional`,
    '*': `optional`,
    '+': `at least one`,
    'n..n': `<n1> a <n2>`,
    'n..*': `<n1> a many`
  },
  'ranges': {
    '<=': `less than or equal to`,
    '>=': 'more than or equal to',
    '=': 'equal to',
    '<>': 'not equal to',
    '<': `less than`,
    '>': 'more than'
  },
  'object': {
    'thing_generic_name': `objeto`,
    'default_essence_affiliation': ``,
    'digital_twin': `<TWIN> is the Digital Twin of <O>.`,
    'default_essence': `<O> es <a>.`,
    'default_affiliation': `<O> es <e>.`,
    'non_default': `<O> es <e> y <a>.`,
    'singleInzoom': `<O> desde <SD_Parent> aumenta la escala <Current_SD> en <T_list>.`,
    'singleInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>.`,
    'multiInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>, as well as <P_list>.`,
    'multiInzoom': `<O> desde <SD_Parent> aumenta la escala <Current_SD> en <O_list>, asÃ­ como <P_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<O> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<O> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <O_list>, as well as <P_list>.`,
    'single_unfold_instantiation': `<O> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    'object_list_sequence': `<O1...n>, in that vertical sequence`,
    'object_list_parallel': `<O1...n>, in that horizontal sequence`
  },
  'process': {
    'thing_generic_name': `proceso`,
    'default_essence_affiliation': ``,
    'default_essence': `<P> es <a>.`,
    'default_affiliation': `<P> es <e>.`,
    'non_default': `<P> es <e> y <a>.`,
    'singleInzoom': `<P> desde <SD_Parent> aumenta la escala <Current_SD> en <T_list>, which occur in that time sequence.`,
    'singleInzoom_parallel': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
    'multiInzoom': `<P> desde <SD_Parent> aumenta la escala <Current_SD> en <P_list>, asÃ­ como <O_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<P> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<P> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_instantiation': `<P> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    // 'process_list_parallel': `<P1...n>, in that horizontal sequence`,
    'process_list_parallel': `parallel <P1...n>`,
    // 'process_list_sequence': `<P1...n>, in that vertical sequence`,
    'process_list_sequence': `<P1...n>`,
    'expected_duration': `The expected duration of <P> es <exp> <units>.`,
    'min_duration': `TThe minimal duration of <P> es <min> <units>.`,
    'max_duration': `The maximal duration of <P> es <max> <units>.`,
    'min_max_range_duration': `The minimal duration and maximal duration of <P> son <min> <units> y <max> <units>, respectively.`,
    'min_exp_range_duration': `The minimal duration and expected duration of <P> son <min> <units> y <exp> <units>, respectively.`,
    'exp_max_range_duration': `The expected duration and maximal duration of <P> son <exp> <units> y <max> <units>, respectively.`,
    'expected_range_duration': `The minimal duration, expected duration, and maximal duration of <P> son <min> <units>, <exp> <units>, y <max> <units>, respectively.`
  },
  'state': {
    'single_state': `<O> es <s>.`,
    'multiple_states': `<O> pueden ser <s1...n>.`,
    'all_states_are_suppressed': `<O> is stateful.`,
    'one_state_shown_one_missing': `<O> is <s> or at one other state.`,
    'one_state_shown': `<O> is <s> and can be at one of <num> other states.`,
    'two_or_more_states_shown_one_missing': `<O> can be <s1...n> or at one other state.`,
    'two_or_more_states_shown': `<O> can be <s1...n> or at one of <num> other states.`,
    'default': `State <s> is default.`,
    'default_initial': `State <s> is default and initial.`,
    'initial': `State <s> is initial.`,
    'final': `State <s> is final.`,
    'default_initial_final': `State <s> is initial, final, and default.`,
    'initial_final': `State <s> is initial and final.`,
    'default_final': `State <s> is final and default.`,
    'none': ``,
    'Current': `<O> is currently at state <s>.`,
    // 'timeDurational': {
      'expected_duration': `The expected duration of <s> es <exp> <units>.`,
      'min_duration': `The minimal duration of <s> es <min> <units>.`,
      'max_duration': `The maximal duration of <s> es <max> <units>.`,
      'min_max_range_duration': `The minimal duration and maximal duration of <s> son <min> <units> y <max> <units>, respectively.`,
      'min_exp_range_duration': `The minimal duration and expected duration of <s> son <min> <units> y <exp> <units>, respectively.`,
      'exp_max_range_duration': `The expected duration and maximal duration of <s> son <exp> <units> y <max> <units>, respectively.`,
      'expected_range_duration': `The minimal duration, expected duration, and maximal duration of <s> son <min> <units>, <exp> <units>, y <max> <units>, respectively.`,
      'default_time_units': `seconds`
    // },

  },
  'essence': {
    'physical': `physical`,
    'informatical': `informatical`,
  },
  'affiliation': {
    'systemic': `systemic`,
    'environmental': `environmental`,
  },
  'semifolding': {
    'object': `<O> lists `,
    'process': `<P> lists `,
    'aggregation': {
      'single': `<T> as a part`,
      'multiple': `<T1...n-1> and <Tn> as parts`,
    },
    'exhibition': {
      'single': `<T> as a feature`,
      'multiple': `<T1...n-1> and <Tn> as features`,
    },
    'generalization': {
      'single': `<T> as a specialization`,
      'multiple': `<T1...n-1> and <Tn> as specializations`,
    },
    'instantiation': {
      'single': `<T> as an instance`,
      'multiple': `<T1...n-1> and <Tn> as instances`,
    },
  }
};

export const oplTemplates_ml = {
  'structural_link': {
    /*
     * <T1> - source OPM Thing
     * <T2> - target OPM Thing
     * <T2...n> - set of target OPM Things
     */
    'Aggregation-Participation': `<T1> ഉൾപെട്ടിട്ടുള്ളത് <T2>.`,
    'Aggregation-Participation_incomplete': `<T1> consists of <T2> and <num> more parts.`,
    'Aggregation-Participation_(multiple)': `<T1> ഉൾപെട്ടിട്ടുള്ളത് <T2...n>.`,
    'Aggregation-Participation_incomplete_(multiple)': `<T1> consists of <T2...n> and <num> more parts.`,
    'Generalization-Specialization': `<T2> ഒരു <T1>.`,
    'Generalization-Specialization_incomplete': `<T2> and <num> more specializations are <T1>.`,
    'Generalization-Specialization_(multiple)': `<T2...n> ആകുന്നു <T1>.`,
    'Generalization-Specialization_incomplete_(multiple)': `<T2...n> and <num> more specializations are <T1>.`,
    'Classification-Instantiation': `<T2> ഒരു ഉദാഹരണമാണ് <T1>.`,
    'Classification-Instantiation_incomplete': `<T2> and <num> more instances are instances of <T1>.`,
    'Classification-Instantiation_(multiple)': `<T2...n> ഉദാഹരണങ്ങളാണ് <T1>.`,
    'Classification-Instantiation_incomplete_(multiple)': `<T2...n> and <num> more instances are instances of <T1>.`,
    'Exhibition-Characterization': `<T1> പ്രദർശിപ്പിക്കുന്നു <T2>.`,
    'Exhibition-Characterization_incomplete': `<T1> exhibits <T2> and <num> more attributes.`,
    'Exhibition-Characterization_(multiple)': `<T1> പ്രദർശിപ്പിക്കുന്നു <T2...n>.`,
    'Exhibition-Characterization_incomplete_(multiple)': `<T1> exhibits <T2...n> and <num> more attributes.`,
    'Unidirectional_Tagged_Link': `<T1> ഇതുമായി ബന്ധപ്പെട്ടിരിക്കുന്നു <T2>.`,
    'Unidirectional_Tagged_Link_(tag)': `<T1> <tag> <T2>.`,
    'Forked_Unidirectionals': `<T1> <tag> <T2..n>.`,
    'Forked_Unidirectionals_with_missing': `<T1> <tag> <T2..n> and <num> more.`,
    'sequence': `in that sequence.`,
    'Bidirectional_Tagged_Link': `<T1> ഒപ്പം <T2> തുല്യമാണ്.`,
    'Bidirectional_Tagged_Link_(tag)': `<T1> ഒപ്പം <T2> ആകുന്നു <tag>.`,
    'Bidirectional_Tagged_Link_(ftag,btag)': `<T1> <forward tag> <T2>, ഒപ്പം <T2> <backward tag> <T1>.`,
  },
  'procedural_link': {
    /*
     * <O_Os> - OPM Object or OPM Stateful Object
     * <O_Os1...n> - set of OPM Objects or OPM Stateful Objects
     * <s> - OPM State
     * <P> - OPM Process
     */
    'Agent': `<O_Os> കൈകാര്യം ചെയ്യുന്നു <P>.`,
    'Agent_(multiple)': `<O\Os1...n> കൈകാര്യം ചെയ്യുക <P>.`,
    'Agent_Condition': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> നിലവിലുണ്ട്, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Agent_Condition(multiple)': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O\Os1...n> നിലവിലുണ്ട്, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Agent_Condition_state': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> ആണ് <s>, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Agent_Event': `<O> ആരംഭിക്കുകയും കൈകാര്യം ചെയ്യുകയും ചെയ്യുന്നു <P>.`,
    'Agent_Event(multiple)': `<O\Os1...n> ആരംഭിച്ച് കൈകാര്യം ചെയ്യുക <P>.`,
    'Agent_Event_state': `<s> <O> ആരംഭിക്കുകയും കൈകാര്യം ചെയ്യുകയും ചെയ്യുന്നു<P>.`,

    'Instrument': `<P> ആവശ്യമാണ് <O_Os>.`,
    'Instrument_(multiple)': `<P> ആവശ്യമാണ് <O\Os1...n>.`,
    'Instrument_Condition': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> നിലവിലുണ്ട്, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Instrument_Condition(multiple)': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O\Os1...n> നിലവിലുണ്ട്, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Instrument_Condition_state': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> ആണ് അവസ്ഥ <s>, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Instrument_Event': `<O> ആരംഭിക്കുന്നു <P>, ഇതിന് ആവശ്യമാണ് <O>.`,
    'Instrument_Event(multiple)': `<O\Os1...n> തുടങ്ങിവയ്ക്കുക <P>, അതിന് അവ ആവശ്യമാണ്.`,
    'Instrument_Event_state': `<s> <O> ആരംഭിക്കുന്നു <P>, ഇതിന് ആവശ്യമാണ് <s> <O>.`,

    'Effect': `<P> ബാധിക്കുന്നു <O_Os>.`,
    'Effect_(multiple)': `<P> ബാധിക്കുന്നു <O\Os1...n>.`,
    'Effect_Condition': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> നിലവിലുണ്ട്, ഏത് സാഹചര്യത്തിൽ <P> ബാധിക്കുന്നു <O>, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Effect_Condition(multiple)': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O\Os1...n> നിലവിലുണ്ട്, ഏത് സാഹചര്യത്തിൽ <P> അവരെ ബാധിക്കുന്നു, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Effect_Condition_state': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> ആണ് അവസ്ഥ <s>, ഏത് സാഹചര്യത്തിൽ <P> ബാധിക്കുന്നു <s> <O>, അല്ലെങ്കിൽ  <P> ഒഴിവാക്കി.`,
    'Effect_Event': `<O> ആരംഭിക്കുന്നു <P>, ഇത് ബാധിക്കുന്നു <O>`,
    'Effect_Event(multiple)': `<O\Os1...n> തുടങ്ങിവയ്ക്കുക <P>, ഇത് ബാധിക്കുന്നു <O>.`,
    'Effect_Event_state': `<s> <O> ആരംഭിക്കുന്നു <P>, ഇത് ബാധിക്കുന്നു <s> <O>`,

    'Consumption': `<P> ഉപയോഗിക്കുന്നു <O_Os>.`,
    'Consumption_(multiple)': `<P> ഉപയോഗിക്കുന്നു <O\Os1...n>.`,
    'Consumption_Condition': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> നിലവിലുണ്ട്, ഏത് സാഹചര്യത്തിൽ <P> ഉപയോഗിക്കുന്നു  <O>, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Consumption_Condition(multiple)': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O\Os1...n> നിലവിലുണ്ട്, ഏത് സാഹചര്യത്തിൽ <P> ഉപയോഗിക്കുന്നു അവ, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Consumption_Condition_state': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> അവസ്ഥയിൽ ആണ്  <s>, ഏത് സാഹചര്യത്തിൽ <P> ഉപയോഗിക്കുന്നു <s> <O>, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'Consumption_Event': `<O> ആരംഭിക്കുന്നു <P>, അത് ഉപയോഗിക്കുന്നു <O>.`,
    'Consumption_Event(multiple)': `<O\Os1...n> തുടങ്ങിവയ്ക്കുക <P>, അത് അവരെ ഉപയോഗിക്കുന്നു .`,
    'Consumption_Event_state': `<s> <O> ആരംഭിക്കുന്നു <P>, അത് ഉപയോഗിക്കുന്നു <O>.`,

    'Result': `<P> വിളവ് <O_Os>.`,
    'Result_(multiple)': `<P> വിളവ് <O\Os1...n>.`,

    'In-out_Link_Pair': `<P> മാറ്റങ്ങൾ <O> മുതൽ <s1> ടു <s2>.`,
    'In-out(group)': `<O> മുതൽ <s1> ടു <s2>`,
    'In-out_Link_Pair(group)': `<P> മാറ്റങ്ങൾ <O> മുതൽ <s1> ടു <s2><Other_changes>.`,
    'Split_input': `<P> മാറ്റങ്ങൾ <O> മുതൽ മറ്റേതെങ്കിലും  അവസ്ഥലേക്ക്<s>.`,
    'Split_output': `<P> മാറ്റങ്ങൾ <O> മറ്റേതെങ്കിലും അവസ്ഥയിൽ നിന്ന് <s>.`,
    'Condition_Input': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> ആണ് <s1>, ഏത് സാഹചര്യത്തിൽ <P> മാറ്റങ്ങൾ <O> മുതൽ <s1> ടു  <s2>, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'In-out_Link_Pair_Condition': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> ആണ്  <s1>, ഏത് സാഹചര്യത്തിൽ <P> മാറ്റങ്ങൾ <O> മുതൽ <s1> ടു  <s2>, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'In-out_Link_Pair_Condition(group)': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> ആണ് <s1>, ഏത് സാഹചര്യത്തിൽ <P> മാറ്റങ്ങൾ <O> മുതൽ <s1> to <s2>, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
    'In-out_Link_Pair_Event': `<s1> <O> ആരംഭിക്കുന്നു <P>, അത് മാറുന്നു <O> മുതൽ <s1> ടു  <s2>.`,
    'In-out_Link_Pair_Event(group)': `<O> അവസ്ഥകളിൽ  <s1> ആരംഭിക്കുന്നു <P>, അത് മാറുന്നു <O> മുതൽ <s1> ടു  <s2>.`,

    'Overtime_exception': `<O> നയിക്കുക <P> എപ്പോൾ <O> ആണ്  <s> അതിലും കൂടുതൽ <maxtime> <units>.`,
    'Undertime_exception': `<O> നയിക്കുക <P> എപ്പോൾ <O> ആണ് <s> അതിൽ കുറവ് <mintime> <units>.`,
    'OvertimeUndertime-exception': `<O> നയിക്കുക <P> എപ്പോൾ <O> ആണ് <s> അതിലും കൂടുതൽ <maxtime> <units> കുറവ് <mintime> <units>.`,

    'Overtime_exception_(process)': `<P2> എങ്കിൽ സംഭവിക്കുന്നു <P1> എന്നതിനേക്കാൾ കൂടുതൽ നീണ്ടുനിൽക്കും <maxtime> <units>.`,
    'Undertime_exception_(process)': `<P2> എങ്കിൽ സംഭവിക്കുന്നു  <P1> കുറയുന്നു <mintime> <units>.`,
    'OvertimeUndertime-exception_(process)':
      `<P2> എങ്കിൽ സംഭവിക്കുന്നു <P1> കുറയുന്നു <mintime> <units> അല്ലെങ്കിൽ കൂടുതൽ നീണ്ടുനിൽക്കും <maxtime> <units>.`,
    'Invocation': `<P1> ഇതിലേക്ക് തിരിയുക <P2>.`,
    'Invocation_(multiple)': `<P1> ഇതിലേക്ക് തിരിയുക <P2...n>.`,
    'Invocation_(self)': `<P1> സ്വയം അഭ്യർത്ഥിക്കുന്നു.`,
    'Invocation_(parent)': `<P1> ഇതിലേക്ക് തിരിയുക <P2>.`,

  },
  'grouping': { // for OPM Elements in a group
    /*
     * <T> - OPM Thing
     * <T1> - first OPM Thing
     * <T2> - second OPM Thing
     * <T1...n-1> - first n-1 OPM Things
     * <Tn> - nth OPM Thing
     * <O1...n> - first nth OPM Objects
     * <P1...n> - first nth OPM Processes
     * <s> - OPM State
     * <O> - OPM Object
     * <a> - OPM Object as an Attribute
     * <e1...n> - set of OPM Objects as Exhibitors
     */
    'Single-Thing': `<T>`,
    'Multiple-Things': `<T1...n-1> ഒപ്പം <Tn>`,
    'Multiple-Things-Object-Process-Separated': `<O1...n>, കൂടാതെ <P1...n>`,
    'AND': `<T1...n-1> ആൻഡ് <Tn>`,
    'OR': `<T1...n-1> ഓർ <Tn>`,
    'XOR': `<T1> എക്സോർ <T2>`,
    'Stateful-Object': `<O> അവസ്ഥയിൽ <s>`,
    'Stateful-Object-value': `<O> എന്ന മൂല്യം <s>`,
    'Stateful-Object-value(multiple)': `<O> മൂല്യങ്ങളുള്ള <s1...n>`,
    'Stateful-Object(multiple)': `<O> അവസ്ഥകളിൽ <s1...n>`,
    'Attribute-Exhibitor': `<T> ന്റെ <e1...n>`,
    'indentation': `&nbsp;&nbsp;&nbsp;&nbsp;`,
    'Multiple-InOut': `<Other_changes> ഒപ്പം <change>.`
  },
  'logic_operators': {
    // process/object are the single thing in the XOR\OR relation
    // brothers - couple of states of the same object.
    'XOR': {
      'process': {
        'Result': '<P> ഇതിലൊന്ന് കൃത്യമായി നൽകുന്നു <O_Os1...n>.',
        'Result(brothers)': '<P> വിളവ് <O> അവസ്ഥകളിൽ ഒന്ന് <s1...n>.',

        'Consumption': `<P> ഇതിലൊന്ന് കൃത്യമായി ഉപയോഗിക്കുന്നു <O_Os1...n>.`,
        'Consumption(brothers)': `<P> ഉപയോഗിക്കുന്നു <O> അവസ്ഥകളിൽ ഒന്ന് <s1...n>.`,
        'Consumption_Condition': `<P> കൃത്യമായി ഒന്ന് ഉണ്ടെങ്കിൽ സംഭവിക്കുന്നു <O_Os1...n> നിലവിലുണ്ട്, ഏത് സാഹചര്യത്തിൽ <P> നിലവിലുള്ളത് ഉപയോഗിക്കുന്നു, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
        'Consumption_Condition(brothers)': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> ഒരു അവസ്ഥയാണ്  <s1...n>, ഏത് സാഹചര്യത്തിൽ<P> അത് ഉപയോഗിക്കുന്നു, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
        'Consumption_Event': `കൃത്യമായി ഒന്ന് <O_Os1...n> ആരംഭിക്കുന്നു <P>, അത് ഉപയോഗിക്കുന്നു.`,
        'Consumption_Event(brothers)': `<O> ആരംഭിക്കുന്നു <P> അത് ഒരു അവസ്ഥയിൽ ആയിരിക്കുമ്പോൾ    <s1...n>, അത് ഉപയോഗിക്കുന്നു.`,


        'Effect': `<P> ഒന്നിനെ കൃത്യമായി ബാധിക്കുന്നു <O_Os1...n>.`,
        'Effect(brothers)': `<P> ബാധിക്കുന്നു <O> അവസ്ഥകളിൽ ഒന്ന് <s1...n>.`,
        'Effect_Condition': `<P> കൃത്യമായി ഒന്ന് ഉണ്ടെങ്കിൽ സംഭവിക്കുന്നു <O_Os1...n> നിലവിലുണ്ട്, ഏത് സാഹചര്യത്തിൽ <P> അതിനെ ബാധിക്കുന്നു, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
        'Effect_Condition(brothers)': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> ഒരു അവസ്ഥയാണ്  <s1...n>, ഏത് സാഹചര്യത്തിൽ <P> അതിനെ ബാധിക്കുന്നു, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
        'Effect_Event': `കൃത്യമായി ഒന്ന് <O_Os1...n> ആരംഭിക്കുന്നു <P>, അത് ബാധിക്കുന്നു.`,
        'Effect_Event(brothers)': `<O> ആരംഭിക്കുന്നു<P> അത് ഒരു അവസ്ഥയിൽ ആയിരിക്കുമ്പോൾ <s1...n>, അത് ബാധിക്കുന്നു.`,

        'Agent': `കൃത്യമായി ഒന്ന് <O_Os1...n> കൈകാര്യം ചെയ്യുന്നു <P>.`,
        'Agent(brothers)': `<O> കൈകാര്യം ചെയ്യുന്നു <P> അത് ഒരു അവസ്ഥയിൽ ആയിരിക്കുമ്പോൾ <s1...n>.`,
        'Agent_Event': `കൃത്യമായി ഒന്ന് <O_Os1...n> ആരംഭിക്കുകയും കൈകാര്യം ചെയ്യുകയും ചെയ്യുന്നു <P>.`,
        'Agent_Event(brothers)': `<O> ആരംഭിക്കുകയും കൈകാര്യം ചെയ്യുകയും ചെയ്യുന്നു <P> അത് ഒരു അവസ്ഥയിൽ ആയിരിക്കുമ്പോൾ <s1...n>.`,
        'Agent_Condition': `<P> കൃത്യമായി ഒന്ന് ഉണ്ടെങ്കിൽ സംഭവിക്കുന്നു <O_Os1...n> നിലവിലുണ്ട്, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
        'Agent_Condition(brothers)': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> ഒരു അവസ്ഥയാണ് <s1...n>, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,

        'Instrument': `<P> ഇതിലൊന്ന് കൃത്യമായി ആവശ്യമാണ് <O_Os1...n>.`,
        'Instrument(brothers)': `<P> ആവശ്യമാണ് <O> അവസ്ഥകളിൽ ഒന്ന്  <s1...n>.`,
        'Instrument_Condition': `<P> ഇതിലൊന്ന് കൃത്യമായി ആവശ്യമാണ് <O_Os1...n> നിലവിലുണ്ട്, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
        'Instrument_Condition(brothers)': `<P> എങ്കിൽ സംഭവിക്കുന്നു <O> ഒരു അവസ്ഥയാണ് <s1...n>, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
        'Instrument_Event': `കൃത്യമായി ഒന്ന് <O_Os1...n> ആരംഭിക്കുന്നു <P>, അതിന് അത് ആവശ്യമാണ്.`,
        'Instrument_Event(brothers)': `<O> ആരംഭിക്കുന്നു <P> അത് ഒരു അവസ്ഥയിൽ ആയിരിക്കുമ്പോൾ  <s1...n>, അതിന് അത് ആവശ്യമാണ്.`,

        'Invocation_OUT': `<P> ഒന്നുകിൽ അഭ്യർത്ഥിക്കുന്നു <P1...n>.`,
        'Invocation_IN': `ഒന്നുകിൽ <P1...n> ഇതിലേക്ക് തിരിയുക <P>.`,
        'InOut': `<P> changes <O> from <s1> to exactly one of <O_Os2...n>.`,
        'InOut_multi_Ins_Xor': `<P> changes <O> from exactly one of <ins1..n> to exactly one of <O_Os2...n>.`,
      },
      'object': { // _state means the link fan is from state.
        'Result': `കൃത്യമായി ഒന്ന് <P1...n> വിളവ് <O_Os>.`,

        'Consumption': `കൃത്യമായി ഒന്ന് <P1...n> ഉപയോഗിക്കുന്നു <O_Os>`,
        'Consumption_Event': `<O_Os> കൃത്യമായി ഒരെണ്ണം ആരംഭിക്കുന്നു <P1...n>, ഇത് സമാരംഭിച്ച പ്രക്രിയ ഉപയോഗിക്കുന്നു.`,
        'Consumption_Condition_state': `കൃത്യമായി ഒന്ന് <P1...n> എങ്കിൽ സംഭവിക്കുന്നു <O> ആണ് <s1>, അത്തരം സന്ദർഭങ്ങളിൽ സംഭവിക്കുന്ന പ്രക്രിയ ഉപയോഗിക്കുന്നു <O>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,
        'Consumption_Condition': `കൃത്യമായി ഒന്ന് <P1...n> എങ്കിൽ സംഭവിക്കുന്നു <O_Os> നിലവിലുണ്ട്, അത്തരം സന്ദർഭങ്ങളിൽ സംഭവിക്കുന്ന പ്രക്രിയ ഉപയോഗിക്കുന്നു <O_Os>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,

        'Effect': `കൃത്യമായി ഒന്ന് <P1...n> ബാധിക്കുന്നു <O_Os>.`,
        'Effect_Event': `<O_Os> കൃത്യമായി ഒരെണ്ണം ആരംഭിക്കുന്നു <P1...n>, ഇത് സംഭവിക്കുന്ന പ്രക്രിയയെ ബാധിക്കുന്നു.`,
        'Effect_Condition': `കൃത്യമായി ഒന്ന് <P1...n> എങ്കിൽ സംഭവിക്കുന്നു <O_Os> നിലവിലുണ്ട്, അത്തരം സന്ദർഭങ്ങളിൽ സംഭവിക്കുന്ന പ്രക്രിയയെ ബാധിക്കുന്നു <O_Os>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,

        'Agent': `<O_Os> ഒരെണ്ണം കൃത്യമായി കൈകാര്യം ചെയ്യുന്നു <P1...n>.`,
        'Agent_Event': `<O_Os> ഒരെണ്ണം കൃത്യമായി ആരംഭിക്കുകയും കൈകാര്യം ചെയ്യുകയും ചെയ്യുന്നു <P1...n>.`,
        'Agent_Condition': `<O_Os> ഒരെണ്ണം കൃത്യമായി കൈകാര്യം ചെയ്യുന്നു <P1...n> എങ്കിൽ <O_Os> നിലവിലുണ്ട്, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,
        'Agent_Condition_state': `<O> ഒരെണ്ണം കൃത്യമായി കൈകാര്യം ചെയ്യുന്നു <P1...n> എങ്കിൽ <O> ആണ് <s1>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,

        'Instrument': `കൃത്യമായി ഒന്ന് <P1...n> ആവശ്യമാണ് <O_Os>.`,
        'Instrument_Event': `<O_Os> കൃത്യമായി ഒരെണ്ണം ആരംഭിക്കുന്നു <P1...n>, ഇതിന് ആവശ്യമാണ് <O_Os>.`,
        'Instrument_Condition': `കൃത്യമായി ഒന്ന് <P1...n> ആവശ്യമാണ്  <O_Os>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,
        'Instrument_Condition_state': `കൃത്യമായി ഒന്ന് <P1...n> അത് ആവശ്യമാണ് <O> ആണ്  <s1>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`
      }
    },
    'OR': {
      'process': {
        'Result': '<P> കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും നൽകുന്നു <O_Os1...n>.',

        'Consumption': `<P> ഇതിലൊന്നെങ്കിലും ഉപയോഗിക്കുന്നു <O_Os1...n>.`,
        'Consumption_Condition': `<P> കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും സംഭവിക്കുന്നു <O_Os1...n> നിലവിലുണ്ട്, ഏത് സാഹചര്യത്തിൽ <P> നിലവിലുള്ളവ ഉപയോഗിക്കുന്നു, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
        'Consumption_Event': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <O_Os1...n> ആരംഭിക്കുന്നു <P>, അത് ഉപയോഗിക്കുന്നു.`,

        'Effect': `<P> ഇതിലൊന്നെങ്കിലും ബാധിക്കുന്നു <O_Os1...n>.`,
        'Effect_Condition': `<P> കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും സംഭവിക്കുന്നു <O_Os1...n> നിലവിലുണ്ട്, ഏത് സാഹചര്യത്തിൽ <P> അതിനെ ബാധിക്കുന്നു, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
        'Effect_Event': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <O_Os1...n> ആരംഭിക്കുന്നു <P>, അത് ബാധിക്കുന്നു.`,

        'Agent': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <O_Os1...n> കൈകാര്യം ചെയ്യുന്നു <P>.`,
        'Agent_Event': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <O_Os1...n> ആരംഭിക്കുകയും കൈകാര്യം ചെയ്യുകയും ചെയ്യുന്നു <P>.`,
        'Agent_Condition': `<P> കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും സംഭവിക്കുന്നു <O_Os1...n> നിലവിലുണ്ട്, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,

        'Instrument': `<P> ഇതിലൊന്നെങ്കിലും ആവശ്യമാണ് <O_Os1...n>.`,
        'Instrument_Condition': `<P> കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും സംഭവിക്കുന്നു <O_Os1...n> നിലവിലുണ്ട്, അല്ലെങ്കിൽ <P> ഒഴിവാക്കി.`,
        'Instrument_Event': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <O_Os1...n> ആരംഭിക്കുന്നു <P>, അതിന് അത് ആവശ്യമാണ്.`,


        'Invocation_OUT': `<P> ഇതിലൊന്നെങ്കിലും അഭ്യർത്ഥിക്കുന്നു <P1...n>.`,
        'Invocation_IN': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <P1...n> ഇതിലേക്ക് തിരിയുക <P>.`,

        'InOut': `<P> changes <O> from <s1> to at least one of <O_Os2...n>.`,
      },
      'object': {
        'Result': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <P1...n> വിളവ് <O_Os>`,

        'Consumption': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <P1...n> ഉപയോഗിക്കുന്നു <O_Os>`,
        'Consumption_Event': `<O_Os> ഇതിലൊന്നെങ്കിലും ആരംഭിക്കുന്നു <P1...n>, ഇത് സമാരംഭിച്ച പ്രക്രിയ ഉപയോഗിക്കുന്നു.`,
        'Consumption_Condition_state': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <P1...n> എങ്കിൽ സംഭവിക്കുന്നു <O> ആണ് <s1>, അത്തരം സന്ദർഭങ്ങളിൽ സംഭവിക്കുന്ന പ്രക്രിയ ഉപയോഗിക്കുന്നു <O>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,
        'Consumption_Condition': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <P1...n> എങ്കിൽ സംഭവിക്കുന്നു <O_Os> നിലവിലുണ്ട്, അത്തരം സന്ദർഭങ്ങളിൽ സംഭവിക്കുന്ന പ്രക്രിയ ഉപയോഗിക്കുന്നു <O_Os>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,

        'Effect': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <P1...n> ബാധിക്കുന്നു <O_Os>`,
        'Effect_Event': `<O_Os> ഇതിലൊന്നെങ്കിലും ആരംഭിക്കുന്നു <P1...n>, ഇത് സംഭവിക്കുന്ന പ്രക്രിയയെ ബാധിക്കുന്നു.`,
        'Effect_Condition': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <P1...n> എങ്കിൽ സംഭവിക്കുന്നു <O_Os> നിലവിലുണ്ട്, അത്തരം സന്ദർഭങ്ങളിൽ സംഭവിക്കുന്ന പ്രക്രിയയെ ബാധിക്കുന്നു <O_Os>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,

        'Agent': `<O_Os> ഒരെണ്ണമെങ്കിലും കൈകാര്യം ചെയ്യുന്നു <P1...n>.`,
        'Agent_Event': `<O_Os> ഒരെണ്ണമെങ്കിലും ആരംഭിക്കുകയും കൈകാര്യം ചെയ്യുകയും ചെയ്യുന്നു <P1...n>.`,
        'Agent_Condition': `<O_Os> ഒരെണ്ണമെങ്കിലും കൈകാര്യം ചെയ്യുന്നു <P1...n> എങ്കിൽ <O_Os> നിലവിലുണ്ട്, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,
        'Agent_Condition_state': `<O> ഒരെണ്ണമെങ്കിലും കൈകാര്യം ചെയ്യുന്നു <P1...n> എങ്കിൽ <O> ആണ് <s1>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,

        'Instrument': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <P1...n> ആവശ്യമാണ് <O_Os>`,
        'Instrument_Event': `<O_Os> ഇതിലൊന്നെങ്കിലും ആരംഭിക്കുന്നു <P1...n>, ഇതിന് ആവശ്യമാണ് <O_Os>`,
        'Instrument_Condition': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <P1...n> ആവശ്യമാണ് <O_Os>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`,
        'Instrument_Condition_state': `കുറഞ്ഞത് ഒരെണ്ണമെങ്കിലും <P1...n> അത് ആവശ്യമാണ് <O> ആണ് <s1>, അല്ലെങ്കിൽ ഈ പ്രക്രിയകൾ ഒഴിവാക്കി.`
      }
    }
  },
  'tags': {
    'multiplicity': `<tag> <O_Os>`,
    'constraints': `<O_Os>; എവിടെ <tag>`,
    'probability': `<O_Os>സംഭാവ്യതയോടെ <tag>`,
    'rate': `<O_Os> എന്ന നിരക്കിൽ <tag> <units>`,
    'path': `പാത പിന്തുടരുന്നു <tag>, <opl>`,
    'range': `<r> <opl>`
  },
  'symbols': {
    '?': `an optional`,
    '*': `optional`,
    '+': `at least one`,
    'n..n': `<n1> ടു <n2>`,
    'n..*': `<n1> പല സ്ഥലങ്ങളിലേക്ക്`
  },
  'ranges': {
    '<=': `less than or equal to`,
    '>=': 'more than or equal to',
    '=': 'equal to',
    '<>': 'not equal to',
    '<': `less than`,
    '>': 'more than'
  },
  'object': {
    'thing_generic_name': `വസ്തു`,
    'default_essence_affiliation': ``,
    'digital_twin': `<TWIN> is the Digital Twin of <O>.`,
    'default_essence': `<O> ആണ്  <a>.`,
    'default_affiliation': `<O> ആണ്  <e>.`,
    'non_default': `<O> ആണ്  <e> ഒപ്പം <a>.`,
    'singleInzoom': `<O> മുതൽ <SD_Parent> സൂം ഇൻ <Current_SD> എന്നതിലേക്ക് <T_list>.`,
    'singleInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>.`,
    'multiInzoomInDiagram': `<O> zooms into <O_list> as depicted in <Current_SD>, as well as <P_list>.`,
    'multiInzoom': `<O> മുതൽ <SD_Parent> സൂം ഇൻ <Current_SD> എന്നതിലേക്ക് <O_list>, കൂടാതെ <P_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<O> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<O> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<O> from <SD_Parent> feature-unfolds in <Current_SD> into <O_list>, as well as <P_list>.`,
    'single_unfold_instantiation': `<O> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    'object_list_sequence': `<O1...n>, ആ ലംബ ശ്രേണിയിൽ`,
    'object_list_parallel': `<O1...n>, ആ ലംബ ശ്രേണിയിൽ`
  },
  'process': {
    'thing_generic_name': `പ്രക്രിയ`,
    'default_essence_affiliation': ``,
    'default_essence': `<P> ആണ് <a>.`,
    'default_affiliation': `<P> ആണ് <e>.`,
    'non_default': `<P> ആണ്<e> ഒപ്പം <a>.`,
    'singleInzoom': `<P> മുതൽ <SD_Parent> സൂം ഇൻ <Current_SD> എന്നതിലേക്ക് <T_list>, അത് ആ സമയ ശ്രേണിയിൽ സംഭവിക്കുന്നു.`,
    'singleInzoom_parallel': `<P> from <SD_Parent> zooms in <Current_SD> into <T_list>.`,
    'multiInzoom': `<P> മുതൽ <SD_Parent> സൂം ഇൻ <Current_SD> എന്നതിലേക്ക് <P_list>, കൂടാതെ <O_list>.`,
    'multiInzoomOneProcess': `<P> from <SD_Parent> zooms in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_aggregation': `<P> from <SD_Parent> part-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_generalization': `<P> from <SD_Parent> specialization-unfolds in <Current_SD> into <T_list>.`,
    'single_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <T_list>.`,
    'multi_unfold_exhibition': `<P> from <SD_Parent> feature-unfolds in <Current_SD> into <P_list>, as well as <O_list>.`,
    'single_unfold_instantiation': `<P> from <SD_Parent> instance-unfolds in <Current_SD> into <T_list>.`,
    'unspecified_unfold': `<T> from <SD_Parent> unfolds in <Current_SD>.`,
    // 'process_list_parallel': `<P1...n>, ആ തിരശ്ചീന ശ്രേണിയിൽ`,
    'process_list_parallel': `സമാന്തരമായി <P1...n>`,
    // 'process_list_sequence': `<P1...n>, ആ ലംബ ശ്രേണിയിൽ`,
    'process_list_sequence': `<P1...n>`,
    'expected_duration': `ന്റെ പ്രതീക്ഷിച്ച കാലാവധിയുടെ മൂല്യം <P> ആണ് <exp> <units>.`,
    'min_duration': `ന്റെ കാലയളവിന്റെ കാലാവധിയുടെ മൂല്യം <P> ആണ് <min> <units>.`,
    'max_duration': `ന്റെ പ്രതീക്ഷിച്ച കാലാവധിയുടെ മൂല്യം <P> ആണ് <max> <units>.`,
    'min_max_range_duration': `ന്റെ ഏറ്റവും കുറഞ്ഞ കാലയളവിന്റെയും പരമാവധി കാലാവധിയുടെയും മൂല്യങ്ങൾ <P> ആകുന്നു <min> <units> ഒപ്പം <max> <units>, യഥാക്രമം.`,
    'min_exp_range_duration': `ന്റെ ഏറ്റവും കുറഞ്ഞ കാലയളവിന്റെയും പരമാവധി കാലാവധിയുടെയും മൂല്യങ്ങൾ <P> ആകുന്നു <min> <units> ഒപ്പം <exp> <units>, യഥാക്രമം.`,
    'exp_max_range_duration': `ന്റെ ഏറ്റവും കുറഞ്ഞ കാലയളവിന്റെയും പരമാവധി കാലാവധിയുടെയും മൂല്യങ്ങൾ <P> ആകുന്നു <exp> <units> ഒപ്പം <max> <units>, യഥാക്രമം.`,
    'expected_range_duration': `കുറഞ്ഞ കാലയളവിന്റെ മൂല്യങ്ങൾ, പ്രതീക്ഷിച്ച കാലാവധി, ഒപ്പം പരമാവധി ദൈർഘ്യം <P> ആകുന്നു <min> <units>, <exp> <units>, ഒപ്പം <max> <units>, യഥാക്രമം.`
  },
  'state': {
    'single_state': `<O> ആണ് <s>.`,
    'multiple_states': `<O> ആകാം <s1...n>.`,
    'all_states_are_suppressed': `<O> is stateful.`,
    'one_state_shown_one_missing': `<O> is <s> or at one other state.`,
    'one_state_shown': `<O> is <s> and can be at one of <num> other states.`,
    'two_or_more_states_shown_one_missing': `<O> can be <s1...n> or at one other state.`,
    'two_or_more_states_shown': `<O> can be <s1...n> or at one of <num> other states.`,
    'default': `അവസ്ഥ <s> സ്ഥിരസ്ഥിതിയാണ്.`,
    'default_initial': `അവസ്ഥ <s> സ്ഥിരസ്ഥിതിയും പ്രാരംഭവുമാണ്.`,
    'initial': `അവസ്ഥ <s> പ്രാരംഭമാണ്.`,
    'final': `അവസ്ഥ <s> അന്തിമമാണ്.`,
    'default_initial_final': `അവസ്ഥ <s> പ്രാരംഭമാണ്, ഫൈനൽ, സ്ഥിരസ്ഥിതി.`,
    'initial_final': `അവസ്ഥ <s> പ്രാരംഭവും അന്തിമവുമാണ്.`,
    'default_final': `അവസ്ഥ <s> അന്തിമവും സ്ഥിരവുമാണ്.`,
    'none': ``,
    'Current': `<O> is currently at state <s>.`,
    // 'timeDurational': {
      'expected_duration': `ന്റെ പ്രതീക്ഷിച്ച കാലാവധിയുടെ മൂല്യം <s> ആണ് <exp> <units>.`,
      'min_duration': `ന്റെ കാലയളവിന്റെ കാലാവധിയുടെ മൂല്യം <s> ആണ് <min> <units>.`,
      'max_duration': `ന്റെ പ്രതീക്ഷിച്ച കാലാവധിയുടെ മൂല്യം <s> ആണ് <max> <units>.`,
      'min_max_range_duration': `ന്റെ ഏറ്റവും കുറഞ്ഞ കാലയളവിന്റെയും പരമാവധി കാലാവധിയുടെയും മൂല്യങ്ങൾ <s> ആകുന്നു <min> <units> ഒപ്പം <max> <units>, യഥാക്രമം.`,
      'min_exp_range_duration': `ന്റെ ഏറ്റവും കുറഞ്ഞ കാലയളവിന്റെയും പരമാവധി കാലാവധിയുടെയും മൂല്യങ്ങൾ <s> ആകുന്നു <min> <units> ഒപ്പം <exp> <units>, യഥാക്രമം.`,
      'exp_max_range_duration': `ന്റെ ഏറ്റവും കുറഞ്ഞ കാലയളവിന്റെയും പരമാവധി കാലാവധിയുടെയും മൂല്യങ്ങൾ <s> ആകുന്നു <exp> <units> ഒപ്പം <max> <units>, യഥാക്രമം.`,
      'expected_range_duration': `കുറഞ്ഞ കാലയളവിന്റെ മൂല്യങ്ങൾ, പ്രതീക്ഷിച്ച കാലാവധി, ഒപ്പം പരമാവധി ദൈർഘ്യം <s> ആകുന്നു <min> <units>, <exp> <units>, ഒപ്പം <max> <units>, യഥാക്രമം.`,
      'default_time_units': `seconds`
  //  },
  },
  'essence': {
    'physical': `physical`,
    'informatical': `informatical`,
  },
  'affiliation': {
    'systemic': `systemic`,
    'environmental': `environmental`,
  },
  'semifolding': {
    'object': `<O> lists `,
    'process': `<P> lists `,
    'aggregation': {
      'single': `<T> as a part`,
      'multiple': `<T1...n-1> and <Tn> as parts`,
    },
    'exhibition': {
      'single': `<T> as a feature`,
      'multiple': `<T1...n-1> and <Tn> as features`,
    },
    'generalization': {
      'single': `<T> as a specialization`,
      'multiple': `<T1...n-1> and <Tn> as specializations`,
    },
    'instantiation': {
      'single': `<T> as an instance`,
      'multiple': `<T1...n-1> and <Tn> as instances`,
    },
  }
};


/*
 * This function updates the linkTable based on the CURRENT oplTemplate
*/
export function changeLinkTable() {
  const combinedTemplates = {};
  for (const linkType of Object.keys(oplTemplates.structural_link)) {
    combinedTemplates[linkType] = oplTemplates.structural_link[linkType];
  }
  for (const linkType of Object.keys(oplTemplates.procedural_link)) {
    combinedTemplates[linkType] = oplTemplates.procedural_link[linkType];
  }
  for (const relation of Object.keys(oplTable)) {
    for (const linkType of Object.keys(oplTable[relation])) {

      try {
        let templateLinkType = linkType;
        if (oplTable[relation][linkType].indexOf('<s>') > -1) {
          const statefulLink = linkType + '_state';
          if (Object.keys(combinedTemplates).indexOf(statefulLink) > -1) {
            templateLinkType = statefulLink;
          }
        }

        switch (relation) {
          case 'O1-O2': {
            oplTable[relation][linkType] = combinedTemplates[templateLinkType].replace('<T1>', '<O1>').replace('<T2>', '<O2>');
            break;
          }
          case 'O1-O2s': {
            oplTable[relation][linkType] = combinedTemplates[templateLinkType].replace('<T2>',
              oplTemplates.grouping['Stateful-Object'].replace('<O>', '<O2>')).replace('<T1>', '<O1>');
            break;
          }
          case 'O-P': {
            oplTable[relation][linkType] = combinedTemplates[templateLinkType].replace('<O_Os>', '<O>')
              .replace('<T1>', '<O>').replace('<T2>', '<P>');
            break;
          }


          case 'P-O': {
            oplTable[relation][linkType] = combinedTemplates[templateLinkType].replace('<O_Os>', '<O>')
              .replace('<T1>', '<P>').replace('<T2>', '<O>');
            break;
          }
          case 'P1-P2': {
            if (linkType.indexOf('exception') > -1) {
              templateLinkType = linkType + '_(process)';
            }
            oplTable[relation][linkType] = combinedTemplates[templateLinkType]
              .replace('<T1>', '<P1>').replace('<T2>', '<P2>');
            break;
          }
          case 'P-Os': {
            oplTable[relation][linkType] = combinedTemplates[templateLinkType]
              .replace('<O_Os>', oplTemplates.grouping['Stateful-Object'])
              .replace('<T1>', '<P>').replace('<T2>', oplTemplates.grouping['Stateful-Object']);
            break;
          }
          case 'P1-P1 (same process)': {
            if (linkType.toLowerCase().indexOf('invocation') > -1) {
              templateLinkType = linkType + '_(self)';
            }
            oplTable[relation][linkType] = combinedTemplates[templateLinkType]
              .replace('<T1>', '<P1>').replace('<T2>', '<P2>');
            break;
          }
          case 'P1-P2 (parent process)': {
            if (linkType.toLowerCase().indexOf('invocation') > -1) {
              templateLinkType = linkType + '_(parent)';
            }
            oplTable[relation][linkType] = combinedTemplates[templateLinkType]
              .replace('<T1>', '<P1>').replace('<T2>', '<P2>');
            break;
          }

          case 'O1s-O2': {
            oplTable[relation][linkType] = combinedTemplates[templateLinkType].replace('<T1>',
              oplTemplates.grouping['Stateful-Object'].replace('<O>', '<O1>')).replace('<T2>', '<O2>');
            break;
          }
          case 'O1s1-O2s2': {
            oplTable[relation][linkType] = combinedTemplates[templateLinkType]
              .replace('<T1>',
                oplTemplates.grouping['Stateful-Object'].replace('<O>', '<O1>').replace('<s>', '<s1>'))
              .replace('<T2>',
                oplTemplates.grouping['Stateful-Object'].replace('<O>', '<O2>').replace('<s>', '<s2>'));
            break;
          }
          case 'Os-P': {
            try {
              oplTable[relation][linkType] = combinedTemplates[templateLinkType]
                .replace('<O_Os>', oplTemplates.grouping['Stateful-Object'])
                .replace('<T2>', '<P>').replace('<T1>', oplTemplates.grouping['Stateful-Object']);
            } catch (e) {
              console.log();
            }
            break;
          }


          default: {
            oplTable[relation][linkType] = combinedTemplates[templateLinkType];
            break;
          }
        }

      } catch (e) {
        console.log();
      }
    }
  }
  return oplTable;
}

export const OplTables = { 'en': oplTemplates_en, 'cn': oplTemplates_cn, 'fr': oplTemplates_fr, 'gr': oplTemplates_gr ,
  'ko': oplTemplates_ko, 'jp': oplTemplates_jp, 'es': oplTemplates_es, 'ml': oplTemplates_ml, 'ru':  oplTemplates_ru, 'pt': oplTemplates_portuguese};

// The actual settings of the current user.
export let oplDefaultSettings = {
  'affiliation': Affiliations[0],
  'essence': Essences[0],
  'language': Languages[0],
  'oplNumbering': true,
  'autoFormat': true,
  'displayOpt': DisplayOpt[2],
  'oplTables': OplTables,
  'SDNames': false,
  'highlightOpl': false,
  'highlightOpd' : true,
  Notes: true,
  markThings: true,
  syncOplcolorsFromOpd: true,
  timeDurationUnitsDigits: 2,
  displayNotes: true,
  pythonExecution: 'local',
  bringConnectedSettings: {
    proceduralEnablers: true,
    proceduralTransformers: true,
    fundamentals: false,
    tagged: false,
  }
};


export let oplTemplates = oplDefaultSettings.oplTables[oplDefaultSettings.language];

export const structuralLinkTypes = Object.keys(oplTemplates['structural_link']);
export const proceduralLinkTypes = Object.keys(oplTemplates['procedural_link']);





/*
export const defaultTable_cn = {
  'O1-O2': {
    'Aggregation-Participation': `<O1>包含<O2>。`,
    'Bidirectional_Relation_(tag)': `<O1>和<O2>是<tag>。`,
    'Unidirectional_Relation': `<O1>与<O2>相关。`,
    'Bidirectional_Relation': `<O1>和<O2>是等价的。`,
    'Generalization-Specialization': `<O2>是一个<O1>。`,
    'Classification-Instantiation': `<O2>是<O1>的一个实例.`,
    'Bidirectional_Relation_(ftag,btag)': `<O1> <forward tag> <O2>和<O2> <backward tag> <O1>。`,
    'Exhibition-Characterization': `<O1>表现出<O2>。`},
  'P1-P2': {
    'Overtime_exception': `<P2>在<P1>持续超过<maxtime> <units>时发生。`,
    'Undertime_exception': `<P2>在<P1>持续少于<mintime> <units>时发生。`,
    'Aggregation-Participation': `<P1>包含<P2>。`,
    // 'Bidirectional_Relation_(tag)': `<P1> and <P2> are <forward tag>.`,
    'OvertimeUndertime-exception':
      `<P2>在<P1>持续少于<mintime> <units>或大于<maxtime> <units>时发生。`,
    'Unidirectional_Relation': `<P1>和<P2>相关。`,
    'Invocation': `<P1>调用<P2>。`,
    'Bidirectional_Relation': `<P1>和<P2>是等价的。`,
    'Generalization-Specialization': `<P2>是一个<P1>。`,
    'Classification-Instantiation': `<P2>是<P1>的一个实例。`,
    // 'Unidirectional_Relation_(tag)': `<P1> <tag> <P2>.`,
    // 'Bidirectional_Relation_(ftag,btag)': `<P1> <forward tag> <P2> and <P2> <backward tag> <P1>.`,
    'Exhibition-Characterization': `<P1>表现出<P2>。`},
  'P1-P1 (same process)': {
    'Invocation': `<P1>调用其本身。`},
  'O-P': {
    'Agent': `<O>处理<P>。`,
    'Agent_Condition': `<P>在<O>存在时发生,否则<P>被跳过。`,
    'Agent_Event': `<O>发起并处理<P>。`,
    'Consumption': `<P>消耗<O>。`,
    'Consumption_Condition': `<P>在<O>存在时发生,在这种情况下<P>消耗<O>,否则，<P>被跳过。`,
    'Consumption_Event': `<O>发起<P>,并且<P>消耗<O>。`,
    'Instrument': `<P>需要<O>.`,
    'Instrument_Condition': `<P>在<O>存在时发生,否则<P>被跳过。`,
    'Instrument_Event': `<O>发起<P>,并且<P>需要<O>。`,
    'Exhibition-Characterization': `<O>表现出<P>。`,
    'Effect': `<P>影响<O>。`,
    'Effect_Event': `<O>发起<P>,并且<P>影响<O>`,
    'Effect_Condition': `<P>在<O>存在时发生,在这种情况下<P>影响<O>,否则<P>被跳过。`,
  },
  'P-O': {
    'Exhibition-Characterization': `<P>表现出<O>。`,
    'Result': `<P>产出<O>。`,
    'Effect': `<P>影响<O>。`,
    'Effect_Event': `<O>发起<P>,并且影响<O>。`,
    'Effect_Condition': `<P>在<O>存在时发生,在这种情况下<P>影响<O>,否则<P>被调过。`,
  },
  'Os-(P)-O (from object state to the same object)': {
    'Overtime_exception': `当<O>为<s>超过<maxtime><units>时<O>触发<P>,在这种情况下<P>改变<O>。`,
    'Condition_Input ': `<P>在<O>是<s>时发生,在这种情况下<P>将<O>从<s>改变,否则<P>被跳过。`,
    'In-out_Link_Pair': `<P>将<O>从<s>改变。`},
  'O1s-O2': {
    'Bidirectional_Relation_(tag)': `<s> <O1>和<O2>是<forward tag>。`,
    'Unidirectional_Relation': `<s><O1>和<O2>相关。`,
    'Bidirectional_Relation': `<s><O1>和<O2>是等价的。`,
    'Unidirectional_Relation_(tag)': `<s><O1><tag><O2>。`,
    'Bidirectional_Relation_(ftag,btag)': `<s><O1><forward tag><O2>并且<O2><backward tag><s><O1>。`,
    'Exhibition-Characterization': `<s><O1>表现出<O2>。`},
  'P-Os': {
    'Split_output': `<P>改变<O>为<s>.`,
    'In-out_Link_Pair': `<P>把<O>从<s>变为<s>。`,
    // 'Exhibition-Characterization': `<P>表现出<s><O>。`,
    'Result': `<P>产出<s><O>。`},
  'O1s1-O2s2': {
    'Unidirectional_Relation': `<s1><O1>与<s2><O2>相关。`,
    'Bidirectional_Relation_(tag)': `<s1><O1>和<s2><O2>是<forward tag>。`,
    'Bidirectional_Relation_(ftag,btag)': `<s1><O1><forward tag><s2><O2>和<s2><O2><backward tag><s1><O1>。`,
    'Bidirectional_Relation': `<s1><O1>和<s2> <O2>是等价的。`,
    'Unidirectional_Relation_(tag)': `<s1><O1><tag><s2><O2>。`},
  'O1-T2..n (n>=2 many destinations)': {
    'Aggregation-Participation': `<O1>包含<T1...n-1>和<Tn>。`,
    'Unidirectional_Relation': `<O1>与<T1...n-1> 和 <Tn>相关。`,
    'Generalization-Specialization': `<T1...n-1> 和 <Tn> 是 <O1>。`,
    'Classification-Instantiation': `<T1...n-1> 和 <Tn>是<O1>的一个实例。`,
    'Unidirectional_Relation_(tag)': `<O1> <tag> <T1...n-1>和<Tn>。`,
    'Exhibition-Characterization': `<O1>表现出<T1...n-1>和<Tn>。`},
  'P1-T2..n (n>=2 many destinations)': {
    'Exhibition-Characterization': `<P1>表现出<T1...n-1>和<Tn>。`,
    'Aggregation-Participation': `<P1>包含<T1...n-1>和<Tn>。`,
    'Unidirectional_Relation': `<P1>与<T1...n-1>和<Tn>相关。`,
    'Generalization-Specialization': `<T1...n-1>和<Tn>是<P1>。`,
    'Classification-Instantiation': `<T1...n-1>和<Tn>是<P1>的一个实例。`,
    'Unidirectional_Relation_(tag)': `<P1> <tag> <T1...n-1> 和<Tn>.`},
  'O1s1-T2..n (n>=2 many destinations)': {
    'Exhibition-Characterization': `<s1><O1>表现出<T1...n-1>和<Tn>.`},
  'O1-O2s': {
    'Bidirectional_Relation_(tag)': `<O1>和<s><O2>是<forward tag>。`,
    'Unidirectional_Relation': `<O1>和<s><O2>相。.`,
    'Bidirectional_Relation': `<O1>和<s><O2>是等价的。`,
    'Unidirectional_Relation_(tag)': `<O1><tag><s><O2>。`,
    'Bidirectional_Relation_(ftag,btag)': `<O1> <forward tag> <s><O2>并且<s><O2> <backward tag><O1>.`,
    'Exhibition-Characterization': `<O1>表现出<s><O2>。`},
  'Os1-(P)-Os2 (same object)': {
    'Overtime_exception':
      `当<O>为<s1>超过<maxtime><units>时<O>触发<P>,在这种情况下<P>将<O>变为<s2>。`,
    'Condition_Input ': `如果<O>是<s1>,<P>发生,在这种情况下<P>把<O>从<s1>变为<s2>,否则<P>被跳过。`,
    'In-out_Link_Pair': `<P>把<O>从<s1>变为<s2>。`},
  'Os-P': {
    'In-out_Link_Pair': `<P>把<O>从<s>变为<s>。`,
    'Agent': `<s> <O>处理<P>。`,
    'Agent_Condition': `如果<O>是<s>,<P>发生,否则<P>被跳过。`,
    'Agent_Event': `<s><O>发起并处理<P>。`,
    'Consumption': `<P>消耗<s><O>。`,
    'Consumption_Condition': `当<O>是在<s>状态时<P>发生,在这种情况下<P>消耗<O>,否则<P>被跳过。`,
    'Consumption_Event': `<s><O>发起<P>,同时<O>被消耗。`,
    'Split_input': `<P>将<O>改变为<s>。`,
    'Instrument': `<P>需要<s><O>。`,
    'Instrument_Condition': `如果<O>是在<s>状态,<P>发生,否则<P>被跳过。`,
    'Instrument_Event': `<s><O>发起<P>,同时<P>需要<s><O>.`,
    'Overtime_exception': `当<O>为<s>超过<maxtime> <units>,<O>触发<P。`,
    'Undertime_exception': `当<O>为<s>少于<mintime> <units>,<O>触发<P>。`,
    'OvertimeUndertime-exception': `当<O>为 <s>超过<maxtime> <units>或少于<mintime> <units>,<O>触发<P>。`,
    'Exhibition-Characterization': `<s><O>表现出<P>。`},
  'Object': {
    default_essence_affiliation: ``,
    default_essence: `<O> is <a>.`,
    default_affiliation: `<O> is <e>.`,
    non_default: `<O> is <e> and <a>.`,
    singleInzoom: `<O> zooms into <T>.`,
    multiInzoom: `<O> zooms into <T1...n-1> and <Tn>.`
  },
  'Process': {
    default_essence_affiliation: ``,
    default_essence: `<P> is <a>.`,
    default_affiliation: `<P> is <e>.`,
    non_default: `<P> is <e> and <a>.`,
    singleInzoom: `<P> zooms into <T>.`,
    multiInzoom: `<P> zooms into <T1...n-1> and <Tn>.`
  },
  'State': {
    singleState: `<O> is <s>.`,
    multiStates: `<O> can be <s1...n-1> and <sn>.`,
    Default: `<s> by default`,
    DefInitial: `initially <s> by default`,
    Initial: `initially <s>`,
    Final: `finally <s>`,
    all: `initially and finally <s> by default`,
    finInitial: `initially and finally <s>`,
    DefFinal: `finally <s> by default`,
    none: `<s>`
  },
  'Essence': {
    physical: `physical`,
    informatical: `informatical`,
  },
  'Affiliation': {
    systemic: `systemic`,
    environmental: `environmental`,
  }
};
export const defaultTable_fr = {
  'O1-O2': {
    'Aggregation-Participation': '<O1> est compris de <O2>.',
    'Bidirectional_Relation_(tag)': '<O1> et<O2> sont <tag>.',
    'Unidirectional_Relation': '<O1> concerne <O2>.',
    'Bidirectional_Relation': '<O1> et <O2> sont équivalent.',
    'Generalization-Specialization': '<O2> est un <O1>.',
    'Classification-Instantiation': '<O2> est une instance de <O1>.',
    'Bidirectional_Relation_(ftag,btag)': '<O1> <forward tag> <O2> et <O2> <backward tag> <O1>.',
    'Exhibition-Characterization': '<O1> présente <O2>.'
  },
  'P1-P2': {
    'Overtime_exception': '<P2> se produit si <P1> dure plus de <maxtime> <units>.',
    'Undertime_exception': '<P2> se produit si <P1> est plus courte que <mintime> <units>.',
    'Aggregation-Participation': '<P1> consiste en <P2>.',
    'OvertimeUndertime-exception': '<P2> se produit si <P1> fest plus courte que <mintime> <units> ou dure plus de <maxtime> <units>.',
    'Unidirectional_Relation': '<P1> se rapporte à <P2>.',
    'Invocation': '<P1> invoque <P2>.',
    'Bidirectional_Relation': '<P1> et <P2> sont équivalent.',
    'Generalization-Specialization': '<P2> est un <P1>.',
    'Classification-Instantiation': '<P2> est une instance de <P1>.',
    'Exhibition-Characterization': '<P1>présente <P2>.'
  },
  'P1-P1 (same process)': {
    'Invocation': '<P1> s\'invoque.'
  },
  'O-P': {
    'Agent': '<O> gère <P>.',
    'Agent_Condition': '<P> se produit si <O> existe, sinon <P> est ignoré.',
    'Agent_Event': '<O> initie et gère <P>.',
    'Consumption': '<P> consomme <O>.',
    'Consumption_Condition': '<P> se produit si <O> existe, auquel cas <P> consomme <O>, sinon <P> est ignoré.',
    'Consumption_Event': '<O>initie <P>, qui consomme <O>.',
    'Instrument': '<P> exige <O>.',
    'Instrument_Condition': '<P> se produit si <O> existe, sinon <P> est ignoré.',
    'Instrument_Event': '<O> initie <P>, ce qui nécessite <O>.',
    'Exhibition-Characterization': '<O> présente <P>.',
    'Effect': '<P> affecte <O>.',
    'Effect_Event': '<O> initie <P>, ce qui affecte <O>',
    'Effect_Condition': '<P> se produit si <O> existe, auquel cas <P> affecte <O>, sinon <P> est ignoré.'
  },
  'P-O': {
    'Exhibition-Characterization': '<P> présente <O>.',
    'Result': '<P> produit <O>.',
    'Effect': '<P> affecte <O>.',
    'Effect_Event': '<O> initie <P>, ce qui affecte <O>',
    'Effect_Condition': '<P> se produit si <O> existe, auquel cas <P> affecte <O>, sinon <P> est ignoré.'
  },
  'Os-(P)-O (from object state to the same object)': {
    'Overtime_exception': '<O> déclenche <P> quand <O> est <s> plus de <maxtime> <units>, auquel cas <P> change <O>.',
    'Condition_Input ': '<P> se produit si <O> est <s>, auquel cas <P> change <O> de <s>, sinon <P> est ignoré.',
    'In-out_Link_Pair': '<P> change <O> de <s>.'
  },
  'O1s-O2': {
    'Bidirectional_Relation_(tag)': '<s> <O1> et <O2> sont <forward tag>.',
    'Unidirectional_Relation': '<s> <O1> se rapporte à <O2>.',
    'Bidirectional_Relation': '<s> <O1> et <O2> sont équivalent.',
    'Unidirectional_Relation_(tag)': '<s> <O1> <tag> <O2>.',
    'Bidirectional_Relation_(ftag,btag)': '<s> <O1>  <forward tag> <O2> et <O2> <backward tag> <s> <O1>.',
    'Exhibition-Characterization': '<s> <O1> présente <O2>.'
  },
  'P-Os': {
    'Split_output': '<P> change <O> to <s>.',
    'In-out_Link_Pair': '<P> change <O> from <s> to <s>.',
    // 'Exhibition-Characterization': '<P> présente <s> <O>.',
    'Result': '<P> se produit <s> <O>.'
  },
  'O1s1-O2s2': {
    'Unidirectional_Relation': '<s1> <O1> se rapporte à <s2> <O2>.',
    'Bidirectional_Relation_(tag)': '<s1> <O1> et <s2> <O2> sont <forward tag>.',
    'Bidirectional_Relation_(ftag,btag)': '<s1> <O1> <forward tag> <s2><O2> et <s2><O2> <backward tag><s1> <O1>.',
    'Bidirectional_Relation': '<s1> <O1> et <s2> <O2> sont équivalent.',
    'Unidirectional_Relation_(tag)': '<s1> <O1> <tag> <s2> <O2>.'
  },
  'O1-T2..n (n>=2 many destinations)': {
    'Aggregation-Participation': '<O1> consiste en <T1...n-1> et <Tn>.',
    'Unidirectional_Relation': '<O1> se rapporte à <T1...n-1> et <Tn>.',
    'Generalization-Specialization': '<T1...n-1> et <Tn> sont <O1>.',
    'Classification-Instantiation': '<T1...n-1> et <Tn> sont des instances de <O1>.',
    'Unidirectional_Relation_(tag)': '<O1> <tag> <T1...n-1> et <Tn>.',
    'Exhibition-Characterization': '<O1> présente <T1...n-1> et <Tn>.'
  },
  'P1-T2..n (n>=2 many destinations)': {
    'Exhibition-Characterization': '<P1> présente <T1...n-1> et <Tn>.',
    'Aggregation-Participation': '<P1> consiste en <T1...n-1> et <Tn>.',
    'Unidirectional_Relation': '<P1> se rapporte à <T1...n-1> et <Tn>.',
    'Generalization-Specialization': '<T1...n-1> et <Tn> sont <P1>.',
    'Classification-Instantiation': '<T1...n-1> et <Tn> sont des instances de <P1>.',
    'Unidirectional_Relation_(tag)': '<P1> <tag> <T1...n-1> et <Tn>.'
  },
  'O1s1-T2..n (n>=2 many destinations)': {
    'Exhibition-Characterization': '<s1> <O1> présente <T1...n-1> et <Tn>.'
  },
  'O1-O2s': {
    'Bidirectional_Relation_(tag)': '<O1> et  <s> <O2> sont <forward tag>.',
    'Unidirectional_Relation': '<O1> se rapporte à <s> <O2>.',
    'Bidirectional_Relation': '<O1> et <s> <O2> sont équivalent.',
    'Unidirectional_Relation_(tag)': '<O1> <tag> <s> <O2>.',
    'Bidirectional_Relation_(ftag,btag)': '<O1> <forward tag> <s><O2> et <s><O2> <backward tag> to  <O1>.',
    'Exhibition-Characterization': '<O1> présente <s> <O2>.'
  },
  'Os1-(P)-Os2 (same object)': {
    'Overtime_exception': '<O> déclenche le processus lorsque <O> est <s1> plus de <maxtime> <units>, auquel cas <P> change <o> en <s2>.',
    'Condition_Input ': '<P> se produit si <O> est <s1>, auquel cas <P> change <O> de <s1> en <s2>, sinon <P> est ignoré.',
    'In-out_Link_Pair': '<P> change <O> de <s1> à <s2>.'
  },
  'Os-P': {
    'In-out_Link_Pair': '<P> change <O> de <s> à <s>.',
    'Agent': '<s> <O> gère <P>.',
    'Agent_Condition': '<P> se produit si <O> est <s>, sinon <P> est ignoré.',
    'Agent_Event': '<s> <O>initie et gère <P>.',
    'Consumption': '<P> consomme <s> <O>.',
    'Consumption_Condition': '<P> se produit si <O> est à l\'état <s>, auquel cas <P> consomme <O>, sinon <P> est ignoré.',
    'Consumption_Event': '<s> <O> lance <P>, qui consomme <O>.',
    'Split_input': '<P> change <O> de <s>.',
    'Instrument': '<P> nécessite <s> <O>.',
    'Instrument_Condition': '<P> occurs if <O> is at state <s>, otherwise <P> is skipped.',
    'Instrument_Event': '<P> se produit si <O> est à l\'état <s>, sinon <P> est ignoré.',
    'Overtime_exception': '<O> déclenche <P> lorsque <O> est <s> supérieur à <maxtime> <units>.',
    'Undertime_exception': 'déclenche <P> lorsque <O> est <s> inférieur à <mintime> <units>.',
    'OvertimeUndertime-exception': '<O> déclenche <P> lorsque <O> est <s> supérieur à <maxtime> <units> et inférieur à <mintime> <units>.',
    'Exhibition-Characterization': '<s> <O> présente <P>.'
  },
  'Object': {
    'default_essence_affiliation': '',
    'default_essence': '<O> est <a>.',
    'default_affiliation': '<O> est <e>.',
    'non_default': '<O> est <e> et <a>.',
    'singleInzoom': '<O> effectue un zoom sur <T>.',
    'multiInzoom': '<O> effectue un zoom sur <T1...n-1> et <Tn>.'
  },
  'Process': {
    'default_essence_affiliation': '',
    'default_essence': '<P> est <a>.',
    'default_affiliation': '<P> est  <e>.',
    'non_default': '<P> est <e> et <a>.',
    'singleInzoom': '<P> effectue un zoom sur <T>.',
    'multiInzoom': '<P> effectue un zoom sur <T1...n-1> et <Tn>.'
  },
  'State': {
    'singleState': '<O> is <s>.',
    'multiStates': '<O> can be <s1...n-1> and <sn>.',
    'Default': '<s> by default',
    'DefInitial': 'initially <s> by default',
    'Initial': 'initially <s>',
    'Final': 'finally <s>',
    'all': 'initially and finally <s> by default',
    'finInitial': 'initially and finally <s>',
    'DefFinal': 'finally <s> by default',
    'none': '<s>'
  },
  'Essence': {
    'physical': 'physical',
    'informatical': 'informatical'
  },
  'Affiliation': {
    'systemic': 'systemic',
    'environmental': 'environmental'
  }
};
export const defaultTable_en = {
  'O1-O2': {
    'Aggregation-Participation': `<O1> consists of <O2>.`,
    'Unidirectional_Relation': `<O1> relates to <O2>.`,
    'Bidirectional_Relation': `<O1> and <O2> are equivalent.`,
    'Generalization-Specialization': `<O2> is a <O1>.`,
    'Classification-Instantiation': `<O2> is an instance of <O1>.`,
    'Unidirectional_Relation_(tag)': `<O1> <tag> <O2>.`,
    'Bidirectional_Relation_(tag)': `<O1> and <O2> are <tag>.`,
    'Bidirectional_Relation_(ftag,btag)': `<O1> <forward tag> <O2> and <O2> <backward tag> <O1>.`,
    'Exhibition-Characterization': `<O1> exhibits <O2>.`},
  'P1-P2': {
    'Overtime_exception': `<P2> occurs if <P1> lasts more than <maxtime> <units>.`,
    'Undertime_exception': `<P2> occurs if <P1> falls short of <mintime> <units>.`,
    'Aggregation-Participation': `<P1> consist of <P2>.`,
    'OvertimeUndertime-exception':
      `<P2> occurs if <P1> falls short of <mintime> <units> or lasts more than <maxtime> <units>.`,
    'Unidirectional_Relation': `<P1> relates to <P2>.`,
    'Invocation': `<P1> invokes <P2>.`,
    'Bidirectional_Relation': `<P1> and <P2> are equivalent.`,
    'Generalization-Specialization': `<P2> is <P1>.`,
    'Classification-Instantiation': `<P2> is an instance of <P1>.`,
    'Unidirectional_Relation_(tag)': `<P1> <tag> <P2>.`,
    'Bidirectional_Relation_(tag)': `<P1> and <P2> are <forward tag>.`,
    'Bidirectional_Relation_(ftag,btag)': `<P1> <forward tag> <P2> and <P2> <backward tag> <P1>.`,
    'Exhibition-Characterization': `<P1> exhibits <P2>.`},
  'P1-P1 (same process)': {
    'Invocation': `<P1> invokes itself.`},
  'O-P': {
    'Agent': `<O> handles <P>.`,
    'Agent_Condition': `<P> occurs if <O> exists, otherwise <P> is skipped.`,
    'Agent_Event': `<O> initiates and handles <P>.`,
    'Consumption': `<P> consumes <O>.`,
    'Consumption_Condition': `<P> occurs if <O> exists, in which case<P>  consumes <O>, otherwise <P>  is skipped.`,
    'Consumption_Event': `<O> initiates <P>, which consumes <O>.`,
    'Instrument': `<P> requires <O>.`,
    'Instrument_Condition': `<P> occurs if <O> exists, otherwise <P>  is skipped.`,
    'Instrument_Event': `<O> initiates <P>, which requires <O>.`,
    'Exhibition-Characterization': `<O> exhibits <P>.`,
    'Effect': `<P> affects <O>.`,
    'Effect_Event': `<O> initiates <P>, which affects <O>`,
    'Effect_Condition': `<P> occurs if <O> exists, in which case<P>  affects <O>, otherwise <P>  is skipped.`,
  },
  'P-O': {
    'Exhibition-Characterization': `<P> exhibits <O>.`,
    'Result': `<P> yields <O>.`,
    'Effect': `<P> affects <O>.`,
    'Effect_Event': `<O> initiates <P>, which affects <O>`,
    'Effect_Condition': `<P> occurs if <O> exists, in which case<P>  affects <O>, otherwise <P>  is skipped.`,
  },
  'Os-(P)-O (from object state to the same object)': {
    'Overtime_exception': `<O> triggers <P> when <O> is <s> more than <maxtime> <units>, in which case <P> changes <O>.`,
    'Condition_Input ': `<P> occurs if <O> is <s>, in which case <P> changes <O> from <s> , otherwise <P>  is skipped.`,
    'In-out_Link_Pair': `<P> changes <O> from <s>.`},
  'O1s-O2': {
    'Bidirectional_Relation_(tag)': `<s> <O1> and <O2> are <forward tag>.`,
    'Unidirectional_Relation': `<s> <O1> relates to <O2>.`,
    'Bidirectional_Relation': `<s> <O1> and <O2> are equivalent.`,
    'Unidirectional_Relation_(tag)': `<s> <O1> <tag> <O2>.`,
    'Bidirectional_Relation_(ftag,btag)': `<s> <O1>  <forward tag> <O2> and <O2> <backward tag> <s> <O1>.`,
    'Exhibition-Characterization': `<s> <O1> exhibits <O2>.`},
  'P-Os': {
    'In-out_Link_Pair': `<P> changes <O> from <s1> to <s2>.`,
    'Split_output': `<P> changes <O> to <s>.`,
    // 'Exhibition-Characterization': `<P> exhibits <s> <O>.`,
    'Result': `<P> yields <s> <O>.`},
  'O1s1-O2s2': {
    'Unidirectional_Relation': `<s1> <O1> relates to <s2> <O2>.`,
    'Bidirectional_Relation_(tag)': `<s1> <O1> and <s2> <O2> are <forward tag>.`,
    'Bidirectional_Relation_(ftag,btag)': `<s1> <O1> <forward tag> <s2><O2> and <s2><O2> <backward tag><s1> <O1>.`,
    'Bidirectional_Relation': `<s1> <O1> and <s2> <O2> are equivalent.`,
    'Unidirectional_Relation_(tag)': `<s1> <O1> <tag> <s2> <O2>.`},
  'O1-T2..n (n>=2 many destinations)': {
    'Aggregation-Participation': `<O1> consist of <T1...n-1> and <Tn>.`,
    'Unidirectional_Relation': `<O1> relates to <T1...n-1> and <Tn>.`,
    'Generalization-Specialization': `<T1...n-1> and <Tn> are <O1>.`,
    'Classification-Instantiation': `<T1...n-1> and <Tn> are instances of <O1>.`,
    'Unidirectional_Relation_(tag)': `<O1> <tag> <T1...n-1> and <Tn>.`,
    'Exhibition-Characterization': `<O1> exhibits <T1...n-1> and <Tn>.`},
  'P1-T2..n (n>=2 many destinations)': {
    'Exhibition-Characterization': `<P1> exhibits <T1...n-1> and <Tn>.`,
    'Aggregation-Participation': `<P1> consist of <T1...n-1> and <Tn>.`,
    'Unidirectional_Relation': `<P1> relates to <T1...n-1> and <Tn>.`,
    'Generalization-Specialization': `<T1...n-1> and <Tn> are <P1>.`,
    'Classification-Instantiation': `<T1...n-1> and <Tn> are instances of <P1>.`,
    'Unidirectional_Relation_(tag)': `<P1> <tag> <T1...n-1> and <Tn>.`},
  'O1s1-T2..n (n>=2 many destinations)': {
    'Exhibition-Characterization': `<s1> <O1> exhibits <T1...n-1> and <Tn>.`},
  'O1-O2s': {
    'Bidirectional_Relation_(tag)': `<O1> and <s> <O2> are <forward tag>.`,
    'Unidirectional_Relation': `<O1> relates to <s> <O2>.`,
    'Bidirectional_Relation': `<O1> and <s> <O2> are equivalent.`,
    'Unidirectional_Relation_(tag)': `<O1> <tag> <s> <O2>.`,
    'Bidirectional_Relation_(ftag,btag)': `<O1> <forward tag> <s><O2> and <s><O2> <backward tag> to  <O1>.`,
    'Exhibition-Characterization': `<O1> exhibits <s> <O2>.`},
  'Os1-(P)-Os2 (same object)': {
    'Overtime_exception':
      `<O> triggers <P> when <O> is <s1> more than <maxtime> <units>, in which case <P> changes <O> to <s2>.`,
    'Condition_Input ': `<P> occurs if <O> is <s1>, in which case <P> changes <O> from <s1> to <s2>, otherwise <P>  is skipped.`,
    'In-out_Link_Pair': `<P> changes <O> from <s1> to <s2>.`},
  'Os-P': {
    'In-out_Link_Pair': `<P> changes <O> from <s1> to <s2>.`,
    'Agent': `<s> <O> handles <P>.`,
    'Agent_Condition': `<P> occurs if <O> is <s>, otherwise <P> is skipped.`,
    'Agent_Event': `<s> <O> initiates and handles<P>.`,
    'Consumption': `<P> consumes <s> <O>.`,
    'Consumption_Condition': `<P> occurs if <O> is at state <s>, in which case <P> consumes <O>, otherwise <P> is skipped.`,
    'Consumption_Event': `<s> <O> initiates <P>, which consumes <O>.`,
    'Split_input': `<P> changes <O> from <s>.`,
    'Instrument': `<P> requires <s> <O>.`,
    'Instrument_Condition': `<P> occurs if <O> is at state <s>, otherwise <P> is skipped.`,
    'Instrument_Event': `<s> <O> initiates <P>, which requires <s> <O>.`,
    'Overtime_exception': `<O> triggers <P> when <O> is <s> more than <maxtime> <units>.`,
    'Undertime_exception': `<O> triggers <P> when <O> is <s> less than <mintime> <units>.`,
    'OvertimeUndertime-exception': `<O> triggers <P> when <O> is <s> more than <maxtime> <units> and less than <mintime> <units>.`,
    'Exhibition-Characterization': `<s> <O> exhibits <P>.`},
  'Object': {
    default_essence_affiliation: ``,
    default_essence: `<O> is <a>.`,
    default_affiliation: `<O> is <e>.`,
    non_default: `<O> is <e> and <a>.`,
    singleInzoom: `<O> zooms into <T_list>.`,
    multiInzoom: `<O> zooms into <O_list>, as well as, <P_list>.`,
    object_list_sequence: `<O1...n>, in that sequence`,
  },
  'Process': {
    default_essence_affiliation: ``,
    default_essence: `<P> is <a>.`,
    default_affiliation: `<P> is <e>.`,
    non_default: `<P> is <e> and <a>.`,
    singleInzoom: `<P> zooms into <T_list>.`,
    multiInzoom: `<P> zooms into <P_list>, as well as, <O_list>.`,
    process_list_parallel: `parallel <P1...n>`,
    process_list_sequence: `<P1...n>, in that sequence`,
  },
  'Thing':{
    singleThing: `<T>`,
    multiThing: `<T1...n-1> and <Tn>`,
  },
  'State': {
    singleState: `<O> is <s>.`,
    multiStates: `<O> can be <s1...n-1> or <sn>.`,
    singleState_exhibition: `<T_a> of <T_e> is <s>.`,
    multiStates_exhibition: `<T_a> of <T_e> can be <s1...n-1> or <sn>.`,
    exhibitor_chain: `of <T_ee>`,
    multiple_exhibitors: `<T_e1...n-1> and <T_en>`,
    Default: `<s> by default`,
    DefInitial: `initially <s> by default`,
    Initial: `initially <s>`,
    Final: `finally <s>`,
    all: `initially and finally <s> by default`,
    finInitial: `initially and finally <s>`,
    DefFinal: `finally <s> by default`,
    none: `<s>`
  },
  'Essence': {
    physical: `physical`,
    informatical: `informatical`,
  },
  'Affiliation': {
    systemic: `systemic`,
    environmental: `environmental`,
  },
  'Attribute-Exhibitors': {
    basic: `<T_a> of <T_e>`,
    exhibitor_chain: `of <T_ee>`,
    multiple_exhibitors: `<T_e1...n-1> and <T_en>`,
  }
};
export const linkTables = {'en': defaultTable_en, 'cn': defaultTable_cn};
*/


/*
 * linkTable is used for generating opl in link-choosing dialog
 * and it should be derived from current oplTemplate
*/
export let linkTable = changeLinkTable();



/*
export const linkTypes = {
  'en': {
    'Unidirectional_Relation': 'Unidirectional Relation',
    'Unidirectional_Relation_(tag)': 'Unidirectional Relation (tag)',
    'Bidirectional_Relation': 'Bidirectional Relation',
    'Bidirectional_Relation_(tag)': 'Bidirectional Relation (tag)',
    'Bidirectional_Relation_(ftag,btag)': 'Bidirectional Relation (forward tag, backward tag)',
    'Aggregation-Participation': 'Aggregation-Participation',
    'Exhibition-Characterization': 'Exhibition-Characterization',
    'Generalization-Specialization': 'Generalization-Specialization',
    'Classification-Instantiation': 'Classification-Instantiation',
    'Result': 'Result',
    'Consumption': 'Consumption',
    'Effect': 'Effect',
    'Agent': 'Agent',
    'Instrument': 'Instrument',
    'In/out_linkPair': 'In-out link pair',
    'Split_input': 'split input',
    'Split_output': 'split output',
    'Invocation': 'Invocation',
    'Overtime_exception': 'Overtime exception <maxtime, unit>',
    'Undertime_exception': 'Undertime exception<mintime,unit>',
    'UndertimeOvertimeException': 'Undertime and overtime exception <mintime..maxtime, unit>',
    'Consumption_Condition': 'Condition Consumption',
    'Effect_Condition': 'Condition Effect',
    'Condition_Input': 'Condition Input',
    'Instrument_Condition': 'Condition Instrument',
    'Agent_Condition': 'Condition Agent',
    'Consumption_Event': 'Event Consumption',
    'Effect_Event': 'Event Effect',
    'Event_Input': 'Event Input',
    'Instrument_Event': 'Event Instrument',
    'Agent_Event': 'Event Agent'
  }
};
export const legalRelations = {
  'O1-O2': [
    'Aggregation-Participation',
    'Unidirectional_Relation',
    'Bidirectional_Relation',
    'Generalization-Specialization',
    'Classification-Instantiation',
    'Unidirectional_Relation_(tag)',
    'Bidirectional_Relation_(tag)',
    'Bidirectional_Relation_(ftag,btag)',
    'Exhibition-Characterization'],
  'P1-P2': [
    'Overtime_exception',
    'Undertime_exception',
    'Aggregation-Participation',
    'OvertimeUndertime-exception',
    'Unidirectional_Relation',
    'Invocation',
    'Bidirectional_Relation',
    'Generalization-Specialization',
    'Classification-Instantiation',
    'Unidirectional_Relation_(tag)',
    'Bidirectional_Relation_(tag)',
    'Bidirectional_Relation_(ftag,btag)',
    'Exhibition-Characterization'
  ],
  'P1-P1 (same process)': [
    'Invocation'
  ],
  'O-P': [
    'Agent',
    'Agent_Condition',
    'Agent_Event',
    'Consumption',
    'Consumption_Condition',
    'Consumption_Event',
    'Instrument',
    'Instrument_Condition',
    'Instrument_Event',
    'Exhibition-Characterization',
    'Effect',
    'Effect_Event',
    'Effect_Condition',
  ],
  'P-O': [
    'Exhibition-Characterization',
    'Result',
    'Effect',
    'Effect_Event',
    'Effect_Condition',
  ],
  'Os-(P)-O (from object state to the same object)': [
    'Overtime_exception',
    'Condition_Input ',
    'In-out_Link_Pair'
  ],
  'O1s-O2': [
    'Bidirectional_Relation_(tag)',
    'Unidirectional_Relation',
    'Bidirectional_Relation',
    'Unidirectional_Relation_(tag)',
    'Bidirectional_Relation_(ftag,btag)',
    'Exhibition-Characterization'
  ],
  'P-Os': [
    'In/out_linkPair',
    'Split_output',
   // 'Exhibition-Characterization',
    'Result'
  ],
  'O1s1-O2s2': [
    'Unidirectional_Relation',
    'Bidirectional_Relation_(tag)',
    'Bidirectional_Relation_(ftag,btag)',
    'Bidirectional_Relation',
    'Unidirectional_Relation_(tag)'
  ],
  'O1-T2..n (n>=2 many destinations)': [
    'Aggregation-Participation',
    'Unidirectional_Relation',
    'Generalization-Specialization',
    'Classification-Instantiation',
    'Unidirectional_Relation_(tag)',
    'Exhibition-Characterization'
  ],
  'P1-T2..n (n>=2 many destinations)': [
    'Exhibition-Characterization',
    'Aggregation-Participation',
    'Unidirectional_Relation',
    'Generalization-Specialization',
    'Classification-Instantiation',
    'Unidirectional_Relation_(tag)'
  ],
  'O1s1-T2..n (n>=2 many destinations)': [
    'Exhibition-Characterization',
  ],
  'O1-O2s': [
    'Bidirectional_Relation_(tag)',
    'Unidirectional_Relation',
    'Bidirectional_Relation',
    'Unidirectional_Relation_(tag)',
    'Bidirectional_Relation_(ftag,btag)',
    'Exhibition-Characterization'
  ],
  'Os1-(P)-Os2 (same object)': [
    'Overtime_exception',
    'Condition_Input ',
    'In-out_Link_Pair'
  ],
  'Os-P': [
    'In/out_linkPair',
    'Agent',
    'Agent_Condition',
    'Agent_Event',
    'Consumption',
    'Consumption_Condition',
    'Consumption_Event',
    'Split_input',
    'Instrument',
    'Instrument_Condition',
    'Instrument_Event',
    'Overtime_exception',
    'Undertime_exception',
    'OvertimeUndertime-exception',
    'Exhibition-Characterization',
  ]
}
*/

/*
export let userOplSettings = {language: Languages[0], displayOpt: DisplayOpt[1]};
export let orgOplSettings = {affiliation: Affiliations[0], essence: Essences[0], oplTables: OplTables};
*/

export function updateTemplates() {
  if (Object.keys(oplDefaultSettings.oplTables).indexOf(oplDefaultSettings.language) < 0) {
    return;
  }
  oplTemplates = oplDefaultSettings.oplTables[oplDefaultSettings.language];
  complete_from_default(oplTemplates);
  // the link table is derived from oplTemplates
  linkTable = changeLinkTable();
  // linkTable = linkTables[oplDefaultSettings.language];
}

export function complete_from_default(oplTemplates, default_table = oplTemplates_en) {
  for (const key of Object.keys(default_table)) {
    if (!oplTemplates.hasOwnProperty(key) || (typeof oplTemplates[key]) !== (typeof default_table[key])) {
      oplTemplates[key] = default_table[key];
      continue;
    }
    if ((typeof default_table[key]) === 'object') {
      complete_from_default(oplTemplates[key], default_table[key]);
    }
  }
}

// Defaults

/* export const oplOrgDefaultSettings = {
  'affiliation': Affiliations[0],
  'essence': Essences[0],
  'language': Languages[0],
  'displayOpt': DisplayOpt[1],
  'oplTables': OplTables,
  'SDNames': false
}; */

// export const defaultLinkTable =  linkTables[oplOrgDefaultSettings.language];;

// export const oplUserDefaultSettings = { language: Languages[0], displayOpt: DisplayOpt[1], 'SDNames': false, 'essence': Essences[0] };

export const defaultSettings = {
  user: {
    language: Languages[0],
    displayOpt: DisplayOpt[2],
    unitsOpt: UnitsOpt[1],
    aliasOpt: AliasOpt[0],
    'SDNames': false,
    'opdTreeProcessesAutoArrangement': true,
    'essence': Essences[0],
    'oplNumbering': true,
    'autoFormat': true,
    logSharingPermission: false,
    spellCheck: spellCHeck[0],
    multiDeletion: false,
    Notes: true,
    'highlightOpl': false,
    'highlightOpd' : true,
    markThings: true,
    syncOplcolorsFromOpd : true,
    timeDurationUnitsDigits: 2,
    numericComputationalDigits: 2,
    tutorialMode: true,
    dragSearchAuto: true,
    haloDefaultMode: false,
    navigatorEnabled: true,
    chatEnabled: true,
    pythonExecution: 'local',
    codeEditorTheme: undefined,
    loadScreenViewType: undefined,
    loadScreenSortBy: 'date',
    loadScreenSortDirections: undefined,
    connection: {
      ros: {
        server: 'localhost',
        port: '3000'
      },
      mqtt: {
        server: 'localhost',
        port: '9883'
      },
      python: {
        server: 'localhost',
        port: '8765'
      },
      mysql: {
        hostname: 'localhost',
        port: '3306',
        username: 'root',
        password: '1234',
        schema: 'schema',
        ws_hostname: 'localhost',
        ws_port: '5566'
      },
      graphdb: {
        graphdb_api: 'bolt://localhost:7687',
        username: 'neo4j',
        password: 'opcloudNeo4j',
      },
      calculationsServer: {
        computingServerURL: 'https://localhost:3000',
        computingServerCalculations: true
      },
      allow_users: true,
    },
    bringConnectedSettings: {
      proceduralEnablers: true,
      proceduralTransformers: true,
      fundamentals: false,
      tagged: false,
    },
    gridSettings: {
      state: false,
      color: '#8c8c8c',
      thickness: 1,
      scaleFactor: 35,
      gridSize: 5
    },
  },
  organization: {
    'affiliation': Affiliations[0],
    'essence': Essences[0],
    'language': Languages[0],
    'displayOpt': DisplayOpt[2],
    'unitsOpt': UnitsOpt[1],
    'aliasOpt': AliasOpt[0],
    'oplNumbering': true,
    'autoFormat': true,
    'oplTables': OplTables,
    'SDNames': true,
    dragSearchAuto: true,
    'opdTreeProcessesAutoArrangement': true,
    // chat is enabled by default for all orgs
    'chatEnabled': true,
    'logCollectingEnabled': false,
    'ignoreUserLogSharingPermission': false,
    displayNotes: true,
    tutorialMode: true,
    ontologyEnforcementLevel: OntologyEnforcementLevel.SUGGEST,
    ontology: [],
    auth2Factors: 'disabled',
    multiDeletion: false,
    haloDefaultMode: false,
    bringConnectedSettings: {
      proceduralEnablers: true,
      proceduralTransformers: true,
      fundamentals: false,
      tagged: false,
    },
    gridSettings: {
      state: false,
      color: '#8c8c8c',
      thickness: 1,
      scaleFactor: 35,
      gridSize: 5
    },
  }
};

export const edxDefaultSettings = {
  user: {
    language: Languages[0],
    displayOpt: DisplayOpt[0],
    unitsOpt: UnitsOpt[1],
    aliasOpt: AliasOpt[0],
    'SDNames': false,
    'essence': Essences[1],
    'oplNumbering': true,
    'autoFormat': true,
    dragSearchAuto: true,
    haloDefaultMode: false,
    logSharingPermission: false,
    spellCheck: spellCHeck[1],
    navigatorEnabled: true,
    Notes: true,
    'highlightOpl': false,
    'highlightOpd' : true,
    bringConnectedSettings: {
      proceduralEnablers: true,
      proceduralTransformers: true,
      fundamentals: false,
      tagged: false,
    },
    gridSettings: {
      state: false,
      color: '#8c8c8c',
      thickness: 1,
      scaleFactor: 35,
      gridSize: 5
    },
  }
  ,
  organization: {
    'affiliation': Affiliations[0],
    'essence': Essences[1],
    'language': Languages[0],
    'oplNumbering': true,
    'autoFormat': true,
    'displayOpt': DisplayOpt[0],
    'unitsOpt': UnitsOpt[1],
    'aliasOpt': AliasOpt[0],
    'oplTables': OplTables,
    'SDNames': false,
    'chatEnabled': false,
    dragSearchAuto: true,
    displayNotes: true,
    ontologyEnforcementLevel: OntologyEnforcementLevel.NONE,
    ontology: [],
    gridSettings: {
      state: false,
      color: '#8c8c8c',
      thickness: 1,
      scaleFactor: 35,
      gridSize: 5
    },
  }
};
