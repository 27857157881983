import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ReceivedModelFromServer, WriteModelSchema} from "../rappid-components/services/storage/model-object.class";
import {environment} from "../../environments/environment";
import {
  DisplayStereotype,
  Stereotype,
  WriteStereotypeSchema
} from '../dialogs/stereotypes-dialog/StereotypesRelatedInterface';
import {
  DirectoryData,
  DisplayFolder,
  DisplayModel
} from "../rappid-components/services/storage/model-storage.interface";
import {RenameServerResponse} from '../rappid-components/services/server-response/rename-response';
import {RemoveModelServerResponse} from '../rappid-components/services/server-response/removeModelServerResponse';
import {
  DisplayChat,
  getChatAction,
  getChatResponse,
  postChatResponse
} from "../rappid-components/services/storage/chat/chat-storage.interface";
import {ErrorLog} from '../error-handler/error-log.interface';
import {FolderPermissions} from "../dialogs/folder-permissions-dialog/folder-permissions-dialog/folder-permissions-dialog.component";
import {OntologyEnforcementLevel, OntologyItem} from "../modules/Settings/OrgOntology/ontologyInterfaces";
import {ImagesPoolItem, ImagesPoolType} from "../models/VisualPart/backgroundImageEnum";
import {DatabaseDriver} from "./databaseDriverInterface";
import {OPCloudUtils} from "../configuration/rappidEnviromentFunctionality/shared";
import {
  CreatePopupMessage, PopupMessageData,
  PopupMessageItem,
  UpdatePopupMessage
} from "../dialogs/create-popup-message/popup.message.interface";
import {NewOrgUserDefaults} from "../modules/Settings/OPCloud-Organization-Settings/newOrgUserDefaultsInterface";
const pako = require('pako');


@Injectable()
export class ServerDatabaseDrive implements DatabaseDriver {

  constructor(private http: HttpClient) {
  }

  private target = environment.apiTarget;
  // private target = "http://localhost:3000";

  /////// STORAGE ///////

  updateOrganizationsSSOMap(data: Array<any>): Promise<any> {
    return this.http.post<void>(this.target + '/ssoMap', { data: data }).toPromise();
  }

  getOrganizationsSSOMap(): Promise<Array<any>> {
    return this.http.get<Array<any>>(this.target + '/ssoMap').toPromise();
  }

  getOrganizationsSSORequests(): Promise<Array<any>> {
    return this.http.get<Array<any>>(this.target + '/ssoRequests').toPromise();
  }

  updateSSORequests(requests: Array<{ orgAddress: string; orgId: string }>): Promise<any> {
    return this.http.put<void>(this.target + '/ssoRequests', { requests }).toPromise();
  }

  public getStereotype(id: string): Promise<Stereotype> {
    const params = new HttpParams().set('id', id);
    return this.http.get<Stereotype>(this.target + '/storage/stereotype', { params: params }).toPromise();
    // return Promise.resolve({...exampleStereotype, id: id, type: StereotypeType.Organization });
  }

  public getAllStereotypes(): Promise<Array<DisplayStereotype>> {
    return this.http.get<Array<DisplayStereotype>>(this.target + '/storage/stereotypes').toPromise();
  }

  getFavoriteTemplates(): Promise<Array<DisplayModel>> {
    return this.http.get<Array<DisplayModel>>(this.target + '/storage/templates/favorite').toPromise();
}

  public getFavoriteExamples(): Promise<Array<DisplayModel>> {
    return this.http.get<Array<DisplayModel>>(this.target + '/storage/examples/favorite').toPromise();
  }

  public getFavoriteStereotypes(): Promise<Array<string>> {
    return this.http.get<Array<string>>(this.target + '/storage/stereotype/favorites').toPromise();
  }

  getTokenForSSO(idToken: string, type: 'microsoft' | 'google'): Promise<{ token: any }> {
    const params = new HttpParams().set('idToken', idToken).set('sso', 'sso').set('type', type);
    return this.http.get<{token: any}>(this.target + '/sso/' + idToken, { params: params }).toPromise();
  }

  public async saveStereotype(stereotype: WriteStereotypeSchema): Promise<void> {
    return this.http.post<void>(this.target + '/storage/stereotype', { stereotype: stereotype }).toPromise();
  }

  public deleteStereotype(stereotype_id: string): Promise<void> {
    const params = new HttpParams().set('id', stereotype_id);
    return this.http.delete<void>(this.target + '/storage/stereotype', { params: params }).toPromise();
  }

  public setFavoriteStereotype(id: string): Promise<void> {
    return this.http.post<void>(this.target + '/storage/stereotype/favorite/' + id, {}).toPromise();
  }

  public unsetFavoriteStereotype(id: string): Promise<void> {
    return this.http.delete<void>(this.target + '/storage/stereotype/favorite/' + id).toPromise();
  }

  public setFavoriteExample(modelData: DisplayModel, exampleType: 'SYS' | 'ORG'): Promise<void> {
    return this.http.put<void>(this.target + '/storage/examples/favorite/', { model: modelData, exampleType }).toPromise();
  }

  public setFavoriteTemplate(modelData: DisplayModel, templateType: 'SYS' | 'ORG' | 'PERSONAL'): Promise<void> {
    return this.http.put<void>(this.target + '/storage/templates/favorite/', { model: modelData, templateType }).toPromise();
  }

  public unsetFavoriteExample(modelData: DisplayModel): Promise<void> {
    return this.http.delete<void>(this.target + '/storage/examples/favorite/' + modelData.id).toPromise();
  }

  public unsetFavoriteTemplate(modelData: DisplayModel): Promise<void> {
    return this.http.delete<void>(this.target + '/storage/templates/favorite/' + modelData.id).toPromise();
  }

  public saveImageToPool(poolType: string, url: string, imageTags: Array<string>): Promise<{ success: boolean; message?: string }> {
    return this.http.put<{ success: boolean; message?: string }>(this.target + '/storage/imagesPool/add', { poolType: poolType, url: url, imageTags: imageTags }).toPromise();
  }

  public getImagesPool(type: string): Promise<Array<ImagesPoolItem>> {
    const params = new HttpParams().set('type', type);
    return this.http.get<any>(this.target + '/storage/imagesPool', { params: params }).toPromise() as any;
  }

  public updatePoolImageTags(id: string, imageTags: string[], poolType: string): Promise<void> {
    return this.http.post<void>(this.target + '/storage/imagesPool/updateTags', { id, imageTags, poolType }).toPromise();
  }

  public deletePoolImage(id: string, poolType: string): Promise<void> {
    const params = new HttpParams().set('id', id).set('poolType', poolType);
    return this.http.delete<void>(this.target + '/storage/imagesPool', { params }).toPromise();
  }

  public saveModelAsSystemExample(model_id: string, model: WriteModelSchema): Promise<void> {
    return this.http.put<void>(this.target + '/storage/examples', { model_id: model_id, model: model }).toPromise();
  }

  public async getMainModel(model_id: string, isSysExamples: boolean, isGlobalTemplates: boolean): Promise<ReceivedModelFromServer> {
    const params = new HttpParams().set('model_id', model_id).set('sysExamples', String(!!isSysExamples)).set('globalTemplate', String(!!isGlobalTemplates));
    return this.http.get<ReceivedModelFromServer>(this.target + '/storage/model', { params: params }).toPromise();
  }

  public async getAutosaveModel(model_id: string): Promise<ReceivedModelFromServer> {
    const params = new HttpParams().set('model_id', model_id);
    return this.http.get<ReceivedModelFromServer>(this.target + '/storage/automodel', { params: params }).toPromise();
  }

  public async getModelByPath(title: string, path: string): Promise<ReceivedModelFromServer> {
    const params = new HttpParams().set('title', title).set('path', path);
    return this.http.get<ReceivedModelFromServer>(this.target + '/storage/modelByPath', { params: params }).toPromise();
  }

  public async getModels(directory_id: string, archiveMode: boolean, sysExamples: boolean, globalTemplates: boolean): Promise<Array<DisplayModel>> {
    const params = new HttpParams().set('directory_id', directory_id).set('archive', String(archiveMode)).set('sysExamples', String(sysExamples))
      .set('globalTemplate', String(globalTemplates));
    return this.http.get<Array<DisplayModel>>(this.target + '/storage/models', { params: params }).toPromise();
  }

  public async getModelImage(model_id: string, isSysExample: boolean, isGlobalTemplates: boolean): Promise<string> {
    const params = new HttpParams().set('model_id', model_id).set('sysExamples', String(!!isSysExample)).set('globalTemplate', String(!!isGlobalTemplates));
    return this.http.get<string>(this.target + '/storage/modelImage', { params: params }).toPromise();
  }

  getAllModelsUserCanLoad(isGlobalTemplates = false): Promise<Array<DisplayModel>> {
    const params = new HttpParams().set('globalTemplate', String(!!isGlobalTemplates));
    return this.http.get<Array<DisplayModel>>(this.target + '/storage/allModels', { params: params }).toPromise();
  }

  public async getLastModels(): Promise<Array<DisplayModel>> {
    return this.http.get<Array<DisplayModel>>(this.target + '/storage/lastModels').toPromise();
  }

  public async autosaveModel(model_id: string, model: WriteModelSchema): Promise<void> {
    const compressed = pako.gzip(JSON.stringify(model)).toString();
    return this.http.put<void>(this.target + '/storage/autosave', { model_id: model_id, model: compressed }).toPromise()
      .catch(async err => {
        await OPCloudUtils.waitXms(5000); // in case of expired token, try again after the token has refreshed.
        return this.http.put<void>(this.target + '/storage/autosave', { model_id: model_id, model: compressed }).toPromise();
      });
  }

/**
 * API Request to the server to get chat messages
 *
 * @param {string} model_id The id of the context model.
 * @param {getChatAction} action which messages to get (all, unread).
 * @return {Promise<getChatResponse>} promise with the response.
 */
  public async getChatMessages(model_id: string, action: getChatAction): Promise<getChatResponse> {
    const params = new HttpParams().set('model_id', model_id).set('action', action);
    return this.http.get<getChatResponse>(this.target + '/storage/chatMessage', { params: params }).toPromise();
  }

/**
 * API Request to the server to send chat message
 *
 * @param {DisplayChat} message The message to send.
 * @return {Promise<getChatResponse>} promise with the response.
 */
  public async pushChatMessage(message: DisplayChat): Promise<postChatResponse> {
    return this.http.post<postChatResponse>(this.target + '/storage/chatMessage', { message: message }).toPromise();
  }

  public async removeChatMessage(msg_id: string, model_id: string): Promise<void> {
    const params = new HttpParams().set('msg_id', msg_id).set('model_id', model_id);
    return this.http.delete<void>(this.target + '/storage/chatMessage', { params }).toPromise();
  }

  public async createModel(directory_id: string, model: WriteModelSchema): Promise<{ created_id: string }> {
    const compressed = pako.gzip(JSON.stringify(model)).toString();
    return this.http.post<{ created_id: string }>(this.target + '/storage/model', { directory_id: directory_id, model: compressed }).toPromise();
  }

  public async overrideModel(model_id: string, model: WriteModelSchema): Promise<{ id: string }> {
    const compressed = pako.gzip(JSON.stringify(model)).toString();
    return this.http.put<{ id: string }>(this.target + '/storage/model', { model_id: model_id, model: compressed }).toPromise();
  }

  public async archiveModel(model_id: string, archiveMode: boolean): Promise<any> {
    return this.http.put<void>(this.target + '/storage/archive', { model_id: model_id, archiveMode: archiveMode }).toPromise();
  }

  public async getPermissions(model_id: string): Promise<any> {
    const params = new HttpParams().set('model_id', model_id);
    return this.http.get<any>(this.target + '/storage/permissions', { params: params }).toPromise();
  }

  public async updatePermissions(model_id: string, permissions: any): Promise<void> {
    return this.http.put<any>(this.target + '/storage/permissions', { model_id: model_id, permissions: permissions }).toPromise();
  }

  public async updateFolderPermissions(folderId: string, permissions: FolderPermissions): Promise<{success: boolean, message?: string}> {
    return this.http.put<any>(this.target + '/storage/folders/permissions', { folderId: folderId, permissions: permissions }).toPromise();
  }

  public async getFolderPermissions(folderId: string): Promise<FolderPermissions> {
    const params = new HttpParams().set('folderId', folderId);
    return this.http.get<any>(this.target + '/storage/folders/permissions', { params: params }).toPromise();
  }

  public async moveFolder(sourceId: string, targetId: string, sysExamples: boolean, globalTemplate: boolean): Promise<{success: boolean, message?: string}> {
    return this.http.put<any>(this.target + '/storage/directory/move', { sourceId, targetId, sysExamples, globalTemplate }).toPromise();
  }

  public async moveModel(modelId: string, dir_id: string, sysExamples: boolean, globalTemplate: boolean): Promise<{success: boolean, message?: string}> {
    return this.http.put<any>(this.target + '/storage/model/move', { modelId, dir_id, sysExamples, globalTemplate }).toPromise();
  }

  public async getVersions(model_id: string): Promise<any> {
    const params = new HttpParams().set('model_id', model_id);
    return this.http.get<any>(this.target + '/storage/versions', { params }).toPromise();
  }

  public async getVersionModel(model_id: string, ver_index: string): Promise<any> {
    const params = new HttpParams().set('model_id', model_id).set('ver_index', ver_index);
    return this.http.get<any>(this.target + '/storage/version', { params }).toPromise();
  }

  public async renameModel(id: string, title: string, sysExamples: boolean, globalTemplates: boolean): Promise<RenameServerResponse> {
    return this.http.put<RenameServerResponse>(this.target + '/storage/model/title', { model_id: id, title: title, sysExamples: sysExamples, globalTemplate: globalTemplates }).toPromise();
  }



  public async renameFolder(id: string, title: string, sysExamples: boolean, globalTemplates: boolean): Promise<RenameServerResponse> {
    return this.http.put<RenameServerResponse>(this.target + '/storage/directory/title', { directory_id: id, title: title, sysExamples: sysExamples, globalTemplate: globalTemplates }).toPromise();
  }

  public async getFolders(directory_id: string, sysExamples: boolean, globalTemplates: boolean): Promise<Array<DisplayFolder>> {
    const params = new HttpParams().set('directory_id', directory_id).set('sysExamples', String(sysExamples)).set('globalTemplate', String(globalTemplates));
    return this.http.get<Array<DisplayFolder>>(this.target + '/storage/directory', { params: params }).toPromise();
  }

  public async getAllFolders(globalTemplates = false): Promise<Array<DirectoryData>> {
    const params = new HttpParams().set('globalTemplate', String(globalTemplates));
    return this.http.get<Array<DirectoryData>>(this.target + '/storage/directories', { params: params }).toPromise();
  }

  public async createFolder(father_id: string, title: string, sysExamples?: boolean, orgExample?: boolean, globalTemplates?: boolean, orgTemplate?: boolean): Promise<{ret:{ success:boolean, message?: string, created_id?: string }}> {
    return this.http.post<{ret:{ success:boolean, message?: string, created_id?: string }}>(this.target + '/storage/directory', {
      'father_id': father_id, 'title': title, sysExamples: sysExamples, orgExample: orgExample, globalTemplate: globalTemplates, orgTemplate: orgTemplate
    }).toPromise();
  }

  public async removeModel(model_id: string, sysExamples: boolean, globalTemplates: boolean): Promise<RemoveModelServerResponse> {
    const params = new HttpParams().set('model_id', model_id).set('sysExamples', String(!!sysExamples)).set('globalTemplate', String(!!globalTemplates));
    return this.http.delete<RemoveModelServerResponse>(this.target + '/storage/model', { params }).toPromise();
  }

  public async removeFolder(fid: string, sysExamples: boolean, globalTemplates: boolean): Promise<RemoveModelServerResponse> {
    const params = new HttpParams().set('dir_id', fid).set('sysExamples', String(!!sysExamples)).set('globalTemplate', String(!!globalTemplates));
    return this.http.delete<RemoveModelServerResponse>(this.target + '/storage/directories', { params }).toPromise();
  }

  /* public async deleteFolder(organization: string, path: string, name: string): Promise<void> {
    const params = new HttpParams().set('path', path).set('name', name);
    return this.http.delete<void>(this.target + '/storage/folder', {params: params}).toPromise();
  } */

  /////////////////////// USER ///////////////////////////

  public async findUser(userDetails): Promise<any> {
    const uid = userDetails.uid;
    return this.http.get<Array<string>>(this.target + '/user/getUser/' + uid).toPromise();
  }

  public async updateUser(useruid: string, organization: string, user: any): Promise<boolean> {
    return this.http.post<boolean>(this.target + '/user/details/' + useruid, { user: user }).toPromise();
  }

  public async updateOrgName(oldName: string, newName: string): Promise<void> {
    return this.http.post<void>(this.target + '/organization/name', { oldName, newName }).toPromise();
  }

  public async deleteOrganization(orgName: string): Promise<void> {
    const params = new HttpParams().set('orgName', orgName);
    return this.http.delete<void>(this.target + '/organization/delete', { params }).toPromise();
  }

  public async createUser(user: any): Promise<{ success: boolean, message: string }> {
    if (user.hasOwnProperty('viewer')) {
      user.isViewerAccount = user.viewer;
    }
    return this.http.put<{ success: boolean, message: string }>(this.target + '/createUser', { user: user }).toPromise();
  }

  deleteUserCanBeAutoTrasfered(uid: string): Promise<{ auto: boolean }> {
    const http_params = new HttpParams().set('remove_id', uid);
    return this.http.get<{ auto: boolean }>(this.target + '/organization/user/delete/transfred', { params: http_params }).toPromise();
  }

  public async deleteUser(uid: string, params: { action: 'auto' | 'remove' | 'transfer', transfer_to?: string }): Promise<void> {
    return this.http.post<void>(this.target + '/organization/user/delete', { remove_id: uid, ...params }).toPromise();
  }

  resetPasswordForNotLoggedUser(email: string): Promise<string> {
    const http_params = new HttpParams().set('email', email);
    return this.http.get<string>(this.target + '/resetUserPassword', { params: http_params }).toPromise();
  }

  // deleteUser(useruid: string, org: string): Promise<void> {

  // }

  /////////////////Organization///////////

  public async createOrganization(orgniazation): Promise<void> {
    return this.http.post<void>(this.target + '/organization/create', { data: orgniazation }).toPromise();
  }

  public async getOrganizations(): Promise<Array<any>> {
    return this.http.get<Array<string>>(this.target + '/organization/all').toPromise();
  }

  public async getAllOrganizations(): Promise<Array<any>> {
    return this.getOrganizations();
  }

  public async activateOrDeactivateOrg(org: string, activeFlag: boolean): Promise<void> {
    return this.http.post<void>(this.target + '/organization/activateOrDeactivate', { activeFlag: activeFlag, org: org }).toPromise();
  }

  public async SDNamesUpdateForOrg(org: string, SDNames: boolean): Promise<void> {
    return this.http.post<void>(this.target + '/organization/settings/' + org, { settings: { SDNames: SDNames } }).toPromise();
  }

  public async getOrgSDNames(org: string): Promise<boolean> {
    return this.getOrganization(org).then(orgData => {
      return orgData.SDNames;
    });
  }

  public async getOrganizationGroups(organization: string): Promise<Array<any>> {
    const params = new HttpParams().set('organization', organization);
    return this.http.get<Array<any>>(this.target + '/organization/groups', { params }).toPromise();
  }

  public async deleteGroupg(org: string, group: string, parent: string, parentName): Promise<void> {
    const params = new HttpParams().set('organization', org).set('group', group);
    return this.http.delete<void>(this.target + '/organization/group', { params }).toPromise();
  }

  public async updateGroupName(org: string, groupID: string, newName: string): Promise<void> {
    return this.http.post<void>(this.target + '/organization/group/updateName', { org, groupID, newName }).toPromise();
  }

  public async updateGroupDescription(org: string, groupID: string, NewDescription: string): Promise<void> {
    return this.http.post<void>(this.target + '/organization/group/updateDescription', { org, groupID, description: NewDescription }).toPromise();
  }

  public async addUserToAdminGroup(org: string, groupID: string, user: any): Promise<void> {
    return this.http.post<void>(this.target + '/organization/group/addAdmin', { org, groupID, userId: user.uid }).toPromise();
  }

  public async delUserFromGroup(org: string, groupID: string, user: any): Promise<void> {
    const params = new HttpParams().set('organization', org).set('groupId', groupID).set('userId', user.uid);
    return this.http.delete<void>(this.target + '/organization/group/user', { params }).toPromise();
  }

  public async delAdminGroupUser(org: string, groupID: string, user: any): Promise<void> {
    const params = new HttpParams().set('organization', org).set('groupId', groupID).set('userId', user.uid);
    return this.http.delete<void>(this.target + '/organization/group/admin', { params }).toPromise();
  }

  public async deleteSubgroup(org: string, subgroup: string, parentID: string, parentName: string): Promise<void> {
    const params = new HttpParams().set('organization', org).set('subgroupId', subgroup).set('parentId', parentID).set('parentName', parentName);
    return this.http.delete<void>(this.target + '/organization/group/subgroup', { params }).toPromise();
  }

  public async addSubgroup(org: string, parentID: string, subgroup: string, parentName: string): Promise<void> {
    return this.http.post<void>(this.target + '/organization/group/subgroup', { org, parentID, subgroup, parentName }).toPromise();
  }

  public async addGroup(org: string, nameOfGroup: string, descriptionOfGroup: string, parent: string, parentName: string): Promise<string> {
    return this.http.post<string>(this.target + '/organization/group/add', { org, nameOfGroup, description: descriptionOfGroup, parent, parentName }).toPromise();
  }

  public async addMember(user: any, groupID: string, org: string): Promise<void> {
    return this.http.post<void>(this.target + '/organization/group/addMembers', { org, groupId: groupID, usersArray: [user.uid] }).toPromise();
  }

  public async addMultipleMembers(usersArray: Array<string>, groupId: string, org: string): Promise<void> {
    return this.http.post<void>(this.target + '/organization/group/addMembers', { org, groupId, usersArray }).toPromise();
  }

  /**
 * API Request to the server to update chatEnabled state
 *
 * @param {string} org The org to update.
 * @param {boolean} chatEnabled The new state of chatEnabled.
 * @return {Promise<void>}
 */
  public async chatEnabledUpdateForOrg(org: string, chatEnabled: boolean): Promise<void> {
    return this.http.post<void>(this.target + '/organization/settings/' + org, { settings: { chatEnabled: chatEnabled } }).toPromise();
  }

/**
 * API Request to the server to get chatEnabled state
 *
 * @param {string} org The org to retrieve.
 * @param {boolean} chatEnabled The new state of chatEnabled.
 * @return {Promise<boolean>} boolean value for is chatEnabled on this org.
 */

  public async getOrganizationUsers(organization: string): Promise<Array<any>> {
    return this.http.get<Array<string>>(this.target + '/organization/users/' + organization).toPromise();
  }

  public async getOrganization(organizationName): Promise<any> {
    return this.http.get<Array<string>>(this.target + '/organization/settings/' + organizationName).toPromise();
  }

  public getOrganizationUsersData(organization: string): Promise<Array<string>> {
    return this.getOrganizationUsers(organization);
  }

  public getGroupFromOrg(organization: string, groupID: string): Promise<any> {
    return new Promise<any>(resolve => {
      this.getOrganizationGroups(organization).then(groups => {
        groups.forEach(group => {
          if (group.GroupID === groupID) {
            resolve(group);
          }
        });
      });
    });
  }

  public async updateOrganization(organizationName, organization): Promise<boolean> {
    return this.http.post<boolean>(this.target + '/organization/settings/' + organizationName, { settings: organization }).toPromise();
  }

  public async isActiveOrg(org: string): Promise<boolean> {
    return this.getAllOrganizations().then(allOrgs => allOrgs.find(item => item.name === org)?.flag);
  }

  public async sendMail(emails: Array<string>, subject: string, message: string): Promise<void> {
    return this.http.post<void>(this.target + '/mail/send', { to: emails, subject: subject, message: message }).toPromise();
  }

  public async addLog(log:ErrorLog) {
    return this.http.post<void>(this.target + '/logs', log).toPromise();
  }

  public async getLogs(limit:number, offset: number, user?: string): Promise<ErrorLog[]>{
    // console.log("getting logs")
    const optionalUserFilter = user ? `&userId=${user}` : ''
    return this.http.get<ErrorLog[]>(this.target + '/logs' + `?limit=${limit}&offset=${offset}` + optionalUserFilter).toPromise();
  }

  public async removeLogById(logId): Promise<boolean> {
    return this.http.delete<boolean>(this.target + '/logs' + `?logId=${logId}`).toPromise();
  }

  public async updateLogCollectionFlagForOrg(org: string, flag: boolean): Promise<void> {
    return this.http.post<void>(this.target + '/organization/settings/' + org, { settings: { logCollectingEnabled: flag } }).toPromise();
  }

  public async tutorialModeUpdateForOrg(org: string, value: boolean): Promise<void> {
    return this.http.post<void>(this.target + '/organization/settings/' + org, { settings: { tutorialMode: value } }).toPromise();
  }

  public async maxUsersNumberUpdateForOrg(org: string, value: boolean): Promise<void> {
    return this.http.post<void>(this.target + '/organization/settings/' + org, { settings: { maxUsersNumber: value } }).toPromise();
  }

  public async maxViewUsersNumberUpdateForOrg(org: string, value: boolean): Promise<void> {
    return this.http.post<void>(this.target + '/organization/settings/' + org, { settings: { maxViewUsersNumber: value } }).toPromise();
  }

  public async updateOrgOntology(ontology: Array<OntologyItem>): Promise<void> {
    return this.http.put<any>(this.target + '/organization/ontology', { ontology: ontology }).toPromise();
  }

  public async getOrgOntology(): Promise<Array<OntologyItem>> {
    return this.http.get<any>(this.target + '/organization/ontology', {}).toPromise();
  }

  public async updateOrgOntologyEnforcementLevel(org: string, ontologyEnforcementLevel: OntologyEnforcementLevel): Promise<void> {
    return this.http.post<void>(this.target + '/organization/settings/' + org, { settings: { ontologyEnforcementLevel: ontologyEnforcementLevel } }).toPromise();
  }

  public async changeUserInitialPassword(uid: string): Promise<void> {
    return this.http.post<void>(this.target + '/user/changeInitialPassword', { user_id: uid } ).toPromise();
  }

  public async signInWithEmailAndPassword(email: string, password: string, verificationCode: string = null): Promise<any> {
    return this.http.post<any>(this.target + '/guest/login', { user: { email, password, verificationCode } }).toPromise();
  }

  public async resend2FAVerificationCode(email: string): Promise<void> {
    return this.http.post<any>(this.target + '/guest/resend2FAVerificationCode', { email }).toPromise();
  }

  public async getOrganizationAnalytics(org: string): Promise<any> {
    const params = new HttpParams().set('organization', org);
    return this.http.get<any>(this.target + '/organization/analytics', { params }).toPromise();
  }

  public async maxUsersEnabledUpdateForOrg(org: string, value: boolean): Promise<void> {
    return this.http.post<void>(this.target + '/organization/settings/' + org, { settings: { maxUsersEnabled: value } }).toPromise();
  }

  public async updateIgnoreUserLogSharingPermissionFlagForOrg(org: string, flag: boolean): Promise<void> {
    return this.http.post<void>(this.target + '/organization/settings/' + org, { settings: { ignoreUserLogSharingPermission: flag } }).toPromise();
  }
  /*
  public async getIgnoreUserLogSharingPermissionFlagForOrg(org: string) {
    return new Promise<boolean>(true);
    // return this.http.get<any>(this.target + '/organization/settings/' + org).pipe(res => res["ignoreUserLogSharingPermission"] == 'true').toPromise();

  }
  */
  public updateUserOplSettings(settings: any): Promise<void> {
    return this.http.post<void>(this.target + '/user/settings', { settings: settings }).toPromise();
  }

  public addUsers(users: Array<string>): Promise<{ success: boolean, errors: Array<string> }> {
    return this.http.put<{ success: boolean, errors: Array<string> }>(this.target + '/organization/addUsers', { users }).toPromise();
  }

  public validateUsers(users: Array<string>): Promise<{ success: boolean, errors: Array<string> }> {
    return this.http.post<{ success: boolean, errors: Array<string> }>(this.target + '/organization/validateUsers', { users }).toPromise();
  }

  updateUserLastTabsToDB(relevantTabs: Array<string>): Promise<void> {
    return this.http.post<void>(this.target + '/user/lastTabs', { tabs: relevantTabs }).toPromise();
  }

  getUserLastTabsFromDB(): Promise<Array<string>> {
    return this.http.get<Array<string>>(this.target + '/user/lastTabs').toPromise();
  }

  downloadModel(modelId: string, isSysExample: boolean, globalTemplates: boolean): Promise<any> {
    const http_params = new HttpParams().set('modelId', modelId).set('sysExamples', String(isSysExample)).set('globalTemplates', String(globalTemplates));
    return this.http.get<any>(this.target + '/storage/downloadModel', { params: http_params }).toPromise();
  }

  sendUserPing(uid: string): Promise<void> {
    const http_params = new HttpParams().set('uid', uid);
    return this.http.post<any>(this.target + '/organization/userPing', { params: http_params }).toPromise();
  }

  canLoginDueToMaxUsersNumber(organization: string): Promise<boolean> {
    const http_params = new HttpParams().set('organization', organization);
    return this.http.get<any>(this.target + '/organization/checkIfMaxUsersReached', { params: http_params }).toPromise();
  }

  validateGoogleToken(user): Promise<any> {
    const http_params = new HttpParams().set('tokenUser', user);
    return this.http.get<any>(this.target + '/checkGoogleToken', { params: http_params }).toPromise();
  }

  createPopupMessage(type: 'system' | 'org', message: CreatePopupMessage): Promise<void> {
    const route = type === 'org' ? '/organization' : '';
    return this.http.post<any>(this.target + route + '/popupMessage', { message }).toPromise();
  }

  updatePopupMessage(type: 'system' | 'org', message: UpdatePopupMessage): Promise<void> {
    const route = type === 'org' ? '/organization' : '';
    return this.http.put<any>(this.target + route + '/popupMessage', { message }).toPromise();
  }

  removePopupMessage(type: 'org' | 'system', id: string): Promise<void> {
    const params = new HttpParams().set('id', id);
    const route = type === 'org' ? '/organization' : '';
    return this.http.delete<any>(this.target + route + '/popupMessage', { params }).toPromise();
  }

  getAllPopupMessages(type: "org" | "system"): Promise<Array<PopupMessageItem>> {
    const route = type === 'org' ? '/organization' : '';
    return this.http.get<any>(this.target + route + '/popupMessages').toPromise();
  }

  markPopupMessageAsSeen(type: "org" | "system", id: string): Promise<void> {
    const route = type === 'org' ? '/organization' : '';
    return this.http.post<void>(this.target + route + '/popupMessages/seen', { messageId: id }).toPromise();
  }

  markMessageAsConfirmed(type: "org" | "system", id: string, remindMeLater: boolean): Promise<void> {
    const route = type === 'org' ? '/organization' : '';
    return this.http.post<void>(this.target + route + '/popupMessages/confirmed', { messageId: id, remindMeLater }).toPromise();
  }

  getAllPopupMessagesToShow(type: "org" | "system"): Promise<Array<PopupMessageData>> {
    const route = type === 'org' ? '/organization' : '';
    return this.http.get<Array<PopupMessageData>>(this.target + route + '/popupMessages/user').toPromise();
  }

  updateOrgAuth2Factors(selectedOrg: any, auth2Factors: "disabled" | "mandatory" | "optional"): Promise<void> {
    return this.http.post<any>(this.target + '/organization/auth2Factors', { organization: selectedOrg, auth2Factors: auth2Factors }).toPromise();
  }

  updateDefaultUserOptions(organization: string, defaultUserOptions: NewOrgUserDefaults): Promise<void> {
    return this.http.post<any>(this.target + '/organization/defaultUserOptions', { defaultUserOptions, organization }).toPromise();
  }

  getModelLastEditDate(modelId: string): Promise<string> {
    const params = new HttpParams().set('id', modelId);
    return this.http.get<string>(this.target + '/storage/modelLastEditDate', { params: params }).toPromise();
  }

}
