import { Component, ViewEncapsulation } from '@angular/core';
import {UserDetails, UserService} from '../../../rappid-components/services/user.service';
import { ModelStorageService } from '../../../rappid-components/services/storage/model-storage.service';
import {
  ConnectionSettings,
  MySQLConnectionSettings,
  GraphDBConnectionSettings,
  defaultObjectStyleSettings,
  defaultRosConnectionSettings,
  defaultMySQLConnectionSettings,
  defaultGraphDBConnectionSettings,
  OplService,
  StyleSettings, OplSettings, CalculationsServerConnectionSettings, defaultCalculationsServerSettings
} from '../../../opl-generation/opl.service';
import { InitRappidService } from '../../../rappid-components/services/init-rappid.service';
import {joint, validationAlert} from '../../../configuration/rappidEnviromentFunctionality/shared';
import { defaultSettings } from '../../../opl-generation/opl-database';
import { StorageService } from '../../../rappid-components/services/storage.service';
import {
  MatLegacySlideToggleChange,
  MatLegacySlideToggleChange as MatSlideToggleChange
} from "@angular/material/legacy-slide-toggle";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'opcloud-opcloud-settings',
  templateUrl: './opcloud-settings.component.html',
  styleUrls: ['./opcloud-settings.component.css'],
})

export class OpcloudSettingsComponent {
  public newInterval: string;
  public defaultInterval = 5;
  public newDigitsNum: string;
  public defaultDigitsNum = 2;
  public newCompDigitsNum: string;
  public defaultCompDigitsNum = 3;
  settingsForOrg = false;
  public showTextColor: boolean;
  public logSharingPermission: boolean;
  public spellCheck: boolean;
  public notes: boolean;
  public sdNames: boolean;
  public multiDeletion: boolean;
  public tutorialMode: boolean;
  public dragSearchAuto: boolean;
  public haloDefaultMode: boolean;
  public font_sizes_options = [8, 10, 12, 14, 16, 18, 20, 22, 24, 28, 32];
  public style: { object: StyleSettings, process: StyleSettings, state: StyleSettings };
  // tslint:disable-next-line:max-line-length
  public connection: { ros: ConnectionSettings, mqtt: ConnectionSettings, python: ConnectionSettings, mysql: MySQLConnectionSettings, graphdb: GraphDBConnectionSettings, calculationsServer: CalculationsServerConnectionSettings, allow_users: boolean };
  public navigatorEnabled: boolean;
  public chatEnabled: boolean;
  private _isSubscribedToEmails: boolean;
  private _isEmailAsSecondAuthFactor: boolean;
  private _isEmailAsSecondAuthFactorSupported: boolean;
  private currentUser;
  private gridSettings: {
    state: boolean,
    color: string,
    thickness: number,
    scaleFactor: number,
    gridSize: number
  };

  constructor(private oplService: OplService, private storage: StorageService, private userService: UserService, private init: InitRappidService) {
    this.userService.user$.subscribe(user => this.currentUser = user);
  }

  ngOnInit() {
    this.defaultInterval = this.storage.getAutosaveTime();
    this.defaultDigitsNum = this.oplService.settings.timeDurationUnitsDigits;
    this.defaultCompDigitsNum = this.oplService.settings.numericComputationalDigits;
    this.logSharingPermission = this.init.logSharingPermission;
    this.spellCheck = this.init.spellCheck;
    this.notes = this.oplService.settings.Notes;
    this.sdNames = this.oplService.settings.SDNames;
    this.multiDeletion = this.oplService.settings.multiDeletion;
    this.dragSearchAuto = this.oplService.settings.dragSearchAuto;
    this.haloDefaultMode = this.oplService.settings.haloDefaultMode;
    this.tutorialMode = this.oplService.settings.tutorialMode;
    this.style = this.oplService.userStylingSettings();
    this.connection = this.oplService.userConnectionSettings();
    this.navigatorEnabled = this.oplService.settings.navigatorEnabled;
    this.chatEnabled = this.oplService.settings.chatEnabled;
    this.gridSettings = this.oplService.settings.gridSettings;
    if (this.connection.allow_users === false) {
      this.handleDisabledConnectionSettings();
    }
    this.setColorInputChangeHandlers();
    this._isSubscribedToEmails = this.isSubscribedToEmails();
    this._isEmailAsSecondAuthFactor = this.isEmailAsSecondAuthFactor();
    this._isEmailAsSecondAuthFactorSupported = this.checkIfEmailAsSecondAuthFactorSupported();
  }

  setUserDefinedInterval() {
    if (/^\d+$/.test(this.newInterval)) {
      this.storage.autoSaveInterval = Number(this.newInterval);
      this.defaultInterval = Number(this.newInterval);
      const settings = { 'autosave': this.newInterval };
      this.userService.updateUser(this.userService.user.uid, this.userService.user.userData.organization, settings).then(() =>
        joint.ui.FlashMessage.open('Autosave Interval Value changed successfully and now its ' + this.newInterval + ' minutes', '', {
          type: 'success',
          closeAnimation: { delay: 3000 }
        }));
    } else {
      alert('Please enter only numbers');
      return;
    }
  }

  setUserDefinedDigitsNum() {
    if (/^\d+$/.test(this.newDigitsNum)) {
      this.defaultDigitsNum = Number(this.newDigitsNum);
      const settings = { 'timeDurationUnitsDigits': this.defaultDigitsNum };
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() =>
        joint.ui.FlashMessage.open('Value changed successfully. From now OPCloud will show ' + this.newDigitsNum + ' decimal places for Time Precision values', '', {
          type: 'success',
          closeAnimation: { delay: 3000 }
        }));
    } else {
      alert('Please enter only numbers');
      return;
    }
  }

  setUserDefinedCompDigitsNum() {
    if (/^\d+$/.test(this.newCompDigitsNum)) {
      this.defaultCompDigitsNum = Number(this.newCompDigitsNum);
      const settings = { 'numericComputationalDigits': this.defaultCompDigitsNum };
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() =>
        joint.ui.FlashMessage.open('Value changed successfully. From now OPCloud will show ' + this.newCompDigitsNum + ' decimal places for numeric computational values', '', {
          type: 'success',
          closeAnimation: { delay: 3000 }
        }));
    } else {
      alert('Please enter only numbers');
      return;
    }
  }

  updateLogSharingPermission() {
    const settings = { 'logSharingPermission': this.logSharingPermission };
    const message = (this.logSharingPermission) ? 'Log sharing enabled!' : 'Log sharing disabled!';
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(() => validationAlert(message, null, 'Success'));
  }

  updateSpellCheck() {
    const settings = { 'spellCheck': this.spellCheck };
    const message = (this.spellCheck) ? 'Spell Checking are Online' : 'Spell Checking are Offline';
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(() => validationAlert(message, null, 'Success'));
  }

  updateNotes() {
    const settings = { 'Notes': this.notes };
    const message = (this.notes) ? 'Notes are Online' : 'Notes are Offline';
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(() => validationAlert(message, null, 'Success'));
  }

  updateOPDnames() {
    const settings = { SDNames: this.sdNames };
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(_ => validationAlert('Saved', null, 'Success'));
  }

  updateUserMultiDeletion() {
    const settings = { multiDeletion: this.multiDeletion };
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(_ => validationAlert('Saved', null, 'Success'));
  }

  updateObjectFont() {
    const settings = { object: { font: this.style.object.font } };
    this.oplService.settings.style.object.font = this.style.object.font;
    // this.userService.updateUserObjectStyleSettings(this.userService.user.uid, this.userService.userOrg, settings)
    //   .then(() => validationAlert('saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    // this.userService.updateUserDetails(this.userService.user.uid, {style: settings}).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateProcessFont() {
    const settings = { process: { font: this.style.process.font } };
    this.oplService.settings.style.process.font = this.style.process.font;
    // this.userService.updateUserProcessStyleSettings(this.userService.user.uid, this.userService.userOrg, settings)
    //   .then(() => validationAlert('saved', null, 'Success'));
    //this.userService.updateUserDetails(this.userService.user.uid, {style: settings}).then(() => validationAlert('Saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));

  }

  updateStateFont() {
    const settings = { state: { font: this.style.state.font } };
    this.oplService.settings.style.state.font = this.style.state.font;
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateObjectFontSize() {
    const settings = { object: { font_size: this.style.object.font_size } };
    this.oplService.settings.style.object.font_size = this.style.object.font_size;
    // this.userService.updateUserObjectStyleSettings(this.userService.user.uid, this.userService.userOrg, settings)
    //   .then(() => validationAlert('saved', null, 'Success'));
    // this.userService.updateUserDetails(this.userService.user.uid, {style: settings}).then(() => validationAlert('Saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateProcessFontSize() {
    const settings = { process: { font_size: this.style.process.font_size } };
    this.oplService.settings.style.process.font_size = this.style.process.font_size;
    // this.userService.updateUserProcessStyleSettings(this.userService.user.uid, this.userService.userOrg, settings)
    //   .then(() => validationAlert('saved', null, 'Success'));
    //this.userService.updateUserDetails(this.userService.user.uid, {style: settings}).then(() => validationAlert('Saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateStateFontSize() {
    const settings = { state: { font_size: this.style.state.font_size } };
    this.oplService.settings.style.state.font_size = this.style.state.font_size;
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateObjectFillColor(value) {
    this.style.object.fill_color = value;
    const settings = { object: { fill_color: this.style.object.fill_color } };
    this.oplService.settings.style.object.fill_color = this.style.object.fill_color;
    // this.userService.updateUserObjectStyleSettings(this.userService.user.uid, this.userService.userOrg, settings)
    //   .then(() => validationAlert('saved', null, 'Success'));
    // this.userService.updateUserDetails(this.userService.user.uid, {style: settings}).then(() => validationAlert('Saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }
  updateProcessFillColor(value) {
    this.style.process.fill_color = value;
    const settings = { process: { fill_color: this.style.process.fill_color } };
    this.oplService.settings.style.process.fill_color = this.style.process.fill_color;
    // this.userService.updateUserProcessStyleSettings(this.userService.user.uid, this.userService.userOrg, settings)
    //   .then(() => validationAlert('saved', null, 'Success'));
    this.userService.updateUserDetails(this.userService.user.uid, { style: settings }).then(() => validationAlert('Saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateStateFillColor(value) {
    this.style.state.fill_color = value;
    const settings = { state: { fill_color: this.style.state.fill_color } };
    this.oplService.settings.style.state.fill_color = this.style.state.fill_color;
    this.userService.updateUserDetails(this.userService.user.uid, { style: settings }).then(() => validationAlert('Saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateObjectTextColor(value) {
    this.style.object.text_color = value;
    const settings = { object: { text_color: this.style.object.text_color } };
    this.oplService.settings.style.object.text_color = this.style.object.text_color;
    // this.userService.updateUserObjectStyleSettings(this.userService.user.uid, this.userService.userOrg, settings)
    //   .then(() => validationAlert('saved', null, 'Success'));
    // this.userService.updateUserDetails(this.userService.user.uid, {style: settings}).then(() => validationAlert('Saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }
  updateProcessTextColor(value) {
    this.style.process.text_color = value;
    const settings = { process: { text_color: this.style.process.text_color } };
    this.oplService.settings.style.process.text_color = this.style.process.text_color;
    // this.userService.updateUserProcessStyleSettings(this.userService.user.uid, this.userService.userOrg, settings)
    //   .then(() => validationAlert('saved', null, 'Success'));
    // this.userService.updateUserDetails(this.userService.user.uid, {style: settings}).then(() => validationAlert('Saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateStateTextColor(value) {
    this.style.state.text_color = value;
    const settings = { state: { text_color: this.style.state.text_color } };
    this.oplService.settings.style.state.text_color = this.style.state.text_color;
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateObjectBorderColor(value) {
    this.style.object.border_color = value;
    const settings = { object: { border_color: this.style.object.border_color } };
    this.oplService.settings.style.object.border_color = this.style.object.border_color;
    // this.userService.updateUserObjectStyleSettings(this.userService.user.uid, this.userService.userOrg, settings)
    //   .then(() => validationAlert('saved', null, 'Success'));
    // this.userService.updateUserDetails(this.userService.user.uid, {style: settings}).then(() => validationAlert('Saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateProcessBorderColor(value) {
    this.style.process.border_color = value;
    const settings = { process: { border_color: this.style.process.border_color } };
    this.oplService.settings.style.process.border_color = this.style.process.border_color;
    // this.userService.updateUserProcessStyleSettings(this.userService.user.uid, this.userService.userOrg, settings)
    //   .then(() => validationAlert('saved', null, 'Success'));
    // this.userService.updateUserDetails(this.userService.user.uid, {style: settings}).then(() => validationAlert('Saved', null, 'Success'));
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  updateStateBorderColor(value) {
    this.style.state.border_color = value;
    const settings = { state: { border_color: this.style.state.border_color } };
    this.oplService.settings.style.state.border_color = this.style.state.border_color;
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }
  /**
   * updating handlers for input with type color
   */
  private setColorInputChangeHandlers() {
    const thisProc = this;
    const object_fill_color = document.getElementById('object_fill_color_input') as HTMLInputElement;
    object_fill_color.addEventListener('change', function () {
      thisProc.updateObjectFillColor(this.value);
    });
    const process_fill_color = document.getElementById('process_fill_color_input') as HTMLInputElement;
    process_fill_color.addEventListener('change', function () {
      thisProc.updateProcessFillColor(this.value);
    });
    const state_fill_color = document.getElementById('state_fill_color_input') as HTMLInputElement;
    state_fill_color.addEventListener('change', function () {
      thisProc.updateStateFillColor(this.value);
    });
    const object_text_color = document.getElementById('object_text_color_input') as HTMLInputElement;
    object_text_color.addEventListener('change', function () {
      thisProc.updateObjectTextColor(this.value);
    });
    const process_text_color = document.getElementById('process_text_color_input') as HTMLInputElement;
    process_text_color.addEventListener('change', function () {
      thisProc.updateProcessTextColor(this.value);
    });
    const state_text_color = document.getElementById('state_text_color_input') as HTMLInputElement;
    state_text_color.addEventListener('change', function () {
      thisProc.updateStateTextColor(this.value);
    });
    const object_border_color = document.getElementById('object_border_color_input') as HTMLInputElement;
    object_border_color.addEventListener('change', function () {
      thisProc.updateObjectBorderColor(this.value);
    });
    const process_border_color = document.getElementById('process_border_color_input') as HTMLInputElement;
    process_border_color.addEventListener('change', function () {
      thisProc.updateProcessBorderColor(this.value);
    });
    const state_border_color = document.getElementById('state_border_color_input') as HTMLInputElement;
    state_border_color.addEventListener('change', function () {
      thisProc.updateStateBorderColor(this.value);
    });
  }

  ReturnToDefault() {
    this.logSharingPermission = defaultSettings.user.logSharingPermission;
    this.defaultDigitsNum = defaultSettings.user.timeDurationUnitsDigits;
    this.defaultCompDigitsNum = defaultSettings.user.numericComputationalDigits;
    this.navigatorEnabled = defaultSettings.user.navigatorEnabled;
    this.init.showNavigator = this.navigatorEnabled;
    this.chatEnabled = defaultSettings.user.navigatorEnabled;
    this.init.showChatPanel = this.chatEnabled;
    this.spellCheck = defaultSettings.user.spellCheck;
    this.notes = this.oplService.orgOplSettings.displayNotes ? this.oplService.orgOplSettings.displayNotes : defaultSettings.organization.displayNotes;
    this.sdNames = this.oplService.orgOplSettings.sdNames ? this.oplService.orgOplSettings.sdNames : defaultSettings.organization.SDNames;
    this.multiDeletion = defaultSettings.user.multiDeletion;
    this.tutorialMode = this.oplService.orgOplSettings.tutorialMode ? this.oplService.orgOplSettings.tutorialMode : defaultSettings.organization.tutorialMode;
    this.dragSearchAuto = this.oplService.orgOplSettings.dragSearchAuto ? this.oplService.orgOplSettings.dragSearchAuto : defaultSettings.organization.dragSearchAuto;
    this.haloDefaultMode = defaultSettings.user.haloDefaultMode;
    this.style = this.cloneOrganizationStyleSettings();
    this.connection = this.cloneOrganizationConnectionSettings();
    this.init.oplService.settings.bringConnectedSettings.fundamentals = defaultSettings.user.bringConnectedSettings.fundamentals;
    this.init.oplService.settings.bringConnectedSettings.tagged = defaultSettings.user.bringConnectedSettings.tagged;
    this.init.oplService.settings.bringConnectedSettings.proceduralTransformers = defaultSettings.user.bringConnectedSettings.proceduralTransformers;
    this.init.oplService.settings.bringConnectedSettings.proceduralEnablers = defaultSettings.user.bringConnectedSettings.proceduralEnablers;
    this.init.oplService.settings.gridSettings.state = false;
    this.init.oplService.settings.gridSettings.color = '#8c8c8c';
    this.init.oplService.settings.gridSettings.thickness = 1;
    this.init.oplService.settings.gridSettings.scaleFactor = 35;
    this.init.oplService.settings.gridSettings.gridSize = 5;
    this.gridSettings = this.init.oplService.settings.gridSettings;
    this.init.showGrid = this.init.oplService.settings.gridSettings.state;
    this.init.toggleGrid(false);
    this.init.toggleGrid(false);
    const settings = { 'spellCheck': this.spellCheck, 'Notes': this.notes, 'SDNames': this.sdNames, 'tutorialMode': this.tutorialMode, 'dragSearchAuto': this.dragSearchAuto,
      'haloDefaultMode': this.haloDefaultMode, 'style': this.oplService.getUndefinedStyleSettings(), 'connection': this.oplService.getUndefinedConnectionSettings(),
      'numericComputationalDigits': this.defaultCompDigitsNum, 'timeDurationUnitsDigits': this.defaultDigitsNum,
      'navigatorEnabled': this.navigatorEnabled, 'chatEnabled': this.chatEnabled, multiDeletion: this.multiDeletion,
      'bringConnectedSettings': this.init.oplService.settings.bringConnectedSettings, gridSettings: this.gridSettings};
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Settings configuration restored to default', null, 'Success'));
  }
  cloneOrganizationStyleSettings() {
    let objectStyleSettings: StyleSettings = this.oplService.getObjectStyleDefaultSettings();
    let processStyleSettings: StyleSettings = this.oplService.getProcessStyleDefaultSettings();
    let stateStyleSettings: StyleSettings = this.oplService.getStateStyleDefaultSettings();
    this.updateUserStyleSettingsAccordingToOrganzation(objectStyleSettings, 'object');
    this.updateUserStyleSettingsAccordingToOrganzation(processStyleSettings, 'process');
    this.updateUserStyleSettingsAccordingToOrganzation(stateStyleSettings, 'state');
    return { object: objectStyleSettings, process: processStyleSettings, state: stateStyleSettings };
  }

  /**
   * * updates styleSettings object/process(according to given type) by this order: default settings->organization
   * settings in order to return to default settings.
   * */
  public updateUserStyleSettingsAccordingToOrganzation(styleSettings: StyleSettings, type: string) {
    const thisProc = this;
    Object.keys(defaultObjectStyleSettings).forEach(function (key) {
      styleSettings[key] = (thisProc.oplService.orgSettings.style[type] && thisProc.oplService.orgSettings.style[type][key]) ?
        thisProc.oplService.orgSettings.style[type][key] : styleSettings[key];
    });
  }
   /**
    * when returning to default settings, the user's settings should be the organization settings.
    **/
  cloneOrganizationConnectionSettings() {
    const rosSettings: ConnectionSettings = this.oplService.getRosConnectionDefaultSettings();
    const mqttSettings: ConnectionSettings = this.oplService.getMqttConnectionDefaultSettings();
    const pythonSettings: ConnectionSettings = this.oplService.getPythonConnectionDefaultSettings();
    const mysqlSettings: MySQLConnectionSettings = this.oplService.getMySQLConnectionDefaultSettings();
    const graphDBConnectionSettings: GraphDBConnectionSettings = this.oplService.getGraphDBConnectionDefaultSettings();
    const calculationsServerSettings: CalculationsServerConnectionSettings = this.oplService.getComputingServerConnectionDefaultSettings();
    const allow_usersSettings: boolean = this.oplService.allow_users;
    this.updateUserConnectionSettingsAccordingToOrganzation(rosSettings, 'ros');
    this.updateUserConnectionSettingsAccordingToOrganzation(mqttSettings, 'mqtt');
    this.updateUserConnectionSettingsAccordingToOrganzation(pythonSettings, 'python');
    this.updateUserConnectionSettingsAccordingToOrganzation(mysqlSettings, 'mysql');
    this.updateGraphDBConnectionSettingsAccordingToOrganzation(graphDBConnectionSettings, 'graphdb');
    this.updateCalculationsServerConnectionSettingsAccordingToOrganzation(calculationsServerSettings, 'calculationsServer');
    return { ros: rosSettings, mqtt: mqttSettings, python: pythonSettings,
      mysql: mysqlSettings, graphdb: graphDBConnectionSettings, allow_users: allow_usersSettings, calculationsServer: calculationsServerSettings };
  }

  /**
   * * updates Calculations Server Settings (according to given type) by this order: if defined - take the organization
   * settings otherwise stay with the default settings
   * */
  public updateCalculationsServerConnectionSettingsAccordingToOrganzation(calculationsServerSettings: CalculationsServerConnectionSettings, type: string) {
    const thisProc = this;
    Object.keys(defaultRosConnectionSettings).forEach(function (key) {
      calculationsServerSettings[key] = (thisProc.oplService.orgSettings.connection[type] && thisProc.oplService.orgSettings.connection[type][key] !== undefined) ?
        thisProc.oplService.orgSettings.connection[type][key] : calculationsServerSettings[key];
    });
  }

  /**
   * * updates Graph DB Settings (according to given type) by this order: if defined - take the organization
   * settings otherwise stay with the default settings
   * */
  public updateGraphDBConnectionSettingsAccordingToOrganzation(graphDBConnectionSettings: GraphDBConnectionSettings, type: string) {
    const thisProc = this;
    Object.keys(defaultRosConnectionSettings).forEach(function (key) {
      graphDBConnectionSettings[key] = (thisProc.oplService.orgSettings.connection[type] && thisProc.oplService.orgSettings.connection[type][key] !== undefined) ?
        thisProc.oplService.orgSettings.connection[type][key] : graphDBConnectionSettings[key];
    });
  }

  /**
   * * updates connectionSettings ros/mqtt(according to given type) by this order: if defined- take the organization
   * settings otherwise stay with the default settings
   * */
  public updateUserConnectionSettingsAccordingToOrganzation(connectionSettings: ConnectionSettings, type: string) {
    const thisProc = this;
    Object.keys(defaultRosConnectionSettings).forEach(function (key) {
      connectionSettings[key] = (thisProc.oplService.orgSettings.connection[type] && thisProc.oplService.orgSettings.connection[type][key] !== undefined) ?
        thisProc.oplService.orgSettings.connection[type][key] : connectionSettings[key];
    });
  }

  /**
   * updates the user ros server according to the choice
   **/
  updatePythonServer(event) {
    const new_server = event.target.value;
    const prev_server = this.connection.python.server;
    const check_server_validity = this.serverIsValid(new_server);
    if (check_server_validity.isValid) {
      this.connection.python.server = new_server;
      this.oplService.settings.connection.python.server = this.connection.python.server;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      const message = 'Server can contain only numbers, dots and english letters, \n or to be in a format of an IP address.' +
        (check_server_validity.illegal_char !== undefined ? '\n The character ' + check_server_validity.illegal_char + ' cannot be used.' : '');
      validationAlert(message);
      (document.getElementById('server-Python-input') as HTMLInputElement).value = prev_server;
    }
  }
  /**
   * updates the user ros port according to the choice
   **/
  updatePythonPort(event) {
    const prev_port = this.connection.python.port;
    const new_port = event.target.value;
    if (this.portIsValid(new_port)) {
      this.connection.python.port = new_port;
      this.oplService.settings.connection.python.port = this.connection.python.port;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-Python-input') as HTMLInputElement).value = prev_port;
    }
  }

  /**
   * updates the user Mysql port according to the choice
   **/
  updateMySQLPort(event) {
    const prev_port = this.connection.mysql.port;
    const new_port = event.target.value;
    if (this.portIsValid(new_port)) {
      this.connection.mysql.port = new_port;
      this.oplService.settings.connection.mysql.port = this.connection.mysql.port;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-MySQL-input') as HTMLInputElement).value = prev_port;
    }
  }
  /**
   * updates the user mysql hostname according to the choice.
   **/
  updateMySQLHostname(event) {
    const prev_hostname = this.connection.mysql.hostname;
    const new_hostname = event.target.value;
    if (this.hostnameIsValid(new_hostname)) {
      this.connection.mysql.hostname = new_hostname;
      this.oplService.settings.connection.mysql.hostname = this.connection.mysql.hostname;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Hostname should be a string!');
      (document.getElementById('hostname-MySQL-input') as HTMLInputElement).value = prev_hostname ;
    }
  }
  /**
   * updates the user mysql username according to the choice.
   **/
  updateMySQLUsername(event) {
    const prev_username = this.connection.mysql.username;
    const new_username = event.target.value;
    if (this.usernameIsValid(new_username)) {
      this.connection.mysql.username = new_username;
      this.oplService.settings.connection.mysql.username = this.connection.mysql.username;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Username should not be a null value ');
      (document.getElementById('username-MySQL-input') as HTMLInputElement).value = prev_username ;
    }
  }
  /**
   * updates the user mysql password according to the choice.
   **/
  updateMySQLPassword(event) {
    const prev_password = this.connection.mysql.password;
    const new_password = event.target.value;
    if (this.passwordIsValid(new_password)) {
      this.connection.mysql.password = new_password;
      this.oplService.settings.connection.mysql.password = this.connection.mysql.password;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Password should not be a null value');
      (document.getElementById('password-MySQL-input') as HTMLInputElement).value = prev_password ;
    }
  }
  /**
   * updates the mysql Schema according to the choice.
   **/
  updateMySQLSchema(event) {
    const prev_Schema = this.connection.mysql.schema;
    const new_Schema = event.target.value;
    if (this.hostnameIsValid(new_Schema)) {
      this.connection.mysql.schema = new_Schema;
      this.oplService.settings.connection.mysql.schema = this.connection.mysql.schema;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Schema should be a string!');
      (document.getElementById('schema-input') as HTMLInputElement).value = prev_Schema  ;
    }
  }
  /**
   * updates the user mysql hostname according to the choice.
   **/
  updateWSHostname(event) {
    const prev_WS_hostname = this.connection.mysql.ws_hostname;
    const new_WS_hostname = event.target.value;
    if (this.hostnameIsValid(new_WS_hostname)) {
      this.connection.mysql.ws_hostname = new_WS_hostname;
      this.oplService.settings.connection.mysql.ws_hostname = this.connection.mysql.ws_hostname;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Hostname should be a string!');
      (document.getElementById('hostname-WS-input') as HTMLInputElement).value = prev_WS_hostname  ;
    }
  }
  /**
   * updates the user ros port according to the choice
   **/
  updateWSPort(event) {
    const prev_WS_port = this.connection.mysql.ws_port;
    const new_WS_port = event.target.value;
    if (this.portIsValid(new_WS_port )) {
      this.connection.mysql.ws_port = new_WS_port;
      this.oplService.settings.connection.mysql.ws_port = this.connection.mysql.ws_port;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-WS-input') as HTMLInputElement).value = prev_WS_port;
    }
  }
  /**
   * validates the legality of the new_hostname: should be a string
   * */
  private hostnameIsValid(new_hostname: any) {
    return typeof new_hostname === 'string';
  }
  /**
   * validates the legality of the new_username: should not be a null value.
   * */
  private usernameIsValid(new_username: any) {
    return true;
  }
  /**
   * validates the legality of the new_username: should not be a null value.
   * */
  private passwordIsValid(new_password: any) {
    return true;
  }

  /**
   * updates the user ros server according to the choice
   **/
  updateRosServer(event) {
    const new_server = event.target.value;
    const prev_server = this.connection.ros.server;
    const check_server_validity = this.serverIsValid(new_server);
    if (check_server_validity.isValid) {
      this.connection.ros.server = new_server;
      this.oplService.settings.connection.ros.server = this.connection.ros.server;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      const message = 'Server can contain only numbers, dots and english letters, \n or to be in a format of an IP address.' +
        (check_server_validity.illegal_char !== undefined ? '\n The character ' + check_server_validity.illegal_char + ' cannot be used.' : '');
      validationAlert(message);
      (document.getElementById('server-Ros-input') as HTMLInputElement).value = prev_server;
    }
  }
  /**
   * updates the user ros port according to the choice
   **/
  updateRosPort(event) {
    const prev_port = this.connection.ros.port;
    const new_port = event.target.value;
    if (this.portIsValid(new_port)) {
      this.connection.ros.port = new_port;
      this.oplService.settings.connection.ros.port = this.connection.ros.port;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-Ros-input') as HTMLInputElement).value = prev_port;
    }
  }
  /**
   * updates the user mqtt server according to the choice
   **/
  updateMqttServer(event) {
    const new_server = event.target.value;
    const prev_server = this.connection.mqtt.server;
    const check_server_validity = this.serverIsValid(new_server);
    if (check_server_validity.isValid) {
      this.connection.mqtt.server = new_server;
      this.oplService.settings.connection.mqtt.server = this.connection.mqtt.server;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      const message = 'Server can contain only numbers, dots and english letters, \n or to be in a format of an IP address.' +
        (check_server_validity.illegal_char !== undefined ? '\n The character ' + check_server_validity.illegal_char + ' cannot be used.' : '');
      validationAlert(message);
      (document.getElementById('server-Mqtt-input') as HTMLInputElement).value = prev_server;
    }
  }
  /**
   * updates the user mqtt port according to the choice
   **/
  updateMqttPort(event) {
    const prev_port = this.connection.mqtt.port;
    const new_port = event.target.value;
    if (this.portIsValid(new_port)) {
      this.connection.mqtt.port = new_port;
      this.oplService.settings.connection.mqtt.port = this.connection.mqtt.port;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-Mqtt-input') as HTMLInputElement).value = prev_port;
    }
  }
  /**
   * validates the legality of the new_port: should be a number or an empty string
   * */
  private portIsValid(new_port: any) {
    return !isNaN(new_port);
  }
  /**
   * validates the legality of the new_server: should not include certain characters
   * */
  private serverIsValid(new_server: any) {
    // const illegal_chars = new RegExp('[\/|\\|:|\*|\?|\"|<|>|=|\||!]', 'i');
    // const res = new_server.search(illegal_chars);

    const legal_chars_regex = new RegExp('[^a-zA-Z0-9\.]', 'i');
    const is_legal_chars = new_server.search(legal_chars_regex);
    // const ip_address_regex = /^\d{3}\.\d{3}\.\d{3}\.\d{3}$/; // included in the first regex
    // const is_ip = ip_address_regex.test(new_server);
    return {isValid: ( is_legal_chars === -1),
      illegal_char: (is_legal_chars === -1) ? undefined : new_server[is_legal_chars]};
  }
  /**
   * updates the user GraphDB API according to the choice.
   **/
  updateGraphDBAPI(event) {
    const prev_hostname = this.connection.graphdb.graphdb_api;
    const new_hostname = event.target.value;
    if (this.hostnameIsValid(new_hostname)) {
      this.connection.graphdb.graphdb_api = new_hostname;
      this.oplService.settings.connection.graphdb.graphdb_api = this.connection.graphdb.graphdb_api;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('GraphDB API should be a string!');
      (document.getElementById('graphdb-api') as HTMLInputElement).value = prev_hostname ;
    }
  }
  /**
   * updates the GraphDB username according to the choice.
   **/
  updateGraphDBUsername(event) {
    const prev_username = this.connection.graphdb.username;
    const new_username = event.target.value;
    if (this.usernameIsValid(new_username)) {
      this.connection.graphdb.username = new_username;
      this.oplService.settings.connection.graphdb.username = this.connection.graphdb.username;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Username should not be a null value ');
      (document.getElementById('graphdb-user') as HTMLInputElement).value = prev_username ;
    }
  }
  /**
   * updates the user GraphDB password according to the choice.
   **/
  updateGraphDBPassword(event) {
    const prev_password = this.connection.graphdb.password;
    const new_password = event.target.value;
    if (this.passwordIsValid(new_password)) {
      this.connection.graphdb.password = new_password;
      this.oplService.settings.connection.graphdb.password = this.connection.graphdb.password;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Password should not be a null value');
      (document.getElementById('graphdb-password') as HTMLInputElement).value = prev_password ;
    }
  }

  /**
   * when an organization had disabled users to edit their connection settings,
   * their connections settings should be the organization connection settings (also when the organization settings gets
   * changed, so they should be undefined in the user database (so they will not override the organization's settings)
   **/
  private handleDisabledConnectionSettings() {
    const connection = this.oplService.getUndefinedConnectionSettings();
    connection.allow_users = false;
    this.userService.updateUserOplSetting({ connection: connection });
  }

  updateDragSearchAutoMode() {
    this.init.draggableAutocomplete = this.dragSearchAuto;
    const settings = { dragSearchAuto: this.dragSearchAuto };
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(_ => validationAlert('Saved', null, 'Success'));
  }

  updateHaloDefaultMode() {
    this.init.defaultHalo = this.haloDefaultMode;
    const settings = { haloDefaultMode: this.haloDefaultMode };
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(_ => validationAlert('Saved', null, 'Success'));
  }

  updateTutorialMode() {
    const settings = { tutorialMode: this.tutorialMode };
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(_ => validationAlert('Saved', null, 'Success'));
  }

  updateUserNavigatorEnabled() {
    this.init.showNavigator = this.navigatorEnabled;
    const settings = { navigatorEnabled: this.navigatorEnabled };
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(_ => validationAlert('Saved', null, 'Success'));
  }

  updateUserChatEnabled() {
    this.init.showChatPanel = this.chatEnabled;
    const settings = { chatEnabled: this.chatEnabled };
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(_ => validationAlert('Saved', null, 'Success'));
  }

  isSubscribedToEmails(): boolean {
    return !!this.userService.user?.userData.email_subscription;
  }

  isEmailAsSecondAuthFactor(): boolean {
    return !!this.userService.user?.userData.emailAsSecondAuthFactor;
  }

  OnChangeEmailSubscription($event: MatSlideToggleChange) {
    this._isSubscribedToEmails = $event.checked;
    const details: UserDetails = {
      email_subscription: this._isSubscribedToEmails
    };
    this.userService.updateDB(details).then((res) => {
      validationAlert('Preference saved.', null, 'Success');
    }).catch(err => {
      validationAlert('ERROR:' + err, null, 'Error');
    });
  }

  OnChangeEmail2FA($event: MatSlideToggleChange) {
    this._isEmailAsSecondAuthFactor = $event.checked;
    const details: UserDetails = {
      emailAsSecondAuthFactor: this._isEmailAsSecondAuthFactor
    };
    this.userService.updateDB(details).then((res) => {
      validationAlert('Preference saved.', null, 'Success');
    }).catch(err => {
      validationAlert('ERROR:' + err, null, 'Error');
    });
  }

  private checkIfEmailAsSecondAuthFactorSupported() {
    return environment.serverSideAuth && this.oplService.orgOplSettings.auth2Factors === 'optional';
  }

  onBringOptionChange() {
    const settings = {
      bringConnectedSettings: this.init.oplService.settings.bringConnectedSettings
    };
    this.userService.updateUserOplSetting(settings).then(_ => validationAlert('Saved', null, 'Success'));
  }

  onBringSelection($event) {
    $event.stopPropagation();
  }

  toggleBringOption(val) {
    this.init.oplService.settings.bringConnectedSettings[val] = !this.init.oplService.settings.bringConnectedSettings[val];
    this.onBringOptionChange();
  }

  getBringSettingsTitle() {
    const settings = this.init.oplService.settings.bringConnectedSettings;
    let ret = [];
    if (settings.proceduralEnablers) {
      ret.push('Enablers');
    }
    if (settings.proceduralTransformers) {
      ret.push('Transformers');
    }
    if (settings.fundamentals) {
      ret.push('Fundamental');
    }
    if (settings.tagged) {
      ret.push('Tagged');
    }
    if (ret.length === 0) {
      return 'Please Choose...';
    }
    return ret.join(', ');
  }

  onChangeGridMode($event: MatSlideToggleChange) {
    this.gridSettings.state = $event.checked;
    this.init.showGrid = this.gridSettings.state;
    this.init.toggleGrid(false);
    this.init.toggleGrid(false);
    this.onChangeGridSettings();
  }

  onChangeGridColor($event) {
    this.gridSettings.color = $event.target.value;
    this.onChangeGridSettings();
  }

  onChangeGridSettings() {
    const settings = { gridSettings: this.gridSettings };
    this.init.toggleGrid(false);
    this.init.toggleGrid(false);
    this.userService.updateUserOplSetting(settings).then(_ => validationAlert('Saved', null, 'Success'));
  }

  updateComputingServerURL($event) {
    const newValue = (<any>event).target.value;
    if (newValue.startsWith('http://') || newValue.startsWith('https://')) {
      this.connection.calculationsServer.computingServerURL = newValue;
      this.oplService.settings.connection.calculationsServer = this.oplService.settings.connection.calculationsServer || {};
      this.oplService.settings.connection.calculationsServer.computingServerURL = newValue;
      const settings = {connection: this.connection};
      this.oplService.updateUserSettings(settings);
      this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
    } else {
      validationAlert('Invalid URL');
    }
  }

  onChangeLocalServerCheckbox($event: MatLegacySlideToggleChange) {
    const newValue = $event.checked;
    this.connection.calculationsServer.computingServerCalculations = newValue;
    this.oplService.settings.connection.calculationsServer = this.oplService.settings.connection.calculationsServer || {};
    this.oplService.settings.connection.calculationsServer.computingServerCalculations = newValue;
    const settings = {connection: this.connection};
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }
}
