export enum Affiliation {
  Systemic,
  Environmental
}

export enum Essence {
  Physical,
  Informatical
}

export enum statesArrangement {
  Top,
  Bottom,
  Left,
  Right
}

export enum code {
  Unspecified,
  PreDefined,
  UserDefined,
  External,
  ROS,
  MQTT,
  Python,
  SQL
}

export enum TimeDurationType {
  Unspecified = 1,
  Specified,
}

export enum valueType {
  None,
  String,
  Number,
  Array
}

export enum stateType {
  regular,
  value
}
export enum LinkLogicalConnection {
  Or,
  Xor,
  Not
}
export enum linkConnectionType {
  enviromental,
  systemic
}
export enum linkType {
  Agent,
  Instrument,
  Consumption,
  Result,
  Effect,
  Invocation,
  UndertimeException,
  OvertimeException,
  UndertimeOvertimeException,
  Unidirectional,
  Bidirectional,
  Aggregation,
  Exhibition,
  Generalization,
  Instantiation,
  // SelfInvocation,
  // SelfInvocationInZoom,
}
export const AggregationSVG =
  ['<svg xmlns="http://www.w3.org/2000/svg"\n' +
  '     xmlns:se="http://svg-edit.googlecode.com"\n' +
  '   xmlns:xlink="http://www.w3.org/1999/xlink"\n' +
  '   xmlns:dc="http://purl.org/dc/elements/1.1/"\n' +
  '  xmlns:cc="http://creativecommons.org/ns#"\n' +
  '   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"\n' +
  '   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"\n' +
  '   width="30" height="30" style="">\n' +
  '  <g class="currentLayer" style="">\n' +
  '<title>Layer 1</title>\n' +
  '<path fill="#000000" fill-opacity="1" stroke="#586D8C" stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="round" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/>\n' +
  '<path fill="#586D8C" stroke="#586D8C" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355875 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z" style="color: rgb(0, 0, 0);" class=""/>\n' +
  '  </g>\n' +
  '</svg>'].join('');
export const AggSVGWithLine =
  ['<svg xmlns="http://www.w3.org/2000/svg"\n' +
  ' xmlns:se="http://svg-edit.googlecode.com"\n' +
  ' xmlns:xlink="http://www.w3.org/1999/xlink"\n' +
  ' xmlns:dc="http://purl.org/dc/elements/1.1/"\n' +
  ' xmlns:cc="http://creativecommons.org/ns#"\n' +
  ' xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"\n' +
  ' xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"\n' +
  ' width="30" height="40" style="">\n' +
  ' <g class="currentLayer" style="">\n' +
  '<title>Layer 1</title>\n' +
  '<path fill="#000000" fill-opacity="1" stroke="#586D8C" strokeopacity="1" stroke-width="2" stroke-dasharray="none" strokelinejoin="round" stroke-linecap="butt" stroke-dashoffset="" fillrule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/>\n' +
  '<path fill="#586D8C" stroke="#586D8C" stroke-width="2" strokelinejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355875 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z" style="color: rgb(0, 0, 0);" class=""/>\n' +
  '<path fill="#586D8C" stroke="#586D8C" stroke-width="2" strokelinejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,30 L28.162149898128664,30 z" style="color: rgb(0, 0, 0);" class=""/>\n' +
  ' </g>\n' +
  '</svg>'].join('');

export const ExhibitionSVG =
  ['<svg xmlns="http://www.w3.org/2000/svg"' +
  ' xmlns:se="http://svg-edit.googlecode.com"' +
  ' xmlns:xlink="http://www.w3.org/1999/xlink" ' +
  'xmlns:dc="http://purl.org/dc/elements/1.1/" ' +
  'xmlns:cc="http://creativecommons.org/ns#"' +
  ' xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" ' +
  'xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" ' +
  'width="30" height="30" style="">' +
  '<g class="currentLayer" style="">' +
  '<title>Layer 1</title>' +
  '<path fill="#586D8C" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="round" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/>' +
  '<path fill="white" stroke="#586D8C" stroke-width="3" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355875 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z" style="color: rgb(0, 0, 0);" class=""/>' +
  '<path fill="#586D8C" stroke="#586D8C" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_7" d="M10.262040632199465,19.732118465227213 L14.94954026218473,11.528994112752926 L19.637039892170023,19.732118465227213 L10.262040632199465,19.732118465227213 z" style="color: rgb(0, 0, 0);" class="" fill-opacity="1"/></g></svg>'].join('');
export const ExhSVGWithLine =
  ['<svg xmlns="http://www.w3.org/2000/svg"' +
  ' xmlns:se="http://svg-edit.googlecode.com"' +
  ' xmlns:xlink="http://www.w3.org/1999/xlink" ' +
  'xmlns:dc="http://purl.org/dc/elements/1.1/" ' +
  'xmlns:cc="http://creativecommons.org/ns#"' +
  ' xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" ' +
  'xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" ' +
  'width="30" height="50" style="">' +
  '<g class="currentLayer" style="">' +
  '<title>Layer 1</title>' +
  '<path fill="#586D8C" fill-opacity="1" stroke="#000000" strokeopacity="1" stroke-width="2" stroke-dasharray="none" strokelinejoin="round" stroke-linecap="butt" stroke-dashoffset="" fillrule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/>' +
  '<path fill="white" stroke="#586D8C" stroke-width="3" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355875 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z" style="color: rgb(0, 0, 0);" class=""/>' +
  '<path fill="#586D8C" stroke="#586D8C" stroke-width="2" strokelinejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_7" d="M10.262040632199465,19.732118465227213 L14.94954026218473,11.528994112752926 L19.637039892170023,19.732118465227213 L10.262040632199465,19.732118465227213 z" style="color: rgb(0, 0, 0);" class="" fill-opacity="1"/>' +
  '<path fill="#586D8C" stroke="#586D8C" stroke-width="2" strokelinejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_7" d="M2.492512550697853,30 L28.162149898128664,30 z" style="color: rgb(0, 0, 0);" class="" fillopacity="1"/></g></svg>'].join('');

export const GeneralizationSVG =
  ['<svg xmlns="http://www.w3.org/2000/svg"' +
  ' xmlns:se="http://svg-edit.googlecode.com" ' +
  'xmlns:xlink="http://www.w3.org/1999/xlink"' +
  ' xmlns:dc="http://purl.org/dc/elements/1.1/" ' +
  'xmlns:cc="http://creativecommons.org/ns#"' +
  ' xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"' +
  ' xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" ' +
  'width="30" height="30" style=""> ' +
  ' <g class="currentLayer" style="">' +
  '<title>Layer 1</title>' +
  '<path fill="#586D8C" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="round" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/>' +
  '<path fill="white" stroke="#586D8C" stroke-width="3" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355875 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z" style="color: rgb(0, 0, 0);" class=""/></g></svg>'].join('');
export const GenSVGWithLine =
  ['<svg xmlns="http://www.w3.org/2000/svg"' +
  ' xmlns:se="http://svg-edit.googlecode.com" ' +
  'xmlns:xlink="http://www.w3.org/1999/xlink"' +
  ' xmlns:dc="http://purl.org/dc/elements/1.1/" ' +
  'xmlns:cc="http://creativecommons.org/ns#"' +
  ' xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"' +
  ' xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" ' +
  'width="30" height="30" style=""> ' +
  ' <g class="currentLayer" style="">' +
  '<title>Layer 1</title>' +
  '<path fill="#586D8C" fill-opacity="1" stroke="#000000" strokeopacity="1" stroke-width="2" stroke-dasharray="none" strokelinejoin="round" stroke-linecap="butt" stroke-dashoffset="" fillrule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end=""     id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/>' +
  '<path fill="white" stroke="#586D8C" stroke-width="3" strokelinejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355875 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z" style="color: rgb(0, 0, 0);" class=""/>' +
  '<path fill="white" stroke="#586D8C" stroke-width="3" strokelinejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,30 L28.162149898128664,30 " style="color: rgb(0, 0, 0);" class=""/></g></svg>'].join('');

export const InstantiationSVG =
  ['<svg xmlns="http://www.w3.org/2000/svg" ' +
  'xmlns:se="http://svg-edit.googlecode.com"' +
  ' xmlns:xlink="http://www.w3.org/1999/xlink" ' +
  'xmlns:dc="http://purl.org/dc/elements/1.1/"' +
  ' xmlns:cc="http://creativecommons.org/ns#"' +
  ' xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"' +
  ' xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" ' +
  'width="30" height="30" style=""> ' +
  '<g class="currentLayer" style="">' +
  '<title>Layer 1</title>' +
  '<path fill="#586D8C" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="round" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/>' +
  '<path fill="white" stroke="#586D8C" stroke-width="3" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355878 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z" style="color: rgb(0, 0, 0);" class=""/>' +
  '<path fill="#586D8C" fill-opacity="1" stroke="#586D8C" stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="round" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" d="M11.000000238418579,17.390625 C11.000000238418579,14.964865331491712 12.964865422411137,13 15.3906249088217,13 C17.816384395232266,13 19.78124957922482,14.964865331491712 19.78124957922482,17.390625 C19.78124957922482,19.816384668508288 17.816384395232266,21.78125 15.3906249088217,21.78125 C12.964865422411137,21.78125 11.000000238418579,19.816384668508288 11.000000238418579,17.390625 z" id="svg_11" class=""/></g></svg>'].join('');
export const InsSVGWithLine =
  ['<svg xmlns="http://www.w3.org/2000/svg" ' +
  'xmlns:se="http://svg-edit.googlecode.com"' +
  ' xmlns:xlink="http://www.w3.org/1999/xlink" ' +
  'xmlns:dc="http://purl.org/dc/elements/1.1/"' +
  ' xmlns:cc="http://creativecommons.org/ns#"' +
  ' xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"' +
  ' xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" ' +
  'width="30" height="30" style=""> ' +
  '<g class="currentLayer" style="">' +
  '<title>Layer 1</title>' +
  '<path fill="#586D8C" fill-opacity="1" stroke="#000000" strokeopacity="1" stroke-width="2" stroke-dasharray="none" strokelinejoin="round" stroke-linecap="butt" stroke-dashoffset="" fillrule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/>' +
'<path fill="white" stroke="#586D8C" stroke-width="3" strokelinejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355878 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z" style="color: rgb(0, 0, 0);" class=""/>' +
'<path fill="#586D8C" fill-opacity="1" stroke="#586D8C" strokeopacity="1" stroke-width="2" stroke-dasharray="none" strokelinejoin="round" stroke-linecap="butt" stroke-dashoffset="" fillrule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" d="M11.000000238418579,17.390625 C11.000000238418579,14.964865331491712 12.964865422411137,13 15.3906249088217,13 C17.816384395232266,13 19.78124957922482,14.964865331491712 19.78124957922482,17.390625 C19.78124957922482,19.816384668508288 17.816384395232266,21.78125 15.3906249088217,21.78125 C12.964865422411137,21.78125 11.000000238418579,19.816384668508288 11.000000238418579,17.390625 z"id="svg_11" class=""/>' +
'<path fill="#586D8C" fill-opacity="1" stroke="#586D8C" strokeopacity="1" stroke-width="2" stroke-dasharray="none" strokelinejoin="round" stroke-linecap="butt" stroke-dashoffset="" fillrule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" d="M2.492512550697853,30 L28.162149898128664,30 z" id="svg_11" class=""/></g></svg>'].join('');

export const multiplicitySpecialNotations ={
    '0..1':{
      symbol:'?',
      opl: 'an optional'
    },
    '0..*':{
      symbol:'*',
      opl: 'optional (+ plural)'
    },
    '1..1':{
      symbol:'',
      opl: ''
    },
    '1..*':{
      symbol:'+',
      opl: 'at least one'
    },

};
// these next parameters are constant button svg-s in order to use them in several html files.

// the button svg as seen on the navigator, opl sentences box etc. pointing to the left.
export const leftArrowButton =
  [ '<svg width="16" height="18" fill="none" >' + '<g opacity="0.4">' +
  '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.1579 0C13.8146 0 15.1579 1.34314 15.1579 3V15C15.1579 16.6569 13.8146 18 12.1579 18H2.99994C1.3432 18 -6.10352e-05 16.6569 -6.10352e-05 15V3C-6.10352e-05 1.34314 1.3432 0 2.99994 0H12.1579ZM12.2631 1.89478C12.8154 1.89478 13.2631 2.34247 13.2631 2.89478V15.1053C13.2631 15.6576 12.8154 16.1053 12.2631 16.1053H6.68427C6.13177 16.1053 5.68427 15.6576 5.68427 15.1053V2.89478C5.68427 2.34247 6.13177 1.89478 6.68427 1.89478H12.2631Z" fill="#1A3763" />' +
  '<path d="M10.8421 6L8 8.84211L10.8421 11.6842" stroke="#1A3763" stroke-linecap="round" stroke-linejoin="round" />' +
  '</g>' + '</svg>'].join('');

// the button svg as seen on the navigator, opl sentences box etc. pointing to the right.
export const rightArrowButton =
  [ '<svg width="16" height="18" fill="none">' + '<g opacity="0.4">' +
  '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.158 0C13.8148 0 15.158 1.34315 15.158 3V15C15.158 16.6569 13.8148 18 12.158 18H3.00006C1.34321 18 6.38962e-05 16.6569 6.38962e-05 15V3C6.38962e-05 1.34315 1.34321 0 3.00006 0H12.158ZM12.0001 2C12.5524 2 13.0001 2.44772 13.0001 3V10C13.0001 10.5523 12.5524 11 12.0001 11H3.00012C2.44784 11 2.00012 10.5523 2.00012 10V3C2.00012 2.44772 2.44784 2 3.00012 2H12.0001Z" fill="#1A3763"/>' +
  '<path d="M5.95747 4.02604L8.99989 6.47424L6.04319 9.02531" stroke="#1A3763" stroke-linecap="round" stroke-linejoin="round"/>' +
  '</g>' + '</svg>'].join('');
// the button svg as seen on the navigator, opl sentences box etc. pointing down.
export const downArrowButton =
  [ '<svg width="16" height="18" fill="none" >' + '<g opacity="0.4">' +
  '<path fill="#1A3763" fill-rule="evenodd" clip-rule="evenodd" d="M12.158 0C13.8147 0 15.158 1.34314 15.158 3V15C15.158 16.6569 13.8147 18 12.158 18H3C1.34326 18 0 16.6569 0 15V3C0 1.34314 1.34326 0 3 0H12.158ZM12 2C12.5522 2 13 2.44775 13 3V10C13 10.5522 12.5522 11 12 11H3C2.44775 11 2 10.5522 2 10V3C2 2.44775 2.44775 2 3 2H12Z" />' +
  '<path d="M4.63135 5.10528L7.47345 7.94739L10.3156 5.10528" stroke="#1A3763" stroke-linecap="round" stroke-linejoin="round"/>' +
  '</g>' + '</svg>'].join('');
// the button svg as seen on the floating navigator with "plus" sign in it.
export const plusButton =
  [ '<svg  width="22" height="22" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">' +
  '<path style="fill:white;stroke:#1A3763;stroke-width:5;opacity:0.5"' +
  'd="M 38.999, 7 H 11 c -2.25, 0, -4, 1.75, -4, 4 v 27.999 C 7, 41.249, 8.75, 43, 11, 43 h 27.999 C 41.249, 43, 43, 41.249, 43, 38.999 V 11 C 43, 8.75,   41.249, 7,   38.999, 7 z"/>' +
  '<path opacity="0.5" fill="#1A3763" d="M 35.001, 26.999 H 27 V 35 h -3.999 v -8.001  h -8.002 V 23 h 8.002 v -8 H 27 v 8 h 8.001 V 26.999 z"/>' +
  '</svg>'].join('');
// the button svg as seen on the floating navigator with "minus" sign in it.
export const minusButton =
  [ '<svg  width="22" height="22" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">' +
  '<path style="fill:white;stroke:#1A3763;stroke-width:5;opacity:0.5" ' +
  'd="M 38.999, 7 H 11 c -2.25, 0, -4, 1.75, -4, 4 v 27.999 C 7, 41.249, 8.75, 43, 11, 43 h 27.999 C 41.249, 43, 43, 41.249, 43, 38.999 V 11 C 43, 8.75,   41.249, 7,   38.999, 7 z"/>' +
  '<path opacity="0.5" fill="#1A3763" d="M 14.999,26.999 V 23 h 20.002 v 3.999 H 14.999 z"/>' +
  '</svg>'].join('');
// the handle svg as seen on the floating navigator. Combined of 4 arrows pointing outward.
export const dragHandleButton =
  [ '<svg>' +
  '<path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>' +
  '<path d="M0 0h24v24H0z" fill="none"></path>' +
  '</svg>'].join('');
// ---------------------------------- end of constant button paths
