import { OpmFundamentalLink } from '../VisualPart/OpmFundamentalLink';
import { OpmProceduralLink } from '../VisualPart/OpmProceduralLink';
import { OpmProceduralRelation } from '../LogicalPart/OpmProceduralRelation';
import { linkType } from '../ConfigurationOptions';
import { OpmVisualProcess } from '../VisualPart/OpmVisualProcess';
import { OpmVisualObject } from '../VisualPart/OpmVisualObject';
import { OpmLink } from '../VisualPart/OpmLink';
import { OpmLogicalObject } from '../LogicalPart/OpmLogicalObject';
import { OpmVisualThing } from '../VisualPart/OpmVisualThing';
import { OpmOpd } from '../OpmOpd';
import { OpmVisualEntity } from '../VisualPart/OpmVisualEntity';
import { OpmVisualState } from '../VisualPart/OpmVisualState';
import { EntityType, RelationType } from '../model/entities.enum';
import { OpmFundamentalRelation } from '../LogicalPart/OpmFundamentalRelation';
import { util } from 'jointjs';
import uuid = util.uuid;
import {OpmModel} from "../OpmModel";

export class DsmModel {

  private colorPair = [];
  private model;

  constructor(model: OpmModel) {
    this.model = model;
  }

  removeOPMQueryOPD() {
    const opm_q_id = this.getOPMQueryID();
    if (!this.model.getOpd(opm_q_id)) {
      return;
    }
    this.model.setShouldLogForUndoRedo(false, 'query');
    this.model.removeOpd(opm_q_id);
    this.model.setShouldLogForUndoRedo(true, 'query');
  }

  flatLinkIntoInzoomed(sourceElement, targetElement) {
    const currentOpd = this.model.getOpdByElement(targetElement);
    if (!currentOpd)
      return [];
    // const allLinks = currentOpd.visualElements.filter(element => element instanceof OpmLink);
    const currentThings = currentOpd.visualElements.filter(element => element instanceof OpmVisualThing);
    let currentChildrenProcesses = currentThings.filter(element => (<OpmVisualThing>element).fatherObject === (<OpmVisualThing>targetElement) && element instanceof OpmVisualProcess);
    if (currentChildrenProcesses.length === 0) currentChildrenProcesses = currentThings.filter(element => (<OpmVisualThing>element).fatherObject === (<OpmVisualThing>targetElement).id && element instanceof OpmVisualProcess);
    let establishLink = [];
    if (currentChildrenProcesses.length === 0 && targetElement.refineeInzooming !== undefined && targetElement.refineeInzooming !== targetElement && targetElement instanceof OpmVisualProcess) {
      establishLink = establishLink.concat(this.flatLinkIntoInzoomed(sourceElement, targetElement.refineeInzooming));
    } else {
      const visualsOfSourceElementOfCurrentOpd = currentOpd.visualElements.filter(element => element.logicalElement === sourceElement.logicalElement);
      if (visualsOfSourceElementOfCurrentOpd.length === 0) {
        for (let i = 0; i < currentChildrenProcesses.length; i++) {
          if (currentChildrenProcesses[i].isInzoomed()) {
            establishLink = establishLink.concat(this.flatLinkIntoInzoomed(sourceElement, (<OpmVisualThing>currentChildrenProcesses[i]).refineeInzooming));
          }
        }
      } else {
        let outboundThings = [];
        for (let i = 0; i < currentChildrenProcesses.length; i++) {
          for (let l = 0; l < visualsOfSourceElementOfCurrentOpd.length; l++) {
            outboundThings = outboundThings.concat(currentOpd.getOutboundThings(<OpmVisualThing>visualsOfSourceElementOfCurrentOpd[l]));

          }
          if ((outboundThings.indexOf(currentChildrenProcesses[i]) !== -1 || outboundThings.indexOf(targetElement) !== -1) && !currentChildrenProcesses[i].isInzoomed()) {
            establishLink = establishLink.concat(currentChildrenProcesses[i]);
          } else if ((outboundThings.indexOf(currentChildrenProcesses[i]) !== -1 || outboundThings.indexOf(targetElement) !== -1) && currentChildrenProcesses[i].isInzoomed()) {
            establishLink = establishLink.concat(this.flatLinkIntoInzoomed(sourceElement, (<OpmVisualThing>currentChildrenProcesses[i]).refineeInzooming))
          }
        }
      }
    }
    return establishLink;
  }

  flattening(leafsOnly = true): OpmOpd {
    this.removeOPMQueryOPD();
    const flatOPD = new OpmOpd('OPMQuery');
    flatOPD.id = this.getOPMQueryID();
    this.model.currentOpd = flatOPD;
    let alreadyCloned = [];
    const leaveOut = [];
    let allVisualElements = [];
    for (let i = 0; i < this.model.opds.length; i++) {
      if (this.model.opds[i].isHidden)
        continue;
      const visualEntities = this.model.opds[i].visualElements.filter(element => element instanceof OpmVisualEntity);
      const allLinks = this.model.opds[i].visualElements.filter(element => element instanceof OpmLink);
      // Push All Visuals of the OPD to AllVisualElements
      for (let j = 0; j < visualEntities.length; j++) {
        const currentVisual = visualEntities[j];
        if ((<any>currentVisual).isValueTyped && (<any>currentVisual).isValueTyped())
          continue;
        if (currentVisual instanceof OpmVisualObject && alreadyCloned.filter(element => element.original && element.original.logicalElement === currentVisual.logicalElement).length === 0) {
          if ((<OpmLogicalObject>currentVisual.logicalElement).states.length > 1) {
            const ObjFlatResult = this.flatStatefulObj(currentVisual, alreadyCloned);
            alreadyCloned = alreadyCloned.concat(ObjFlatResult[1]);
            allVisualElements = allVisualElements.concat(ObjFlatResult[0]);
          }
        } else if (((<OpmVisualObject>currentVisual) instanceof OpmVisualState && alreadyCloned.filter(element => element.original && element.original.logicalElement === currentVisual.logicalElement).length === 0)) {
          const ObjFlatResult = this.flatStatefulObj((<OpmVisualState>currentVisual).fatherObject, alreadyCloned);
          alreadyCloned = alreadyCloned.concat(ObjFlatResult[1]);
          allVisualElements = allVisualElements.concat(ObjFlatResult[0]);
          // leaveOut.push(currentVisual.logicalElement);
        }

        if ((<OpmVisualThing>currentVisual).refineeInzooming === undefined && !leaveOut.includes(currentVisual.logicalElement)) {
          let clonedVisual;
          if (alreadyCloned.filter(element => element.original && element.original.logicalElement === currentVisual.logicalElement).length === 0) {
            clonedVisual = currentVisual.clone();
            clonedVisual.fatherObject = undefined;
            allVisualElements.push(clonedVisual);
            alreadyCloned.push({ original: currentVisual, cloned: clonedVisual });
          } else {
            clonedVisual = alreadyCloned.filter(element => element.original && element.original.logicalElement === currentVisual.logicalElement)[0].cloned;
          }
          // for all my targets
          const allOutgoingLinks = allLinks.filter(link => (<OpmLink>link).sourceVisualElement === currentVisual);
          for (let link = 0; link < allOutgoingLinks.length; link++) {
            const targetVisual = (<OpmLink>allOutgoingLinks[link]).targetVisualElements[0].targetVisualElement;
            if ((<any>targetVisual).isValueTyped && (<any>targetVisual).isValueTyped())
              continue;
            if (targetVisual instanceof OpmVisualObject && alreadyCloned.filter(element => element.original && element.original.logicalElement === targetVisual.logicalElement).length === 0) {
              if ((<OpmLogicalObject>targetVisual.logicalElement).states.length > 0) {
                const ObjFlatResult = this.flatStatefulObj(targetVisual, alreadyCloned);
                alreadyCloned = alreadyCloned.concat(ObjFlatResult[1]);
                allVisualElements = allVisualElements.concat(ObjFlatResult[0]);
              }
            } else if (targetVisual instanceof OpmVisualState && alreadyCloned.filter(element => element.original && element.original.logicalElement === targetVisual.logicalElement).length === 0) {
              const ObjFlatResult = this.flatStatefulObj(targetVisual.fatherObject, alreadyCloned);
              alreadyCloned = alreadyCloned.concat(ObjFlatResult[1]);
              allVisualElements = allVisualElements.concat(ObjFlatResult[0]);
              // leaveOut.push(targetVisual.logicalElement);
            }
            if (!targetVisual.isInzoomed()) {
              let clonedTarget;
              if ((<any>targetVisual).isValueTyped && (<any>targetVisual).isValueTyped())
                continue;
              if (alreadyCloned.filter(element => element.original && element.original.logicalElement === targetVisual.logicalElement).length === 0) {
                clonedTarget = targetVisual.clone();
                clonedTarget.fatherObject = undefined;
                allVisualElements.push(clonedTarget);
                alreadyCloned.push({ original: targetVisual, cloned: clonedTarget });
              } else {
                const originalClonedPair = alreadyCloned.filter(element => element.original && element.original.logicalElement === targetVisual.logicalElement);
                clonedTarget = originalClonedPair[0].cloned;
              }
              const clonedLink = allOutgoingLinks[link].clone();
              clonedLink.sourceVisualElement = clonedVisual;
              clonedLink.targetVisualElements[0].targetVisualElement = clonedTarget;
              allVisualElements.push(clonedLink);
            } else { // The target is in-zoomed process
              let clonedChildren = [];
              if (alreadyCloned.filter(element => element.original && element.original === targetVisual).length === 0) {
                const flatProcessDetails = this.flatInzoomedThing(targetVisual, leafsOnly);
                clonedChildren = flatProcessDetails[2];
                alreadyCloned = alreadyCloned.concat(flatProcessDetails[1]);
                allVisualElements = allVisualElements.concat(flatProcessDetails[0]);
              } else {
                clonedChildren = alreadyCloned.filter(element => element.original && element.original === targetVisual)[0].cloned;
              }
              const targets = this.flatLinkIntoInzoomed(currentVisual, targetVisual);
              for (let x = 0; x < targets.length; x++) {
                const clonedTarget = alreadyCloned.filter(element => element.original && element.original === targets[x])[0].cloned;

                const params = {

                  sourceElementId: clonedVisual.id,
                  targetElementId: clonedTarget.id,
                  linkType: (<OpmProceduralRelation>allOutgoingLinks[link].logicalElement).linkType,
                  id: uuid()
                };
                const newLink = this.model.logicalFactory(RelationType.Procedural, params);
                allVisualElements.push(newLink.visualElements[0]);
              }
              // leaveOut.push(currentVisual.logicalElement);

            }
          }
        } else if (!leaveOut.includes(currentVisual.logicalElement)) {
          let clonedChildren = [];
          if (alreadyCloned.filter(element => element.original && element.original === currentVisual).length === 0) {
            const flatProcessDetails = this.flatInzoomedThing(currentVisual, leafsOnly);
            clonedChildren = flatProcessDetails[2];
            alreadyCloned = alreadyCloned.concat(flatProcessDetails[1]);
            allVisualElements = allVisualElements.concat(flatProcessDetails[0]);
          }
        }
      }
    }
    flatOPD.visualElements = allVisualElements;
    flatOPD.parendId = this.model.getOpdIDByName('SD');
    this.model.addOpd(flatOPD);
    for (const vis of flatOPD.visualElements)
      if (vis instanceof OpmVisualThing)
        flatOPD.beautify(vis);
    return flatOPD;
  }

  flatStatefulObj(statefulObject, alreadyCloned) {
    const visualElements = [];
    const cloned = []
    let clonedVisual;
    if (alreadyCloned.filter(element => element.original && element.original.logicalElement === statefulObject.logicalElement).length === 0) {
      const parameters = statefulObject.getParams();
      parameters.id = uuid();
      if (statefulObject.isValueTyped()) {
        clonedVisual = statefulObject.clone();
      } else {
        clonedVisual = statefulObject.logicalElement.createVisual(parameters) as OpmVisualObject;
        clonedVisual.children = [];
      }
      clonedVisual.fatherObject = undefined;
      clonedVisual.height = 60;
      clonedVisual.width = 135;
      clonedVisual.textHeight = '80%';
      clonedVisual.textWidth = '80%';
      clonedVisual.refY = 0.5;
      clonedVisual.refX = 0.5;
      clonedVisual.xAlign = 'middle';
      clonedVisual.yAlign = 'middle';
      clonedVisual.refineeInzooming = undefined;
      clonedVisual.refineable = undefined;
      clonedVisual.refineeUnfolding = undefined;
      clonedVisual.strokeWidth = 2;
      visualElements.push(clonedVisual);
      cloned.push({ original: statefulObject, cloned: clonedVisual });
    } else {
      clonedVisual = alreadyCloned.filter(element => element.original && element.original.logicalElement === statefulObject.logicalElement)[0];
    }
    if (statefulObject.logicalElement.states.length === 1) {
      cloned.push({ original: statefulObject.logicalElement.states[0].visualElements[0], cloned: clonedVisual });
    } else {
      for (let j = 0; j < statefulObject.logicalElement.states.length; j++) {
        let logObjectOfState;
        if (alreadyCloned.filter(element => element.original && element.original.logicalElement === statefulObject.logicalElement.states[j].logicalElement).length === 0) {
          const params = statefulObject.getParams();
          params.id = uuid();
          params.children = [];
          params.fatherObjectId = undefined;
          params.text = statefulObject.logicalElement.toggleCapitalize(statefulObject.logicalElement.states[j].text) + ' ' + statefulObject.logicalElement.text;
          logObjectOfState = this.model.logicalFactory(EntityType.Object, params) as OpmLogicalObject;
          // logObjectOfState.text = statefulObject.logicalElement.toggleCapitalize(statefulObject.logicalElement.states[j].text) + ' ' + statefulObject.logicalElement.text;
          logObjectOfState.visualElements[0].height = 60;
          logObjectOfState.visualElements[0].width = 135;
          logObjectOfState.visualElements[0].yPos = (<OpmVisualObject>statefulObject).yPos + (<OpmVisualObject>statefulObject).height + logObjectOfState.visualElements[0].height * (j) + 15 * (j + 1);
          logObjectOfState.visualElements[0].textHeight = '80%';
          logObjectOfState.visualElements[0].textWidth = '80%';
          logObjectOfState.visualElements[0].refY = 0.5;
          logObjectOfState.visualElements[0].refX = 0.5;
          logObjectOfState.visualElements[0].xAlign = 'middle';
          logObjectOfState.visualElements[0].yAlign = 'middle';
          logObjectOfState.visualElements[0].refineeInzooming = undefined;
          logObjectOfState.visualElements[0].refineable = undefined;
          logObjectOfState.visualElements[0].refineeUnfolding = undefined;
          visualElements.push(logObjectOfState.visualElements[0]);
          cloned.push({ original: statefulObject.logicalElement.states[j].visualElements[0], cloned: logObjectOfState.visualElements[0] });
          const par = {
            linkConnectionType: 1,
          };
          const newLogicLink = new OpmFundamentalRelation(par, this.model, false);
          newLogicLink.sourceLogicalElement = statefulObject.logicalElement;
          newLogicLink.targetLogicalElements = [logObjectOfState];
          newLogicLink.linkType = linkType.Generalization;
          this.model.add(newLogicLink);
          newLogicLink.visualElements[0].sourceVisualElement = clonedVisual;
          newLogicLink.visualElements[0].targetVisualElements[0].targetVisualElement = logObjectOfState.visualElements[0];
          newLogicLink.visualElements[0].id = uuid();
          visualElements.push(newLogicLink.visualElements[0]);
        } else {
          logObjectOfState = alreadyCloned.filter(element => element.original && element.original.logicalElement === statefulObject.logicalElemen.states[j].logicalElement)[0];
        }
      }
    }
    return [visualElements, cloned];
  }
  sortChildrenForParallel(unsortedChildren) {
    const children = unsortedChildren.sort((n1, n2) => n1.yPos - n2.yPos);
    const sortedChildren = []
    let parallel = [];
    for (let i = 0; i < children.length; i++) {
      if (parallel.length === 0) parallel.push(children[i]);
      if (i === children.length - 1) {
        sortedChildren.push(parallel);
        break;
      }
      if (Math.abs(children[i].yPos - children[i + 1].yPos) < 5) {
        parallel.push(children[i + 1]);
      } else {
        sortedChildren.push(parallel);
        parallel = [];
      }
    }
    return sortedChildren;
  }


  getFlatOutgoingOfLayer(arrayLine) {
    let flatOutgoingArrayLine = [];
    for (let i = 0; i < arrayLine.length; i++) {
      if (Array.isArray(arrayLine[i]) && arrayLine[i].length !== 0) {
        flatOutgoingArrayLine = flatOutgoingArrayLine.concat(this.getFlatOutgoingOfLayer(arrayLine[i][(arrayLine[i].length - 1)]));
      } else {
        flatOutgoingArrayLine.push(arrayLine[i]);
      }
    }
    return flatOutgoingArrayLine;
  }

  getFlatIngoingOfLayer(arrayLine) {
    let flatIngoingArrayLine = [];
    for (let i = 0; i < arrayLine.length; i++) {
      if (Array.isArray(arrayLine[i]) && arrayLine[i].length !== 0) {
        flatIngoingArrayLine = flatIngoingArrayLine.concat(this.getFlatIngoingOfLayer(arrayLine[i][0]));
      } else {
        flatIngoingArrayLine.push(arrayLine[i]);
      }
    }
    return flatIngoingArrayLine;
  }

  connectBetweenInzoomedThings(lineNumber, parallelSortedChildren) {
    const visualElements = [];
    const outgoingLayerThings = this.getFlatOutgoingOfLayer(parallelSortedChildren[lineNumber]);
    const ingoingLayerThings = this.getFlatIngoingOfLayer(parallelSortedChildren[lineNumber + 1]);
    for (let e = 0; e < outgoingLayerThings.length; e++) {
      if (outgoingLayerThings[e] instanceof OpmVisualProcess) {
        for (let f = 0; f < ingoingLayerThings.length; f++) {
          const params = {
            sourceElementId: outgoingLayerThings[e].id,
            targetElementId: ingoingLayerThings[f].id,
            linkType: linkType.Invocation,
            id: uuid()
          };
          const newInvocationLink = this.model.logicalFactory(RelationType.Procedural, params);
          this.model.add(newInvocationLink);
          visualElements.push(newInvocationLink.visualElements[0]);
        }
      }
      if (outgoingLayerThings[e] instanceof OpmVisualObject) {
        for (let f = 0; f < ingoingLayerThings.length; f++) {
          const params = {
            sourceElementId: outgoingLayerThings[e].id,
            targetElementId: ingoingLayerThings[f].id,
            linkType: linkType.Unidirectional,
            tag: 'precedes',
            id: uuid()
          };
          const newTaggedUnidirectionalLink = this.model.logicalFactory(RelationType.Tagged, params);
          this.model.add(newTaggedUnidirectionalLink);
          visualElements.push(newTaggedUnidirectionalLink.visualElements[0]);
        }
      }
    }
    return visualElements;
  }

  flatInzoomedThing(thing, leafsOnly = false) { // Parallel processes identified, connection between process to in-zoom of another process missing.
    let visualElements = [];
    let alreadyCloned = [];
    let clonedChildren = [];
    let currentChildren = [];
    if (currentChildren.length === 0 && (<OpmVisualThing>thing).refineeInzooming) {
      const currentThings = this.model.getOpdByThingId(((<OpmVisualThing>thing).refineeInzooming).id).visualElements.filter(element => element instanceof thing.constructor);
      currentChildren = currentThings.filter(element => (<OpmVisualThing>element).fatherObject === (<OpmVisualThing>thing).refineeInzooming);
    }
    currentChildren.sort((n1, n2) => n1.yPos - n2.yPos);
    const parallelSortedChildren = this.sortChildrenForParallel(currentChildren);
    const clonedThing = thing.clone();
    if (!leafsOnly) {
      clonedThing.fatherObject = undefined;
      alreadyCloned.push({ original: thing, cloned: clonedThing });
      visualElements.push(clonedThing);
    }
    for (let d = 0; d < parallelSortedChildren.length; d++) {
      for (let e = 0; e < parallelSortedChildren[d].length; e++) {
        if (!(<OpmVisualThing>parallelSortedChildren[d][e]).isInzoomed()) {
          const clonedChild = parallelSortedChildren[d][e].clone();
          clonedChild.fatherObject = undefined;
          clonedChildren.push(clonedChild);
          alreadyCloned.push({ original: parallelSortedChildren[d][e], cloned: clonedChild });
          visualElements.push(clonedChild);
          if (!leafsOnly) {
            const params = {
              sourceElementId: clonedThing.id,
              targetElementId: clonedChild.id,
              linkType: linkType.Aggregation,
              id: uuid()
            };
            const newExhibitionLink = this.model.logicalFactory(RelationType.Fundamental, params);
            this.model.add(newExhibitionLink);
            visualElements.push(newExhibitionLink.visualElements[0]);
          }
          parallelSortedChildren[d][e] = clonedChild;
        } else {
          const flatProcessDetails = this.flatInzoomedThing(parallelSortedChildren[d][e], leafsOnly);
          visualElements = visualElements.concat(flatProcessDetails[0]);
          alreadyCloned = alreadyCloned.concat(flatProcessDetails[1]);
          clonedChildren = clonedChildren.concat(flatProcessDetails[2]);
          const clonedChild = flatProcessDetails[3];
          const params = {
            sourceElementId: clonedThing.id,
            targetElementId: clonedChild.id,
            linkType: linkType.Aggregation,
            id: uuid()
          };
          const newExhibitionLink = this.model.logicalFactory(RelationType.Fundamental, params);
          this.model.add(newExhibitionLink);
          visualElements.push(newExhibitionLink.visualElements[0]);
          parallelSortedChildren[d][e] = flatProcessDetails[4];
        }
      }
    }
    alreadyCloned.push({ original: (<OpmVisualThing>thing).refineeInzooming, cloned: clonedChildren });
    alreadyCloned.push({ original: thing, cloned: clonedChildren });
    for (let d = 0; d < parallelSortedChildren.length - 1; d++) {
      visualElements = visualElements.concat(this.connectBetweenInzoomedThings(d, parallelSortedChildren));
    }

    return [visualElements, alreadyCloned, clonedChildren, clonedThing, parallelSortedChildren];
  }
  getCorrespondingChar(counter) {
    if (counter === -1) return '';
    // in English there are 26 letters
    let prefix = '';
    const lastChar = (counter % 26) === 0 ? 26 : (counter % 26);
    // decide how many letters will be in the name
    let numberOfChars = 1;  // A...Z
    if (counter > 26) numberOfChars++;  // AA...ZZ
    if (counter > Math.pow(26, 2) + 26) numberOfChars++; // AAA...ZZZ
    if (numberOfChars === 1) prefix = String.fromCharCode(lastChar + 64);
    if (numberOfChars === 2) {
      prefix = String.fromCharCode(Math.ceil((counter - 26) / 26) + 64) +
        String.fromCharCode(lastChar + 64);
    }
    if (numberOfChars === 3) {
      const firstDigit = Math.ceil((counter - Math.pow(26, 2) - 26) / Math.pow(26, 2));
      const firstChar = String.fromCharCode(firstDigit + 64);
      const secondDigit = Math.ceil((counter - Math.pow(26, 2) - 26 -
        (firstDigit - 1) * Math.pow(26, 2)) / 26);
      const secondChar = String.fromCharCode(secondDigit + 64);
      prefix = firstChar + secondChar + String.fromCharCode(lastChar + 64);
    }
    return prefix;
  }

  getRelationType(targetVisualObject, sourceVisualObject, flatOpd) {
    if (targetVisualObject === sourceVisualObject) return 'X';
    const allLinks = flatOpd.visualElements.filter(link => link instanceof OpmFundamentalLink || link instanceof OpmProceduralLink);
    const allIncomingLinksForTargetElement = allLinks.filter(link => (<OpmProceduralLink>link).targetVisualElements[0].targetVisualElement === targetVisualObject);
    const allConnectingLinksForBothElements = allIncomingLinksForTargetElement.filter(link => (<OpmProceduralLink>link).sourceVisualElement === sourceVisualObject);
    if (allConnectingLinksForBothElements.length < 1) {
      return '';
    } else {
      const type = (<OpmProceduralRelation>allConnectingLinksForBothElements[0].logicalElement).linkType;
      let relation = '';
      if (type === linkType.Consumption) relation = '11';
      if (type === linkType.Result) relation = '12';
      if (type === linkType.Effect) relation = '13';
      if (type === linkType.Invocation) relation = '14';
      if (type === linkType.Agent) relation = '16';
      if (type === linkType.Instrument) relation = '17';
      if (type === linkType.Aggregation) relation = '21';
      if (type === linkType.Generalization) relation = '22';
      if (type === linkType.Exhibition) relation = '23';
      if (type === linkType.Instantiation) relation = '24';
      if (type === linkType.Unidirectional) relation = '25';
      if (type === linkType.Bidirectional) relation = '26';
      if ((<OpmProceduralRelation>allConnectingLinksForBothElements[0].logicalElement).condition) relation = relation + '1';
      if ((<OpmProceduralRelation>allConnectingLinksForBothElements[0].logicalElement).event) relation = relation + '2';

      return relation;
    }
  }

  getSecondDegreeRelation(targetVisualObject, sourceVisualObject, flatOpd) {
    if (sourceVisualObject === targetVisualObject) return 'X';
    let secondDegreeRelation = '';
    const allLinks = flatOpd.visualElements.filter(link => link instanceof OpmFundamentalLink || link instanceof OpmProceduralLink);
    const allIncomingLinksForTargetElement = allLinks.filter(link => (<OpmProceduralLink>link).targetVisualElements[0].targetVisualElement === targetVisualObject);
    const allOutgoingLinksForSourceElement = allLinks.filter(link => (<OpmProceduralLink>link).sourceVisualElement === sourceVisualObject);
    for (let a = 0; a < allIncomingLinksForTargetElement.length; a++) {
      for (let b = 0; b < allOutgoingLinksForSourceElement.length; b++) {
        if (allIncomingLinksForTargetElement[a].sourceVisualElement === allOutgoingLinksForSourceElement[b].targetVisualElements[0].targetVisualElement) {
          if (targetVisualObject instanceof OpmVisualProcess) secondDegreeRelation = '14';
          if (targetVisualObject instanceof OpmVisualObject) secondDegreeRelation = '25';
          return secondDegreeRelation;
        }
      }
    }
    return secondDegreeRelation;
  }

  checkForCommonTargetWithAgentIntrumentLink(visualThingA, visualThingB, flatOpd) {
    if (visualThingA === visualThingB) return false;
    const allLinks = flatOpd.visualElements.filter(link => ((<OpmProceduralRelation>link.logicalElement).linkType === linkType.Agent
      || (<OpmProceduralRelation>link.logicalElement).linkType === linkType.Instrument));
    const allOutgoingLinksA = allLinks.filter(link => (<OpmLink>link).sourceVisualElement === visualThingA);
    const allOutgoingLinksB = allLinks.filter(link => (<OpmLink>link).sourceVisualElement === visualThingB);

    for (let i = 0; i < allOutgoingLinksA.length; i++) {
      for (let j = 0; j < allOutgoingLinksB.length; j++) {
        if (allOutgoingLinksA[i].targetVisualElements[0].targetVisualElement === allOutgoingLinksB[j].targetVisualElements[0].targetVisualElement)
          return true;
      }
    }
    return false;
  }

  createAgentInstrumentDSM(model: OpmModel) {
    const modelToAnalyze = model || this.model;
    const currOpd = modelToAnalyze.currentOpd;
    const flatOpd = model.getOpd(this.getOPMQueryID()) || this.flattening(true);
    const allLinks = flatOpd.visualElements.filter(link => ((<OpmProceduralRelation>link.logicalElement).linkType === linkType.Agent
      || (<OpmProceduralRelation>link.logicalElement).linkType === linkType.Instrument));
    const dsmElements = [];
    for (let a = 0; a < allLinks.length; a++) {
      if (!dsmElements.includes((<OpmLink>allLinks[a]).sourceVisualElement)) dsmElements.push((<OpmLink>allLinks[a]).sourceVisualElement);
    }

    const numberOfDsmElements = dsmElements.length;
    const dsmArray = [];
    for (let i = -1; i < numberOfDsmElements; i++) {
      const currentMatrixLine = [];
      if (i === -1) {
        currentMatrixLine.push('');
        currentMatrixLine.push('');
        for (let j = 0; j < numberOfDsmElements; j++) {
          currentMatrixLine.push(this.getCorrespondingChar(j + 1));
        }
      } else {
        currentMatrixLine.push((<OpmLogicalObject>dsmElements[i].logicalElement).text);
        currentMatrixLine.push(this.getCorrespondingChar(i + 1));
        for (let j = 0; j < numberOfDsmElements; j++) {
          let relationType = '';
          if (this.checkForCommonTargetWithAgentIntrumentLink(dsmElements[j], dsmElements[i], flatOpd)) {
            relationType = 'X';
          }
          if (dsmElements[j] === dsmElements[i]) relationType = this.getCorrespondingChar(i + 1);
          currentMatrixLine.push(relationType);
        }
      }
      dsmArray.push(currentMatrixLine);
    }
    this.removeOPMQueryOPD();
    modelToAnalyze.currentOpd = currOpd;

    return dsmArray;
  }

  createDsm(linkTypes, thingTypes, serverModel?) {
    const allThings = 'All things';
    const onlyProcesses = 'Processes only';
    const onlyObjects = 'Objects only';
    const allLinks = 'All links';
    const onlyProceduralLinks = 'Procedural links';
    const onlyStructuralLinks = 'Structural links';
    const modelToAnalyze = serverModel || this.model;
    const currOpd = modelToAnalyze.currentOpd;
    const flatOpd = modelToAnalyze.getOpd(this.getOPMQueryID()) || this.flattening(true);
    let dsmElements = [];
    if (thingTypes === onlyProcesses) dsmElements = flatOpd.visualElements.filter(link => link instanceof OpmVisualProcess);
    else if (thingTypes === onlyObjects) dsmElements = flatOpd.visualElements.filter(link => link instanceof OpmVisualObject);
    else dsmElements = flatOpd.visualElements.filter(link => link instanceof OpmVisualObject || link instanceof OpmVisualProcess);
    const numberOfDsmElements = dsmElements.length;
    const dsmArray = [];

    for (let i = -1; i < numberOfDsmElements; i++) {
      const currentMatrixLine = [];
      if (i === -1) {
        currentMatrixLine.push('');
        currentMatrixLine.push('');
        for (let j = 0; j < numberOfDsmElements; j++) {
          currentMatrixLine.push(this.getCorrespondingChar(j + 1));
        }
      } else {
        currentMatrixLine.push((<OpmLogicalObject>dsmElements[i].logicalElement).text);
        currentMatrixLine.push(this.getCorrespondingChar(i + 1));
        for (let j = 0; j < numberOfDsmElements; j++) {
          let relationType = '';
          if (thingTypes === allThings
            || (thingTypes === onlyProcesses && dsmElements[i] instanceof OpmVisualProcess && dsmElements[j] instanceof OpmVisualProcess)
            || (thingTypes === onlyObjects && dsmElements[i] instanceof OpmVisualObject && dsmElements[j] instanceof OpmVisualObject)) {
            relationType = this.getRelationType(dsmElements[i], dsmElements[j], flatOpd);
          }
          if (relationType === '' && !(thingTypes === allThings)) {
            relationType = this.getSecondDegreeRelation(dsmElements[i], dsmElements[j], flatOpd);
          }
          if (linkTypes === allLinks || (linkTypes === onlyProceduralLinks && relationType[0] === '1') || (linkTypes === onlyStructuralLinks && relationType[0] === '2') || relationType === 'X' || relationType === undefined) {
            if (relationType === 'X') relationType = this.getCorrespondingChar(i + 1);
            currentMatrixLine.push(relationType);
          } else {
            currentMatrixLine.push('');
          }
        }
      }
      dsmArray.push(currentMatrixLine);
    }
    this.removeOPMQueryOPD();
    modelToAnalyze.currentOpd = currOpd;

    return dsmArray;
  }

  swapRows(row1, row2, dsmArray) {
    if (row1 >= dsmArray.length || row2 >= dsmArray.length) {
      alert('One of the rows out of range!')
      return;
    }
    if (row1 === row2) return dsmArray;
    const arr = [];
    // swap the columns
    for (let i = 0; i < dsmArray.length; i++) {
      const innerArr = [];
      for (let j = 0; j < dsmArray[i].length; j++) {
        if (!(j === row1 + 1 || j === row2 + 1)) {
          innerArr.push(dsmArray[i][j]);
        } else {
          if (j === row2 + 1) {
            innerArr.push(dsmArray[i][row1 + 1]);
          } else {
            innerArr.push(dsmArray[i][row2 + 1]);
          }

        }
      }
      arr.push(innerArr);
    }
    // swap the rows
    const temp = arr[row1];
    arr[row1] = arr[row2];
    arr[row2] = temp;
    return arr;
  }

  partitioning(dsmArray) {
    let partitionedDsm = dsmArray;
    let newOrder = [];
    let foundNextElement = true;
    // Upside down, looking for elements that are independent. Repeat the search of next Element dsmArray.length times.
    for (let a = 1; a < dsmArray.length; a++) {
      if (newOrder.length === dsmArray.length - 1) break;
      const topDown = [];
      // Chose an element
      for (let i = 1; i < dsmArray.length; i++) {
        foundNextElement = true;
        if (newOrder.includes(dsmArray[i][1])) continue;
        // Go through the x-axis relations of this element
        for (let j = 2; j < dsmArray[i].length; j++) {
          if (newOrder.includes(dsmArray[j - 1][1])) continue;
          // Check if any dependencies on other elements exist
          if (dsmArray[i][j][0] === '1' || dsmArray[i][j][0] === '2') {
            foundNextElement = false;
            break;
          }
        }
        if (foundNextElement) {
          topDown.push(dsmArray[i][1]);
        }
      }
      newOrder = newOrder.concat(topDown);
    }
    const bottomUp = [];
    if (newOrder.length !== dsmArray.length - 1) {
      // Search for elements that no other element depends on.
      for (let a = 1; a < dsmArray.length; a++) {
        // Chose an element
        for (let i = 2; i < dsmArray[0].length; i++) {
          foundNextElement = true;
          if (bottomUp.includes(dsmArray[0][i])) continue;
          // Go through the y- axis relations of this element
          for (let j = 1; j < dsmArray.length; j++) {
            if (bottomUp.includes(dsmArray[j][1])) continue;
            // Check if any dependencies of other elements exist
            if (dsmArray[j][i][0] === '1' || dsmArray[j][i][0] === '2') {
              foundNextElement = false;
              break;
            }
          }
          if (foundNextElement && !newOrder.includes(dsmArray[0][i])) {
            bottomUp.push(dsmArray[0][i]);
            break;
          }
        }
      }
    }
    // Swap rows to new order
    for (let l = 0; l < newOrder.length; l++) {
      let row2;
      for (let k = 0; k < partitionedDsm.length; k++) {
        if (newOrder[l] === partitionedDsm[k][1]) row2 = k; // find position of newOrder in DSM
      }
      partitionedDsm = this.swapRows(l + 1, row2, partitionedDsm);
    }
    for (let m = 0; m < bottomUp.length; m++) {
      let row2;
      for (let n = 0; n < partitionedDsm.length; n++) {
        if (bottomUp[m] === partitionedDsm[n][1]) row2 = n; // find position of newOrder in DSM
      }
      partitionedDsm = this.swapRows(dsmArray.length - 1 - m, row2, partitionedDsm);
    }
    return partitionedDsm;
  }

  clustering(dsmArray) {
    if (dsmArray === undefined) {
      alert('Please create DSM first.')
      return;
    }
    const clusteredDSM = dsmArray;
    let bestClustering = [];
    for (let j = 1; j < dsmArray.length; j++) {
      bestClustering.push([dsmArray[j][1]]);
    }
    for (let d = 0; d < 20; d++) {
      let clusters = [];
      for (let j = 1; j < dsmArray.length; j++) {
        clusters.push([dsmArray[j][1]]);
      }
      let currentCluster;
      let previousTotalCoordinationCost = this.intraClusterCost(clusters, dsmArray) + this.extraClusterCost(clusters, dsmArray);
      let currentTotalCoordinationCost = 0;
      for (let z = 0; z < 500; z++) {//(previousTotalCoordinationCost !== currentTotalCoordinationCost){
        // previousTotalCoordinationCost = currentTotalCoordinationCost;
        const num = Math.floor(Math.random() * (clusters.length));
        currentCluster = clusters[num];
        let highestBid = 0;
        let highestCluster;
        for (let i = 0; i < clusters.length; i++) {
          const bid = this.getBid(currentCluster, clusters[i], dsmArray);
          if (bid > highestBid) {
            highestBid = bid;
            highestCluster = i;
          }
        }
        const tempClusters = [];
        tempClusters.push(currentCluster.concat(clusters[highestCluster]));
        for (let c = 0; c < clusters.length; c++) {
          if (c !== num && c !== highestCluster) {
            tempClusters.push(clusters[c]);
          }
        }
        // taskList = taskList.splice(num, 1);
        currentTotalCoordinationCost = this.intraClusterCost(tempClusters, dsmArray) + this.extraClusterCost(tempClusters, dsmArray);
        if (currentTotalCoordinationCost < previousTotalCoordinationCost) {
          clusters = tempClusters;
          previousTotalCoordinationCost = currentTotalCoordinationCost;
        }
      }
      if (this.intraClusterCost(clusters, dsmArray) + this.extraClusterCost(clusters, dsmArray) < this.intraClusterCost(bestClustering, dsmArray) + this.extraClusterCost(bestClustering, dsmArray)) {
        bestClustering = clusters;
      }
    }
    return bestClustering;
  }

  reorganizeDsmForClusters(dsmArray, bestClustering) {
    let clustering;
    let newDsm = dsmArray;
    if (bestClustering === undefined) {
      return;
    }
    clustering = bestClustering;
    let dsmOrder = [];
    for (let i = 0; i < clustering.length; i++) {
      dsmOrder = dsmOrder.concat(clustering[i]);
    }
    for (let m = 0; m < dsmOrder.length; m++) {
      let row2;
      for (let n = 0; n < newDsm.length; n++) {
        if (dsmOrder[m] === newDsm[n][1]) row2 = n; // find position of newOrder in DSM
      }
      newDsm = this.swapRows(m + 1, row2, newDsm);
    }
    return newDsm;
  }

  getThingNameFromDsmWithRowID(id, dsmArray) {
    for (let i = 1; i < dsmArray.length; i++) {
      if (dsmArray[i][1] === id) return dsmArray[i][0];
    }
  }

  getVisualOfOpdByName(opd, name) {
    for (let i = 0; i < opd.visualElements.length; i++) {
      if (opd.visualElements[i].logicalElement.text === name) return opd.visualElements[i];
    }

  }

  getRandomColor(colorNum, colors) {
    if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
    return 'hsl(' + (colorNum * (360 / colors) % 360) + ',100%,50%)';
  }

  colorClustersFlatModel(dsmArray, clusters, serverModel: OpmModel): OpmModel {
    const modelToAnalyze = serverModel || this.model;
    const flatOpd = modelToAnalyze.getOpd(this.getOPMQueryID()) || this.flattening(true);
    flatOpd.isFlatteningOpd = true;
    let color;
    for (let j = 0; j < clusters.length; j++) {
      color = this.getRandomColor(j, clusters.length);
      for (let i = 0; i < clusters[j].length; i++) {
        const visualToBeColored = this.getVisualOfOpdByName(flatOpd, this.getThingNameFromDsmWithRowID(clusters[j][i], dsmArray));
        this.addColorOfVisualThing(visualToBeColored);
        visualToBeColored.fill = color;
      }
    }
    modelToAnalyze.currentOpd = flatOpd;
    return modelToAnalyze;
  }

  colorClustersRegularModel(dsmArray, clusters) {
    let color;
    if (!clusters)
      return;
    for (let j = 0; j < clusters.length; j++) {
      color = this.getRandomColor(j, clusters.length);
      for (let i = 0; i < clusters[j].length; i++) {
        const name = this.getThingNameFromDsmWithRowID(clusters[j][i], dsmArray);
        const logical = this.model.getLogicalByText(name);
        if (logical !== undefined) {
          for (let b = 0; b < logical.visualElements.length; b++) {
            this.addColorOfVisualThing(logical.visualElements[b]);
            (<OpmVisualThing>logical.visualElements[b]).fill = color;
          }
        }
      }
    }
  }

  addColorOfVisualThing(visualThing) {
    this.colorPair.push([visualThing.id, visualThing.fill]);
  }

  colorBack() {
    for (let i = 0; i < this.colorPair.length; i++) {
      const currentVisual = this.model.getVisualElementById(this.colorPair[i][0]);
      (<OpmVisualThing>currentVisual).fill = this.colorPair[i][1];
    }
  }


  getBid(cluster1, cluster2, dsmArray) {
    const sum = this.sumInteractionBetweenClusters(cluster1, cluster2, dsmArray);
    return ((sum) / cluster1.length);
  }

  extraClusterCost(clusters, dsmArray) {
    let totalExtraClusterCost = 0;
    for (let i = 0; i < clusters.length; i++) {
      for (let j = i; j < clusters.length; j++) {
        if (clusters[i] === clusters[j]) continue;
        totalExtraClusterCost += (this.sumInteractionBetweenClusters(clusters[i], clusters[j], dsmArray) * (clusters.length ** 4));
      }
    }
    return totalExtraClusterCost;
  }

  intraClusterCost(clusters, dsmArray) {
    let totalIntraClusterCost = 0;
    for (let i = 0; i < clusters.length; i++) {
      totalIntraClusterCost += (this.sumInteractionBetweenClusters(clusters[i], clusters[i], dsmArray) * (clusters[i].length ** 2));
    }
    return totalIntraClusterCost;
  }

  sumInteractionBetweenClusters(cluster1, cluster2, dsmArray) {
    let sum = 0;
    // if (cluster1 === cluster2) return;
    for (let i = 0; i < dsmArray.length; i++) {
      if (cluster1.includes(dsmArray[i][1])) {
        for (let a = 0; a < dsmArray[i].length; a++) {
          if (cluster2.includes(dsmArray[0][a]) && (dsmArray[i][a][0] === '1' || dsmArray[i][a][0] === '2' || dsmArray[i][a][0] === 'X')) sum += 1;
        }
      }
      if (cluster2.includes(dsmArray[i][1])) {
        for (let b = 0; b < dsmArray[i].length; b++) {
          if (cluster1.includes(dsmArray[0][b]) && (dsmArray[i][b][0] === '1' || dsmArray[i][b][0] === '2' || dsmArray[i][b][0] === 'X')) sum += 1;
        }
      }
    }
    return sum;
  }

  public getOPMQueryID(): string {
    return 'OPMqUeRy';
  }

}
