import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../rappid-components/services/user.service';
import {OrganizationService} from '../../../rappid-components/services/organization.service';
import {
  OplService,
  defaultObjectStyleSettings,
  defaultProcessStyleSettings,
  StyleSettings,
  defaultRosConnectionSettings,
  defaultMqttConnectionSettings,
  ConnectionSettings,
  defaultAllowUsersConnectionSettings,
  defaultTutorialMode,
  defaultMaxUsersEnabled,
  MySQLConnectionSettings,
  defaultMySQLConnectionSettings,
  GraphDBConnectionSettings,
  defaultGraphDBConnectionSettings,
} from '../../../opl-generation/opl.service';
import {validationAlert} from '../../../configuration/rappidEnviromentFunctionality/shared';
import {Observable} from 'rxjs/Rx';
import {defaultSettings} from "../../../opl-generation/opl-database";
import {OntologyEnforcementLevel} from "../OrgOntology/ontologyInterfaces";
import {environment} from "../../../../environments/environment";
import {NewOrgUserDefaults} from "./newOrgUserDefaultsInterface";
import {defaultStateStyleSettings} from "../../../models/Defaults/style";



@Component({
  selector: 'OPCloud-Organization-Settings',
  templateUrl: './OPCloud-Organization-Settings.component.html',
  styleUrls: ['./OPCloud-Organization-Settings.component.css']
})

export class OPCloudOrganizationSettingsComponent_org implements OnInit {
  settingsForOrg = true;
  organizations$: Observable<Array<any>>;
  placeholder: string;
  selectedOrg = this.user.user.userData.organization;
  public logCollectingEnabled: boolean;
  public ignoreUserLogSharingPermission: boolean;
  public checked: boolean;
  public chatEnabled: boolean;
  public font_sizes_options = [ 8, 10, 12, 14, 16, 18, 20, 22, 24, 28, 32];
  public style: {object: StyleSettings, process: StyleSettings, state: StyleSettings};
  public connection: {ros: ConnectionSettings, mqtt: ConnectionSettings, python: ConnectionSettings, mysql: MySQLConnectionSettings, graphdb: GraphDBConnectionSettings, allow_users: boolean};
  public archive: {days_interval: number};
  public tutorialMode = defaultTutorialMode;
  public maxUsersEnabled: boolean = defaultMaxUsersEnabled;
  public maxUsersNumber: number;
  public maxViewUsersNumber: number;
  public ontologyEnforcementLevel: OntologyEnforcementLevel;
  private OntologyTypes = OntologyEnforcementLevel;
  private auth2Factors: 'disabled' | 'mandatory' | 'optional';
  private readonly isMongo: boolean;
  private defaultUserOptions: NewOrgUserDefaults;

  constructor(private oplService: OplService, private user: UserService, private orgService: OrganizationService) {
    this.isMongo = environment.serverSideAuth; // hide some part if it is mongo
    this.defaultUserOptions = {
      executionUser: false,
      dsmUser: false,
      viewer: false,
      insightsUser: false
    }
  }

  ngOnInit() {
    let user;
    if (this.user.user) {
      user = this.user.user.userData ;
    }
    if (user.SysAdmin){
      this.organizations$ = this.orgService.getOrganizations();
      this.orgService.getOrganizations().subscribe(data => {
        if (data.length > 0) {
          this.selectOrg({value: data[0].name});
        }
      });
    } else {
      this.organizations$ = Observable.of([{ id: user._organization, name: user.organization }]);
    }
    this.checked = this.oplService.orgOplSettings.SDNames;
    this.logCollectingEnabled = this.oplService.orgOplSettings.logCollectingEnabled;
    this.ignoreUserLogSharingPermission = this.oplService.orgOplSettings.ignoreUserLogSharingPermission;
    this.tutorialMode = this.oplService.orgOplSettings.tutorialMode;
    this.maxUsersEnabled = !!this.oplService.orgOplSettings.maxUsersEnabled;
    this.maxUsersNumber = this.oplService.orgOplSettings.maxUsersNumber;
    this.maxViewUsersNumber = this.oplService.orgOplSettings.maxViewUsersNumber;
    this.auth2Factors = this.oplService.orgOplSettings.auth2Factors;
    this.ontologyEnforcementLevel = this.oplService.orgOplSettings.ontologyEnforcementLevel;
    this.placeholder = (user.OrgAdmin) ? user.organization : 'Organization';
    document.getElementById('selectOPDName').addEventListener('selection', this.updateOrganizationOPDnames);
    this.style = this.oplService.orgStylingSettings();
    // this.style.object = this.oplService.orgStylingSettings()[0];
    // this.style.process = this.oplService.orgStylingSettings()[1];
    this.setColorInputChangeHandlers();
    this.connection = this.oplService.orgConnectionSettings();
    this.chatEnabled = this.oplService.orgOplSettings.chatEnabled;
    this.archive = this.oplService.orgArchiveSettings();
  }
  selectOrg(event) {
    this.selectedOrg = event.value;
    const thisProcess = this;
    this.orgService.getOrgSDNames(this.selectedOrg).then(SDNamesFlag => {
      thisProcess.checked = (SDNamesFlag === undefined || SDNamesFlag === null) ? true : SDNamesFlag;
    });

    this.orgService.getOrganization(this.selectedOrg).then(res => {
      thisProcess.loadChosenOrgStyleSettings(res);
      thisProcess.loadChosenOrgConnectionSettings(res);
      thisProcess.updateLogSharingParams(res);
      thisProcess.updateTutorialModeParam(res);
      thisProcess.updateMaxUsersParams(res);
      thisProcess.updateChatEnabledParam(res);
      thisProcess.updateDefaultUserOptions(res);
      thisProcess.auth2Factors = res.auth2Factors || 'disabled';
    });
  }

  private updateChatEnabledParam(res) {
    if (!res)
      return;
    this.chatEnabled = (res['chatEnabled'] === undefined || res['chatEnabled'] === null) ? true : res['chatEnabled'];
    this.oplService.orgOplSettings.chatEnabled = this.chatEnabled;
  }

  private updateDefaultUserOptions(res) {
    if (!res || !res['defaultUserOptions'])
      return;
    this.defaultUserOptions = res['defaultUserOptions'];
  }

  private updateMaxUsersParams(res) {
    if (!res)
      return;
    const keys = Object.keys(res);

    this.maxUsersEnabled = !!res['maxUsersEnabled'];
    this.oplService.orgOplSettings.maxUsersEnabled = this.maxUsersEnabled;

    if (keys.includes('maxUsersNumber')) {
      this.maxUsersNumber = res['maxUsersNumber'];
      this.oplService.orgOplSettings.maxUsersNumber = this.maxUsersNumber;
    }

    if (keys.includes('maxViewUsersNumber')) {
      this.maxViewUsersNumber = res['maxViewUsersNumber'];
      this.oplService.orgOplSettings.maxViewUsersNumber = this.maxViewUsersNumber;
    }
  }

  private updateLogSharingParams(res) {
    if (!res)
      return;
    const keys = Object.keys(res);
    if (keys.includes('logCollectingEnabled')) {
      this.logCollectingEnabled = res['logCollectingEnabled'];
      this.oplService.orgOplSettings.logCollectingEnabled = this.logCollectingEnabled;
    }
    if (keys.includes('ignoreUserLogSharingPermission')) {
      this.ignoreUserLogSharingPermission = res['ignoreUserLogSharingPermission'];
      this.oplService.orgOplSettings.ignoreUserLogSharingPermission = this.ignoreUserLogSharingPermission;
    }
  }

  private updateTutorialModeParam(res) {
    if (!res)
      return;
    const keys = Object.keys(res);
    if (keys.includes('tutorialMode')) {
      this.tutorialMode = res['tutorialMode'];
      this.oplService.orgOplSettings.tutorialMode = this.tutorialMode;
    }
  }

  /**
   * receives: selected organization details
   * updates current process seen style according to the selected organization
   * */
  private loadChosenOrgStyleSettings(res: any) {
    const thisProcess = this;
    let resAtKey;
    if (res) {
      // get object style settings
      Object.keys(thisProcess.style.object).forEach(function (key) {
        // if the organization data is undefined, update according to default settings
          resAtKey = (!res.style || !res.style.object || res.style.object[key] === undefined) ? defaultObjectStyleSettings[key] : res.style.object[key];
          thisProcess.style.object[key] = resAtKey;
      });
      // get process style settings
      Object.keys(thisProcess.style.process).forEach(function (key) {
        // if the organization data is undefined, update according to default settings
        resAtKey = (!res.style || !res.style.process || res.style.process[key] === undefined) ? defaultProcessStyleSettings[key] : res.style.process[key];
        thisProcess.style.process[key] = resAtKey;
      });
      // get state style settings
      Object.keys(thisProcess.style.state).forEach(function (key) {
        // if the organization data is undefined, update according to default settings
        resAtKey = (!res.style || !res.style.state || res.style.state[key] === undefined) ? defaultStateStyleSettings[key] : res.style.state[key];
        thisProcess.style.state[key] = resAtKey;
      });
    }
  }
  /**
   * receives: selected organization details
   * updates current process seen connection settings according to the selected organization
   * */
  private loadChosenOrgConnectionSettings(res: any) {
    const thisProcess = this;
    if (res) {
      thisProcess.connection.allow_users = (res.connection && (res.connection.allow_users !== undefined))
        ? res.connection.allow_users : defaultAllowUsersConnectionSettings;
      const options = ['ros', 'mqtt', 'mysql'];
      thisProcess.connection.ros = this.oplService.getRosConnectionDefaultSettings();
      thisProcess.connection.mqtt = this.oplService.getMqttConnectionDefaultSettings();
      thisProcess.connection.mysql = this.oplService.getMySQLConnectionDefaultSettings();
      for (const opt of options) {
        Object.keys(thisProcess.connection[opt]).forEach(function (key) {
          // if the organization data is undefined, keep default settings
          thisProcess.connection[opt][key] =  (!res.connection || !res.connection[opt] || res.connection[opt][key] === undefined) ? thisProcess.connection[opt][key] : res.connection[opt][key];
        });
      }
      }
  }
  updateOrganizationOPDnames(event) {
    this.checked = event.value;
    this.orgService.SDNamesUpdateForOrg(this.selectedOrg, this.checked).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {// update the current organization style settings
      this.oplService.orgOplSettings.SDNames = this.checked;
      this.oplService.updateDefaultSettings();
    }
  }
  updateLogCollectionFlag(event) {
    this.orgService.updateLogCollectionFlagForOrg(this.selectedOrg, this.logCollectingEnabled).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {// update the current organization style settings
      this.oplService.orgOplSettings.logCollectingEnabled = this.logCollectingEnabled;
      this.oplService.updateDefaultSettings();
    }
  }
  updateIgnoreUserLogSharingPermissionFlag(event) {
    this.orgService.updateIgnoreUserLogSharingPermissionFlagForOrg(this.selectedOrg, this.ignoreUserLogSharingPermission).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {// update the current organization style settings
      this.oplService.orgOplSettings.ignoreUserLogSharingPermission = this.ignoreUserLogSharingPermission;
      this.oplService.updateDefaultSettings();
    }
  }

  /** Enable/disable chat throughout the org according to settings
   * params: selectChatEnabled toggle event
   */
  updateOrganizationChatEnabled(event) {
    this.chatEnabled = event.value;
    this.orgService.chatEnabledUpdateForOrg(this.selectedOrg, this.chatEnabled).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {// update the current organization chat settings
      this.oplService.orgOplSettings.chatEnabled = this.chatEnabled;
      this.oplService.updateDefaultSettings();
    }
  }

  /** updates the organization object style settings according to the new chosen font.**/
  updateObjectFont() {
    const thisProcess = this;
    const settings = {object: {font: this.style.object.font}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update current organization style settings
        thisProcess.oplService.orgOplSettings.style.object.font = thisProcess.style.object.font;
      }
      validationAlert('saved', null, 'Success');
  });
  }
  /** updates the organization process style settings according to the new chosen font.**/
  updateProcessFont() {
      const thisProcess = this;
      const settings = {process: {font: this.style.process.font }};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update current organization style settings
          thisProcess.oplService.orgOplSettings.style.process.font = thisProcess.style.process.font;
        }
        validationAlert('saved', null, 'Success');
      });
  }

  updateStateFont() {
    const thisProcess = this;
    const settings = {state: {font: this.style.state.font }};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update current organization style settings
        thisProcess.oplService.orgOplSettings.style.state.font = thisProcess.style.state.font;
      }
      validationAlert('saved', null, 'Success');
    });
  }

  /** updates the organization object style settings according to the new chosen font size.**/
  updateObjectFontSize() {
    const thisProcess = this;
    const settings = {object: {font_size: this.style.object.font_size}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update current organization style settings
        thisProcess.oplService.orgOplSettings.style.object.font_size = thisProcess.style.object.font_size;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization process style settings according to the new chosen font size.**/
  updateProcessFontSize() {
    const thisProcess = this;
    const settings = {process: {font_size: this.style.process.font_size}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update the current organization style settings
          thisProcess.oplService.orgOplSettings.style.process.font_size = this.style.process.font_size;
        }
        validationAlert('saved', null, 'Success');
      }
    );
  }

  updateStateFontSize() {
    const thisProcess = this;
    const settings = {state: {font_size: this.style.state.font_size}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update the current organization style settings
          thisProcess.oplService.orgOplSettings.style.state.font_size = this.style.state.font_size;
        }
        validationAlert('saved', null, 'Success');
      }
    );
  }

  /** updates the organization object style settings according to the new chosen fill color.**/
  updateObjectFillColor(value) {
    this.style.object.fill_color = value;
    const thisProcess = this;
    const settings = { object: {fill_color: thisProcess.style.object.fill_color} };
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.object.fill_color = thisProcess.style.object.fill_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization process style settings according to the new chosen fill color.**/
  updateProcessFillColor(value) {
    this.style.process.fill_color = value;
    const thisProcess = this;
    const settings = { process: {fill_color: thisProcess.style.process.fill_color} };
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.process.fill_color = thisProcess.style.process.fill_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  updateStateFillColor(value) {
    this.style.state.fill_color = value;
    const thisProcess = this;
    const settings = { state: {fill_color: thisProcess.style.state.fill_color} };
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.state.fill_color = thisProcess.style.state.fill_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization object style settings according to the new chosen text color.**/
  updateObjectTextColor(value) {
    this.style.object.text_color = value;
    const thisProcess = this;
    const settings = { object: {text_color: thisProcess.style.object.text_color}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.object.text_color = thisProcess.style.object.text_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization process style settings according to the new chosen text color.**/
  updateProcessTextColor(value) {
    this.style.process.text_color = value;
    const thisProcess = this;
    const settings = { process: {text_color: thisProcess.style.process.text_color}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.process.text_color = thisProcess.style.process.text_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  updateStateTextColor(value) {
    this.style.state.text_color = value;
    const thisProcess = this;
    const settings = { state: {text_color: thisProcess.style.state.text_color}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.state.text_color = thisProcess.style.state.text_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization object style settings according to the new chosen border color.**/
  updateObjectBorderColor(value) {
    this.style.object.border_color = value;
    const thisProcess = this;
    const settings = { process: {border_color: thisProcess.style.object.border_color} };
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.object.border_color = thisProcess.style.object.border_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization process style settings according to the new chosen border color.**/
  updateProcessBorderColor(value) {
    this.style.process.border_color = value;
    const thisProcess = this;
    const settings = { process: {border_color: thisProcess.style.process.border_color}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.process.border_color = thisProcess.style.process.border_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  updateStateBorderColor(value) {
    this.style.state.border_color = value;
    const thisProcess = this;
    const settings = { state: {border_color: thisProcess.style.state.border_color}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.state.border_color = thisProcess.style.state.border_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /**
   * updating handlers for input with type color
   */
  private setColorInputChangeHandlers() {
    const thisProc = this;
    const object_fill_color = document.getElementById('object_fill_color_input') as HTMLInputElement;
    object_fill_color.addEventListener('change', function() {
      thisProc.updateObjectFillColor(this.value);
    });
    const process_fill_color = document.getElementById('process_fill_color_input') as HTMLInputElement;
    process_fill_color.addEventListener('change', function() {
      thisProc.updateProcessFillColor(this.value);
    });
    const state_fill_color = document.getElementById('state_fill_color_input') as HTMLInputElement;
    state_fill_color.addEventListener('change', function() {
      thisProc.updateStateFillColor(this.value);
    });
    const object_text_color = document.getElementById('object_text_color_input') as HTMLInputElement;
    object_text_color.addEventListener('change', function() {
      thisProc.updateObjectTextColor(this.value);
    });
    const process_text_color = document.getElementById('process_text_color_input') as HTMLInputElement;
    process_text_color.addEventListener('change', function() {
      thisProc.updateProcessTextColor(this.value);
    });
    const state_text_color = document.getElementById('state_text_color_input') as HTMLInputElement;
    state_text_color.addEventListener('change', function() {
      thisProc.updateStateTextColor(this.value);
    });
    const object_border_color = document.getElementById('object_border_color_input') as HTMLInputElement;
    object_border_color.addEventListener('change', function() {
      thisProc.updateObjectBorderColor(this.value);
    });
    const process_border_color = document.getElementById('process_border_color_input') as HTMLInputElement;
    process_border_color.addEventListener('change', function() {
      thisProc.updateProcessBorderColor(this.value);
    });
    const state_border_color = document.getElementById('state_border_color_input') as HTMLInputElement;
    state_border_color.addEventListener('change', function() {
      thisProc.updateStateBorderColor(this.value);
    });
  }
  ReturnToDefault() {
    const thisProcess = this;
    this.checked = true ; // TODO: should be according to current default settings.
    this.chatEnabled = true; // Chat is enabled by default
    this.style.process = defaultProcessStyleSettings;
    this.style.object = defaultObjectStyleSettings;
    this.style.state = defaultStateStyleSettings;
    this.connection.ros = defaultRosConnectionSettings;
    this.connection.mqtt = defaultMqttConnectionSettings;
    this.connection.mysql = defaultMySQLConnectionSettings;
    this.connection.graphdb = defaultGraphDBConnectionSettings;
    this.orgService.updateOrganization(this.selectedOrg, { style: this.style, SDNames: this.checked, chatEnabled: this.chatEnabled, connection: this.connection }).then(_ => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update the current organization style and connection settings
        thisProcess.oplService.orgOplSettings.style = thisProcess.style;
        thisProcess.oplService.orgOplSettings.connection = thisProcess.connection;
      }
      validationAlert('Returned to default settings', null, 'Success');
    });
  }
  /**
   * updates the organization ros server according to the choice
   **/
  private updatePythonServer(event) {
    const new_server = event.target.value;
    const prev_server = this.connection.python.server;
    const check_server_validity = this.serverIsValid(new_server);
    if (check_server_validity.isValid) {
      this.connection.python.server = new_server;
      const thisProcess = this;
      const settings = { ros: {server: thisProcess.connection.python.server}};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.python.server = thisProcess.connection.python.server;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      const message = 'Server can contain only numbers, dots and english letters, \n or to be in a format of an IP address.' +
        (check_server_validity.illegal_char !== undefined ? '\n The character ' + check_server_validity.illegal_char + ' cannot be used.' : '');
      validationAlert(message);
      (document.getElementById('server-Python-input') as HTMLInputElement).value = prev_server;
    }

  }

  /**
   * updates the organization ros port according to the choice
   **/
  private updatePythonPort(event) {
    const prev_port = this.connection.python.port;
    const new_port = event.target.value;
    if (this.portIsValid(new_port)) {
      this.connection.python.port = event.target.value;
      const thisProcess = this;
      const settings = {python: {port: thisProcess.connection.python.port}};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.python.port = thisProcess.connection.python.port;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-Python-input') as HTMLInputElement).value = prev_port;
    }
  }
  /**
   * updates the organization ros server according to the choice
   **/
  private updateRosServer(event) {
    const new_server = event.target.value;
    const prev_server = this.connection.ros.server;
    const check_server_validity = this.serverIsValid(new_server);
    if (check_server_validity.isValid) {
      this.connection.ros.server = new_server;
      const thisProcess = this;
      const settings = { ros: {server: thisProcess.connection.ros.server}};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.ros.server = thisProcess.connection.ros.server;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      const message = 'Server can contain only numbers, dots and english letters, \n or to be in a format of an IP address.' +
        (check_server_validity.illegal_char !== undefined ? '\n The character ' + check_server_validity.illegal_char + ' cannot be used.' : '');
      validationAlert(message);
      (document.getElementById('server-Ros-input') as HTMLInputElement).value = prev_server;
    }

  }

  /**
   * updates the organization ros port according to the choice
   **/
  private updateRosPort(event) {
    const prev_port = this.connection.ros.port;
    const new_port = event.target.value;
    if (this.portIsValid(new_port)) {
      this.connection.ros.port = event.target.value;
      const thisProcess = this;
      const settings = {ros: {port: thisProcess.connection.ros.port}};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.ros.port = thisProcess.connection.ros.port;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-Ros-input') as HTMLInputElement).value = prev_port;
    }
  }
  /**
   * updates the organization mqtt server according to the choice
   **/
  private updateMqttServer(event) {
    const new_server = event.target.value;
    const prev_server = this.connection.mqtt.server;
    const check_server_validity = this.serverIsValid(new_server);
    if (check_server_validity.isValid) {
      this.connection.mqtt.server = new_server;
      const thisProcess = this;
      const settings = {mqtt: {server: thisProcess.connection.mqtt.server}};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mqtt.server = thisProcess.connection.mqtt.server;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      const message = 'Server can contain only numbers, dots and english letters, \n or to be in a format of an IP address.' +
        (check_server_validity.illegal_char !== undefined ? '\n The character ' + check_server_validity.illegal_char + ' cannot be used.' : '');
      validationAlert(message);
      (document.getElementById('server-Mqtt-input') as HTMLInputElement).value = prev_server;
    }
  }
  /**
   * updates the organization mqtt port according to the choice
   **/
  private updateMqttPort(event) {
    const prev_port = this.connection.mqtt.port;
    const new_port = event.target.value;
    this.connection.mqtt.port = new_port;
    if (this.portIsValid(new_port)) {
      const thisProcess = this;
      const settings = {mqtt: {port: thisProcess.connection.mqtt.port}};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mqtt.port = thisProcess.connection.mqtt.port;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-Mqtt-input') as HTMLInputElement).value = prev_port;
    }
  }

  /**
   * validates the legality of the new_port: should be a number or an empty string
   * */
  private portIsValid(new_port: any) {
    return !isNaN(new_port);
  }

  /**
   * validates the legality of the new_server: should not include certain characters
   * */
  private serverIsValid(new_server: any) {
    // const illegal_chars = new RegExp('[\/|\\|:|\*|\?|\"|<|>|=|\||!]', 'i');
    // const res = new_server.search(illegal_chars);

      const legal_chars_regex = new RegExp('[^a-zA-Z0-9\.]', 'i');
      const is_legal_chars = new_server.search(legal_chars_regex);
      // const ip_address_regex = /^\d{3}\.\d{3}\.\d{3}\.\d{3}$/; // included in the first regex
      // const is_ip = ip_address_regex.test(new_server);
      return {isValid: is_legal_chars === -1,
        illegal_char: (is_legal_chars === -1) ? undefined : new_server[is_legal_chars]};
  }

  /**
   * updates the organization field for enabling/disabling users to edit their own connection settings
   **/
  public updateAllowUsers(event) {
    this.connection.allow_users = !event.checked;
    const settings = {allow_users: this.connection.allow_users};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (this.selectedOrg === this.user.user.userData.organization) { // update the current organization connection settings
        this.oplService.orgOplSettings.connection.allow_users = this.connection.allow_users;
      }
    });
  }
  /**
   * updates the organization field for automatic archive interval settings
   **/
  private updateArchiveInterval(event) {
    const prev_archiev_interval = this.archive.days_interval;
    const new_archiev_interval = event.target.value;
    this.archive.days_interval = new_archiev_interval;
    if (/^\d+$/.test(new_archiev_interval)) {
      const thisProcess = this;
      const settings = {archive: {days_interval: this.archive.days_interval}};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {
          thisProcess.oplService.orgOplSettings.archive.days_interval = thisProcess.archive.days_interval;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Archive interval should be a number or empty');
      (document.getElementById('archive-timeinterval-input') as HTMLInputElement).value = String(prev_archiev_interval);
    }
  }

  updateOrganizationTutorialMode($event) {
    const value = $event.value;
    this.orgService.tutorialModeUpdateForOrg(this.selectedOrg, value).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {// update the current organization style settings
      this.oplService.orgOplSettings.tutorialMode = value;
      this.oplService.updateDefaultSettings();
    }
  }

  updateMaxUsersEnabled($event) {
    console.log(this.maxUsersEnabled);
    this.oplService.orgOplSettings.maxUsersEnabled = this.maxUsersEnabled;
    this.orgService.maxUsersEnabledUpdateForOrg(this.selectedOrg, this.maxUsersEnabled).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {
      this.oplService.orgOplSettings.maxUsersEnabled = this.maxUsersEnabled;
    }
    if (this.maxUsersEnabled && !this.maxUsersNumber) {
      this.maxUsersNumber = 10;
      this.updateMaxUsersNumber();
    }
    if (this.maxUsersEnabled && !this.maxViewUsersNumber) {
      this.maxViewUsersNumber = 1;
      this.updateMaxViewUsersNumber();
    }
  }

  updateMaxUsersNumber() {
    if (isNaN(Number(this.maxUsersNumber)))
      return;
    this.oplService.orgOplSettings.maxUsersNumber = this.maxUsersNumber;
    this.orgService.maxUsersNumberUpdateForOrg(this.selectedOrg, this.maxUsersNumber).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {
      this.oplService.orgOplSettings.maxUsersNumber = this.maxUsersNumber;
    }
  }

  updateMaxViewUsersNumber() {
    if (isNaN(Number(this.maxViewUsersNumber)))
      return;
    this.oplService.orgOplSettings.maxEditUsersNumber = this.maxViewUsersNumber;
    this.orgService.maxViewUsersNumberUpdateForOrg(this.selectedOrg, this.maxViewUsersNumber).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {
      this.oplService.orgOplSettings.maxEditUsersNumber = this.maxViewUsersNumber;
    }
  }

  updateOntologyEnforcement() {
    if (this.selectedOrg === this.user.user.userData.organization)
      this.oplService.orgOplSettings.ontologyEnforcementLevel = this.ontologyEnforcementLevel;
    this.orgService.ontologyEnforcementLevelUpdateForOrg(this.selectedOrg, this.ontologyEnforcementLevel).then(_ => validationAlert('Saved', null, 'Success'));
  }

  updateOrgAuth2Factors($event) {
    if (this.selectedOrg === this.user.user.userData.organization) {
      this.oplService.orgOplSettings.auth2Factors = this.auth2Factors;
    }
    this.orgService.auth2FactorsUpdateForOrg(this.selectedOrg, this.auth2Factors).then(_ => validationAlert('Saved', null, 'Success'));
  }

  toggleDefaultUserOption(val) {
    this.defaultUserOptions[val] = !this.defaultUserOptions[val];
    this.onDefaultUserOption();
  }

  onDefaultUserOption() {
    this.orgService.updateDefaultUserOptions(this.selectedOrg, this.defaultUserOptions).then(_ => validationAlert('Saved', null, 'Success'));
  }

  onDefaultUserOptionSelection($event) {
    $event.stopPropagation();
  }
}
