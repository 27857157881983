import { ErrorHandler, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { SharedModule } from './shared/shared.module';
import { FormsModule } from '@angular/forms';

import { OpcRoutingModule } from './app-routing.module';
import { LoadModelDialogComponent } from '../dialogs/load-model-dialog/load-model-dialog.component';
import { RenameDialogComponent } from '../dialogs/load-model-dialog/sub-dialogs/rename/rename-dialog.component';
import { SettingsNewUser } from './Settings/user-mgmt/new-user/new-user.component';
import { TreeViewComponent_user } from './Settings/tree-view/TreeView.component_user';
import { SettingsOplGroupComponent } from './Settings/settings-opl-group.component';
import { SettingsUnderConstruction } from './Settings/settings.under_constractions';
import { SettingsUpdateOrgUserDetails } from './Settings/user-mgmt/update-user-details/update-org-users-details';
import { OrgAdminComponent } from './Settings/organization-mgmt/org-admin/org-admin.component';
import { UserDetailsComponent } from './Settings/user-mgmt/update-user-details/update-user-details.component';
import { UserGroupsComponent } from './Settings/group-mgmt/update-user-grp/update-user-grp.component';
import { GroupAdministrationComponent } from './Settings/group-mgmt/group-administration/group-administration.component';
import { DeleteOrgComponent } from './Settings/organization-mgmt/delete-org/delete-org.component';
import { SetAdminUserListComponent } from '../dialogs/set-admin-user-list/set-admin-user-list.component';
import { GroupsService } from '../rappid-components/services/groups.service';
import { StorageService } from '../rappid-components/services/storage.service';
import { AuthenticationService } from '../rappid-components/services/authentication/authentication.service';
import { OrganizationService } from '../rappid-components/services/organization.service';
import { UpdateUserDialogComponent } from '../dialogs/update-user-dialog/update-user-dialog.component';
import { ActivateUserDialogComponent } from '../dialogs/activate-user-dialog/activate-user-dialog.component';
import { CanActivateViaAuthGuard } from './CanActivateViaAuthGuard';
import { FirebaseAuthDriver } from '../database/firebaseAuthDriver';
import { ModelStorageService } from '../rappid-components/services/storage/model-storage.service';
import { UserService } from '../rappid-components/services/user.service';
import { DatabaseService } from '../database/database.service';
import { AddGroupComponent } from './Settings/group-mgmt/add-group/add-group.component';
import { ChangePasswordDialogComponent } from '../dialogs/change-password-dialog/change-password-dialog.component';
import { UserAgreementComponent } from '../dialogs/UserAgreement/user-agreement.component';
import { MainLoginComponent } from './MainLogin.component';
import { SettingsComponent } from './Settings/settings.component';
import { OplDialogComponent, KeysPipe } from '../dialogs/opl-dialog/opl-dialog.component';
import { OplDialogComponentSysAdmin } from '../dialogs/opl-dialog/opl-dialog-SysAdmin';
import { SettingsHome } from './Settings/settings-home/settings-home';
import { MemberGrpComponent } from './Settings/group-mgmt/update-user-grp/member-grp/member-grp.component';
import { ModelAnalysisComponent } from './Settings/model-analysis/model-analysis.component';
import { ListThingsComponent } from './Settings/list-things/list-things.component';
import { SettingsNewOrg } from './Settings/organization-mgmt/new-org/new-org.component';
import { SignInComponent } from './layout/header/sign-in/sign-in.component';
import { PermissionsService } from '../rappid-components/services/permissions/permissions.service';
import { InputNameDialogComponent } from '../dialogs/input-name-dialog/input-name-dialog';
import { AboutDialogComponent } from '../dialogs/About/about';
import { NewModelComponent } from '../dialogs/new-model-dialog/new-model-dialog';
import { SaveScreenshotComponent } from '../dialogs/saveScreenshot-dialog/saveScreenshot';
import { ChooseExportedFileNameComponent } from '../dialogs/choose-exported-file-name/choose-exported-file-name';
import { UploadFile } from '../dialogs/FileUploader/FileUploader';
import { FileSelectDirective } from 'ng2-file-upload';
// import { NgProgressModule } from 'ngx-progressbar';
import { OpcAppResolver } from '../database/resolvers/opc.resolver';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '../database/authInterceptor';
import { ServerDatabaseDrive } from '../database/ServerDatabaseDrive';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { DsmAnalysisComponent } from './Settings/dsm-analysis/dsm-analysis.component';
import { OPCloudManualComponent } from './Settings/OPCloudManual/opcloud-manual.component';

import { environment } from '../../environments/environment';
import { SendEmailComponent } from './Settings/organization-mgmt/send-email/send-email.component';
import { SavePdfComponent } from '../dialogs/savePdf-dialog/savePdf';
import { OpcVersionService } from '../rappid-components/services/init/opc.init.service';
import { AbstractVersionService } from '../rappid-components/services/init/abstract.init.service';
import { AddUserComponent } from '../dialogs/add-users-excel/add-users.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OplSettingsComponent } from './Settings/OplSettings/opl-settings.component';
import { OpcloudSettingsComponent } from './Settings/OpCloudSettings/opcloud-settings.component';
import { SaveURLComponent } from "../dialogs/saveURL-dialog/saveURL";
import { OPCloudOrganizationSettingsComponent_org } from "./Settings/OPCloud-Organization-Settings/OPCloud-Organization-Settings.component_org";
import { OPCloudOrganizationSettingsComponent_user } from "./Settings/OPCloud-Organization-Settings/OPCloud-Organization-Settings.component_user";
import { StereotypesDialogComponent } from "../dialogs/stereotypes-dialog/stereotypes-dialog.component";
import { GreyItemsDialogComponent } from '../dialogs/grey-items-dialog/grey-items-dialog.component';
import { ContextService } from './app/context.service';
import { SimulationElementComponent } from "../dialogs/simulationElement/SimulationElement";
import { SimulationComponent } from "../dialogs/Simulation/Simulation";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { multiInstancesDialog } from "../dialogs/multi-instances-dialog/multi-instances-dialog";
import { paretoFrontierDialog } from "../dialogs/pareto-frontier-dialog/pareto-frontier-dialog";
import { RemoveUserComponent } from '../dialogs/remove-user/remove-user.component';
import {DeleteModelDialogComponent} from "../dialogs/load-model-dialog/sub-dialogs/removeModel/delete-model-dialog.component";
import {CreateViewDialog} from "../dialogs/create-view-dialog/create-view-dialog";
import {StyleCopyingDialogComponent} from "../dialogs/styleCopyingDialog/styleCopyingDialog.component";
import {DownloadCSVComponent} from "../dialogs/DownloadCSV/DownloadCSV";
import { LogsDashboardDialogComponent } from '../dialogs/logs-dashboard-dialog/logs-dashboard-dialog.component';
import { LogsDashboardComponent } from '../dialogs/logs-dashboard/logs-dashboard.component';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { LogSharingDialogComponent } from "../dialogs/log-sharing-dialog/log-sharing-dialog.component";
import { GlobalErrorHandler } from '../error-handler/global-error-handler';
import {ValidationSettingsComponent} from "../dialogs/validation-settings/validation-settings";
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { MsalAuthDriver } from '../database/msalAuth';
import {SSOSettingsComponent} from "./Settings/organization-mgmt/sso/sso-settings";
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory
} from "../configuration/ssoConfigurations/ssoConfigurations";
import {HeadlessRunnerComponent} from "../dialogs/headlessRunner-dialog/headlessRunner-dialog.component";
import {EnterValueDialogComponent} from "../dialogs/enterValue/enter-value";
import {ChatComponentFloat} from "./layout/opcloud-chat-component-float/chat.component.float";
import {ChatComponentPanel} from "./layout/opcloud-chat-component-panel/chat.component.panel";
import {MatBadgeModule} from "@angular/material/badge";
import {CodeEditorDialog} from "../dialogs/code-editor-dialog/code-editor-dialog";
import {PythonCodeEditorDialog} from "../dialogs/python-code-editor-dialog/python-code-editor-dialog";
import {CodeEditorModule} from "@ngstack/code-editor";
import {FolderPermissionsDialogComponent} from "../dialogs/folder-permissions-dialog/folder-permissions-dialog/folder-permissions-dialog.component";
import {OpdtreeModelSettingsComponent} from '../dialogs/opdtree-model-settings/opdtree-model-settings.component';
import {OrganizationOntologyComponent} from "./Settings/OrgOntology/Organization-Ontology.component";
import {OntologySuggestionDialog} from "../dialogs/ontologySugesstionDialog/ontology-suggestion-dialog";
import {NlpModelAnalysisComponent} from "./Settings/nlp-model-analysis/nlp-model-analysis.component";
import {TemplatesComponent} from "../dialogs/templates-import/templates-import";
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import {ModelAnalysisToolsComponent} from './Settings/model-analysis-tools/model-analysis-tools.component';
import {OPCloudPrivacyPolicyComponent} from "./Settings/OPCloudPrivacyPolicy/opcloud-privacy-policy.component";
import {FindUserAdminComponent} from "../dialogs/find-user-admin/find-user-admin.component";
import {ResetInitialPasswordComponent} from "../dialogs/reset-initial-password/reset-initial-password.component";
import {CreateRequirementViewDialog} from "../dialogs/create-requirement-view-dialog/create-requirement-view-dialog";
import {MAT_LEGACY_CHIPS_DEFAULT_OPTIONS as MAT_CHIPS_DEFAULT_OPTIONS} from "@angular/material/legacy-chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {ImagesPoolManagementComponent} from "../dialogs/images-pool-management/images-pool-management-component";
import {ImagesPoolContainer} from "../dialogs/images-pool-container/images-pool-container";
import {ServerAuthDriver} from "../database/serverAuthDriver";
import {CreatePopupMessagesComponent} from "../dialogs/create-popup-message/create-popup-message.component";
import {PopupMessagesComponent} from "./Settings/PopupMessages/popup-messages.component";
import {PopupMessageContentComponent} from "../dialogs/popup-message-content/popup-message-content.component";
import {PopupViewsOrConfirmsComponent} from "../dialogs/popup-views-or-confirms/popup-views-or-confirms.component";
import {Set2AuthFactorsDialog} from "../dialogs/set-2-auth-factors/set-2-auth-factors-dialog";
import {SubModelNameComponent} from "../dialogs/submodel-name-dialog/submodel-name-dialog";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {EnterEmailVerificationCodeDialog} from "../dialogs/enter-email-verification-code-dialog/enter-email-verification-code-dialog";
import {OrganizationAnalyticsComponent} from "./Settings/organization-analytics/organization-analytics.component";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";

@NgModule({
    imports: [
        environment['serverSideAuth'] ? [] : AngularFireModule.initializeApp(environment.firebaseCredentials),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        CommonModule,
        TreeModule,
        SharedModule,
        FormsModule,
        OpcRoutingModule,
        DragDropModule,
        MatSlideToggleModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MsalModule,
        MatBadgeModule,
        CodeEditorModule,
        MatTabsModule,
        MatProgressBarModule,
        MatRadioModule,
    ],
    declarations: [
        MainLoginComponent,
        SettingsComponent,
        LoadModelDialogComponent,
        HeadlessRunnerComponent,
        RenameDialogComponent,
        SubModelNameComponent,
        DeleteModelDialogComponent,
        SendEmailComponent,
        UserGroupsComponent,
        UserDetailsComponent,
        SettingsUpdateOrgUserDetails,
        OrgAdminComponent,
        SSOSettingsComponent,
        SettingsUnderConstruction,
        SettingsOplGroupComponent,
        // AutosaveComponent,
        TreeViewComponent_user,
        // TreeViewComponent_org,
        OPCloudOrganizationSettingsComponent_org,
        OPCloudOrganizationSettingsComponent_user,
        OplDialogComponent,
        OplDialogComponentSysAdmin,
        KeysPipe,
        SettingsHome,
        MemberGrpComponent,
        ModelAnalysisComponent,
        NlpModelAnalysisComponent,
        ModelAnalysisToolsComponent,
        ListThingsComponent,
        SettingsNewUser,
        GroupAdministrationComponent,
        DeleteOrgComponent,
        SetAdminUserListComponent,
        FindUserAdminComponent,
        UpdateUserDialogComponent,
        ActivateUserDialogComponent,
        AddGroupComponent,
        ChangePasswordDialogComponent,
        UserAgreementComponent,
        ResetInitialPasswordComponent,
        // SettingsScriptsComponent,
        SettingsNewOrg,
        SignInComponent,
        AboutDialogComponent,
        InputNameDialogComponent,
        EnterValueDialogComponent,
        NewModelComponent,
        SaveScreenshotComponent,
        ChooseExportedFileNameComponent,
        UploadFile,
        SavePdfComponent,
        SaveURLComponent,
        AddUserComponent,
        DsmAnalysisComponent,
        OPCloudManualComponent,
        OPCloudPrivacyPolicyComponent,
        OplSettingsComponent,
        OpcloudSettingsComponent,
        StereotypesDialogComponent,
        GreyItemsDialogComponent,
        SimulationElementComponent,
        SimulationComponent,
        multiInstancesDialog,
        paretoFrontierDialog,
        RemoveUserComponent,
        CreateViewDialog,
        CreateRequirementViewDialog,
        LogsDashboardComponent,
        LogsDashboardDialogComponent,
        LogSharingDialogComponent,
        StyleCopyingDialogComponent,
        DownloadCSVComponent,
        ValidationSettingsComponent,
        CodeEditorDialog,
        PythonCodeEditorDialog,
        FolderPermissionsDialogComponent,
        OpdtreeModelSettingsComponent,
        OrganizationOntologyComponent,
        OrganizationAnalyticsComponent,
        OntologySuggestionDialog,
        TemplatesComponent,
        ImagesPoolManagementComponent,
        ImagesPoolContainer,
        CreatePopupMessagesComponent,
        PopupMessagesComponent,
        PopupMessageContentComponent,
        PopupViewsOrConfirmsComponent,
        Set2AuthFactorsDialog,
        EnterEmailVerificationCodeDialog
    ],
    providers: [
        AuthenticationService,
        DatabaseService,
        { provide: 'DatabaseDriver', useClass: ServerDatabaseDrive },
        { provide: 'AuthenticationDriver', useClass: environment['serverSideAuth'] ? ServerAuthDriver : FirebaseAuthDriver },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        FirebaseAuthDriver,
        MsalAuthDriver,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        UserService,
        ModelStorageService,
        ContextService,
        StorageService,
        OrganizationService,
        GroupsService,
        CanActivateViaAuthGuard,
        PermissionsService,
        OpcAppResolver,
        DsmAnalysisComponent,
        OPCloudManualComponent,
        OPCloudPrivacyPolicyComponent,
        { provide: AbstractVersionService, useClass: OpcVersionService },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: { separatorKeyCodes: [ENTER, COMMA] } }
    ]
})
export class OpcModule {
}
