import {
  Component, Optional,
} from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import * as XLSX from 'xlsx';
import { OrganizationService } from '../../rappid-components/services/organization.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogDialogComponent } from '../confirm-dialog/confirm-dialog';
import {UserService} from '../../rappid-components/services/user.service';
const parseString = require('xml2js').parseString;

@Component({
  selector: 'add-user',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.css']
})
export class AddUserComponent {

  uploader: FileUploader = new FileUploader({}); // Empty options to avoid having a target URL
  reader: FileReader = new FileReader();
  XML: XMLDocument;
  importExcel = true;
  imported = false;
  uploaded = false;
  errorMessage = false;
  log = '';
  now = new Date();

  public errors: Array<string>;

  private readonly EMAIL = 'email';
  private readonly FNAME = 'name';
  private readonly PASS = 'password';
  private readonly PASS_REP = 'repeat password';
  private readonly ORG = 'organization';

  private users = new Array();

  constructor(@Optional() public dialogRef: MatDialogRef<ConfirmDialogDialogComponent>,
    private readonly organization: OrganizationService, private readonly currUser: UserService) {
  }

  arrayBuffer: any;
  file: File;

  incomingfile(event) {
    this.file = event.target.files[0];
    this.uploaded = true;
  }

  excelFileReader(): Promise<any> {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        const data = new Uint8Array(this.arrayBuffer);
        const arr = new Array();
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const bstr = arr.join('');
        const workbook = XLSX.read(bstr, { type: 'binary' });
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        if (this.excelFormatIsValid(worksheet)) {// includes all columns
          resolve(XLSX.utils.sheet_to_json(worksheet, { raw: false }));
        } else {
          reject('Wrong format of file. The file headers should be: name, email, password, repeat password and organization.');
        }
      };
      fileReader.readAsArrayBuffer(this.file);
    });
  }

  private excelFormatIsValid(worksheet) {
    const validColumnNames = ['name', 'email', 'password', 'repeat password', 'organization', 'expiration'];
    const cells = Object.keys(worksheet);
    const colNames: Array<String> = [];
    const firstLineRegex = /^[A-Z]+1$/;
    for (let i = 0; i < Object.keys(cells).length; i++) {
      if (firstLineRegex.test(cells[i])) { // first line
        colNames.push(worksheet[cells[i]].v);
      }
    }
    if (colNames.length !== validColumnNames.length) {
      return false;
    } else {
      for (let i = 0; i < colNames.length ; i++) {
        if (colNames[i].toLowerCase() !== validColumnNames[i]) {
          return false;
        }
      }
      return true;
    }
  }

  async upload() {
    let errors_set = new Set<string>();
    try {
      const users = await this.excelFileReader();
      // converting legal keys to lower case if needed
      users.map(user => {
        Object.keys(user).forEach(key => {
          if (key !== key.toLowerCase() ) {
            user[key.toLowerCase()] = user[key];
            delete user[key];
          }
          if (key === 'expiration') {
            let expDate = new Date(user[key]).getTime();
            if (isNaN(expDate) || expDate < (new Date().getTime()))
              expDate = new Date().getTime() + 5184000000;
            user['exp_date'] = expDate;
            delete user[key];
          }
      });
      if (!user['exp_date'])
        user['exp_date'] = new Date().getTime() + 5184000000;
      });
      this.errors = [];
      const emails = [];

      let line = 1;

      for (const user of users) {

        const email = user[this.EMAIL];
        const name = user[this.FNAME];
        const password = user[this.PASS];
        const reapestPassword = user[this.PASS_REP];
        const org = user[this.ORG];

        if (!email || !name || !password || !reapestPassword || !org) {
          errors_set.add('Missing field in excel file you uploaded in line: ' + (line));
        }
        if ((this.emailIsValid(email)) === false) {
          errors_set.add('You have entered an invalid email address in line: ' + (line) + '.');
        }
        if (password && password.length < 6) {
          errors_set.add('You have entered an invalid password in line: ' + (line) + '.');
        }
        if (password && reapestPassword && password !== reapestPassword) {
          errors_set.add('You have entered an invalid re-password in line: ' + (line) + '.');
        }
        if (!this.currUser.isSysAdmin() && this.currUser.isOrgAdmin() && org !== this.currUser.userOrg) {
          errors_set.add('You have tried to add users to ' + org + ' in line: ' + (line) + ' but this is not your organization.');
        }
        const email_possible_error = 'You have inserted the email ' + email + ' more than once.';
        if (email && emails.includes(email) && !errors_set.has(email_possible_error)) {
          errors_set.add(email_possible_error);
        } else {
          emails.push(email);
        }
        line++;
      }

      // commented to see some errors from the server
      // if (errors.length > 0) {
      //   return;
      // }

      this.organization.validateUsers(users).then(res => {
        if (res.success && errors_set.size === 0) {
          this.imported = true;
          this.users = users;
          return;
        }
        if (res.errors) {
          res.errors.forEach( error => {
            const isOrgMissing = error.lastIndexOf(': organization does not exist');
            if (errors_set.size === 0 || error.lastIndexOf(': email exists') !== -1 || isOrgMissing !== -1) {
              // the rest of the server side data checks were also checked here
              errors_set.add(error);
              if (isOrgMissing !== -1) {
                const missingOrg = error.substring(0, isOrgMissing);
                // if an organization does not exist only messages about non existing regarding that organization should appear
                errors_set.forEach(errorMsg => {
                  if (errorMsg.lastIndexOf('You have tried to add users to ' + missingOrg ) !== -1 ) {
                    errors_set.delete(errorMsg);
                  }});
              }
            }
          });
        }
        this.errors = [...errors_set];
      }).catch( err => this.errors = [err.message]);
    } catch (e) { // suppose to be an error regarding the file headers
      errors_set.add(e);
      this.errors = [...errors_set];
      return ;
    }
  }

  public send() {
    this.organization.addUsers(this.users)
      .then(res => {
        if (res.success) {
          alert('Added successefuly');
          this.dialogRef.close();
          return;
        }

        this.errors = res.errors;
      }).catch(err => this.errors = [err.message]);
  }

  emailIsValid(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  nameIsValid(name: string): boolean {
    return /^[ a-zA-Z]+$/.test(name);
  }

}

