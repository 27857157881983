import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RenameServerResponse } from '../../../../rappid-components/services/server-response/rename-response';
import { StorageService } from '../../../../rappid-components/services/storage.service';
import { DisplayModel, DisplayFolder } from '../../../../rappid-components/services/storage/model-storage.interface';
import { ModelTitleValidator } from '../../name-validator';
import {RemoveModelServerResponse} from "../../../../rappid-components/services/server-response/removeModelServerResponse";
import {ContextService} from "../../../../modules/app/context.service";
import {validationAlert} from "../../../../configuration/rappidEnviromentFunctionality/shared";

interface DELETE_MODEL_DIALOG_INPUT {
  model: DisplayModel,
  folder: DisplayFolder,
  sysExamples?: boolean,
  globalTemplates?: boolean
}

@Component({
  selector: 'rename-dialog',
  templateUrl: './delete-model-dialog.component.html',
  styleUrls: ['./delete-model-dialog.component.css']
})
export class DeleteModelDialogComponent implements OnInit {

  public title: string;
  public error: string;

  constructor(
    private readonly dialogRef: MatDialogRef<DeleteModelDialogComponent>,
    private readonly storage: StorageService,
    private contextService: ContextService,
    @Inject(MAT_DIALOG_DATA) private readonly data: DELETE_MODEL_DIALOG_INPUT
  ) {
  }

  ngOnInit() {
    this.title = this.data.model.title;
  }

  public removeModel() {
    if (this.contextService.getTabs().find(t => (<any>t.context).properties?.id === this.data.model.id)) {
        validationAlert('Cannot remove an open model.');
        this.cancel();
        return;
    }
    this.finish(this.storage.removeModel(this.data.model.id, !!this.data.sysExamples, !!this.data.globalTemplates));
  }

  private finish(p: Promise<RemoveModelServerResponse>) {
    p.then(res => {
      if (res && res.removed)
        this.dialogRef.close({ removed: true });
      else
        this.error = res.message;
    }).catch(err => this.error = 'error has occurred');
  }

  cancel() {
    this.dialogRef.close({ removed: false });
  }

}
