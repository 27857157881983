import { Injectable } from '@angular/core';
import { DatabaseService } from '../../database/database.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { random } from 'lodash';
import { ErrorLog } from '../../error-handler/error-log.interface';
import {OntologyEnforcementLevel, OntologyItem} from "../../modules/Settings/OrgOntology/ontologyInterfaces";
import {NewOrgUserDefaults} from "../../modules/Settings/OPCloud-Organization-Settings/newOrgUserDefaultsInterface";

export interface OrgPayload {
  org?: {
    Name: string,
    Admin_Mail: string,
  };
}

export interface Organization {
  id: string;
  name: string;
  flag: boolean;
}

@Injectable()
export class OrganizationService {
  // orgToDelete = '';
  orgGroups = [];
  orgAdminList = [];
  //organizations: Array<string>; // This element should contain all information.
  orgGroupName = ' All Users';
  private readonly organizations: ReplaySubject<Array<String>> = new ReplaySubject<Array<String>>(1);
  public readonly organizations$: Observable<Array<String>> = this.organizations.asObservable();

  private readonly allOrganizations: ReplaySubject<Array<String>> = new ReplaySubject<Array<String>>(1);
  public readonly allOrganizations$: Observable<Array<String>> = this.allOrganizations.asObservable();

  constructor(private dbService: DatabaseService) {
  }

  private fetch(): void {
    this.dbService.driver.getOrganizations().then((result) => {
      this.organizations.next(result);
    });
  }

  getOrganizationAnalytics(orgName: string) {
    return this.dbService.driver.getOrganizationAnalytics(orgName);
  }

  createOrganization(organization) {
    return this.dbService.driver.createOrganization(organization)
      .then(() => this.addGroup(organization.Name, this.getOrgGroupName(organization.Name), '', '', ''));
  }

  getOrganizations(): Observable<Array<any>> {
    this.fetch();
    return this.organizations$;
  }

  getAllOrgs() {
    return this.dbService.driver.getAllOrganizations();
  }

  getAllOrganizations(): Observable<Array<any>> {
    this.dbService.driver.getAllOrganizations().then((orgs) => {
      this.allOrganizations.next(orgs);
    });
    return this.allOrganizations$;
  }

  getOrganizationUsers(organization): Promise<Array<any>> {
    return this.dbService.driver.getOrganizationUsers(organization);
  }

  getOrganizationUsersData(organization): Promise<Array<any>> {
    return this.dbService.driver.getOrganizationUsersData(organization);
  }

  getOrganizationGroups(organization): Promise<Array<any>> {
    return this.dbService.driver.getOrganizationGroups(organization);
  }
  setOrgAdmins(org) {
    this.orgAdminList = [];
    this.getOrganizationUsersData(org).then(usersList => {
      const users = usersList;
      users.forEach(user => {
        if (user.val.OrgAdmin) {
          this.orgAdminList.push(user.key);
        }
      });
    });
  }
  getOrgGroupName(org) {
    return org + this.orgGroupName;
  }

  deleteOrganization(org: string): Promise<void> {
    return this.dbService.driver.deleteOrganization(org);
  }

  /**
   * Alon: gets ref object and passes it to be itterated and stored in a class member array
   * @param org
   * @returns {firebase.Promise<any>}
   */

  getGroups(org) {
    return this.dbService.driver.getOrganizationGroups(org);
  }
  deleteGroups(org, groupKey, groupParent, groupName) {
    return this.dbService.driver.deleteGroupg(org, groupKey, groupParent, groupName);
  }
  deleteSubgroup(org, groupKey, groupParent, groupName) {
    return this.dbService.driver.deleteSubgroup(org, groupKey, groupParent, groupName);
  }
  /*returnCurCounter(org) {
    return this.dbService.driver.returnCurCounter(org);
  }*/
  addUserToAdminGroup(org, groupID, user) {
    return this.dbService.driver.addUserToAdminGroup(org, groupID, user);
  }

  delUserFromGroup(org, groupID, user) {
    return this.dbService.driver.delUserFromGroup(org, groupID, user);
  }

  delAdminGroupUser(org, groupID, user) {
    return this.dbService.driver.delAdminGroupUser(org, groupID, user);
  }

  addMultipleMembers(usersAraay, group, org) {
    return this.dbService.driver.addMultipleMembers(usersAraay, group, org);
  }

  addMembers(user, groupID, org) {
    return this.dbService.driver.addMember(user, groupID, org);
  }

  addGroup(org, nameOfGroup, descriptionOfGroup, parent, parentName) {
    return this.dbService.driver.addGroup(org, nameOfGroup, descriptionOfGroup, parent, parentName);
  }

  getGroupFromOrg(organization: string, groupID: string) {
    return this.dbService.driver.getGroupFromOrg(organization, groupID);
  }

  deleteUserCanBeAutoTrasfered(user_id: string): Promise<boolean> {
    return this.dbService.driver.deleteUserCanBeAutoTrasfered(user_id).then(ret => ret.auto);
  }

  deleteUser(user_id: string, params: { action: 'auto' | 'remove' | 'transfer', transfer_to?: string }): Promise<any> {
    return this.dbService.driver.deleteUser(user_id, params);
  }

  getOrganization(orgName) {
    return this.dbService.driver.getOrganization(orgName);
  }

  updateOrganization(orgName, settings) {
    return this.dbService.driver.updateOrganization(orgName, settings);
  }

  updateGroupName(org, groupID, newName) {
    return this.dbService.driver.updateGroupName(org, groupID, newName);
  }

  updateGroupDescription(org, groupID, NewDescription) {
    return this.dbService.driver.updateGroupDescription(org, groupID, NewDescription);
  }

  activateOrDeactivateOrg(org, activeFlag) {
    return this.dbService.driver.activateOrDeactivateOrg(org, activeFlag);
  }

  updateOrgName(oldName, newName) {
    return this.dbService.driver.updateOrgName(oldName, newName);
  }

  isActiveOrg(org) {
    return this.dbService.driver.isActiveOrg(org);
  }

  updateIgnoreUserLogSharingPermissionFlagForOrg(org, flag) {
    return this.dbService.driver.updateIgnoreUserLogSharingPermissionFlagForOrg(org,flag);
  }

  updateLogCollectionFlagForOrg(org, flag) {
    return this.dbService.driver.updateLogCollectionFlagForOrg(org, flag);
  }

  SDNamesUpdateForOrg(org, SDNames) {
    return this.dbService.driver.SDNamesUpdateForOrg(org, SDNames);
  }

  tutorialModeUpdateForOrg(org, value) {
    return this.dbService.driver.tutorialModeUpdateForOrg(org, value);
  }

  maxUsersNumberUpdateForOrg(org, value) {
    return this.dbService.driver.maxUsersNumberUpdateForOrg(org, value);
  }

  maxViewUsersNumberUpdateForOrg(org, value) {
    return this.dbService.driver.maxViewUsersNumberUpdateForOrg(org, value);
  }

  maxUsersEnabledUpdateForOrg(org, value) {
    return this.dbService.driver.maxUsersEnabledUpdateForOrg(org, value);
  }

  getOrgSDNames(org) {
    return this.dbService.driver.getOrgSDNames(org);
  }
// Enables/disable chat in a specific organization
  chatEnabledUpdateForOrg(org, chatEnabled) {
    return this.dbService.driver.chatEnabledUpdateForOrg(org, chatEnabled);
  }

  sendMail(emails, subject, message) {
    return this.dbService.driver.sendMail(emails, subject, message);
  }

  addLog(errorLog: ErrorLog) {
    return this.dbService.driver.addLog(errorLog);
  }

  getLogs(limit:number, offset: number, user?: string): Promise<ErrorLog[]> {
    return this.dbService.driver.getLogs(limit, offset, user);
  }

  removeLog(logId) {
    return this.dbService.driver.removeLogById(logId);
  }

  validateUsers(users: Array<any>): Promise<{ success: boolean, errors: Array<string> }> {
    return this.dbService.driver.validateUsers(users);
  }

  addUsers(users: Array<any>): Promise<{ success: boolean, errors: Array<string> }> {
    return this.dbService.driver.addUsers(users);
  }

  /**
   * input: an id of a user and a boolean value representing the waned action. if true- this user should be deactivated, otherwise activated
   * outout: the user activatuon status will be updated according**/
  changeActivationStatus(useruid: string, activationStatus: boolean) {
    return this.dbService.driver.updateUser(useruid, undefined, { isActive: activationStatus });
  }

  async getOrganizationOntology(): Promise<Array<OntologyItem>> {
    // return [{
    //   phrases: ['pointer','pointing device'],
    //   synonyms: ['keyboard','mouse','pen']
    // }];
    return this.dbService.driver.getOrgOntology();
  }

  updateOrganizationOntology(ontology: Array<OntologyItem>): Promise<void> {
    return this.dbService.driver.updateOrgOntology(ontology);
  }

  ontologyEnforcementLevelUpdateForOrg(selectedOrg: string, ontologyEnforcementLevel: OntologyEnforcementLevel): Promise<void> {
    return this.dbService.driver.updateOrgOntologyEnforcementLevel(selectedOrg, ontologyEnforcementLevel);
  }

  auth2FactorsUpdateForOrg(selectedOrg: any, auth2Factors: "disabled" | "mandatory" | "optional") {
    return this.dbService.driver.updateOrgAuth2Factors(selectedOrg, auth2Factors);
  }

  updateDefaultUserOptions(organization: string, defaultUserOptions: NewOrgUserDefaults) {
    return this.dbService.driver.updateDefaultUserOptions(organization, defaultUserOptions);
  }
}
