import {Component, OnInit, Optional, Inject, DoCheck, Input, HostListener} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InputNameDialogComponent } from '../input-name-dialog/input-name-dialog';
import { ConfirmDialogDialogComponent } from '../confirm-dialog/confirm-dialog';
import { StorageService } from '../../rappid-components/services/storage.service';
import { OpmModelComparisonService } from '../../modules/app/opm-model-comparison.service';
import {OPCloudUtils, validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';
import { ContextService, Path } from '../../modules/app/context.service';
import { ModelTitleValidator } from './name-validator';
import {
  DisplayFolder, DisplayFolderType,
  DisplayModel, DisplayModelPermissionType,
  DisplayModelType,
  VerisonsServerData,
} from '../../rappid-components/services/storage/model-storage.interface';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';
import {RenameDialogComponent} from './sub-dialogs/rename/rename-dialog.component';
import {DeleteModelDialogComponent} from "./sub-dialogs/removeModel/delete-model-dialog.component";
import {UserService} from "../../rappid-components/services/user.service";
import {DeepSearchFolder, DeepSearchModel, FoldPathsAndDirs, ModelsPathAndDirs} from './load-model-dialog-interfaces';
import {OpmLogicalProcess} from "../../models/LogicalPart/OpmLogicalProcess";
import {OpmVisualProcess} from "../../models/VisualPart/OpmVisualProcess";
import {OpmProceduralLink} from "../../models/VisualPart/OpmProceduralLink";
import {FolderPermissionsDialogComponent} from "../folder-permissions-dialog/folder-permissions-dialog/folder-permissions-dialog.component";
import {formatDate} from "../../database/dateFormat";
import {GroupsService} from "../../rappid-components/services/groups.service";

export enum StorageMode {
  LOAD,
  SAVE
}

export enum ScreenType {
  REGULAR = 1,
  EXAMPALES,
  TEMPLATES
}

export enum ExamplesType {
  SYS = 1,
  ORG
}

export enum TemplateType {
  SYS = 1,
  ORG,
  PERSONAL
}

export interface Model {
  name: string;
  description: string;
  archiveMode: string;
  editable: boolean;
}

@Component({
  selector: 'app-load-model-dialog',
  templateUrl: './load-model-dialog.component.html',
  styleUrls: ['./load-model-dialog.component.css']
})
export class LoadModelDialogComponent implements OnInit, DoCheck {

  public readonly Mode = StorageMode;

  public mode: StorageMode = StorageMode.SAVE;
  public comparison: boolean;
  public showVersions: boolean;
  public showArchivedModels: boolean;
  public selectedModel: DisplayModel | DeepSearchModel;
  public selectedFolder: DisplayFolder | DeepSearchFolder;

  public deepSearchMode = false;
  public searchInput = '';
  public currentDialogPath: Path;
  private favorites: Array<any> = [];
  public spinnerFlag = true;
  public shouldShowDeepSearchResults = false;

  private models: Array<DisplayModel> = [];
  private folders: Array<DisplayFolder> = [];
  private modelsForDeepSearch: Array<DeepSearchModel> = [];
  private foldersForDeepSearch: Array<DeepSearchFolder> = [];
  private foldersMap: Map<string, { title: string, father: string }>;

  public modelsPathsAndDirsRepresentation: Array<ModelsPathAndDirs>;
  public foldPathsAndDirsRepresentation: Array<FoldPathsAndDirs>;
  public ShownLastModels: Array<DisplayModel> = [];
  public shownModels: Array<DisplayModel> = [];
  public shownFolders: Array<DisplayFolder> = [];
  public shownDeepModels: Array<DeepSearchModel> = [];
  public shownDeepFolders: Array<DeepSearchFolder> = [];
  public loadType = false;
  public defSortType = {
    recent: { path: 'Up', model: 'Up', date: 'Down', author: 'Up' },
    folders: { folder: 'Up' },
    models: { folder: 'Up', date: 'Down', author: 'Up' }
  };
  public sortStatuses = {
    recent: { ...this.defSortType.recent },
    folders: { ...this.defSortType.folders },
    models: { ...this.defSortType.models }
  };

  public name = '';
  public description: string;
  public archiveMode: boolean;
  private screenTypeVal: ScreenType;
  private exampleType: ExamplesType;
  private templateType: TemplateType;
  private cutFolder: DisplayFolder;
  private cutModel: DisplayModel;
  private isImportMode: boolean;
  @Input() alternativeData;
  private currentFolderPermissions;
  private currentUserGroups;
  private modelWasSelectedFromRecents: boolean;
  private selectedModelPermissions;
  private isCurrentlySaving: boolean;

  constructor(
    @Optional() public dialogRef: MatDialogRef<LoadModelDialogComponent>,
    private readonly init: InitRappidService,
    private readonly storage: StorageService,
    private readonly context: ContextService,
    private readonly groupService: GroupsService,
    private _dialog: MatDialog,
    private comparisonService: OpmModelComparisonService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.alternativeData)
      this.data = this.alternativeData.data;
    this.mode = this.data.mode;
    this.comparison = this.data.comparison ? this.data.comparison : false;
    this.screenTypeVal = this.data.screenType ? this.data.screenType : ScreenType.REGULAR;
    this.exampleType = this.data.exampleType;
    this.templateType = this.data.templateType;
    this.isImportMode = !!this.data.isImportMode;
    this.description = this.init.opmModel.description || this.data.description || '';
    this.name = this.init.modelService.displayName && this.init.modelService.displayName !== 'Model (Not Saved)' ? this.init.modelService.displayName : '';
    this.name = this.clearName(this.name);
    this.archiveMode = this.data.archiveMode ? this.data.archiveMode : 'false';
    this.showVersions = this.data.showVersions;
    this.showArchivedModels = this.data.showArchivedModels;
    this.currentDialogPath = [].concat(this.context.getPath() || [{ id: 'root', title: 'Home' }]);
    this.init.setSelectedElementToNull();
    this.groupService.getGroupsByUserIDNew(this.userService.user.uid).then(res => this.currentUserGroups = res);
    if (localStorage.getItem('dirsPath')) {
      const dirsPath = JSON.parse(localStorage.getItem('dirsPath'));
      this.currentDialogPath = dirsPath.reverse().map(item => {
        return { id: item.id, title: item.title ? item.title : 'Home'};
      });

      if ([ScreenType.EXAMPALES, ScreenType.TEMPLATES].includes(this.screenTypeVal)) {
        this.loadType = true;
      }
    }

    if (!!this.currentDialogPath?.find(d => d.id === 'ORGEXAMPLES')) {
      this.currentDialogPath = [{ id: 'root', title: 'Home' }];
    }
    if (this.init.oplService.settings.loadScreenSortDirections)
      this.sortStatuses.models = this.init.oplService.settings.loadScreenSortDirections;
    if (this.isExamplesView()) {
      this.changeViewMode(true, false);
      const exampleType = this.isSysExamples() ? 'SYS' : 'ORG';
      this.storage.getFavoriteExamples().then(models => {
        this.favorites = models.filter(m => m.exampleType === exampleType);
      }).catch(err => this.favorites = []);
      this.currentDialogPath = [].concat([{ id: 'root', title: 'Home' }, { id: 'ORGEXAMPLES', title: 'Examples' }]);
      this.initAttributes();
    } else if (this.isTemplatesView()) {
      this.changeViewMode(true, false);
      const templateType = TemplateType[this.templateType];
      this.storage.getFavoriteTemplates().then(models => {
        this.favorites = models.filter(m => m.templateType === templateType);
      }).catch(err => this.favorites = []);
      if ([TemplateType.ORG, TemplateType.SYS].includes(this.templateType))
        this.currentDialogPath = [].concat([{ id: 'root', title: 'Home' }, { id: 'ORGTEMPLATES', title: 'Templates' }]);
      else
        this.currentDialogPath = [].concat([{ id: 'root', title: 'Home' }, { id: 'PRIVATETEMPLATES', title: 'Templates' }]);
      this.initAttributes();
    } else {
      const viewType = this.init.oplService.settings.loadScreenViewType;
      if (viewType !== undefined)
        this.loadType = viewType;
      this.storage.getLastModels().then(models => {
        this.createLastModelList(models);
      }).catch(err => { });
    }

    this.recoverLastOpenedFolder();
    this.initAttributes();

    $('mat-dialog-container').on('click', (event) => {
      if (event.target.tagName !== 'INPUT')
        this.changeFocusFromDeepSearch(event);
    });
    this.getModelBasicNameOnSave();

    const elStyle = $('.mat-dialog-container')[0].style;
    elStyle.overflow = 'hidden';
    elStyle.position = 'relative';
    elStyle.resize = 'auto';
  }

  ngDoCheck() {
    if ($('.footerActions').length) {
      const listView = $('#combinedFolderModelDiv')[0] as any;
      const regularView = $('#regularViewFoldersAndModels')[0] as any;
      const footerTop = $('.footerActions')[0].getClientRects()[0].top;
      if (listView)
        listView.style.height = footerTop - listView.getClientRects()[0].top + 7 + 'px';
      else if (regularView) {
        regularView.style.height = footerTop - regularView.getClientRects()[0].top + 7 + 'px';
      }
    }
    this.checkSizeChanges();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'x' && event.ctrlKey && this.selectedModel && !this.modelWasSelectedFromRecents && this.canModelBeCut()) {
      this.cutSelectedModel();
    } else if (this.cutModel && event.key === 'v' && event.ctrlKey && this.canPasteModel()) {
      this.pasteModel();
    }
  }

  private getCurrentDirectoryId(): string {
    return this.currentDialogPath[this.currentDialogPath.length - 1]?.id || 'root';
  }

  initAttributes(): void {
    this.spinnerFlag = true;
    this.searchInput = '';

    const id = this.getCurrentDirectoryId();
    this.storage.getFolderPermissions(id).then(per => this.currentFolderPermissions = per);
    if (id.endsWith('_ver')) {
      this.storage.getVersions(id.replace('_ver', ''))
        .then(models => {
          this.createModelVersionList(models);
          this.folders = []
          this.search();
          this.spinnerFlag = false
        });
    } else {
      Promise.all([
        this.storage.getModels(id, this.showArchivedModels, this.isSysExamples(), this.isGlobalTemplates()).catch(err => []),
        this.storage.getFolders(id, this.isSysExamples(), this.isGlobalTemplates()).catch(err => undefined)
      ]).then(res => {
        // if lost permissions to the last folder => open the root folder
        if (!res[1]) {
          this.currentDialogPath = [{id: 'root', title: 'Home'}];
          return this.initAttributes();
        }
        this.createModelList(res[0]);
        this.createDirectoriesList(res[0], res[1]);
        this.search();
        this.spinnerFlag = false;
        if (this.init.oplService.settings.loadScreenSortBy) {
          this.sortByColumn('models', this.init.oplService.settings.loadScreenSortBy, false);
        }
      });
    }
  }

  private isSysExamples() {
    return this.exampleType === ExamplesType.SYS;
  }

  private isOrgExample() {
    return this.exampleType === ExamplesType.ORG;
  }

  private isGlobalTemplates() {
    return this.templateType === TemplateType.SYS;
  }

  private isOrgTemplate() {
    return this.templateType === TemplateType.ORG;
  }

  private isPersonalTemplate() {
    return this.templateType === TemplateType.PERSONAL;
  }

  private createModelVersionList(models: VerisonsServerData) {
    const versions = new Array<DisplayModel>();
    for (const model of models.versions) {
      if (typeof model.date === 'number')
        model.date = formatDate(new Date(model.date));
      versions.push({
        id: models.id,
        title: model.date,
        description: models.description,
        archiveMode: {
          archiveMode: false,
          date: model.date,
          name: ''
        },
        type: DisplayModelType.VERSION,
        permissions: DisplayModelPermissionType.READ,
        editBy: {
          date: model.date,
          name: ''
        },
        path: model.ver_index
      });
    }
    this.models = versions;
  }

  convertMonthToNumber(str) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months.indexOf(str) + 1;
  }

  private createDirectoriesList(models: Array<DisplayModel>, directories: Array<DisplayFolder>) {
    const versions = new Array<DisplayFolder>();
    for (const model of models) {
      if (model.editBy?.date?.toString().includes('Coordinated Universal Time')) {
        const str = model.editBy.date;
        const day = str.substr(8,2);
        const month = this.convertMonthToNumber(str.substr(4,3));
        const year = str.substr(11,4);
        const hour = str.substr(16,8);
        model.editBy.date = day + '-' + month + '-' + year + ' ' + hour;
      }
      versions.push({
        id: model.id + "_ver",
        name: model.title + " " + "Versions",
        type: DisplayFolderType.VERSION
      });
    }
    this.folders = directories.filter(dir => dir.id !== 'ORGEXAMPLES').concat(versions);
    this.folders = this.folders.sort((a,b) => {
      if (a.id === 'shared')
        return -1;
      if (b.id === 'shared')
        return 1;
      return a.name > b.name ? 1 : -1;
    });
  }

  private isAdmin(): boolean {
    return this.userService.isSysAdmin() || this.userService.isOrgAdmin();
  }

  private createModelList(received: Array<DisplayModel>) {
    const models = new Array<DisplayModel>();
    for (const model of received) {
      if (typeof model.editBy.date === 'number')
        model.editBy.date = formatDate(new Date(model.editBy.date));
      if (model.lastAutosaveData?.date && typeof model.lastAutosaveData.date === 'number')
        model.lastAutosaveData.date = formatDate(new Date(model.lastAutosaveData.date));
      model.type = DisplayModelType.MAIN;
      models.push(model);
      const copy = Object.assign({}, model);
      copy.type = DisplayModelType.AUTOSAVE;
      if (copy.lastAutosaveData) {
        copy.editBy = copy.lastAutosaveData;
      }
      models.push(copy);
    }
    this.models = models;
  }

  private createLastModelList(received: Array<DisplayModel>) {
    const models = new Array<DisplayModel>();
    for (const model of received) {
      model.type = DisplayModelType.MAIN;
      if (typeof model.editBy?.date === 'number') {
        model.editBy.date = new Date(model.editBy.date).toLocaleString().replace('.','-').replace('.','-');
      }
      models.push(model);
    }
    this.ShownLastModels = models;
  }

  getDate(dateString) {
    const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let day, month, year;
    if (dateString.length === 8) {
      day = dateString.substr(0, 1);
      month = String(month_names_short.indexOf(dateString.substr(1, 3)) + 1);
      if (month.length === 1) { month = '0' + month; }
      year = dateString.substr(4, 4);
    } else {
      day = dateString.substr(0, 2);
      month = String(month_names_short.indexOf(dateString.substr(2, 3)) + 1);
      if (month.length === 1) { month = '0' + month; }
      year = dateString.substr(5, 4);
    }
    const date = new Date(year + '-' + month + '-' + day);
    return date.getTime();
  }

  isSooner(String1, String2) {
    const dateString1 = String1.split('_', 2)[0];
    const timeString1 = String1.split('_', 2)[1];
    const dateString2 = String2.split('_', 2)[0];
    const timeString2 = String2.split('_', 2)[1];
    const date1 = this.getDate(dateString1);
    const date2 = this.getDate(dateString2);
    if (date1 === date2) { return timeString1 < timeString2; }
    return date1 < date2;
  }

  clearSelected() {
    this.selectedModel = undefined;
    this.selectedFolder = undefined;
  }

  getModelTooltip(model: DisplayModel): string {
    const archiveMode: string = (model.archiveMode && model.archiveMode.archiveMode === true) ? 'Yes' : (model.archiveMode && model.archiveMode.archiveMode === false) ? 'No' : 'Unknown';
    return `Model: ${model.title}
     Description: ${model.description || 'No description'}
     Model archived: ${archiveMode}
     Date: ${model.editBy.date || 'No date'}
     Author: ${model.editBy.name || 'Unknown'}
     *Times are shown in local time`;
  }

  showVersionsFolders() {
    this.search();
  }

  // Load archived models from the server
  showArchiveModels() {
    this.initAttributes();
  }

  // setLoadType() {
  //   this.loadType = this.loadType === 'TILES' ? 'LIST' : 'TILES';
  // }

  search() {
    const term = this.searchInput.toLowerCase();

    this.shownModels = this.models.filter(model => model.title.toLowerCase().includes(term));
    this.shownFolders = this.folders.filter(folder => folder.name.toLowerCase().includes(term));

    if (this.showVersions === false) {
      this.shownModels = this.shownModels.filter(model => model.type !== DisplayModelType.AUTOSAVE);
      this.shownFolders = this.shownFolders.filter(folder => folder.type !== DisplayFolderType.VERSION);
    }

    this.clearSelected();
  }

  getModelIcon(model: DisplayModel): string {
    if (this.isExamplesView())
      return 'assets/SVG/example.svg';
    if (this.isTemplatesView())
      return 'assets/SVG/template.svg';
    if (model.type === DisplayModelType.AUTOSAVE)
      return 'assets/SVG/autosave.svg';
    if (model.permissions === DisplayModelPermissionType.WRITE)
      return 'assets/SVG/regFile.svg';
    return 'assets/SVG/lock.svg';
  }

  getModelPath(path: string): string {
    if (path.startsWith('/'))
      path = path.substr(1);
    return 'HOME/' + path;
  }

  // displayModelDate(model: DisplayModel): string {
  //   return model.editBy.user;
  //   if (model.type === DisplayModelType.AUTOSAVE) {
  //     return    'assets/SVG/autosave.svg';
  //   }
  //
  //   if (model.permissions === DisplayModelPermissionType.WRITE) {
  //     return 'assets/SVG/regFile.svg';
  //   }
  //   return 'assets/SVG/lock.svg';
  // }

  getFolderIcon(folder: DisplayFolder): string {
    if (folder.id === 'shared')
      return 'assets/SVG/sharedFolder.svg';
    if (folder.type === DisplayFolderType.VERSION)
      return 'assets/SVG/verFile.svg';
    return 'assets/SVG/folder.svg';
  }

  showVersionsChange() {
    this.search();
  }

  openDeepSearchModel(model: ModelsPathAndDirs) {
    this.clearSelected();
    this.selectedModel = model.model;
    this.name = model.model.title;
    this.action();
  }

  openDeepSearchFolder(folder: FoldPathsAndDirs) {
    this.clearSelected();
    this.selectedFolder = folder.folder;
    this.currentDialogPath = folder.pathAndDir.currentDialogPath;
    this.setCurrentFolderToStorage();
    this.initAttributes();
  }

  selectModel(model: DisplayModel, modelWasSelectedFromRecents = false) {
    this.clearSelected();
    this.selectedModel = model;
    this.storage.getPermissions(model.id).then(per => this.selectedModelPermissions = per).catch(err => {});
    this.modelWasSelectedFromRecents = modelWasSelectedFromRecents;
    this.name = model.title;
    this.description = model.description || this.description;
    this.archiveMode = model.archiveMode.archiveMode;
  }

  selectFolder(folder: DisplayFolder) {
    this.clearSelected();
    this.selectedFolder = folder;
  }

  openFolder() {
    const folder = this.selectedFolder;
    if (!folder)
      return;
    this.currentDialogPath.push({ id: folder.id, title: folder.name });
    this.setCurrentFolderToStorage();
    this.initAttributes();
  }

  goBack() {
    if (this.currentDialogPath.length == 1)
      return;
    if (this.currentDialogPath.length == 2 && (this.isExamplesView() || this.isTemplatesView()))
      return;
    this.currentDialogPath.pop();
    this.setCurrentFolderToStorage();
    this.initAttributes();
  }

  goRoutingPath(): string {
    return this.currentDialogPath.map(a => a.title).join('/').replace('Home/Examples', 'Examples')
      .replace('Home/Templates', 'Templates');
  }

  load(asFavorite = false) {
    this.dialogRef.close();
    const that = this;
    if (this.comparison) {
      this.loadForComparison();
    } else {

      if (this.context.isModelAlreadyOpenOnTab(this.selectedModel.id)) {
        validationAlert(`The model is already open in other tab.`, 2500, 'Error');
        return;
      }
      this.init.isLoadingModel = true;
      /**
       * group 04:
       * Here we make sure that when new model is loaded, we clean the copy dictionaries.
       */

      this.init.linkCopiedStyleParams = null;
      this.init.copiedStyleParams = null;
      if (this.selectedModel.type == DisplayModelType.VERSION) {
        // In this case path is the ver index
        this.context.loadVersion(this.selectedModel.id, this.selectedModel.path, this.currentDialogPath);
      } else {
        const mode = (this.selectedModel.type == DisplayModelType.MAIN) ? 'MAIN' : 'AUTO';
        localStorage.removeItem('lastPathEntered');
        this.context.loadModel(this.selectedModel.id, this.currentDialogPath, mode, undefined, this.isSysExamples(), this.isOrgExample(), this.isGlobalTemplates(), this.isOrgTemplate())
          .then((res) => {
            this.init.isLoadingModel = false;
            that.init.elementToolbarReference.setIsExample(that.context.isExample());
            that.init.elementToolbarReference.setIsTemplate(that.context.isTemplate());
            that.init.elementToolbarReference.setIsStereotype(that.context.isStereotype());
            validationAlert(`Successfully loaded model [${this.name}].`, 2500, 'warning');
          })
          .catch(err => {
            this.init.isLoadingModel = false;
            if (asFavorite && err.status === 500) {
              // if model doesn't exist anymore remove from user's favorites.
              this.storage.unsetFavoriteExample(this.selectedModel as DisplayModel);
              validationAlert(`Model doesn't exist anymore.`, 2500, 'Error');
            } else {
              validationAlert(`Failed to load model [${this.name}].`, 2500, 'Error');
            }
          });
      }
    }
  }

  importTemplate() {
    if (!this.selectedModel)
      return;
    this.spinnerFlag = true;
    const selectedId = this.selectedModel.id;
    this.context.getTemplateForImport(this.selectedModel.id, this.isGlobalTemplates()).then(res => {
      this.dialogRef.close({ importedTemplate: res });
    }).catch(err => {
      const favorite = this.favorites.find(f => f.id === selectedId);
      if (err.status === 500 && favorite) {
          const idx = this.favorites.indexOf(favorite);
          this.favorites.splice(idx ,1);
          this.storage.unsetFavoriteTemplate(favorite);
      }
      this.dialogRef.close();
    });

  }

  isSaveDisabled(): boolean {
    return (this.mode == StorageMode.SAVE && this.name.length == 0) || this.shouldShowSaveAsSystemExample() || (this.mode == StorageMode.SAVE && this.isCurrentlySaving);
  }

  save(asSystemExample = false, asGlobalTemplate = false) {
    const name = this.name.trim();
    const that = this;
    if (ModelTitleValidator.create().validateTitle(name) == false) {
      this.openBadlyFormatDialog();
      return;
    }

    const desc = this.description.trim();
    if (desc.length === 0 && this.isExamplesView()) {
      validationAlert('Description field is required.', 3500, 'Warning');
      return;
    }

    const model = this.models.find(m => m.title == name);

    if (model && this.context.isUserSysAdmin() && (this.context.isExample() || this.context.isTemplate()))
      model.permissions = DisplayModelPermissionType.WRITE;

    if (model && model.permissions == DisplayModelPermissionType.READ) {
      this._dialog.open(ConfirmDialogDialogComponent, {
        height: '225px',
        width: '320px',
        data: { message: 'Warning: You do not have permission to overwrite existing model. \n\n To save it as a copy, you can change the file name.', closeFlag: true },
      });
      return;
    }

    if (model && model.permissions == DisplayModelPermissionType.WRITE) {
      const selected = this.selectedModel || model;
      if ((<any>selected).fatherModelId && selected.id !== this.context.getCurrentModelId()) {
        validationAlert('Overriding a sub model is possible only by its versions.', 5000, 'Error');
        return;
      }
      const confirmDialog = this._dialog.open(ConfirmDialogDialogComponent, {
        height: '180px',
        width: '320px',
        data: { message: 'Warning: This model will be overwritten.\n Are you sure?', closeFlag: false },
      });
      confirmDialog.afterClosed().subscribe((data) => {
        if (data) {
          const temp = selected.description || '' + '';
          if (that.description && that.description !== selected.description)
            selected.description = that.description;
          validationAlert('Saving...', 2000, 'warning');
          this.isCurrentlySaving = true;
          this.doOverrideModel(selected as DisplayModel, asSystemExample, asGlobalTemplate);
          selected.description = temp;
        }
      });
      return;
    }
    validationAlert('Saving...', 2000, 'warning');
    this.isCurrentlySaving = true;
      this.doCreateModel(asSystemExample, asGlobalTemplate)
  }

  private saveModelAsSystemExample() {
    this.save(true, false);
  }

  private saveModelAsGlobalTemplate() {
    this.save(false, true);
  }

  private async setWithOrgExamplePermission(modelId: string) {
    if (this.isExamplesView() && this.exampleType === ExamplesType.ORG) {
      const per = await this.storage.getPermissions(modelId);
      per.readIDs = ['ORGEXAMPLE'];
      this.storage.updatePermissions(modelId, per);
    }
  }

  private async setWithOrgTemplatePermission(modelId: string) {
    if (this.isTemplatesView() && this.templateType === TemplateType.ORG) {
      const per = await this.storage.getPermissions(modelId);
      per.readIDs = ['ORGTEMPLATES'];
      this.storage.updatePermissions(modelId, per);
    }
  }

  private async doOverrideModel(model: DisplayModel, asSystemExample = false, asGlobalTemplate = false) {
    const image = await this.getModelImage();
    this.context.saveModel({ model_id: model.id, title: this.name, path: this.currentDialogPath, description: this.description, image: image, archiveMode: this.archiveMode, sysExample: asSystemExample, globalTemplate: asGlobalTemplate }, 'override')
      .then(() => {
        this.setWithOrgExamplePermission(model.id);
        this.isCurrentlySaving = false;
        this.dialogRef.close();
        validationAlert(`Successfully saved model [${this.name}].`, 2500, 'Success');
      }).catch(err => {
      this.isCurrentlySaving = false;
      validationAlert(`Cannot save model to this folder. It seems you do not have a permissions.`, 5000, 'Error');
    });
  }

  async getModelImage(): Promise<string> {
    if (!this.isTemplatesView())
      return undefined;
    return this.init.getModelImage();
  }

  private async doCreateModel(asSystemExample = false, asGlobalTemplate = false) {
    const this_ = this;
    const image = await this.getModelImage();
    this.context.saveModel({ directory_id: this.currentDialogPath[this.currentDialogPath.length - 1].id, title: this.name, description: this.description, image: image, archiveMode: this.archiveMode, path: this.currentDialogPath, sysExample: asSystemExample, globalTemplate: asGlobalTemplate }, 'create')
      .then(() => {
        this.setWithOrgExamplePermission(this_.context.getCurrentModelId());
        this.setWithOrgTemplatePermission(this_.context.getCurrentModelId());
        // this.context.updateTabData(this.context.getCurrentTabItem());
        this_.context.updateUserLastTabsToDB();
        this.isCurrentlySaving = false;
        this.dialogRef.close();
        validationAlert(`Successfully saved model [${this.name}].`, 2500, 'Success');
      }).catch(err => {
      this.isCurrentlySaving = false;
      validationAlert(`Cannot save model to this folder. It seems you do not have a permissions.`, 5000, 'Error');
    });
  }

  nextSortType(group = 'recent', column = 'path'): string {
    const status = this.sortStatuses[group][column];
    return (status === 'Up') ? 'Down' : (status === 'Down') ? 'Up' : this.defSortType[group][column];
  }

  nextSortMark(group = 'recent', column = 'path'): string {
    return this.pickList(group).length > 1 ?
      (this.nextSortType(group, column) === 'Down' ? '\u2193' : '\u2191') : '';
  }

  setSortType(group = 'recent', column = 'path', sort = 'Down') {
    this.sortStatuses[group] = { ...this.defSortType[group] };
    this.sortStatuses[group][column] = sort;
  }

  private pickList(group = 'recent'): Array<any> {
    return { recent: this.ShownLastModels, folders: this.shownFolders, models: this.shownModels }[group];
  }

  convertDate(dateStr) {
    if (dateStr.length > 0) {
      const year = dateStr.substr(6,4);
      const month = dateStr.substr(3,2) - 1;
      const date = dateStr.substr(0,2);
      const hours = dateStr.substr(10).split(':').map(i => Number(i));
      return new Date(Number(year), Number(month), Number(date), hours[0], hours[1], hours[2]);
    }
    return new Date(1990, 2, 1, 0,0,0);
  }

  // convertDate(dateStr) {
  //   if (dateStr && !!dateStr.match("[a-zA-Z]")) {
  //     const monthConvert = {
  //       'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06',
  //       'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12'
  //     };
  //     let day = dateStr.substr(0, dateStr.match("[a-zA-Z]").index);
  //     day = day.length === 1 ? '0' + day : day;
  //     const month = dateStr.substr(dateStr.match("[a-zA-Z]").index, 3);
  //     const monthAsNumber = monthConvert[month];
  //     const year = dateStr.substr(dateStr.match("[a-zA-Z]").index + 3, 4);
  //     const hours = dateStr.substr(dateStr.match("[a-zA-Z]").index + 8);
  //     return year + '-' + monthAsNumber + '-' + day + ' ' + hours;
  //   }
  //
  //   if (dateStr)
  //     return dateStr;
  //   return 'NO DATE';
  //
  // }

  private pickComparator(group: string, column: string): (a: any) => string {
    const byDate = (a: DisplayModel) => this.convertDate(a.editBy.date);
    const byAuthor = (a: DisplayModel) => a.editBy.name ? a.editBy.name.toLowerCase() : '';
    const byModel = (a: DisplayModel) => a.title ? a.title.toLowerCase() : '';
    const byPath = (a: DisplayModel) => this.getModelPath(a.path).toLowerCase();
    const byFolder = (a: DisplayFolder) => a.name ? a.name.toLowerCase() : '';
    return {
      recent: { path: byPath, model: byModel, date: byDate, author: byAuthor },
      folders: { folder: byFolder },
      models: { model: byModel, date: byDate, author: byAuthor }
    }[group][column];
  }

  public sortByColumn(group = 'recent', column = 'path', updateDB = true) {
    this.sortByColumn_(this.pickList(group), group, column, this.pickComparator(group, column), updateDB ? undefined : this.sortStatuses[group][column]);
    if (group === 'models') {
      const loadScreenData = {
        loadScreenSortBy: column,
        loadScreenSortDirections: this.sortStatuses.models
      };
      if (updateDB) {
        this.init.updateDB(loadScreenData);
        this.init.oplService.updateUserSettings(loadScreenData);
      }
    }
  }

  private sortByColumn_(list: Array<any>, group = 'recent', column = 'path', f?: (a: any) => string, sortCategoryDirection = undefined) {
    const sortCategory = sortCategoryDirection || this.nextSortType(group, column);
    let l = 1, u = -1;
    if (sortCategory === 'Up') { l = -1; u = 1; }
    // console.log('statuses:', JSON.stringify(this.sortStatuses), '\n ascending:', sortCategory);
    // console.log('before sort:', list.map(a => f(a)));
    list.sort((a, b) =>
      (f(a) < f(b)) ? l : u);
    this.setSortType(group, column, sortCategory);
    // console.log('after sort:', list.map(a => f(a)));
    // console.log('statuses:', JSON.stringify(this.sortStatuses), '\n---------------------');
  }

  isActionButtonDisabled() {
    if (this.selectedModel === undefined && this.selectedFolder === undefined) {
      return true;
    }

    if (this.selectedFolder) {
      return false;
    }

    if (this.mode === StorageMode.SAVE) {
      if (this.selectedModel && this.selectedModel.permissions == DisplayModelPermissionType.WRITE) {
        return true;
      }

      if (this.name.length == 0) {
        return true;
      }
    }

    return false;
  }

  getActionName(): string {
    if (this.selectedFolder) {
      return 'Open';
    }

    if (this.mode === this.Mode.SAVE) {
      return 'Save';
    }

    if (this.selectedModel && this.mode === this.Mode.LOAD) {
      return 'Load';
    }

  }

  action(asFavorite =  false) {
    if (this.selectedFolder) {
      this.openFolder();
    }
    else if (this.mode === this.Mode.LOAD) {
      if (this.isImportMode)
        this.importTemplate();
      else
        this.load(asFavorite);
    }
    else if (this.mode === this.Mode.SAVE) {
      this.save(this.isSysExamples(), this.isGlobalTemplates());
    }
  }

  // Change the label of the action button based on the archive mode of the selected model
  getArchiveActionName(): string {
    if (this.selectedModel) {
      if (this.archiveMode === false) {
        return 'Archive';
      } else { return 'Restore'; }
    }
  }

  // Archive the selected model
  archive() {
    // Precondition: Check user permission - The user need to be the owner and have write permissions
    // After the request was performed by the server a refresh need to be done - using the existing method - initAttributes()
    const model = this.selectedModel as DisplayModel;
    if (model && model.permissions === DisplayModelPermissionType.READ) {
      this._dialog.open(ConfirmDialogDialogComponent, {
        height: '150px',
        width: '320px',
        data: { message: 'Warning: You do not have permission to archive the selected model', closeFlag: true },
      });
      return;
    }
    if (model && model.permissions === DisplayModelPermissionType.WRITE) { // Checking only write permission
      // TODO: need to add a validation that the user archiving is the owner of the selected model
      // Archive/unArchive selected model based on the value of the model ArchiveMode property
      if (model.archiveMode.archiveMode === false) { // archive model
        this.context.archiveModel(this.selectedModel.id, true).then(res => this.initAttributes());
      } else if (model.archiveMode.archiveMode === true) { // unarchive model
        this.context.archiveModel(this.selectedModel.id, false).then(res => this.initAttributes());
      }
    }
  }

  nameStyle(name) {
    let firstSpace = name.indexOf(' ');
    if (firstSpace === -1) {
      firstSpace = name.length;
    }
    if (name.substring(0, firstSpace) > 12) {
      return name.slice(0, 12) + '...';
    } else {
      return name.substring(0, 12) + name.slice(firstSpace, firstSpace + 6) + '...';
    }
  }

  newFolder() {
    const dialogRef = this._dialog.open(InputNameDialogComponent, {
      height: '200px',
      width: '280px',
      data: { message: '', inputName: 'Folder Name' }
    });

    const sub = dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        const name = data.NameInput.trim();
        if (ModelTitleValidator.create().validateTitle(name) == false) {
          this.openBadlyFormatDialog();
        } else if (this.shownFolders.find(f => f.name.trim().toLowerCase() === name.toLowerCase())) {
          validationAlert('This name is already been used. Please choose a different name.', 5000, 'Error');
        } else {
          const current = this.getCurrentDirectoryId();
          const res = await this.storage.createFolder(current, name, this.isSysExamples(), this.isOrgExample(), this.isGlobalTemplates(), this.isOrgTemplate());
          if (res.ret.success) {
            this.storage.getFolders(current, this.isSysExamples(), this.isGlobalTemplates())
              .then(folders => { this.folders = folders; this.shownFolders = folders; });
          }
          else {
            validationAlert(res.ret.message, 5000, 'error');
          }
        }
        sub.unsubscribe();
      }
    });
  }

  displayModelName(model: DisplayModel) {
    return (model.title.length > 9) ? (model.title.slice(0, 9)) + '...' : (model.title);
  }

  loadForComparison() {
    this.comparisonService.start(this.selectedModel.id)
      .then(() => validationAlert(`Successfully loaded model [${this.selectedModel?.title || ''}] at [${this.selectedModel.path}] for comparison.`,
        2500, 'Success'));
  }

  getFoldersNumberInRow() {
    if ($('.mat-dialog-container').length > 0 && $('.mat-dialog-container')[0].getClientRects().length > 0)
      return Math.round(($('.mat-dialog-container')[0].getClientRects()[0].width - 100) / 200);
    else
      return 4;
  }

  changeViewMode(value: boolean, changedByUser = true) {
    this.loadType = value;
    if (changedByUser) {
      this.init.updateDB({loadScreenViewType: this.loadType});
      this.init.oplService.updateUserSettings({loadScreenViewType: this.loadType});
    }
  }

  rename() {
    if (!this.selectedModel && !this.selectedFolder) {
      return;
    }
    const ref = this._dialog.open(RenameDialogComponent, {
      data: { model: this.selectedModel, folder: this.selectedFolder, sysExamples: this.isSysExamples(), globalTemplates: this.isGlobalTemplates(), usedNames: this.shownFolders.map(f => f.name)},
    });
    const sub = ref.afterClosed().subscribe((data) => {
      if (data && data.renamed)
        this.initAttributes();
      sub.unsubscribe();
    });
  }

  isRenameDisabled(): boolean {
    if (this.selectedFolder?.name?.endsWith('Versions'))
      return true;

    if (this.selectedModel && this.selectedModel?.permissions !== DisplayModelPermissionType.WRITE)
      return true;

    if (this.selectedFolder && !(<any>this.selectedFolder)?.permissions?.owner)
      return true;

    if (this.selectedModel && (<DisplayModel>this.selectedModel).fatherModelId) {
      return true;
    }

    return false;
  }

  private openBadlyFormatDialog() {
    this._dialog.open(ConfirmDialogDialogComponent, {
      data: { message: 'Filename is badly formatted. Cannot contain the following characters: ^ \ / : * ? " < > | . $ [ ] #', closeFlag: true },
    });
  }

  removeModel() {
    const ref = this._dialog.open(DeleteModelDialogComponent, {
      data: { model: this.selectedModel, folder: this.selectedFolder, sysExamples: this.isSysExamples(), globalTemplates: this.isGlobalTemplates() },
    });
    const sub = ref.afterClosed().subscribe((data) => {
      if (data && data.removed)
        this.initAttributes();
      sub.unsubscribe();
    });
  }

  canModelBeRemoved() {
    if (this.mode === this.Mode.LOAD && (this.isExamplesView() || this.isTemplatesView()))
      return false;
    if (this.selectedModel?.type === 'version' || this.selectedModel?.type === 'autosave')
      return false;
    return this.selectedModel != undefined && this.hasPermissionToDeleteSelectedModel();
  }

  canModelBeCut(): boolean {
    if (this.getCurrentDirectoryId().endsWith('_ver')) {
      return false;
    }
    if (!this.selectedModel) {
      return false;
    } else if (this.selectedModel && this.modelWasSelectedFromRecents) {
      return false;
    } else if (this.mode === this.Mode.LOAD && (this.isExamplesView() || this.isTemplatesView())) {
      return false;
    } else if (this.selectedModel.type !== DisplayModelType.MAIN) {
      return false;
    }
    return this.selectedModel.permissions === 'write' || this.selectedModelPermissions?.ownerID === this.userService.user.uid || this.context.isUserSysAdmin() || this.context.isUserOrgAdmin();
  }

  cutSelectedModel() {
    this.cutModel = this.selectedModel as DisplayModel;
  }

  canPasteModel(): boolean {
    const currentFolderId = this.getCurrentDirectoryId();
    if (currentFolderId.endsWith('_ver')) {
      return false;
    }
    const isAdmin = this.context.isUserSysAdmin() || this.context.isUserOrgAdmin();
    let isOwnerOfTargetFolder = false;
    let hasWritePermissionToTargetFolder = false;
    const uid = this.userService.user.uid;
    if (this.currentFolderPermissions) {
      isOwnerOfTargetFolder = this.currentFolderPermissions.ownerIds.includes(uid) || this.currentFolderPermissions.ownerIds.includes('all')
        || this.currentFolderPermissions.groupsOwnersIds?.some(g => this.currentUserGroups?.includes(g));
      hasWritePermissionToTargetFolder = this.currentFolderPermissions.writeIds.includes(uid) || this.currentFolderPermissions.writeIds.includes('all')
        || this.currentFolderPermissions.groupsWriteIds?.some(g => this.currentUserGroups?.includes(g));
    }
    const isModelOwner = this.selectedModelPermissions?.ownerID === this.userService.user.uid;
    return !!this.cutModel && (isAdmin || isOwnerOfTargetFolder || hasWritePermissionToTargetFolder || isModelOwner);
  }

  pasteModel() {
    const confirmDialog = this.init.dialogService.openDialog(ConfirmDialogDialogComponent, 200, 350, {
      allowMultipleDialogs: true,
      message: 'Warning:\n Are you sure you want to move this model?',
      closeFlag: false
    });
    confirmDialog.afterClosed().toPromise().then((data) => {
      if (!data)
        return;
      this.spinnerFlag = true;
      this.storage.moveModel(this.cutModel.id, this.getCurrentDirectoryId(), this.isSysExamples(), this.isGlobalTemplates()).then(res => {
        if (res.success) {
          this.initAttributes();
          validationAlert('The model was pasted successfully.', 5000, 'Success');
        } else {
          validationAlert(res.message, 5000, 'Error');
          this.spinnerFlag = false;
        }
        this.cutModel = undefined;
      }).catch(err => {
        this.spinnerFlag = false;
      })
    });
  }

  hasPermissionToDeleteSelectedModel() {
    return true;
  }

  clearName(name) {
    if (name)
      return name.replace(' (read only)','').replace('<<OPM Example Model>> ', '').replace('<<Template>> ', '');
    return '';
  }

  setCurrentFolderToStorage() {
    if (this.currentDialogPath && !this.isExamplesView() && !this.isTemplatesView())
      localStorage.setItem('lastPathEntered', JSON.stringify(this.currentDialogPath));
  }

  recoverLastOpenedFolder() {
    const path = localStorage.getItem('lastPathEntered');
    if (path && !this.isExamplesView() && !this.isTemplatesView()) {
      const pathAsArray = JSON.parse(path);
      if (pathAsArray?.length > 1)
        this.currentDialogPath = pathAsArray;
    }
  }

  async prepareDeepSearchData() {
    if (!this.deepSearchMode) {
      this.foldPathsAndDirsRepresentation = [];
      this.modelsPathsAndDirsRepresentation = [];
      this.search();
    }
    else if (this.deepSearchMode && !this.foldersMap) {
      await this.getFoldersAndModelsArrays();
      await this.createFoldersMap(this.foldersForDeepSearch);
      this.deepSearch();
    }
    else {
      this.deepSearch();
    }
  }

  async getFoldersAndModelsArrays() {
    const that = this;
    this.spinnerFlag = true;
    await Promise.all([this.storage.getAllFolders(this.isGlobalTemplates()), this.storage.getAllModelsUserCanLoad(this.isGlobalTemplates())]).then(res => {
      that.foldersForDeepSearch = res[0].map(item => new DeepSearchFolder(item.father, item.id, item.title));
      that.modelsForDeepSearch =  res[1].map(item => new DeepSearchModel(item.archiveMode, item.description, item.directory_id,
        item.editBy, item.id, item.permissions, item.title));
      this.spinnerFlag = false;
    }).catch(err => {
    });
  }

  createFoldersMap(folders: Array<{ id: string, title: string, father: string }>) {
    const foldersMap: Map<string, { title: string, father: string }> = new Map<string, { title: string, father: string }>();
    folders.forEach(elm => {
      foldersMap.set(elm.id, {title: elm.title, father: elm.father});
    });
    this.foldersMap = foldersMap;
  }

  getLeafFather(leaf): string {
    return (<any>leaf).directory_id || (<any>leaf).father;
  }

  findPath(leaf: DeepSearchModel | DeepSearchFolder): {path: string, currentDialogPath: Array<{id: string, title: string}>} {
    let path = '';
    let pathArr = [];
    const arr = [];
    let dupIdInPath;
    if (leaf.id === 'root') {
      arr.push({id: leaf.id, title: 'Home'});
      path = 'Home';
      return {path: path, currentDialogPath: arr};
    }
    arr.push({id: leaf.id, title: leaf.title});
    let fatherId = this.getLeafFather(leaf);
    while (fatherId && fatherId !== 'root') {
      const titleAndFather = this.foldersMap.get(fatherId);
      if (!titleAndFather) {
        return null;
      }
      let title = titleAndFather.title;
      const father = titleAndFather.father;
      dupIdInPath = arr.find(elm => elm.id === fatherId);
      if (dupIdInPath) {
        return null;
      }
      if (fatherId === 'ORGTEMPLATES' || fatherId === 'PRIVATETEMPLATES')
        title = 'Templates';
      arr.push({id: fatherId, title: title});
      fatherId = father;
    }
      arr.push({id: fatherId, title: 'Home'});
      arr.reverse();
      pathArr  = arr.map(obj => obj.title);
      path = pathArr.join('/');
      path = path.replace('Home/Templates', 'Templates');
      return {path: path, currentDialogPath: arr};
    }

  deepSearch() {
    const that = this;
    if (this.searchInput.length < 3) {
      this.shouldShowDeepSearchResults = false;
      return;
    }
    this.shouldShowDeepSearchResults = true;
    const term = this.searchInput.toLowerCase();
    this.shownDeepFolders = this.foldersForDeepSearch.filter(folder => folder.title?.toLowerCase().includes(term));
    this.shownDeepModels = this.modelsForDeepSearch.filter(model => model.title?.toLowerCase().includes(term));
    this.modelsPathsAndDirsRepresentation = this.shownDeepModels.map(model => {
      return {model: model, pathAndDir: that.findPath(model)};
    }).filter(mod => !!mod.pathAndDir);
    this.foldPathsAndDirsRepresentation = this.shownDeepFolders.map(folder => {
      return {folder: folder, pathAndDir: that.findPath(folder)};
    }).filter(fold => !!fold.pathAndDir);
  }

  changeFocusFromDeepSearch($event) {
    const that = this;
    setTimeout( function() {
      that.shouldShowDeepSearchResults = false;
      }, 200 );
  }

  isExamplesView(): boolean {
    return this.screenTypeVal === ScreenType.EXAMPALES;
  }

  isTemplatesView(): boolean {
    return this.screenTypeVal === ScreenType.TEMPLATES;
  }

  getHeaderTitle() {
    let mode = this.mode === StorageMode.SAVE ? 'Save' : 'Load';
    if (this.screenTypeVal === ScreenType.TEMPLATES)
      return ' ';

    let type = ' Model';
    if (this.screenTypeVal === ScreenType.EXAMPALES)
      type = ' Example';

    return mode + type;
  }

  prepareString(name) {
    return (name.length > 9) ? (name.slice(0, 9)) + '...' : (name);
  }

  toggleFavorite($event, model: DisplayModel) {
    $event.stopPropagation();
    const favorite = this.favorites.find(f => f.id === model.id);
    if (favorite) {
      const idx = this.favorites.indexOf(favorite);
      this.favorites.splice(idx ,1);
      if (this.isExamplesView())
        this.storage.unsetFavoriteExample(model);
      else
        this.storage.unsetFavoriteTemplate(model);
    } else {
      this.favorites.push(model);
      if (this.isExamplesView()) {
        const exType = this.isSysExamples() ? 'SYS' : 'ORG';
        this.storage.setFavoriteExample(model, exType);
      } else if (this.isTemplatesView()) {
        const tType = TemplateType[this.templateType];
        this.storage.setFavoriteTemplate(model, <any>tType);
      }
    }
  }

  isFavorite(model: DisplayModel) {
    return !!this.favorites.find(f => f.id === model.id);
  }

  shouldShowCreateNewFolder() {

    if (this.mode === this.Mode.LOAD && (this.isExamplesView() || this.isTemplatesView()))
      return false;

    if (this.currentDialogPath[this.currentDialogPath.length - 1]?.id?.endsWith('_ver'))
      return false;

    if ((this.isExamplesView() || this.isTemplatesView()) && (this.userService.isSysAdmin() || this.userService.isOrgAdmin()))
      return true;

    if (!this.isExamplesView() && !this.isTemplatesView())
      return true;

    if (this.mode === this.Mode.SAVE &&  this.isTemplatesView() && this.templateType === TemplateType.PERSONAL)
      return true;

    return false;
  }

  shouldShowSaveAsSystemExample() {
    if (this.mode === this.Mode.SAVE && this.screenTypeVal === ScreenType.EXAMPALES && this.isSysExamples() && this.userService.isSysAdmin())
        return true;

    return false;
  }

  shouldShowSaveAsGlobalTemplate(): boolean {
    if (this.mode === this.Mode.SAVE && this.screenTypeVal === ScreenType.TEMPLATES && this.isGlobalTemplates() && this.userService.isSysAdmin())
      return true;

    return false;
  }

  shouldShowActionButton() {
    return this.mode !== this.Mode.SAVE || (this.exampleType !== ExamplesType.SYS && this.templateType !== TemplateType.SYS);
  }

  shouldShowRename() {
    if (this.selectedModel && (<DisplayModel>this.selectedModel).fatherModelId) {
      return false;
    }
    return (this.selectedModel || this.selectedFolder) && !(this.mode === this.Mode.LOAD && (this.isExamplesView() || this.isTemplatesView())) && !this.isRenameDisabled();
  }

  getFavoriteStarTooltip(model) {
    if (this.favorites.find(f => f.id === model.id))
      return 'Remove from Favorites';
    return 'Add to Favorites';
  }

  removeFolder() {
    if (!this.selectedFolder || !this.canRemoveFolder())
      return;
    const that = this;
    const ref = this._dialog.open(ConfirmDialogDialogComponent, {
      height: '180px',
      width: '320px',
      data: {
        message: 'Are you sure you want to delete the folder ' + this.selectedFolder.name + '?',
      }});
    const fid = this.selectedFolder.id;
    const sub = ref.afterClosed().subscribe((data) => {
      if (data === 'OK') {
        this.storage.removeFolder(fid, that.isExamplesView() && that.exampleType === ExamplesType.SYS,
          that.isTemplatesView() && that.templateType === TemplateType.SYS).then(res => {
          if (res.removed) {
            that.initAttributes();
            that.selectedFolder = undefined;
          } else {
            if (res.message.includes('models in it'))
              res.message += ' Also, check for possible archived models.'
            validationAlert(res.message, 3500);
          }
        });
      }
      sub.unsubscribe();
    });

  }

  getModelBasicNameOnSave() {
    const this_ = this;
    let visualElements = this.init.getOpmModel().getOpd('SD').visualElements;
    visualElements = visualElements.filter(elm => OPCloudUtils.isInstanceOfVisualProcess(elm));
    if (visualElements.length === 1) {
      if (this.init.opmModel.name.indexOf('Not Saved') !== -1) {
        this.name = (<OpmLogicalProcess>visualElements[0].logicalElement).text;
        return;
      }
    }
    const isThereSystematicProcess = visualElements.find(elm => {
      return (<OpmLogicalProcess>elm.logicalElement).affiliation === 0;
    });
    if (isThereSystematicProcess) {
      visualElements = visualElements.filter(proc => this_.isSystematic(<OpmVisualProcess>proc));
      if (visualElements.length === 1) {
        if (this.init.opmModel.name.indexOf('Not Saved') !== -1) {
          this.name = (<OpmLogicalProcess>visualElements[0].logicalElement).text;
          return;
        }
      }
    }
    const isThereRefinable = visualElements.find(elm => elm.isInzoomed() || elm.isUnfolded());
    if (isThereRefinable) {
      visualElements = visualElements.filter(elm => elm.isInzoomed() || elm.isUnfolded());
      if (visualElements.length === 1) {
        if (this.init.opmModel.name.indexOf('Not Saved') !== -1) {
          this.name = (<OpmLogicalProcess>visualElements[0].logicalElement).text;
          return;
        }
      }
    }
    if (visualElements.length > 0) {
      const visualsWithLinksNumber = visualElements.map(elm => this_.getProceduralLinksNumber(<OpmVisualProcess>elm));
      visualsWithLinksNumber.sort((a, b) => a.length - b.length);
      visualsWithLinksNumber.reverse();
      if (this.init.opmModel.name.indexOf('Not Saved') !== -1) {
        this.name = (<OpmLogicalProcess>visualsWithLinksNumber[0].process.logicalElement).text;
        return;
      }
    }
    return;
  }

  isSystematic(elem: OpmVisualProcess) {
    return !elem.getAffiliation();
  }

  getProceduralLinksNumber(process: OpmVisualProcess): {process: OpmVisualProcess, length: number } {
    const links = process.getLinks();
    const inGoing = links.inGoing.filter(link => link instanceof OpmProceduralLink);
    const outGoing = links.outGoing.filter(link => link instanceof OpmProceduralLink);
    const length = inGoing.concat(outGoing).length;
    return {process, length};
  }

  canRemoveFolder() {
    return ((this.mode === this.Mode.LOAD && (this.isExamplesView() || this.isTemplatesView())) === false) && this.selectedFolder;
  }


  shouldShowFolderPermission() {
    if (!this.selectedFolder || this.isImportMode)
      return false;

    if (this.selectedFolder && this.selectedFolder.id.endsWith('_ver'))
      return false;

    if (this.isTemplatesView() && this.isGlobalTemplates())
      return false;

    if (this.isTemplatesView() && (this.isPersonalTemplate() || this.isOrgTemplate()))
      return true;

    return this.isExamplesView() === false;
  }

  openFolderPermissions(folder?) {
    const folderPermissionDialog = this.init.dialogService.openDialog(FolderPermissionsDialogComponent, 700, 900, {
      folderID: folder?.id || this.selectedFolder.id,
      folderName: folder?.title || this.selectedFolder.name,
      allowMultipleDialogs: true,
      templates: this.isTemplatesView(),
      examples: this.isExamplesView()
    });
  }

  getFolderTooltip(folder: DisplayFolder):string {
    const name = folder.name;
    const arr = [];
    if (folder.permissions?.read)
      arr.push('R');
    if (folder.permissions?.write)
      arr.push('W');
    if (folder.permissions?.owner)
      arr.push('O');

    if (arr.length > 0)
      return name + ' [' + arr.join('/') + ']';

    return name;
  }

  goHome() {
    this.currentDialogPath = [{id: 'root', title: 'Home'}];
    this.initAttributes();
  }

  resize(col: string) {
    for (let item of $('.' + col))
      item.style.width = $('.modelsTableHeaderTitles.' + col)[0].style.width;
  }

  checkSizeChanges() {
    if ((<any>$('.modelNameCol')[0])?.style?.width?.includes('px'))
      this.resize('modelNameCol');
    if ((<any>$('.modelDescCol')[0])?.style?.width?.includes('px'))
      this.resize('modelDescCol');
    if ((<any>$('.modelDateCol')[0])?.style?.width?.includes('px'))
      this.resize('modelDateCol');
    if ((<any>$('.modelDateCol')[0])?.style?.width?.includes('px'))
      this.resize('modelDateCol');
    if ((<any>$('.modelAuthorCol')[0])?.style?.width?.includes('px'))
      this.resize('modelAuthorCol');
    if ((<any>$('#foldersHeader')[0])?.style?.width?.includes('px') && $('#leftSideFolders')[0])
      $('#leftSideFolders')[0].style.width = (<any>$('#foldersHeader')[0])?.style?.width;
  }

  onDropFolder($event: DragEvent, targetFolder: DisplayFolder) {
    const sourceFolder = JSON.parse($event.dataTransfer.getData('sourceFolder')) as DisplayFolder;
    const targetElement = $($event.target).parents('#foldersRow')[0] || $event.target;
    $(targetElement).removeClass('folderHover');
    this.pasteFolder(sourceFolder, targetFolder);
  }

  allowDrop(event) {
    event.preventDefault();
  }

  dragStart(event, folder: DisplayFolder) {
    event.dataTransfer.setData('sourceFolder', JSON.stringify(folder));
  }

  dragEnter($event) {
    $event.preventDefault();
   const target = $($event.target).parents('#foldersRow')[0] || $event.target;
    $(target).addClass('folderHover');
  }

  dragLeave($event) {
    $event.preventDefault();
    const target = $($event.target).parents('#foldersRow')[0] || $event.target;
    $(target).removeClass('folderHover');
  }

  setFolderToCut(folder: DisplayFolder) {
    this.cutFolder = folder;
  }

  canCutFolder() {
    return !this.selectedFolder || (<any>this.selectedFolder)?.permissions?.owner;
  }

  pasteFolderFromCut() {
    if (this.folders.find(f => f.id === this.cutFolder.id)) {
      validationAlert('Cannot paste at the same folder.', 5000, 'Error');
      this.cutFolder = undefined;
      return;
    }
    const dummyTarget: DisplayFolder = {
      id: this.getCurrentDirectoryId(),
      permissions: { read: true, write: true, owner: true }, // if illegal the server will block it
      name: '',
      type: DisplayFolderType.ORDINARY
    }
    this.pasteFolder(this.cutFolder, dummyTarget);
  }

  pasteFolder(sourceFolder: DisplayFolder, targetFolder: DisplayFolder) {
    if (sourceFolder.id === targetFolder.id) {
      validationAlert('Folder cannot be moved into its inner folders.', 5000, 'Error');
      return;
    }
    if (sourceFolder.id === 'shared') {
      validationAlert('Shared Folder Cannot be moved.', 5000, 'Error');
      return;
    }
    if (!targetFolder.permissions.write) {
      validationAlert('It seems you don\'t have a write permission to the destination folder.', 5000, 'Error');
      return;
    }
    const confirmDialog = this.init.dialogService.openDialog(ConfirmDialogDialogComponent, 200, 350, {
      allowMultipleDialogs: true,
      message: 'Warning:\n Moving a folder includes all the models, sub folders, archived and versions.\n Are you sure?',
      closeFlag: false
    });
    confirmDialog.afterClosed().subscribe((data) => {
      if (!data)
        return;
      this.spinnerFlag = true;
      this.storage.moveFolder(sourceFolder.id, targetFolder.id, this.isSysExamples(), this.isGlobalTemplates()).then(res => {
        if (res.success)
          this.initAttributes();
        else {
          validationAlert(res.message, 5000, 'Error');
          this.spinnerFlag = false;
        }
        this.setFolderToCut(undefined);
      }).catch(err => {
        this.spinnerFlag = false;
      })
    });

  }

  getFolderCssClass(folder: DisplayFolder) {
    let ret = '';
    if (folder === this.selectedFolder)
     ret = 'selected selectedFolder';
    if (folder.id === this.cutFolder?.id)
      ret += ' cutFolder';

    return ret.trim();
  }

  getRegularViewFolderCssClass(folder: DisplayFolder) {
    let ret = '';
    if (folder === this.selectedFolder)
      ret = 'selected';
    if (folder.id === this.cutFolder?.id)
      ret += ' cutFolder';

    return ret.trim();

  }

  showGIF($event, handlerGif = '') {
    if (!handlerGif)
      return;
    return OPCloudUtils.showGIF($event, handlerGif, false, true, 1000);
  }

  mouseLeave() {
    OPCloudUtils.removeAllExplainationsDivs();
  }

  shouldShowCurrentFolderPermissionsButton() {
    if (!this.currentDialogPath)
      return false;
    const current = this.currentDialogPath[this.currentDialogPath.length-1];
    if (this.currentDialogPath.find(p => p && ['Templates', 'Examples'].includes(p.title)))
      return false;
    if (!current || ['Home', 'Shared'].includes(current.title))
      return false;
    return true;
  }

  openCurrentFolderPermissions() {
    const current = this.currentDialogPath[this.currentDialogPath.length-1];
    this.openFolderPermissions(current)
  }
}

