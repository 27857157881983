import {AfterViewInit, Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import { CodeModel } from "@ngstack/code-editor";
import {PathsFromObjectsToProcessCalculator} from "../../configuration/elementsFunctionality/paths-from-objects-to-process-calculator";
import {code} from "../../models/ConfigurationOptions";
import {VariablesCalculator} from "../../configuration/elementsFunctionality/variables-calculator";
import {UserDefinedFunctionExecutor} from "../../configuration/elementsFunctionality/user-defined-function-executor";
import {PythonFunctionExecutor} from "../../configuration/elementsFunctionality/python-function-executor";
import {WebSocketCommunicator} from "../../configuration/elementsFunctionality/communication-object";

@Component ({
  selector: 'python-code-editor-dialog',
  templateUrl: 'python-code-editor-dialog.html',
  styleUrls: ['python-code-editor-dialog.css']
})
export class PythonCodeEditorDialog implements AfterViewInit {

  private theme: string;
  private codeModel: CodeModel;
  private options;
  private monaco: any;
  private themes: string[];
  private spinnerFlag: boolean = true;
  private init;

  constructor(
    @Optional() public dialogRef: MatDialogRef<PythonCodeEditorDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.init = data.initRappid;
    this.theme = 'vs';
    this.codeModel = {
      language: 'python',
      uri: 'main.json',
      value: this.data.code,
      dependencies: []
    };
    this.options = {
      contextmenu: true,
      colorDecorators: true,
      minimap: {
        enabled: true
      }
    };
    this.monaco = (<any>window).monaco;
    this.monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      diagnosticCodesToIgnore: [1108, 2304, 7027]
    });
    this.getExtraData();
  }

  changeTheme($event) {
    const value = $event.target.value;
    if (value === 'VS Dark')
      return this.defaultTheme();
    else
      this.monaco.editor.setTheme(value);

    this.init.updateDB({codeEditorTheme: value});
  }

  async loadThemes() {
    $('#codeEditor').css({'opacity' :'0'});
    this.themes = ["Active4D","Merbivore","Solarized-light","Tomorrow","krTheme","Clouds","GitHub",
      "SpaceCadet","Twilight","monoindustrial","Amy","Cobalt","IDLE","Monokai","Sunburst","Dawn","Katzenmilch","Blackboard",
      "Tomorrow-Night-Blue","Dracula","LAZY","Tomorrow-Night-Bright","Zenburnesque","Dreamweaver","Tomorrow-Night-Eighties",
      "iPlastic","Eiffel","Solarized-dark","Tomorrow-Night","idleFingers"];
    for (const name of this.themes) {
      const data = await fetch('./assets/codeEditorThemes/' + name + '.json');
      const jsn = await data.json();
      this.monaco.editor.defineTheme(name, jsn);
    }
    this.themes.push('VS Dark');
    const userChoice = this.init.oplService.settings.codeEditorTheme;
    if (userChoice && this.themes.includes(userChoice)) {
      this.monaco.editor.setTheme(userChoice);
      (<HTMLSelectElement>$('#themeSelect')[0]).selectedIndex = this.themes.indexOf(userChoice);
    }
    else
      this.monaco.editor.setTheme('Active4D');
    $('#codeEditor').css({'opacity' :'1'});
    this.spinnerFlag = false;
  }

  ngAfterViewInit() {
    $('.mat-dialog-container').css({'background-color' :'rgba(255,255,255,0)', 'padding': '0px', 'box-shadow': 'none'});
    this.loadThemes();
  }

  defaultTheme() {
    this.monaco.editor.setTheme('vs-dark');
  }

  onCodeChanged() {
  }

  update() {
    const val = this.codeModel.value;
    return this.dialogRef.close(val.split('-----#\n\n')[1]);
  }

  cancel() {
    this.dialogRef.close();
  }

  onStartDrag(event) {
    if (!!event.touches)
      return;
    event.preventDefault();
    const that = this;
    window.onmousemove = function (e) { that.moveDrag(e) };
    window.onmouseup = function (e) { that.endDrag(e) };
  }

  endDrag(event) {
    window.onmousemove = function (e) { };
    window.onmouseup = function (e) { };
    const rect = $('.mat-dialog-container')[0].getClientRects()[0];
    if (rect && (rect.x - 20 > window.innerWidth || rect.y - 20 > window.innerHeight)) {
      this.dialogRef.updatePosition({ left: '100px', top: '100px' })
    }
  }

  moveDrag(event) {
    // const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    // const top = Math.max(10, (event.clientY - 0 + scrollTop)) + 'px';
    // const left = (event.clientX - 0 - window.innerWidth / 2) + 'px';
    const rect = $('.mat-dialog-container')[0].getClientRects()[0];
    const left = Math.max(0, rect.left + event.movementX) + 'px';
    const top = Math.max(0, rect.top + event.movementY) + 'px';
    this.dialogRef.updatePosition({ left: left, top: top })
  }

  private getExtraData() {
    const globalRunTimeEnvironment = { objects: new Map<string, any>(), alreadyRanLinks: [], processesToIgnore: [] };
    const visual = this.data.visual;
    const model = visual.logicalElement.opmModel;
    const opd = model.getOpdByThingId(visual.id);
    const valuesArray = [];
    const pathsCalculator = new PathsFromObjectsToProcessCalculator(opd, visual, model, code.Python, globalRunTimeEnvironment);
    const all_paths = pathsCalculator.calculate(opd, valuesArray);
    let all_variables_str = [];
    let non_legal_variables_str = [];
    let alias = [];
    const vc = new VariablesCalculator(all_paths, valuesArray);
    const userInputVar = '\nuserInput = None';
    const executor = new PythonFunctionExecutor(model, new WebSocketCommunicator(this.init.handlerPython, this.init.activatePython), visual);
    const function_variables = vc.calc_variables_str(all_variables_str, code.Python, non_legal_variables_str, alias);
    const functionContent = executor.replaceVariables('', all_variables_str, non_legal_variables_str);
    let functionInput = userInputVar + '\n' + function_variables + '\n' + functionContent as any;
    let aliasArr = '';
    for (const item of alias) {
      delete item['lid'];
    }
    if (alias.length > 0) {
      aliasArr = 'aliasArr = ' + JSON.stringify(alias, null, 2);
      let count = 0;
      aliasArr = aliasArr.replace(/{/g, function() {
          count++;
          return count + ':{';
        }
      );
      aliasArr = aliasArr.replace('[', '{');
      aliasArr = aliasArr.replace(']', '}');
    }
    functionInput = aliasArr + functionInput;
    /*let updateValue = '\'\'\'\n* @param {string} alias\n* @param {string | number} value\n\'\'\'';
    updateValue += '\ndef updateValue(alias, value):' + '\n';*/
    const warning = '\n\n#--------Don\'t edit or change the lines above here. These are runtime variables that will be updated with real values on execution-------#\n\n';
    this.codeModel.value = functionInput.replaceAll(';', '\n') + warning + this.codeModel.value;
  }
}
