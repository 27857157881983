import { Component, OnInit, ViewContainerRef, Injectable, DoCheck } from '@angular/core';
import { GraphService } from '../services/graph.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';import { InitRappidService } from '../services/init-rappid.service';
import { AboutDialogComponent } from '../../dialogs/About/about';
import { UploadFile } from '../../dialogs/FileUploader/FileUploader';
import { ClearCanvasComponent } from '../../dialogs/clear-canvas/clear-canvas';
import { UserService } from '../services/user.service';
import {
  copyAllObjectValues,
  updateAllObjectValues
} from '../../configuration/elementsFunctionality/computationalPart';
import * as FileSaver from 'file-saver';
import { ChooseExportedFileNameComponent } from '../../dialogs/choose-exported-file-name/choose-exported-file-name';
import { SaveScreenshotComponent } from '../../dialogs/saveScreenshot-dialog/saveScreenshot';
import { OplService } from "../../opl-generation/opl.service";
import { ToolbarComponent } from './toolbar.component';
import { ModelStorageService } from '../services/storage/model-storage.service';
import {redoshared, undoShared, validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';
import {ConfirmDialogDialogComponent} from "../../dialogs/confirm-dialog/confirm-dialog";
import {ContextService} from "../../modules/app/context.service";

let valuesArray = new Array();

const commandGroups = [
  {
    group: 'editor',
    commands: [
      { name: 'undo', tooltip: 'Undo', icon: 'undo' },
      { name: 'redo', tooltip: 'Redo', icon: 'redo' },
      // { name: 'clearcanvas', tooltip: 'Clear Canvas', icon: 'delete_sweep' },
      // { name: 'newModel', tooltip: 'New Model', icon: 'fiber_new' }
    ]
  },
  {
    group: 'file',
    commands: [
      // { name: 'executeIfLogged(exportModel)', tooltip: 'export', icon: 'backup' },
      // { name: 'importModel', tooltip: 'Import/Export opx Model', icon: 'import_export' },
      { id: 'save', name: 'executeIfLogged(saveModel)', tooltip: 'Save', icon: 'save' },
      // { name: 'executeIfLogged(saveModelAs)', tooltip: 'Save As', icon: 'library_books' },
      { id: 'load', name: 'executeIfLogged(loadModel)', tooltip: 'Load', icon: 'open_in_browser' },
      // Export OPL button
      // { name: 'executeIfLogged(exportOPL)', tooltip: 'Export OPL', icon: 'publish' },
      { name: 'copyLink', tooltip: 'Copy link URL for current model', icon: 'link' },
      // Export ScreenShots button
      // { name: 'executeIfLogged(saveScreenshot)', tooltip: 'Save Screenshot', icon: 'camera_alt' }
    ]
  },
  {
    group: 'zoom',
    commands: [
      // { name: 'zoomin', tooltip: 'Zoom In', icon: 'zoom_in' },
      // { name: 'zoomout', tooltip: 'Zoom Out', icon: 'zoom_out' },
      // { name: 'zoomtofit', tooltip: 'Zoom to Fit', icon: 'zoom_out_map' },
      // { name: 'zoomtodefault', tooltip: 'Default Zoom', icon: 'youtube_searched_for' },
      // { name: 'about', tooltip: 'About', icon: 'info' },
      { name: 'execute', tooltip: 'Execute', icon: 'send' },
      // { name: 'list', tooltip: 'List Elements', icon: 'list' } moved to setting
    ]
  }
];

@Component({
  selector: 'opc-toolbar',
  template: `
    <div class="button-row" align="right">
      <div class="button-group" *ngFor="let commandGroup of commands" align="right">
        <button *ngFor="let command of commandGroup.commands" id="{{command.id}}"
                mat-mini-fab [disabled]="['redo'].includes('k')"
                [matTooltip]="command.tooltip"
                class="button"
                (click)="buttonClick(command)">
          <mat-icon *ngIf="!command['progressSpinner']">{{command.icon}}</mat-icon>
          <div *ngIf="command['progressSpinner']">
            <mat-spinner [diameter]="25" class="mat-spinner-color" *ngIf="command['progressSpinner']()" ></mat-spinner>
          </div>
        </button>
      </div>
    </div>
    <div>
    <!--<opcloud-element-tool-bar></opcloud-element-tool-bar>-->
    </div>
  `,
  styleUrls: ['./rappid-toolbar.component.scss']
})
export class RappidToolbarComponent extends ToolbarComponent implements OnInit, DoCheck {
  graph;
  // userID;
  // modelName;
  private commandManager;
  private OPX_JSON: any;
  private myUrl: any;
  commandGroups = commandGroups;
  autosaveObservable;
  isSubscribe;
  currentUser: any;
  user;
  private scenario: {
    project?: boolean;
    path?: string;
    name?: string;
    model?: boolean,
    modelData?: any
  } = {};

  constructor(
    private graphService: GraphService,
    private initRappidService: InitRappidService,
    private _dialog: MatDialog,
    private userService: UserService,
    private oplService: OplService,
    private context: ContextService,
    private model: ModelStorageService) {
    super();
    this.userService.user$.subscribe(user => this.currentUser = user);
  }

  getCommands() {
    return commandGroups;
  }

  // makeUrl(): string {
  //   const name: string = this.graphService.modelObject.name || '';
  //   const path: string = this.graphService.modelObject.path || '';
  //   if (name.trim().length + path.trim().length > 0) {
  //     let url = window.location.origin + '/load?path=' + path + '&name=' + name;
  //     url = url.replace(/ /g, '%20');
  //     return url;
  //   }
  //   return null;
  // }

  ngOnInit() {
    this.graph = this.graphService.getGraph();
    this.user = this.userService.user$;
  }

  ngDoCheck() {
    this.commandGroups[0].commands[0].tooltip = this.initRappidService.getOpmModel().undoRedo.getLastUndoStateReason();
    this.commandGroups[0].commands[1].tooltip = this.initRappidService.getOpmModel().undoRedo.getLastRedoStateReason();
  }

  undo() {
    undoShared();
  }

  redo() {
    redoshared();
  }

  importModel() {
    this._dialog.open(UploadFile);
  }

  copyLink() {
    this.model.openUrlDialog();
  }

  async saveModel() {
    if (this.initRappidService.modelService.modelObject?.modelData?.dirsPath?.find(d => d.id === 'ORGEXAMPLES')) {
      validationAlert('To prevent overriding examples by mistake, this shortcut is disabled. Please use the menu saving option.', 3500, 'Error');
      return;
    }
    if (this.currentUser.userData.isViewerAccount) {
      validationAlert('This operation is not available for viewer accounts. Please contact your organization\'s admin to make changes. Thank you!', 5000, 'ERROR');
      return;
    }
    const savingCommand = this.commandGroups.find(cg => cg.group === 'file').commands.find(cmd => cmd.id === 'save');
    savingCommand["progressSpinner"] = () => {
      if (this.context.isCurrentlySavingModel) {
        return true;
      } else {
        delete savingCommand["progressSpinner"];
      }
    };
    const image = await this.initRappidService.getModelImage();
    this.model.save(image);
  }

  loadModel() {
    this.model.openLoadModelDialog();
  }

  executeIfLogged(func) {
    //if (this.userService.isUserLoggedIn$) {
    return this[func]();
    /*} else {
      this._dialog.open(SignInComponent, {
        viewContainerRef: this.viewContainer,
      });
    }*/
  }


  zoomin() {
    this.initRappidService.paperScroller.zoom(0.2, { max: 4 });
  }

  zoomout() {
    this.initRappidService.paperScroller.zoom(-0.2, { min: 0.2 });
  }

  zoomtofit() {
    this.initRappidService.paperScroller.zoomToFit();
  }

  zoomtodefault() {
    this.initRappidService.paperScroller.zoom(1, { absolute: true });
  }

  about() {
    const dialogRef = this._dialog.open(AboutDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {

      }
    });
  }

  clearcanvas() {
    const opmModel = this.initRappidService.getOpmModel();
    const dialogRef = this._dialog.open(ClearCanvasComponent);
    // context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'clear') {
        while (opmModel.currentOpd.visualElements.length > 0) {
          opmModel.remove(opmModel.currentOpd.visualElements[0].id);
        }
        this.graph.resetCells([]);
      }
    });
  }

  newModel() {
    this.initRappidService.setSelectedElementToNull();
    this.model.newModel();
  }

  execute() {
    if (!this.userService.user.userData.isExecutionUser) {
      this._dialog.open(ConfirmDialogDialogComponent, {
        height: '280px',
        width: '430px',
        data: { message: 'You found a feature that is open in the full version!\n' +
            'Upgrading will unlock it.\n' +
            'To use this feature write to: contact@opcloud.tech\n\n' + 'The animated simulation / model execution provides' +
            ' for a deep understanding of the system\'s behavior,' +
            ' finds conceptual modeling errors, do calculations,' +
            ' and connecting to external systems.', okName: 'Got it!', okColor: '#1a3763', centerText: true, closeFlag: true },
      });
      return;
    }
    if (this.initRappidService.selectedElement) {
      this.initRappidService.setSelectedElementToNull();
    }
    // If clicked on the execute icon and already was in execution mode then stop execution mode and restore values
    if (this.initRappidService.ExecuteMode === true && this.initRappidService.Executing === false) {
      this.initRappidService.ExecuteMode = false;
      updateAllObjectValues(valuesArray, this.initRappidService);
      valuesArray = [];
    } else { // If clicked on the execute icon and wasn't in execution mode then start execution mode
      this.initRappidService.ExecuteMode = true;
      valuesArray = copyAllObjectValues(valuesArray, this.initRappidService);
    }
    // If clicked on the execute icon during execution then alert
    if (this.initRappidService.Executing === true) {
      alert('Executing is still running!');

    }
  }
  /*
  execute() {
    // array that contains all the link ids needed to be visualized by a token
    this.initRappidService.setSelectedElementToNull();
    const linksArray = [];
    execute(linksArray, this.initRappidService, 'SD'); // start execute from SD graph
    showExecution(linksArray, 0, this.initRappidService);
  }
  */

  /**
   * Alon: button for mapping elements in the model
   */
  list() {
    const options = this.initRappidService;
    this.graphService.displayElements(options);
  }
  // This function is exporting all OPL sentences to an HTML file.
  // The exported file includes the OPL sentences for each SD and for all SDs without duplication of OPL sentences.
  exportOPL() {
    // Open a dialog box that will allow the user to choose the file name
    const dialogRef = this._dialog.open(ChooseExportedFileNameComponent, {
      height: '270px',
      width: '400px',
    });
    // This function invokes the exporting OPL process
    dialogRef.afterClosed().subscribe((fileName) => {
      if (fileName === 'CLOSED') { // If the user pressed CANCEL.
        return;
      } else {
        // If the user pressed OK but didn't give a name to the exported file -
        // get default file name
        if ((fileName.trim() === '') || !fileName) {
          fileName = this.initRappidService.opmModel.createDefaultModelName();
        }
        // The process of saving the exported file is starting.
        // An array that will include the OPL sentences for each OPD separately.
        const arrayOfOpds = new Array();
        arrayOfOpds.push('<html><body>'); // Open an HTML Tag for arrayOfOpds.
        // The next lines define the colors of words according to their type - object \ process \ state for arrOfSD.
        arrayOfOpds.push('<style> .object { color: #00b050; } </style>');
        arrayOfOpds.push('<style> .process { color: #0070c0; } </style>');
        arrayOfOpds.push('<style> .state { color: #808000; } </style>');
        // An array that will include the OPL sentences for all Opds together
        // without duplication.
        const arrOfAllOpl = new Array();
        arrOfAllOpl.push('<html><body>'); // Open an HTML Tag for arrOfAllOpl.
        /// The next lines define the colors of words according to their type - object \ process \ state for arrOfAllOpl.
        arrOfAllOpl.push('<style> .object { color: #006400; } </style>');
        arrOfAllOpl.push('<style> .process { color: #00008B; } </style>');
        arrOfAllOpl.push('<style> .state { color: #808000; } </style>');
        arrOfAllOpl.push('<b>OPL spec.</b>'); // Insert a title for the arrOfAllOpl array.
        arrOfAllOpl.push('<br>');
        // Save the current Opd that the user is editing, for rendering it at the end of the function.
        const currentOpd = this.initRappidService.opmModel.currentOpd;
        // Get all existing OPDs in the graph.
        const allOpd = this.initRappidService.opmModel.opds;
        // This loop goes over all OPDs and inserts the OPL sentences to the arrays.
        for (let i = 0; i < allOpd.length; i++) {
          this.graphService.renderGraph(allOpd[i], this.initRappidService); // Goes to the next OPD in the allOpd array.
          // Get the name of the current OPD.
          const nameOfOpd = allOpd[i].name;
          arrayOfOpds.push('<b>');
          arrayOfOpds.push(nameOfOpd); // Insert the name of the current OPD as a title in the arrOfSD array.
          arrayOfOpds.push('</b>');
          arrayOfOpds.push('<br>');
          // Get the cells that contain the OPL sentences of the current OPD.
          const cells = this.oplService.generateOpl(); // YANG's function
          // This loop goes over all of the cells and inserts the OPL sentences to the arrays.
          for (let j = 0; j < cells.length; j++) {
            if (cells[j].opl) { // Check if the cell contains an OPL sentence.
              // Get the OPL sentence of the cell.
              const innerHTML = cells[j].opl;
              arrayOfOpds.push(innerHTML); // Insert the OPL sentence into the arrOfSD array.
              arrayOfOpds.push('<br>');
              // Initialization of a temp boolean variable.
              // It will be used as a flag to check if the current OPL sentence already exists in the arrOfAllOpl array.
              if (!arrOfAllOpl.includes(innerHTML)) {
                arrOfAllOpl.push(innerHTML); /// Insert the OPL sentence into the arrOfAllOpl array.
                arrOfAllOpl.push('<br>');
              }
              /*
              let exists = 'False';
              // This loop goes over the arrOfAllOpl array and checks if the current OPL sentence already exists in the array.
              for (let k = 0; k < arrOfAllOpl.length; k++) {
                if (arrOfAllOpl[k] === innerHTML) {
                  exists = 'True';
                }
              }
              if (exists === 'False') { /// Check if the flag indicates that the current OPL sentence doesn't already exist in the array.
                arrOfAllOpl.push(innerHTML); /// Insert the OPL sentence into the arrOfAllOpl array.
                arrOfAllOpl.push('<br>');
              }*/
            }
          }
          arrayOfOpds.push('<br>');
        }
        arrayOfOpds.push('</body></html>'); // Close an HTML Tag for arrayOfOpds.
        arrOfAllOpl.push('</body></html>'); // Close an HTML Tag for arrOfAllOpl.
        this.graphService.renderGraph(currentOpd, this.initRappidService); // Goes back to the OPD that the user is editing.
        // Concatenation of the arrOfSD and the arrOfAllOpl arrays.
        const exportFileContent = arrayOfOpds.concat(arrOfAllOpl);
        // Create the exported file.
        const exportFile = new Blob(exportFileContent, { type: 'html/plain;charset=utf-8' });
        FileSaver.saveAs(exportFile, fileName + '.html'); // Save the exported file.
      }
    });
  }
  /* dialog box for saving opds as jpeg files */
  saveScreenshot() {
    this._dialog.open(SaveScreenshotComponent);
  }
  getCurrentUser() {
    return this.user;
    /* return new Promise(resolve => {
       this.userService.user$.take(1)
         .subscribe(user => {
           this.currentUser = {
             uid: user ? user.uid : '',
             displayName: user ? user.displayName : 'Anonymous',
             email: user ? user.email : '',
           };
           resolve(user);
         });
     });*/
  }
}
