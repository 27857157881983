import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private zone: NgZone, private errorService:ErrorHandlerService) { }

  handleError(error: any) {
    this.zone.run(() => {
        this.errorService.logError(error.stack || error.code);
    });
  }
}
