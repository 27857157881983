import { Injectable } from '@angular/core';
import {OplTables, Languages, oplDefaultSettings, updateTemplates, defaultSettings, DisplayOpt} from './opl-database';
import { GraphService } from '../rappid-components/services/graph.service';
import { oplTemplates } from './opl-database';
import { oplFunctions, oplGenerating } from './opl-functions';
import { UserDetails } from '../rappid-components/services/user.service';
import { Essence } from '../models/ConfigurationOptions';
import { OplConfig } from './opl.config';
import { Subject } from 'rxjs/Subject';
import {OntologyItem} from "../modules/Settings/OrgOntology/ontologyInterfaces";
import {OPCloudUtils} from "../configuration/rappidEnviromentFunctionality/shared";
import {defaultStateStyleSettings} from "../models/Defaults/style";

export const defaultObjectStyleSettings = {
  font_size: 14,
  font: 'Arial',
  text_color: '#000002', // was changed so color input would apply changes at first
  border_color: '#70E483',
  fill_color: '#fdffff', // was changed so color input would apply changes at first
}

export const defaultProcessStyleSettings = {
  font_size: 14,
  font: 'Arial',
  text_color: '#000002', // was changed so color input would apply changes at first
  border_color: '#3BC3FF',
  fill_color: '#fdffff', // was changed so color input would apply changes at first
}


export interface StyleSettings {
  font_size?;
  font?;
  text_color?;
  border_color?;
  fill_color?;
}

export const defaultPythonConnectionSettings = {
  server: 'localhost',
  port: '8765'
};

export const defaultMySQLConnectionSettings = {
  hostname: 'localhost',
  port: '3306',
  username: 'root',
  password: '1234',
  schema: 'schema',
  ws_hostname: 'localhost',
  ws_port: '5566'
};

export const defaultGraphDBConnectionSettings = {
  graphdb_api: 'bolt://localhost:7687',
  username: 'neo4j',
  password: 'opcloudNeo4j',
};

export const defaultCalculationsServerSettings =  {
  computingServerURL: 'http://localhost:3000',
  computingServerCalculations: true
}

export const defaultRosConnectionSettings = {
  server: 'localhost',
  port: '3000'
};

export const defaultMqttConnectionSettings = {
  server: 'localhost',
  port: '9883'
};

export const defaultAllowUsersConnectionSettings = true;

export const defaultTutorialMode = true;

export const defaultMaxUsersEnabled = false;

export interface MySQLConnectionSettings {
  hostname?;
  port?;
  username?;
  password?;
  schema?;
  ws_hostname?;
  ws_port?;
}
export interface GraphDBConnectionSettings {
  graphdb_api?;
  username?;
  password?;
}

export interface CalculationsServerConnectionSettings {
  computingServerURL?: string;
  computingServerCalculations?: boolean;
}

export interface ConnectionSettings {
  server?;
  port?;
}

export const defaultArchiveDaysIntervalSettings = 30;

export interface OplSettings {
  language?: string;
  displayOpt?: string;
  unitsOpt?: string;
  aliasOpt?: string;
  essence?: Essence;
  oplNumbering?: boolean;
  highlightOpd?: boolean;
  highlightOpl?: boolean;
  logSharingPermission?: boolean;
  opdTreeProcessesAutoArrangement?: boolean;
  spellCheck?: boolean;
  Notes?: boolean;
  SDNames?: boolean;
  dragSearchAuto?: boolean;
  haloDefaultMode?: boolean;
  autoFormat?: boolean;
  style?: {
    object?: StyleSettings;
    process?: StyleSettings;
    state?: StyleSettings;
  };
  connection?: {
    ros?: ConnectionSettings;
    mqtt?: ConnectionSettings;
    python?: ConnectionSettings;
    mysql?: MySQLConnectionSettings;
    graphdb?: GraphDBConnectionSettings;
    calculationsServer?: CalculationsServerConnectionSettings;
    allow_users?: boolean;
  };
  archive?: {
    days_interval?: number;
  };
  markThings?: boolean;
  syncOplcolorsFromOpd?: boolean;
  timeDurationUnitsDigits?: number;
  numericComputationalDigits?: number;
  navigatorEnabled?: boolean;
  // flag to enable/disable chat for an org
  chatEnabled?: boolean;
  logCollectingEnabled?: boolean;
  ignoreUserLogSharingPermission?: boolean;
  validationTime?: string;
  validationEnforcementLevel?: string;
  tutorialMode?: boolean;
  codeEditorTheme?: string;
  loadScreenViewType?: boolean;
  loadScreenSortBy?: string;
  loadScreenSortDirections?: any;
  multiDeletion?: boolean;
  bringConnectedSettings?: {
    proceduralEnablers: boolean,
    proceduralTransformers: boolean,
    fundamentals: boolean,
    tagged: boolean
  },
  gridSettings?: {
    state: boolean,
    color: string,
    thickness: number,
    scaleFactor: number,
    gridSize: number
  },
}

@Injectable()
export class OplService {

  options;
  graph;
  dictionary;
  public readonly userOplSettings: OplSettings;
  orgOplSettings;
  user: any;

  model;

  oplOpen: boolean; // a boolean variable that indicates if updateOpl function will be called
  oplSwitch: Subject<any>; // a boolean variable that changes its value in certain functions in order to trigger ngOnChange
  queryResultLabel: Subject<any>;
  public testingMode: boolean;
  public areSettingsLoaded: boolean;

  // Boolean to indicate if present 'OPM Result' label instead of 'OPL'

  constructor(readonly config: OplConfig) {
    this.orgOplSettings = Object.assign(config.organization());
    // this.setSettingsToUndefined(this.orgOplSettings); // to enable organization settings to be the settings if needed
    this.userOplSettings = config.user();
    this.updateDefaultSettings();
    // this.setSettingsToUndefined(this.userOplSettings); // to enable organization settings to be the settings if needed
    // this.updateDefaultSettings();
    this.oplOpen = true;
    this.oplSwitch = new Subject();
    this.queryResultLabel = new Subject();
    this.testingMode = false;
    this.areSettingsLoaded = false;
  }

  // private setSettingsToUndefined(settingsToSet){
  //   Object.keys(settingsToSet).forEach(key => settingsToSet[key] = undefined);
  // }
  public updateUserSettings(settings: OplSettings) {
    const currProc = this;
    const clean = currProc.cleanOplSettings(settings);
    Object.keys(clean).forEach((key) => {
      currProc.userOplSettings[key] = clean[key];
    });
    currProc.updateDefaultSettings();
  }

  private cleanOplSettings(settings: OplSettings): UserDetails {
    const param = {};
    Object.keys(settings).forEach(function (key) {
      if (settings[key] !== undefined) {
        // param[key] = (key === 'essence') ? String(settings[key]) : settings[key];
        param[key] = settings[key];
      }
    });
    return param;
  }

  updateOrgSettings() {
    // const orgName = this.userS.user.userData.organization;
    this.removeDefaultTables();
    // this.orgS.updateOrganization(orgName, { 'defaultSettings': this.orgOplSettings });
    this.addDefaultTables();
    this.updateDefaultSettings();
  }

  /*
  updateUserSetings() {
    let thisProcess = this;
    Object.keys(this.userOplSettings).forEach(function (key) {
      if (thisProcess.userOplSettings[key] === undefined) {
        return;
      }
      let details = {};
      details[key] = thisProcess.userOplSettings[key];
      if (key === 'essence') {
        details[key] = thisProcess.essenceString(thisProcess.userOplSettings[key]);
      }
      //thisProcess.userS.updateDB(details);
    });

    this.updateDefaultSettings();
  }*/


  toggleOplNumbering() {
    this.userOplSettings.oplNumbering = !this.userOplSettings.oplNumbering;
  }

  async areSettingsAlreadyLoaded(): Promise<boolean> {
    const that = this;
    if (this.areSettingsLoaded) {
      return Promise.resolve(true);
    } else {
      while (!this.areSettingsLoaded) {
        await OPCloudUtils.waitXms(100);
        if (that.areSettingsLoaded) {
          return Promise.resolve(true);
        }
      }
    }
  }

  loadOrgSettings(res) {
    const thisProcess = this;
    let resAtKey;
    if (res) {
      Object.keys(res).forEach(function (key) {
        // this field should be initialized
        // if (thisProcess.orgOplSettings[key] === undefined || thisProcess.orgOplSettings[key].length === 0 ) {
        // this if was removed to allow updating the organization according to the database, for example if it was changed.
        resAtKey = (res[key] === undefined) ? defaultSettings.organization[key] : res[key]; // if the organization data is undefined
        if (key === 'essence') {
          thisProcess.orgOplSettings[key] = thisProcess.convertEnum(resAtKey);
          return;
        }
        if (key === 'oplTables') {
          let new_lans = {};
          for (const lan of Object.keys(resAtKey)) {
            if (Languages.indexOf(lan) === -1) {
              new_lans[lan] = resAtKey[lan];
            }
          }
          thisProcess.orgOplSettings.oplTables = new_lans;
          return;
        }
        // if the organization data includes some styling data
        if (key === 'style') {
          thisProcess.handleOrganizationStyleKey(res);
        }
        if (key === 'connection') {
          thisProcess.handleOrganizationConnectionKey(res);
        }
        if (key === 'archive') {
          thisProcess.handleOrganizationArchiveKey(res);
        }
        if (key === 'displayOpt') {
          thisProcess.orgOplSettings[key] = thisProcess.convertDisplayOptForBackwardCompatibility(resAtKey);
        } else if (key !== 'style') {
          thisProcess.orgOplSettings[key] = resAtKey;
        }
        /* if the organization details didnt contain styling data, should be initialized the object style will
        not be undefined */
        if (thisProcess.orgOplSettings.style === undefined) {
          thisProcess.orgOplSettings.style = thisProcess.getUndefinedStyleSettings();
        }
        // }
      });
      // for organizations without those keys (for example, new organizations)
      if (thisProcess.orgOplSettings.style === undefined) {
        thisProcess.orgOplSettings.style = thisProcess.getUndefinedStyleSettings();
      }
      if (thisProcess.orgOplSettings.connection === undefined) {
        thisProcess.orgOplSettings.connection = thisProcess.getUndefinedConnectionSettings();
      }
      this.addDefaultTables();
    }
    this.updateDefaultSettings();
  }
  setDefaultTimeDurationUnitsDigits() {
    this.userOplSettings['timeDurationUnitsDigits'] = 2;
  }

  loadUserSettings(userData) {
    const Highlight_OPL_OPD_qnd_Spellcheck = { highlightOpl: true, highlightOpd: true, spellCheck: false };
    if (!userData) {
      userData = this.config.user();
      this.cloneDefaultSettings(userData);
      this.setUpdatedSettings(userData, Highlight_OPL_OPD_qnd_Spellcheck);
    }
    const thisProcess = this;
    Object.keys(thisProcess.userOplSettings).forEach(function (key) {
      // if this users field is not initialized yet
      if (thisProcess.userOplSettings[key] === undefined || thisProcess.userOplSettings[key].length === 0) {
        // for example, some of the users spellcheck value is initialized with an empty string, so it needs to be checked
        if (userData[key] === undefined || userData[key].length === 0) {
          // defaults settings of those fields
          switch (key) {
            case ('syncOplcolorsFromOpd'): {
              thisProcess.userOplSettings[key] = defaultSettings.user[key];
              break;
            }
            case ('timeDurationUnitsDigits'): {
              thisProcess.setDefaultTimeDurationUnitsDigits();
              break;
            }
            case ('numericComputationalDigits'): {
              thisProcess.userOplSettings[key] = defaultSettings.user['numericComputationalDigits'];
              break;
            }
            case ('Notes'): {
              thisProcess.userOplSettings[key] = thisProcess.orgOplSettings['displayNotes'];
              break;
            }
            case ('navigatorEnabled'): {
              if (userData.hasOwnProperty(key))
                thisProcess.userOplSettings[key] = userData['navigatorEnabled'];
              else
                thisProcess.userOplSettings[key] = defaultSettings.user.navigatorEnabled;
              break;
            }
            case ('chatEnabled'): {
              if (userData.hasOwnProperty(key))
                thisProcess.userOplSettings[key] = userData['chatEnabled'];
              else
                thisProcess.userOplSettings[key] = defaultSettings.user.chatEnabled;
              break;
            }
            case ('opdTreeProcessesAutoArrangement'): {
              thisProcess.userOplSettings[key] = defaultSettings.user.opdTreeProcessesAutoArrangement;
            }
            case ('spellCheck'):
            case ('highlightOpd'):
            case ('highlightOpl'): {
              thisProcess.setUpdatedSettings(thisProcess.userOplSettings, Highlight_OPL_OPD_qnd_Spellcheck, key);
              break;
            }
            default:
              if (thisProcess.orgOplSettings.hasOwnProperty(key) && thisProcess.orgOplSettings[key] !== undefined) {
                thisProcess.userOplSettings[key] = thisProcess.orgOplSettings[key];
              }
              return;
          }
        } else {
          if (key === 'essence') {
            thisProcess.userOplSettings[key] = thisProcess.convertEnum(userData[key]);
          } else if (key === 'displayOpt') {
            thisProcess.userOplSettings[key] = thisProcess.convertDisplayOptForBackwardCompatibility(userData[key]);
          } else {
            thisProcess.userOplSettings[key] = userData[key];
          }
        }
      }
    });
    // For left pane display of chat
    thisProcess.options.showNavigator = thisProcess.userOplSettings['navigatorEnabled'];
    // For left pane display of chat
    thisProcess.options.showChatPanel = thisProcess.userOplSettings['chatEnabled'];
    // For draggable things search autocomplete
    thisProcess.options.draggableAutocomplete = thisProcess.userOplSettings['dragSearchAuto'];
    // For halo toggle default mode
    thisProcess.options.haloDefaultMode = thisProcess.userOplSettings['haloDefaultMode'];
    thisProcess.options.defaultHalo = thisProcess.options.haloDefaultMode;
    thisProcess.options.showGrid = thisProcess.userOplSettings['gridSettings']['state'];
    thisProcess.options.toggleGrid(false);
    thisProcess.options.toggleGrid(false);
    // if there was no user style data in the db
    if (thisProcess.userOplSettings.style === undefined) {
      this.loadUserStyleSettings(userData);
    }
    // if there was no user connection data in the db
    if (thisProcess.userOplSettings.connection === undefined) {
      this.loadUserConnectionSettings(userData);
    }
    this.userOplSettings.validationTime = userData.validationTime ? userData.validationTime : 0;
    this.userOplSettings.validationEnforcementLevel = userData.validationEnforcementLevel ? userData.validationEnforcementLevel : 0;
    this.model.model.validation = this.getValidationSettings();
    this.updateDefaultSettings();
    thisProcess.options.setLeftBarWindowsSizes({});
  }

  addDefaultsToUndefined(defaults, obj) {
    Object.keys(defaults).forEach(function (key) {
      if (obj[key] === undefined) {
        obj[key] = defaults[key];
      }
    });

  }

  convertDisplayOptForBackwardCompatibility(sentence: string): string {
    switch (sentence) {
      case DisplayOpt[0]:
        return DisplayOpt[0];
      case DisplayOpt[1]:
        return DisplayOpt[1];
      case DisplayOpt[2]:
        return DisplayOpt[2];
      case 'Show OPL for all Things':
        return DisplayOpt[0];
      case 'Show OPL only for non-default Things':
        return DisplayOpt[1];
    }
  }

  generateOpl(options = this.options) {
    let opl = oplGenerating.generateOPL(options);
    return this.addGrammer(opl);
  }

  generateOplTextOnly(options = this.options) {
    if (!this.testingMode)
      oplGenerating.textOnly = true;
    const opl = oplGenerating.generateOPL(options);
    oplGenerating.textOnly = false;
    return opl;
  }

  addGrammer(opl) {
    if (oplDefaultSettings.language === 'en') {
      for (let i in opl) {
        opl[i]['opl'] = oplFunctions.addCapital(opl[i]['opl']);
      }
    }
    return opl;
  }

  generateOplSpec() {

  }

  updateDefaultSettings() {
    const thisProcess = this;
    if ((typeof thisProcess.orgOplSettings) === 'object') {
      Object.keys(thisProcess.orgOplSettings).forEach(function (key) {
        oplDefaultSettings[key] = thisProcess.orgOplSettings[key];
      });
    }
    if ((typeof thisProcess.userOplSettings) === 'object') {
      Object.keys(thisProcess.userOplSettings).forEach(function (key) {
        if (thisProcess.userOplSettings[key] !== undefined) {
          oplDefaultSettings[key] = thisProcess.userOplSettings[key];
        }
      });
    }
    updateTemplates();
  }

  /*
  updateUserOplSettings() {
    const userOplSettings = this.userOplSettings;
    if (userOplSettings.lan) {
      delete userOplSettings.lan;
    }
    this.userS.updateDB({'oplSettings': userOplSettings}).then((res) => {
      //   validationAlert("user opl settings were saved");
    }).catch(err => {validationAlert("ERROR:"+ err)});
    updateDefaultSettings(this.userOplSettings, this.orgOplSettings);
  }

  getUserOplSettings(){
    const orgSettings = this.getOrgOplSettings()
    let oplSettings = null;
    this.userS.user$.take(1)
      .subscribe(user => {
        oplSettings = user.userData.oplSettings;
        console.log(oplSettings);
      });
    if(oplSettings){
      for( const key of Object.keys(userOplSettings)){
        if (!oplSettings[key]){
          if(orgSettings[key]){
            oplSettings[key] = orgSettings[key]
          }else{
            oplSettings[key]= userOplSettings[key];
          }
        }
      }
    }else{
      oplSettings = userOplSettings;
    }

    this.userOplSettings = oplSettings;
    this.updateUserOplSettings();
    return oplSettings;
  }

  getOrgOplSettings(){
    //const ref = this.orgS.getOrganization(this.userS.user.userData.organization); //todo kfir
    const ref = this.orgS.getOrganization('public');
    let oplSettings  = null;
    const subscription = Observable.fromPromise(ref);
    subscription.subscribe(val => {
      oplSettings = val;
      console.log(val);
    });
    // ref.then('value', val=>{
    //   oplSettings = val;
    //   console.log(oplSettings);
    // });
    console.log(oplSettings);
    if (oplSettings) {
      return oplSettings['defaultSettings'];
    } else {
      return oplDefaultSettings;
    }
  }

  updateOrgOplSettings(){
    // this.options.opmModel.defaultSettings = this.orgOplSettings;
    // this.options.updateModel(this.graphService.modelObject.name, false ,
    //   this.graphService.modelObject.path, 'overwrite');
    const orgName = this.userS.user.userData.organization;
    this.orgS.updateOrganization(orgName, {'defaultSettings': this.orgOplSettings});
  }
*/

  getAvailableLanguages() {
    if (this.orgOplSettings.oplTables) {
      return Object.keys(this.orgOplSettings.oplTables);
    }
  }

  getOplTable(lan = null) {
    let oplTable = null;
    if (lan) {
      oplTable = this.orgOplSettings.oplTables[lan];
    } else {
      oplTable = oplTemplates;
    }
    return JSON.parse(JSON.stringify(oplTable));
  }


  generateAllOpl(graphService: GraphService) {
    const arrOfAllOpl = new Array();
    const allOpd = this.options.opmModel.opds;
    // This loop goes over all OPDs and inserts the OPL sentences to the arrays.
    for (let i = 0; i < allOpd.length; i++) {
      const graph = graphService.renderGraphSilent(allOpd[i]); // Goes to the next OPD in the allOpd array.
      const options = {};
      options['graph'] = graph;
      options['opmModel'] = this.options;
      const cells = this.generateOplTextOnly(); // YANG's function
      // This loop goes over all of the cells and inserts the OPL sentences to the arrays.
      for (let j = 0; j < cells.length; j++) {
        if (cells[j].opl) { // Check if the cell contains an OPL sentence.
          // Get the OPL sentence of the cell.
          const innerHTML = cells[j].opl;
          if (!arrOfAllOpl.includes(innerHTML)) {
            arrOfAllOpl.push(innerHTML); /// Insert the OPL sentence into the arrOfAllOpl array.
          }
        }
      }
    }
    if (this.testingMode) {
      return arrOfAllOpl.map(sent => sent.replace(/<\/?[^>]+>/ig, ''));
    }
    return arrOfAllOpl;
  }

  essenceEnum(e) {
    if (e === 'Physical') {
      return Essence.Physical;
    }
    if (e === 'Informatical') {
      return Essence.Informatical;
    }
  }

  essenceString(e) {
    if (e === Essence.Informatical) {
      return 'Informatical';
    }
    if (e === Essence.Physical) {
      return 'Physical';
    }
    console.log('ERROR: ', e);
  }

  addDefaultTables() {
    if (this.orgOplSettings.oplTables === undefined) {
      this.orgOplSettings.oplTables = {};
    }
    for (const lan of Languages) {
      this.orgOplSettings.oplTables[lan] = OplTables[lan];
    }
  }

  removeDefaultTables() {
    for (const lan of Languages) {
      if (this.orgOplSettings.oplTables[lan] !== undefined) {
        delete this.orgOplSettings.oplTables[lan];
      }
    }
  }

  fromStringToEnum(s) {
    if (s === '0') {
      return 0;
    }
    if (s === '1') {
      return 1;
    }
    console.log('Error', s);
  }

  createSysDict(options) {
    const logicalElements = options.opmModel.logicalElements;
    const sysDict = {}; // create new system dictionary
    const lan = oplDefaultSettings.language; // current language in use
    for (const e of logicalElements) { // go over all logical elements and add in system dictionary
      sysDict[e.text] = {};
      sysDict[e.text][lan] = e.text;
    }
  }

  get settings(): Readonly<OplSettings> {
    return this.userOplSettings;
  }

  /*receives settings object to update, settings object to update according to, an optional key if only one key should be updated */
  private setUpdatedSettings(setting_to_update: OplSettings, settings_update_according: OplSettings, certainKey?) {
    if (settings_update_according) {
      Object.keys(settings_update_according).forEach(function (key) {
        if (certainKey === undefined || key === certainKey) {
          if (setting_to_update.hasOwnProperty(key)) {
            if (settings_update_according[key] !== undefined &&
              (setting_to_update[key] === undefined || setting_to_update[key].length === 0)) {
              setting_to_update[key] = settings_update_according[key];
            }
          }
        }
      });
    }
  }

  /*receives a value of essence field in OPL setting and returns true if this is a legal value (currently, 0 or 1), false otherwise*/
  private legalEssenceNumValue(val: number): boolean {
    return (val === 0 || val === 1);
  }

  /*receives a value of essence field in OPL setting and return the Essence Field according.
  * converts from string to essence enum if necessary. if enumParam is a number but not Essence legal value,
  * default is physical */
  private convertEnum(enumParam) {
    if (typeof enumParam === 'string') {
      return this.fromStringToEnum(enumParam);
    }
    if (typeof enumParam === 'number') {
      if (this.legalEssenceNumValue(enumParam)) {
        return enumParam;
      } else { // should not get here
        return 0;
      }
    } else {
      console.log('Error', enumParam);
    }
  }

  /**returns organization opl settings**/
  get orgSettings(): Readonly<OplSettings> {
    return this.orgOplSettings;
  }

  /**
   * makes sure the local version of the organization object and process style settings will have all the keys, even if they are
   * undefined to prevent a reference to non existing keys
   **/
  handleOrganizationStyleKey(res) {
    // thisProcess.orgOplSettings.style should be defined
    this.orgOplSettings.style = this.getUndefinedStyleSettings();
    // set the object style settings which are not undefined
    if (res.style.object) {
      this.loadOrgObjectStyleSettings(res.style.object);
    }
    // set the process style settings which are not undefined
    if (res.style.process) {
      this.loadOrgProcessStyleSettings(res.style.process);
    }
    // set the state style settings which are not undefined
    if (res.style.state) {
      this.loadOrgStateStyleSettings(res.style.state);
    }
  }

  /**
   * updating current styling related settings according to current user. if doesnot exist- according to default
   * settings.* */
  userStylingSettings() {
    let objectStyleSettings: StyleSettings = this.getObjectStyleDefaultSettings();
    let processStyleSettings: StyleSettings = this.getProcessStyleDefaultSettings();
    let stateStyleSettings: StyleSettings = this.getStateStyleDefaultSettings();
    this.updateUserObjectStyleSettings(objectStyleSettings);
    this.updateUserProcessStyleSettings(processStyleSettings);
    this.updateUserStateStyleSettings(stateStyleSettings);
    return { object: objectStyleSettings, process: processStyleSettings, state: stateStyleSettings };
  }

  /**
   * updating current styling related settings according to current organization. if doesnot exist- according to default
   * settings.* */
  orgStylingSettings() {
    let objectStyleSettings: StyleSettings = this.getObjectStyleDefaultSettings();
    let processStyleSettings: StyleSettings = this.getProcessStyleDefaultSettings();
    let stateStyleSettings: StyleSettings = this.getStateStyleDefaultSettings();
    this.updateOrganizationStyleSettings(objectStyleSettings, 'object');
    this.updateOrganizationStyleSettings(processStyleSettings, 'process');
    this.updateOrganizationStyleSettings(stateStyleSettings, 'state');
    return { object: objectStyleSettings, process: processStyleSettings, state: stateStyleSettings };
  }

  /**returns an object with default object style settings**/
  getObjectStyleDefaultSettings() {
    const objectStyleSettings: StyleSettings = {
      font_size: defaultObjectStyleSettings.font_size,
      font: defaultObjectStyleSettings.font,
      text_color: defaultObjectStyleSettings.text_color,
      border_color: defaultObjectStyleSettings.border_color,
      fill_color: defaultObjectStyleSettings.fill_color,
    };
    return objectStyleSettings;
  }

  getStateStyleDefaultSettings() {
    const stateStyleSettings: StyleSettings = {
      font_size: defaultStateStyleSettings.font_size,
      font: defaultStateStyleSettings.font,
      text_color: defaultStateStyleSettings.text_color,
      border_color: defaultStateStyleSettings.border_color,
      fill_color: defaultStateStyleSettings.fill_color,
    };
    return stateStyleSettings;
  }

  /**returns an object with default process style settings, as declared in opl service**/
  getProcessStyleDefaultSettings() {
    const processStyleSettings: StyleSettings = {
      font_size: defaultProcessStyleSettings.font_size,
      font: defaultProcessStyleSettings.font,
      text_color: defaultProcessStyleSettings.text_color,
      border_color: defaultProcessStyleSettings.border_color,
      fill_color: defaultProcessStyleSettings.fill_color,
    };
    return processStyleSettings;
  }

  /**
   * updates styleSettings object by this order: default settings->organization settings->user settings
   * */
  public updateUserObjectStyleSettings(styleSettings: StyleSettings) {
    const thisProc = this;
    Object.keys(defaultObjectStyleSettings).forEach(function (key) {
      styleSettings[key] = (thisProc.settings.style && thisProc.settings.style.object && thisProc.settings.style.object[key]) ?
        thisProc.settings.style.object[key] : (thisProc.orgSettings.style && thisProc.orgSettings.style.object
          && thisProc.orgSettings.style.object[key]) ?
          thisProc.orgSettings.style.object[key] : styleSettings[key];
    });
  }

  /**
   * updates styleSettings process by this order: default settings->organization settings->user settings
   * */
  public updateUserProcessStyleSettings(styleSettings: StyleSettings) {
    const thisProc = this;
    Object.keys(defaultObjectStyleSettings).forEach(function (key) {
      styleSettings[key] = (thisProc.settings.style && thisProc.settings.style.process && thisProc.settings.style.process[key]) ?
        thisProc.settings.style.process[key] : (thisProc.orgSettings.style && thisProc.orgSettings.style.process
          && thisProc.orgSettings.style.process[key]) ?
          thisProc.orgSettings.style.process[key] : styleSettings[key];
    });
  }

  public updateUserStateStyleSettings(styleSettings: StyleSettings) {
    const thisProc = this;
    Object.keys(defaultStateStyleSettings).forEach(function (key) {
      styleSettings[key] = (thisProc.settings.style && thisProc.settings.style.state && thisProc.settings.style.state[key]) ?
        thisProc.settings.style.state[key] : (thisProc.orgSettings.style && thisProc.orgSettings.style.state
          && thisProc.orgSettings.style.state[key]) ?
          thisProc.orgSettings.style.state[key] : styleSettings[key];
    });
  }

  /**
   * updates organization styleSettings object/process(according to given type) by this order: default settings->organization settings
   * */
  updateOrganizationStyleSettings(objectStyleSettings: StyleSettings, type: string) {
    const thisProc = this;
    Object.keys(defaultObjectStyleSettings).forEach(function (key) {
      objectStyleSettings[key] = (thisProc.orgSettings.style && thisProc.orgSettings.style[type] && thisProc.orgSettings.style[type][key]) ?
        thisProc.orgSettings.style[type][key] : objectStyleSettings[key];
    });
  }

  /**
   * returns an style object with StyleSettings properties for object and process, undefined.
   * this was in order to allow the flow of settings:
   * later on, if it will be overridden by organization/user settings.
   * **/
  getUndefinedStyleSettings() {
    const emptyStyleSettings1: StyleSettings = {
      font_size: undefined,
      font: undefined,
      text_color: undefined,
      border_color: undefined,
      fill_color: undefined,
    };
    const emptyStyleSettings2: StyleSettings = {
      font_size: undefined,
      font: undefined,
      text_color: undefined,
      border_color: undefined,
      fill_color: undefined,
    };
    const emptyStyleSettings3: StyleSettings = {
      font_size: undefined,
      font: undefined,
      text_color: undefined,
      border_color: undefined,
      fill_color: undefined,
    };
    // two objects needed so they wont both be a refrence to the same object.
    return { 'object': emptyStyleSettings1, 'process': emptyStyleSettings2, 'state': emptyStyleSettings3};
  }

  /*receiving organization object style settings from the db and update local settings according */
  loadOrgObjectStyleSettings(resAtKey_object) {
    const thisProc = this;
    Object.keys(resAtKey_object).forEach(function (key) {
      if (resAtKey_object[key]) {
        thisProc.orgOplSettings.style.object[key] = resAtKey_object[key];
      }
    });
  }

  /*receiving organization process style settings from the db and update local settings according */
  loadOrgProcessStyleSettings(resAtKey_process) {
    const thisProc = this;
    Object.keys(resAtKey_process).forEach(function (key) {
      if (resAtKey_process[key]) {
        thisProc.orgOplSettings.style.process[key] = resAtKey_process[key];
      }
    });
  }

  loadOrgStateStyleSettings(resAtKey_state) {
    const thisProc = this;
    Object.keys(resAtKey_state).forEach(function (key) {
      if (resAtKey_state[key]) {
        thisProc.orgOplSettings.style.state[key] = resAtKey_state[key];
      }
    });
  }

  /*receiving user object style settings from the db and update local settings according */
  loadUserObjectStyleSettings(resAtKey_object) {
    const thisProc = this;
    Object.keys(resAtKey_object).forEach(function (key) {
      if (resAtKey_object[key]) {
        thisProc.userOplSettings.style.object[key] = resAtKey_object[key];
      }
    });
  }

  /*receiving organization process style settings from the db and update local settings according */
  loadUserProcessStyleSettings(resAtKey_process) {
    const thisProc = this;
    Object.keys(resAtKey_process).forEach(function (key) {
      if (resAtKey_process[key]) {
        thisProc.userOplSettings.style.process[key] = resAtKey_process[key];
      }
    });
  }

  loadUserStateStyleSettings(resAtKey_state) {
    const thisProc = this;
    Object.keys(resAtKey_state).forEach(function (key) {
      if (resAtKey_state[key]) {
        thisProc.userOplSettings.style.state[key] = resAtKey_state[key];
      }
    });
  }

  /**
   * loading user style settings according to the given userData (which is from the db, or default settings otherwise)
   * */
  loadUserStyleSettings(userData) {
    this.userOplSettings.style = this.getUndefinedStyleSettings();
    // set the object style settings which are not undefined
    if (userData && userData.style && userData.style.object) {
      this.loadUserObjectStyleSettings(userData.style.object);
    }
    // set the process style settings which are not undefined
    if (userData && userData.style && userData.style.process) {
      this.loadUserProcessStyleSettings(userData.style.process);
    }
    // set the state style settings which are not undefined
    if (userData && userData.style && userData.style.state) {
      this.loadUserStateStyleSettings(userData.style.state);
    }
  }
  /**
   * loading user connection settings according to the given userData (which is from the db, or default settings otherwise)
   * */
  loadUserConnectionSettings(userData) {
    this.userOplSettings.connection = this.getUndefinedConnectionSettings();
    // set the ros connection settings which are not undefined
    if (userData && userData.connection && userData.connection.ros) {
      this.loadUserRosConnectionSettings(userData.connection.ros);
    }
    // set the mqtt connection settings which are not undefined
    if (userData && userData.connection && userData.connection.mqtt) {
      this.loadUserMqttConnectionSettings(userData.connection.mqtt);
    }
    // set the mysql connection settings which are not undefined
    if (userData && userData.connection && userData.connection.mysql) {
      this.loadUserMySQLConnectionSettings(userData.connection.mysql);
    }
    // set the mysql connection settings which are not undefined
    if (userData && userData.connection && userData.connection.graphdb) {
      this.loadUserGraphDBConnectionSettings(userData.connection.graphdb);
    }

    if (userData && userData.connection && userData.connection.calculationsServer) {
      this.loadUserCalculationsServerConnectionSettings(userData.connection.calculationsServer);
    }
  }
  /**
   * Loading user mysql connection settings to local user settings according to given data from db
   * */
  loadUserMySQLConnectionSettings(resAtKey_mysql) {
    const thisProc = this;
    Object.keys(resAtKey_mysql).forEach(function (key) {
      if (resAtKey_mysql[key] !== undefined && resAtKey_mysql[key] !== null) {
        thisProc.userOplSettings.connection.mysql[key] = resAtKey_mysql[key];
      }
    });
  }
  /**
   * Loading GraphDB connection settings to local user settings according to given data from db
   * */
  loadUserGraphDBConnectionSettings(resAtKey_graphdb) {
    const thisProc = this;
    Object.keys(resAtKey_graphdb).forEach(function (key) {
      if (resAtKey_graphdb[key] !== undefined && resAtKey_graphdb[key] !== null) {
        thisProc.userOplSettings.connection.mysql[key] = resAtKey_graphdb[key];
      }
    });
  }

  loadUserCalculationsServerConnectionSettings(settings) {
    const thisProc = this;
    Object.keys(settings).forEach(function (key) {
      if (settings[key] !== undefined && settings[key] !== null) {
        thisProc.userOplSettings.connection.calculationsServer[key] = settings[key];
      }
    });
  }
  /**
   * Loading user ros connection settings to local user settings according to given data from db
   * */
  loadUserRosConnectionSettings(resAtKey_ros) {
    const thisProc = this;
    Object.keys(resAtKey_ros).forEach(function (key) {
      if (resAtKey_ros[key] !== undefined && resAtKey_ros[key] !== null) {
        thisProc.userOplSettings.connection.ros[key] = resAtKey_ros[key];
      }
    });
  }
  /**
   * Loading user mqtt connection settings to local user settings according to given data from db
   * */
  loadUserMqttConnectionSettings(resAtKey_mqtt) {
    const thisProc = this;
    Object.keys(resAtKey_mqtt).forEach(function (key) {
      if (resAtKey_mqtt[key] !== undefined && resAtKey_mqtt[key] !== null) {
        thisProc.userOplSettings.connection.mqtt[key] = resAtKey_mqtt[key];
      }
    });
  }
  /**
   * initializing userData to be default settings without making userData a reference of defaults settings.
   * **/
  private cloneDefaultSettings(userData) {
    Object.keys(userData).forEach(key => function () {
      userData[key] = defaultSettings.organization.hasOwnProperty(key) ? defaultSettings.organization[key] : defaultSettings.user[key];
    });
  }

  /**
   * gets the current organization connection (type parameter can be ros or mqtt) settings, from the db (considers only
   * the defined data from the db)
   **/
  loadOrgConnectionSettings(resAtKey_process, type) {
    const thisProc = this;
    Object.keys(resAtKey_process).forEach(function (key) {
      if (resAtKey_process[key]) {
        thisProc.orgOplSettings.connection[type][key] = resAtKey_process[key];
      }
    });
  }
  /**
   * returns an object that represents the current user connection settings
   **/
  public userConnectionSettings() {
    let rosConnectionSettings: ConnectionSettings = this.getRosConnectionDefaultSettings();
    let mqttConnectionSettings: ConnectionSettings = this.getMqttConnectionDefaultSettings();
    let pythonConnectionSettings: ConnectionSettings = this.getPythonConnectionDefaultSettings();
    let mySQLConnectionSettings: MySQLConnectionSettings = this.getMySQLConnectionDefaultSettings();
    let graphDBConnectionSettings: GraphDBConnectionSettings = this.getGraphDBConnectionDefaultSettings();
    let calculationsServerSettings: CalculationsServerConnectionSettings = defaultCalculationsServerSettings;
    const allow_usersConnectionSettings: boolean = this.allow_users;
    this.updateUserRosConnectionSettings(rosConnectionSettings, allow_usersConnectionSettings);
    this.updateUserMqttConnectionSettings(mqttConnectionSettings, allow_usersConnectionSettings);
    this.updateUserPythonConnectionSettings(pythonConnectionSettings, allow_usersConnectionSettings);
    this.updateUserMySQLConnectionSettings(mySQLConnectionSettings, allow_usersConnectionSettings);
    this.updateUserGraphDBConnectionSettings(graphDBConnectionSettings, allow_usersConnectionSettings);
    this.updateUserServerCalculationsSettings(calculationsServerSettings);

    return { ros: rosConnectionSettings, mqtt: mqttConnectionSettings, python: pythonConnectionSettings,
      mysql: mySQLConnectionSettings, graphdb: graphDBConnectionSettings, allow_users: allow_usersConnectionSettings,
      calculationsServer: calculationsServerSettings,
    };
  }
  /**
   * updates the given ros user connectionSettings according to the following order:
   * 1. if the user defined the settings for them self, take it.
   * 2. if not- try the organization settings.
   * 3. if the organization settings are also not defined, take the default settings
   **/
  public updateUserPythonConnectionSettings(connectionSettings: ConnectionSettings, allow_users: boolean) {
    const thisProc = this;
    Object.keys(defaultPythonConnectionSettings).forEach(function (key) {
      connectionSettings[key] = (allow_users === true && thisProc.settings.connection && thisProc.settings.connection.python && thisProc.settings.connection.python[key] !== undefined) ?
        thisProc.settings.connection.python[key] : (thisProc.orgSettings.connection && thisProc.orgSettings.connection.python
          && thisProc.orgSettings.connection.python[key] !== undefined) ?
          thisProc.orgSettings.connection.python[key] : connectionSettings[key];
    });
  }
  /**
   * updates the given MySQL user connectionSettings according to the following order:
   * 1. if the user defined the settings for them self, take it.
   * 2. if not- try the organization settings.
   * 3. if the organization settings are also not defined, take the default settings
   **/
  public updateUserMySQLConnectionSettings(connectionSettings: ConnectionSettings, allow_users: boolean) {
    const thisProc = this;
    Object.keys(defaultMySQLConnectionSettings).forEach(function (key) {
      connectionSettings[key] = (allow_users === true && thisProc.settings.connection && thisProc.settings.connection.mysql && thisProc.settings.connection.mysql[key] !== undefined) ?
        thisProc.settings.connection.mysql[key] : connectionSettings[key];
    });
  }
  /**
   * updates the given GraphDB (currently NEO4J) connectionSettings according to the following order:
   * 1. if the user defined the settings for them self, take it.
   * 2. if the organization settings are also not defined, take the default settings
   **/
  public updateUserGraphDBConnectionSettings(connectionSettings: GraphDBConnectionSettings, allow_users: boolean) {
    const thisProc = this;
    Object.keys(defaultGraphDBConnectionSettings).forEach(function (key) {
      connectionSettings[key] = (allow_users === true && thisProc.settings.connection && thisProc.settings.connection.graphdb && thisProc.settings.connection.graphdb[key] !== undefined) ?
        thisProc.settings.connection.graphdb[key] : connectionSettings[key];
    });
  }
  /**
   * updates the given ros user connectionSettings according to the following order:
   * 1. if the user defined the settings for them self, take it.
   * 2. if not- try the organization settings.
   * 3. if the organization settings are also not defined, take the default settings
   **/
  public updateUserRosConnectionSettings(connectionSettings: ConnectionSettings, allow_users: boolean) {
    const thisProc = this;
    Object.keys(defaultRosConnectionSettings).forEach(function (key) {
      connectionSettings[key] = (allow_users === true && thisProc.settings.connection && thisProc.settings.connection.ros && thisProc.settings.connection.ros[key] !== undefined) ?
        thisProc.settings.connection.ros[key] : (thisProc.orgSettings.connection && thisProc.orgSettings.connection.ros
          && thisProc.orgSettings.connection.ros[key] !== undefined) ?
          thisProc.orgSettings.connection.ros[key] : connectionSettings[key];
    });
  }
  /**
   * updates the given mqtt user connectionSettings according to the following order:
   * 1. if the user defined the settings for them self, take it.
   * 2. if not- try the organization settings.
   * 3. if the organization settings are also not defined, take the default settings
   **/
  public updateUserMqttConnectionSettings(connectionSettings: ConnectionSettings, allow_users: boolean) {
    const thisProc = this;
    Object.keys(defaultMqttConnectionSettings).forEach(function (key) {
      connectionSettings[key] = (allow_users === true && thisProc.settings.connection && thisProc.settings.connection.mqtt && thisProc.settings.connection.mqtt[key] !== undefined) ?
        thisProc.settings.connection.mqtt[key] : (thisProc.orgSettings.connection && thisProc.orgSettings.connection.mqtt
          && thisProc.orgSettings.connection.mqtt[key] !== undefined) ?
          thisProc.orgSettings.connection.mqtt[key] : connectionSettings[key];
    });
  }
  /**
   * returns the current organization connection details
   **/
  public orgConnectionSettings() {
    const rosConnectionSettings: ConnectionSettings = this.getRosConnectionDefaultSettings();
    const mqttConnectionSettings: ConnectionSettings = this.getMqttConnectionDefaultSettings();
    const pythonConnectionSettings: ConnectionSettings = this.getPythonConnectionDefaultSettings();
    const mysqlConnectionSettings: MySQLConnectionSettings = this.getMySQLConnectionDefaultSettings();
    const allow_usersConnectionSettings: boolean = this.allow_users;
    this.updateOrganizationConnectionSettings(rosConnectionSettings, 'ros');
    this.updateOrganizationConnectionSettings(mqttConnectionSettings, 'mqtt');
    this.updateOrganizationConnectionSettings(pythonConnectionSettings, 'python');
    this.updateOrganizationConnectionSettings(mysqlConnectionSettings, 'mysql');
    return { ros: rosConnectionSettings, mqtt: mqttConnectionSettings, python: pythonConnectionSettings, mysql: mysqlConnectionSettings, graphdb: defaultGraphDBConnectionSettings, allow_users: allow_usersConnectionSettings };
  }
  /**
   * makes sure the local version of the organization ros and mqtt settings will have all the keys, even if they are
   * undefined to prevent a reference to non existing keys
   **/
  handleOrganizationConnectionKey(res) {
    // thisProcess.orgOplSettings.connection should be defined
    this.orgOplSettings.connection = this.getUndefinedConnectionSettings();
    // set the ros connections settings which are not undefined
    if (res.connection.python) {
      this.loadOrgConnectionSettings(res.connection.python, 'python');
    }
    // set the MySQL connections settings which are not undefined
    if (res.connection.mysql) {
      this.loadOrgConnectionSettings(res.connection.mysql, 'mysql');
    }
    // set the ros connections settings which are not undefined
    if (res.connection.ros) {
      this.loadOrgConnectionSettings(res.connection.ros, 'ros');
    }
    // set the mqtt connections settings which are not undefined
    if (res.connection.mqtt) {
      this.loadOrgConnectionSettings(res.connection.mqtt, 'mqtt');
    }
    // set the allow_users settings which are not undefined
    if (res.connection.allow_users !== undefined) {
      this.orgOplSettings.connection.allow_users = res.connection.allow_users;
    }
  }
  /**
   * returns undefined version of the connection object
   * */
  getUndefinedConnectionSettings() {
    const emptyPythonSettings: ConnectionSettings = {
      server: undefined,
      port: undefined,
    };
    const emptyMySQLSettings: MySQLConnectionSettings = {
      hostname: undefined,
      port: undefined,
      username: undefined,
      password: undefined,
      ws_hostname: undefined,
      ws_port: undefined,
    };
    const emptyGraphDBSettings: GraphDBConnectionSettings = {
      graphdb_api: undefined,
      username: undefined,
      password: undefined,
    };
    const emptyRosSettings: ConnectionSettings = {
      server: undefined,
      port: undefined,
    };
    const emptyMqttSettings: ConnectionSettings = {
      server: undefined,
      port: undefined,
    };
    const emptyCalculationsServerConnectionsSettings: CalculationsServerConnectionSettings = {
      computingServerURL: undefined,
      computingServerCalculations: undefined
    };
    // two objects needed so they won't both be a reference to the same object.
    return { 'ros': emptyRosSettings, 'mqtt': emptyMqttSettings, 'python': emptyPythonSettings, 'mysql': emptyMySQLSettings, 'graphdb': emptyGraphDBSettings, 'allow_users': undefined, 'calculationsServer': emptyCalculationsServerConnectionsSettings };
  }
  /**
   * updates the given organization connectionSettings according to the following order:
   * 2. if the organization settings are defined- take it.
   * 3. if the organization settings are also not defined, take the default settings
   **/
  updateOrganizationConnectionSettings(connectionSettings: ConnectionSettings, type: string) {
    const thisProc = this;
    Object.keys(defaultRosConnectionSettings).forEach(function (key) {
      connectionSettings[key] = (thisProc.orgSettings.connection && thisProc.orgSettings.connection[type] &&
        thisProc.orgSettings.connection[type][key] !== undefined) ?
        thisProc.orgSettings.connection[type][key] : connectionSettings[key];
    });
  }
  /**
   * returns an object with default ros connection settings
   **/
  getPythonConnectionDefaultSettings() {
    const pythonConnectionSettings: ConnectionSettings = {
      server: defaultPythonConnectionSettings.server,
      port: defaultPythonConnectionSettings.port,
    };
    return pythonConnectionSettings;
  }
  /**
   * returns an object with default MySQL connection settings
   **/
  getMySQLConnectionDefaultSettings() {
    const mySQLConnectionSettings: MySQLConnectionSettings = {
      hostname: defaultMySQLConnectionSettings.hostname,
      port: defaultMySQLConnectionSettings.port,
      username: defaultMySQLConnectionSettings.username,
      password: defaultMySQLConnectionSettings.password,
      schema: defaultMySQLConnectionSettings.schema ,
      ws_port: defaultMySQLConnectionSettings.ws_port,
      ws_hostname: defaultMySQLConnectionSettings.ws_hostname,
    };
    return mySQLConnectionSettings;
  }
  /**
   * returns an object with default GraphDB connection settings
   **/
  getGraphDBConnectionDefaultSettings() {
    const graphDBConnectionSettings: GraphDBConnectionSettings = {
      graphdb_api: defaultGraphDBConnectionSettings.graphdb_api,
      username: defaultGraphDBConnectionSettings.username,
      password: defaultGraphDBConnectionSettings.password,
    };
    return graphDBConnectionSettings;
  }
  /**
   * returns an object with default ros connection settings
   **/
  getRosConnectionDefaultSettings() {
    const rosConnectionSettings: ConnectionSettings = {
      server: defaultRosConnectionSettings.server,
      port: defaultRosConnectionSettings.port,
    };
    return rosConnectionSettings;
  }
  /**
   * returns an object with default mqtt connection settings
   **/
  getMqttConnectionDefaultSettings() {
    const mqttConnectionSettings: ConnectionSettings = {
      server: defaultMqttConnectionSettings.server,
      port: defaultMqttConnectionSettings.port,
    };
    return mqttConnectionSettings;
  }
  /**
   * returns the default allow_users connection value
   **/
  private getAllowUsersConnectionDefaultSettings() {
    return defaultAllowUsersConnectionSettings;
  }
  /**
   * returns the right allow_users connection value - if the organization has determined this value - return it,
   * otherwise should be the default value
   **/
  public get allow_users() {
    return (this.orgSettings.connection && this.orgSettings.connection.allow_users !== undefined) ?
      this.orgSettings.connection.allow_users : this.getAllowUsersConnectionDefaultSettings();
  }

  handleOrganizationArchiveKey(res) {
    // thisProcess.orgOplSettings.archive should be defined
    this.orgOplSettings.style = this.getUndefinedArchiveSettings();
    // set the object archive settings which are not undefined
    if (res.archive.object) {
      this.loadOrgObjectArchiveSettings(res.archive.object);
    }
  }

  getUndefinedArchiveSettings() {
    return { 'days_interval': undefined };
  }

  loadOrgObjectArchiveSettings(resAtKey_object) {
    const thisProc = this;
    Object.keys(resAtKey_object).forEach(function (key) {
      if (resAtKey_object[key]) {
        thisProc.orgOplSettings.archive.object[key] = resAtKey_object[key];
      }
    });
  }

  orgArchiveSettings() {
    const days_interval: number = defaultArchiveDaysIntervalSettings;
    return { days_interval: days_interval };
  }

  getValidationSettings() {
    return getValidationObject(Number(this.userOplSettings['validationTime']), Number(this.userOplSettings['validationEnforcementLevel']));
  }

  loadOrgOntologyData(ontology: Array<OntologyItem>) {
    this.orgOplSettings.ontology = ontology;
  }

  private updateUserServerCalculationsSettings(calculationsServerSettings: CalculationsServerConnectionSettings) {
    if (this.settings.connection?.calculationsServer?.hasOwnProperty('computingServerCalculations')) {
      calculationsServerSettings.computingServerCalculations = this.settings.connection.calculationsServer.computingServerCalculations;
    } else {
      calculationsServerSettings.computingServerCalculations = defaultCalculationsServerSettings.computingServerCalculations;
    }

    if (this.settings.connection?.calculationsServer?.hasOwnProperty('computingServerURL')) {
      calculationsServerSettings.computingServerURL = this.settings.connection.calculationsServer.computingServerURL;
    } else {
      calculationsServerSettings.computingServerURL = defaultCalculationsServerSettings.computingServerURL;
    }
  }
}

export function getValidationObject(validationTime: number, validationEnforcementLevel: number): {
  validation_time: 'design' | 'execution' | 'both', enforcment_level: 'soft' | 'hard'
} {
  return {
    validation_time: validationTime == 1 ? 'design' : (validationTime == 2 ? 'execution' : 'both'),
    enforcment_level: validationEnforcementLevel == 1 ? 'soft' : 'hard'
  };
}
