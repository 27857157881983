import {ObjectItem, handleExeExceptions, hasWhiteSpaces} from './computationalPartUtils';
import {getInitRappidShared, isNumber, OPCloudUtils} from '../rappidEnviromentFunctionality/shared';
import {code, linkType} from '../../models/ConfigurationOptions';

/**
 * A class that handles converting paths from a objects to objects instrumentally linked to a computational process (via
 * aggregation/consists of links) to variables
 **/
export class VariablesCalculator {
  all_paths: Map<string, ObjectItem[][]>;
  valuesArray: ObjectItem[];
  constructor(all_paths: Map<string, ObjectItem[][]>, valuesArray: ObjectItem[]) {
    this.all_paths = all_paths;
    this.valuesArray = valuesArray;
  }
  /**
   * this function has 4 purposes:
   * 1. return the variables definitions str in the format let a_b = 80; let a = 70; let c$d = 80; (if there is an object named c/d)
   * 2. update variables_array to include all legal variables (name, alias, value).
   * 3. update all_variables_str to include all of the variables identifiers
   * 4. update non_legal_variables_str to include all of the variables that should not be used (if a.b.c is a variable,
   * then a.b cannot be used though a might be) - this part is commented because a.b could be used
   * parameters:
   * - all_variables_str - an array of strings, to include all of the variables identifiers
   * - non_legal_variables_str - an array of strings to include all non legal variables identifiers as mentioned above
   * - variables_array - an ObjectItem array that will include all legal variables (name, alias, value).
   * - calling_type - an enum, to indicate if the a ros/mqtt/user defined/external function calls this function
   **/
  public calc_variables_str(all_variables_str: string[], calling_type: code,
                            non_legal_variables_str: string[], alias, variables_array: ObjectItem[] = []) {
    let variables_string = '';
    const that = this;
    const is_user_defined_function = calling_type === code.UserDefined || calling_type === code.Python;
    const is_ros_or_mqtt_function = calling_type === code.MQTT || calling_type === code.ROS;
    const is_sql_function = calling_type === code.SQL;
    let just_alias = [];
    that.all_paths.forEach( (paths_array, targetId) => {
      for (let i = 0 ; i < paths_array.length ; i++) {
        const concat_ids = paths_array[i].map(node => node.elementId).reduce((accumulator, currentValue) => accumulator +'_'+ currentValue, '');
        const var_name_options = that.calc_var_by_path(paths_array[i], is_user_defined_function, calling_type);
        const one_just_alias = that.calc_alias_by_path(paths_array[i], is_user_defined_function, calling_type);
        just_alias = [...just_alias, ...one_just_alias];
        for (let j = 0 ; j < var_name_options.length ; j++ ) {
          const curr_var_string = var_name_options[j];
          let var_name;
          let var_value;
          if (is_sql_function) {
            var_name = curr_var_string.substring(4, curr_var_string.indexOf('=') - 1);
            var_value = curr_var_string.substring(curr_var_string.indexOf('=') + 2, curr_var_string.lastIndexOf(';'));
          } else {
            var_name = curr_var_string.substring(calling_type === code.Python ? 0 : 4, curr_var_string.lastIndexOf('=') - 1);// remove the 'let and value (after = ) '
            var_value = curr_var_string.substring(curr_var_string.lastIndexOf('=') + 2, calling_type === code.Python ? curr_var_string.lastIndexOf('\n') : curr_var_string.lastIndexOf(';'));
          }
          const name_or_alias = var_name.replace(/_/g, '\.').replace(/\$/g, '/');
          // if (all_variables_str.includes(var_name) || non_legal_variables_str.includes(var_name)) {
          if (all_variables_str.includes(var_name) && !hasWhiteSpaces(var_name)) {
            handleExeExceptions('Identifier ' + name_or_alias + ' has been declared more than once.');
          } else {
            // if (paths_array[i].length > 0 && (paths_array[i].length === 1 || path_is_not_prefix_path || is_ros_or_mqtt_function)) {
            if (paths_array[i].length > 0) {
              all_variables_str.push(var_name);
              if ((!hasWhiteSpaces(var_name) || is_ros_or_mqtt_function ) && var_value !== undefined) { // if it is a legal identifier with a value
                variables_array.push({name: name_or_alias, alias: name_or_alias, sourceElementValue: var_value, concatedId: concat_ids});
                variables_string += curr_var_string;
                // if (is_user_defined_function) {
                //   that.updateNonLegalVariables(var_name, non_legal_variables_str);
                // }
              }
            }
            // if (paths_array[i].length > 1 && !path_is_not_prefix_path) {
            //   non_legal_variables_str.push(var_name);
            // }
          }
        }
      }
    });
    for (let i = 0 ; i < just_alias.length ; i++) {
      just_alias[i] = just_alias[i].replace(/_/g, '\.').replace(/\$/g, '/');
    }
    const sc = getInitRappidShared()?.opmModel?.getCurrentConfiguration();
    for (let i = 0 ; i < variables_array.length ; i++) {
      if(just_alias.indexOf(variables_array[i].alias) > -1 ) {
        const lidArr = variables_array[i].concatedId.substr(1).split('_');
        const lid = lidArr[lidArr.length - 1];
        let instances = sc && (sc[lid]?.value || sc[lid]?.value === 0) ? sc[lid].value : 1;
        if (sc && instances === 1) {
          instances = this.getInstancesNumberByAliasPath(variables_array[i], sc);
        }
        alias.push({alias: variables_array[i].alias, value: variables_array[i].sourceElementValue, instances: instances, lid: lid});
      }
    }
    return variables_string;
  }
  // calculates the correct instances number by the path to the thing.
  public getInstancesNumberByAliasPath(item, sc) {
    let ret = 1;
    const mult = item.concatedId.split('_').filter(i => i && i !== '').map(k => sc[k]?.value ? sc[k]?.value : 1)
    for (const part of mult)
        ret = ret * part;
    return ret;
  }
  /**
   * returns a string that will describe a variable according to the given path
   * **/
  private calc_var_by_path(path: ObjectItem[], is_user_defined_function, curr_code = undefined) {
    let value;
    let LastVar = this.valuesArray.filter(value1 => value1.elementId === path[path.length - 1].elementId)[0];
    if (LastVar) {
      // only user defined functions should add the ' ' to strings
      value =  !isNumber(LastVar.sourceElementValue) && is_user_defined_function ?
        ' = ' + '\'' + LastVar.sourceElementValue + '\'' + (curr_code === code.Python ? '\n' : ';') : ' = ' + LastVar.sourceElementValue + (curr_code === code.Python ? '\n' : ';') ;
    } else {
      value = ' = ' + (curr_code === code.Python ? 'None\n' : 'undefined ;');
    }
    return this.generate_all_possible_identifiers(path, value, (LastVar !== null && LastVar !== undefined), curr_code);
  }
  calc_outputs_alias(visProcess) {
    const ret = {};
    const targets = visProcess.getLinks().outGoing.filter(l => l.type === linkType.Result).map(l => l.target);
    for (const target of targets) {
      const realTarget = OPCloudUtils.isInstanceOfVisualState(target) ? target.fatherObject : target;
      const alias = realTarget.logicalElement.alias;
      if (alias && ret[alias])
        ret[alias].push(realTarget.logicalElement.lid);
      else if(alias)
        ret[alias] = [realTarget.logicalElement.lid];

      const fundLinks = target.getLinks().outGoing.filter(lnk => [linkType.Exhibition, linkType.Aggregation].includes(lnk.type));
      for (const link of fundLinks) {
        const fundTargetAlias = link.target.logicalElement.alias;
        if (fundTargetAlias && ret[fundTargetAlias])
          ret[fundTargetAlias].push(link.target.logicalElement.lid);
        else if (fundTargetAlias && (alias === '' || !alias))
          ret[fundTargetAlias] = [link.target.logicalElement.lid];
        else if (fundTargetAlias && alias && alias !== '')
          ret[alias + '.' + fundTargetAlias] = [link.target.logicalElement.lid];
      }
    }
    return ret;
  }
  /**
   * returns a list of all possibilities of identifier (+ the 'let' definition and the identifier value) that can be
   * created from the given path.
   * var_existed- a boolean that indicates if the path does not represent a computational object.
   * for example:
   * if the following object exists:
   * - {alias: a, name: Object1}
   * - {alias: b, name: Object2}
   * and Object1 consists of/aggregates Object2, then the following identifier can be derived:
   * - Object1.Object2
   * - Object1.b
   * - a.Object2
   * - a.b
   **/
  private generate_all_possible_identifiers(path: ObjectItem[], value: string, var_existed: boolean, curr_code = undefined) {
    const identifiers_list = [];
    this.generate_all_possible_identifiers_aux(path, 0, '', identifiers_list, var_existed);
    return identifiers_list.map(identifier =>  (curr_code === code.Python ? '' : 'let ') + identifier + value);
  }
  /**
   * a recursive function that returns a list of all possibilities of identifier  that can be derived from given path,
   * as mentioned above
   **/
  private generate_all_possible_identifiers_aux(path: ObjectItem[], index: number, curr_identifier: string,
                                                identifiers_list: string[], var_existed: boolean) {
    if (index === path.length) {
      identifiers_list.push(curr_identifier.replace(/\//g, '$'));
    } else {
      const next_name = (index === (path.length - 1)) ? path[index].name :  path[index].name + '_';
      const next_alias = (index === (path.length - 1)) ? path[index].alias :  path[index].alias + '_';
      // taking the name
      if (path[index].name !== undefined && path[index].name !== '') {
        this.generate_all_possible_identifiers_aux(path, index + 1, curr_identifier + next_name,
          identifiers_list, var_existed);
      }
      // taking the alias
      if (path[index].name !== path[index].alias && path[index].alias !== undefined &&
        path[index].alias && path[index].alias.length > 0) {
        this.generate_all_possible_identifiers_aux(path, index + 1, curr_identifier + next_alias, identifiers_list, var_existed);
      }
    }
  }
  /**
   * returns a string that will describe a variable according to the given path
   * **/
  private calc_alias_by_path(path: ObjectItem[], is_user_defined_function, curr_code = undefined) {
    const LastVar = this.valuesArray.filter(value1 => value1.elementId === path[path.length - 1].elementId)[0];
    return this.generate_all_possible_alias_identifiers(path, (LastVar !== null && LastVar !== undefined));
  }
  /**
   * returns a list of all possibilities of identifier (+ the 'let' definition and the identifier value) that can be
   * created from the given path.
   * var_existed- a boolean that indicates if the path does not represent a computational object.
   * for example:
   * if the following object exists:
   * - {alias: a, name: Object1}
   * - {alias: b, name: Object2}
   * and Object1 consists of/aggregates Object2, then the following identifier can be derived:
   * - Object1.Object2
   * - Object1.b
   * - a.Object2
   * - a.b
   **/
  private generate_all_possible_alias_identifiers(path: ObjectItem[], var_existed: boolean) {
    const identifiers_list = [];
    this.generate_all_possible_alias_identifiers_aux(path, 0, '', identifiers_list, var_existed);
    return identifiers_list.map(identifier =>  identifier);
  }
  /**
   * a recursive function that returns a list of all possibilities of identifier  that can be derived from given path,
   * as mentioned above
   **/
  private generate_all_possible_alias_identifiers_aux(path: ObjectItem[], index: number, curr_identifier: string,
                                                identifiers_list: string[], var_existed: boolean) {
    if (index === path.length) {
      identifiers_list.push(curr_identifier.replace(/\//g, '$'));
    } else {
      const next_alias = (index === (path.length - 1)) ? path[index].alias :  path[index].alias + '_';
      // taking the alias
      if (path[index].name !== path[index].alias && path[index].alias !== undefined &&
        path[index].alias && path[index].alias.length > 0) {
        this.generate_all_possible_alias_identifiers_aux(path, index + 1, curr_identifier + next_alias, identifiers_list, var_existed);
      } else {
        if (path[index].name !== undefined && path[index].name !== '' && !(index === (path.length - 1))) {
          const next_alias = path[index].name + '_';
          this.generate_all_possible_alias_identifiers_aux(path, index + 1, curr_identifier + next_alias, identifiers_list, var_existed);
        }
      }
    }
  }
  /**
   * returns true if the path is not a sub (prefix path) of any other path.
   * **/
  private pathIsNotAPrefixPath(path: any[]) {
    const path_as_string = path.map((function(val, index) { return val.elementId; }) ).toString();
    let isPrefix = true;
    this.all_paths.forEach((paths_array, key) => {
      for (let i = 0; i < paths_array.length ; i++) {
        const curr_path = paths_array[i];
        const curr_path_as_string = curr_path.map((function(val, index) { return val.elementId; }) ).toString();
        if (curr_path_as_string !== path_as_string && curr_path_as_string.startsWith(path_as_string)) {
          isPrefix = false;
        }
      }
    });
    return isPrefix;
  }
  /**
   * updates non_legal_variables_str array to include all non legal variables that can be derived from var_name
   * (for example: let a_b_c = 70 => a_b is not a legal variable)
   * **/
  private updateNonLegalVariables(var_name: string, non_legal_variables_str: string[]) {
    const var_name_objects_names  = var_name.split('_');
    let illegal_var_name = var_name_objects_names.length > 0 ? var_name_objects_names[0] : '';
    for (let i = 1 ; i <var_name_objects_names.length - 1 ; i++) {
      illegal_var_name +=  '_' + var_name_objects_names[i] ;
      non_legal_variables_str.push(illegal_var_name);
    }
  }
}
