import {OplTables, oplDefaultSettings, linkTable, oplTable} from './opl-database';
import { oplTemplates, structuralLinkTypes, proceduralLinkTypes } from './opl-database';
import {Affiliation, Essence, linkType} from '../models/ConfigurationOptions';
import Pluralize from 'pluralize';
import { getInitRappidShared, OPCloudUtils } from '../configuration/rappidEnviromentFunctionality/shared';
import { OpmEntity } from '../models/DrawnPart/OpmEntity';
import { OpmProcess } from '../models/DrawnPart/OpmProcess';
import { OpmState } from '../models/DrawnPart/OpmState';
import { InOutPairType } from '../models/consistency/links.model';

export const oplFunctions = {
  addPlural(text) {
    if (!(oplDefaultSettings.language === 'en')) {
      return text;
    }
    // return Pluralize( text, 42 );
    return Pluralize(text, 42);
  },
  AddPath(path, opl) {
    let temp = oplTemplates.tags.path.replace(`<tag>`, path);
    temp = temp.replace('<opl>', opl);
    return temp;
  },
  DivideLinks(links, direction, inOutLinkPairs, proceduralLinks) {
    // direction=TRUE encode as in-links
    // direction=FALSE encode as out-links
    const IN = ['Consumption', 'Consumption_Negation', 'Consumption_Condition', 'Consumption_Condition_Negation', 'Consumption_Event'];
    const OUT = 'Result';
    for (const link of links) {
      if (!(link.getSourceElement() && link.getTargetElement())) {
        continue;
      }
      let type = link.attributes.name;
      if (!proceduralLinks[type]) {
        continue;
      }
      if (type === 'Consumption') {
        if (link.condition) {
          type = type + '_Condition';
        }
        if (link.event) {
          type = type + '_Event';
        }
      }
      if ((direction && IN.indexOf(type) > -1) || (!direction && type === OUT)) {
        let cell = link.getSourceElement();
        if (!direction) {
          cell = link.getTargetElement();
        }
        const mainObject = oplGenerating.getObjectOfState(cell);
        oplGenerating.addToPairs(inOutLinkPairs, mainObject, link, type);
      } else if ((direction && proceduralLinks[type] && type.indexOf('directional') < 0 && type.indexOf('Invocation') < 0 && type !== 'defaultLink') ||
        ((!direction) && proceduralLinks[type] && type.indexOf('exception') < 0 && type.indexOf('directional') < 0 && type !== 'defaultLink')) {

        if (type === 'Invocation' && link.isSelfInvocation() && link.sourceElement.id === link.targetElement.id) {
          proceduralLinks[type + '_(self)'].push(link);
          continue;
        }
        proceduralLinks[type].push(link);
      }
    }
  },
  SplitByTags(links, noPathKey) {
    const linksTags = {};
    for (const link of links) {
      if (link.attributes.Path) {
        if (linksTags[link.attributes.Path]) {
          linksTags[link.attributes.Path].push(link);
        } else {
          linksTags[link.attributes.Path] = [link];
        }
      } else {
        if (linksTags[noPathKey]) {
          linksTags[noPathKey].push(link);
        } else {
          linksTags[noPathKey] = [link];
        }
      }
    }
    return linksTags;
  },
  groupStates(cells, links, parallelSeq = false, preventReorder = false) { // return array of arrays.
    const res = [];
    const index_dict = {};
    for (let i = 0; i < cells.length; i++) {
      if (!(cells[i].constructor.name === 'OpmState')) {
        res.push([[cells[i], links[i]]]);
        continue;
      }
      if (index_dict[oplFunctions.getStatefulObject(cells[i]).id] !== undefined) {
        res[index_dict[oplFunctions.getStatefulObject(cells[i]).id]].push([cells[i], links[i]]);
      } else {
        index_dict[oplFunctions.getStatefulObject(cells[i]).id] = res.length;
        res.push([[cells[i], links[i]]]);
      }
    }
    let brothers = res.filter(function (elem) {
      return elem.length > 1;
    });
    let regular = res.filter(function (elem) {
      return elem.length === 1;
    });
    if (parallelSeq) {
      regular = regular.sort((n1, n2) => {
        const th1X = n1[0][0].get('position').x;
        const th2X = n2[0][0].get('position').x;
        return th1X > th2X ? 1 : -1;
      });
    } else {
      if (!preventReorder) {
        regular = regular.sort((n1, n2) => {
          const name1 = n1[0][0].attributes.attrs.text.textWrap.text;
          const name2 = n2[0][0].attributes.attrs.text.textWrap.text;
          if (name1 > name2) {
            return 1;
          }
          if (name1 < name2) {
            return -1;
          }

          return 0;
        });
      }
    }

    brothers = brothers.sort((n1, n2) => {
      const name1 = oplFunctions.getStatefulObject(n1[0][0]).attributes.attrs.text.textWrap.text;
      const name2 = oplFunctions.getStatefulObject(n2[0][0]).attributes.attrs.text.textWrap.text;
      if (name1 > name2) {
        return 1;
      }

      if (name1 < name2) {
        return -1;
      }

      return 0;
    });

    // dividing multiplicity and cells into 2 different arrays

    const new_cells = [];
    const new_multi = [];
    for (let cell of regular) {
      cell = cell[0];
      new_cells.push(cell[0]);
      new_multi.push(cell[1]);
    }
    for (const cell_array of brothers) {
      const temp_brothers = [];
      const temp_multi = [];
      for (const cell of cell_array) {
        temp_brothers.push(cell[0]);
        temp_multi.push(cell[1]);
      }
      new_cells.push(temp_brothers);
      new_multi.push(temp_multi);
    }
    return [new_cells, new_multi];
  },
  sortByX(cells) {
    const res = cells.sort((cell1, cell2) => {
      if (cell1.attributes.position.x > cell2.attributes.position.x) {
        return 1;
      }

      if (cell1.attributes.position.x < cell2.attributes.position.x) {
        return -1;
      }

      return 0;
    });
    return res;
  },
  sortByY(cells) {
    const res = cells.sort((cell1, cell2) => {
      if (cell1.attributes.position.y > cell2.attributes.position.y) {
        return 1;
      }

      if (cell1.attributes.position.y < cell2.attributes.position.y) {
        return -1;
      }

      return 0;
    });
    return res;
  },
  sortByText(cells) {
    return cells.sort((s1, s2) => {
      return s1.attributes.attrs.text.textWrap.text > s2.attributes.attrs.text.textWrap.text ? 1 : -1;
    });
  },
  sameFather(cells) {
    if (!(cells.length > 0)) {
      return false;
    }
    let father_id;
    for (const cell of cells) {
      if (cell.constructor.name === 'OpmState') {
        if (!father_id) {
          father_id = oplFunctions.getStatefulObject(cell).id;
        } else {
          if (oplFunctions.getStatefulObject(cell).id !== father_id) {
            return false;
          }
        }
      } else {
        return false;
      }
    }
    return true;
  },
  stringReverse(str) {
    return str.split('').reverse().join('');
  },
  addCapital(sentence) {
    if (!sentence || sentence === '' || sentence[0] === '<') {
      return sentence;
    }
    if (sentence[0] !== '<') {
      sentence = sentence[0].toUpperCase() + sentence.substr(1, sentence.length);
    }
    return sentence;
  },
  hasSameLinkProps(link1, link2) {
    return link1.constructor.name === link2.constructor.name &&
      link1.condition === link2.condition &&
      link1.event === link2.event &&
      link1.negation === link2.negation;
  },
  hasArc(link, allCells) {
    const allLinks = allCells.filter(dr => dr.isLink());
    const hasSourceArc = ![null, undefined].includes(link.getVisual().logicalElement.sourceLogicalConnection);
    if (hasSourceArc && link.getVisual().sourceVisualElementPort && link.getSourceElement()) {
      const links = allLinks.filter(dr => this.hasSameLinkProps(dr, link) && (dr.getVisual().logicalElement.sourceLogicalConnection === link.getVisual().logicalElement.sourceLogicalConnection) && link.getVisual().sourceVisualElementPort === dr.getVisual().sourceVisualElementPort)
      if (links.length >= 2 && link.get('name') !== 'Overtime_exception') {
        return true;
      }
    }
    const hasTargetArc = ![null, undefined].includes(link.getVisual().logicalElement.targetLogicalConnection);
    if (hasTargetArc && link.getVisual().targetVisualElementPort && link.getTargetElement()) {
      const links = allLinks.filter(dr => this.hasSameLinkProps(dr, link) && (dr.getVisual().logicalElement.targetLogicalConnection === link.getVisual().logicalElement.targetLogicalConnection) && link.getVisual().targetVisualElementPort === dr.getVisual().targetVisualElementPort)
      if (links.length >= 2 && link.get('name') !== 'Overtime_exception') {
        return true;
      }
    }
    return false;
  },
  getIDs(arcGroup) {
    const ids = new Set();
    for (const position of Object.keys(arcGroup)) {
      for (const linkType of Object.keys(arcGroup[position])) {
        for (const link of arcGroup[position][linkType]) {
          ids.add(link.id);
        }
      }
    }
    return ids;
  },
  getForbiddenIds() {
    return oplGenerating.forbidden;
  },
  removeLinks(links, forbidIDs, arcsOpl) {
    // filter forbidden links. and links that are already involved in in/out with or/xor (partners...)
    return links.filter(value => !forbidIDs.has(value.id) && !this.getForbiddenIds().includes(value.id));
  },
  splitByArcs(cells) {
    const withArcs = [];
    const withoutArcs = [];
    for (const cell of cells) {
      if (cell.isProceduralLink && cell.isProceduralLink() && cell.getSourceElement() && cell.getTargetElement()) {
        if (this.hasArc(cell, cells)) {
          withArcs.push(cell);
          continue;
        }
      }
      withoutArcs.push(cell);
    }
    const arcGroups = this.divideToGroupsByArcs(withArcs);
    return [withoutArcs, arcGroups];
  },
  divideToGroupsByArcs(cells) {
    const arcGroups = {};
    for (const cell of cells) {
      // console.log(cell.getSourceArcOnLink() , cell.getTargetArcOnLink());
      if (![null, undefined].includes(cell.getVisual().logicalElement.sourceLogicalConnection)) {
        // const arc = cell.getSourceArcOnLink();
        // console.log(cell.getSourceArcOnLink());
        let arcType;
        const currentArcTypeValue = cell.getVisual().logicalElement.sourceLogicalConnection;
        if (currentArcTypeValue === 2) {
          arcType = 'NOT';
        } else if (currentArcTypeValue === 1) {
          arcType = 'XOR';
        } else {
          arcType = 'OR';
        }
        const position = arcType + '_' + String(cell.getVisual().sourceVisualElementPort);
        arcGroups[position] = cells.filter(dr => this.hasSameLinkProps(dr, cell) && (dr.getVisual().logicalElement.sourceLogicalConnection === cell.getVisual().logicalElement.sourceLogicalConnection) && cell.getVisual().sourceVisualElementPort === dr.getVisual().sourceVisualElementPort);
      }
      if (![null, undefined].includes(cell.getVisual().logicalElement.targetLogicalConnection)) {
        // const arc = cell.getTargetArcOnLink();
        // console.log(arc.arcVec);
        let arcType;
        const currentArcTypeValue = cell.getVisual().logicalElement.targetLogicalConnection;
        if (currentArcTypeValue === 2) {
          arcType = 'NOT';
        } else if (currentArcTypeValue === 1) {
          arcType = 'XOR';
        } else {
          arcType = 'OR';
        }
        const position = arcType + '_' + String(cell.getVisual().targetVisualElementPort);
        arcGroups[position] = cells.filter(dr => this.hasSameLinkProps(dr, cell) && (dr.getVisual().logicalElement.targetLogicalConnection === cell.getVisual().logicalElement.targetLogicalConnection) && cell.getVisual().targetVisualElementPort === dr.getVisual().targetVisualElementPort);
      }
    }
    this.divideGroupsOfLinks(arcGroups);
    return arcGroups;
  },
  divideGroupsOfLinks(arcGroups) {
    for (const position of Object.keys(arcGroups)) {
      arcGroups[position] = this.divideToConditionEvent(arcGroups[position]);
    }
  },
  divideToConditionEvent(linksArray) {
    const objToReturn = {
      'regular': [],
      'event': [],
      'condition': []
    };
    for (const link of linksArray) {
      if (link.event) {
        objToReturn.event.push(link);
      } else if (link.condition) {
        objToReturn.condition.push(link);
      } else {
        objToReturn.regular.push(link);
      }
    }
    return objToReturn;
  },
  getStatefulObject(state) {
    return state.getParent();
  },

  sourceTargetRelation(source, target): any {
    if (!Array.isArray(target)) {
      const sourceID = source.attributes.id;
      const targetID = target.attributes.id;
      const sourceType = source.attributes.type;
      const targetType = target.attributes.type;
      const isParent = source.get('parent') === target.get('id');
      const isSame = source.get('id') === target.get('id');
      const relation = { relation: '', pairs: {} };

      switch (sourceType) {
        case 'opm.Object':
          if (targetType === 'opm.Object') {
            relation['relation'] = 'O1-O2';
            relation['pairs'] = { O1: source, O2: target };
          } else if (targetType === 'opm.Process') {
            relation['relation'] = 'O-P';
            relation['pairs'] = { O: source, P: target };
          } else if (targetType === 'opm.State') {
            const Object2 = this.getStatefulObject(target);
            relation['pairs'] = { O1: source, s: target, O2: Object2 };
            relation['relation'] = 'O1-O2s';
          }
          break;
        case 'opm.Process':
          if (targetType === 'opm.Object') {
            relation['pairs'] = { P: source, O: target };
            relation['relation'] = 'P-O';
          } else if (targetType === 'opm.Process') {
            if (targetID !== sourceID) {
              relation['pairs'] = { P1: source, P2: target };
              if (isSame) {
                relation['relation'] = 'P1-P1 (same process)';
              } else if (isParent) {
                relation['relation'] = 'P1-P2 (parent process)';
              } else {
                relation['relation'] = 'P1-P2';
              }
            } else {
              relation['pairs'] = { P1: source };
              relation['relation'] = 'P1-P1 (same process)';
            }
          } else if (targetType === 'opm.State') {
            const Object = this.getStatefulObject(target);
            relation['pairs'] = { P: source, s: target, O: Object };
            relation['relation'] = 'P-Os';
          }
          break;
        case 'opm.State':
          if (targetType === 'opm.Object') {
            const Object1 = this.getStatefulObject(source);
            relation['pairs'] = { s: source, O2: target, O1: Object1 };
            relation['relation'] = 'O1s-O2';
          } else if (targetType === 'opm.Process') {
            const Object = this.getStatefulObject(source);
            relation['pairs'] = { s: source, P: target, O: Object };
            relation['relation'] = 'Os-P';
            // TODO:In/out link pair identification
          } else if (targetType === 'opm.State') {
            const Object1 = this.getStatefulObject(source);
            const Object2 = this.getStatefulObject(target);
            relation['pairs'] = { s1: source, s2: target, O1: Object1, O2: Object2 };
            relation['relation'] = 'O1s1-O2s2';
          }
          break;

      }
      return relation;
    } else {
      const sourceType = source.attributes.type;
      const relation = { relation: '', pairs: {} };
      const len = target.length;
      if (sourceType === 'opm.Object') {
        relation['pairs'] = { O1: source, Tn: target[len - 1], 'T1...n-1': target.slice(0, len - 1) };
        relation['relation'] = 'O1-T2..n (n>=2 many destinations)';
      }
      if (sourceType === 'opm.Process') {
        relation['pairs'] = { P1: source, Tn: target[len - 1], 'T1...n-1': target.slice(0, len - 1) };
        relation['relation'] = 'P1-T2..n (n>=2 many destinations)';
      }
      if (sourceType === 'opm.State') {
        const O1 = this.getStatefulObject(source);
        relation['pairs'] = { s1: source, O1: O1, Tn: target[len - 1], 'T1...n-1': target.slice(0, len - 1) };
        relation['relation'] = 'O1s1-T2..n (n>=2 many destinations)';
      }
      return relation;
    }
  },
  replaceInTemplate(code, cell, template, linkType) {
    let opl = template;
    code = `<${code}>`;
    if (code.indexOf('tag') > -1) {
      opl = opl.replace(code, cell);
      return opl;
    }
    while (cell && (opl.indexOf(code) > -1)) {
      // opl = opl.replace(code, `<opcloud-opl-element [cell]="${cell}"></opcloud-opl-element>`);
      if (Array.isArray(cell) && cell[0].attributes.type === 'opm.State') {
        let selectHtml = ``;
        selectHtml = `<select name="selectStates">`;
        for (const c of cell) {
          const text = this.replaceBracelets(c.attributes.attrs.text.textWrap.text);
          selectHtml = selectHtml + `<option value="${text}"> ${text}</option>`;
        }
        selectHtml = selectHtml + `</select>`;
        opl = opl.replace(code, selectHtml);
      } else {
        if (code !== `<T1...n-1>`) {
          // YANG's code to handle, ['grouping']['Attribute-Exhibitors'] does'nt exist anymore.
          /*try{
            if (code.indexOf('s')===-1  && cell.getExhibitors().length > 0 && linkType !== 'Exhibition-Characterization') {
              opl = opl.replace(code, this.generateAttributeExhibitors(code, cell));
            }
          }catch(e){console.log(e)}*/
          opl = opl.replace(code, this.generateCellTag(cell));
        } else {
          opl = opl.replace(code, this.generateSubGroup(cell));
        }
      }
    }
    const pairs = [];
    if (opl.includes('<units>') && ['<P1>', '<s>'].includes(code) && cell.getVisual().logicalElement.duration.units) {
      pairs.push([`<units>`, cell.getVisual().logicalElement.duration.units]);
    }
    if (opl.includes('<mintime>') && ['<P1>', '<s>'].includes(code) && cell.getVisual().logicalElement.duration.min) {
      pairs.push([`<mintime>`, cell.getVisual().logicalElement.duration.min]);
    }
    if (opl.includes('<maxtime>') && ['<P1>', '<s>'].includes(code) && cell.getVisual().logicalElement.duration.max) {
      pairs.push([`<maxtime>`, cell.getVisual().logicalElement.duration.max]);
    }


    for (const pair of pairs) {
      while (opl.indexOf(pair[0]) > -1) {
        opl = opl.replace(pair[0], pair[1]);
      }
    }
    // opl = this.replaceContents(opl, pairs);

    return opl;
  },

  generateRelationOPL(pairs, template, linkType) {
    if (template) {
      let opl = template;
      for (const code of Object.keys(pairs)) {
        opl = this.replaceInTemplate(code, pairs[code], opl, linkType);
      }
      opl = opl.replace(`.`, `<b>.</b>`);

      return opl;
    } else {
      return ``;
    }
  },

  generateLinksWithOpl(link) {
    const source = link.getSourceElement();
    const target = link.getTargetElement();
    if (source.constructor.name.includes('Semi')) {
      // const type = source.getVisual().type.includes('Process') ? 'opm.Process' : 'opm.Object';
      source.attr('text/textWrap/text', source.getVisual().logicalElement.text);
      // source.set('type', type);
    }
    const multiSelection = getInitRappidShared().selection.collection.models.filter(el => el instanceof OpmEntity);
    const multiTargets = multiSelection.length > 1 ? multiSelection : [link.getTargetElement()];
    if (!multiTargets.includes(target)) {
      multiTargets.push(target);
    }
    // const ret = this.generateLinksWithOplByElements(source, target);
    let ret;
    for (const trgt of multiTargets) {
      if (multiTargets.indexOf(trgt) === 0) {
        ret = this.generateLinksWithOplByElements(source, trgt);
      } else {
        const temp = this.generateLinksWithOplByElements(source, trgt);
        for (const lnk of temp) {
          const idx = this.getIndexOfLink(ret, lnk.name);
          if (idx === -1) {
            ret.push(lnk);
            continue;
          }
          if (ret[idx] && lnk.opl) {
            ret[idx].opl = ret[idx].opl + '<br>' + lnk.opl;
          }
        }
      }
    }
    return ret;
  },
  getIndexOfLink(tableOfLinks, linkName: string) {
    for (let i = 0; i < tableOfLinks.length; i++) {
      if (tableOfLinks[i].name === linkName) {
        return i;
      }
    }
    return -1;
  },
  generateLinksWithOplByElements(source, target) {

    const stRelation = this.sourceTargetRelation(source, target);
    const relation = stRelation['relation'];
    const pairs = stRelation['pairs'];
    const list = Object.assign({}, linkTable[relation]);
    const init = getInitRappidShared();

    // Yang: to deal with in/out link pairs
    if (relation === 'Os-P') {
      const srcObject = this.getStatefulObject(source);
      const states = srcObject.getEmbeddedCells();
      if (states.length < 2) {
        delete list['In-out_Link_Pair'];
      } else {
        pairs['s1'] = source;
        const s2 = init.opmModel.links.getDestinationInOutState(source.getVisual(), InOutPairType.Standart);
        if (s2) {
          pairs['s2'] = source.graph.getCell(s2.id);
        }
        // const lastState = states[states.length - 1];
        // if (source === lastState) {
        //   pairs['s2'] = states[states.length - 2];
        // } else {
        //   pairs['s2'] = states[states.indexOf(source) + 1];
        // }
      }
    } else if (relation === 'P-Os') {
      const trgObject = this.getStatefulObject(target);
      const states = trgObject.getEmbeddedCells().filter(s => OPCloudUtils.isInstanceOfDrawnState(s));
      if (states.length < 2) {
        delete list['In-out_Link_Pair'];
      } else {
        pairs['s2'] = target;
        pairs['s1'] = target.graph.getCell(init.opmModel.links.getDestinationInOutState(target.getVisual(), InOutPairType.Standart).id);
        // const lastState = states[states.length - 1];
        // if (target === lastState) {
        //   pairs['s1'] = states[states.length - 2];
        // } else {
        //   pairs['s1'] = states[states.indexOf(target) + 1];
        // }
      }
    } else if (relation === 'O1-O2s') {
      list['Exhibition-Characterization'] = list['Exhibition-Characterization'].replace('at state', 'with value');
    }
    const result = [];
    // a note cell is not registered in OPL, thus the result list must be empty
    if (target.attributes.type === 'notes.Note' || source.attributes.type === 'notes.Note') {
      return result;
    }
    for (const linkType of Object.keys(list)) {
      const OPL = this.generateRelationOPL(pairs, list[linkType], linkType);
      result.push({ name: linkType, opl: OPL });
    }
    return result;
  },

  generateCellTag(cell) {
    if (cell.attributes.targetMultiplicity) {
      const tag = cell.attributes.targetMultiplicity;
      cell.attributes.targetMultiplicity = null;
      const text = oplGenerating.replaceBracelets(cell.getVisual().logicalElement.getBareName());
      return `<b>${tag}</b> <b class="${cell.attributes.type.slice(4).toLowerCase()} " lid="${cell.getVisual().logicalElement.lid}">${text}</b>`;
    }
    const txt = cell.getVisual() ? cell.getVisual().logicalElement.getBareName() : cell.attributes.attrs.text.textWrap.text;
    return `<b class="${cell.attributes.type.slice(4).toLowerCase()}" lid="${cell.getVisual().logicalElement.lid}">${oplGenerating.replaceBracelets(txt)}</b>`;
  },

  generateSubGroup(things) {
    let grouping = ``;
    let i = 0;
    if (things) {
      const len = things.length;
      if (len === 1) {
        grouping = grouping + this.generateCellTag(things[i]);
      } else {
        grouping = grouping + this.generateCellTag(things[i]);
        i = 1;
        for (i; i < len; i++) {
          grouping = grouping + this.generateCellTag(things[i]);
        }
      }
    }
    return grouping;
  },

  extractAndDelete(text, type) {
    let alias = '';
    let new_text = '';
    let beg = false;
    let afterbraclet = false;
    for (let i = 0; i < text.length; i++) {
      const letter = text.charAt(i);
      if (letter === type[0]) {
        beg = true;
        if (i > 0 && [' ', '\n'].indexOf(new_text[i - 1]) > -1) {
          new_text = new_text.substr(0, new_text.length - 1);
        }
        continue;
      }
      if (letter === type[1]) {
        beg = false;
        afterbraclet = true;
        continue;
      }
      if (beg) {
        alias = alias.concat(letter); // not necessary alias.
      } else {
        if (afterbraclet && [' ', '\n'].indexOf(new_text[i]) > -1) {
          continue;
        }
        new_text = new_text.concat(letter);
      }
    }
    return [alias, new_text];
  },

  addRange(val) {
    const str = String(val);
    if (str.length < 1) {
      return val;
    }
    for (const r of Object.keys(oplTemplates['ranges'])) {
      if (str.startsWith(r)) {
        let template = oplTemplates['tags']['range'];
        template = template.replace(`<r>`, oplTemplates['ranges'][r]);
        template = template.replace(`<opl>`, str.substr(r.length));
        return template;
      }
    }
    return val;
  },

  generateCellProperties(cell, link = undefined, withExhibitors = true, isObjectOfState = false, withProbability = false) {
    const obj = {
      cell: cell,
      link: link,
      withExhibitors: withExhibitors,
      multiplicity: undefined,
      probability: undefined,
      rate: undefined,
      rateUnits: undefined,
    };
    if (!link) {
      return obj;
    }
    if (isObjectOfState && cell.attributes.type === 'opm.Object') {
      const states = cell.getStatesOnly();
      for (const state of states) {
        if (link.sourceElement.id === state.id) {
          obj.multiplicity = link.attributes.sourceMultiplicity;
        }
        if (link.targetElement.id === state.id) {
          obj.multiplicity = link.attributes.targetMultiplicity;
        }
      }
      return obj;
    }

    // const sourceType = link.sourceElement.attributes.type; // if the source is a process than the probability addition should be next to the target
    // // otherwise it should be next to the source
    // const targetProb = (link.attributes.name !=='Instrument')||(link.attributes.name !=='Agent');
    if (withProbability) {
      obj.probability = link.attributes.Probability;
    }
    if (cell.attributes.type === 'opm.State') {// state needs only probability and not multiplicity.
      return obj;
    }
    if (link.targetElement.id === cell.id) {
      obj.multiplicity = link.attributes.targetMultiplicity;
    }
    if (link.sourceElement.id === cell.id) {
      obj.multiplicity = link.attributes.sourceMultiplicity;
    }
    if (['Result', 'Consumption', 'Effect'].indexOf(link.attributes.name) > -1 && cell.attributes.type === 'opm.Object') {
      // console.log("Add rate",link);
      obj.rate = link.attributes.rate;
      obj.rateUnits = link.attributes.rateUnits;
    }


    return obj;

  },

  /*generateCellProperties(cell,link=undefined,withExhibitors=true,isObjectOfState=false){
    let obj = {
      cell: cell,
      link: link,
      withExhibitors: withExhibitors,
      multiplicity: undefined,
      probability: undefined,
      rate: undefined,
    };
    if(!link){
      return obj;
    }
    const sourceType = link.sourceElement.attributes.type; // if the source is a process than the probability addition should be next to the target
    // otherwise it should be next to the source
    if(isObjectOfState && cell.attributes.type === 'opm.Object') {
      let states = cell.getStatesOnly();
      for (let state of states) {
        if (link.sourceElement.id === state.id) {
          obj.multiplicity = link.attributes.sourceMultiplicity;
        }
        if (link.targetElement.id === state.id) {
          obj.multiplicity = link.attributes.targetMultiplicity;
        }
      }
      return obj;
    }
    if(cell.attributes.type === 'opm.State'){
      obj.probability = link.attributes.Probability;
      console.log(link,cell,obj,link.attributes.Probability);
      return obj;
    }
    if(link.targetElement.id === cell.id){
      obj.rate = link.attributes.rate;
      obj.multiplicity = link.attributes.targetMultiplicity;
      if(sourceType === 'opm.Process'){
        obj.probability = link.attributes.Probability;
      }
    }
    if(link.sourceElement.id === cell.id){
      obj.multiplicity = link.attributes.sourceMultiplicity;
      if(sourceType !== 'opm.Process'){
        obj.probability = link.attributes.Probability;
      }
    }
    return obj;
  }*/

  getProcessStateTimeAttrs(process, target) {
    const init = getInitRappidShared();
    const logical = init.getOpmModel().getLogicalElementByVisualId(process.id);
    let onlyName;
    if (logical) {
      onlyName = logical.getBareName();
    }
    if (!init.getOpmModel().getLogicalElementByVisualId(process.id)) {
      return { units: undefined, min: undefined, max: undefined, expected: undefined, template: '' };
    }
    const duration = init.getOpmModel().getLogicalElementByVisualId(process.id).getDurationManager().getTimeDuration();
    let numOfParmeters = ((duration.min != null) && (duration.min.toString() != 'null') && (duration.min.toString() != '')) ? 1 : 0;
    numOfParmeters = ((duration.nominal != null) && (duration.nominal.toString() != 'null') && (duration.nominal.toString() != '')) ? (numOfParmeters + 1) : numOfParmeters;
    numOfParmeters = ((duration.max != null) && (duration.max.toString() != 'null') && (duration.max.toString() != '')) ? (numOfParmeters + 1) : numOfParmeters;

    const text = process.attributes.attrs.text.textWrap.text;
    const subStr = text.substr(onlyName.length);
    // const source = target.getSourceElement();

    let units = subStr.match(/\[\w+\]/);
    // let range = text.match(/\([0-9]?[\,\.0-9]*\)/);
    const minTxt = subStr.match(/min=/ig);
    const nomTxt = subStr.match(/nom=/ig);
    const maxTxt = subStr.match(/max=/ig);
    // let numOfCommas = subStr.match(/[,]/ig);
    // let range = subStr.match(/[0-9]/g);
    if (!numOfParmeters) {
      return null;
    }
    if (!units) {
      if (target === 'OpmProcess') {
        units = oplTemplates['process']['default_time_units'];
      }
      if (target === 'OpmState') {
        units = oplTemplates['state']['default_time_units'];
      }
    } else {
      units = units[0].slice(1, -1);
    }
    const vals = numOfParmeters;
    let template = ``;
    let min = '';
    let max = '';
    let exp = '';
    if (vals === 3) {
      if (target === 'OpmProcess') {
        template = oplTemplates['process']['expected_range_duration'];
      }
      if (target === 'OpmState') {
        template = oplTemplates['state']['expected_range_duration'];
      }
      min = duration.min;
      exp = duration.nominal;
      max = duration.max;
    }
    if (vals === 2) {
      if (duration.min != null && duration.max != null) {
        if (target === 'OpmProcess') {
          template = oplTemplates['process']['min_max_range_duration'];
        }
        if (target === 'OpmState') {
          template = oplTemplates['state']['min_max_range_duration'];
        }
        min = duration.min;
        max = duration.max;
        // exp = min + '-' + max
      } else if (duration.min != null && duration.nominal != null) {
        if (target === 'OpmProcess') {
          template = oplTemplates['process']['min_exp_range_duration'];
        }
        if (target === 'OpmState') {
          template = oplTemplates['state']['min_exp_range_duration'];
        }
        min = duration.min;
        exp = duration.nominal;
        // exp = min + '-' + exp
      } else if (duration.nominal != null && duration.nominal != null) {
        if (target === 'OpmProcess') {
          template = oplTemplates['process']['exp_max_range_duration'];
        }
        if (target === 'OpmState') {
          template = oplTemplates['state']['exp_max_range_duration'];
        }
        exp = duration.nominal;
        max = duration.max;
        // exp = exp + '-' + max
      }
    }
    if (vals === 1) {
      // if(nomTxt!= '' && nomTxt!= null) {
      if (duration.nominal != null) {
        if (target === 'OpmProcess') {
          template = oplTemplates['process']['expected_duration'];
        }
        if (target === 'OpmState') {
          template = oplTemplates['state']['expected_duration'];
        }
        exp = duration.nominal;
        // min = exp;
        // max = exp;
      } else if (duration.min != null) {
        if (target === 'OpmProcess') {
          template = oplTemplates['process']['min_duration'];
        }
        if (target === 'OpmState') {
          template = oplTemplates['state']['min_duration'];
        }
        min = duration.min;
        // min = exp;
        // max = exp;
      } else if (duration.max != null) {
        if (target === 'OpmProcess') {
          template = oplTemplates['process']['max_duration'];
        }
        if (target === 'OpmState') {
          template = oplTemplates['state']['max_duration'];
        }
        max = duration.max;
        // min = exp;
        // max = exp;
      }

    }

    return {
      units: units,
      min: min,
      max: max,
      expected: exp,
      template: template,
    };
  },
  cleanThingName(text) {
    let temp = this.extractAndDelete(text, ['[', ']']);
    text = temp[1];
    temp = this.extractAndDelete(text, ['(', ')']);
    text = temp[1];
    return text;
  }

};




export const oplGenerating = {
  period() {
    let period = '.';
    if (this.textOnly) {
      period = '.';
    } else {
      period = '<b>.</b>';
    }
    return period;
  },
  comma() {
    let comma = ',';
    if (this.textOnly) {
      comma = ',';
    } else {
      comma = '<b>,</b>';
    }
    return comma;
  },
  replaceContents(template, pairs) {
    for (const pair of pairs) {
      while (template.indexOf(pair[0]) > -1) {
        template = template.replace(pair[0], pair[1]);
      }
    }
    while (!this.textOnly && template.indexOf(', ') > -1) {
      template = template.replace(', ', `${this.comma()} `);
    }
    template = template.replace('.', this.period());
    return template;
  },
  getObjectOfState(cell) {
    let mainObject = null;
    if (cell.attributes.type === 'opm.State') {
      mainObject = cell.getParent();
    } else {
      mainObject = cell;
    }
    return mainObject;
  },
  /*
    myfunc(cell,withExhibitors = true , multiplicity = undefined){
      // if (cell === "self"){
      //   return `<b class="process">itself</b>`;
      // }
      let template = oplTemplates['grouping']['Single-Thing'];
      if(withExhibitors){
        template = this.generateExhibitorsHtml(cell)
      }
      let cellID = cell.attributes.id ? cell.attributes.id : "NoID";
      let objClass = cell.attributes.type.slice(4, ).toLowerCase();
      if(objClass === 'state'){
        let obj = oplFunctions.getStatefulObject(cell);
        let val = cell.attributes.attrs.text.textWrap.text;
        val = oplFunctions.addRange(val);
        let obj_text = obj.attributes.attrs.text.textWrap.text;
        let units = "";
        if(obj.attributes.attrs.value.value !== 'None'){ //if state is computational
          let ans = oplFunctions.extractAndDelete(obj.attributes.attrs.text.textWrap.text, ['[', ']']);
          units = ans[0];
        }
        else{ // state is note computational.
          if(obj_text.length>0 && obj_text.charAt(obj_text.length-1) === ']'){
            let ans = oplFunctions.extractAndDelete(oplFunctions.stringReverse(obj_text) , ['[', ']'].reverse());
            units = oplFunctions.stringReverse(ans[0]);
          }
        }
        if (this.textOnly) {
          return cell.attributes.attrs.text.textWrap.text;
        }
        if(units && units!==''){
          return `<b cellID="${'cellID'}" class="${'state'}">${val}</b> <b class="${'object'}">${units}</b>`;
        }
        return `<b cellID="${'cellID'}" class="${'state'}">${val}</b>`;
      }
      if(this.textOnly){
        return cell.attributes.attrs.text.textWrap.text;
      }
      let text = this.generateCellComputation(cell);
      if(multiplicity && ['+','?'].indexOf(multiplicity)===-1){
        text = oplFunctions.addPlural(text);
      }
      template = template.replace(`<T>`,`<b cellID="${cellID}" class="${objClass}">${text}</b>`);
      return this.addMultiplicity(multiplicity, template);
    },
  */
  addPathForOrXor(template, link) {
    if (link.get('Path') && (link.getSourceArcOnLink() || link.getTargetArcOnLink())) {
      return template + ' following path ' + link.get('Path');
    }
    return template;
  },
  replaceBracelets(str) {
    let ret = str;
    if (str.includes('<')) {
      const re = new RegExp('<', 'g');
      ret = ret.replace(re, '&#60;');
    }
    if (str.includes('>')) {
      const re = new RegExp('>', 'g');
      ret = ret.replace(re, '&#62;');
    }
    return ret;
  },
  generateCellHtml(cell, link?, withExhibitors?, isObjectOfState?, withProbability?, propObj?) {
    if (cell && cell.constructor.name.includes('Semi')) {
      return this.generateSemifoldedThingCellHtml(cell);
    }
    if (!propObj) {
      propObj = oplFunctions.generateCellProperties(cell, link, withExhibitors, isObjectOfState, withProbability);
    }
    const init = getInitRappidShared();
    const logical = init.getOpmModel().getLogicalElementByVisualId(cell.id);
    let onlyName;
    if (logical) {
      onlyName = logical.getBareName();
    } else {
      onlyName = cell.attr('text/textWrap/text');
    }
    onlyName = this.replaceBracelets(onlyName);
    let template = oplTemplates['grouping']['Single-Thing'];
    if (link && link.isProceduralLink && link.isProceduralLink()) {
      const connectedLinks = cell.graph.getConnectedLinks(cell, { 'inbound': true });
      if (connectedLinks.find(l => l.constructor.name.includes('ExhibitionLink')) !== undefined) {
        propObj.withExhibitors = true;
      }
      if (propObj.withExhibitors === false && link) {
        template = this.addPathForOrXor(template, link);
      }
    }
    if (propObj.withExhibitors) {
      template = this.generateExhibitorsHtml(cell);
    }
    const objClass = cell.attributes.type.slice(4).toLowerCase();
    const colorLikeElement = init.oplService.settings.syncOplcolorsFromOpd;
    const textStyle = (colorLikeElement && cell.getVisual && cell.getVisual() && cell.getVisual().strokeColor);
    if (objClass === 'state') {
      const obj = oplFunctions.getStatefulObject(cell);
      let val = cell.attributes.attrs.text.textWrap.text;
      if (cell.getVisual() && (cell.getVisual().isTimeDuration() || cell.getParent().isComputational())) {
        val = onlyName;
      }
      val = oplFunctions.addRange(val);
      const obj_text = obj.attributes.attrs.text.textWrap.text;
      let units = '';
      if (obj.attributes.attrs.value.value !== 'None') { // if state is computational
        const ans = oplFunctions.extractAndDelete(obj.attributes.attrs.text.textWrap.text, ['[', ']']);
        units = ans[0];
      } else { // state is not computational.
        if (obj_text.length > 0 && obj_text.charAt(obj_text.length - 1) === ']') {
          const ans = oplFunctions.extractAndDelete(oplFunctions.stringReverse(obj_text), ['[', ']'].reverse());
          units = oplFunctions.stringReverse(ans[0]);
        }
      }
      if (this.textOnly) {
        return this.replaceBracelets(cell.attributes.attrs.text.textWrap.text);
      }
      let temp = '';
      if (textStyle) {
        let color = cell.getVisual().strokeColor;
        if (color === 'transparent') {
          color = '#808000';
        }
        if (units && units !== '') {
          temp = `<font color=${color}><b class="${cell.id}" lid="${cell.getVisual().logicalElement.lid}">${this.generateValueText(val)}</b> <b class="${'object'}">${units}</b></font>`;
        } else {
          temp = `<font color=${color}><b class="${cell.id}" lid="${cell.getVisual().logicalElement.lid}">${val}</b></font>`;
        }
      } else {
        if (units && units !== '') {
          temp = `<b class="${'state ' + cell.id}" lid="${cell.getVisual().logicalElement.lid}">${this.generateValueText(val)}</b> <b class="${'object'}">${units}</b>`;
        } else {
          temp = `<b class="${'state ' + cell.id}" lid="${cell.getVisual().logicalElement.lid}">${val}</b>`;
        }
      }
      temp = this.addProbability(propObj.probability, temp);
      if (propObj.probability && propObj.rate) {
        temp = temp + ', ';
      }
      temp = this.addRate(propObj.rate, temp, propObj.rateUnits);
      return temp;
    }


    if (this.textOnly) {
      return this.replaceBracelets(cell.attributes.attrs.text.textWrap.text);
    }
    let text = this.generateCellComputation(cell);
    text = oplFunctions.cleanThingName(text);
    text = this.replaceBracelets(text);
    if (propObj.multiplicity && ['+', '?', '1'].indexOf(propObj.multiplicity) === -1) {
      text = oplFunctions.addPlural(text);
    }
    let computationalProcessVal = '';
    if (cell.attributes.type === 'opm.Process') {
      if (cell.attributes.attrs.value.value === 'userDefined') {
        computationalProcessVal = 'computationalfunction="' + encodeURIComponent(cell.attributes.userDefinedFunction.functionInput) + '"';
      } else if (cell.attributes.attrs.value.value === 'userPythonDefined') {
        computationalProcessVal = 'computationalfunction="' + encodeURIComponent(cell.attributes.userPythonDefinedFunction.functionInput) + '"';
      }
      computationalProcessVal = this.replaceBracelets(computationalProcessVal);
    }
    if (textStyle) {
      let color = cell.getVisual().strokeColor;
      if (color === '#3BC3FF' || (color === 'transparent' && cell.attributes.type === 'opm.Process')) {
        color = '#0070c0';
      } else if (color === '#70E483' || (color === 'transparent' && cell.attributes.type === 'opm.Object')) {
        color = '#00b050';
      }
      template = template.replace(`<T>`, `<font color=${color}><b class="${cell.id}" ${computationalProcessVal} lid="${cell.getVisual().logicalElement.lid}">${text}</b></font>`);
    } else {
      template = template.replace(`<T>`, `<b class="${objClass}" ${computationalProcessVal} lid="${cell.getVisual().logicalElement.lid}">${text}</b>`);
    }
    template = this.addMultiplicity(propObj.multiplicity, template);
    template = this.addProbability(propObj.probability, template);
    if (propObj.probability && propObj.rate) {
      template = template + ', ';
    }
    template = this.addRate(propObj.rate, template, propObj.rateUnits);
    return template;
  },
  generateSemifoldedThingCellHtml(cell) {
    if (cell) {
      const text = this.replaceBracelets(cell.attributes.attrs.label.text);
      return `<b class="${cell.attributes.type.slice(4).toLowerCase()}" lid="${cell.getVisual().logicalElement.lid}">${text}</b>`;
    }
    return '';
  },
  generateValueText(tag) {
    const reg1 = /^([\.,0-9,a-z,A-Z]+)\.\.([0-9,a-z,A-Z,\.]+|\*)$/;
    const reg2 = /^([\.,0-9,a-z,A-Z]+)\.\.([0-9,a-z,A-Z,\.]+|\*)\.\.([0-9,a-z,A-Z,\.]+|\*)$/;
    const data1 = tag.match(reg1);
    const data2 = tag.match(reg2);
    if (data2) {
      const low = data2[1];
      const mean = data2[2];
      const high = data2[3];
      let temp = oplTemplates['symbols']['n..mean..n'];
      temp = temp.replace(`<n1>`, low);
      temp = temp.replace(`<n2>`, high);
      temp = temp.replace(`<mean>`, mean);
      return temp;
    }
    if (data1) {
      const low = data1[1];
      const high = data1[2];
      let temp = oplTemplates['symbols']['n..n'];
      temp = temp.replace(`<n1>`, low);
      temp = temp.replace(`<n2>`, high);
      return temp;
    }
    return tag;

  },
  generateCellComputation(cell) {
    let text = cell.attributes.attrs.text.textWrap.text;
    let alias = '';
    let units = '';
    const cc = this.options.opmModel.getCurrentConfiguration();
    const visual = cell.getVisual();
    if (visual && visual.logicalElement && cc && cc[cell.getVisual().logicalElement.lid] && cc[cell.getVisual().logicalElement.lid] !== 0) {
      text = oplFunctions.extractAndDelete(text, ['{', '}'])[1];
    }
    if (cell && cell.attributes.type === 'opm.Object') {
      if (cell.attributes.attrs.value.value !== 'None' || ((text.length > 0) && (text.charAt(text.length - 1) === ']'))) {
        const ans_unit = oplFunctions.extractAndDelete(text, ['[', ']']); // just for deleting the units.
        units = ans_unit[0];
        text = ans_unit[1];
      }
      // if (cell.attributes.attrs.value.value === 'None') { //if object is not computational
      //   return text;
      // }
      const ans_alias = oplFunctions.extractAndDelete(text, ['{', '}']);
      alias = ans_alias[0];
      text = ans_alias[1];
      if (alias !== '') {
        text = text.trim();
        text = text.concat(', ', alias, ',');
      }
    }
    return text;
  },
  generateThingHtml(cell_array, logicType = 'AND', link?, withProbability = false) {// cell_array can be DrawnElement, or array with DrawnElement\s
    let cell = cell_array;
    if (cell_array instanceof Array) {
      cell = cell_array[0];
    }
    try {
      if (cell.attributes.type === 'opm.State') {
        const parent = cell.getParent();
        if (!(cell_array instanceof Array) || cell_array.length === 1) {
          const stateFullUni = (link && link.get('name').includes('Uni')) ? 'Stateful-Object-unidirectional' : (link && link.get('name').includes('Exhibition-Characterization')) ? 'Stateful-Object-value' : 'Stateful-Object';
          let template = oplTemplates['grouping'][stateFullUni];
          template = template.replace('<s>', this.generateCellHtml(cell, link, true, false, withProbability));
          template = template.replace('<O>', this.generateCellHtml(parent, link, true, true, withProbability));
          return template;
        } else {
          const links = link; // in that case link is an array of links
          let template = '';
          if (links[0] && links[0].get('name').includes('Exhibition-Characterization')) {
            template = oplTemplates['grouping']['Stateful-Object-value(multiple)'];
          } else {
            template = oplTemplates['grouping']['Stateful-Object(multiple)'];
          }
          template = template.replace('<s1...n>', this.generateGroupOfStates(cell_array, 'OR', links, withProbability)); // mytest, Object can be at one state at a time, OR semantic.
          template = template.replace('<O>', this.generateCellHtml(parent, links[0], true, false, withProbability)); // mytest
          return template;
        }
      }
    } catch (e) {
      console.log(e);
    }
    return this.generateCellHtml(cell, link, false, false, withProbability);
  },

  generateGroupOfThings(cells, links = undefined, logicType = 'AND', withProbability = false, parallelSeq = false, preventReorder = false) {
    let numOfCells = cells.length;
    if (!links) {
      links = Array(numOfCells).fill(undefined);
    }
    const organized = oplFunctions.groupStates(cells, links, parallelSeq, preventReorder); // returns array of arrays, that each array contains 1 process\object\state , or group of states with the same father.
    // Cells in ascending order
    cells = organized[0];
    links = organized[1];
    numOfCells = cells.length;

    let template = oplTemplates['grouping'][logicType + (numOfCells > 2 && links.length && logicType === 'AND' ? '(links)' : '')];
    if (numOfCells === 1) {
      return this.generateThingHtml(cells[0], logicType, links[0], withProbability);
    } else if (numOfCells === 2) {
      const T1 = this.generateThingHtml(cells[0], logicType, links[0], withProbability);
      const T2 = this.generateThingHtml(cells[1], logicType, links[1], withProbability);
      template = template.replace('<T1...n-1>', T1);
      template = template.replace('<Tn>', T2);
    } else {
      let i = 1;
      let T1 = this.generateThingHtml(cells[0], logicType, links[0], withProbability) + '<b>,</b>';
      for (i; i < numOfCells - 2; i++) {
        T1 = T1 + ' ' + this.generateThingHtml(cells[i], logicType, links[i], withProbability) + '<b>,</b> ';
      }
      T1 = T1 + ' ' + this.generateThingHtml(cells[numOfCells - 2], logicType, links[numOfCells - 2], withProbability);
      const T2 = this.generateThingHtml(cells[i + 1], logicType, links[i + 1], withProbability);
      template = template.replace('<T1...n-1>', T1);
      template = template.replace('<Tn>', T2);
    }
    return template;
  },
  generateGroupOfObjectsAndProjects(cells, links, preventReorder = false) {
    const objects = [];
    const objectsLinks = [];
    const processes = [];
    const processLinks = [];
    for (let i = 0; i < cells.length; i++) {
      if (cells[i].attributes.type === 'opm.Process') {
        processes.push(cells[i]);
        processLinks.push(links[i]);
      } else {
        objects.push(cells[i]);
        objectsLinks.push(links[i]);
      }
    }
    if (objects.length === 0 || processes.length === 0) {
      return this.generateGroupOfThings(cells, links, 'AND',  false, false, preventReorder);
    } else {
      let template = oplTemplates['grouping']['Multiple-Things-Object-Process-Separated'];
      const initRappid = getInitRappidShared();
      const visualLink = initRappid.getOpmModel().getVisualElementById(links[0].id);
      if (visualLink && visualLink.sourceVisualElement.type.includes('Process')) {
        template = template.replace('<O1...n>', '<G1...n>');
        template = template.replace('<P1...n>', '<O1...n>');
        template = template.replace('<G1...n>', '<P1...n>');
      }
      const template_map = ['grouping', 'Multiple-Things-Object-Process-Separated'];
      template = template.replace('<O1...n>', this.generateGroupOfThings(objects, objectsLinks));
      template = template.replace('<P1...n>', this.generateGroupOfThings(processes, processLinks));
      return template;
    }

  },

  generateGroupOfStates(cells, logicType = 'OR', links?, withProbability?, onlyStates = true) {
    cells = cells.filter(function (cell) {
      const objClass = cell.attributes.type.slice(4, ).toLowerCase();
      const bool = objClass === 'state';
      onlyStates = onlyStates && bool;
      return bool;
    });
    if (cells.length === 0) {
      if (!onlyStates) {
        return oplTemplates['procedural_link']['Split'];
      }
      return '';
    }
    // if (['left', 'right'].includes(cells[0]?.getParentCell()?.getParams()?.statesArrangement)) {
    //   cells = oplFunctions.sortByY(cells);
    // } else { cells = oplFunctions.sortByX(cells); }
    cells = oplFunctions.sortByText(cells);
    const numOfCells = cells.length;
    let template = oplTemplates['grouping'][logicType];
    if (!links) {
      links = Array(numOfCells).fill(undefined);
    }
    if (numOfCells === 1) {
      if (!onlyStates) {
        template = template.replace('<T1...n-1>', this.generateCellHtml(cells[0], links[0], true, false, withProbability));
        template = template.replace('<Tn>', oplTemplates['procedural_link']['Split']);
      } else {
        return this.generateCellHtml(cells[0], links[0], true, false, withProbability);
      }
    } else if (numOfCells === 2) {
      const T1 = this.generateCellHtml(cells[0], links[0], true, false, withProbability);
      const T2 = this.generateCellHtml(cells[1], links[1], true, false, withProbability);
      if (!onlyStates) {
        template = template.replace('<T1...n-1>', T1 + '<b class="bolderComma">, </b>' + T2);
        template = template.replace('<Tn>', oplTemplates['procedural_link']['Split']);
      } else {
        template = template.replace('<T1...n-1>', T1);
        template = template.replace('<Tn>', T2);
      }
    } else {
      let i = 1;
      let T1 = this.generateCellHtml(cells[0], links[0], true, false, withProbability);
      for (i; i < numOfCells - 1; i++) {
        T1 += `<b class="bolderComma">,</b>` + ` ` + this.generateCellHtml(cells[i], links[i], true, false, withProbability);
      }
      const T2 = this.generateCellHtml(cells[i], links[i], true, false, withProbability);
      if (!onlyStates) {
        template = template.replace('<T1...n-1>', T1 + '<b class="bolderComma">, </b>' + T2);
        template = template.replace('<Tn>', oplTemplates['procedural_link']['Split']);
      } else {
        template = template.replace('<T1...n-1>', T1);
        template = template.replace('<Tn>', T2);
      }
    }
    return template;
  },
  generateExhibitorsHtml(exhibitor) {
    const predecessor = exhibitor.getExhibitors();
    if (predecessor.length === 0) {
      return oplTemplates['grouping']['Single-Thing'];
    } else {
      let template = oplTemplates['grouping']['Attribute-Exhibitor'];
      template = template.replace('<e1...n>', this.generateGroupOfThings(predecessor));
      return template;
    }
  },
  addMultiplicity(tag, objectHtml) {
    if (tag && !(tag instanceof Array)) {
      let template = oplTemplates['tags']['multiplicity'];
      const tags = this.extractTag(tag);
      template = template.replace('<tag>', tags[0]);
      template = template.replace('<O_Os>', objectHtml);
      if (tags[1]) {
        const temp = oplTemplates['tags']['constraints'];
        template = temp.replace('<O_Os>', template);
        template = template.replace('<tag>', tags[1]);
      }
      return template;
    } else {
      return objectHtml;
    }
  },
  addProbability(tag, opl) {
    if (tag) {
      let template = oplTemplates['tags']['probability'];
      template = template.replace('<tag>', tag);
      template = template.replace('<O_Os>', opl);
      return template;
    }
    return opl;
  },
  addRate(tag, opl, rateUnits) {
    if (tag) {
      let template = oplTemplates['tags']['rate'];
      template = template.replace('<tag>', tag);
      template = template.replace('<O_Os>', opl);
      // if there is no rateUnits, replace the '<units>' tag and the space character before it with empty characters in order to prevent the space appearing for no reason
      template = (rateUnits && rateUnits !== '') ? template.replace('<units>', rateUnits) : template.replace(' <units>', '');
      return template;
    }
    return opl;
  },
  generateGroupOfStrings(array, template) {
    if (!array.length) {
      return '';
    }
    let temp = ``;
    if (array.length === 1) {
      return array[0];
    }
    for (let i = 0; i < array.length - 1; i++) {
      temp = temp.concat(array[i], `, `);
    }
    template = template.replace(`<T1...n-1>`, temp);
    template = template.replace(`<Tn>`, array[array.length - 1]);
    return template;


  },
  generateStructureMultiplicityText(tag) { // generate the text from a single part of the multiplicity structure
    if (oplTemplates['symbols'][tag]) {
      return oplTemplates['symbols'][tag];
    }
    const reg1 = /^([0-9]+)\.\.([0-9]+|\*)$/;
    const data = tag.match(reg1);
    if (!data) {
      return tag;
    }
    const numbers = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const low = data[1] < 10 ? numbers[data[1]] : data[1];
    const high = data[2] < 10 ? numbers[data[2]] : data[2];
    if (high === '*') {
      return oplTemplates['symbols']['n..*'].replace(`<n1>`, low);
    }
    let temp = oplTemplates['symbols']['n..n'];
    temp = temp.replace(`<n1>`, low);
    temp = temp.replace(`<n2>`, high);
    return temp;
  },
  extractTag(tag) {
    const const_exp = tag.split(/\s*;\s*/);
    const expressions = const_exp[0].split(/\s*,\s*/);
    const stringArray = [];

    for (let i = 0; i < expressions.length; i++) {
      stringArray.push(this.generateStructureMultiplicityText(expressions[i]));
    }
    const constraints = this.generateGroupOfStrings(const_exp.slice(1, const_exp.length), oplTemplates['grouping']['AND']);
    const ranges = this.generateGroupOfStrings(stringArray, oplTemplates['grouping']['OR']);

    return [ranges, constraints];
  },
  orderedTopDown(cells) {
    const res = cells.sort((cell1, cell2) => {
      if (cell1.attributes.position.y > cell2.attributes.position.y) {
        return 1;
      }

      if (cell1.attributes.position.y < cell2.attributes.position.y) {
        return -1;
      }

      return 0;
    });
    return res;
  },
  generateRequirementsOpl(ownerCell) {
    const graph = ownerCell.graph;
    const oplArray: Array<{ opl: string, cells: Array<any>}> = [];
    const owner = ownerCell.getVisual().logicalElement;
    const visExhibitionLink = ownerCell.getVisual().getLinks().outGoing.find(l =>
      l.type === linkType.Exhibition && l.target.logicalElement.getSatisfiedRequirementSetModule().isRequirementSetObject);
    const exhibitionLink = graph.getCell(visExhibitionLink?.id);
    if (!exhibitionLink)
      return oplArray;
    const requirements = owner.getSatisfiedRequirementSetModule().getRequirementsSet().getAllRequirements();
    for (const req of requirements) {
      const visRequirementObject = req.getRequirementObject();
      if (!visRequirementObject)
        continue;
      const requirementCell = graph.getCell(visRequirementObject.id);
      const setObjectCell = exhibitionLink.getTargetElement();
      const valueState = requirementCell.getStatesOnly()[0];
      let template = oplTemplates['hidden_attributes']['requirement'];
      template = template.replace('<set_object>', this.generateThingHtml(requirementCell));
      template = template.replace('<value>', this.generateCellHtml(valueState));
      template = template.replace('<owner>', this.generateThingHtml(ownerCell));
      const cells = [];
      cells.push(ownerCell, setObjectCell, requirementCell, exhibitionLink, valueState);
      oplArray.push({opl: template, cells: cells});
    }
    return oplArray;
  },
  generateFundamentalLinkOpl(link) {
    const linkType = link.attributes.name;
    const source = link.getSource();
    const obj = link.getTriangleChildren();
    const links = obj[1];
    let targets = obj[0];
    let preventReorder = false;
    if ((source.getVisual().logicalElement).orderedFundamentalTypes.includes((<any>link.getVisual()).type)) {
      preventReorder = true;
      targets = this.orderedTopDown(targets);
    }
    const cells = [source, ...targets, ...links];
    let template = '';
    const numbers = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    if (targets.length > 1) {
      const missingData = (<any>link.getVisual()).CheckAddLine();
      if (missingData.missingNumber > 0) {
        if (linkType === 'Exhibition-Characterization' && missingData.missingProcesses.length > 0 && missingData.missingObjectsAndStates.length === 0) {
          if (missingData.missingNumber === 1) {
            template = oplTemplates['structural_link'][linkType + '_incomplete_obj_proc_(one)'];
          } else {
            template = oplTemplates['structural_link'][linkType + '_incomplete_obj_proc_(multiple)'];
          }
        } else if (linkType === 'Exhibition-Characterization' && missingData.missingProcesses.length > 0 && missingData.missingObjectsAndStates.length > 0) {
          template = oplTemplates['structural_link']['Exhibition-Characterization_incomplete_both_(multiple)'];
        } else {
          if (missingData.missingNumber === 1) {
            template = oplTemplates['structural_link'][linkType + '_incomplete_(one)'];
          } else { template = oplTemplates['structural_link'][linkType + '_incomplete_(multiple)']; }
        }
        template = template.replace('<T2...n>', this.generateGroupOfObjectsAndProjects(targets, links, preventReorder));
        const num = template.includes('<num2>') ? missingData.missingObjectsAndStates.length : missingData.missingNumber;
        if (template.includes('<num2>')) {
          const operationText = missingData.missingProcesses.length > 1 ? oplTemplates['structural_link']['Exhibition-Operations'] : oplTemplates['structural_link']['Exhibition-Operation'];
          template = template.replace('<operation>', operationText);
        }
        const attributeText = missingData.missingObjectsAndStates.length > 1 ? oplTemplates['structural_link']['Exhibition-Attributes'] : oplTemplates['structural_link']['Exhibition-Attribute'];
        template = template.replace('<attribute>', attributeText);
        template = template.replace('<num>', num < 9 ? numbers[num] : num);
        template = template.replace('<num2>', missingData.missingNumber < 9 ? numbers[missingData.missingProcesses.length] : missingData.missingProcesses);
        // template = template.replace(' and', ',');
      } else {
        template = oplTemplates['structural_link'][linkType + '_(multiple)'];
        template = template.replace('<T2...n>', this.generateGroupOfObjectsAndProjects(targets, links, preventReorder));
      }
      if (linkType === 'Generalization-Specialization') {
        const unpluraled = this.generateThingHtml(source, links[0]);
        if (unpluraled.includes('</b>')) {
          let firstPart = unpluraled.substr(0, unpluraled.indexOf('</b>'));
          const secondPart = unpluraled.slice(unpluraled.indexOf('</b>'));
          if (!OPCloudUtils.isInstanceOfDrawnProcess(source)) {
            firstPart = oplFunctions.addPlural(firstPart);
          }
          template = template.replace('<T1>', firstPart + secondPart);
        } else {
          template = template.replace('<T1>', oplFunctions.addPlural(unpluraled));
        }
      }
      template = template.replace('<T1>', this.generateThingHtml(source, links[0]));
    } else {
      template = oplTemplates['structural_link'][linkType];
      if (oplDefaultSettings.language === 'en' && linkType === 'Generalization-Specialization' && source.attributes.type === 'opm.Process') {
        template = `<T2> is <T1>.`;
      }
      const missingData = (<any>link.getVisual()).CheckAddLine();
      if (missingData.missingNumber > 0) {
        if (linkType === 'Exhibition-Characterization' && missingData.missingProcesses.length > 0 && missingData.missingObjectsAndStates.length === 0) {
          if (missingData.missingNumber === 1) {
            template = oplTemplates['structural_link'][linkType + '_one_incomplete_obj_proc'];
          } else {
            template = oplTemplates['structural_link'][linkType + '_incomplete_obj_proc'];
          }
        } else if (linkType === 'Exhibition-Characterization' && missingData.missingProcesses.length > 0 && missingData.missingObjectsAndStates.length > 0) {
          template = oplTemplates['structural_link']['Exhibition-Characterization_incomplete_both'];
        } else {
          if ((<any>link.getVisual()).CheckAddLine().missingNumber === 1) {
            template = oplTemplates['structural_link'][linkType + '_one_incomplete'];
          } else {
            template = oplTemplates['structural_link'][linkType + '_incomplete'];
          }
        }
        if (linkType === 'Generalization-Specialization') {
          const unpluraled = this.generateThingHtml(source, links[0]);
          if (unpluraled.includes('</b>')) {
            let firstPart = unpluraled.substr(0, unpluraled.indexOf('</b>'));
            const secondPart = unpluraled.slice(unpluraled.indexOf('</b>'));
            if (!OPCloudUtils.isInstanceOfDrawnProcess(source)) {
              firstPart = oplFunctions.addPlural(firstPart);
            }
            template = template.replace('<T1>', firstPart + secondPart);
          } else {
            template = template.replace('<T1>', oplFunctions.addPlural(unpluraled));
          }
        }
        template = template.replace('<T1>', this.generateThingHtml(source, links[0]));
        template = template.replace('<T2>', this.generateGroupOfThings(targets, links));
        if (template.includes('<num2>')) {
          const operationText = missingData.missingProcesses.length > 1 ? oplTemplates['structural_link']['Exhibition-Operations'] : oplTemplates['structural_link']['Exhibition-Operation'];
          template = template.replace('<operation>', operationText);
        }
        const attributeText = missingData.missingObjectsAndStates.length > 1 ? oplTemplates['structural_link']['Exhibition-Attributes'] : oplTemplates['structural_link']['Exhibition-Attribute'];
        template = template.replace('<attribute>', attributeText);
        const num = template.includes('<num2>') ? missingData.missingObjectsAndStates.length : missingData.missingNumber;
        template = template.replace('<num>', num < 9 ? numbers[num] : num);
        template = template.replace('<num2>', missingData.missingNumber < 9 ? numbers[missingData.missingProcesses.length] : missingData.missingProcesses);
      }

      template = template.replace('<T2>', this.generateGroupOfThings(targets, links));
      template = template.replace('<T1>', this.generateThingHtml(source, links[0]));
    }
    if (preventReorder) { template = template.replace('.', ' in that sequence.'); }
    template = this.replaceContents(template, []);
    return { opl: template, cells: cells };
  },
  generateDirectionalLinkOpl(link) {
    const linkType = link.attributes.name;
    let T1 = link.getSourceElement();
    let T2 = link.getTargetElement();
    if (OPCloudUtils.isInstanceOfDrawnState(T2) && !link.constructor.name.includes('Uni')) {
      const temp = T1;
      T1 = T2;
      T2 = temp;
    }
    const tag1 = link.attributes.tag;
    const tag2 = link.attributes.backwardTag;
    let template = ``;
    if (!tag1 && !tag2) {
      template = oplTemplates['structural_link'][linkType];
      template = template.replace('<T1>', this.generateThingHtml(T1, 'AND', link));
      template = template.replace('<T2>', this.generateThingHtml(T2, 'AND', link));
    } else if (tag1 && !tag2) {
      if (link.isForkedLink() || (link.constructor.name.includes('Uni') && link.getVisual()?.getMissingChildrenNames(true).names)) {
        return this.generateForkedLinksOpl(link);
      }
      template = oplTemplates['structural_link'][linkType + '_(tag)'];
      template = template.replace('<T1>', this.generateThingHtml(T1, 'AND', link));
      template = template.replace('<T2>', this.generateThingHtml(T2, 'AND', link));
      template = template.replace('<tag>', tag1);
    } else if (tag1 && tag2) {
      template = oplTemplates['structural_link'][linkType + '_(ftag,btag)'];
      template = template.replace('<T1>', this.generateThingHtml(T1, 'AND', link));
      template = template.replace('<T2>', this.generateThingHtml(T2, 'AND', link));
      template = template.replace('<forward tag>', tag1);
      template = template.replace('<backward tag>', tag2);
      template = template.replace('<T1>', this.generateThingHtml(T1, 'AND', link));
      template = template.replace('<T2>', this.generateThingHtml(T2, 'AND', link));
    } else if (!tag1 && tag2) {
      template = oplTemplates['structural_link'][linkType + '_(tag)'];
      template = template.replace('<T2>', this.generateThingHtml(T1, 'AND', link));
      template = template.replace('<T1>', this.generateThingHtml(T2, 'AND', link));
      template = template.replace('<tag>', tag2);
    }
    return { opl: template, cells: [link, T1, T2] };
  },
  generateForkedLinksOpl(link) {
    const src = link.getSourceElement();
    let targets = link.getForkedLinks().map(l => l.getTargetElement()).filter(t => !!t);
    const tag = link.attributes.tag;
    const ordered = src.getVisual().logicalElement.orderedFundamentalTypes?.includes(linkType.Unidirectional);
    if (ordered) {
      targets = targets.sort((a,b) => a.get('position').x - b.get('position').x);
    }
    const missingTargets = link.getVisual().getMissingChildrenNames(true).names;
    let template;
    if (missingTargets.length === 0) {
      template = oplTemplates['structural_link']['Forked_Unidirectionals'];
    } else {
      template = oplTemplates['structural_link']['Forked_Unidirectionals_with_missing'];
    }
    template = template.replace('<T1>', this.generateThingHtml(src, 'AND', link));
    template = template.replace('<T2..n>', this.generateGroupOfThings(targets, link.getForkedLinks(), undefined, undefined, ordered));
    if (missingTargets.length > 0 && targets.length > 1) {
      template = template.replace(' and', ',');
    }
    template = template.replace('<tag>', tag);
    template = template.replace('<num>', this.getTextualNumber([missingTargets.length]) || missingTargets.length);
    if (ordered && targets.length > 1) {
     template = template.substr(0, template.length - 1) + ', ' +  oplTemplates['structural_link']['sequence'];
    }
    this.forbidden.push(...link.getForkedLinks());
    return { opl: template, cells: [link, src, ...targets] };
  },
  addToPairs(inOutLinkPairs, mainObject, link, linkType) {
    const mainObjectID = mainObject.attributes.id;
    const noPathKey = 'key for links without path';
    let key = noPathKey;
    if (link.attributes.Path) {
      key = link.attributes.Path;
    }
    /*else{
      if(link.paths.paths.length>0 && link.paths.paths[0].label){
        key = link.attributes.path;
      }
    }*/
    if (!inOutLinkPairs[mainObjectID]) {
      inOutLinkPairs[mainObjectID] = { mainObject: mainObject, path: {} };
      inOutLinkPairs[mainObjectID].path[key] = { Consumption: [], Consumption_Negation: [], Consumption_Condition: [], Consumption_Condition_Negation: [], Consumption_Event: [], Result: [] };
      inOutLinkPairs[mainObjectID].path[key][linkType].push(link);
    } else {
      if (!inOutLinkPairs[mainObjectID].path[key]) {
        inOutLinkPairs[mainObjectID].path[key] = { Consumption: [], Consumption_Negation: [], Consumption_Condition: [], Consumption_Condition_Negation: [], Consumption_Event: [], Result: [] };
        inOutLinkPairs[mainObjectID].path[key][linkType].push(link);
      } else {
        inOutLinkPairs[mainObjectID].path[key][linkType].push(link);
      }
    }
  },
  generateTypedProceduralLinkOPL(link, mainProcess, linkType, templates) { // condition event or exception.
    let template = ``;
    const cells = [];
    const target = mainProcess;
    const source = link.getSourceElement();
    if (source.attributes.type === 'opm.State') {
      let pairs = [];
      if (linkType.indexOf('exception') > -1) {
        template = oplTemplates['procedural_link'][linkType];
        const parent = source.getParent();
        pairs = [['<s>', this.generateCellHtml(source, link)], ['<O>', this.generateCellHtml(parent, link, true, true)],
          ['<P>', this.generateCellHtml(target, link, true)]];
        const obj = oplFunctions.getProcessStateTimeAttrs(source, source.constructor.name);
        if (obj) {
          pairs = pairs.concat([[`<units>`, obj.units], [`<mintime>`, obj.min], [`<maxtime>`, obj.max]]);
        }
      } else {
        template = oplTemplates['procedural_link'][linkType + '_state'];
        const parent = source.getParent();
        pairs = [['<s>', this.generateCellHtml(source, link, true)], ['<O>', this.generateCellHtml(parent, link, true, true)],
          ['<P>', this.generateCellHtml(target, link, true)]];
      }
      template = this.replaceContents(template, pairs);
    } else {
      let pairs = [];
      if (linkType.indexOf('exception') > -1) {
        template = oplTemplates['procedural_link'][linkType + '_(process)'];
        pairs = [['<P1>', this.generateCellHtml(source, link, true)], ['<P2>', this.generateCellHtml(target, link, true)]];
        const obj = oplFunctions.getProcessStateTimeAttrs(source, source.constructor.name);
        if (obj) {
          pairs = pairs.concat([[`<units>`, obj.units], [`<mintime>`, obj.min], [`<maxtime>`, obj.max]]);
        }
      } else {
        template = oplTemplates['procedural_link'][linkType];
        const process = source.get('type') === 'opm.Process' ? source : link.getTargetElement();
        const object = source.get('type') === 'opm.Process' ? link.getTargetElement() : source;
        pairs = [['<O>', this.generateCellHtml(object, link, true)], ['<P>', this.generateCellHtml(process, link, true)]]; // using getTargetElement because
        // the target variable defined as mainProcess and it is not always the case, could be an effect link from process to object.
      }
      template = this.replaceContents(template, pairs);
    }
    cells.push(link, source, target);
    templates.push({ opl: template, cells: cells });
    return templates;
  },
  generateGroupOfProceduralLinks(links, mainProcess, linkType) {
    if (links.length === 0) {
      return null;
    }
    let template = ``;
    let templates = [];
    let cells = [];
    const target = mainProcess;
    // let target_multi = links[0].attibutes.targetMultiplicity;
    if (linkType.indexOf('Condition') > -1 || linkType.indexOf('Event') > -1 || linkType.indexOf('exception') > -1) {
      if (links.length === 1) {
        templates = this.generateTypedProceduralLinkOPL(links[0], mainProcess, linkType, templates);
      } else {
        if (linkType.indexOf('Condition') > -1 || linkType.indexOf('Event') > -1) {
          const sources = [];
          for (const link of links) {
            sources.push(link.getSourceElement());
          }
          let pairs = [];
          template = oplTemplates['procedural_link'][linkType + '(multiple)'];
          pairs = [['<O\Os1...n>', this.generateGroupOfThings(sources, links)], ['<P>', this.generateCellHtml(target, links[0], true)]]; // effect link need to be changed
          template = this.replaceContents(template, pairs);
          cells.push(target);
          cells = cells.concat(sources);
          cells = cells.concat(links);
          templates.push({ opl: template, cells: cells });
        } else { // this is exception link - generating one one
          for (const link of links) {
            templates = this.generateTypedProceduralLinkOPL(link, mainProcess, linkType, templates);
          }
        }
      }

    } else if (linkType.indexOf('Invocation') > -1) {
      const targets = [];
      for (const link of links) {
        targets.push(link.getTargetElement());
      }
      cells.push(...links, ...targets, mainProcess); // mainProcesss is the source
      if (targets.length === 1) {
        template = oplTemplates['procedural_link'][linkType];
        const pairs = [['<P2>', this.generateGroupOfThings(targets, links)], ['<P1>', this.generateCellHtml(mainProcess, links[0], true)]];
        template = this.replaceContents(template, pairs);
      } else {
        template = oplTemplates['procedural_link'][linkType + '_(multiple)'];
        const pairs = [['<P2...n>', this.generateGroupOfThings(targets, links)], ['<P1>', this.generateCellHtml(mainProcess, links[0], true)]];
        template = this.replaceContents(template, pairs);
      }
      templates.push({ opl: template, cells: cells });
    } else {
      const sources = [];
      for (const link of links) {
        if (link.getSourceElement().attributes.id !== mainProcess.attributes.id) {
          sources.push(link.getSourceElement());
        } else {
          sources.push(link.getTargetElement());
        }
      }
      cells.push(...links, ...sources, target);
      if (sources.length === 1) {
        template = oplTemplates['procedural_link'][linkType];
        const pairs = [['<O_Os>', this.generateGroupOfThings(sources, links)], ['<P>', this.generateCellHtml(target, links[0], true)]];
        template = this.replaceContents(template, pairs);
        if (['Consumption', 'Result'].includes(linkType)) {
          template = this.fixInzoomedConsumtionResult(template, linkType, sources[0], target, links[0]);
        }
      } else {
        template = oplTemplates['procedural_link'][linkType + '_(multiple)'];
        const pairs = [['<O\Os1...n>', this.generateGroupOfThings(sources, links)], ['<P>', this.generateCellHtml(target, links[0], true)]];
        template = this.replaceContents(template, pairs);
      }
      templates.push({ opl: template, cells: cells });
    }
    return templates;
  },
  fixInzoomedConsumtionResult(template, type, source, target, link) {
    const model = getInitRappidShared().getOpmModel();
    const visualLink = model.getVisualElementById(link.id);
    if (!visualLink) {
      return template;
    }
    const src = visualLink.sourceVisualElement;
    const trgt = visualLink.targetVisualElements[0].targetVisualElement;
    if (src.fatherObject && trgt.fatherObject) {
      const shouldChange = model.links.isHavingInouts(src.fatherObject, trgt.fatherObject);
      if (type === 'Consumption' && shouldChange) {
        template = template.replace('consumes', 'changes');
        template = template.replace('at state', 'from state');
      } else if (type === 'Result' && shouldChange) {
        template = template.replace('yields', 'changes');
        template = template.replace('at state', 'to state');
      }
    }

    return template;
  },
  generateProceduralLinksOpl(process, options, arcLinks, arcsOpl) {
    const templates = [];
    const mainProcess = process;
    let allInLinks = options.graph.getConnectedLinks(process, { inbound: true }).filter(l => l.attr('./visible') !== 'none');
    let allOutLinks = options.graph.getConnectedLinks(process, { outbound: true }).filter(l => l.attr('./visible') !== 'none');
    allInLinks = oplFunctions.removeLinks(allInLinks, arcLinks, arcsOpl);
    allOutLinks = oplFunctions.removeLinks(allOutLinks, arcLinks, arcsOpl);
    const inOutLinkPairs = {};
    const proceduralLinks = {
      'Agent': [],
      'Agent_Negation': [],
      'Agent_Condition': [],
      'Agent_Condition_Negation': [],
      'Agent_Event': [],

      'Instrument': [],
      'Instrument_Negation': [],
      'Instrument_Condition': [],
      'Instrument_Condition_Negation': [],
      'Instrument_Event': [],

      'Effect': [],
      'Effect_Negation': [],
      'Effect_Condition': [],
      'Effect_Condition_Negation': [],
      'Effect_Event': [],

      'Consumption': [],
      'Consumption_Negation': [],
      'Consumption_Condition': [],
      'Consumption_Condition_Negation': [],
      'Consumption_Event': [],

      'Result': [],

      'Overtime_exception': [],
      'Undertime_exception': [],
      'OvertimeUndertime-exception': [],

      'Unidirectional_Tagged_Link': [],
      'Invocation': [],
      'Invocation_(self)': []
    };

    oplFunctions.DivideLinks(allInLinks, true, inOutLinkPairs, proceduralLinks); // direction=TRUE encode as in-links
    oplFunctions.DivideLinks(allOutLinks, false, inOutLinkPairs, proceduralLinks); // direction=FALSE encode as out-links

    // checking weather is input\output split links.
    this.generateInOutLinks(inOutLinkPairs, mainProcess, templates, proceduralLinks); // and also split input/output!!

    this.generateProceduralTemplates(proceduralLinks, mainProcess, templates);
    return templates;
  },
  generateInOutLinks(inOutLinkPairs, mainProcess, templates, proceduralLinks) {
    // inOutLinkPairs[mainObject].path[key] = {Consumption: [], Result: []};
    const template = ``;
    const allObjectsIDs = Object.keys(inOutLinkPairs);
    const isIn_Out_Link = false;
    for (const objectID of allObjectsIDs) {
      for (const path of Object.keys(inOutLinkPairs[objectID].path)) {
        const consumed = inOutLinkPairs[objectID].path[path].Consumption;
        const consumed_negation = inOutLinkPairs[objectID].path[path].Consumption_Negation;
        const consumed_c = inOutLinkPairs[objectID].path[path].Consumption_Condition;
        const consumed_c_negation = inOutLinkPairs[objectID].path[path].Consumption_Condition_Negation;
        const consumed_e = inOutLinkPairs[objectID].path[path].Consumption_Event;
        const resulted = inOutLinkPairs[objectID].path[path].Result;
        const consumed_check = (consumed.length === 0 && consumed_negation.length === 0 && consumed_c.length === 0 && consumed_c_negation.length === 0 && consumed_e.length === 0);
        if (consumed_check || resulted.length === 0) { // returning the result links - regular generalization
          proceduralLinks['Result'] = proceduralLinks['Result'].concat(resulted);
          proceduralLinks['Consumption'] = proceduralLinks['Consumption'].concat(consumed);
          proceduralLinks['Consumption_Negation'] = proceduralLinks['Consumption_Negation'].concat(consumed_negation);
          proceduralLinks['Consumption_Condition'] = proceduralLinks['Consumption_Condition'].concat(consumed_c);
          proceduralLinks['Consumption_Condition_Negation'] = proceduralLinks['Consumption_Condition_Negation'].concat(consumed_c_negation);
          proceduralLinks['Consumption_Event'] = proceduralLinks['Consumption_Event'].concat(consumed_e);
        } else {
          /*  const states = [];
          for (const link of resulted){
            states.push(link.getTargetElement());
          }
            const mainObject = inOutLinkPairs[objectID].mainObject;
            console.log(consumed);
          for (const link of consumed){
              /!*if (link.condition) {
              template = oplTemplates['procedural_link']['Condition_Input'];
              template_map = ['procedural_link','Condition_Input'];
              } else {*!/
              template = oplTemplates['procedural_link']['In-out_Link_Pair'];
              template_map = ['procedural_link','In-out_Link_Pair'];
              isIn_Out_Link = true;
            const pairs = [['<P>',this.generateCellHtml(mainProcess)],
              ['<O>',this.generateCellHtml(mainObject,isIn_Out_Link)],
              ['<s1>', this.generateCellHtml(link.getSourceElement())],
              ['<s2>',this.generateGroupOfStates(states)]];
            template = this.replaceContents(template_map,template,pairs);
              if(!(path === "key for links without path")){
                template = oplFunctions.AddPath(path, template);
              }
            const cells=[];
            cells.push(link.getSourceElement(), link, mainProcess, ...states, ...resulted);
            templates.push({opl:template, cells: cells});
            }*/
          if (consumed.length > 0) {
            this.generateGroupOfInOut(consumed, resulted, mainProcess, inOutLinkPairs[objectID].mainObject, templates, path);
          } else {
            proceduralLinks['Consumption'] = proceduralLinks['Consumption'].concat(consumed);
          }
          if (consumed_c.length > 0) {
            this.generateGroupOfInOut(consumed_c, resulted, mainProcess, inOutLinkPairs[objectID].mainObject, templates, path);
          } else {
            proceduralLinks['Consumption_Condition'] = proceduralLinks['Consumption_Condition'].concat(consumed_c);
          }
          if (consumed_e.length > 0) {
            this.generateGroupOfInOut(consumed_e, resulted, mainProcess, inOutLinkPairs[objectID].mainObject, templates, path);
          } else {
            proceduralLinks['Consumption_Event'] = proceduralLinks['Consumption_Event'].concat(consumed_e);
          }
        }
      }
    }
  },
  generateUnTypedInOut(pairs, consumed, objHtml, statesHtml) {
    let template = ``;
    template = oplTemplates['procedural_link']['In-out_Link_Pair(group)'];
    template = this.replaceContents(template, pairs);
    let all_changes = ``;
    for (let i = 1; i < consumed.length; i++) {
      if (i <= consumed.length - 2) {
        all_changes += this.comma() + ` `;
      }
      if (i === consumed.length - 1) {
        all_changes += ' and ';
      }
      let change = oplTemplates['procedural_link']['In-out(group)'];
      let otherStateHtml = this.generateCellHtml(consumed[i].getSourceElement());
      const objClass = consumed[i].getSourceElement().attributes.type.slice(4, ).toLowerCase();
      if (objClass !== 'state') {
        otherStateHtml = oplTemplates['procedural_link']['Split'];
      }
      pairs = [['<O>', objHtml],
        ['<s1>', otherStateHtml], // mytest
        ['<s2>', statesHtml]];
      change = this.replaceContents(change, pairs);
      all_changes += change;
    }
    template = template.replace(`<Other_changes>`, all_changes);
    return template;
  },
  generateGroupOfInOut(consumed, resulted, mainProcess, mainObject, templates, path) { // consumed can be array of Condition /+ condition /+ event
    let template = ``;
    const objHtml = this.generateCellHtml(mainObject, undefined, true); // mytest
    const processHtml = this.generateCellHtml(mainProcess); // mytest

    const states = [];
    for (const link of resulted) {
      states.push(link.getTargetElement());
    }
    const statesHtml = this.generateGroupOfStates(states);
    let otherStateHtml = this.generateCellHtml(consumed[0].getSourceElement());
    const objClass = consumed[0].getSourceElement().attributes.type.slice(4, ).toLowerCase();
    if (objClass !== 'state') {
      otherStateHtml = oplTemplates['procedural_link']['Split'];
    }

    const consumed_sources = [];
    for (const link of consumed) {
      consumed_sources.push(link.getSourceElement());
    }

    const cells = [];
    cells.push(...consumed_sources, ...consumed, mainProcess, ...states, ...resulted);
    let addition = '';
    if (consumed[0].condition) {
      addition = '_Condition';
    }
    if (consumed[0].event) {
      addition = '_Event';
    }
    const pairs = [['<P>', processHtml],
      ['<O>', objHtml],
      ['<s1>', otherStateHtml],
      ['<s2>', statesHtml]];
    if (consumed.length === 1) {
      template = oplTemplates['procedural_link']['In-out_Link_Pair' + addition];
      template = this.replaceContents(template, pairs);
    } else {
      if (!consumed[0].condition && !consumed[0].event) {
        template = this.generateUnTypedInOut(pairs, consumed, objHtml, statesHtml);
      }
      if (consumed[0].condition || consumed[0].event) {
        pairs[2] = ['<s1>', this.generateGroupOfStates(consumed_sources)];
        template = oplTemplates['procedural_link']['In-out_Link_Pair' + addition + '(group)'];
        template = this.replaceContents(template, pairs);
      }
    }



    if (!(path === 'key for links without path')) {
      template = oplFunctions.AddPath(path, template);
    }
    templates.push({ opl: template, cells: cells });

  },
  generateProceduralTemplates(proceduralLinks, mainProcess, templates) {
    const linkTypes = Object.keys(proceduralLinks);
    const noPathKey = 'key for links without path';
    for (const linkType of linkTypes) {
      if (proceduralLinks[linkType].length > 0) {
        const linksTags = oplFunctions.SplitByTags(proceduralLinks[linkType], noPathKey);
        for (const path of Object.keys(linksTags)) {
          const tem = this.generateGroupOfProceduralLinks(linksTags[path], mainProcess, linkType);
          if (!(path === noPathKey)) {
            tem[0].opl = oplFunctions.AddPath(path, tem[0].opl);
          }
          templates.push(...tem);
        }
      }
    }
  },
  generateLogicLinksOPL(arcGroups) {
    const oplArray = [];
    for (const position of Object.keys(arcGroups)) {
      for (const type of Object.keys(arcGroups[position])) {
        let arcType;
        if (position.startsWith('OR')) {
          arcType = 'OR';
        } else if (position.startsWith('NOT')) {
          arcType = 'NOT';
        } else {
          arcType = 'XOR';
        }
        const opl = this.generateArcOpl(arcGroups[position][type], arcType);
        if (opl) {
          oplArray.push({ 'opl': opl, 'cells': [...arcGroups[position][type], ...this.getCellsFromLinks(arcGroups[position][type])] });
        }
      }
    }
    return oplArray;
  },
  getCellsFromLinks(links) {
    const cells = new Set();
    for (const link of links) {
      cells.add(link.getSourceElement());
      cells.add(link.getTargetElement());
    }
    return Array.from(cells.values());
  },
  generateArcOpl(links, arcType) {
    links = links.filter(l => !oplGenerating.forbidden.includes(l.id));
    if (links.length < 2) {
      return;
    }
    const param = this.getSourceAndTargetOfArc(links);
    if (!param) {
      return undefined;
    }
    const singleType = param[0].constructor.name.slice(3).toLowerCase();
    const withProbability = arcType === 'XOR';

    if (singleType === 'process') {
      let template = oplTemplates.logic_operators[arcType][singleType][links[0].attributes.name];
      let splitType = 'OR';
      if (arcType === 'NOT') {
        splitType = 'AND';
      }
      if (links[0].attributes.name === 'Invocation') {
        if (links[0].targetArcOnLink && links[1].targetArcOnLink && links[0].targetArcOnLink.x === links[1].targetArcOnLink.x && links[0].targetArcOnLink.y === links[1].targetArcOnLink.y) {
          template = oplTemplates.logic_operators[arcType][singleType]['Invocation_IN'];
        } else {
          template = oplTemplates.logic_operators[arcType][singleType]['Invocation_OUT'];
        }
        template = template.replace(',', this.comma());
        const pairs = [[`<P>`, this.generateCellHtml(param[0], links[0], true, false)], [`<P1...n>`, this.generateGroupOfThings(param[1], links, splitType, withProbability)]];
        template = this.replaceContents(template, pairs);
        template = template.replace('.', this.period());
        return template;
      }

      if (arcType === 'XOR' && oplFunctions.sameFather(param[1])) { // couple of states with the same parent.
        template = oplTemplates.logic_operators[arcType][singleType][links[0].attributes.name + '(brothers)'];
        const pairs = [[`<P>`, this.generateCellHtml(param[0], links[0], true, false)],
          [`<O>`, this.generateCellHtml(param[1][0].getParent(), links[0], true, false)],
          [`<s1...n>`, this.generateGroupOfStates(param[1], 'OR', links, withProbability)]];
        if (!links[0]?.getVisual()?.source) {
          return '';
        }
        const process = links[0].getVisual().source.constructor.name.includes('Process') ? links[0].getVisual().source : links[0].getVisual().target;
        const isInout = param[1][0].getParent().getVisual().states.filter(st => st.getLinks().outGoing.find(l => l.type === linkType.Consumption && l.target === process));
        const isHavingInouts = getInitRappidShared().getOpmModel().links.isHavingVisibleInOuts(links[0].getVisual().source, links[0].getVisual().target);
        let flag = false;
        if (isInout && isInout.length > 0 && !links.find(lnk => !lnk.getSourceArcOnLink() || lnk.getVisual().type !== linkType.Result)) {
          pairs.push([`<O_Os2...n>`, this.generateGroupOfStates(param[1], 'OR', links, withProbability)]);
          const inStates = isInout.map(visState => links[0].graph.getCell(visState.id));
          flag = true;
          // used to prevent generating other opl for same links.
          const forbiddenLinks = inStates.map(st => st.getVisual().getLinks().outGoing.find(l => l.type === linkType.Consumption && l.target === process));
          for (const forbiddenLink of forbiddenLinks) {
            if (forbiddenLink && forbiddenLink.id) {
              this.forbidden.push(forbiddenLink.id);
            }
          }
          if (isInout.length > 1) {
            template = oplTemplates.logic_operators[arcType][singleType]['InOut_multi_Ins_Xor'];
            pairs.push([`<ins1..n>`, this.generateGroupOfStates(inStates)]);
          } else {
            template = oplTemplates.logic_operators[arcType][singleType]['InOut'];
            pairs.push([`<s1>`, this.generateCellHtml(links[0].graph.getCell(isInout[0].id), undefined, false, false)]);
          }
        }
        if (isHavingInouts.outs.length > 1 && flag === false && isHavingInouts.ins.length > 0) {
          return '';
        }
        template = template ? template : '';
        template = template.replace(',', this.comma());
        template = this.replaceContents(template, pairs);
        template = template.replace('.', this.period());
        return template;
      }
      template = template ? template.replace(',', this.comma()) : '';
      const pairs = [[`<P>`, this.generateCellHtml(param[0], links[0], true, false)], [`<O_Os1...n>`, this.generateGroupOfThings(param[1], links, 'AND', withProbability)]];
      template = this.replaceContents(template, pairs);
      template = template.replace('.', this.period());
      return template;
    }

    if (singleType === 'object') {
      let template = oplTemplates.logic_operators[arcType][singleType][links[0].attributes.name];
      template = template.replace(',', this.comma());
      const pairs = [[`<O_Os>`, this.generateCellHtml(param[0], links[0], true, false)], ['<P1...n>', this.generateGroupOfThings(param[1], links, 'AND', withProbability)]];
      template = this.replaceContents(template, pairs);
      template = template.replace('.', this.period());
      return template;
    }

    if (singleType === 'state') {
      let template = oplTemplates.logic_operators[arcType]['object'][links[0].attributes.name];
      if (links[0].attributes.name.indexOf('Condition') > -1 && oplTemplates.logic_operators[arcType]['object'][links[0].attributes.name + '_state']) {
        template = oplTemplates.logic_operators[arcType]['object'][links[0].attributes.name + '_state'];
        template = template.replace(',', this.comma());
        const pairs = [[`<O>`, this.generateThingHtml(param[0].getParent(), 'AND', links[0])], [`<s1>`, this.generateCellHtml(param[0], links, true, false)], [`<P1...n>`, this.generateGroupOfThings(param[1], links, 'OR', withProbability)]];
        template = this.replaceContents(template, pairs);
        template = template.replace('.', this.period());
        return template;
      }
      let splitType = 'OR';
      if (arcType === 'NOT') {
        splitType = 'AND';
      }
      template = template.replace(',', this.comma());
      const pairs = [[`<O_Os>`, this.generateThingHtml(param[0], 'AND', links[0])], [`<P1...n>`, this.generateGroupOfThings(param[1], links, splitType, withProbability)]];
      template = this.replaceContents(template, pairs);
      template = template.replace('.', this.period());
      return template;
    }

  },
  getSourceAndTargetOfArc(array) {// array length >=2
    let single;
    let multi = [];
    if (array[0].sourceArcOnLink && array[1].sourceArcOnLink && array[0].sourceArcOnLink.x === array[1].sourceArcOnLink.x && array[0].sourceArcOnLink.y === array[1].sourceArcOnLink.y) {
      single = array[0].getSourceElement();
      for (const link of array) {
        multi.push(link.getTargetElement());
      }
      multi = multi.filter(function (val) {
        return !!val;
      });
      if (!single || multi.length < 2) {
        return undefined;
      }
    } else {
      single = array[0].getTargetElement();
      for (const link of array) {
        multi.push(link.getSourceElement());
      }
      multi = multi.filter(function (val) {
        return !!val;
      });
      if (!single || multi.length < 2) {
        return undefined;
      }
    }
    return [single, multi];
  },
  generateInZoomInDiagramOpl(cell, options) {
    const mainThing = cell;
    const embededThings = mainThing.getInZoomedThings();
    const processList = embededThings[0];
    const objectList = embededThings[1];
    const flatProcessList = [].concat(...processList);
    const flatObjectList = [].concat(...objectList);
    const type = cell.attributes.type.slice(4).toLowerCase();
    const thingHtml = this.generateCellHtml(cell);
    const table = oplTemplates[type];
    let opl;
    if (processList.length === 0) {
      opl = table['singleInzoomInDiagram'];
    } else {
      opl = table['multiInzoomInDiagram'];
      opl = opl.replace(`<P_list>`, this.generateGroupOfThings(flatProcessList, undefined, 'AND', false, false));
    }
    const { sd_parent, current_sd } = this.getCurrentOpdName(cell, options);
    opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
    opl = opl.replace(`<Current_SD>`, current_sd);
    const ol = flatObjectList.length;
    const o12 = objectList.length;
    if (ol === 1) {
      opl = opl.replace(`<O_list>`, oplTemplates['grouping']['Single-Thing']);
      opl = opl.replace(`<T>`, this.generateCellHtml(flatObjectList[0]));
    } else {
      if (o12 === 1) {
        opl = opl.replace(`<O_list>`, table['object_list_parallel']);
        opl = opl.replace(`<O1...n>`, this.generateGroupOfThings(objectList[0], undefined, 'AND', false, true));
      } else {
        opl = opl.replace(`<O_list>`, table['object_list_sequence']);
        opl = opl.replace(`<O1...n>`, this.generateParallelSequenceHtml(objectList, type));
        this.generateParallelSequenceHtml(objectList, type);
      }
    }
    const cells = [mainThing, ...flatObjectList, ...flatProcessList];
    return { opl: this.replaceContents(opl, []), cells: cells };
  },
  generateFatherToSubModelOpl(options) {
    const ret = [];
    const currentOpd = options.opmModel.currentOpd;
    for (const subviewOpd of currentOpd.children.filter(child => child.sharedOpdWithSubModelId)) {
      let opl = oplTemplates['father_model_to_sub_model'];
      const things = currentOpd.visualElements.filter(v => OPCloudUtils.isInstanceOfLogicalThing(v.logicalElement)
        && v.logicalElement.protectedFromBeingRefinedBySubModel === subviewOpd.sharedOpdWithSubModelId);
      const objects = things.filter(th => OPCloudUtils.isInstanceOfVisualObject(th));
      const processes = things.filter(th => OPCloudUtils.isInstanceOfVisualProcess(th));
      const objectsCells = objects.map(v => options.graph.getCell(v.id)).filter(c => !!c);
      const processesCells = processes.map(v => options.graph.getCell(v.id)).filter(c => !!c);
      opl = opl.replace(`<o1...n>`, this.generateGroupOfThings(objectsCells, undefined, 'AND'));
      opl = opl.replace(`<p1...n>`, this.generateGroupOfThings(processesCells, undefined, 'AND'));
      opl = opl.replace(`<subsystem_name>`, subviewOpd.name);
      ret.push({ cells: [...objectsCells, ...processesCells], opl: opl });
    }
    return ret;
  },
  generatesSubModelFromFatherOpl(options) {
    const currentOpd = options.opmModel.currentOpd;
    if (currentOpd.id === 'SD') {
      let opl = oplTemplates['sub_model_from_father_model'];
      const fatherModelName = this.options.opmModel.fatherModelName;
      opl = opl.replace(`<father_model_name>`, fatherModelName);
      const subSystemName = currentOpd.visualElements.find(ob => ob.getLinks().outGoing.find(l => l.type == linkType.Exhibition) &&
        ob.getLinks().outGoing.find(l => l.type == linkType.Instrument))?.logicalElement.text || options.modelService.displayName;
      opl = opl.replace(`<subsystem_name>`, subSystemName);
      return [{ opl, cells:[] }];
    }
    return [];
  },
  generateInZoomOpl(cell, options) {
    const mainThing = cell;
    const embededThings = mainThing.getInZoomedThings();
    const processList = embededThings[0];
    const objectList = embededThings[1];
    const flatProcessList = [].concat(...processList);
    const flatObjectList = [].concat(...objectList);
    const pl = flatProcessList.length;
    const ol = flatObjectList.length;
    const pl2 = processList.length;
    const o12 = objectList.length;
    const type = cell.attributes.type.slice(4).toLowerCase();
    const thingHtml = this.generateCellHtml(cell);
    // added dummy name to allow tests to run.
    let node_parent = { data: { name: 'ParentSD', subTitle: '' } };
    let current_node = { data: { name: 'CurrentSD', subTitle: '' } };
    if (options.treeViewService) {
      const parentId = options.opmModel.getOpdByThingId(cell.attributes.id).parendId;
      const currentNodeId = options.opmModel.getOpdByThingId(cell.attributes.id).id;
      node_parent = options.treeViewService.treeView?.treeModel.getNodeById(parentId);
      if (!node_parent) {
        node_parent = { data: { name: options.opmModel.getOpd(parentId)?.getNumberedName() || '', subTitle: '' } };
      }
      current_node = options.treeViewService.treeView?.treeModel.getNodeById(currentNodeId);
      if (!current_node) {
        current_node = { data: { name: options.opmModel.getOpd(currentNodeId)?.getNumberedName() || '', subTitle: '' } };
      }
    }
    const sd_parent = `<data lid="${options.opmModel.getOpdByThingId(cell.attributes.id).parendId}">${(<any>node_parent).data.name + (<any>node_parent).data.subTitle}</data>`;
    const current_sd = `<data lid="${options.opmModel.getOpdByThingId(cell.attributes.id).id}">${(<any>current_node).data.name + (<any>current_node).data.subTitle}</data>`;
    const currentOpd = options.opmModel.currentOpd;
    const refineeOpd = options.opmModel.getOpdByThingId(cell.getVisual().getRefineeInzoom()?.id);
    if (cell.getVisual().getRefineable() === cell.getVisual().getRefineeInzoom() && refineeOpd === currentOpd && flatObjectList.length > 0) {
      return this.generateInZoomInDiagramOpl(cell, options);
    }
    let opl = ``;
    const cells = [];
    cells.push(mainThing, ...flatProcessList, ...flatObjectList);
    const table = oplTemplates[type];
    /*if (type === 'object'){
      if (pl >0 && ol >0){
        opl = table['multiInzoom'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
        opl = opl.replace(`<O_list>`, this.generateGroupOfThings(objectList));
        opl = opl.replace(`<P_list>`, this.generateGroupOfThings(flatProcessList));
      }else if (pl ===0 && ol >0){
        opl = table['singleInzoom'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
        opl = opl.replace(`<T_list>`, this.generateGroupOfThings(objectList));
      }else if (pl>0 && ol ===0){
        opl = table['singleInzoom'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
        opl = opl.replace(`<T_list>`, this.generateGroupOfThings(flatProcessList));
      }else{
        opl = ``;
      }
    }*/
    if (type === 'object') {
      if (pl > 0 && ol > 0) {
        opl = table['multiInzoom'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
        opl = opl.replace(`<P_list>`, this.generateGroupOfThings(flatProcessList));
        opl = opl.replace('<SD_Parent>', sd_parent);
        opl = opl.replace('<Current_SD>', current_sd);


        if (ol === 1) {
          opl = opl.replace(`<O_list>`, oplTemplates['grouping']['Single-Thing']);
          opl = opl.replace(`<T>`, this.generateCellHtml(flatObjectList[0]));
        } else {
          if (o12 === 1) {
            opl = opl.replace(`<O_list>`, table['object_list_parallel']);
            opl = opl.replace(`<O1...n>`, this.generateGroupOfThings(objectList[0]));
          } else {
            opl = opl.replace(`<O_list>`, table['object_list_sequence']);
            opl = opl.replace(`<O1...n>`, this.generateParallelSequenceHtml(objectList, type));
          }
        }
      } else if (ol === 0 && pl > 0) {
        opl = table['singleInzoom'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
        opl = opl.replace(`<T_list>`, this.generateGroupOfThings(flatProcessList));
        opl = opl.replace('<SD_Parent>', sd_parent);
        opl = opl.replace('<Current_SD>', current_sd);
      } else if (ol > 0 && pl === 0) {
        opl = table['singleInzoom'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
        opl = opl.replace('<SD_Parent>', sd_parent);
        opl = opl.replace('<Current_SD>', current_sd);
        if (ol === 1) {
          opl = opl.replace(`<T_list>`, oplTemplates['grouping']['Single-Thing']);
          opl = opl.replace(`<T>`, this.generateCellHtml(flatObjectList[0]));
        } else {
          if (o12 === 1) {
            opl = opl.replace(`<T_list>`, table['object_list_parallel']);
            opl = opl.replace(`<O1...n>`, this.generateGroupOfThings(objectList[0], undefined, 'AND', false, true));
          } else {
            opl = opl.replace(`<T_list>`, table['object_list_sequence']);
            opl = opl.replace(`<O1...n>`, this.generateParallelSequenceHtml(objectList, type));
            this.generateParallelSequenceHtml(objectList, type);
          }
        }
      } else {
        opl = ``;
      }
    } else if (type === 'process') {
      if (pl > 0 && ol > 0) {
        opl = (pl === 1 || (pl > 0 && pl2 === 1) ) ? table['multiInzoomOneProcess'] : table['multiInzoom'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
        opl = opl.replace(`<O_list>`, this.generateGroupOfThings(flatObjectList));
        opl = opl.replace('<SD_Parent>', sd_parent);
        opl = opl.replace('<Current_SD>', current_sd);

        if (pl === 1) {
          opl = opl.replace(`<P_list>`, oplTemplates['grouping']['Single-Thing']);
          opl = opl.replace(`<T>`, this.generateCellHtml(flatProcessList[0]));
        } else {
          if (pl2 === 1) {
            opl = opl.replace(`<P_list>`, table['process_list_parallel']);
            opl = opl.replace(`<P1...n>`, this.generateGroupOfThings(processList[0]));
          } else {
            opl = opl.replace(`<P_list>`, table['process_list_sequence']);
            opl = opl.replace(`<P1...n>`, this.generateParallelSequenceHtml(processList, type));
          }
        }
      } else if (pl === 0 && ol > 0) {
        opl = table['singleInzoom'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
        opl = opl.replace(`<T_list>`, this.generateGroupOfThings(flatObjectList));
        opl = opl.replace('<SD_Parent>', sd_parent);
        opl = opl.replace('<Current_SD>', current_sd);
      } else if (pl > 0 && ol === 0) {
        opl = pl2 === 1 ? table['singleInzoom_parallel'] : table['singleInzoom'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
        opl = opl.replace('<SD_Parent>', sd_parent);
        opl = opl.replace('<Current_SD>', current_sd);
        if (pl === 1) {
          opl = opl.replace(`<T_list>`, oplTemplates['grouping']['Single-Thing']);
          opl = opl.replace(`<T>`, this.generateCellHtml(flatProcessList[0]));
        } else {
          if (pl2 === 1) {
            opl = opl.replace(`<T_list>`, table['process_list_parallel']);
            opl = opl.replace(`<P1...n>`, this.generateGroupOfThings(processList[0]));
          } else {
            opl = opl.replace(`<T_list>`, table['process_list_sequence']);
            opl = opl.replace(`<P1...n>`, this.generateParallelSequenceHtml(processList, type));
          }
        }
      } else {
        opl = ``;
      }
    }
    return { opl: this.replaceContents(opl, []), cells: cells };
  },
  getCurrentOpdName(cell, options) {
    let node_parent = { data: { name: 'ParentSD', subTitle: '' } };
    let current_node = { data: { name: 'CurrentSD', subTitle: '' } };
    if (options.treeViewService) {
      const parentId = options.opmModel.getOpdByThingId(cell.attributes.id).parendId;
      const currentNodeId = options.opmModel.getOpdByThingId(cell.attributes.id).id;
      node_parent = options.treeViewService.treeView?.treeModel.getNodeById(parentId);
      if (!node_parent) {
        node_parent = { data: { name: options.opmModel.getOpd(parentId)?.getNumberedName() || '', subTitle: '' } };
      }
      current_node = options.treeViewService.treeView?.treeModel.getNodeById(currentNodeId);
      if (!current_node) {
        current_node = { data: { name: options.opmModel.getOpd(currentNodeId)?.getNumberedName() || '', subTitle: '' } };
      }
    }
    const sd_parent = `<data lid="${options.opmModel.getOpdByThingId(cell.attributes.id).parendId}">${(<any>node_parent).data.name + (<any>node_parent).data.subTitle}</data>`;
    const current_sd = `<data lid="${options.opmModel.getOpdByThingId(cell.attributes.id).id}">${(<any>current_node).data.name + (<any>current_node).data.subTitle}</data>`;

    return { sd_parent, current_sd };
  },
  generateUnfoldOpl(cell, options) {
    const ret = [];
    const unfoldData = cell.getUnfoldedThings();
    const { sd_parent, current_sd } = this.getCurrentOpdName(cell, options);
    if (unfoldData.aggregation.objectsAndStates.length || unfoldData.aggregation.processes.length) {
      ret.push(this.generateUnfoldAggregationOpl(cell, unfoldData.aggregation, sd_parent, current_sd, options));
    }
    if (unfoldData.exhibition.objectsAndStates.length || unfoldData.exhibition.processes.length) {
      ret.push(this.generateUnfoldExhibitionOpl(cell, unfoldData.exhibition, sd_parent, current_sd, options));
    }
    if (unfoldData.generalization.objectsAndStates.length || unfoldData.generalization.processes.length) {
      ret.push(this.generateUnfoldGeneralizationOpl(cell, unfoldData.generalization, sd_parent, current_sd, options));
    }
    if (unfoldData.instantiation.objectsAndStates.length || unfoldData.instantiation.processes.length) {
      ret.push(this.generateUnfoldInstantiationOpl(cell, unfoldData.instantiation, sd_parent, current_sd, options));
    }
    const all = [
      ...Object.keys(unfoldData).map(key => unfoldData[key].processes),
      ...Object.keys(unfoldData).map(key => unfoldData[key].objectsAndStates)
    ];
    if (!all.some(arr => arr.length)) {
      ret.push(this.generateUnspecifiedUnfoldOpl(cell, sd_parent, current_sd, options));
    }
    return ret;
  },
  generateUnfoldAggregationOpl(cell, data, sd_parent, current_sd, options) {
    const type = cell.attributes.type.slice(4).toLowerCase();
    const table = oplTemplates[type];
    const thingHtml = this.generateCellHtml(cell);
    let opl;
    opl = table['single_unfold_aggregation'];
    opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
    opl = opl.replace('<SD_Parent>', sd_parent);
    opl = opl.replace('<Current_SD>', current_sd);
    const targets = [...data.processes.map(v => options.graph.getCell(v.id)), ...data.objectsAndStates.map(v => options.graph.getCell(v.id))].filter(c => c);
    opl = opl.replace(`<T_list>`, this.generateGroupOfThings(targets, undefined, 'AND', false, false, true));
    const linksCells = cell.getVisual().getLinks().outGoing.filter(l => l.type === linkType.Aggregation).map(v => options.graph.getCell(v?.id)).filter(c => c);
    return { opl, cells: [cell, ...targets, ...linksCells] };
  },
  generateUnfoldExhibitionOpl(cell, data, sd_parent, current_sd, options) {
    const type = cell.attributes.type.slice(4).toLowerCase();
    const table = oplTemplates[type];
    const thingHtml = this.generateCellHtml(cell);
    let opl;
    let targets = [];
    if (data.processes.length > 0 && data.objectsAndStates.length > 0) {
      opl = table['multi_unfold_exhibition'];
      opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
      opl = opl.replace('<SD_Parent>', sd_parent);
      opl = opl.replace('<Current_SD>', current_sd);
      const processesCells = data.processes.map(v => options.graph.getCell(v.id)).filter(c => c);
      const objectsAndStatesCells = data.objectsAndStates.map(v => options.graph.getCell(v.id)).filter(c => c);
      targets = [...processesCells, ...objectsAndStatesCells];
      opl = opl.replace(`<P_list>`, this.generateGroupOfThings(processesCells, undefined, 'AND', false, false, true));
      opl = opl.replace(`<O_list>`, this.generateGroupOfThings(objectsAndStatesCells, undefined, 'AND', false, false, true));
    } else {
      opl = table['single_unfold_exhibition'];
      opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
      opl = opl.replace('<SD_Parent>', sd_parent);
      opl = opl.replace('<Current_SD>', current_sd);
      targets = [...data.processes.map(v => options.graph.getCell(v.id)), ...data.objectsAndStates.map(v => options.graph.getCell(v.id))].filter(c => c);
      opl = opl.replace(`<T_list>`, this.generateGroupOfThings(targets, undefined, 'AND', false, false, true));
    }
    const linksCells = cell.getVisual().getLinks().outGoing.filter(l => l.type === linkType.Exhibition).map(v => options.graph.getCell(v?.id)).filter(c => c);
    return { opl, cells: [cell, ...targets, ...linksCells] };
  },
  generateUnfoldGeneralizationOpl(cell, data, sd_parent, current_sd, options) {
    const type = cell.attributes.type.slice(4).toLowerCase();
    const table = oplTemplates[type];
    const opl = table['single_unfold_generalization'];
    return this.generateUnfoldSingleOpl(cell, data, sd_parent, current_sd, options, opl, type, linkType.Generalization);
  },
  generateUnfoldInstantiationOpl(cell, data, sd_parent, current_sd, options) {
    const type = cell.attributes.type.slice(4).toLowerCase();
    const table = oplTemplates[type];
    const opl = table['single_unfold_instantiation'];
    return this.generateUnfoldSingleOpl(cell, data, sd_parent, current_sd, options, opl, type, linkType.Instantiation);
  },
  generateUnspecifiedUnfoldOpl(cell, sd_parent, current_sd, options) {
    const thingHtml = this.generateCellHtml(cell);
    const type = cell.attributes.type.slice(4).toLowerCase();
    const table = oplTemplates[type];
    let opl = table['unspecified_unfold'];
    opl = opl.replace(`<T>`, thingHtml);
    opl = opl.replace('<SD_Parent>', sd_parent);
    opl = opl.replace('<Current_SD>', current_sd);
    return { opl, cells: [cell] };
  },
  generateUnfoldSingleOpl(cell, data, sd_parent, current_sd, options, opl, type, linksType) {
    const thingHtml = this.generateCellHtml(cell);
    opl = opl.replace(`<${type[0].toUpperCase()}>`, thingHtml);
    opl = opl.replace('<SD_Parent>', sd_parent);
    opl = opl.replace('<Current_SD>', current_sd);
    const targets = [...data.processes.map(v => options.graph.getCell(v.id)), ...data.objectsAndStates.map(v => options.graph.getCell(v.id))].filter(c => c);
    opl = opl.replace(`<T_list>`, this.generateGroupOfThings(targets, undefined, 'AND', false, false, true));
    const linksCells = cell.getVisual().getLinks().outGoing.filter(l => l.type === linksType).map(v => options.graph.getCell(v?.id)).filter(c => c);
    return { opl, cells: [cell, ...targets, ...linksCells] };
  },
  generateParallelSequenceHtml(List, type) {
    const HtmlList = [];
    for (const thing of List) {
      if (thing.length === 1) {
        HtmlList.push(this.generateCellHtml(thing[0]));
      } else {
        let t = oplTemplates[type][type + '_list_parallel'];
        const searchval = `<` + type[0].toUpperCase() + '1...n>';
        t = t.replace(searchval, this.generateGroupOfThings(thing, undefined, 'AND', false, true));
        HtmlList.push(t);
      }
    }
    let firstPart = ``;
    let i = 0;
    for (i; i < List.length - 1; i++) {
      firstPart = firstPart + HtmlList[i] + this.comma() + ` `;
    }
    const lastPart = HtmlList[i];
    let html = oplTemplates.grouping['Multiple-Things'];
    html = html.replace(`<T1...n-1>`, firstPart);
    html = html.replace(`<Tn>`, lastPart);
    return html;
  },
  essenceName(essence: Essence) {
    if (essence === Essence.Informatical) {
      return oplTemplates.essence.informatical;
    } else {
      return oplTemplates.essence.physical;
    }
  },
  affiliationName(affiliation) {
    if (affiliation === Affiliation.Environmental) {
      return oplTemplates.affiliation.environmental;
    } else {
      return oplTemplates.affiliation.systemic;
    }
  },
  generatDigitalTwinOpl(cell) {
    if (cell.attr('digitalTwinConnected')) {
      const digitalTwinId = cell.attr('digitalTwin');
      const twinCell = cell.graph.getCell(digitalTwinId);
      let opl = ``;
      const type = cell.attributes.type.slice(4).toLowerCase();
      const table = oplTemplates[type];
      opl = table['digital_twin'];
      opl = opl.replace(`<O>`, this.generateCellHtml(cell));
      opl = opl.replace(`<TWIN>`, this.generateCellHtml(twinCell, undefined, false));
      opl = opl.replace(`.`, this.period());
      return { opl: opl, cells: [cell, twinCell] };
    }

  },
  generateThingOPL(cell) { // all essence/affiliation are Enum
    const essence = cell.getEssence();
    const affiliation = cell.getAffiliation();
    const defaultEssence = oplDefaultSettings.essence;
    const defaultAffiliation = oplDefaultSettings.affiliation;
    const name = cell.attributes.attrs.text.textWrap.text;
    const type = cell.attributes.type.slice(4).toLowerCase();
    const thingHtml = this.generateCellHtml(cell);
    const table = oplTemplates[type];
    let opl = ``;
    if (cell.getVisual()?.logicalElement.isSatisfiedRequirementSetObject())
      return {opl: opl, cells: [cell]};
    if (cell.getVisual()?.logicalElement.isSatisfiedRequirementObject())
      return {opl: opl, cells: [cell]};
    const opt = getInitRappidShared().oplService.userOplSettings.displayOpt;
    if (opt === 'Don\'t show essence OPL for All Things') {
      return {opl: opl, cells: [cell]};
    } else if (opt === 'Show essence OPL for all Things') {
      opl = table['non_default'];
      opl = opl.replace(`<${type[0].toUpperCase()}>`, this.generateCellHtml(cell));
      opl = opl.replace(`<a>`, this.affiliationName(affiliation));
      opl = opl.replace(`<e>`, this.essenceName(essence));
      opl = opl.replace(`.`, ' ' + table['thing_generic_name'] + this.period());
      return {opl: opl, cells: [cell]};
    } else if (essence !== defaultEssence) {
      if (affiliation === defaultAffiliation) {
        opl = table['default_affiliation'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, this.generateCellHtml(cell));
        opl = opl.replace(`<e>`, this.essenceName(essence));
        opl = opl.replace(`.`, ' ' + table['thing_generic_name'] + this.period());
      } else {
        opl = table['non_default'];
        opl = opl.replace(`<${type[0].toUpperCase()}>`, this.generateCellHtml(cell));
        opl = opl.replace(`<a>`, this.affiliationName(affiliation));
        opl = opl.replace(`<e>`, this.essenceName(essence));
        opl = opl.replace(`.`, ' ' + table['thing_generic_name'] + this.period());
      }
    }
    return {opl: opl, cells: [cell]};
  },
  generateStateOPL(cell) { // cell is OpmState
    const parent = cell.getParent(); // parent is the object who contains the states
    if (!parent) { return; }
    let opl = ``;
    const cells = [];
    const logicalStates = parent.getVisual().logicalElement.states_;
    if (parent.getStatesOnly()) {
      const states = parent.getStatesOnly().filter(state => {
        return state.constructor.name === 'OpmState';
      });
      cells.push(...states);
      const len = states.length;
      const indent = oplTemplates['grouping']['indentation'];
      const table = oplTemplates['state'];
      if (len === 1 && logicalStates.length === len) {
        const pairs = [['<O>', this.generateCellHtml(parent)], ['<s>', this.generateGroupOfStates(states)]];
        opl = this.replaceContents(table['single_state'], pairs);

      }
      if (len > 1 && logicalStates.length === len) {
        const pairs = [['<O>', this.generateCellHtml(parent)], ['<s1...n>', this.generateGroupOfStates(states)]];
        opl = this.replaceContents(table['multiple_states'], pairs);

      }
      if (len === 0 && logicalStates.length > 0 ) {
        const pairs = [['<O>', this.generateCellHtml(parent)]];
        opl = this.replaceContents(table['all_states_are_suppressed'], pairs);
      }
      if (len > 0 && logicalStates.length !== len ) {
        const oneMissing = logicalStates.length - len === 1 ? '_one_missing' : '';
        if (len === 1) {
          const pairs = [
            ['<O>', this.generateCellHtml(parent)],
            ['<s>', this.generateGroupOfStates(states)],
            ['<num>', this.getTextualNumber(logicalStates.length - len)]
          ];
          opl = this.replaceContents(table['one_state_shown' + oneMissing], pairs);
        } else {
          const pairs = [
            ['<O>', this.generateCellHtml(parent)],
            ['<s1...n>', this.generateGroupOfStates(states)],
            ['<num>', this.getTextualNumber(logicalStates.length - len)]
          ];
          opl = this.replaceContents(table['two_or_more_states_shown' + oneMissing], pairs);
          opl = opl.replace(' or', '<b class="bolderComma">,</b>');
        }
      }
      for (const state of states) {
        const type = state.checkType();
        if (type !== 'none') {
          const stateOpl = table[state.getTypeForOpl()];
          const pairs = [['<s>', this.generateCellHtml(state)]];
          pairs.push(['<O>', this.generateCellHtml(state.getParentCell())]);
          if (type.includes('Current')) {
            opl = opl + '<br>' + indent + this.replaceContents(table['Current'], pairs);
          }
          opl = opl + (stateOpl === '' ? '' : '<br>') + indent + this.replaceContents(stateOpl, pairs);
        }
        const init = getInitRappidShared();
        if (state.isTimeDuration()) {
          const timeDParams = oplFunctions.getProcessStateTimeAttrs(state, state.constructor.name);
          if (!timeDParams) {
            return { opl: '', cells: [] };
          }
          while (timeDParams.template.indexOf(`<units>`) !== -1) {
            timeDParams.template = timeDParams.template.replace(`<units>`, timeDParams.units);
          }
          timeDParams.template = timeDParams.template.replace(`<exp>`, timeDParams.expected);
          timeDParams.template = timeDParams.template.replace(`<min>`, timeDParams.min);
          timeDParams.template = timeDParams.template.replace(`<max>`, timeDParams.max);
          // timeDParams.template = timeDParams.template.replace(`<P>`, this.generateCellHtml(process, undefined, true, false, false, undefined));
          const pairs = [['<s>', this.generateCellHtml(state)]];
          opl = opl + '<br>' + indent + this.replaceContents(timeDParams.template, pairs);
          // return { opl: timeDParams.template, cells: [state] };

        }
      }
    }
    return { opl: opl, cells: cells };
  },
  getTextualNumber(num: number): string {
    const numbers = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    if (num < 10) {
      return numbers[num];
    }
    return String(num);
  },
  generateProcessTime(process) {
    const obj = oplFunctions.getProcessStateTimeAttrs(process, process.constructor.name);
    if (!obj) {
      return { opl: '', cells: [] };
    }
    while (obj.template.indexOf(`<units>`) !== -1) {
      obj.template = obj.template.replace(`<units>`, obj.units);
    }
    obj.template = obj.template.replace(`<exp>`, obj.expected);
    obj.template = obj.template.replace(`<min>`, obj.min);
    obj.template = obj.template.replace(`<max>`, obj.max);

    obj.template = obj.template.replace(`<P>`, this.generateCellHtml(process, undefined, true, false, false, undefined));
    return { opl: obj.template, cells: [process] };
  },
  generateSemiFoldingOPL(cell) {
    let opl = '';
    const folded = [];
    const cells = [cell];
    const cellType = cell.get('type').includes('Object') ? 'object' : 'process';
    const relationsCounter = { 11: 0, 12: 0, 13: 0, 14: 0 };
    let numberOfRels = 0;
    cell.getVisual().semiFolded.forEach(vis => {
      const relationType = vis.isFoldedUnderThing().triangleType;
      folded.push({ thing: vis, relationType: relationType });
      if (relationsCounter[relationType] === 0) {
        numberOfRels += 1;
      }
      relationsCounter[relationType] = relationsCounter[relationType] + 1;
    });

    const pair = [['<' + cellType[0].toUpperCase() + '>', this.generateCellHtml(cell)]];
    opl += this.replaceContents(oplTemplates['semifolding'][cellType], pair);

    if (relationsCounter[linkType.Aggregation] > 0) {
      const relevantCells = cell.getVisual().semiFolded.filter(v => v.isFoldedUnderThing().triangleType === linkType.Aggregation)
        .map(vis => cell.graph.getCell(vis.id));
      let template = relevantCells.length === 1 ? oplTemplates['semifolding']['aggregation']['single'] : oplTemplates['semifolding']['aggregation']['multiple'];
      template = template.replace('<T>', this.generateSemifoldedThingCellHtml(relevantCells[0]));
      template = template.replace('<Tn>', this.generateSemifoldedThingCellHtml(relevantCells[relevantCells.length - 1]));
      let str = '';
      for (let i = 0; i < relevantCells.length - 1; i++) {
        let comma = (relevantCells.length === 2 && i === relevantCells.length - 2) ? '' : ', ';
        if (relevantCells.length !== 2 && i === relevantCells.length - 2) {
          comma = '';
        }
        str += this.generateSemifoldedThingCellHtml(relevantCells[i]) + comma;
      }
      template = template.replace('<T1...n-1>', str);
      opl += template;
      // const hasAfter = relationsCounter[12] > 0 || relationsCounter[13] > 0 || relationsCounter[14] > 0;
      // opl += hasAfter ? ', ' : '';
      cells.push(...relevantCells);
    }
    if (relationsCounter[linkType.Exhibition] > 0) {
      const relevantCells = cell.getVisual().semiFolded.filter(v => v.isFoldedUnderThing().triangleType === linkType.Exhibition)
        .map(vis => cell.graph.getCell(vis.id));
      let template = relevantCells.length === 1 ? oplTemplates['semifolding']['exhibition']['single'] : oplTemplates['semifolding']['exhibition']['multiple'];
      template = template.replace('<T>', this.generateSemifoldedThingCellHtml(relevantCells[0]));
      template = template.replace('<Tn>', this.generateSemifoldedThingCellHtml(relevantCells[relevantCells.length - 1]));
      let str = '';
      for (let i = 0; i < relevantCells.length - 1; i++) {
        let comma = (relevantCells.length === 2 && i === relevantCells.length - 2) ? '' : ', ';
        if (relevantCells.length !== 2 && i === relevantCells.length - 2) {
          comma = '';
        }
        str += this.generateSemifoldedThingCellHtml(relevantCells[i]) + comma;
      }
      template = template.replace('<T1...n-1>', str);
      cells.push(...relevantCells);
      const hasBefore = relationsCounter[11] > 0;
      const hasAfter = relationsCounter[13] > 0 || relationsCounter[14] > 0;
      if (hasBefore && !hasAfter) {
        opl.slice(opl.lastIndexOf(', '));
        opl += ', and ';
      } else if (hasBefore && hasAfter) {
        opl += ', ';
 }
      opl += template;
    }
    if (relationsCounter[linkType.Generalization] > 0) {
      const relevantCells = cell.getVisual().semiFolded.filter(v => v.isFoldedUnderThing().triangleType === linkType.Generalization)
        .map(vis => cell.graph.getCell(vis.id));
      let template = relevantCells.length === 1 ? oplTemplates['semifolding']['generalization']['single'] : oplTemplates['semifolding']['generalization']['multiple'];
      template = template.replace('<T>', this.generateSemifoldedThingCellHtml(relevantCells[0]));
      template = template.replace('<Tn>', this.generateSemifoldedThingCellHtml(relevantCells[relevantCells.length - 1]));
      let str = '';
      for (let i = 0; i < relevantCells.length - 1; i++) {
        let comma = (relevantCells.length === 2 && i === relevantCells.length - 2) ? '' : ', ';
        if (relevantCells.length !== 2 && i === relevantCells.length - 2) {
          comma = '';
        }
        str += this.generateSemifoldedThingCellHtml(relevantCells[i]) + comma;
      }
      template = template.replace('<T1...n-1>', str);
      cells.push(...relevantCells);
      const hasBefore = relationsCounter[11] > 0 || relationsCounter[12] > 0;
      const hasAfter = relationsCounter[14] > 0;
      if (hasBefore && !hasAfter) {
        opl.slice(opl.lastIndexOf(', '));
        opl += ', and ';
      } else if (hasBefore && hasAfter) {
        opl += ', ';
 }
      opl += template;
    }
    if (relationsCounter[linkType.Instantiation] > 0) {
      const relevantCells = cell.getVisual().semiFolded.filter(v => v.isFoldedUnderThing().triangleType === linkType.Instantiation)
        .map(vis => cell.graph.getCell(vis.id));
      let template = relevantCells.length === 1 ? oplTemplates['semifolding']['instantiation']['single'] : oplTemplates['semifolding']['instantiation']['multiple'];
      template = template.replace('<T>', this.generateSemifoldedThingCellHtml(relevantCells[0]));
      template = template.replace('<Tn>', this.generateSemifoldedThingCellHtml(relevantCells[relevantCells.length - 1]));
      let str = '';
      for (let i = 0; i < relevantCells.length - 1; i++) {
        let comma = (relevantCells.length === 2 && i === relevantCells.length - 2) ? '' : ', ';
        if (relevantCells.length !== 2 && i === relevantCells.length - 2) {
          comma = '';
        }
        str += this.generateSemifoldedThingCellHtml(relevantCells[i]) + comma;
      }
      template = template.replace('<T1...n-1>', str);
      cells.push(...relevantCells);
      const hasBefore = relationsCounter[11] > 0 || relationsCounter[12] > 0 || relationsCounter[13] > 0;
      if (hasBefore) {
        opl += ', and ';
      }
      opl += template;
    }
    opl += '.';

    const model = getInitRappidShared().getOpmModel();
    const thisSd = getInitRappidShared().getOpmModel().currentOpd;
    const sds = cell.getVisual().logicalElement.visualElements.map(visual => model.getOpdByThingId(visual.id)).filter(s => !!s);
    let highestSd = sds[0];
    for (const opd of sds) {
      if (opd.getOpdDepth() < highestSd.getOpdDepth()) {
        highestSd = opd;
      }
    }
    const ret = [{ opl: opl, cells: cells }];
    if (highestSd !== thisSd) {
      const prs = [
        ['<' + cellType[0].toUpperCase() + '>', this.generateCellHtml(cell)],
        ['<SD>', thisSd.getNumberedName()],
        ['<HighestSD>', highestSd.getNumberedName()],
      ];
      const sent2 = { opl: this.replaceContents(oplTemplates['semifolding']['general'][cellType], prs), cells: [cell] };
      ret.push(sent2);
    }
    return ret;
  },
  generateOPL(options) {
    this.options = options;
    this.forbidden = [];
    let cells = options.graph.getCells();
    const groups = oplFunctions.splitByArcs(cells);
    cells = groups[0];
    const linksOfArcsIDs = oplFunctions.getIDs(groups[1]);
    const ArcsOpl = this.generateLogicLinksOPL(groups[1]);
    const inzoomOpl = [];
    const unfoldOpl = [];
    const objectOpl = [];
    const processOpl = [];
    for (const cell of cells) {
      try {
        let opl = ``;
        const type = cell.attributes.type.slice(4).toLowerCase();
        const isSemifolded = cell.constructor.name.includes('Semi');
        if (isSemifolded && type === 'object') {
          continue;
        }
        const linkType = cell.attributes.name;
        if ((type === 'object' || type === 'process')) {
          const visualCell = options.opmModel.getVisualElementById(cell.attributes.id);
          if (visualCell && visualCell.isInzoomed()) {
            try {
              inzoomOpl.push(this.generateInZoomOpl(cell, options));
            } catch (err) {
            }
          }
          if (visualCell && visualCell.getRefineeUnfold() === visualCell) {
            try {
              unfoldOpl.push(...this.generateUnfoldOpl(cell, options));
            } catch (err) {
            }
          }
        }
        if (type === 'object') {
          opl = this.generateThingOPL(cell);
          if (opl['opl'] && opl['opl'] !== '') {
            objectOpl.push(opl);
          }
          const digitalTwinOpl = this.generatDigitalTwinOpl(cell);
          if (digitalTwinOpl) {
            objectOpl.push(digitalTwinOpl);
          }
          // checking whether the state is ellipsis
          const rectangleStates = cell.getStatesOnly().filter(state => {
            return state.constructor.name === 'OpmState';
          });
          const ellipsis = cell.getEmbeddedCells().find(c => c?.constructor.name.includes('Ellipsis'));
          if (rectangleStates.length > 0 || ellipsis) {
            if (!cell.getVisual()?.logicalElement.isSatisfiedRequirementObject()) {
              opl = this.generateStateOPL(cell.getStatesOnly()[0] || ellipsis);
              objectOpl.push(opl);
            }
          }
          if (cell.getVisual() && cell.getVisual().isSemiFolded()) {
            const semiFoldedOpl = this.generateSemiFoldingOPL(cell);
            objectOpl.push(...semiFoldedOpl);
          }
          if (OPCloudUtils.isInstanceOfVisualThing(cell.getVisual()) && cell.getVisual()?.logicalElement.hasRequirements())
            objectOpl.push(...this.generateRequirementsOpl(cell));
        } else if (type === 'process') {
          if (!isSemifolded) {
            opl = this.generateThingOPL(cell);
            if (opl['opl'] && opl['opl'] !== '') {
              processOpl.push(opl);
            }
            opl = this.generateProcessTime(cell);
            if (opl['opl'] && opl['opl'] !== '') {
              processOpl.push(opl);
            }
          }
          // processOpl.push(...this.generateResultConsumptionChangeLinkOpl(cell));
          processOpl.push(...this.generateProceduralLinksOpl(cell, options, linksOfArcsIDs, ArcsOpl));
          if (cell.getVisual() && cell.getVisual().isSemiFolded()) {
            const semiFoldedOpl = this.generateSemiFoldingOPL(cell);
            processOpl.push(...semiFoldedOpl);
          }
          if (OPCloudUtils.isInstanceOfVisualThing(cell.getVisual()) && cell.getVisual()?.logicalElement.hasRequirements())
            processOpl.push(...this.generateRequirementsOpl(cell));
        } else if (type === 'link' && cell.getSourceElement() && cell.getTargetElement()) {
          if (structuralLinkTypes.indexOf(linkType) > -1) {
            if (['Aggregation-Participation', 'Generalization-Specialization', 'Classification-Instantiation',
              'Exhibition-Characterization'].indexOf(linkType) > -1) {
              if (cell.getAllFundamentalLinks() && cell.attributes.id === cell.getAllFundamentalLinks()[0].attributes.id) {
                const visSource = cell.getVisual()?.source;
                const visTarget = cell.getVisual()?.target;
                if (OPCloudUtils.isInstanceOfVisualThing(visSource) && visSource?.logicalElement.isSatisfiedRequirementSetObject())
                  continue;
                if (OPCloudUtils.isInstanceOfVisualThing(visTarget) && visTarget?.logicalElement.isSatisfiedRequirementSetObject())
                  continue;

                objectOpl.push(this.generateFundamentalLinkOpl(cell));
              }
            } else if (!this.forbidden.includes(cell)) {
              objectOpl.push(this.generateDirectionalLinkOpl(cell));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    const currentOpd = this.options.opmModel.currentOpd;
    const viewOpdOpl = [];
    if (currentOpd.isViewOpd) {
      const opdLevel = this.options.opmModel.currentOpd.getNumberedName();
      const fatherLevel = this.options.opmModel.getOpd(this.options.opmModel.currentOpd.parendId).getNumberedName();
      viewOpdOpl.push({opl: opdLevel + ' is a view OPD, derived from ' + fatherLevel + '.', cells: []});
    }
    const subModelsOpl = [];
    if (currentOpd.children.find(child => child.sharedOpdWithSubModelId)) {
      subModelsOpl.push(...this.generateFatherToSubModelOpl(options));
    }
    if (currentOpd.id === 'SD' && this.options.opmModel.getAllBasicThings().find(l => l.belongsToFatherModelId)) {
      subModelsOpl.push(...this.generatesSubModelFromFatherOpl(options));
    }
    for (const item of [...objectOpl, ...processOpl]) {
      if (item.opl.endsWith(',</b></font><b>.</b>')) {
        item.opl = item.opl.replace(',</b></font><b>.</b>', '</b></font><b>.</b>');
      }
    }
    const oplSet = [...subModelsOpl, ...viewOpdOpl, ...inzoomOpl, ...unfoldOpl, ...objectOpl, ...processOpl, ...ArcsOpl];
    let index = 0;
    while (index < oplSet.length) {
      if (!oplSet[index] || oplSet[index].opl === '') {
        oplSet.splice(index, 1);
        continue;
      }
      const opl = oplSet[index];
      opl.id = index;
      opl.highlighted = false;
      index += 1;
    }

    return oplSet;
  },
  textOnly: false,
  options: undefined,
  forbidden: [],
};
