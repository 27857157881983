import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { OrganizationService } from './organization.service';
import { DatabaseService } from '../../database/database.service';
import { AuthenticationService, User } from './authentication/authentication.service';
import {
  CalculationsServerConnectionSettings,
  ConnectionSettings,
  GraphDBConnectionSettings,
  MySQLConnectionSettings,
  OplService,
  OplSettings,
  StyleSettings
} from '../../opl-generation/opl.service';
import { ReplaySubject } from 'rxjs';
import {joint, validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';
import * as workerTimers from 'worker-timers';
import {PopupMessageData} from '../../dialogs/create-popup-message/popup.message.interface';
import {PopupMessageContentComponent} from '../../dialogs/popup-message-content/popup-message-content.component';

export enum AuthActionTypes {
  login,
  logout,
  signup
}

export interface AuthActionPayload {
  provider?: string;
  user?: {
    Email: string,
    password: string,
  };
}

export interface UserDetails {
  uid?: string;
  Name?: string;
  Email?: string;
  PhotoURL?: string;
  SysAdmin?: boolean;
  OrgAdmin?: boolean;
  IsInsightsUser?: boolean;
  IsDSMUser?: boolean;
  exp_date?;
  AcceptedEula?: boolean;
  SDNames?: boolean;
  autoFormat?: boolean;
  isActive?: boolean; // added for set admin user list (edit users screen)
  usersManagement?: boolean;
  email_subscription?: boolean;
  isExecutionUser?: boolean;
  autosave?;
  multiFactorAuth?: string;
  emailAsSecondAuthFactor?: boolean;
  isViewerAccount?: boolean;
  // opl?: OplSettings;
}

interface StyleDetails {
  process?: StyleSettings;
  object?: StyleSettings;
  state?: StyleSettings;
}

interface ConnectionDetails {
  ros?: ConnectionSettings;
  mqtt?: ConnectionSettings;
  python?: ConnectionSettings;
  mysql?: MySQLConnectionSettings;
  graphDB?: GraphDBConnectionSettings;
  calculationsServer?: CalculationsServerConnectionSettings;
}

export const MAX_SSO_SESSION_TIME = 96; // 96 hours = 4 days.

@Injectable()
export class UserService {
  isUserLoggedIn$ = false;
  isUserInDB;
  userOrg;
  isPublicOrganization;
  returnArr;
  // curUser;
  isAnon;
  authApp;

  public autoload: { path: string, name: string };

  readonly orgAdmin = 'OrgAdmin';
  readonly sysAdmin = 'SysAdmin';
  readonly IsInsightsUser = 'IsInsightsUser';
  readonly IsDSMUser = 'IsDSMUser';
  private user_: User;

  // @ts-ignore
  get user(): Readonly<User> {
    return this.user_;
  }

  private activeUserPingSubscribe;
  private readonly user_subject: ReplaySubject<User> = new ReplaySubject<User>(1);
  public readonly user$: Observable<User> = this.user_subject.asObservable();

  constructor(private database: DatabaseService, private authentication: AuthenticationService, private router: Router,
              private opl: OplService, private organization: OrganizationService) {
    authentication.autoSignIn().then(this.fetchUser).catch(err => { });
    const that = this;
    this.authentication.authenticated$.subscribe(authenticated => {
      if (!authenticated) {
        if (this.activeUserPingSubscribe) {
          try {
            workerTimers.clearInterval(this.activeUserPingSubscribe);
          } catch (err) {}
        }
        this.router.navigate(['/login']);
        if (that.user_)
          location.reload();
      }
    });

    this.user$.subscribe(async (user: User) => {
      this.user_ = user;
      this.userOrg = user.userData.organization;
      this.isPublicOrganization = this.isPublicOrg();
      const isOrgActive = (await this.organization.getAllOrgs()).find(item => item.name === user.userData.organization)?.flag;
      if (isOrgActive === false) {
        validationAlert('Your Organization is Deactivated. Please contact us at: contact@opcloud.tech', 5000);
        this.router.navigate(['/login']);
        setTimeout(() => {
          this.signOutWithFirebase();
          location.reload();
        }, 5000);
        return;
      }

      this.checkIfCanLoginDueToMaxUsersNumber().then( ans => organization.getOrganization(this.userOrg))
        .catch(err => {
          const isViewAccountText = user.userData.isViewerAccount ? 'view ' : '';
          validationAlert('Your Organization maximal ' + isViewAccountText + 'users able to connect at the same time has reacehd. Please contact your organization administrator.', 5000);
          this.router.navigate(['/login']);
          setTimeout(() => {
            this.signOutWithFirebase();
            location.reload();
          }, 5000);
        }).then(res => {
          opl.loadOrgSettings(res);
          return organization.getOrganizationOntology();
        }
      ).then(ontology => {
        opl.loadOrgOntologyData(ontology);
        if (this.autoload) {
          this.router.navigate(['load'], { queryParams: this.autoload });
          this.autoload = undefined;
          return;
        }
      }).finally(() => {
          opl.loadUserSettings(user.userData.opl);
          const that = this;
          this.sendUserPing();
          this.activeUserPingSubscribe = workerTimers.setInterval(function() {
            that.sendUserPing();
          }, 60000);
          this.authentication.listenForInactivity();
          this.showAllAdminsPopupMessages();
          this.opl.areSettingsLoaded = true;
        });
    });
  }

  checkIfCanLoginDueToMaxUsersNumber(): Promise<boolean> {
    return this.database.driver.canLoginDueToMaxUsersNumber(this.user?.userData?.organization);
  }

  sendUserPing() {
    this.database.driver.sendUserPing(this.user?.uid).then((a) => {}).catch((err) => { });
  }

  getUserObservable(): Observable<User> {
    return this.user$;
  }

  isPublicOrg() {
    return this.user.userData.organization === 'Public' && !this.user.userData.orgAdmin && !this.user.userData.sysAdmin;
  }
  //   this.user$.subscribe(user => {
  //     this.user_ = user;
  //     console.log('this.user_ ', this.user_);
  //     if (user)
  //       this.userOrg = user.userData.organization;
  //   });
  // }


  private async showAllAdminsPopupMessages() {
    const messages = await Promise.all([
      this.database.driver.getAllPopupMessagesToShow('system'),
      this.database.driver.getAllPopupMessagesToShow('org')
    ]);
    const sysMessages = messages[0];
    const orgMessages = messages[1];

    for (const msg of sysMessages) {
      const ret = await this.openAdminPopupMessage(msg, 'system');
      if (ret === 'signout') {
        return this.authentication.signOut();
      }
    }
    for (const msg of orgMessages) {
      await this.openAdminPopupMessage(msg, 'org');
    }

  }

  openAdminPopupMessage(message: PopupMessageData, type: 'org' | 'system') {
    const init = this.opl.options;
    const dialog = init.dialogService.openDialog(PopupMessageContentComponent, null, 700, { doNotClose: type === 'system' ? 'true' : 'false', message: message, type });
    return dialog?.afterClosed().toPromise() || Promise.resolve();
  }

  public authAction(type: AuthActionTypes, payload?: AuthActionPayload) {
    return this.chooseAuthAction(type, payload);
  }

  public updateUserValidationSettings(values) {
    return this.updateUserOplSetting(values);
  }

  private chooseAuthAction(type: AuthActionTypes, payload?: AuthActionPayload) {
    switch (type) {
      case AuthActionTypes.login: {
        return payload.provider ? this.signInWithProvider(payload.provider) : this.signInWithMsal();

      }
      /*case AuthActionTypes.signup: {
        return this.signUp(payload.user)
          .then((res) => { this.updateDB(payload.user); });
      }*/
      case AuthActionTypes.logout: {
        if (payload.provider) {
          return this.signOutWithFirebase();
        } else {
          return this.signOutWithMsal();
        }
      }
    }
  }

  private signInWithProvider(provider) {
    return Promise.reject('Not Implemented');
    // this.signInWithProoviderInterface(provider);
    /*if (!availableProviders.includes(provider)) {
      throw (new Error(`Auth provider is not supported: ${provider}`));
    }
    return this.afAuth.auth.signInWithPopup(new authProviders[provider]());*/
    // return this.auth.signInWithPopup(provider);
  }

  private fetchUser = async (user) => {
    if (!user) {
      return;
    }
    return new Promise<void>((res, rej) => {
      this.database.driver.findUser(user).then((userData) => {
        if (userData) {
          this.user_subject.next(userData);
        }
        if (userData.userData.sso_user && userData.userData.lastAuthTime) {
          const lastAuth = Number(String(userData.userData.lastAuthTime) + '000');
          const now = new Date().getTime();
          const diffInHours = ( now - lastAuth ) / (1000 * 60 * 60);
          if (diffInHours > MAX_SSO_SESSION_TIME) {
            this.authentication.signOut();
          }
        }
        res();
      }).catch(err => {
        rej(err);
      });
    });
  }


  // functions for both ways (Firebase = OPCloud account & msal = Microsoft account). sign in + sign out
  public signInWithEmailAndPassword(user, recaptchaVerifier) {
      return this.authentication.signInWithEmailAndPassword(user.Email, user.password, recaptchaVerifier, user.verificationCode).then(res => this.fetchUser(res.user));
  }

  public signInWithGoogle() {
    return this.authentication.signInGoogle().then(res => {
      return {
        homeAccountId: res.sub,
        tenantId: res.hd,
        name: res.name,
        username: res.email,
        PhotoURL: res.picture,
        idTokenClaims: { tid: res.hd }
      };
    }).then(account => this.fetchUserBySSOProvider(account));
  }

  public signInWithMsal() {
      return this.authentication.signInMsal().then(res => {
        // cleaning invalid chars.
        if (res.account?.homeAccountId) {
          res.account.homeAccountId = res.account.homeAccountId.split('.').join('').split('-').join('');
        }
        return res.account;
      }).then(account => this.fetchUserBySSOProvider(account));
  }

  private updateUserPhotoFromSSO(name: string, photo: string) {
    const details: UserDetails = {
      Name: name,
      PhotoURL: photo
    };
    this.updateDB(details).then(res => {}).catch(err => {})
  }

  private async fetchUserBySSOProvider(user, firstTime = true) {
    if (!user) {
      return;
    }
    const that = this;
    return new Promise<void>((res, rej) => {
      this.database.driver.findUser({uid: user.homeAccountId}).then(async (userData) => {
        if (userData && userData.userData?.Email) {
          this.user_subject.next(userData);
          if (user.PhotoURL && user.PhotoURL !== '') {
            that.updateUserPhotoFromSSO(userData.userData.Name, user.PhotoURL);
            userData.userData.PhotoURL = user.PhotoURL ? user.PhotoURL : userData.userData.PhotoURL;
          }
          res();
        } else {
          if (userData && !userData.email && firstTime) {       // if succeeded to authenticate using sso provider but still has no user at our system - register it.
            const reg = await this.registerNewUserFromSSO(user);
            if (reg.success) {
              this.fetchUserBySSOProvider(user, false);
              res();
            } else if ((<any>reg).exist?.token?.token) {
              await this.authentication.signInWithFirebaseToken((<any>reg).exist.token?.token);
              await this.fetchUser((<any>reg).exist.user);
              res();
            }
            else rej(reg.message);
          }
        }
      }).catch(async err => {
        res();
      });
    });
  }

  private getOrganizationBySSOOrgId(org_id: string) {
    return 'Technion';
  }

  private registerNewUserFromSSO(userData) {
    const uuid = joint.util.uuid;
    const password = uuid();
    const user = {
      uid: userData.homeAccountId,
      name: userData.name,
      email: userData.username,
      password: password,
      repeatPassword: password,
      organization: userData.idTokenClaims.tid,
      exp_date: '',
      isPermanent: true,
      sso_user: true,
    };
    return this.registerUser(user);
  }

  public registerUser(user: any): Promise<{ success: boolean, message?: string }> {
    return this.database.driver.createUser(user);
  }

  public signOutWithFirebase(): Promise < boolean > {
      return this.authentication.signOut();
  }

  public signOutWithMsal(): Promise < boolean > {
      return this.authentication.signOutWithMsal();
  }

  public updateDB(details: UserDetails) {
    return this.updateUser(this.user.uid, this.user.userData.organization, details);
  }

  public updateUser(uid: string, organization: string, details: UserDetails) {
    const param = this.cleanUserDetails(details);
    if (param.Name === '') {
      param.Name = this.user.userData.Name;
    }
    const userData = this.user.userData;
    return this.database.driver.updateUser(uid, organization, param).then(result => {
      if (this.user.uid !== uid) {
        return;
      }
      Object.keys(param).forEach(function (key) {
        userData[key] = param[key];
      });
    });
  }

  private cleanUserDetails(details: UserDetails): UserDetails {
    const param = {};
    Object.keys(details).forEach(function (key) {
      // if (key === 'exp_date' || (details[key] === true || details[key] === false))
      if (key === 'exp_date' || details[key] !== undefined) {
        param[key] = details[key];
      }
    });
    return param;
  }

  updateUserDetails(uid, user) {
    return this.updateUser(uid, this.user.userData.organization, user);
  }

  deleteUser(useruid: string, params: any) {
    return this.database.driver.deleteUser(useruid, params);
  }

  resetPassword(user_email: string) {
    return this.authentication.resetPassword(user_email);
  }

  public resetPasswordForNotLoggedUser(email: string) {
    return this.authentication.resetPasswordForNotLoggedUser(email);
  }

  public async changePassword(userid: string, password: string): Promise<boolean> {
    // if (this.database.driver instanceof FirebaseDatabaseDriver)
    //   return Promise.reject('not imp');
    // const driver = <MongoDatabaseDriver>this.database.driver;
    return false;
  }

  public shouldChangePassword(): boolean {
    // if (this.database.driver instanceof FirebaseDatabaseDriver)
    //   return false;
    return true;
  }

  isOrgAdmin(user = this.user.userData) {
    if (this.orgAdmin in user) {
      if (user.OrgAdmin) { return true; }
    }
    return false;
  }
  isSysAdmin(user = this.user.userData) {
    if (this.sysAdmin in user) {
      if (user.SysAdmin) { return true; }
    }
    return false;
  }
  isInsightsUser(user) {
    if (this.IsInsightsUser in user.userData) {
      return (user.userData.IsInsightsUser);
    }
    return false;
  }

  isDSMUser(user) {
    if (user && user.userData && (this.IsDSMUser in user.userData)) {
      return (user.userData.IsDSMUser);
    }
    return false;
  }

  updateUserOplSetting(settings: OplSettings | StyleDetails | ConnectionDetails): Promise<void> {
    return this.database.driver.updateUserOplSettings(settings).then(result => {
      Object.keys(settings).forEach((key) => {
        this.user.userData.opl[key] = settings[key];
      });
    });
  }

  changeUserInitialPassword() {
    return this.database.driver.changeUserInitialPassword(this.user.uid);
  }

  resend2FAVerificationCode(email: string) {
    return this.database.driver.resend2FAVerificationCode(email);
  }
}


/*
getUserListWithDetails(organization?): any {
    // this.getUserListWithDetailsInterface(organization?);
    this.orgService.getOrganizations().then(orgS => {
      orgS.forEach((org) => {
        if (organization && (org !== organization)) {
          return;
        } else {
          return this.orgService.getOrganizationUsers(org)
            .then((Members) => {
              Members.forEach((member) => {
                this.database.driver.getUserFromOrg(member, org)
                  this.afDB.object(`/${org}/Users/${member}`).map((userData) => {
                    if (member !== null) {
                      return { userData };
                    }
                  })
                  .forEach(res => {
                    if (res) { this.returnArr.push(res.userData) };
                  });
              });
            });
        }
      });
    });
    return this.returnArr;
  }

  getUserOrg(uid?): string {
    // this.getUserOrgInterface(uid);
    return "SSSS";
     if (this.isAnon) {
      return environment.Defaults.Organization;
    }
    if ((!uid) && (!this.auth.getCurrentUserUid())) {
      return null;
    }
    if (((!uid) || (uid === this.auth.getCurrentUserUid())) && (this.userOrg)) {
      return this.userOrg;
    } else {
      //console.log("scanning all orgs for user");
      return this.orgService.getOrganizations().then(orgS => {
        orgS.forEach((org) => {
          return this.orgService.getOrganizationUsers(org)
            .then((Members) => {
              let currentUid = uid ? uid : this.auth.getCurrentUserUid();
              for (var i = 0, len = Members.length; i < len; i++) {
                console.log(this.calls++);
                let uid = Members[i];
                if (uid === currentUid) {
                  //console.log("get user org found a match:",org);
                  this.userOrg = org;
                  break;
                }
              }
              return org;
            }).catch(e => console.log(e));
        });
      });
    }
  }


  getUserDetailsById(id): any {
    //this.getUserOrg(id);
    return this.database.driver.getUserDetailsById(id, this.userOrg);
    if (this.afDB.object(`/${this.userOrg}/Users/${id}`)) {
      //console.log("found userid:",id);
      return this.afDB.object(`/${this.userOrg}/Users/${id}`).take(1);
    } else {
      return Observable.of(null);
    }
  }

  UpdateIfCurrUserExistsInDB() {
    if (this.isUserInDB) {
      return this.isUserInDB;
    } else {
      //console.log("scanning users to find if user exists in DB");
      //console.log(this.orgService.getOrganizations());
      this.orgService.getOrganizations().then(orgS => {
        orgS.forEach((org) => {
          this.orgService.getOrganizationUsers(org)
            .then((Members) => {
              let currentUid = this.auth.getCurrentUserUid();
              for (var i = 0, len = Members.length; i < len; i++) {
                let uid = Members[i];
                if (uid === currentUid) {
                  //console.log("get user org found a match:",org);
                  this.isUserInDB = true;
                  break;
                }
              }
            }).catch(e => console.log(e));
        });
      }).catch(e => console.log(e));
    }
  }
  */

/*public updateCurrentUserDetails(details) {
const userData = this.user.value.userData;
const param = {};
Object.keys(details).forEach(function (key) {
  if (details[key].length)
    param[key] = details[key];
});

return this.database.driver.updateUser(userData.uid, userData.organization, param).then(result => {
  Object.keys(param).forEach(function (key) {
    userData[key] = param[key];
  });
});
}*/


