import {Injectable, Optional} from '@angular/core';
import { GraphService } from '../../rappid-components/services/graph.service';
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import * as fileSaver from 'file-saver';
import {formatDate} from "../../database/dateFormat";

@Injectable()
export class ExportOPLAPIService {

  private readonly initRappidService: InitRappidService;
  private opdsOrder;

  constructor(initRappid: InitRappidService) {
    this.initRappidService = initRappid;
  }

  public async exportOPL(getFileName: any) {
    this.initRappidService.isLoadingModel = true;
    this.initRappidService.exportingOpl = true;
    const originalGraph = this.initRappidService.graph;
    await new Promise(r => setTimeout(r, 3000));
    let numberedOPL;
    let num = 0;
    let fileName;
    if (!getFileName || (getFileName[0].trim() === '')) {
      // get default file name
      fileName = this.initRappidService.opmModel.createDefaultModelName();
      numberedOPL = true;
    } else {
      fileName = getFileName[0];
      numberedOPL = getFileName[1];
    }

    // The process of saving the exported file is starting.
    // An array that will include the OPL sentences for each OPD separately.
    const arrayOfOpds = new Array();
    arrayOfOpds.push('<html><body>'); // Open an HTML Tag for arrayOfOpds.
    // The next lines define the colors of words according to their type - object \ process \ state for arrOfSD.
    arrayOfOpds.push('<style> .object { color: #00b050; } </style>');
    arrayOfOpds.push('<style> .process { color: #0070c0; } </style>');
    arrayOfOpds.push('<style> .state { color: #808000; } </style>');
    let creationDate;
    const model = this.initRappidService.graphService.modelObject.modelData;
    if (model?.editBy && model.editBy['date'] && model.editBy['date'] !== '') {
      creationDate = typeof model.editBy['date'] === 'number' ? formatDate(new Date(model.editBy['date'])) : model.editBy['date'];
    } else {
      creationDate = 'unknown';
    }
    // Inserting the model name with model id in the tag and last edited date for versions
    arrayOfOpds.push(`<table><tr><b model-id="${this.initRappidService.opmModel?.name !== 'Model (Not Saved)' ? this.initRappidService.graphService.modelObject.id : 'undefined'}" last-edited="${creationDate}">Model Name: `);
    arrayOfOpds.push(this.initRappidService.opmModel?.name ? this.initRappidService.opmModel.name : 'Undefined name for unsaved model');
    arrayOfOpds.push('</b></tr></table>');
    arrayOfOpds.push('<br>');
    // An array that will include the OPL sentences for all Opds together
    // without duplication.
    const arrOfAllOpl = new Array();
    // arrOfAllOpl.push('<html><body>'); // Open an HTML Tag for arrOfAllOpl. No need for one document to have two html tags
    /// The next lines define the colors of words according to their type - object \ process \ state for arrOfAllOpl.
    arrOfAllOpl.push('<style> .object { color: #006400; } </style>');
    arrOfAllOpl.push('<style> .process { color: #00008B; } </style>');
    arrOfAllOpl.push('<style> .state { color: #808000; } </style>');
    arrOfAllOpl.push('<style> p { margin-top: 0em; margin-bottom: 0em; } </style>');
    arrOfAllOpl.push('<table><tr><b>OPL spec.</b></tr>'); // Insert a title for the arrOfAllOpl array.
    arrOfAllOpl.push('<br>');
    // Save the current Opd that the user is editing, for rendering it at the end of the function.
    const currentOpd = this.initRappidService.opmModel.currentOpd;
    // Get all existing OPDs in the graph.
    this.setOpdsOrder();
    const allOpd = this.opdsOrder; // this.initRappidService.opmModel.opds.filter(o => !o.isHidden);
    // This loop goes over all OPDs and inserts the OPL sentences to the arrays.
    for (let i = 0; i < allOpd.length; i++) {
      if (allOpd[i].sharedOpdWithSubModelId && allOpd[i].visualElements.length === 0) {
        continue;
      }
      const tempGraph = this.initRappidService.graphService.renderGraph(allOpd[i], this.initRappidService, null, true); // Goes to the next OPD in the allOpd array.
      this.initRappidService.graph = tempGraph;
      this.initRappidService.graphService.graph = tempGraph;
      // Get the name of the current OPD.
      let nameOfOpd;
      if (allOpd[i].parendId !== 'Stereotypes') {
        nameOfOpd = allOpd[i].getNumberedName() + ((allOpd[i].getNumberedName() === 'SD') ? '' : ': ' + allOpd[i].getName());
      } else {
        nameOfOpd = 'Stereotype: ' + allOpd[i].getNumberedName();
      }
      arrayOfOpds.push(`<table><tr><b opd-id="${allOpd[i].id}">`);
      arrayOfOpds.push(nameOfOpd); // Insert the name of the current OPD as a title in the arrOfSD array.
      arrayOfOpds.push('</b></tr>');
      arrayOfOpds.push('<br>');
      // Get the cells that contain the OPL sentences of the current OPD.
      const cells = this.initRappidService.oplService.generateOpl(); // YANG's function
      // This loop goes over all of the cells and inserts the OPL sentences to the arrays.
      for (let j = 0; j < cells.length; j++) {
        if (cells[j].opl) { // Check if the cell contains an OPL sentence.
          // Get the OPL sentence of the cell.
          let innerHTML = '';
          if (numberedOPL) {
            innerHTML = String(j + 1) + '. ' + cells[j].opl; // get the html of this opl sentence
            num += 1;
          } else innerHTML = cells[j].opl;
          arrayOfOpds.push('<tr>', innerHTML, '</tr>'); // Insert the OPL sentence into the arrOfSD array.
          arrayOfOpds.push('<br>');
          // Initialization of a temp boolean variable.
          // It will be used as a flag to check if the current OPL sentence already exists in the arrOfAllOpl array.
          if (!numberedOPL) innerHTML = cells[j].opl;
          else innerHTML = String(num) + '. ' + cells[j].opl;
          arrOfAllOpl.push('<tr>', innerHTML, '</tr>'); /// Insert the OPL sentence into the arrOfAllOpl array.
          arrOfAllOpl.push('<br>');
        }
      }
      arrayOfOpds.push('</table><br>');
    }
    // arrayOfOpds.push('</body></html>'); // Close an HTML Tag for arrayOfOpds. No need for one document to have two html tags
    arrOfAllOpl.push('</table></body></html>'); // Close an HTML Tag for arrOfAllOpl.
    this.initRappidService.graph = originalGraph;
    this.initRappidService.graphService.graph = originalGraph;
    this.initRappidService.graphService.renderGraph(currentOpd, this.initRappidService); // Goes back to the OPD that the user is editing.
    // Concatenation of the arrOfSD and the arrOfAllOpl arrays.
    const exportFileContent = arrayOfOpds.concat(arrOfAllOpl);
    // Create the exported file.
    const exportFile = new Blob(exportFileContent, {type: 'html/plain;charset=utf-8'});
    fileSaver.saveAs(exportFile, fileName + '.html'); // Save the exported file.
    this.initRappidService.isLoadingModel = false;
    this.initRappidService.exportingOpl = false;
  }

  setOpdsOrder() {
    this.opdsOrder = [];
    const regularOpds = this.initRappidService.opmModel.opds.filter(o => !o.requirementViewOf && !o.stereotypeOpd).sort((a, b) => {
      return a.getNumberedName() > b.getNumberedName() ? 1 : -1;
    });
    const requirementViews = this.initRappidService.opmModel.opds.filter(o => o.requirementViewOf);
    const stereotypesOpds = this.initRappidService.opmModel.stereotypes.getStereoTypes().map(str => str.opd);
    this.opdsOrder = [...regularOpds, ...requirementViews, ...stereotypesOpds].filter(opd => !opd.isHidden);
  }

}
