/**
 * Created by ta2er on 2017-08-17.
 */

import {
  Component, EventEmitter, OnInit, Optional,
  ComponentFactoryResolver, ViewContainerRef,
} from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GraphService } from "../../rappid-components/services/graph.service";
import { InitRappidService } from "../../rappid-components/services/init-rappid.service";
// import { NgProgress } from 'ngx-progressbar';
import * as FileSaver from "file-saver";
import {getInitRappidShared, OPCloudUtils} from '../../configuration/rappidEnviromentFunctionality/shared';
import { ModelService } from '../../modules/app/model.service';
import { ContextService } from '../../modules/app/context.service';
import { parseString } from 'xml2js';
import {OpmVisualThing} from "../../models/VisualPart/OpmVisualThing";

@Component({
  selector: 'Upload_File',
  templateUrl: 'FileUploader.html',
  styleUrls: ['FileUploader.scss']
})

export class UploadFile implements OnInit {

  uploader: FileUploader = new FileUploader({});//Empty options to avoid having a target URL
  reader: FileReader = new FileReader();
  XML: XMLDocument;
  onAdd = new EventEmitter();
  importOPX: boolean = true;
  private OPX_JSON: any;
  SpinnerComponentRef;
  SpinnerComponentFactory;
  imported = true;
  uploaded = false;
  logcolor = "primary";
  DoneImport = false;
  log = '';
  now = new Date();

  constructor(@Optional() public dialogRef: MatDialogRef<FileUploader>,
    private graphService: GraphService,
    private contextService: ContextService,
    private modelService: ModelService,
    private initRappidService: InitRappidService) {
  }

  ngOnInit(): void {
    let That = this;
    this.reader.onload = (ev: any) => {
      this.XML = ev.target.result;

      parseString(this.XML, function (err, result) {
        That.OPX_JSON = result;
        That.uploaded = true;
        That.imported = false;
      });

    };

    this.uploader.onAfterAddingFile = (fileItem: any) => {
      That.reader.readAsText(fileItem._file);
    };
  }

  public async Import() {

    let promise = new Promise<any>((resolve, reject) => {
      // this.ngProgress.start();
      setTimeout(() => { resolve({}) }, 3000);
    });
    promise.then(() => {
      // reset the current graph in the back
      this.contextService.newModel();
      this.contextService.closeEmptyTabs();
      this.graphService.renderGraph(this.modelService.model.getOpd('SD'), this.initRappidService);
      const ImportedModel = this.graphService.importOpxGraph(this.OPX_JSON, this.initRappidService);
      this.log = JSON.stringify(ImportedModel.Log, null, ' ');
      // this.ngProgress.done();
      this.DoneImport = true;
      this.imported = true;
      if (ImportedModel.CheckLog) {
        this.logcolor = "warn"
      }
      const init = getInitRappidShared();
      init.getOpmModel().opds.forEach(opd => {
        init.graphService.renderGraph(opd, init);
        init.graph.getCells().filter(c => OPCloudUtils.isInstanceOfDrawnEntity(c)).forEach(th => {
          const vis = th.getVisual();
          th.set('position', { x: vis.xPos * 2, y: vis.yPos * 2});
          th.autosize(init);
          if (OPCloudUtils.isInstanceOfDrawnProcess(th))
            th.changeSizeHandle(init);
        });
        init.graph.getCells().filter(c => OPCloudUtils.isInstanceOfDrawnTriangle(c)).forEach(tr => {
          tr.set('position', { x: tr.get('position').x * 2, y: tr.get('position').y * 2});
        });
        init.graph.getCells().filter(c => OPCloudUtils.isInstanceOfDrawnObject(c)).forEach(obj => {
            obj.attr('text/ref-y', obj.hasStates() ? 0.25 : 0.5);
            obj.shiftEmbeddedToEdge(init);
        });
        for (const vis of opd.visualElements)
          if (vis instanceof OpmVisualThing)
            opd.beautify(vis);
        init.graphService.updateOPDAfterImport(opd, init);
        init.graphService.renderGraph(opd, init);
      });
      init.graphService.renderGraph(init.getOpmModel().currentOpd, init); // Kfir: Go back to current opd so it will displayed
    });

  }

  logFile() {
    FileSaver.saveAs(new Blob([this.log], { type: 'text/plain;charset=utf-8' }),
      'OPCloud_log' + this.now + '.txt');
    this.dialogRef.close();
  }

  onButtonClick() {
    this.onAdd.emit();
  }

}
