import { OpmOpd } from '../../models/OpmOpd';
import { PathsFromObjectsToProcessCalculator } from './paths-from-objects-to-process-calculator';
import { OpmModel } from '../../models/OpmModel';
import { VariablesCalculator } from './variables-calculator';
import { handleExeExceptions, hasWhiteSpaces, ObjectItem } from './computationalPartUtils';
import { code, linkType } from '../../models/ConfigurationOptions';
import { getInitRappidShared, validationAlert } from '../rappidEnviromentFunctionality/shared';
import { OpmVisualProcess } from '../../models/VisualPart/OpmVisualProcess';
import {ExecutionRunner, runner} from './computationalPart';
import {WebSocketCommunicator, WebSocketCommunicatorI} from "./communication-object";

/**
 * A class that handles Python functions.
 * opmModel - the current opm model
 * */
export class PythonFunctionExecutor {
  runner;
  globalRunTimeEnvironment;
  userInputValue;
  WSPython;

  constructor(private readonly opmModel: OpmModel, private readonly pythonWS: WebSocketCommunicatorI, private visProcess: OpmVisualProcess, runner?: ExecutionRunner, globalRunTimeEnvironment = undefined, userInputValue = undefined) {
      this.runner = runner;
      this.globalRunTimeEnvironment = globalRunTimeEnvironment;
      this.userInputValue = userInputValue;
      this.WSPython = pythonWS;
    }

  public async execute(opd: OpmOpd, OpmVisualProcess, valuesArray: ObjectItem[], functionValue) {
    const pathsCalculator = new PathsFromObjectsToProcessCalculator(opd, OpmVisualProcess, this.opmModel, code.UserDefined, this.globalRunTimeEnvironment);
    const all_paths = pathsCalculator.calculate(opd, valuesArray);
    return this.runPythonFunction(all_paths, valuesArray, functionValue);
  }
  /**
   * calculates the function variables definitions and the function (replace the variables: a.b -> a_b ,a/b -> a$b),
   * returns the function output
   **/
  private async runPythonFunction(all_paths: Map<string, ObjectItem[][]>, valuesArray: ObjectItem[],
    pythonFunction: { functionInput: string; }) {
    let all_variables_str = [];
    let non_legal_variables_str = []; /* will include illegal variables, for example, if a.b.c is computational object
    (with no other sub objects), then a.b  legal.*/
    let alias = [];
    const vc = new VariablesCalculator(all_paths, valuesArray);
    const userInputVar = 'userInput = ' + (!isNaN(Number(this.userInputValue)) ? Number(this.userInputValue) : "'"+ this.userInputValue + "'");
    const function_variables = vc.calc_variables_str(all_variables_str, code.Python, non_legal_variables_str, alias);
    const functionContent = this.replaceVariables(pythonFunction.functionInput, all_variables_str, non_legal_variables_str);

    let functionInput = userInputVar + '\n' + function_variables + '\n' + functionContent;
    let aliasArr = '';
    for (const item of alias) {
      delete item['lid'];
    }
    if (alias.length > 0) {
      aliasArr = 'aliasArr = ' + JSON.stringify(alias, null, 2);
      let count = 0;
      aliasArr = aliasArr.replace(/{/g, function() {
          count++;
          return count + ':{';
        }
      );
      aliasArr = aliasArr.replace('[', '{');
      aliasArr = aliasArr.replace(']', '}\n');
    }
    functionInput = aliasArr + functionInput;
    let x;
    if (this.pythonWS.isActive) {
      this.pythonWS.send({
        what: 'python',
        message: functionInput,
      });
      x = this.pythonWS.get();
    } else {
      x = '';
      validationAlert('No Python connection established!', 2500, undefined, true);
    }
    return x;
  }
  setValueByOutputsAlias(outputsAliases, aliasToSearch, valueToAssign) {
    const targets = outputsAliases[aliasToSearch];
    if (!targets)
      return;
    for (const targetId of targets) {
      const logical = getInitRappidShared().opmModel.getLogicalElementByLid(targetId);
      if (!logical.isComputational())
        continue;

      const wasSet = logical.setValue(valueToAssign);
      if (wasSet == false) {
        getInitRappidShared().elementToolbarReference.Executing = false;
        validationAlert('Non valid value was given. Execution will stop.', 5000, 'error');
        return;
      }
      logical.states[0].text = valueToAssign && !!valueToAssign.toString ? valueToAssign.toString() : valueToAssign;
      getInitRappidShared().getGraphService().updateComputationalStateDrawnsByLogicalObject(logical);
    }
    return valueToAssign;
  }
  isAllowedToChangeValue(logProcess, logObject) {
    const validTypes = [linkType.Effect, linkType.Result];
    // if there is a direct effect link between the process and the object.
    const conditionA = !!logObject.getLinks().outGoing.find(l =>
      validTypes.includes(l.linkType) && l.targetLogicalElements[0].lid === logProcess.lid);
    if (conditionA)
      return true;
    const parents = logObject.getLinks().inGoing.filter(link =>
      [linkType.Aggregation, linkType.Instantiation, linkType.Exhibition, linkType.Generalization].includes(link.linkType)).map(link => link.sourceLogicalElement);
    for (const p of parents)
      if (!!p.getLinks().outGoing.find(l => validTypes.includes(l.linkType) && l.targetLogicalElements[0].lid === logProcess.lid))
        return true;
    return false;
  }
  /**
   returns the function with variables replaced if needed. for example, if there is an object a.b.c.d and the
   function is return a.b.c.d, it will return 'return a_b_c_d'. also, a/b/c will be replaced to a&b&c to enable names with '/'
   the function also announces the user if they are using an illegal variable for example if a.b.c is legal, then a.b is
   not legal. (though a can be legal)
   **/
  public replaceVariables(functionInput: string, function_variables: string[], non_legal_variables_str: string[]) {
    let function_input_replaced_str = functionInput;
    function_variables.sort((a, b) => b.length - a.length);
    non_legal_variables_str.sort((a, b) => b.length - a.length);
    function_variables.forEach(variable => {
      const var_with_dots_and_dollars = variable.replace(/_/g, '\.').replace(/\$/g, '\/');
      const var_regex = new RegExp(var_with_dots_and_dollars, 'g');
      // function_input_replaced_str = function_input_replaced_str.replace(/\r?\n|\r/g, '');
      const occurrence_of_variable = function_input_replaced_str.match(var_regex);
      if (occurrence_of_variable && occurrence_of_variable.length > 0) {
        if (hasWhiteSpaces(variable)) {
          handleExeExceptions('Check ' + var_with_dots_and_dollars + '. Its identifier contains illegal characters (like space)!.<br>Check if it has another possible identifier (use name or alias). ');
        } else {
          function_input_replaced_str = function_input_replaced_str.replace(var_regex, variable);
        }
      }
    });
    non_legal_variables_str.forEach(variable => {
      const var_with_dots_and_dollars = variable.replace(/_/g, '\.').replace(/\$/g, '\/');
      const var_regex = new RegExp(var_with_dots_and_dollars + '(\\*|\\/|;|-|\\+| )*$', 'g'); /* for exact
      match - so only if a sub object is used for example, it should prevent from using a.b.c to alert about using a.b.
       might need refinement for more complex occurrences */
      function_input_replaced_str = function_input_replaced_str.replace(/\r?\n|\r/g, '');
      const occurrence_of_variable = function_input_replaced_str.match(var_regex);
      if (occurrence_of_variable && occurrence_of_variable.length > 0) {
        handleExeExceptions('The variable ' + var_with_dots_and_dollars + ' cannot be used.');
      }
    });
    return function_input_replaced_str;
  }
}
