import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { CoreModule } from './core.module';
import { SettingsComponent } from './Settings/settings.component';
import { UserDetailsComponent } from './Settings/user-mgmt/update-user-details/update-user-details.component';
import { SettingsHome } from './Settings/settings-home/settings-home';
import { MemberGrpComponent } from './Settings/group-mgmt/update-user-grp/member-grp/member-grp.component';
import { UserGroupsComponent } from './Settings/group-mgmt/update-user-grp/update-user-grp.component';
import { SettingsUpdateOrgUserDetails } from './Settings/user-mgmt/update-user-details/update-org-users-details';
import { SettingsUnderConstruction } from './Settings/settings.under_constractions';
import { OplDialogComponent } from '../dialogs/opl-dialog/opl-dialog.component';
import { CanActivateViaAuthGuard } from './CanActivateViaAuthGuard';
import { MainLoginComponent } from './MainLogin.component';
import { SettingsNewUser } from './Settings/user-mgmt/new-user/new-user.component';
import { ModelAnalysisComponent } from "./Settings/model-analysis/model-analysis.component";
import { ListThingsComponent } from "./Settings/list-things/list-things.component";
import { SettingsScriptsComponent } from "./Settings/settings-scripts.component";
import { SettingsNewOrg } from "./Settings/organization-mgmt/new-org/new-org.component";
import { AddGroupComponent } from './Settings/group-mgmt/add-group/add-group.component';
import { SetAdminUserListComponent } from '../dialogs/set-admin-user-list/set-admin-user-list.component';
import { DeleteOrgComponent } from './Settings/organization-mgmt/delete-org/delete-org.component';
import {GroupAdministrationComponent} from './Settings/group-mgmt/group-administration/group-administration.component';
import { UserAgreementComponent } from "../dialogs/UserAgreement/user-agreement.component";
import {OPCloudOrganizationSettingsComponent_user} from './Settings/OPCloud-Organization-Settings/OPCloud-Organization-Settings.component_user';
import {OPCloudOrganizationSettingsComponent_org} from './Settings/OPCloud-Organization-Settings/OPCloud-Organization-Settings.component_org';
import {OplSettingsComponent} from './Settings/OplSettings/opl-settings.component';
import {OpcloudSettingsComponent} from './Settings/OpCloudSettings/opcloud-settings.component';
import { OpcAppResolver } from '../database/resolvers/opc.resolver';
import {OrgAdminComponent} from './Settings/organization-mgmt/org-admin/org-admin.component';
import {OplDialogComponentSysAdmin} from '../dialogs/opl-dialog/opl-dialog-SysAdmin';
import {SendEmailComponent} from './Settings/organization-mgmt/send-email/send-email.component';
import {DsmAnalysisComponent} from './Settings/dsm-analysis/dsm-analysis.component';
import {OPCloudManualComponent} from './Settings/OPCloudManual/opcloud-manual.component';
import {paretoFrontierDialog} from "../dialogs/pareto-frontier-dialog/pareto-frontier-dialog";
import { LogsDashboardComponent } from '../dialogs/logs-dashboard/logs-dashboard.component';
import {SSOSettingsComponent} from "./Settings/organization-mgmt/sso/sso-settings";
import {OrganizationOntologyComponent} from "./Settings/OrgOntology/Organization-Ontology.component";
import {NlpModelAnalysisComponent} from "./Settings/nlp-model-analysis/nlp-model-analysis.component";
import {ModelAnalysisToolsComponent} from "./Settings/model-analysis-tools/model-analysis-tools.component";
import {OPCloudPrivacyPolicyComponent} from "./Settings/OPCloudPrivacyPolicy/opcloud-privacy-policy.component";
import {FindUserAdminComponent} from "../dialogs/find-user-admin/find-user-admin.component";
import {PopupMessagesComponent} from "./Settings/PopupMessages/popup-messages.component";
import {OrganizationAnalyticsComponent} from "./Settings/organization-analytics/organization-analytics.component";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [CanActivateViaAuthGuard]
  },
  {
    path: 'load/:id',
    component: MainComponent,
    canActivate: [CanActivateViaAuthGuard],
    resolve: { message: OpcAppResolver }
  },
  {
    path: 'load/:id/:opd',
    component: MainComponent,
    canActivate: [CanActivateViaAuthGuard],
    resolve: { message: OpcAppResolver }
  },
  {
    path: 'load',
    component: MainComponent,
    canActivate: [CanActivateViaAuthGuard],
    resolve: { message: OpcAppResolver }
  },
  {
    path: 'login',
    component: MainLoginComponent
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: 'under-Construction',
        component: SettingsUnderConstruction,
        outlet: 'settings_main'
      },
      {
        path: 'org-opl-settings',
        component: OplDialogComponent,
        outlet: 'settings_main'
      },
      {
        path: 'sysadmin-opl-settings',
        component: OplDialogComponentSysAdmin,
        outlet: 'settings_main'
      },
      {
        path: 'organization_ontology',
        component: OrganizationOntologyComponent,
        outlet: 'settings_main'
      },
      {
        path: 'organization_analytics',
        component: OrganizationAnalyticsComponent,
        outlet: 'settings_main'
      },
      {
        path: 'system_popup_messages',
        component: PopupMessagesComponent,
        data: { type: 'system' },
        outlet: 'settings_main'
      },
      {
        path: 'org_popup_messages',
        component: PopupMessagesComponent,
        data: { type: 'org' },
        outlet: 'settings_main'
      },
      {
        path: 'opl-settings',
        component: OplSettingsComponent,
        outlet: 'settings_main'
      },
      {
        path: 'opcloud-settings',
        component: OpcloudSettingsComponent,
        outlet: 'settings_main'
      },
      {
        path: 'logs-dashboard',
        component: LogsDashboardComponent,
        outlet: 'settings_main'
      },
      {
        path: 'home',
        component: SettingsHome,
        outlet: 'settings_main'
      },
      {
        path: 'update-user-details',
        component: UserDetailsComponent,
        outlet: 'settings_main'
      },
      /*{
        path: 'update-org-user-details',
        component: SettingsUpdateOrgUserDetails,
        outlet: 'settings_main'
      },*/
      {
        path: 'groups-member',
        component: MemberGrpComponent,
        outlet: 'settings_main'
      },
      {
        path: 'update-user-group',
        component: UserGroupsComponent,
        outlet: 'settings_main'
      },
      /*{
        path: 'autosave',
        component: AutosaveComponent,
        outlet: 'settings_main'
      },
      {
        path: 'tree-view_user',
        component: TreeViewComponent_user,
        outlet: 'settings_main'
      },*/
      /*
      {
        path: 'tree-view_org',
        component: TreeViewComponent_org,
        outlet: 'settings_main'
      },*/
      {
        path: 'OPCloud-Organization-Settings_org',
        component: OPCloudOrganizationSettingsComponent_org,
        outlet: 'settings_main'
      },
      {
        path: 'OPCloud-Organization-Settings_user',
        component: OPCloudOrganizationSettingsComponent_user,
        outlet: 'settings_main'
      },
      {
        path: 'send_email',
        component: SendEmailComponent,
        outlet: 'settings_main'
      },
      {
        path: 'new-user',
        component: SettingsNewUser,
        outlet: 'settings_main'
      },
      {
        path: 'model-analysis',
        component: ModelAnalysisComponent,
        outlet: 'settings_main'
      },
      {
        path: 'nlp-model-analysis',
        component: NlpModelAnalysisComponent,
        outlet: 'settings_main'
      },
      {
        path: 'model-analysis-tools',
        component: ModelAnalysisToolsComponent,
        outlet: 'settings_main'
      },
      {
        path: 'pareto-frontier',
        component: paretoFrontierDialog,
        outlet: 'settings_main'
      },
      {
        path: 'listOfThings',
        component: ListThingsComponent,
        outlet: 'settings_main'
      },
      /*{
        path: 'scripts',
        component: SettingsScriptsComponent,
        outlet: 'settings_main'
      },*/
      {
        path: 'new-org',
        component: SettingsNewOrg,
        outlet: 'settings_main'
      },
      {
        path: 'add-group',
        component: AddGroupComponent,
        outlet: 'settings_main'
      },
      {
        path: 'set-admin/:chosenOrganization',
        component: SetAdminUserListComponent,
        outlet: 'settings_main',
      },
      {
        path: 'set-admin',
        component: SetAdminUserListComponent,
        outlet: 'settings_main'
      },
      {
        path: 'find-users-admin',
        component: FindUserAdminComponent,
        outlet: 'settings_main'
      },
      {
        path: 'org-admin',
        component: OrgAdminComponent,
        outlet: 'settings_main'
      },
      {
        path: 'sso-settings',
        component: SSOSettingsComponent,
        outlet: 'settings_main'
      },
      {
        path: 'del-org',
        component: DeleteOrgComponent,
        outlet: 'settings_main'
      },
      {
        path: 'del-grp',
        component: GroupAdministrationComponent,
        outlet: 'settings_main'
      },
      {
        path: 'eula',
        component: UserAgreementComponent,
        outlet: 'settings_main'
      },
      {
        path: 'dsm',
        component: DsmAnalysisComponent,
        outlet: 'settings_main'
      },
      {
        path: 'opcloud-quick-manual',
        component: OPCloudManualComponent,
        outlet: 'settings_main'
      },
      {
        path: 'opcloud-privacy-policy',
        component: OPCloudPrivacyPolicyComponent,
        outlet: 'settings_main'
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [RouterModule]
})
export class OpcRoutingModule { }
