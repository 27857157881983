import { Component, Injectable, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {UserDetails, UserService} from '../../rappid-components/services/user.service';
import {validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';
import {MatLegacyCheckboxChange as MatCheckboxChange} from "@angular/material/legacy-checkbox";
import {OrganizationService} from "../../rappid-components/services/organization.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'opcloud-update-user-dialog',
  templateUrl: './update-user-dialog.component.html',
  styleUrls: ['./update-user-dialog.component.css']
})
@Injectable()
export class UpdateUserDialogComponent implements OnInit {

  public isPermanent: boolean;

  user;
  isSysAdmin: boolean;
  curUserDetails;
  subscription;
  hasMultiFactorAuth: boolean;

  details: UserDetails = {
    Name: undefined,
    Email: undefined,
    PhotoURL: undefined,
    SysAdmin: undefined,
    OrgAdmin: undefined,
    IsInsightsUser: undefined,
    IsDSMUser: undefined,
    exp_date: undefined,
    isActive: undefined,
    usersManagement: undefined,
    isExecutionUser: undefined,
    isViewerAccount: undefined,
    multiFactorAuth: undefined
  };
  isOrgAdmin: boolean;
  usersManagement: boolean;
  isOrgSupportMFA: boolean;
  private serverAuth: boolean;

  constructor(
    private service: UserService,
    @Inject(MAT_DIALOG_DATA) private data,
    public dialog: MatDialog, private orgService: OrganizationService
  ) { }

  ngOnInit() {
    this.isSysAdmin = this.service.user.userData.SysAdmin;
    this.isOrgAdmin = this.service.user.userData.OrgAdmin;
    this.usersManagement = this.service.user.userData.usersManagement;
    this.user = this.data.user;
    this.details.SysAdmin = this.user.SysAdmin;
    this.details.OrgAdmin = this.user.OrgAdmin;
    this.details.usersManagement = !!this.user.usersManagement;
    this.details.IsInsightsUser = this.user.IsInsightsUser;
    this.details.IsDSMUser = this.user.IsDSMUser;
    this.details.exp_date = (this.user.exp_date !== '') ? this.user.exp_date : 1546347600000;
    this.isPermanent = !(this.user.exp_date !== '');
    this.details.isActive = this.user.isActive;
    this.details.isExecutionUser = this.user.isExecutionUser;
    this.details.multiFactorAuth = this.user.multiFactorAuth;
    this.details.isViewerAccount = this.user.isViewerAccount;
    this.hasMultiFactorAuth = !!this.details.multiFactorAuth;
    this.serverAuth = environment.serverSideAuth;
    this.orgService.getOrganization(this.user.organization).then(settings => {
      this.isOrgSupportMFA = ['optional', 'mandatory'].includes(settings.auth2Factors);
    });

  }

  updateUserDialog() {
    if (this.isPermanent) {
      this.details['exp_date'] = '';
    }
    if(this.details.IsInsightsUser == undefined){
      this.details.IsInsightsUser = false;
    }
    if(this.details.IsDSMUser == undefined){
      this.details.IsDSMUser = false;
    }
    if (!this.hasMultiFactorAuth) {
      this.details.multiFactorAuth = null;
    }
    const userActivatedAccordingToDate = this.isUserActivatedAccordingToDate(this.details['exp_date']);
    this.details.isActive = userActivatedAccordingToDate;
    this.service.updateUser(this.user.uid, this.data.organization, this.details).then(res => {
      validationAlert('Successfully updated', null, 'Success');
      Object.keys(this.details).forEach((key) => {
       if (this.details[key] !== undefined) {
        this.user[key] = this.details[key];
      }});
      /* update this.user.isActive immediately according to the new date selected so it will be shown without the need
      to render the page again*/
      this.user.isActive = userActivatedAccordingToDate;
      this.dialog.closeAll();
    });

  }

  OnChangeAdminStatus(value) {
    this.details.SysAdmin = value.checked ? true : false;
  }

  OnChangeOrgStatus(value) {
    this.details.OrgAdmin = value.checked ? true : false;
    if (this.details.OrgAdmin === false)
      this.details.usersManagement = false;
  }

  OnChangeUsersManagementStatus(value) {
    this.details.usersManagement = value.checked ? true : false;
  }

  OnChangeIsInsightsUserStatus(value) {
    this.details.IsInsightsUser = value.checked ? true : false;
  }

  OnChangeIsDSMUserStatus(value) {
    this.details.IsDSMUser = value.checked ? true : false;
  }

  /**
   * input: receives a user expiration date (timestamp)
   * output: true if the user has permanent access or if the expiration date is in the future (or today).
   * otherwise, returns false*/
  isUserActivatedAccordingToDate(user_exp_date) {
    if (user_exp_date) {
      const currDateTime = new Date().setHours(0, 0, 0, 0); // to compare just according to day, month and year
      return (currDateTime <= user_exp_date);
    }
    return true; // the user has permanent access
  }

  OnChangeExecutionUser($event) {
    this.details.isExecutionUser = $event.checked ? true : false;
  }

  multiFactorChange($event: MatCheckboxChange) {
    this.hasMultiFactorAuth = $event.checked;
  }

  OnChangeViewerAccount($event) {
    this.details.isViewerAccount = $event.checked ? true : false;
  }
}

